import React, { Component } from "react";
import linkDS from "legacy-js/react/views/PresentationSettings/dataservices/LinkDataService";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import {
    BeautifulDialog,
    DialogContent
} from "legacy-js/react/components/Dialogs/BaseDialog";
import * as social from "js/core/utilities/social";
import { trackActivity } from "js/core/utilities/utilities";
import slug from "slug";
import { Gap30, Gap20 } from "legacy-js/react/components/Gap";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";
import styled from "styled-components";

const LinkedInIcon = () => (<svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M39.0308 37.2808V27.5208C39.0308 22.2917 36.2392 19.8583 32.5175 19.8583C29.5142 19.8583 28.1683 21.5108 27.4183 22.67V20.2583H21.76C21.835 21.8558 21.76 37.2808 21.76 37.2808H27.4183V27.7742C27.4183 27.2667 27.455 26.7583 27.605 26.395C28.0133 25.3783 28.9442 24.3258 30.5067 24.3258C32.555 24.3258 33.3733 25.8867 33.3733 28.1742V37.2817H39.0308V37.2808ZM15.7992 17.935C17.7717 17.935 19.0008 16.6267 19.0008 14.9925C18.9642 13.3233 17.7717 12.0525 15.8367 12.0525C13.9017 12.0525 12.6358 13.3225 12.6358 14.9925C12.6358 16.6267 13.8642 17.935 15.7633 17.935H15.7992ZM25 50.5C11.1933 50.5 0 39.3067 0 25.5C0 11.6925 11.1933 0.5 25 0.5C38.8067 0.5 50 11.6925 50 25.5C50 39.3067 38.8067 50.5 25 50.5ZM18.6283 37.2808V20.2583H12.9708V37.2808H18.6283Z" fill="#11A9E2" />
</svg>);

const TwitterIcon = () => (<svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.001 0.5C11.1933 0.5 0 11.6954 0 25.501C0 39.3087 11.1933 50.5 25.001 50.5C38.8087 50.5 50 39.3087 50 25.501C50 11.6954 38.8077 0.5 25.001 0.5ZM37.5944 20.4602C37.6076 20.7303 37.6127 21.0013 37.6127 21.2755C37.6127 29.6017 31.2754 39.2011 19.6871 39.2011C16.1296 39.2011 12.8168 38.1584 10.0288 36.3715C10.5223 36.4294 11.0228 36.4588 11.5314 36.4588C14.4838 36.4588 17.2007 35.4517 19.3561 33.7623C16.5997 33.7105 14.2737 31.8881 13.4716 29.3854C13.8554 29.4606 14.2503 29.4981 14.6564 29.4981C15.2311 29.4981 15.7874 29.423 16.3164 29.2778C13.433 28.6991 11.2614 26.1508 11.2614 23.1009C11.2614 23.0735 11.2614 23.0471 11.2624 23.0197C12.1112 23.4918 13.0828 23.7751 14.1163 23.8086C12.4249 22.6765 11.3131 20.7506 11.3131 18.5637C11.3131 17.4093 11.6238 16.326 12.166 15.396C15.2737 19.2074 19.9165 21.7161 25.1533 21.978C25.0457 21.5181 24.9898 21.0359 24.9898 20.5424C24.9898 17.0641 27.8103 14.2427 31.2906 14.2427C33.1029 14.2427 34.7395 15.0082 35.8888 16.2316C37.3244 15.9504 38.6726 15.4245 39.8899 14.7026C39.4178 16.1748 38.4198 17.4093 37.1183 18.188C38.3945 18.0357 39.6087 17.6966 40.7377 17.1961C39.895 18.4622 38.8259 19.5718 37.5944 20.4602Z" fill="#11A9E2" />
</svg>);

const FacebookIcon = () => (<svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M21.4708 39.1H27.0908V25.4983H30.84L31.3367 20.8117H27.0908L27.0967 18.465C27.0967 17.2433 27.2133 16.5875 28.9667 16.5875H31.31V11.9H27.56C23.0558 11.9 21.4708 14.1742 21.4708 17.9975V20.8117H18.6633V25.4992H21.4708V39.1ZM25 50.5C11.1933 50.5 0 39.3067 0 25.5C0 11.6925 11.1933 0.5 25 0.5C38.8067 0.5 50 11.6925 50 25.5C50 39.3067 38.8067 50.5 25 50.5Z" fill="#11A9E2" />
</svg>);

export const networks = [
    {
        name: "linkedin",
        icon: <LinkedInIcon />
    },
    {
        name: "twitter",
        icon: <TwitterIcon />
    },
    {
        name: "facebook",
        icon: <FacebookIcon />
    }
];

const StyledBeautifulDialog = styled(BeautifulDialog)`
    padding: 0 0 0 34px;

    .MuiDialog-paperWidthSm {
        max-width: 500px;
    }
`;

const StyledDialogActions = styled(DialogActions)`
    padding: 0;
`;

const Social = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    width: 100px;
    height: 100px;
    justify-content: center;

    &:hover {
        cursor: pointer;
        background: #F7FAFC;
    }
`;

const SocialLink = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;
`;

const SocialTitle = styled.label`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #666666;
`;

export default class SocialShareDialog extends Component {
    constructor() {
        super();
        this.linkRef = React.createRef();

        this.state = {
            allowCommenting: false,
        };
    }

    async handleSocialAccept(network) {
        let {
            presentation,
        } = this.props;
        let {
            allowCommenting,
        } = this.state;

        let link = await linkDS.createSocialLink(presentation, network.name, allowCommenting);
        await linkDS.saveLink(presentation, link);
        //TODO mitch
        // this.setGlobal({
        //     alertMessage: "Social link was created"
        // });
        const props = {
            presentation_id: presentation.id,
            link_ids: [link.get("id")],
            is_new: true,
            type: network.name,
            workspace_id: presentation.getWorkspaceId()
        };
        trackActivity("Sharing", "SocialShare", null, null, props, { audit: true });
        this.props.callback();
        social.createSharePresentationPopup(
            network.name,
            `${window.location.origin}/player/${link.id}/${slug(
                presentation.get("name")
            )}`,
            presentation.get("name")
        );
    }

    toggleAllowCommenting = event => {
        const allowCommenting = !this.state.allowCommenting;
        this.setState({ allowCommenting });
        this.forceUpdate();
    }

    render() {
        let {
            allowCommenting,
        } = this.state;

        return (
            <StyledBeautifulDialog
                maxWidth="sm"
                closeDialog={this.props.closeDialog}
                className="social-share-dialog"
            >
                <DialogTitle>Social Share</DialogTitle>
                <DialogContent>
                    <h3>
                        Share a link to your presentation on these social networks.
                    </h3>
                    <SocialLink>
                        {networks.map(network => (
                            <Social
                                key={network.name}
                                onClick={() => this.handleSocialAccept(network)}
                            >
                                <div>{network.icon}</div>
                                <SocialTitle>{network.name}</SocialTitle>
                            </Social>
                        ))}
                    </SocialLink>
                    <Gap20 />
                    <ToggleAllowCommenting
                        color="#11a9e2"
                        allowCommenting={allowCommenting}
                        onToggle={this.toggleAllowCommenting}
                    />
                </DialogContent>
                <StyledDialogActions>
                    <Button onClick={this.props.closeDialog} color="primary">
                        Close
                    </Button>
                </StyledDialogActions>
            </StyledBeautifulDialog >
        );
    }
}
