import React, { Component } from "react";
import styled from "styled-components";
import {
    DialogTitle,
    DialogActions,
    Button,
    TextField
} from "@material-ui/core";

import { BeautifulDialog, DialogContent, ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import AddTagsDialog from "legacy-js/react/views/UserOptions/dialogs/AddTagsDialog";
import { Field } from "legacy-js/react/components/UiComponents";
import TagsList from "legacy-js/react/components/TagsList";

const SubText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    padding-top: 10px;
`;

const StyledDialogContent = styled(DialogContent)`
    padding: 0 80px;
    overflow-y: hidden;
`;

const StyledDialogActions = styled(DialogActions)`
    &&& {
        justify-content: space-between;
    }
`;

export default class CreateTeamTemplateDialog extends Component {
    constructor(props) {
        super(props);
        let {
            name = "",
            description = "",
            tags = [],
        } = props;

        this.state = {
            name,
            description,
            tags,
        };
    }

    handleRemoveTag = tag => {
        const { tags } = this.state;
        let index = tags.indexOf(tag);
        if (index > -1) {
            tags.splice(index, 1);
            this.setState({
                tags,
            });
        }
    }

    handleAddTag = () => {
        const { existingTags } = this.props;
        const { tags } = this.state;

        ShowDialog(AddTagsDialog, {
            activeTags: tags,
            existingTags,
            acceptCallback: newTags => {
                this.setState({
                    tags: newTags
                });
            },
        });
    }

    handleAccept = isPublished => {
        const {
            callback,
            closeDialog,
        } = this.props;

        if (callback) {
            callback({
                ...this.state,
                isPublished,
            });
        }
        closeDialog();
    }

    render() {
        const {
            closeDialog,
            title = "[Title Missing]",
            subTitle = "[subTitle Missing]",
        } = this.props;

        const {
            name,
            description,
            tags,
        } = this.state;

        return (
            <BeautifulDialog closeDialog={closeDialog}>
                <DialogTitle>
                    <div>{title}</div>
                    <SubText>{subTitle}</SubText>
                </DialogTitle>

                <StyledDialogContent>
                    <Field title="Template Name">
                        <TextField
                            autoFocus
                            id="name"
                            value={name}
                            placeholder="Template name..."
                            fullWidth
                            onChange={event => this.setState({ name: event.target.value })}
                        />
                    </Field>
                    <Field title="Add a description or instructions (optional)">
                        <TextField
                            multiline
                            id="description"
                            value={description}
                            placeholder="Type a description here..."
                            fullWidth
                            onChange={event => this.setState({ description: event.target.value })}
                        />
                    </Field>
                    <Field title="Add tags to facilitate search (optional)">
                        <TagsList
                            tags={tags}
                            onRemove={this.handleRemoveTag}
                            onClick={this.handleAddTag}
                        />
                    </Field>
                </StyledDialogContent>

                <StyledDialogActions>
                    <Button onClick={closeDialog} color="secondary">Cancel</Button>
                    <FlexBox>
                        <Button onClick={() => this.handleAccept(false)} color="secondary">Save as Draft</Button>
                        <Button onClick={() => this.handleAccept(true)} color="primary">Publish Now</Button>
                    </FlexBox>
                </StyledDialogActions>
            </BeautifulDialog>
        );
    }
}
