export default class CycleAnimator {
    get duration() {
        return 3000;
    }

    constructor(element, type, playReverse) {
        this.element = element;
        this.type = type;
        this.playReverse = playReverse;
        this.setupAnimation();
    }

    start() {
        this.isRunning = true;

        let startTime;
        const handleFrame = timestamp => {
            if (!this.isRunning) {
                return;
            }

            if (!startTime) {
                startTime = timestamp;
            }

            let time = timestamp - startTime;
            if (time > this.duration) {
                startTime = timestamp;
                time = 0;
            }

            this.handleAnimationFrame(time);

            this.element.canvas.layouter.requestAnimationFrame(this.element, handleFrame);
        };

        this.element.canvas.layouter.requestAnimationFrame(this.element, handleFrame);
    }

    stop() {
        this.isRunning = false;
    }

    setupAnimation() {
    }

    handleAnimationFrame(time) {
    }
}
