import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Transition } from "react-transition-group";
import { PositionType } from "legacy-common/constants";

const TRANSITION_DURATION = 350;
const TRANSITION = `350ms ease`;

const ClickShield = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
`;

const LeftSidePanelContainer = styled.div`
  ${props => props.width && css`
    width: ${props.width}px;
  `}
  height: 100%;
  background: ${props => props.backgroundColor};
  position: absolute;
  top: 0px;
  transform: translateX(${({ state, width }) => (["entering", "entered"].includes(state) ? 0 : -(width + 40))}px);
  z-index: ${({ state }) => (["entering", "entered"].includes(state) ? 3 : 2)};
  transition: ${TRANSITION};
  box-shadow: 10px 0px 40px rgb(0 0 0 / 50%);
`;

const ResizeBar = styled.div`
  cursor: row-resize;
  height: 5px;
  position: absolute;
  width: 100%;
`;

export class LeftSidePanel extends Component {
    handleEntering = () => {
        this.props.callbacks && this.props.callbacks("entering", {
            position: PositionType.LEFT,
            size: this.props.width + 20,
            showControls: this.props.showControls,
            keepSelection: this.props.keepSelection,
        });
    }

    handleEntered = () => {
        this.props.callbacks && this.props.callbacks("entered", {
            showControls: this.props.showControls
        });
    }

    handleExiting = () => {
        this.props.callbacks && this.props.callbacks("exiting");
    }

    handleExited = () => {
        this.props.callbacks && this.props.callbacks("exited");
    }

    render() {
        const { visible, width, children, onClose, showControls, backgroundColor = "white" } = this.props;

        return (
            <Transition
                in={visible}
                timeout={TRANSITION_DURATION}
                mountOnEnter
                unmountOnExit
                onEnter={this.handleEntering}
                onEntered={this.handleEntered}
                onExiting={this.handleExiting}
                onExited={this.handleExited}
            >
                {state => (
                    <>
                        {!showControls && <ClickShield onClick={onClose} />}
                        <LeftSidePanelContainer backgroundColor={backgroundColor} state={state}
                            width={width}
                            className={["entering", "entered"].includes(state) ? "activeSidePanel" : ""}
                        >
                            {React.cloneElement(React.Children.only(children), { transitionState: state, onClose })}
                        </LeftSidePanelContainer>
                    </>
                )}
            </Transition>
        );
    }
}

const RightSidePanelContainer = styled.div.attrs(({ width, state }) => ({
    style: {
        width: `${width}px`,
        right: ["entering", "entered"].includes(state) ? 0 : -(width + 40),
        zIndex: ["entering", "entered"].includes(state) ? 3 : 2
    }
}))`
  height: 100%;
  background: white;
  position: absolute;
  top: 0px;
  transition: ${TRANSITION};
`;

export class RightSidePanel extends Component {
    handleEntering = () => {
        this.props.callbacks && this.props.callbacks("entering", {
            position: PositionType.RIGHT,
            size: this.props.width + 20,
            showControls: this.props.showControls
        });
    }

    handleEntered = () => {
        this.props.callbacks && this.props.callbacks("entered", {
            showControls: this.props.showControls
        });
    }

    handleExiting = () => {
        this.props.callbacks && this.props.callbacks("exiting");
    }

    handleExited = () => {
        this.props.callbacks && this.props.callbacks("exited");
    }

    render() {
        const { visible, width, children, onClose, showControls } = this.props;

        return (
            <Transition
                in={visible}
                timeout={TRANSITION_DURATION}
                mountOnEnter
                unmountOnExit
                onEnter={this.handleEntering}
                onEntered={this.handleEntered}
                onExiting={this.handleExiting}
                onExited={this.handleExited}
            >
                {state => (
                    <>
                        {!showControls && <ClickShield onClick={onClose} />}
                        <RightSidePanelContainer state={state}
                            width={width}
                            className={["entering", "entered"].includes(state) ? "activeSidePanel" : ""}
                        >
                            {React.cloneElement(React.Children.only(children), { transitionState: state })}
                        </RightSidePanelContainer>
                    </>
                )}
            </Transition>
        );
    }
}

const BottomPanelContainer = styled.div.attrs(({ height, state }) => ({
    style: {
        height: `${height}px`,
        bottom: ["entering", "entered"].includes(state) ? 0 : -(height),
        zIndex: ["entering", "entered"].includes(state) ? 2 : 1,
        transition: ["entering", "exiting"].includes(state) ? TRANSITION : "none"
    }
}))`
  width: 100%;
  background: white;
  position: absolute;
  left: 0px;
  left: 0px;
`;

export class BottomPanel extends Component {
    constructor(props) {
        super(props);

        this.start = null;
        this.state = {
            isResizing: false,
            userHeight: null
        };
    }

    handleEntering = () => {
        const { userHeight } = this.state;
        const { callbacks, height, showControls } = this.props;

        const panelHeight = userHeight ?? height;

        callbacks && callbacks("entering", {
            position: PositionType.BOTTOM,
            size: panelHeight,
            showControls
        });
    }

    handleEntered = () => {
        const { callbacks, showControls } = this.props;
        callbacks && callbacks("entered", { showControls });
    }

    handleExiting = () => {
        const { callbacks } = this.props;
        callbacks && callbacks("exiting");
    }

    handleExited = () => {
        const { callbacks } = this.props;
        callbacks && callbacks("exited");
        this.setState({ userHeight: null });
    }

    handleMouseDown = event => {
        const { userHeight } = this.state;
        const { height, onStartResize } = this.props;

        event.preventDefault();

        this.start = event.screenY;
        this.initialHeight = userHeight ?? height;

        this.setState({
            isResizing: true
        });

        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);

        onStartResize();
    };

    handleMouseMove = ({ screenY }) => {
        window.requestAnimationFrame(timestamp => {
            if (this.dragHandledAt === timestamp) {
                return;
            }
            const { userHeight } = this.state;
            const { onResize, activePanelProps, availableBounds } = this.props;
            const { isResizing } = this.state;
            if (!isResizing) return;

            const diff = this.start - screenY;
            let newHeight = this.initialHeight + diff;

            this.dragHandledAt = timestamp;

            if (newHeight > userHeight && availableBounds.height < 250) {
                newHeight = userHeight;
            }

            this.setState({
                userHeight: newHeight
            });

            onResize({
                ...activePanelProps,
                size: newHeight
            });
        });
    };

    handleMouseUp = event => {
        const { onEndResize } = this.props;

        event.preventDefault();

        this.setState({
            isResizing: false
        });

        onEndResize();

        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
    };

    render() {
        const { userHeight, isResizing } = this.state;
        const { visible, children, height, resizable = false, onClose, showControls } = this.props;

        const panelHeight = userHeight ?? height;

        return (
            <Transition
                in={visible}
                timeout={TRANSITION_DURATION}
                mountOnEnter
                unmountOnExit
                onEnter={this.handleEntering}
                onEntered={this.handleEntered}
                onExiting={this.handleExiting}
                onExited={this.handleExited}
            >
                {state => (
                    <>
                        {!showControls && <ClickShield onClick={onClose} />}
                        <BottomPanelContainer state={state}
                            height={panelHeight}
                            className={["entering", "entered"].includes(state) ? "activeSidePanel" : ""}
                        >
                            {resizable && <ResizeBar onMouseDown={this.handleMouseDown} />}
                            {React.cloneElement(React.Children.only(children), { transitionState: state, isResizing })}
                        </BottomPanelContainer>
                    </>
                )}
            </Transition>
        );
    }
}
