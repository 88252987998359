import { $ } from "legacy-js/vendor";
import { controls } from "legacy-js/editor/ui";
import InputConfirmationDialog from "legacy-js/react/components/Dialogs/InputConfirmationDialog";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

import { ElementSelection } from "../BaseElementEditor";

const StreetMapSelection = ElementSelection.extend({
    getOffset() {
        return -40;
    },

    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: "Set Address...",
            icon: "location_on",
            callback: () => {
                let dialog = ShowDialog(InputConfirmationDialog, {
                    title: "Set Street Map Address",
                    input: {
                        id: "street_address",
                        type: "text",
                        placeholder: "Enter an address or location...",
                        value: this.element.address
                    },
                    acceptCallback: value => {
                        this.element.model.map_address.text = value;
                        this.element.canvas.updateCanvasModel(false);
                    }
                });

                $("#street_address").on("keypress", event => {
                    if (event.which == 13) {
                        dialog.onAcceptDialog();
                    }
                });
            }
        });

        this.addControl({
            id: "mapStyle",
            type: controls.POPUP_BUTTON,
            label: "Map Style",
            items: () => [{
                value: "default", label: "Default",
            }, {
                value: "light", label: "Light"
            }, {
                value: "dark", label: "Dark"
            }, {
                value: "retro", label: "Retro"
            }, {
                value: "satellite", label: "Satellite"
            }, {
                value: "hybrid", label: "Hybrid"
            }, {
                value: "terrain", label: "Terrain"
            }, {
                type: "divider"
            }, {
                type: "control",
                view: () => controls.createCheckbox(this, {
                    label: "Show Marker",
                    value: this.element.model.showMarker,
                    callback: value => {
                        this.element.model.showMarker = value;
                        this.element.canvas.updateCanvasModel(false);
                    }
                })
            }],
            // model: this.element.model,
            // property: "mapStyle",
            selectedValue: this.element.model.mapStyle,
            callback: value => {
                this.element.model.mapStyle = value;
                this.element.canvas.updateCanvasModel(false);
            }
        });

        this.addControl({
            type: controls.BUTTON,
            icon: "zoom_in",
            callback: () => {
                this.element.model.zoom = (this.element.model.zoom || 12) + 1;
                this.element.canvas.updateCanvasModel(false);
            }
        });

        this.addControl({
            type: controls.BUTTON,
            icon: "zoom_out",
            callback: () => {
                this.element.model.zoom = (this.element.model.zoom || 12) - 1;
                this.element.canvas.updateCanvasModel(false);
            }
        });
    }

});

export const editors = {
    StreetMapSelection
};
