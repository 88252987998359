import React from "react";
import classNames from "classnames";
import { InputBase } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

function TextArea({ isVisible, classes, parentClass, onChange, onBlur, text, placeholder, inputProps, readOnly = false }) {
    return isVisible ? (
        <div className={classNames(classes.root, parentClass)}>
            <InputBase
                readOnly={readOnly}
                onChange={event => onChange(event.target.value)}
                onBlur={event => onBlur && onBlur(event.target.value)}
                multiline
                fullWidth
                placeholder={placeholder}
                rows="3"
                classes={{ focused: classes.focus }}
                className={classes.textArea}
                inputProps={{ className: classes.input, ...inputProps }}
                value={text || ""}
                onKeyDown={event => event.stopPropagation()}
            />
        </div>
    ) : null;
}

TextArea.propTypes = {
    isVisible: PropTypes.bool,
    classes: PropTypes.object,
    parentClass: PropTypes.string,
    onChange: PropTypes.func,
    text: PropTypes.string
};

const StyledTextArea = withStyles({
    root: {
        border: "1px solid #ddd",
        width: "100%",
        height: "100%",
        fontFamily: "Source Sans Pro"
    },
    focus: {
        outline: "3px solid #82BDFA"
    },
    textArea: {
        backgroundColor: "#FFFFFF",
        borderRadius: "1px",
        color: "#222"
    },
    input: {
        fontSize: "14px",
        padding: "4px 10px"
    }
})(TextArea);

export default StyledTextArea;
