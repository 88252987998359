import React, { Component } from "react";

class Tab extends Component {
    onClick = () => {
        let { label, onClick } = this.props;
        onClick(label);
    };

    render() {
        const {
            onClick,
            props: { activeTab, label }
        } = this;

        let className = "tab-list-item";
        if (activeTab == label) {
            className += " tab-list-active";
        }

        return (
            <div className={className} onClick={onClick}>
                {label}
            </div>
        );
    }
}

class Tabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label
        };
    }

    onClickTabItem = tab => {
        const { parentOnClickHandler } = this.props;
        this.setState({ activeTab: tab });
        parentOnClickHandler && parentOnClickHandler(tab);
    };

    render() {
        const {
            onClickTabItem,
            props: { children, parentOnClickHandler },
            state: { activeTab }
        } = this;

        return (
            <div className="tabs">
                <div className="tab-list">
                    {children.map(child => {
                        return (
                            <Tab
                                key={child.props.label}
                                activeTab={activeTab}
                                label={child.props.label}
                                onClick={this.onClickTabItem}
                            />
                        );
                    })}
                </div>
                <div className="tab-content">
                    {children.map(child => {
                        if (child.props.label !== activeTab) {
                            return undefined;
                        } else {
                            return child.props.children;
                        }
                    })}
                </div>
            </div>
        );
    }
}

export default Tabs;
