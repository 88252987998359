import React, { useRef, useState } from "react";
import styled from "styled-components";

import { Icon, IconButton } from "@material-ui/core";

import { BlueButton } from "legacy-js/react/components/UiComponents";
import { themeColors } from "legacy-js/react/sharedStyles";
import { ContextSource } from "legacy-js/react/views/AIGeneration/panes/GeneratePresentationPane/controllers/GeneratePresentationController";

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const FileInputContainer = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 10px;

  border: 1px solid #EEEEEE;

  > button {
    height: 28px;
    border-radius: 14px;
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 123px;
      height: 100%;
      cursor: pointer;
    }
  }
`;

const Caption = styled.div`
  margin-top: 10px;

  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #666666;
`;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 10px;

  > span {
    color: ${themeColors.mediumGray};
  }
`;

interface FileInputProps {
    contextSource: ContextSource,
}

function FileInput({ contextSource }: FileInputProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [file, setFile] = useState<File>(contextSource.file);

    const handleFileInputChange = event => {
        setFile(event.target.files[0]);
        contextSource.file = event.target.files[0];

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    return (
        <Container>
            <FileInputContainer>
                {file && <FileContainer>
                    <Icon>text_snippet</Icon>
                    {file.name}
                    <IconButton size="small" onClick={() => handleFileInputChange({ target: { files: [] } })}>
                        <Icon>close</Icon>
                    </IconButton>
                </FileContainer>}
                {!file && <BlueButton>
                    <Icon>cloud_upload</Icon>
                    upload
                    <input
                        type="file"
                        multiple
                        ref={fileInputRef}
                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt,text/plain,.pdf,application/pdf"
                        onChange={handleFileInputChange}
                    />
                </BlueButton>}
            </FileInputContainer>
            <Caption>
                Upload a text file, word document or PDF
            </Caption>
        </Container>
    );
}

export default FileInput;
