import { setGlobal } from "reactn";

import { BackgroundStyleType, ForeColorType, PREDEFINED_PALETTES } from "legacy-common/constants";
import { appVersion } from "legacy-js/config";
import PresentationLibraryController from "legacy-js/controllers/PresentationLibraryController";
import AppController from "legacy-js/core/AppController";
import getLogger from "js/core/logger";
import { ds } from "js/core/models/dataService";
import * as geom from "js/core/utilities/geom";
import { trackActivity, trackState } from "js/core/utilities/utilities";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import PresentationEditorController, { PanelType } from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { HelpBubble, HelpBubblePositionType } from "legacy-js/help/help.js";
import { app } from "js/namespaces";
import { ShowDialog, ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddSlideContainer } from "legacy-js/react/views/AddSlide";
import { $, _, Backbone } from "legacy-js/vendor";

import "css/controls.scss";
import "css/tour.scss";

const logger = getLogger();

export class Tour {
    constructor(workspaceId) {
        _.extend(this, Backbone.Events);

        this.workspaceId = UIController.getWorkspaceId();

        const skipTour = () => {
            trackActivity("Tour", "Skip");
            trackState({ isInTour: false });

            this.allowKeyEvents();

            this.tourShield();
            this.createPrompt({
                body: "<p>If you'd like to take the tour later, you can always find it here in the Help menu.</p>",
                target: ".help-button",
                position: HelpBubblePositionType.BELOW,
                buttons: [
                    {
                        label: "Great!",
                        action: () => {
                            this.removeShield();
                        }
                    }
                ],
            });
            app.tour = null;
        };

        app.user.update({ hasTakenTour: true });
        app.user.updatePromise.then(() => {
            // show tour intro
            this.tourShield();

            //this delay is for UX purposes to make the prompt stand out to the user.
            _.delay(() => {
                this.createPrompt({
                    body:
                        "<h4>Your 2-Minute Tour of Beautiful.ai</h4><p>If you're used to working in PowerPoint, your first experience with Beautiful.ai will definitely feel different.</p><p>But give us two minutes and we'll show you everything you need to know to start building your own beautiful slides.</p>",
                    buttons: [
                        {
                            label: "Skip",
                            style: "simple",
                            action: skipTour
                        },
                        {
                            label: "Start the tour!",
                            action: () => {
                                this.removeShield();
                                this.start();
                            }
                        },
                    ],
                    buttonsAlignment: "space-between"
                });
            }, 1000);
        });
    }

    tourShield(callback) {
        this.removeShield();
        $(".help_bubble").remove();

        this.addShield();
    }

    addShield() {
        this.$tourShield = $("body").addEl($.div("tour_shield")).css({ left: 0, top: 0, position: "absolute", width: "100vw", height: "100vh", zIndex: 9999 });
    }

    removeShield() {
        $(".tour_shield").remove();
    }

    // start tour play
    async start() {
        trackState({ isInTour: true });
        trackActivity("Tour", "Start", null, null, { tourType: "old" });
        this.buildSteps();
        this.allowKeys = true;
        this.preventKeyEvents();
        if (PresentationLibraryController.isInitialized) {
            this.setupTour();
        } else {
            await PresentationLibraryController.initialize({ workspaceId: "personal" });
            this.setupTour();
        }
    }

    // setupTour by creating tour presentation
    async setupTour() {
        // create tour presentation
        let tourSlides;

        if (appVersion < 10) {
            tourSlides = [
                {
                    template_id: "title",
                    states: [{
                        primary: {
                            title: {
                                text: "My First Presentation"
                            },
                            description: {
                                text: "Let's Make it Beautiful"
                            }
                        }
                    }]
                },
                {
                    template_id: "textgrid_vertical_icons",
                    states: [{
                        header: {
                            title: {
                                text: "Why Beautiful.ai?",
                                styles: [{ start: 0, end: 25 }]
                            }
                        },
                        primary: {
                            items: [
                                {
                                    content_type: "icon",
                                    content_value: "speed-arrow",
                                    title: {
                                        text: "It's Fast",
                                        styles: [{ start: 0, end: 9 }]
                                    },
                                    body: {
                                        text: "Build your presentations in hours, not days",
                                        styles: [{ start: 0, end: 43 }]
                                    }
                                },
                                {
                                    content_type: "icon",
                                    content_value: "grid",
                                    title: {
                                        text: "It's Better",
                                        styles: [{ start: 0, end: 11 }]
                                    },
                                    body: {
                                        text: "Tell a visual story that connects with audiences",
                                        styles: [{ start: 0, end: 48 }]
                                    }
                                },
                                {
                                    content_type: "icon",
                                    content_value: "rose",
                                    title: {
                                        text: "It's Beautiful",
                                        styles: [{ start: 0, end: 14 }]
                                    },
                                    body: {
                                        text: "You will be amazed at what you can create",
                                        styles: [{ start: 0, end: 39 }]
                                    }
                                },
                                {
                                    content_value: "balloon",
                                    content_type: "icon",
                                    title: {
                                        text: "It's Fun",
                                        styles: [{ start: 0, end: 8 }]
                                    },
                                    body: {
                                        text: "When was the last time you enjoyed making slides?",
                                        styles: [{ start: 0, end: 49 }]
                                    }
                                }
                            ],
                            forceSingleColumn: false
                        }
                    }]
                }
            ];
        } else {
            tourSlides = [
                {
                    template_id: "title",
                    states: [{
                        primary: {
                            textPosition: "left",
                            textAlign: "left",
                            text: {
                                blocks: [{
                                    id: "block1",
                                    html: "My First Presentation",
                                    textStyle: "heading",
                                    type: "text"
                                }, {
                                    id: "block2",
                                    html: "Let's Make It Beautiful!",
                                    textStyle: "body",
                                    type: "text"
                                }]
                            }
                        }
                    }]
                },
                {
                    template_id: "textgrid_vertical_icons",
                    states: [{
                        header: {
                            text: {
                                blocks: [{
                                    id: "block1",
                                    html: "Why Beautiful.ai?",
                                    type: "text",
                                    textStyle: "heading"
                                }]
                            }
                        },
                        primary: {
                            items: [
                                {
                                    content_type: "icon",
                                    content_value: "speed-arrow",
                                    text: {
                                        blocks: [{
                                            id: "block1",
                                            html: "It's Fast",
                                            type: "text",
                                            textStyle: "title"
                                        }, {
                                            id: "block2",
                                            html: "Build your presentations in hours, not days",
                                            type: "text",
                                            textStyle: "body"
                                        }]
                                    }
                                },
                                {
                                    content_type: "icon",
                                    content_value: "grid",
                                    text: {
                                        blocks: [{
                                            id: "block1",
                                            html: "It's Better",
                                            type: "text",
                                            textStyle: "title"
                                        }, {
                                            id: "block2",
                                            html: "Tell a visual story that connects with audiences",
                                            type: "text",
                                            textStyle: "body"
                                        }]
                                    }
                                },
                                {
                                    content_type: "icon",
                                    content_value: "rose",
                                    text: {
                                        blocks: [{
                                            id: "block1",
                                            html: "It's Beautiful",
                                            type: "text",
                                            textStyle: "title"
                                        }, {
                                            id: "block2",
                                            html: "You will be amazed at what you can create",
                                            type: "text",
                                            textStyle: "body"
                                        }]
                                    }
                                },
                                {
                                    content_value: "balloon",
                                    content_type: "icon",
                                    text: {
                                        blocks: [{
                                            id: "block1",
                                            html: "It's Fun",
                                            type: "text",
                                            textStyle: "title"
                                        }, {
                                            id: "block2",
                                            html: "When was the last time you enjoyed making slides?",
                                            type: "text",
                                            textStyle: "body"
                                        }]
                                    }
                                }
                            ],
                            forceSingleColumn: false
                        }
                    }]
                }
            ];
        }

        const theme = ds.builtInThemes.filter(theme => theme.id === "cheeky")[0];
        theme.set("colors", _.find(PREDEFINED_PALETTES, { id: "colorful" }).colors);

        const presentation = await PresentationLibraryController.createPresentation(
            {
                name: "My First Presentation",
                workspaceId: this.workspaceId,
                slides: tourSlides,
                theme
            }
        );
        presentation.disconnect();

        // navigate to editor
        AppController.showEditor({ presentationId: presentation.id });

        this.tourStep = -1;
        this.addShield();

        // wait for the editor to initialize
        while (!PresentationEditorController.isInitialized) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        this.next();

        $(window).on("popstate.tour", event => {
            history.pushState(null, null, null);
            this.quitTour();
        });
    }

    quitTour() {
        let prompt = this.createPrompt({
            body:
                "<h4>Exit Tour</h4><p>We strongly recommend you complete the tour (we promise it won't take more than a minute or so) but we understand if you really want to get started.</p><p>You can always replay the tour from the help menu.</p>",
            buttons: [
                {
                    label: "Exit Tour",
                    action: () => {
                        trackActivity("Tour", "quit");
                        trackState({ isInTour: false });
                        this.end();
                    }
                },
                {
                    label: "Continue Tour",
                    action: () => {
                        prompt.remove();
                    }
                }
            ]
        });
        prompt.$el.clickShield(() => {
        });
    }

    // play next tour step
    next() {
        this.tourStep++;

        if (this.tourStep < this.tourSteps.length) {
            try {
                this.playStep(this.tourStep);
                trackActivity("Tour", "Step", "none", this.tourStep, {}, { skipAmplitude: true });
            } catch (error) {
                ShowWarningDialog({
                    title: "Tour Error",
                    message: "We're sorry but something went wrong with the tour.",
                });
                logger.error(error, "Tour playStep() failed");
                this.end({ trigger: "error", message: error.message });
            }
        }
    }

    // execute playback of tour step at index
    playStep(index) {
        this.tourSteps[index]();
    }

    preventKeyEvents() {
        if (this.allowKeys) {
            document.addEventListener("keydown", this.onKeyDown, true);
            document.addEventListener("keypress", this.onKeyDown, true);
            document.addEventListener("keyup", this.onKeyDown, true);
            document.addEventListener("wheel", this.onKeyDown, { passive: false });
        }
        this.allowKeys = false;
    }

    allowKeyEvents(allowScroll = true) {
        this.allowKeys = true;
        document.removeEventListener("keydown", this.onKeyDown, true);
        document.removeEventListener("keypress", this.onKeyDown, true);
        document.removeEventListener("keyup", this.onKeyDown, true);
        // We have a special case for preventing the scroll wheel because we want to prevent scrolling in the editor
        // when we are in the tour, when navigating to another slide
        if (allowScroll) {
            document.removeEventListener("wheel", this.onKeyDown, { passive: false });
        }
    }

    onKeyDown(event) {
        if (this.allowKeys) return;
        if (event.metaKey) return;

        event.preventDefault();
        event.stopPropagation();
    }

    // build array of tour step functions
    buildSteps() {
        this.tourSteps = [
            () => {
                this.createPrompt({
                    body:
                        "<h4>Here We Go!</h4><p>Welcome to your first Beautiful.ai presentation. We've created a couple of slides for you to help you get started.</p>",
                    target: null,
                    buttons: [{ label: "Let's Go!", action: () => this.next() }]
                });
            },

            () => {
                let prompt = this.createPrompt({
                    title: "Add an Image",
                    body:
                        "<p>Let's start by adding an image to our title slide.</p><p>Click here to see the additional ways we can layout this slide.</p>",
                    target: "#show-layout",
                    position: HelpBubblePositionType.RIGHT,
                    targetOffsetY: -6,
                    targetOffsetX: 10
                });

                this.waitForClick("#show-layout").then(() => {
                    prompt.remove();
                    PresentationEditorController.togglePanel(PanelType.LAYOUT);
                    _.delay(() => {
                        this.next();
                    }, 400);
                });
            },
            () => {
                $(".tour_shield").css("z-index", 90000);

                let item = ".left_tray_full";
                let prompt = this.createPrompt({
                    title: "Pick a Style",
                    body:
                        "<p>Many slides give you different options to add to a slide. Let's add an image to the side.</p>",
                    target: item,
                    position: HelpBubblePositionType.RIGHT,
                    targetOffsetY: -6,
                    targetOffsetX: -14
                });

                this.waitForClick(item).then(() => {
                    prompt.remove();
                    $(item).trigger("click");
                    _.delay(() => this.next(), 400);
                });
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Add an Image",
                    body:
                        "<p>Your days of hunting around for stock photos and icons are over.</p><p>We've got millions of searchable - and totally <strong>free</strong> - images that you can use in your presentations.</p>",
                    target: ".searchbar .micon",
                    targetOffsetX: 100,
                    position: HelpBubblePositionType.BELOW,
                    buttons: [
                        {
                            label: "Find Something For Me!",
                            action: () => this.next()
                        }
                    ]
                });
            },
            () => {
                let query = "dandelion";
                const assetPanel = $(".searchbar input");
                // type query in to search input
                let ch = 0;
                let s = "";
                let typeChar = () => {
                    s += query[ch];
                    assetPanel.val(s);

                    ch++;
                    if (ch < query.length) {
                        _.delay(() => typeChar(), 20 + Math.random() * 40);
                    } else {
                        _.delay(
                            () =>
                                app.tourFuncs.doTourImageSearch().then(() => {
                                    _.delay(() => {
                                        this.next();
                                    }, 500);
                                }),
                            300
                        );
                    }
                };
                typeChar();
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Add an Image",
                    body: "<p>There are tons of great choices here, but let's go with this one.</p>",
                    target: ".add-asset-container .stock-photo-wrapper",
                    targetOffsetX: -30,
                    position: HelpBubblePositionType.RIGHT
                });

                this.waitForClick(".add-asset-container .stock-photo-wrapper:eq(0)").then(() => {
                    prompt.remove();

                    app.tourFuncs.selectDandelion().then(() => {
                        PresentationEditorController.closeAllPanels();
                        _.delay(() => {
                            this.next();
                        }, 1000);
                    });
                });
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Navigating Slides",
                    body:
                        "<p>Awesome, that looks great!</p><p>Now that we've finished our title slide, let's go to the next slide in our presentation.</p><p>You can either click on the next slide to advance or hit the right arrow key on your keyboard.</p>",
                    target: Object.values(PresentationEditorController.getCanvasControllers())[1].canvas.$el,
                    position: HelpBubblePositionType.LEFT
                });

                this.allowKeyEvents(false); // allow key events so we can listen for RIGHT_ARROW

                function onKeyPress(event) {
                    event.stopPropagation();
                    // listen for RIGHT_ARROW key event
                    if (event.which == 39) {
                        event.currentTarget.removeEventListener(event.type, onKeyPress, true);
                        goNextSlide();
                    }
                }

                let goNextSlide = () => {
                    prompt.remove();
                    PresentationEditorController.goNextSlide();
                    this.next();
                    // this.listenToOnce(app.mainView.editorView, "setCurrentCanvas", () => this.next());
                    document.removeEventListener("keydown", onKeyPress, true);
                    this.preventKeyEvents();
                };

                document.addEventListener("keydown", onKeyPress, true);

                this.waitForClick(".slide_canvas:eq(1)").then(() => goNextSlide());
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Working with Slides",
                    body:
                        "<p>We've gone ahead and filled in this slide for you so that we can get right to the good stuff.</p><p>Let's learn some template basics!</p>",
                    buttons: [{ label: "OK", action: () => this.next() }]
                });

                app.mainView.editorView.selectionLayer.showElementRollover();
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Changing Colors",
                    body:
                        "<p>This slide is nice, but maybe we want it to <strong>pop</strong> more.</p><p>Let's open up the color panel.</p>",
                    target: "#show-colors",
                    position: HelpBubblePositionType.RIGHT,
                    targetOffsetY: -6,
                    targetOffsetX: 10
                });

                this.waitForClick("#show-colors").then(() => {
                    prompt.remove();
                    PresentationEditorController.togglePanel(PanelType.COLOR);
                    _.delay(() => this.next(), 400);
                });
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Changing Colors",
                    body:
                        "<p>You can use these controls to change colors, edit your theme, or create a custom theme with your own brand colors and styles.</p><p>For now, let's just set a dark background color for our slide.</p>",
                    target: ".color_chit[data-color-name='background_dark']",
                    position: HelpBubblePositionType.RIGHT
                });

                this.waitForClick(".color_chit[data-color-name='background_dark']").then(() => {
                    prompt.remove();
                    app.currentCanvas.model.layout.backgroundStyle = BackgroundStyleType.DARK;
                    app.currentCanvas.model.layout.slideColor = ForeColorType.NEUTRAL;
                    app.currentCanvas.getCanvasElement().markStylesAsDirty();
                    app.currentCanvas.updateCanvasModel(true);

                    _.delay(() => {
                        this.next();
                    }, 600);
                });
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Changing Colors",
                    body:
                        "<p>Did you notice that the color of your text and icons changed with your new background color? (Pretty cool, right?)</p><p>Now let's change the foreground color to 'colorful' so that each item has its own color.</p>",
                    target: ".color_group.foreground  .colorful_chit",
                    position: HelpBubblePositionType.RIGHT
                });

                this.waitForClick(".color_group.foreground  .colorful_chit").then(() => {
                    prompt.remove();
                    app.currentCanvas.model.layout.slideColor = ForeColorType.COLORFUL;
                    app.currentCanvas.getCanvasElement().markStylesAsDirty();
                    app.currentCanvas.updateCanvasModel(true);

                    _.delay(() => {
                        PresentationEditorController.closeAllPanels();
                        _.delay(() => this.next(), 350);
                    }, 600);
                });
            },
            () => {
                // We select the item by text because the render order here is not guaranteed, so we can't rely on the index
                const item = ".IconTextGridItem:contains(\"It\'s Fun\")";

                const prompt = this.createPrompt({
                    title: "Re-ordering Items",
                    body:
                        "<p>With your new colors dialed in, let's try editing your slide.</p><p>With Beautiful.ai, you never have to spend time positioning or aligning shapes or text on your slide.</p><p>First, click to select the item.</p>",
                    target: item,
                    position: HelpBubblePositionType.LEFT,
                    targetOffsetX: 70,
                    targetOffsetY: -20
                });
                this.waitForClick(item).then(e => {
                    prompt.remove();
                    app.mainView.editorView.selectionLayer.onMouseDown(e);

                    this.next();
                });
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Re-ordering Items",
                    body: "<p>And now drag it to a new position.</p>",
                    target: ".icon_button_square",
                    position: HelpBubblePositionType.ABOVE,
                    targetOffsetY: -10
                });

                const canvas = app.currentCanvas;

                const dragItem = canvas.layouter.primary.itemElements[canvas.layouter.primary.itemElements.length - 1];
                const containerElement = dragItem.getRootElement();
                const selectionLayer = app.mainView.editorView.selectionLayer;

                ds.selection.element = dragItem;

                this.waitForClick(".icon_button_square", "mousedown").then(event => {
                    app.isDraggingItem = true;
                    dragItem.isDragging = true;
                    prompt.remove();

                    const offsetX = (event.pageX - selectionLayer.$el.offset().left) * canvas.getScale() - dragItem.canvasBounds.left;
                    const offsetY = (event.pageY - selectionLayer.$el.offset().top) * canvas.getScale() - dragItem.canvasBounds.top;
                    $(document).on("mousemove.drag", event => {
                        window.requestAnimationFrame(timestamp => {
                            if (!canvas.layouter.isGenerating) {
                                const newX = (event.pageX - selectionLayer.$el.offset().left) * canvas.getScale() - containerElement.canvasBounds.left - offsetX;
                                const newY = (event.pageY - selectionLayer.$el.offset().top) * canvas.getScale() - containerElement.canvasBounds.top - offsetY;
                                dragItem.dragPosition = new geom.Point(newX, newY);

                                selectionLayer.hideWidgets();
                                dragItem.overlay.calcDropTarget({
                                    containerElement,
                                    dragItem
                                }, { elementPosition: new geom.Point(newX, newY) });
                                canvas.refreshCanvas(false);
                            }
                        });
                    });

                    $(document).on("mouseup.drag", event => {
                        $(document).off(".drag");
                        app.isDraggingItem = false;
                        dragItem.isDragging = false;
                        ds.selection.element = null;
                        canvas.updateCanvasModel(false).then(() => {
                            //check if the dragItem has moved to a new position
                            if (containerElement.model[containerElement.collectionPropertyName].indexOf(dragItem.model) != 3) {
                                _.delay(() => this.next(), 500);
                            } else {
                                this.playStep(this.tourStep);
                            }
                        });
                    });
                });
            },
            () => {
                let prompt = this.createPrompt({
                    title: "Adding an Item",
                    body:
                        "<p>Each slide will have a bunch of formatting options for you to play with right here.</p><p>For now, let's try adding another item.</p>",
                    target: ".control.button[data-label='Add Item']",
                    position: HelpBubblePositionType.ABOVE,
                    targetOffsetY: -10
                });

                this.waitForClick(".control.button[data-label='Add Item']").then(() => {
                    prompt.remove();

                    let newItem = app.currentCanvas.layouter.primary.addItem({
                        title: {
                            text: "Your New Item"
                        },
                        content_type: "icon",
                        content_value: "baby"
                    });
                    return app.currentCanvas.updateCanvasModel(true).then(() => {
                        _.delay(() => this.next(), 1000);
                    });
                });
            },
            () => {
                const prompt = this.createPrompt({
                    title: "Adding a New Slide",
                    body:
                        "<p>Beautiful.ai will automatically lay out your new items so they always look great.</p><p>We can get to the content later. For now, let's try adding a new slide.</p>",
                    target: "#add-slide-button",
                    position: HelpBubblePositionType.LEFT,
                    targetOffsetY: -10
                });

                this.waitForClick("#add-slide-button").then(() => {
                    prompt.remove();

                    ds.selection.element = null;
                    const addSlideDialog = ShowDialog(AddSlideContainer, { isTour: true });
                    _.delay(() => this.next(), 400);
                });
            },
            () => {
                let prompt;

                function removePrompt() {
                    prompt.remove();
                    $(".search input").off(".tour");
                    $(".contents").off(".tour");
                    $(".template_item").off(".tour");
                }

                // scroll templates automatically to reveal that there are more
                $(".content-wrapper.templates").animate({ scrollTop: 2180 }, 8000, () => {
                    $(".search input").one("keypress.tour click.tour", () => removePrompt());
                    $(".template_item").one("mousedown.tour", () => removePrompt());
                });

                _.delay(() => {
                    prompt = this.createPrompt({
                        title: "Adding Another Slide",
                        body:
                            "<p>Beautiful.ai comes with a huge library of templates that are just as smart and flexible as the one you just used.</p><p>Scroll up and down or use the search box to find a template you'd like to add to your presentation.</p>",
                        target: null,
                        buttons: [
                            {
                                label: "Got it!",
                                action: () => {
                                    prompt.remove();
                                    this.$tourShield.hide();
                                    this.allowKeyEvents();
                                }
                            }
                        ],
                        modal: true
                    });
                }, 500);

                // listen for new slide to be created and set before next step
                ds.selection.presentation.once("slidesUpdated", () => {
                    _.delay(() => this.next(), 500);
                });
            },
            () => {
                this.$tourShield.show();
                this.$tourShield.on("click", () => {
                    this.end({ trigger: "finish" });
                });

                let prompt = this.createPrompt({
                    title: "Congratulations!",
                    body:
                        "<p>Congratulations! You are officially a Beautiful.ai power user. (Seriously, that's it!)</p>",
                    target: null,
                    buttons: [
                        {
                            label: "OK!",
                            action: () => {
                                prompt.remove();
                                this.end({ trigger: "finish" });
                            }
                        }
                    ]
                });

                _.delay(() => {
                    this.createPrompt({
                        body: "Return to your library and view more info about your presentation.",
                        target: "#presentation-menu",
                        style: "simple",
                        targetOffsetX: -14,
                        targetOffsetY: 4,
                        offsetY: 12,
                        position: HelpBubblePositionType.RIGHT
                    });
                }, 500);

                _.delay(() => {
                    const prompt1 = this.createPrompt({
                        body: "View and organize all your slides (or just click space).",
                        target: "#show-slide-grid",
                        style: "simple",
                        targetOffsetX: -6,
                        targetOffsetY: -2,
                        position: HelpBubblePositionType.BELOW
                    });
                }, 1000);

                _.delay(() => {
                    this.createPrompt({
                        body: "Add collaborators to work on your presentation with you.",
                        target: ".add-collaborators",
                        style: "simple",
                        targetOffsetX: -6,
                        targetOffsetY: -2,
                        position: HelpBubblePositionType.LEFT
                    });
                }, 1500);

                $(".quit-tour").remove();
            }
        ];
    }

    end(options = {}) {
        trackActivity("Tour", "Finish", null, null, options);
        trackState({ isInTour: false });

        this.removeShield();
        $(".help_bubble").remove();
        $(".menu-shield").remove();
        $(".quit-tour").remove();

        $(window).off("popstate.tour");

        this.allowKeyEvents();
        app.tour = null;
        setGlobal({
            lastImageSearchTerm: null,
            lastLogoSearchTerm: null,
            lastIconSearchTerm: null,
            lastVideoSearchTerm: null
        });
    }

    createPrompt(options) {
        options.animate = "tour";
        let bubble = new HelpBubble(options);
        $("body").append(bubble.render().$el);

        _.defer(() => {
            bubble.position();
        });

        return bubble;
    }

    waitForClick(target, eventType = "mouseup") {
        return new Promise((resolve, reject) => {
            this.$tourShield[0].addEventListener(
                eventType,
                function handleClick(event) {
                    if (
                        geom.Rect.FromBoundingClientRect($(target)[0].getBoundingClientRect()).contains(
                            event.pageX,
                            event.pageY
                        )
                    ) {
                        event.currentTarget.removeEventListener(event.type, handleClick, true);
                        resolve(event);
                    }
                },
                true
            );
        });
    }
}
