import { VerticalBlockAlignType } from "legacy-common/constants";
import { VerticalAlignType, HorizontalAlignType } from "legacy-common/constants";
import CompareValuesCollection from "./CompareValuesCollection";
import CompareBubbleItem from "./CompareBubbleItem";

export default class CompareBubble extends CompareValuesCollection {
    getChildItemType() {
        return CompareBubbleItem;
    }

    get maxItemCount() {
        return 7;
    }

    get useValueForSize() {
        return this.model.useValueForSize ?? true;
    }

    get circleAlign() {
        return this.model.circleAlign ?? "bottom";
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcHorizontalLayout({
            verticalItemAlign: VerticalAlignType.BOTTOM,
            verticallyAlignText: true
        });
        layouter.alignVertically(VerticalBlockAlignType.MIDDLE);
        layouter.alignHorizontally(HorizontalAlignType.CENTER);

        return { size: layouter.size };
    }
}
