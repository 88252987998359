import React, { Component } from "react";
import { trackActivity } from "js/core/utilities/utilities";
import Spinner from "legacy-js/react/components/Spinner";
import Table from "legacy-js/react/components/Table";
import { FetchStatus } from "legacy-js/react/constants";
import AnalyticsController from "legacy-js/react/views/Analytics/AnalyticsController";
import linkDS from "legacy-js/react/views/PresentationSettings/dataservices/LinkDataService";

const PAGE_SIZE = 20;

class RecentAnalytics extends Component {
    constructor() {
        super();

        this.state = {
            pageOffset: 0,
        };
    }

    handleLoadMore = () => {
        const {
            source_view,
            fetchMore,
        } = this.props;
        const {
            pageOffset,
        } = this.state;
        const newOffset = pageOffset + PAGE_SIZE;
        this.setState({ pageOffset: newOffset });
        const props = {
            presentation_id: this.props.presentation.get("id"),
            page_num: newOffset / PAGE_SIZE,
            source_view,
        };
        trackActivity("PresentationAnalytics", "LoadMore", null, null, props);

        fetchMore(newOffset, PAGE_SIZE);
    };

    getLinkType(row) {
        let linkType = null;
        if (row.linkid) {
            const link = linkDS.getLinkById(this.props.presentation, row.linkid);
            if (link) {
                linkType = link.get("type") === "social" ? link.get("name") : link.get("type");
            } else {
                linkType = "deleted";
            }
        }
        return linkType;
    }

    handleRowClick(row) {
        const {
            source_view,
        } = this.props;
        const props = {
            presentation_id: this.props.presentation.get("id"),
            link_id: row.linkid,
            type: this.getLinkType(row),
            source_view,
        };
        trackActivity("LinkAnalytics", "ShowLinkDetail", null, null, props);
        AnalyticsController.setSelectedLink(row.linkid, row.groupid);
    }

    render() {
        const {
            analytics,
            presentation,
        } = this.props;
        if (analytics.status.type !== FetchStatus.SUCCESS) return null;

        const {
            noRowsMessage,
            allowRowClick,
            dataSource,
            totalCount,
            columnDefs,
        } = this.props;
        const {
            pageOffset,
        } = this.state;

        const columns = columnDefs.map(({ name, width }) => ({ name, width }));
        const cellDefs = columnDefs.map(x => x.cellDef);

        let loadMore;
        if (totalCount > pageOffset + PAGE_SIZE) {
            if (dataSource.status.type == FetchStatus.LOADING_MORE) {
                loadMore = (
                    <div className="load-more with-spinner">
                        <Spinner />
                    </div>
                );
            } else {
                loadMore = (
                    <div className="load-more" onClick={this.handleLoadMore}>
                        Load More
                    </div>
                );
            }
        }

        return (
            <div className="report">
                <Table
                    status={dataSource.status}
                    noRowsMessage={noRowsMessage}
                    columns={columns}
                    rows={dataSource.data.map(row => {
                        const displayData = cellDefs.map(renderCell => renderCell(row, presentation));
                        displayData.isSelected = analytics.sessionId && row.groupid === analytics.sessionId;
                        displayData.originalData = row;
                        return displayData;
                    })}
                    onRowClick={allowRowClick && (row => this.handleRowClick(row))}
                />
                {loadMore}
            </div>
        );
    }
}

export default AnalyticsController.withState(RecentAnalytics);
