import React, { Component } from "react";
import { Menu as MaterialMenu, MenuItem as MaterialMenuItem } from "@material-ui/core";
import styled from "styled-components";
import { ToolTipBadge } from "./ToolTip";

export class Menu extends Component {
    render() {
        return <MaterialMenu {...this.props} />;
    }
}

const StyledMenuItem = styled.div`
    .tooltip-badge {
        margin-left: 5px;
        top: 0px;
    }
`;

export class MenuItem extends Component {
    render() {
        return (
            <StyledMenuItem>
                <MaterialMenuItem {...this.props}>
                    {this.props.children}
                </MaterialMenuItem>
            </StyledMenuItem>
        );
    }
}

const MenuItemWithDetailsContainer = styled.div`
    li > div > div {
        display: block;
    }
`;

export const MenuItemDetails = styled.div`
    font-weight: 500;
    font-size: 12px;
    display: none;
    max-width: 300px;
    white-space: normal;
    color: #444;
`;

export class MenuItemWithDetails extends Component {
    render() {
        return (
            <MenuItemWithDetailsContainer>
                <MaterialMenuItem {...this.props}>
                    <div>
                        {this.props.children}
                    </div>
                </MaterialMenuItem>
            </MenuItemWithDetailsContainer>
        );
    }
}
