import moment from "moment";

export const dummyAnalyticsData = {
    presentation: {
        getSips: function() {
            return [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28"
            ];
        },
        getWorkspaceId() {
            return "personal";
        },
        getAnalytics() {
            return {
                "presentation_id": null,
            };
        },
        async getThumbnailUrl(slideId = null, size = "small", reloadCache = false) {
            return [];
        },
    },
    analytics: {
        viewTimes: [
            {
                groupid: "048990e3-2a8d-45d9-969b-fabdc5a40788",
                totaltime: "13681"
            },
            {
                groupid: "0e1da6ac-3084-4e24-a163-e7d788c0be36",
                totaltime: "466129"
            },
            {
                groupid: "1d1b02f6-b908-4ff1-afb4-8cdff36f76de",
                totaltime: "20793"
            },
            {
                groupid: "2b759e45-033d-484d-87df-b19805f6606c",
                totaltime: "10173814"
            },
            {
                groupid: "2d20567c-fc25-423b-9869-507bd67b1f21",
                totaltime: "10776"
            },
            {
                groupid: "2d4c078b-c0d9-47a4-95a7-b62526d05a31",
                totaltime: "779349"
            },
            {
                groupid: "32748749-171d-4df0-9624-1ea146524cf9",
                totaltime: "484839"
            },
            {
                groupid: "45c25b45-5999-4c18-9ddd-4296d3864eb3",
                totaltime: "460776"
            },
            {
                groupid: "55ffdf3f-7d8b-4683-b03d-72010e602779",
                totaltime: "60070"
            },
            {
                groupid: "60dd5f06-ce9b-4843-9398-f966d2e9582f",
                totaltime: "6183"
            },
            {
                groupid: "7f79eb26-4b0c-4f9c-8b7c-08fd428f74b4",
                totaltime: "31679"
            },
            {
                groupid: "834cbe07-93bb-407d-8d55-3fda618452dc",
                totaltime: "2537719"
            },
            {
                groupid: "85e952ac-bd4d-409f-b302-ca9514634325",
                totaltime: "1930"
            },
            {
                groupid: "8df5ac37-8a57-44f2-b02e-cb2dc376f468",
                totaltime: "3162089"
            },
            {
                groupid: "975cef2f-3d70-41e4-a561-0b4bf03d108e",
                totaltime: "38739"
            },
            {
                groupid: "a124146b-e8f9-4c0d-8713-8db1d3840eca",
                totaltime: "69844"
            },
            {
                groupid: "c21fc2ef-09ff-40c4-83b7-dd53d513e5df",
                totaltime: "6867"
            },
            {
                groupid: "cbb40caf-fd3a-4572-990c-eec7b08ddf1c",
                totaltime: "10145"
            },
            {
                groupid: "d1f02e91-e95e-4878-baef-72ef4d12276e",
                totaltime: "902012"
            },
            {
                groupid: "d86b15ee-20b6-49b2-9044-1ac1ea24a726",
                totaltime: "132045"
            },
            {
                groupid: "dec24bcd-2912-4f7f-bdb7-d05d0d76730c",
                totaltime: "20531"
            },
            {
                groupid: "e390e709-dd02-4679-81e3-73dfeda518b6",
                totaltime: "8724"
            },
            {
                groupid: "e72bf141-5bef-40a1-a1c1-c8f478e0bd8e",
                totaltime: "22846"
            }
        ],
        completion: [
            {
                count: "23",
                action: "player-completed"
            },
            {
                count: "25",
                action: "player-start"
            }
        ],
        viewsPerDay: [],
        downloadsPerDay: [],
        aggregates: {
            totalviews: "33",
            uniqueviewers: "23",
            totaldownloads: "0",
            uniquedownloaders: "0",
        },
        viewsPerSlide: [
            {
                slideid: "1",
                averagetime: "4.91",
                count: "38"
            },
            {
                slideid: "2",
                averagetime: "8.84",
                count: "21"
            },
            {
                slideid: "3",
                averagetime: "25.07",
                count: "16"
            },
            {
                slideid: "4",
                averagetime: "12.51",
                count: "17"
            },
            {
                slideid: "5",
                averagetime: "12.34",
                count: "12"
            },
            {
                slideid: "6",
                averagetime: "11.96",
                count: "13"
            },
            {
                slideid: "7",
                averagetime: "47.82",
                count: "14"
            },
            {
                slideid: "8",
                averagetime: "12.91",
                count: "16"
            },
            {
                slideid: "9",
                averagetime: "12.39",
                count: "17"
            },
            {
                slideid: "10",
                averagetime: "11.61",
                count: "15"
            },
            {
                slideid: "11",
                averagetime: "15.12",
                count: "25"
            },
            {
                slideid: "12",
                averagetime: "11.85",
                count: "33"
            },
            {
                slideid: "13",
                averagetime: "13.10",
                count: "32"
            },
            {
                slideid: "14",
                averagetime: "17.07",
                count: "33"
            },
            {
                slideid: "15",
                averagetime: "39.62",
                count: "35"
            },
            {
                slideid: "16",
                averagetime: "11.42",
                count: "24"
            },
            {
                slideid: "17",
                averagetime: "12.55",
                count: "29"
            },
            {
                slideid: "18",
                averagetime: "15.05",
                count: "29"
            },
            {
                slideid: "19",
                averagetime: "16.44",
                count: "31"
            },
            {
                slideid: "20",
                averagetime: "31.35",
                count: "28"
            },
            {
                slideid: "21",
                averagetime: "7.64",
                count: "30"
            },
            {
                slideid: "22",
                averagetime: "26.88",
                count: "35"
            },
            {
                slideid: "23",
                averagetime: "18.38",
                count: "12"
            },
            {
                slideid: "24",
                averagetime: "22.44",
                count: "12"
            },
            {
                slideid: "25",
                averagetime: "16.51",
                count: "10"
            },
            {
                slideid: "26",
                averagetime: "11.61",
                count: "8"
            },
            {
                slideid: "27",
                averagetime: "15.31",
                count: "2"
            },
            {
                slideid: "28",
                averagetime: "13.74",
                count: "2"
            }
        ],
        views: {
            data: [
                {
                    groupid: "32748749-171d-4df0-9624-1ea146524cf9",
                    username: "john@example.com",
                    time: moment()
                        .subtract(1, "hours")
                        .format(),
                    totaltime: "211663",
                    viewedslides: 28
                },
                {
                    groupid: "e390e709-dd02-4679-81e3-73dfeda518b6",
                    username: "fatima@example.com",
                    time: moment()
                        .subtract(3, "hours")
                        .format(),
                    totaltime: "28724",
                    viewedslides: 21
                },
                {
                    groupid: "e72bf141-5bef-40a1-a1c1-c8f478e0bd8e",
                    username: "anuja@example.com",
                    time: moment()
                        .subtract(22, "hours")
                        .format(),
                    totaltime: "422846",
                    viewedslides: 9
                },
                {
                    groupid: "2d20567c-fc25-423b-9869-507bd67b1f21",
                    username: "diana@example.com",
                    time: moment()
                        .subtract(46, "hours")
                        .format(),
                    totaltime: "410776",
                    viewedslides: 28
                },
                {
                    groupid: "45c25b45-5999-4c18-9ddd-4296d3864eb3",
                    username: "ben@example.com",
                    time: moment()
                        .subtract(55, "hours")
                        .format(),
                    totaltime: "460776",
                    viewedslides: 27
                },
                {
                    groupid: "d1f02e91-e95e-4878-baef-72ef4d12276e",
                    username: "jill@example.com",
                    time: moment()
                        .subtract(74, "hours")
                        .format(),
                    totaltime: "902012",
                    viewedslides: 25
                }
            ],
            status: {
                type: "success"
            }
        },
        status: {
            type: "success"
        }
    }
};
