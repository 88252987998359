import React, { Component } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import { CloudUpload } from "@material-ui/icons";

import getLogger, { LogGroup } from "js/core/logger";
import { loadFileAsArrayBuffer } from "js/core/utilities/promiseHelper";
import { clipboardRead, ClipboardType } from "js/core/utilities/clipboard";
import { Gap10, Gap30 } from "legacy-js/react/components/Gap";
import { ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import * as REGEX from "js/core/utilities/regex";

const logger = getLogger(LogGroup.DATA_SOURCE);

const DragDropContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #11A9E2;
    width: 100%;
    height: 100%;
    padding: 30px 50px;
    margin-bottom: 30px;
    & .label-text {
        font-size: 18px;
        font-weight: 600;
        & .text-blue {
            cursor: pointer;
        }
    }
`;

interface Props {
    firebaseUser: any,
    handleAddedData: Function,
}

export default class ImportCsvPane extends Component<Props> {
    uploadRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.uploadRef = React.createRef<HTMLInputElement>();
    }

    componentDidMount() {
        // @ts-ignore
        window.addEventListener("paste", this.handleClipboardEvent);
    }

    componentWillUnmount() {
        // @ts-ignore
        window.removeEventListener("paste", this.handleClipboardEvent);
    }

    checkFileTypeAndSize(file: File) {
        const supportedFileTypes = ["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        if (!supportedFileTypes.includes(file.type)) {
            throw Error("File format not supported. Only CSV, XLS, or XLSX formats are permitted.");
        }
    }

    clearClipboardTextFormat(text: string) {
        let result = text.replace(REGEX.negativeNumberParanthesisRegex, "-$1$2");

        // Only treat spaces as tabs if there are no tabs present
        if (!result.includes("\t")) {
            result = result.replace(REGEX.spacesRegex, "\t");
        }
        return result;
    }

    parsePastedData = async (text: string) => {
        const clearText = this.clearClipboardTextFormat(text);

        const data = [];
        for (const line of clearText.split("\n")) {
            data.push(line.split("\t").map(w => {
                w = w.trim();
                return w.endsWith("\r") ? w.slice(0, -1) : w;
            }));
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws);

        this.props.handleAddedData(wb);
    }

    parseFileData = async (file: File) => {
        const buffer = await loadFileAsArrayBuffer(file);

        const workbook = XLSX.read(buffer);
        this.props.handleAddedData(workbook);
    }

    handleClipboardEvent = async (event: React.ClipboardEvent) => {
        event.stopPropagation();
        // check if there is valid file pasted
        try {
            if (event.clipboardData?.files.length) {
                return await this.handleFileSelectChange(event, true);
            }
        } catch {
            // check if there is plain text pasted if there is no valid file
        }

        const text: string = await clipboardRead([ClipboardType.TEXT], event);
        if (text) {
            return await this.parsePastedData(text);
        }
    }

    handleFileSelectChange = async (event: React.ChangeEvent | React.DragEvent | React.ClipboardEvent, skipErrorDialog?: boolean) => {
        try {
            // @ts-ignore
            const files = (event.target?.files || event.dataTransfer?.files || event.clipboardData?.files) as FileList;
            const file = files[0];

            this.checkFileTypeAndSize(file);
            await this.parseFileData(file);
        } catch (err) {
            if (skipErrorDialog) throw err;

            logger.error(err, "[ImportCsvPane] handleFileSelectChange() failed");
            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: err.message
            });
        }
    }

    handleMediaOptionClick = (event?: React.DragEvent) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            this.handleFileSelectChange(event);
        } else {
            this.uploadRef.current.click();
        }
    }

    render() {
        return (
            <DragDropContainer
                onDragEnter={e => e.preventDefault()}
                onDragOver={e => e.preventDefault()}
                onDragLeave={e => e.preventDefault()}
                onDrop={e => this.handleMediaOptionClick(e)}
            >
                <input // @ts-ignore
                    ref={this.uploadRef}
                    type="file"
                    style={{ display: "none" }}
                    accept={"csv/*, xls/*, xlsx/*"}
                    onChange={e => this.handleFileSelectChange(e)}
                />
                <CloudUpload style={{ width: 50, height: 50, color: "#11a9e2" }} />
                <Gap30 />
                <span className="label-text">
                    Paste from clipboard, drag and drop or <span className="text-blue" onClick={() => this.handleMediaOptionClick()}>browse files...</span>
                </span>
            </DragDropContainer>
        );
    }
}
