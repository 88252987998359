import { setGlobal, getGlobal } from "reactn";
import Api from "js/core/api";
import { FetchStatus } from "legacy-js/react/constants";
import { ds } from "js/core/models/dataService";
import { trackActivity } from "js/core/utilities/utilities";
import { _ } from "legacy-js/vendor";

class permissionsDS {
    static initializeStore() {
        setGlobal({
            presentationPermissions: {
                status: {
                    type: FetchStatus.UNSET
                },
                permissions: []
            }
        });
    }

    static async getPermissions(presentationId, teamIds, includeMembers = false) {
        const teamPermissionsResponse = await Api.teamPermissions.post({
            resourceId: presentationId,
            resourceType: "PRESENTATION",
            teamIds: Object.values(teamIds),
            type: "get_teams_and_members_with_resource"
        });
        const results = await Api.permissions.get({ id: presentationId });
        //Logic to remove team members with presentation as shared resource from permissions list.
        const filteredPermissions = results.permissions.filter(permission => {
            return (
                includeMembers ||
                (
                    // Show owner permission in permissions list.
                    permission.type === "owner" ||
                    // Only include the member if it's not a member we already have and permissions match
                    !teamPermissionsResponse.body.teams.find(team => {
                        return team.members[permission.id] &&
                            !!team.resourcePermission.read === !!permission.read &&
                            !!team.resourcePermission.write === !!permission.write;
                    })
                )
            );
        });
        //Logic to add team permissions to permission list.
        const permissionsWithTeamPermissions = filteredPermissions.concat(
            teamPermissionsResponse.body.teams
        );

        return permissionsWithTeamPermissions;
    }

    static async fetchPermissions(presentationId) {
        setGlobal({
            presentationPermissions: {
                status: {
                    type: FetchStatus.LOADING
                },
                permissions: []
            }
        });

        const global = getGlobal();
        await ds.teams.loadModels();
        const teamIds = ds.teams.map(team => team.id);

        try {
            const permissionsWithTeamPermissions = await permissionsDS.getPermissions(presentationId, teamIds);

            setGlobal({
                presentationPermissions: {
                    permissions: permissionsWithTeamPermissions,
                    status: {
                        type: FetchStatus.SUCCESS
                    }
                }
            });
        } catch (err) {
            setGlobal({
                presentationPermissions: {
                    status: {
                        type: FetchStatus.ERROR,
                        message: err.message
                    }
                }
            });
        }
    }

    static createPermission(permissions, presentationId, shouldFetch) {
        return Api.permissions
            .post({ id: presentationId }, permissions)
            .then(result => {
                ds.trigger("presentationPermissionsChanged");

                if (shouldFetch) {
                    return permissionsDS.fetchPermissions(presentationId);
                }
            })
            .catch(err => {
                throw err;
            });
    }

    static inviteTeam(teamId, permission = "write", presentationId, shouldFetch) {
        return Api.teamPermissions
            .put({
                teamId,
                resourceIds: [presentationId],
                permission,
                resourceType: "presentations",
                type: "access"
            })
            .then(results => {
                if (shouldFetch) {
                    return permissionsDS.fetchPermissions(presentationId);
                }
            });
    }

    static updatePermissions(permission, permissionUpdate, presentationId) {
        let presentationPermissions = getGlobal().presentationPermissions;

        const props = {
            presentation_id: presentationId,
            old_value: permission.type === "edit" ? "Edit" : "View",
            new_value: permissionUpdate === "write" ? "Edit" : "View",
            recipient_pending: permission.pending || false,
            recipient_email: permission.email
        };

        Api.permissions
            .put(
                { id: presentationId },
                {
                    permission: permissionUpdate,
                    emails: [permission.email]
                }
            )
            .then(results => {
                let permissions = presentationPermissions.permissions;
                results.permissions.forEach(update => {
                    Object.assign(
                        _.find(permissions, { email: update.email }),
                        update
                    );
                });
                setGlobal({
                    presentationPermissions: {
                        ...presentationPermissions,
                        permissions
                    }
                });
                trackActivity("Collab", "AccessChanged", null, null, props);

                ds.trigger("presentationPermissionsChanged");
            });
    }

    static updateTeamPermissions(teamPermission, permissionUpdate, presentationId) {
        let presentationPermissions = getGlobal().presentationPermissions;

        Api.teamPermissions
            .put({
                teamId: teamPermission.id,
                resourceIds: [presentationId],
                permission: permissionUpdate,
                resourceType: "presentations",
                type: "editAccess"
            })
            .then(results => {
                let permissions = presentationPermissions.permissions;
                const updates = permissionUpdate === "read"
                    ? { type: "view" }
                    : { type: "edit" };
                Object.assign(
                    _.find(permissions, { name: teamPermission.name }),
                    updates
                );

                setGlobal({
                    presentationPermissions: {
                        ...presentationPermissions,
                        permissions
                    }
                });
            });
    }

    static deletePermission(email, permToDelete, presentationId = null) {
        let presentationPermissions = getGlobal().presentationPermissions;
        return Api.permissions
            .delete({ id: presentationId }, { ...permToDelete, emails: [email] })
            .then(result => {
                let permissions = presentationPermissions.permissions;
                _.remove(
                    permissions,
                    perm => perm.email == result.permissions[0].email
                );

                setGlobal({
                    presentationPermissions: {
                        ...presentationPermissions,
                        permissions
                    }
                });

                ds.trigger("presentationPermissionsChanged");
            });
    }

    static deleteTeamPermission(teamId, permToDelete, presentationId = null) {
        let presentationPermissions = getGlobal().presentationPermissions;
        return Api.teamPermissions
            .delete({
                ...permToDelete,
                resourceIds: [presentationId],
                resourceType: "presentations",
                teamId
            })
            .then(result => {
                let permissions = presentationPermissions.permissions;
                _.remove(permissions, perm => perm.id === teamId);

                setGlobal({
                    presentationPermissions: {
                        ...presentationPermissions,
                        permissions
                    }
                });
            });
    }
}

export default permissionsDS;
