import React, { Component } from "react";
import { QRCodeSVG } from "qrcode.react";
import styled from "styled-components";
import { Gap10, Gap30 } from "legacy-js/react/components/Gap";
import pusher from "js/core/services/pusher";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import { DialogTitle } from "@material-ui/core";
import { getStaticUrl } from "legacy-js/config";
import { themeColors } from "legacy-js/react/sharedStyles";
import { getCode } from "js/core/utilities/utilities";

const Container = styled.div`
  padding: 50px;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #111;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  a {
    color: ${themeColors.ui_blue};
  }
`;

export class MeetingRoom extends Component {
    constructor() {
        super();

        this.roomID = new URLSearchParams(window.location.search).get("roomID") ?? getCode(4);
    }

    componentDidMount() {
        pusher.waitForEvent(`presence-remote-control-${this.roomID}`, "client-registerLeader")
            .then(({ presentationId }) => {
                window.location.href = `${window.location.origin}/player/${presentationId}?roomID=${this.roomID}`;
            });
    }

    render() {
        const url = `${window.location.origin}/login?roomID=${this.roomID}`;
        return (
            <Container>
                <img src={getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg")} />
                <Gap30 />
                <h2>To start a presentation on this device, scan this QR Code on your phone</h2>
                <Gap30 />
                <div>
                    <QRCodeSVG value={url} bgColor="white" size={300} />
                </div>
                <Gap30 />
                <h2>or go to</h2>
                <h2>
                    <a href={url} target="_blank">{url}</a>
                </h2>
            </Container>
        );
    }
}

// Dialog used by Control From Phone button in PlayerControls
export class RemoteControlDialog extends Component {
    constructor() {
        super();

        this.roomID = getCode(4);
    }

    componentDidMount() {
        const { onRemoteControlLeaderRegistered, closeDialog } = this.props;

        pusher.waitForEvent(`presence-remote-control-${this.roomID}`, "client-registerLeader")
            .then(() => {
                onRemoteControlLeaderRegistered(this.roomID);
                closeDialog();
            });
    }

    render() {
        const { presentationId, currentSlideIndex, closeDialog } = this.props;

        const url = `${window.location.origin}/player/${presentationId}/${currentSlideIndex}?roomID=${this.roomID}&remoteRole=leader`;

        return (
            <BeautifulDialog closeDialog={closeDialog}>
                <DialogTitle>Control this presentation from your phone or other device</DialogTitle>
                <DialogContent>
                    <Container>
                        <h2>Scan this QR Code on your Phone</h2>
                        <div>
                            <QRCodeSVG value={url} />
                        </div>
                        <Gap30 />
                        or go to this url
                        <Gap10 />
                        <div>
                            <a href={url} target="_blank">{url}</a>
                        </div>
                        <Gap30 />
                        or enter this code
                        <h2>{this.roomID}</h2>
                    </Container>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}
