import React, { Component } from "reactn";
import { getGlobal, setGlobal } from "reactn";
import { SearchAssets } from "js/core/utilities/searchAssets";
import { SearchInput } from "legacy-js/react/components";
import { _ } from "legacy-js/vendor";
import { loadImage } from "js/core/utilities/promiseHelper";
import {
    UIContainerContext,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "legacy-js/react/components/UiComponents";
import Loadable from "../../../components/Loadable";
import LoadingOverlay from "../../../components/LoadingOverlay";
import ImageThumbnailGrid from "legacy-js/react/views/AddAssets/Components/ImageThumbnailGrid";
import StockMediaCategoryImage from "legacy-js/react/views/AddAssets/Components/StockMediaCategoryImage";
import { VideoPreview } from "../Components/VideoPreview";
import { NoMatchNotice } from "legacy-js/react/components/Notice";

const keyIndex = "previewUrl";

export class StockVideosPane extends Component {
    static contextType = UIContainerContext;

    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            searchResults: null,
            searchAssetsUtil: new SearchAssets(),
            stockCategory: null,
            muted: true,
        };
        this.removedKeys = [];
        this.lookupItemRefs = {};
        this.searchInputRef = React.createRef();
    }

    componentDidMount = async () => {
        const {
            prefilledSearchQuery,
            onPrefilledSearchQueryComplete
        } = this.props;
        const prefill = prefilledSearchQuery || getGlobal().lastVideoSearchTerm;
        this.setState({ autoSearch: prefill });
        prefill && (await this.doSearch(prefill));
        onPrefilledSearchQueryComplete();
    };

    componentDidUpdate = async prevProps => {
        if (
            this.props.prefilledSearchQuery &&
            prevProps.prefilledSearchQuery !== this.props.prefilledSearchQuery
        ) {
            this.setState({ autoSearch: this.props.prefilledSearchQuery });
            await this.doSearch(this.props.prefilledSearchQuery);
        }
    };

    toggleMuted = () => {
        this.setState({ muted: !this.state.muted });
    }

    hidePlaybacks = itemExcluded => {
        this.lookupItemRefs = this.lookupItemRefs || {};
        Object.entries(this.lookupItemRefs)
            .filter(([key, value]) => !!value?.current && key !== itemExcluded[keyIndex])
            .forEach(([key, value]) => value.current.hidePlayback());
    }

    doSearch = async (searchTerm, isTour = false) => {
        if (searchTerm === "") {
            this.setState({ searchResults: null });
            return;
        }
        this.setState({
            isLoading: true,
            isSearching: true,
            searchResults: []
        });
        this.lookupItemRefs = {};
        setGlobal({ lastVideoSearchTerm: searchTerm });
        const response = await this.state.searchAssetsUtil.searchVideos(
            searchTerm.toLowerCase(),
            isTour
        );
        if (response.complete && response.results.length) {
            await Promise.all(
                response.results.map(result => loadImage(result.thumbnailUrl))
            );

            this.setState({
                searchResults: response.results.filter(data => !this.removedKeys.includes(data[keyIndex])),
                isLoading: false,
                isSearching: false
            });
            !isTour && this.checkContentHeight();
        } else {
            this.setState({
                searchResults: [],
                isLoading: false,
                isSearching: false
            });
        }
    };

    getNextPage = async () => {
        this.setState({ isLoading: true });
        const response = await this.state.searchAssetsUtil.getNextPage();
        if (response.complete && response.results.length) {
            await Promise.all(
                response.results.map(result => loadImage(result.thumbnailUrl))
            );
            this.setState(prevState => {
                if (
                    prevState.searchResults &&
                    prevState.searchResults.length > 1
                ) {
                    const searchResults = [
                        ...prevState.searchResults,
                        ...response.results
                    ];
                    const uniqueResults = _.uniqBy(
                        searchResults,
                        e => e.previewUrl
                    );
                    return { searchResults: uniqueResults, isLoading: false };
                }
            });
            this.checkContentHeight();
        }
    };

    removeItem(item) {
        const key = item[keyIndex];
        const {
            searchResults,
        } = this.state;

        const index = searchResults.findIndex(data => data[keyIndex] === key);
        index > -1 && searchResults.splice(index, 1);
        this.lookupItemRefs[key] && delete this.lookupItemRefs[key];
        this.removedKeys.push(key);
        this.setState({
            searchResults,
        });
    }

    checkContentHeight = () => {
        // if ((this.scrollerRef.current.offsetHeight < this.wrapperRef.current.offsetHeight) && this.state.searchResults.length) {
        //     this.getNextPage();
        // }
    };

    checkScroll = e => {
        const scrollDistance =
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
        if (scrollDistance <= e.currentTarget.clientHeight * 2) {
            if (!this.state.isLoading) {
                const debouncedLoadPage = _.debounce(
                    () => {
                        this.getNextPage();
                    },
                    3000,
                    { leading: true }
                );
                debouncedLoadPage();
            }
        }
    };

    renderSearchResults = () => {
        const { searchResults, muted, isSearching } = this.state;
        const { addAssetCallback, handleConfirm } = this.props;

        let results = searchResults
            .map((data, idx) => {
                // Get the ref, or create it if it doesn't exist
                const key = data[keyIndex];
                let ref = this.lookupItemRefs[key];
                if (!ref) {
                    ref = React.createRef();
                    this.lookupItemRefs[key] = ref;
                }
                return (
                    <VideoPreview
                        ref={ref}
                        addAssetCallback={addAssetCallback}
                        key={key}
                        handleConfirm={handleConfirm}
                        videoProps={data}
                        muted={muted}
                        toggleMuted={this.toggleMuted}
                        hideAllPlaybacks={this.hidePlaybacks}
                        removeSelf={() => this.removeItem(data)}
                        backgroundVideoOnly={true}
                    />
                );
            });

        return (
            <>
                {isSearching && <LoadingOverlay />}
                <Loadable isLoading={isSearching}>
                    {
                        !!results.length &&
                        <ImageThumbnailGrid>
                            {results}
                        </ImageThumbnailGrid>
                    }
                    {
                        !results.length &&
                        <NoMatchNotice />
                    }
                </Loadable>
            </>
        );
    };

    handleClearSearch() {
        this.searchInputRef.current.clearSearch();
    }

    renderPopularSearches = () => {
        const stockInfo = [
            { title: "Bokeh", urlFragment: "video-categories/bokeh.png" },
            { title: "City", urlFragment: "video-categories/city.png" },
            { title: "Data", urlFragment: "video-categories/data.png" },
            { title: "People", urlFragment: "video-categories/people.png" },
            { title: "Meeting", urlFragment: "video-categories/meeting.png" },
            { title: "Minimal", urlFragment: "video-categories/minimal.png" },
            { title: "Nature", urlFragment: "video-categories/nature.png" },
            { title: "Office", urlFragment: "video-categories/office.png" },
            { title: "Science", urlFragment: "video-categories/science.png" },
            { title: "Sky", urlFragment: "video-categories/sky.png" },
            { title: "Slow Motion", urlFragment: "video-categories/slow-motion.png" },
            { title: "Speed", urlFragment: "video-categories/speed.png" },
            { title: "Sunlight", urlFragment: "video-categories/sunlight.png" },
            { title: "Technology", urlFragment: "video-categories/technology.png" },
            { title: "Time Lapse", urlFragment: "video-categories/time-lapse.png" },
            { title: "Water", urlFragment: "video-categories/water.png" },
        ];
        return (
            <div className="stock-media-popular-search-container">
                {stockInfo.map(photo => (
                    <StockMediaCategoryImage
                        key={photo.title}
                        title={photo.title}
                        urlFragment={photo.urlFragment}
                        handleClick={async () => {
                            this.setState({
                                stockCategory: photo.title.toLowerCase()
                            });
                            await this.doSearch(photo.title.toLowerCase());
                        }}
                    />
                ))}
            </div>
        );
    };

    render() {
        const { searchResults, stockCategory, autoSearch } = this.state;
        const inputFocused = !this.context;

        return (
            <UIPane>
                <UIPaneHeader>
                    <SearchInput
                        ref={this.searchInputRef}
                        handleSubmit={searchTerm =>
                            this.doSearch(searchTerm)
                        }
                        placeholder="Search for videos..."
                        handleClearSearch={() => {
                            setGlobal({ lastVideoSearchTerm: null });
                            this.setState({
                                searchResults: null,
                                stockCategory: null,
                                isSearching: false
                            });
                        }}
                        focus={inputFocused}
                        prefilledSearch={stockCategory || autoSearch}
                    />
                </UIPaneHeader>
                <UIPaneContents
                    style={{ paddingTop: 20, position: "relative" }}
                    onScroll={e => {
                        searchResults && this.checkScroll(e);
                    }}
                >
                    {
                        searchResults
                            ? this.renderSearchResults()
                            : this.renderPopularSearches()
                    }
                </UIPaneContents>
            </UIPane>
        );
    }
}
