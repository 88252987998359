import moment from "moment-timezone";

export function guessUserCountry() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    for (const country of moment.tz.countries()) {
        const timeZones = moment.tz.zonesForCountry(country);
        if (timeZones.includes(userTimeZone)) {
            return country.toUpperCase();
        }
    }

    return "US";
}
