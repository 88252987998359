import React, { Component } from "react";
import styled from "styled-components";
import { TextField, Button } from "@material-ui/core";

interface Props {
    handleAddedVideoUrl: (url: string) => void;
}

interface State {
    url: string;
    error: string;
}

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #A9A9A9;
    padding: 30px 50px;
    gap: 20px;
    margin-bottom: 30px;
    .error-message {
        font-size: 14px;
        font-weight: 400;
        color: orangered;
    }
`;

export default class HostedVideoPicker extends Component<Props, State> {
    state = { url: null, error: null } as State;

    handleVideoUrl = event => {
        const url = event.target.value;
        const error = event.target.validationMessage;

        this.setState({ url, error });
    }

    handleAddVideo = () => {
        const { url, error } = this.state;

        if (!error) {
            this.props.handleAddedVideoUrl(url);
        }
    }

    render() {
        const { url, error } = this.state;

        return (
            <Container>
                <TextField
                    type="url"
                    inputProps={{
                        pattern: "https://.*"
                    }}
                    required
                    placeholder="Paste the url of a Vimeo or YouTube video..."
                    value={url}
                    fullWidth
                    autoFocus
                    variant="outlined"
                    onChange={this.handleVideoUrl}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {error && <span className="error-message">{error}</span>}
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!url || !!error}
                    onClick={this.handleAddVideo}
                >Add Video</Button>
            </Container>
        );
    }
}
