import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.MIGRATION);

class MigrationsHelper {
    static migrate(instance, lowerBoundInclusiveVersion, upperBoundInclusiveVersion) {
        if (
            !lowerBoundInclusiveVersion ||
            !upperBoundInclusiveVersion ||
            lowerBoundInclusiveVersion < 4 ||
            lowerBoundInclusiveVersion > upperBoundInclusiveVersion
        ) {
            logger.warn(`${instance.type ?? instance.constructor.name} invalid migration bounds ${lowerBoundInclusiveVersion}-${upperBoundInclusiveVersion}`, { lowerBoundInclusiveVersion, upperBoundInclusiveVersion });
            return;
        }

        for (let i = lowerBoundInclusiveVersion + 0.01; i <= upperBoundInclusiveVersion; i = Math.round(i * 100) / 100 + 0.01) {
            const methodName = Number.isInteger(i)
                ? `_migrate_${i}`
                : `_migrate_${i.toFixed(2)}`.replace(/\./g, "_");
            const migrateMethod = instance[methodName];
            if (migrateMethod) {
                migrateMethod.call(instance);
            }
        }
    }
}

export { MigrationsHelper };
