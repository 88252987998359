import { ElementOptionsMenu } from "../../BaseElementEditor";
import { controls } from "legacy-js/editor/ui";
import { CollectionItemElementSelection } from "../../CollectionElementEditor";
import { AnnotationLayer } from "../../../elements/elements/AnnotationLayer";
import { PositionType } from "legacy-common/constants";
import { renderReactDialog } from "legacy-js/react/renderReactRoot";
import { VideoRecorder } from "./VideoOverlayRecorder";

const VideoOverlaySelection = CollectionItemElementSelection.extend({
    showDragDropTarget: false,

    getOffset() {
        return 10;
    },

    canDrag() {
        return true;
    },

    canDelete() {
        return true;
    },

    onDeleteItem() {
        this.element.parentElement.model.videoOverlay = null;
        this.element.parentElement.canvas.updateCanvasModel();
    },

    renderControls() {
        this.addControl({
            type: controls.BUTTON,
            icon: "video_camera_front",
            label: "Add Recording",
            callback: () => this.addRecording()
        });

        // this.$el.append(controls.createButton(this, {
        //     icon: "video_camera_front",
        //     callback: () => this.addRecording()
        // }));
    },

    addRecording() {
        this.selectionLayer.hideWidgets();
        renderReactDialog(VideoRecorder, {
            element: this.element,
            onClose: () => {
                this.selectionLayer.showWidgets();
            }
        });
    },

    setupGridDragging: function() {
        if (this.canDrag()) {
            let dragProps = {
                dragItem: this.element,
                containerElement: this.element.parentElement
            };

            let constraints = this.element.parentElement.bounds.clone();
            constraints.width -= this.element.calculatedProps.size.width;
            constraints.height -= this.element.calculatedProps.size.height;

            this.$el.makeDraggable({
                element: dragProps.dragItem,
                axis: this.getDragAxis(),
                dragDistance: 5,
                constrainDrag: constraints,
                check: event => this.onCheckDrag(event),
                start: (event, axis) => this.onStartDrag(event, dragProps, axis),
                drag: (event, position) => this.onDrag(event, position, dragProps),
                stop: (event, position) => this.onStopDrag(event, position, dragProps),
            });
        }
    },

    onDrag: function(event, position, dragProps) {
        if (!this.canvas.layouter.isGenerating) {
            const containerElement = this.element.parentElement;

            const dragPosition = position.elementPosition;
            this.element.dragWidgetPosition = position.elementPosition;
            this.element.model.x = Math.clamp(dragPosition.x / containerElement.canvasBounds.width, 0, 1);
            this.element.model.y = Math.clamp(dragPosition.y / containerElement.canvasBounds.height, 0, 1);

            this.element.findClosestOfType(AnnotationLayer).refreshElement();
        }
    },

});

const VideoOverlayOptionsMenu = ElementOptionsMenu.extend({
    renderControls() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Position",
            value: this.element.getPosition(),
            items: [{
                value: PositionType.BOTTOM_RIGHT, label: "Bottom Right"
            }, {
                value: PositionType.BOTTOM_LEFT, label: "Bottom Left"
            }, {
                value: PositionType.TOP_LEFT, label: "Top Left"
            }, {
                value: PositionType.TOP_RIGHT, label: "Top Right"
            }],
            callback: pos => {
                this.element.setPosition(pos, true);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Size",
            value: this.element.size,
            items: [{
                value: 150, label: "Small"
            }, {
                value: 200, label: "Medium"
            }, {
                value: 250, label: "Large"
            }],
            callback: size => {
                let pos = this.element.getPosition();
                this.element.model.size = size;
                this.element.setPosition(pos, false);
            }
        });
    }

});

export const editors = {
    VideoOverlaySelection,
    VideoOverlayOptionsMenu
};
