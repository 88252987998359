import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle, DialogContent } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";

import { getStaticUrl } from "legacy-js/config";
import { ds } from "js/core/models/dataService";
import AppController from "legacy-js/core/AppController";
import { withFirebaseAuth } from "legacy-js/react/views/Auth/FirebaseAuthContext";
import { withFirebaseUser } from "legacy-js/react/views/Auth/FirebaseUserContext";
import { Divider, BlueButton } from "legacy-js/react/components/UiComponents";
import { Gap30 } from "legacy-js/react/components/Gap";
import ProBadge from "legacy-js/react/components/ProBadge";
import { BeautifulDialog, ShowUpgradeDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import {
    FileSource, FileType, validateFileTypeAndSize, getAllowedFileSources
} from "legacy-js/core/utilities/fileSourcesAndTypes";

import LocalFilePicker from "./pickers/LocalFilePicker";
import GoogleDrivePicker from "./pickers/GoogleDrivePicker";
import OneDrivePicker from "./pickers/OneDrivePicker";
import DropboxPicker from "./pickers/DropboxPicker";
import BoxPicker from "./pickers/BoxPicker";
import BynderPicker from "./pickers/BynderPicker";
import HostedVideoPicker from "./pickers/HostedVideoPicker";

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px 20px 0 20px;
    gap: 10px;
    .title-text {
        font-weight: 600;
        font-size: 23px;
        line-height: 120%;
    }
    .description-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
    }
`;

const ContentContainer = styled.div`
    overflow: hidden;
    display: flex;
    padding: 0 20px 20px 20px;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const RightContainer = styled.div`
    width: 100%;
    min-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
`;

const LeftTab = styled.button<{ selected?: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 225px;
    height: 46px;
    border: none;
    padding: 10px 15px;
    background-color: ${p => p.selected ? "#DBE9F0" : "white"};
    cursor: pointer;
    &:hover {
        background-color: #f3fbff;
    }
    span {
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-weight: 600;
        color: #222;
        padding-left: 10px;
    }
`;

const UpgradeTabContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #A9A9A9;
    padding: 30px 50px;
    margin-bottom: 30px;
    .icons-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
    }
    .label-text {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
`;

const ErrorMessage = styled.span<{ isWarning?: boolean }>`
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: ${props => props.isWarning ? "#A9A9A9" : "orangered"};
    margin-top: -20px;
`;

interface Props {
    auth: any;
    firebaseUser: any;
    title?: string;
    description?: string;
    fileTypes?: FileType[];
    closeOnSuccess?: boolean;
    closeDialog: () => void;
    onSuccess: (fileOrVideoUrl: File | string, fileSource?: FileSource) => void;
}

interface State {
    selectedTab: FileSource | "upgrade";
    errorMessage: string;
}

class ImportFileDialog extends Component<Props, State> {
    state = {
        selectedTab: FileSource.Local,
        errorMessage: "",
    } as State

    handleImportedFile = (file: File) => {
        const { fileTypes, closeOnSuccess, onSuccess, closeDialog } = this.props;
        const { selectedTab: fileSource } = this.state;

        const errorMessage = validateFileTypeAndSize(file, fileTypes);
        if (errorMessage) {
            this.setState({ errorMessage });
        } else {
            onSuccess(file, fileSource as FileSource);
            if (closeOnSuccess) {
                closeDialog && closeDialog();
            }
        }
    }

    handleAddedVideoUrl = (url: string) => {
        const { closeOnSuccess, onSuccess, closeDialog } = this.props;
        onSuccess(url);
        if (closeOnSuccess) {
            closeDialog && closeDialog();
        }
    }

    handleTabChange = (selectedTab: State["selectedTab"]) => {
        this.setState({ selectedTab });
    }

    handleClose = () => {
        this.props.closeDialog();
    }

    renderUpgradeTab() {
        return (
            <UpgradeTabContent>
                <div className="icons-list">
                    <img width={50} height={50} src={getStaticUrl("/images/file_hosting/bynder-icon.svg")} />
                    <img width={50} height={50} src={getStaticUrl("/images/file_hosting/google-drive-icon.svg")} />
                    <img width={50} height={50} src={getStaticUrl("/images/file_hosting/box.svg")} />
                </div>
                <Gap30 />
                <span className="label-text">Upgrade to our Team Plan to import assets directly from your preferred asset management app.</span>
                <Gap30 /> {/* @ts-ignore */}
                <BlueButton onClick={(e: any) => {
                    ShowUpgradeDialog({
                        type: UpgradePlanDialogType.UPGRADE_PLAN_TO_TEAM,
                        analytics: { cta: "AssetIntegrations" },
                        workspaceId: AppController.workspaceId
                    });
                }}>Get Started</BlueButton>
            </UpgradeTabContent>
        );
    }

    renderSelectedTab() {
        const { auth, firebaseUser, fileTypes } = this.props;
        const { selectedTab } = this.state;

        if (selectedTab === FileSource.Local) {
            return <LocalFilePicker handleImportedFile={this.handleImportedFile} fileTypes={fileTypes} />;
        } else if (selectedTab === FileSource.Google) {
            return <GoogleDrivePicker handleImportedFile={this.handleImportedFile} fileTypes={fileTypes} auth={auth} firebaseUser={firebaseUser} />;
        } else if (selectedTab === FileSource.OneDrive) {
            return <OneDrivePicker handleImportedFile={this.handleImportedFile} fileTypes={fileTypes} />;
        } else if (selectedTab === FileSource.Dropbox) {
            return <DropboxPicker handleImportedFile={this.handleImportedFile} fileTypes={fileTypes} />;
        } else if (selectedTab === FileSource.Box) {
            return <BoxPicker handleImportedFile={this.handleImportedFile} fileTypes={fileTypes} />;
        } else if (selectedTab === FileSource.Bynder) {
            return <BynderPicker handleImportedFile={this.handleImportedFile} fileTypes={fileTypes} />;
        } else if (selectedTab === FileSource.Vimeo || selectedTab === FileSource.Youtube) {
            return <HostedVideoPicker handleAddedVideoUrl={this.handleAddedVideoUrl} />;
        } else if (selectedTab === "upgrade") {
            return this.renderUpgradeTab();
        }
    }

    render() {
        const { title, description, fileTypes, closeDialog } = this.props;
        const { selectedTab, errorMessage } = this.state;

        const allowedFileSources = fileTypes.map(fileType => getAllowedFileSources(fileType)).flat();
        const isVideoOnly = fileTypes.length === 1 && fileTypes.includes(FileType.Video);
        const showUpgradeTab = !allowedFileSources.some(source => [FileSource.Google, FileSource.OneDrive].includes(source));

        return (
            <BeautifulDialog maxWidth="md" closeButton closeDialog={closeDialog}>
                <DialogTitle>
                    <TitleContainer>
                        <span className="title-text">{title || "Import File"}</span>
                        {description && <span className="description-text">{description}</span>}
                    </TitleContainer>
                </DialogTitle>
                <DialogContent>
                    <ContentContainer>
                        <LeftContainer>
                            <LeftTab selected={selectedTab === FileSource.Local} onClick={() => this.handleTabChange(FileSource.Local)}>
                                <CloudUpload style={{ width: 24, height: 24, color: "#11a9e2" }} />
                                <span>Upload a file</span>
                            </LeftTab> {/* @ts-ignore() */}
                            <Divider margin={"10px"} />
                            {(allowedFileSources.includes(FileSource.Vimeo) || allowedFileSources.includes(FileSource.Youtube)) && isVideoOnly && (
                                <>
                                    <LeftTab selected={selectedTab === FileSource.Vimeo} onClick={() => this.handleTabChange(FileSource.Vimeo)}>
                                        <img src={getStaticUrl("/images/file_hosting/vimeo-icon.svg")} />
                                        <span>Vimeo</span>
                                    </LeftTab>
                                    <LeftTab selected={selectedTab === FileSource.Youtube} onClick={() => this.handleTabChange(FileSource.Youtube)}>
                                        <img src={getStaticUrl("/images/file_hosting/youtube-icon.svg")} />
                                        <span>Youtube</span>
                                    </LeftTab> {/* @ts-ignore() */}
                                    <Divider margin={"10px"} />
                                </>
                            )}

                            <LeftTab selected={selectedTab === FileSource.Dropbox} onClick={() => this.handleTabChange(FileSource.Dropbox)}>
                                <img src={getStaticUrl("/images/data-linking/dropbox.svg")} />
                                <span>Dropbox</span>
                            </LeftTab>
                            {allowedFileSources.includes(FileSource.Google) && (
                                <LeftTab selected={selectedTab === FileSource.Google} onClick={() => this.handleTabChange(FileSource.Google)}>
                                    <img src={getStaticUrl("/images/file_hosting/google-drive-icon.svg")} />
                                    <span>Google Drive</span>
                                </LeftTab>
                            )}
                            {allowedFileSources.includes(FileSource.OneDrive) && (
                                <LeftTab selected={selectedTab === FileSource.OneDrive} onClick={() => this.handleTabChange(FileSource.OneDrive)}>
                                    <img src={getStaticUrl("/images/file_hosting/onedrive.svg")} />
                                    <span>OneDrive</span>
                                </LeftTab>
                            )}
                            {allowedFileSources.includes(FileSource.Box) && (
                                <LeftTab selected={selectedTab === FileSource.Box} onClick={() => this.handleTabChange(FileSource.Box)}>
                                    <img src={getStaticUrl("/images/file_hosting/box.svg")} />
                                    <span>Box</span>
                                </LeftTab>
                            )}
                            {allowedFileSources.includes(FileSource.Bynder) && (
                                <LeftTab selected={selectedTab === FileSource.Bynder} onClick={() => this.handleTabChange(FileSource.Bynder)}>
                                    <img src={getStaticUrl("/images/file_hosting/bynder-icon.svg")} />
                                    <span>Bynder</span>
                                </LeftTab>
                            )}
                            {showUpgradeTab && (
                                <LeftTab selected={selectedTab === "upgrade"} onClick={() => this.handleTabChange("upgrade")}>
                                    <span>More Options...</span>
                                    <ProBadge
                                        show
                                        upgradeType={UpgradePlanDialogType.UPGRADE_PLAN_TO_TEAM}
                                        analytics={{
                                            cta: "AssetIntegrations",
                                            ...ds.selection?.presentation?.getAnalytics()
                                        }}
                                        workspaceId={AppController.workspaceId}
                                    />
                                </LeftTab>
                            )}
                        </LeftContainer>
                        <RightContainer>
                            {this.renderSelectedTab()}
                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                            {!errorMessage && <ErrorMessage isWarning>NOTE: Max file size for images is 50MB and for videos is 500MB</ErrorMessage>}
                        </RightContainer>
                    </ContentContainer>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}

export default withFirebaseAuth(withFirebaseUser(ImportFileDialog)) as React.ForwardRefExoticComponent<Omit<Props, "auth" | "firebaseUser">>;
