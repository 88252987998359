import React from "react";
import { Icon, Tooltip } from "@material-ui/core";
import { _ } from "legacy-js/vendor";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { BlueSwitch } from "legacy-js/react/components/Switch";

export const ToggleAllowCommenting = props => {
    const {
        allowCommenting,
        onToggle,
        color = "#333"
    } = props;

    return (
        <FlexBox left middle>
            <BlueSwitch
                label="Allow commenting"
                checked={allowCommenting}
                onChange={onToggle}
            />
            <Tooltip
                title={(
                    <div>Viewers will be able to view and <br /> add comments in the player.</div>
                )}
                placement="top"
            >
                <Icon style={{ color }}>info</Icon>
            </Tooltip>
        </FlexBox>
    );
};
