import { _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { SVGRectElement } from "../base/SVGElement";

class SliceChart extends CollectionElement {
    getChildItemType() {
        return SliceChartItem;
    }

    get defaultItemData() {
        return {
            width: 1
        };
    }

    get maxItemCount() {
        return 8;
    }

    _calcProps(props, options) {
        let { size, children } = props;
        this.availableWidth = size.width - (this.itemElements.length - 1) * this.styles.hGap;
        this.totalSliceWidth = _.sumBy(this.itemElements, item => item.model.width);
        this.minTextScale = this.isAnimating ? this.animationTextScale : 1;

        let maxHeight = 0;
        for (let item of this.itemElements) {
            let itemWidth = this.getSliceWidth(item.model.width);
            let itemProps = item.calcProps(new geom.Size(itemWidth, size.height));
            maxHeight = Math.max(maxHeight, itemProps.size.height);
        }
        if (!this.isAnimating && this.minTextScale < 1) {
            maxHeight = 0;
            for (let item of this.itemElements) {
                let itemWidth = this.getSliceWidth(item.model.width);
                let itemProps = item.calcProps(new geom.Size(itemWidth, size.height));
                maxHeight = Math.max(maxHeight, itemProps.size.height);
            }
        }
        let offsetY = size.height / 2 - maxHeight / 2;
        let offsetX = 0;
        for (let item of this.itemElements) {
            item.calculatedProps.bounds = new geom.Rect(offsetX, offsetY, item.calculatedProps.size);
            offsetX += item.calculatedProps.bounds.width + this.styles.hGap;
        }

        props.isFit = this.itemCount <= this.styles.maxItemCount;

        return { size };
    }

    getSliceWidth(gridWidth) {
        return gridWidth / this.totalSliceWidth * this.availableWidth;
    }

    _migrate_9() {
        for (let item of this.model.items) {
            item.title = item.label;
            item.body = item.description;
            delete item.label;
            delete item.description;
        }
    }
}

class SliceChartItem extends CollectionItemElement {
    static get schema() {
        return {
            width: 1
        };
    }

    get selectionPadding() {
        return { left: 0, right: 0, top: 0, bottom: 20 };
    }

    _build() {
        this.shapeContainer = this.addElement("shapeContainer", () => SliceChartShape);
        this.description = this.addElement("body", () => TextElement, {
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        let { size, children } = props;

        const shapeWidth = this.isAnimating ? size.width * this.animationState.value : size.width;
        const shapeProps = this.shapeContainer.calcProps(new geom.Size(shapeWidth, this.styles.sliceHeight));
        shapeProps.bounds = new geom.Rect(0, 0, shapeProps.size);

        const descriptionProps = this.description.calcProps(size, options);
        descriptionProps.bounds = new geom.Rect(0, this.styles.sliceHeight, size.width, descriptionProps.size.height);

        size = new geom.Size(size.width, shapeProps.size.height + descriptionProps.size.height);
        return { size };
    }

    get animationElementName() {
        return `Slice #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            prepare: () => {
                this.shapeContainer.label.animationState.fadeInProgress = 0;
                this.description.animationState.fadeInProgress = 0;
                this.animationState.value = 0;
                this.parentElement.animationTextScale = this.parentElement.minTextScale;
            },
            onBeforeAnimationFrame: progress => {
                this.shapeContainer.label.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.description.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.animationState.value = progress;
                return this;
            }
        }];
    }
}

class SliceChartShape extends BaseElement {
    get canRollover() {
        return false;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGRectElement);
        this.label = this.addElement("title", () => TextElement, {
            constrainSelectionBounds: true,
            autoHeight: true,
            scaleTextToFit: this.isAnimating ? false : true,
            allowStyling: true
        });
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const shapeProps = this.shape.calcProps(size);
        shapeProps.bounds = new geom.Rect(0, 0, size);

        const sliceChart = this.getRootElement();
        const labelProps = this.label.calcProps(size, { forceTextScale: sliceChart.minTextScale });
        labelProps.bounds = new geom.Rect(0, size.height / 2 - labelProps.size.height / 2, size.width, labelProps.size.height);

        sliceChart.minTextScale = Math.min(this.label.textScale, sliceChart.minTextScale);

        return { size };
    }

    getBackgroundColor(forElement) {
        if (forElement === this.label) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }
}

export const elements = {
    SliceChart
};
