import React from "react";
import styled from "styled-components";

import { PlayArrow as PlayArrowIcon } from "@material-ui/icons";

import { stopPropagation } from "../helpers/stopPropagation";

const Container = styled.div`
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    //background-color: #222222c2;
    backdrop-filter: blur(10px);
    z-index: 999;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
`;

const PlayButton = styled(PlayArrowIcon)`
    &&& {
        color: #ffffff;
        cursor: pointer;
        font-size: ${({ large }) => large ? "280" : "100"}px;
        background: black;
        border-radius: 50%;
        padding: 30px;

        &:hover {
            background: #11a9e2;
        }
    }
`;

const StartPage = ({ onPlayButtonClick, showPlayButton, isMobileOrTablet }) => (
    <Container onClick={event => stopPropagation(event, onPlayButtonClick)} large={isMobileOrTablet}>
        {showPlayButton && <PlayButton />}
    </Container>
);

export default StartPage;
