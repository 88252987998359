import * as geom from "js/core/utilities/geom";

import { TextElement } from "../../base/TextElement";
import { OrgChartNode } from "./OrgChartNode";

export class LabelOrgChartNode extends OrgChartNode {
    _build() {
        this.text = this.addElement("title", () => TextElement, {
            canSelect: false,
            canRollover: false,
            autoHeight: true,
            placeholder: "Click to edit"
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, size.height / 2 - textProps.size.height / 2, textProps.size);

        return { size: new geom.Size(size.width, textProps.size.height) };
    }
}
