import moment from "moment";
import { trim } from "lodash";

import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { calculateTimeElapsed, calculateTimeRemaining, prefixZeros } from "js/core/utilities/utilities";

import { TextElement } from "../base/TextElement";
import { BaseElement } from "../base/BaseElement";

// move to styles?
const PREFERRED_GAP = 20;
const BONUS_SCALE_WITHOUT_DECORATION = 1.4;

class CountdownTextElement extends TextElement {
    static resetDigits(container) {
        const digits = container?.querySelectorAll("#value g");
        for (const label of digits) {
            label.parentElement.style.visibility = "hidden";
        }
    }

    get canSelect() {
        return false;
    }

    renderElement(props, transition) {
        const result = super.renderElement(props, transition, { forceTextScale: 0.3 });
        let { animated, didChange, willChange, active, animate } = this.model;

        // apply correct behaviors
        const label = this.DOMNode?.querySelector("g").parentElement;
        const isHidden = label?.style?.visibility === "hidden";

        if (!this.isInActiveSlide) {
            active = false;
        }

        if (animated && label) {
            if (!active) {
                this.inactive = true;
                label.style.visibility = "hidden";
            }

            // inactive for now
            if (!animate) {
                label.style.animation = null;
            }

            if (!(animate && active)) {
                return result;
            }

            // special condition
            if (this.inactive && active) {
                this.inactive = false;
                didChange = true;
            }

            const [animation, hideFirst, repeat] = willChange && didChange ? ["cycle", false, true]
                : willChange && !didChange ? ["exit", false, false]
                    : (!willChange && didChange) || isHidden ? ["enter", true, false]
                        : [];

            if (hideFirst) {
                label.style.visibility = "hidden";
            }

            if (animation && active && animate) {
                setTimeout(() => {
                    label.style.visibility = "visible";
                    label.style.animation = `element-countdown-tick-${animation} 1s forwards ${repeat ? "infinite" : ""}`;
                });
            } else {
                label.style.animation = null;
            }
        }

        return result;
    }
}

class CountdownUnit extends TextElement {
    get allowStyling() {
        return true;
    }

    get allowEmptyLines() {
        return false;
    }

    get allowUserScale() {
        return false;
    }

    get singleLine() {
        return true;
    }
}

class CountdownDigit extends BaseElement {
    get animateChildren() {
        return false;
    }

    get canRollover() {
        return false;
    }

    _build() {
        const MAXIMUM_COUNTDOWN_DIGIT = 999;

        // always add the value
        this.value = this.addElement("value", () => CountdownTextElement, {
            canEdit: false,
            model: {
                ...this.model,
                value: this.isReadyToAnimate ? Math.min(this.model.value, MAXIMUM_COUNTDOWN_DIGIT) : " ",
                animated: this.isReadyToAnimate
            }
        });

        // text label
        this.unit = this.addElement(this.model.unit, () => CountdownUnit, {
            autoHeight: true,
            canEdit: true,
            model: this.model.root
        });

        // after the first pass, this can be animated
        // this prevents a weird animation issue when
        // first adding a countdown timer
        this.isReadyToAnimate = true;
    }

    _calcProps(props, options) {
        let { size, children } = props;
        let container = new geom.Size(size.width, size.width);

        // check for each numeric digit
        for (const digit of ["value", "previous"]) {
            if (!this[digit]) {
                continue;
            }

            // check for scaling adjustments when not showing a
            // decoration behind a countdown digit
            const shape = this[digit].styles?.decoration?.shape || "none";
            const sizeModifier = shape === "none" ? BONUS_SCALE_WITHOUT_DECORATION : 1;
            const digitOptions = { ...options, forceTextScale: options.forceTextScale * sizeModifier };

            // update the bounds and position
            this[digit].calcProps(container, digitOptions);
        }

        // update the unit label
        let unitProps = this.unit.calcProps(size, options);
        unitProps.bounds = new geom.Rect(0, (size.height + unitProps.size.height), unitProps.size);

        return { size };
    }
}

class Countdown extends BaseElement {
    _pauseAnimations = true;

    get disableAnimationsByDefault() {
        return true;
    }

    get animateChildren() {
        return false;
    }

    constructor(...args) {
        super(...args);
        this._tickInterval = setInterval(this._tick, 1000);
        this.inactive = true;
    }

    get timestamp() {
        const { date, time } = this.model;
        return moment(`${date} ${time}`, "M/D/YYYY H:mm a").valueOf();
    }

    resetCountdownDisplay = () => {
        CountdownTextElement.resetDigits(this.DOMNode);
        this._prepareToShowElement();
    }

    // calculate time
    _getTimeValues(dir = 0) {
        const units = this.model.direction === "up"
            ? calculateTimeElapsed(this.timestamp + dir, { roundToSecond: true })
            : calculateTimeRemaining(this.timestamp + dir, { roundToSecond: true });

        return units;
    }

    _beforeShowElement() {
        CountdownTextElement.resetDigits(this.DOMNode);
    }

    shouldTransitionWhenNew() {
        return false;
    }

    // showing the slide
    _prepareToShowElement() {
        this._initializeAnimation();
    }

    // clean up
    _stopElement() {
        this.inactive = true;
        this._pauseAnimations = true;

        CountdownTextElement.resetDigits(this.DOMNode);
        this.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
    }

    _initializeAnimation() {
        this._pauseAnimations = true;

        // set the initial view
        this._isPreparing = true;

        // allow animations again
        this.inactive = false;
        clearTimeout(this._stopTimeout);
    }

    // tick the clock
    _tick = () => {
        if (this.inactive) {
            return;
        }

        this.previousUnits = this.units || { };
        this.units = this._getTimeValues();

        // stop preparing
        this._pauseAnimations = false;
        this._isPreparing = false;

        // refresh when looking at the view
        this.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
    }

    _build() {
        const { _pauseAnimations: pauseAnimations, _isPreparing: isPreparing } = this;
        const units = this._getTimeValues();

        const previous = this._previousTimes || this._getTimeValues(1000);
        const next = this._getTimeValues(-1000);
        this._previousTimes = next;

        const elementsToShow = [
            "Days",
            "Hours",
            "Minutes"
        ];

        // check if enabled
        if (this.model.showSeconds) {
            elementsToShow.push("Seconds");
        }

        // render each element
        this.timeElements = elementsToShow.map(unit => {
            const id = unit.toLowerCase();
            const isSeconds = id === "seconds";
            const value = units[id];
            const active = !isPreparing;
            const animate = !pauseAnimations;
            const didChange = isSeconds || previous[id] !== value;
            const willChange = isSeconds || next[id] !== value;

            // validate some form of label is present
            const current = trim(this.model[id]?.text);
            const isBlank = !current;
            if (!this.model[id] || isBlank) {
                let displayText = app.isEditingText ? current : unit;
                this.model[id] = { text: displayText };
            }

            // create the element
            const element = this[id] = this.addElement(id, () => CountdownDigit, {
                model: {
                    root: this.model,
                    unit: id,
                    animate,
                    active,
                    value: prefixZeros(value, 2),
                    didChange,
                    willChange
                }
            });

            return element;
        });
    }

    _calcProps(props, options) {
        let { size, children } = props;

        // determine the sizes to work with
        let { width: baseWidth, height: baseHeight } = size;
        let { length: totalElements } = this.timeElements;

        // determine the default size to work with
        const maxWidth = baseWidth * 0.9;
        let individualWidth = (maxWidth / totalElements) * 0.5;

        // determines the total width, including gaps
        const calcTotalWidth = val => {
            return (val * totalElements) + (PREFERRED_GAP * (totalElements - 1));
        };

        // the starting scale to work from
        let scale = 1;
        let preferredWidth;

        // try to determine how much space is required to fit
        const [main] = this.timeElements;
        let limit = 25;
        function findBestFit() {
            if (!--limit) {
                return;
            }

            // make sure the text fits
            const attemptWidth = Math.floor(individualWidth * scale);
            const totalWidth = calcTotalWidth(attemptWidth);
            const attempt = new geom.Size(attemptWidth, baseHeight);
            main.calcProps(attempt, options);

            // either the text doesn't fit or it's too big
            if (totalWidth > maxWidth) {
                return;
            }

            // save the size
            preferredWidth = attemptWidth;

            // try a little bigger
            scale += 0.1;
            findBestFit();
        }

        // determine the best size to use
        findBestFit();

        // apply each style and position
        const textScale = preferredWidth / this.styles.CountdownDigit.minWidth;
        const preferredSize = new geom.Size(preferredWidth, preferredWidth);
        const combinedWidth = calcTotalWidth(preferredSize.width);
        const startAt = (size.width - combinedWidth) * 0.5;

        // update each of their positions
        for (let i = 0; i < this.timeElements.length; i++) {
            const element = this.timeElements[i];

            // set the initial size
            const elementProps = element.calcProps(preferredSize, { ...element.props, forceTextScale: textScale });
            elementProps.bounds.left = startAt + (preferredSize.width * i) + (PREFERRED_GAP * i);
            elementProps.bounds.top = (size.height - elementProps.bounds.height) * 0.45;
        }

        // return { size: layouter.size };
        return { size };
    }
}

export const elements = {
    Countdown
};
