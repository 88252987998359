import CompareBubble from "./CompareBubble";
import CompareHorizontalBar from "./CompareHorizontalBar";
import CompareRadialBar from "./CompareRadialBar";
import CompareVerticalBar from "./CompareVerticalBar";
import CompareVerticalPictograph from "./CompareVerticalPictograph";

export const elements = {
    CompareBubble,
    CompareHorizontalBar,
    CompareRadialBar,
    CompareVerticalBar,
    CompareVerticalPictograph
};
