import React from "reactn";
import { app } from "js/namespaces";
import { BackgroundStyleType, PaletteColorType } from "legacy-common/constants";
import * as geom from "js/core/utilities/geom";
import { SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import Logos from "js/core/models/logos";

import { DecorativeElement } from "./BaseElement";

class CanvasBackground extends DecorativeElement {
    get canvasBackgroundStyle() {
        let backgroundStyle = this.model.backgroundStyle || this.canvas.slideTemplate.defaultBackgroundStyle;

        if (!backgroundStyle) {
            let defaultBackgroundColor = app.currentTheme.get("defaultBackgroundColor");

            if (app.currentTheme.get("backgroundImages")) {
                let bgImage = app.currentTheme.get("backgroundImages").find(bgImage => bgImage.id == defaultBackgroundColor);
                if (bgImage) {
                    return BackgroundStyleType.IMAGE;
                }
            }

            if (defaultBackgroundColor === PaletteColorType.BACKGROUND_LIGHT) {
                backgroundStyle = BackgroundStyleType.LIGHT;
            } else if (defaultBackgroundColor === PaletteColorType.BACKGROUND_DARK) {
                backgroundStyle = BackgroundStyleType.DARK;
            } else {
                backgroundStyle = BackgroundStyleType.COLOR;
            }
        }

        if (this.canvas.slideTemplate.allowColorBackgrounds == false && backgroundStyle == BackgroundStyleType.COLOR) {
            backgroundStyle = BackgroundStyleType.LIGHT;
        }

        return backgroundStyle;
    }

    get canvasBackgroundColorType() {
        const backgroundColor = this.model.backgroundColor ||
            app.currentTheme.get("defaultBackgroundColor") ||
            BackgroundStyleType.LIGHT;

        switch (this.canvasBackgroundStyle) {
            case BackgroundStyleType.LIGHT:
                return PaletteColorType.BACKGROUND_LIGHT;
            case BackgroundStyleType.DARK:
                return PaletteColorType.BACKGROUND_DARK;
            case BackgroundStyleType.COLOR:
                // if style is gradient and bg color is white
                if (["gradient", "reverseGradient"].includes(app.currentTheme.get("styleBackground")) && (!backgroundColor || backgroundColor === PaletteColorType.BACKGROUND_LIGHT)) {
                    return PaletteColorType.THEME;
                } else {
                    return backgroundColor;
                }
            default:
                return backgroundColor;
        }
    }

    get canvasBackgroundColor() {
        if (this.canvasBackgroundStyle == BackgroundStyleType.IMAGE) {
            if (this.backgroundImage) {
                switch (this.backgroundImage.colorStyle) {
                    case "dark":
                        return this.canvas.getTheme().palette.getColor("black");
                    case "color":
                        return this.canvas.getTheme().palette.getColor("theme");
                    case "light":
                    default:
                        return this.canvas.getTheme().palette.getColor("white");
                }
            } else {
                return this.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_LIGHT);
            }
        } else {
            return this.canvas.getTheme().palette.getColor(this.canvasBackgroundColorType);
        }
    }

    get backgroundEffect() {
        const backgroundStyle = this.canvas.styleSheet.Backgrounds.styles[this.canvasBackgroundStyle];
        if (backgroundStyle) {
            return backgroundStyle.effect;
        } else {
            return null;
        }
    }

    get backgroundOverlay() {
        let overlayClass;
        switch (this.backgroundEffect) {
            case "vignette":
                overlayClass = VignetteOverlayElement;
                break;
            case "greyBorder":
            case "whiteBorder":
            case "colorBorder":
                overlayClass = BorderOverlayElement;
                break;
        }
        return overlayClass;
    }

    _build() {
        let backgroundClass;

        if (this.canvasBackgroundStyle == BackgroundStyleType.IMAGE) {
            this.backgroundImage = app.currentTheme.get("backgroundImages")?.find(bg => bg.id == this.canvasBackgroundColorType);
            if (this.backgroundImage) {
                this.background = this.addElement("background", () => BackgroundImage, { backgroundImage: this.backgroundImage });
                return;
            }
        }
        switch (this.backgroundEffect) {
            case "gradient":
                backgroundClass = BackgroundGradient;
                break;
            case "reverseGradient":
                backgroundClass = BackgroundGradientReverse;
                break;
            // case "image":
            //     backgroundClass = BackgroundImage;
            //     break;
            // case "greyBorder":
            // case "whiteBorder":
            // case "colorBorder":
            //     backgroundClass = BackgroundBorder;
            //     break;
            default:
                backgroundClass = BackgroundColor;
        }
        this.background = this.addElement("background", () => backgroundClass, {
            backgroundColor: this.canvasBackgroundColor,
            backgroundStyle: this.canvasBackgroundStyle
        });
    }

    _calcProps(props) {
        let { size } = props;

        let backgroundProps = this.background.calcProps(size);
        backgroundProps.bounds = new geom.Rect(0, 0, size);

        return { size: props.size };
    }
}

export class BackgroundColor extends DecorativeElement {
    renderChildren(transition) {
        let props = this.calculatedProps;
        return (
            <SVGGroup key={this.id}>
                <rect
                    id={this.id}
                    x={props.bounds.left}
                    y={props.bounds.top}
                    width={props.bounds.width}
                    height={props.bounds.height}
                    style={{ fill: this.options.backgroundColor.toHexString() }}
                />
            </SVGGroup>
        );
    }
}

export class BackgroundGradient extends DecorativeElement {
    renderChildren(transition) {
        let props = this.calculatedProps;
        return (
            <SVGGroup key={this.id}>
                <rect
                    id={this.id}
                    x={props.bounds.left}
                    y={props.bounds.top}
                    width={props.bounds.width}
                    height={props.bounds.height}
                    style={{ fill: this.options.backgroundColor.toHexString() }}
                />
                <defs>
                    <linearGradient id={`rampGradient${this.uniqueId}`} x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="35%" style={{ stopColor: "rgb(0,0,0)", stopOpacity: 0 }} />
                        <stop offset="100%" style={{ stopColor: "rgb(0,0,0)", stopOpacity: 0.50 }} />
                    </linearGradient>
                </defs>
                <rect x="-150" y="-150"
                    width={props.bounds.width + 300}
                    height={props.bounds.height + 300}
                    fill={`url(#rampGradient${this.uniqueId})`}
                ></rect>
            </SVGGroup>
        );
    }
}

export class BackgroundGradientReverse extends DecorativeElement {
    renderChildren(transition) {
        let props = this.calculatedProps;
        return (
            <SVGGroup key={this.id}>
                <rect
                    id={this.id}
                    x={props.bounds.left}
                    y={props.bounds.top}
                    width={props.bounds.width}
                    height={props.bounds.height}
                    style={{ fill: this.options.backgroundColor.toHexString() }}
                />
                <defs>
                    <linearGradient id={`reverseRampGradient${this.uniqueId}`} x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="20%" style={{ stopColor: "rgb(0,0,0)", stopOpacity: 0.7 }} />
                        <stop offset="100%" style={{ stopColor: "rgb(0,0,0)", stopOpacity: 0.0 }} />
                    </linearGradient>
                </defs>
                <rect x="-150" y="-150"
                    width={props.bounds.width + 300}
                    height={props.bounds.height + 300}
                    fill={`url(#reverseRampGradient${this.uniqueId})`}>
                </rect>
            </SVGGroup>
        );
    }
}

export class BackgroundImage extends DecorativeElement {
    getBackgroundImageUrl(reloadCache = false) {
        if (this.options.backgroundImage) {
            return Logos.getSignedUrlAndLoad(this.options.backgroundImage.asset, reloadCache);
        }

        return null;
    }

    async _load() {
        this.backgroundImageUrl = await this.getBackgroundImageUrl();
    }

    _renderElement(transition) {
        return (
            <SVGGroup key={this.id}>
                <image width="100%" height="100%" viewBox={`0 0 ${this.canvas.CANVAS_WIDTH} ${this.canvas.CANVAS_HEIGHT}`} preserveAspectRatio="xMinYMin slice" href={this.backgroundImageUrl} />
            </SVGGroup>
        );
    }
}

export class VignetteOverlayElement extends DecorativeElement {
    _loadStyles(styles) {
        styles = {
            decoration: {
                type: "frame",
                shape: "rect",
                effect: "vignette",
                fillColor: "none"
            }
        };
    }

    _calcProps(props, options) {
        return { size: props.size };
    }

    _renderElement(transition) {
        let props = this.calculatedProps;
        return (
            <SVGGroup key={this.id}>
                <defs>
                    <radialGradient id={`vignette${this.uniqueId}`}>
                        <stop offset="60%" style={{ stopColor: "rgb(0,0,0)", stopOpacity: 0 }} />
                        <stop offset="70%" style={{ stopColor: "rgb(0,0,0)", stopOpacity: 0.025 }} />
                        <stop offset="80%" style={{ stopColor: "rgb(0,0,0)", stopOpacity: 0.1 }} />
                        <stop offset="100%" style={{ stopColor: "rgb(0,0,0)", stopOpacity: 0.25 }} />
                    </radialGradient>
                </defs>
                <rect x="-150" y="-150" width={props.size.width + 300} height={props.size.height + 300}
                    fill={`url(#vignette${this.uniqueId})`}></rect>
            </SVGGroup>
        );
    }
}

export class BorderOverlayElement extends DecorativeElement {
    renderChildren(transition) {
        let props = this.calculatedProps;
        switch (this.parentElement.elements.background.canvasBackgroundStyle) {
            case BackgroundStyleType.LIGHT:
                this.calculatedBorderColor = "rgb(212,212,212)";
                break;
            case BackgroundStyleType.DARK:
                this.calculatedBorderColor = "white";
                break;
            case BackgroundStyleType.COLOR:
                this.calculatedBorderColor = "white";
                break;
            case BackgroundStyleType.IMAGE:
                this.calculatedBorderColor = "white";
                break;
        }

        this.calculatedBorderWidth = this.canvas.styleSheet.$decorationStrokeWidth * 4;

        return (
            <SVGGroup key={this.id}>
                <rect
                    id={this.id}
                    x={props.bounds.left}
                    y={props.bounds.top}
                    width={props.bounds.width}
                    height={props.bounds.height}
                    style={{
                        fill: "none",
                        stroke: this.calculatedBorderColor,
                        strokeWidth: this.calculatedBorderWidth
                    }}
                />
            </SVGGroup>
        );
    }
}

export { CanvasBackground };
