import React, { Component } from "reactn";
import { Slider } from "@material-ui/core";

import { PopupMenu } from "legacy-js/react/views/Editor/PopupMenu";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { PositionType } from "legacy-common/constants";
import IconButton from "legacy-js/react/views/Editor/Components/IconButton";

export class TextSizePopupMenu extends Component {
    state = {
        value: this.props.element.userFontScale
    }

    handleChangeTextSize(value) {
        value = Math.clamp(value, 0.25, 2);
        this.setState({ value: value });

        this.props.onUpdate(value);
    }

    handleDragEnd(value) {
        this.props.onComplete(value);
    }

    render() {
        let { value } = this.state;

        return (
            <PopupMenu {...this.props} className="text-style-popup-menu" position={PositionType.TOP}>
                <FlexBox verticalAlign="middle">
                    <label>Text Scale</label>
                    <IconButton id="decrease-size" icon="remove" onClick={() => {
                        this.handleChangeTextSize(value - 0.05);
                    }} />
                    <Slider
                        id="text-size-slider"
                        style={{ width: 120 }}
                        value={value}
                        onChange={(event, value) => this.handleChangeTextSize(value)}
                        onDragEnd={(event, value) => this.handleDragEnd(value)}
                        min={0.25}
                        max={2}
                        step={0.05}
                    />
                    <IconButton id="increase-size" icon="add" onClick={() => {
                        this.handleChangeTextSize(value + 0.05);
                    }} />
                </FlexBox>
            </PopupMenu>
        );
    }
}

