import React, { Component } from "reactn";
import { ds } from "js/core/models/dataService";
import { getAssetIds } from "legacy-common/assetUtils";
import { Slide } from "js/core/models/slide";
import { ASSET_RESOLUTION, AssetType } from "legacy-common/constants";
import { UIPane, UIPaneContents, UIPaneHeader } from "../../../components/UiComponents";
import Loadable from "legacy-js/react/components/Loadable";
import ImageThumbnailGrid from "legacy-js/react/views/AddAssets/Components/ImageThumbnailGrid";
import AssetPreview from "legacy-js/react/views/AddAssets/Components/AssetPreview";
import { loadImage } from "js/core/utilities/promiseHelper";
import { getAverageImageColor } from "js/core/utilities/imageUtilities";
import { Notice } from "legacy-js/react/components/Notice";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ASSETS);

export default class PresentationAssetsPane extends Component {
    state = {
        assets: [],
        loadedAssets: [],
        isLoading: true,
        muted: true,
    };

    componentDidMount() {
        this.lookupPreviewRefs = {};
        this.loadAssetsForSlide();
    }

    async loadAssetsForSlide() {
        const { selectedPresentation } = this.props;
        this.setState({
            isLoading: true
        });
        const sips = selectedPresentation.getSips();
        this.slidePromises = [];
        this.slideModels = sips.map(slideId => {
            let slide = new Slide({ id: slideId }, {
                autoSync: true,
                presentation: selectedPresentation
            });
            this.slidePromises.push(slide.load());
            return slide;
        });
        const slides = await Promise.all(this.slidePromises);
        let assetIds = slides
            .map(model => getAssetIds(model.toJSON()))
            .reduce((l, r) => l.concat(r), [])
            .filter(({ type }) => type !== "icon")
            .map(({ id }) => id);
        assetIds = Array.from(new Set(assetIds));
        const slideAssets = await ds.assets.getAssets(assetIds, "image");
        const assetTypes = this.props.assetTypes ?? [AssetType.IMAGE, AssetType.LOGO, AssetType.VIDEO, AssetType.STOCK_VIDEO];
        const assets = slideAssets.filter(asset => assetTypes.includes(asset.get("type")));

        // Load urls now
        const asyncLoadProps = assets.map(asset => {
            let loadAsset;
            if (asset.type === AssetType.VIDEO || asset.type === AssetType.STOCK_VIDEO) {
                loadAsset = async () => {
                    const result = { asset };
                    try {
                        const previewProps = { ...asset.attributes };
                        if (asset.type === AssetType.VIDEO) {
                            previewProps.url = await asset.getURL();
                            const previewAssetId = asset.get("previewAssetId");
                            if (previewAssetId) {
                                const previewAsset = await ds.assets.getAssetById(previewAssetId, AssetType.IMAGE);
                                previewProps.previewUrl = await previewAsset.getURL();
                            }
                        }

                        // Ensure we have width and height values
                        previewProps.width = previewProps.width || previewProps.w;
                        previewProps.height = previewProps.height || previewProps.h;

                        result.previewProps = previewProps;
                    } catch (err) {
                        logger.error(err, "[PresentationAssetsPane] failed to load video asset", { id: asset.id });
                        result.error = err;
                    }

                    return result;
                };
            } else {
                loadAsset = async () => {
                    const result = { asset };
                    try {
                        let url = await asset.getURL(ASSET_RESOLUTION.SMALL);

                        // Will calculate average image color to set black background for white svg and png logos
                        let renderDarkBackground = false;
                        if (asset.type === AssetType.LOGO && (asset.get("fileType") === "svg" || asset.get("fileType") === "png")) {
                            let image;
                            // Note: anonymous cross origin will allow us to use this image in a canvas to calculate its color
                            try {
                                image = await loadImage(url, null, "Anonymous");
                            } catch (err) {
                                // Will retry w/o cache because cached requests don't get served correctly for
                                // anonymous cross origin requests
                                url = await asset.getURL(ASSET_RESOLUTION.SMALL, true);
                                image = await loadImage(url, null, "Anonymous");
                            }
                            const { r, g, b, hasAlpha } = getAverageImageColor(image);
                            if (hasAlpha && r > 240 && g > 240 && b > 240) {
                                renderDarkBackground = true;
                            }
                        }

                        const previewProps = {
                            ...asset.attributes,
                            url,
                            renderDarkBackground,
                        };

                        // Ensure we have width and height values
                        previewProps.width = previewProps.width || previewProps.w;
                        previewProps.height = previewProps.height || previewProps.h;

                        result.previewProps = previewProps;
                    } catch (err) {
                        logger.error(err, "[PresentationAssetsPane] failed to load image asset", { id: asset.id });
                        result.error = err;
                    }

                    return result;
                };
            }
            return loadAsset();
        });

        const loadedAssets = await Promise.all(asyncLoadProps);

        // Remove the assets with errors and load any remaining assets to fill the gaps
        const assetsWithErrors = loadedAssets.filter(({ error }) => !!error);
        if (assetsWithErrors.length) {
            const assets = assetsWithErrors.map(({ asset }) => asset);
            this.removeAssets(assets, loadedAssets);
        }

        this.setState({
            isLoading: false,
            loadedAssets
        });
    }

    removeAssets = (assetsToRemove, loadedAssets) => {
        assetsToRemove.forEach(asset => {
            const userAsset = ds.userAssets.get(asset.id);
            ds.userAssets.remove(userAsset);

            // Remove the ref associated with this asset
            const key = asset.id;
            this.lookupPreviewRefs[key] && delete this.lookupPreviewRefs[key];

            // Remove the asset from the loaded assets
            const index = loadedAssets.findIndex(x => x.asset.id === asset.id);
            if (index > -1) {
                loadedAssets.splice(index, 1);
            }
        });
    }

    removeAssetCallback = async asset => {
        let {
            loadedAssets
        } = this.state;

        this.removeAssets([asset], loadedAssets);

        this.setState({
            loadedAssets,
        });
    }

    hidePlaybacks = itemExcluded => {
        this.lookupPreviewRefs = this.lookupPreviewRefs || {};
        Object.entries(this.lookupPreviewRefs)
            .filter(([key, value]) => !!value?.current && key !== itemExcluded.id)
            .forEach(([key, value]) => value.current.hidePlayback());
    }

    render() {
        const {
            addAssetCallback,
            handleConfirm,
            backgroundVideoOnly = false,
        } = this.props;
        const {
            isLoading,
            loadedAssets,
            muted,
        } = this.state;

        return (
            <UIPane>
                <UIPaneHeader>Select asset to add</UIPaneHeader>
                <UIPaneContents style={{ padding: 20 }}>
                    <Loadable isLoading={isLoading}>
                        {
                            !!loadedAssets.length &&
                            <ImageThumbnailGrid>
                                {
                                    loadedAssets.map(({
                                        asset,
                                        previewProps,
                                    }) => {
                                        // Get the ref, or create it if it doesn't exist
                                        const key = asset.id;
                                        let ref = this.lookupPreviewRefs[key];
                                        if (!ref) {
                                            ref = React.createRef();
                                            this.lookupPreviewRefs[key] = ref;
                                        }
                                        return (
                                            <AssetPreview
                                                ref={ref}
                                                key={asset.id}
                                                className={`media-asset-${asset.type}`}
                                                asset={asset}
                                                previewProps={previewProps}
                                                addAssetCallback={addAssetCallback}
                                                removeAssetCallback={this.removeAssetCallback}
                                                handleConfirm={handleConfirm}
                                                muted={muted}
                                                toggleMuted={() => this.setState({ muted: !this.state.muted })}
                                                hidePlaybacks={this.hidePlaybacks}
                                                backgroundVideoOnly={backgroundVideoOnly}
                                            />
                                        );
                                    })
                                }
                            </ImageThumbnailGrid>
                        }
                        {
                            !loadedAssets.length &&
                            <Notice
                                title="There currently are no assets used in this presentation."
                            />
                        }
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}
