import { ExportToPPT } from "legacy-js/exporter/exportToPPT";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { uploadBlobToSignedUrl } from "js/core/utilities/uploadBlobToSignedUrl";
import { ShowErrorDialog, ShowMessageDialog, ShowConfirmationDialog, ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "legacy-js/react/components/Dialogs/ProgressDialog";
import * as gDrive from "js/core/utilities/gDrive";
import * as googleAuth from "js/core/oauth/googleAuth";
import { app } from "js/namespaces";
import { downloadFromBlob, trackActivity } from "js/core/utilities/utilities";

const logger = getLogger(LogGroup.EXPORT);

export async function exportToGoogleSlides({ slideCanvases = null, includeSkippedSlides = false }) {
    const progressDialog = ShowDialog(ProgressDialog, {
        title: `Exporting ${slideCanvases ? (slideCanvases.length === 1 ? "slide" : "slides") : "presentation"} to Google Slides...`,
    });

    try {
        const { hasOfflineAccess } = await gDrive.getState();
        if (!hasOfflineAccess) {
            await gDrive.grantOfflineAccessWithDialogIfNeeded(app.user.id);
        }

        const exporter = new ExportToPPT();
        const { blob, fileName: presentationFileName } = await exporter.export({
            destination: ExportToPPT.EXPORT_DESTINATION.blob,
            showDialogs: false,
            includeSkippedSlides,
            slideCanvases
        });

        // Google Drive API won't let us import files bigger than 100MB as Google Slides presentations
        if (blob.size > 100 * 1024 * 1024) {
            const shouldDownload = await ShowConfirmationDialog({
                title: "Presentation is too big",
                message: "Presentation is too big to be imported to Google Slides, save presentation locally instead?",
                okButtonLabel: "Save locally"
            });

            if (shouldDownload) {
                downloadFromBlob(blob, `${presentationFileName}.pptx`, "application/vnd.openxmlformats-officedocument.presentationml.presentation");
            }

            return;
        }

        const { writeUrl, fileName: tempAssetFileName } = await Api.tempAssets.post();
        await uploadBlobToSignedUrl(blob, writeUrl, () => {}, "application/vnd.openxmlformats-officedocument.presentationml.presentation");

        const { fileId } = await Api.gDriveGoogleSlides.post({ presentationFileName, tempAssetFileName });

        const openGoogleSlides = () => window.open(`https://docs.google.com/presentation/d/${fileId}`, "_blank");

        const newWindow = openGoogleSlides();
        if (!newWindow) {
            // New window was blocked, show dialog
            ShowMessageDialog({
                title: "Success",
                message: "Your presentation was successfully exported to Google Slides",
                buttonText: "Open Google Slides",
                onClick: openGoogleSlides,
                cancelButtonLabel: "Dismiss",
                preventClose: false,
                showCancel: true,
                buttonOptions: { color: "blue" }
            });
        }

        if (slideCanvases?.length === 1) {
            trackActivity("Presentation", "SlideExport", null, null, { type: "google" }, { audit: true });
        } else {
            trackActivity("Presentation", "GoogleExport", null, null, {}, { audit: true });
        }
    } catch (err) {
        if (err instanceof googleAuth.GoogleAuthFlowWasInterruptedError) {
            return;
        }

        logger.error(err, "exportToGoogleSlides() failed");

        if (err.message === "Wrong user") {
            ShowErrorDialog({
                title: "Wrong user",
                message: "Looks like you logged into Google as a wrong user"
            });
            return;
        }

        ShowErrorDialog({
            title: "Failed to export presentation",
            message: "We couldn't export your presentation, please try again later"
        });
    } finally {
        progressDialog.props.closeDialog();
    }
}
