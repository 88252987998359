import React from "react";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

const FetchingClickShieldContainer = styled.div.attrs(
    ({
        visible,
        opaque,
        backgroundColor = null,
        style
    }) => ({
        style: {
            opacity: visible ? 1 : 0,
            pointerEvents: visible ? "auto" : "none",
            backgroundColor: backgroundColor ?? (opaque ? "#4b4e55" : "#4b4e5588"),
            ...style
        }
    }))`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    z-index: 1200;
    transition: opacity 500ms;
`;

const Message = styled.div`
    margin-bottom: 35px;

    font-weight: normal;
    font-size: 15px;
    line-height: 125%;
    letter-spacing: 0.5px;
    color: #222222;
`;

function FetchingClickShield({ visible, opaque = false, backgroundColor = null, message = null, style = {}, iconStyle = {} }) {
    return (
        <FetchingClickShieldContainer visible={visible} opaque={opaque} backgroundColor={backgroundColor} style={style}>
            {message && <Message>{message}</Message>}
            <CircularProgress size={35} thickness={5} style={iconStyle} />
        </FetchingClickShieldContainer>
    );
}

export default FetchingClickShield;
