import React, { Component } from "react";
import styled from "styled-components";

import { Button, Icon, DialogActions } from "@material-ui/core";

import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap10, Gap20, Gap30 } from "legacy-js/react/components/Gap";
import { BlueButton } from "legacy-js/react/components/UiComponents";
import { themeColors } from "legacy-js/react/sharedStyles";
import { getStaticUrl } from "legacy-js/config";

const TextFrame = styled.div`
  padding: 0px 16px;
`;

const Header = styled.div`
  padding: 0px 16px;
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  line-height: 1.2;
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    width: 560px;
  }
`;

const HighlightFrame = styled.div`
  background: ${themeColors.hoverBlue};
  padding: 20px;
`;

export class UpdateTemplateVersionDialog extends Component {
    render() {
        const { canvas, closeDialog } = this.props;

        return (
            <BeautifulDialog
                preventClose
                closeDialog={() => closeDialog(false)}
                maxWidth="md"
                fullWidth={false}
                hideBackdrop={false}
            >
                <DialogContent style={{ width: 880, padding: "0px 50px 34px" }}>
                    <Gap20 />
                    <FlexBox center fillWidth>
                        <img src={getStaticUrl("/images/template-update-large.gif")} style={{ height: 160 }} />
                    </FlexBox>
                    <Gap20 />
                    <Header>
                        <div>
                            A new, improved version of the {canvas.slideTemplate.constructor.title} template is available!
                        </div>
                    </Header>
                    <Gap30 />
                    <HighlightFrame>
                        <FlexBox top left fill>
                            <Icon color="primary">info</Icon>
                            <Gap10 />
                            <div>
                                <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>What's new?</div>
                                {canvas.slideTemplate.constructor.updateSummaryText}
                            </div>
                        </FlexBox>
                    </HighlightFrame>
                    <hr />
                    <TextFrame>
                        <p>
                            <b>NOTE:</b> In order to make changes to this slide, you'll need to update it to the latest version. You may notice some stylistic differences or layout changes, but we think you'll love the new functionality. You may choose to update this slide later when it is convenient for you and you can undo this change or revert using version history.
                        </p>
                    </TextFrame>
                    <Gap10 />
                    <TextFrame>
                        This update <strong>can be undone</strong> if you wish to return to the old version after updating.
                    </TextFrame>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog(false)}>
                        Not Now
                    </Button>
                    <BlueButton onClick={() => closeDialog(true)}>
                        Update Slide
                    </BlueButton>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
