const devTemplates = [];

devTemplates.push({
    getInstanceProps: () => ({
        elementType: "DebugBox"
    }), getStaticProps: () => ({
        id: "debug_box",
        icon: "debug_box",
        title: "Debug Box",
        category: "organization",
        tags: "",
    })
});

module.exports = devTemplates;
