export const configs = {
    "no-icons": {
        markerSrc: "/images/journey/marker/map-pin.svg",
        pathSrc: {
            "no-start-no-end": "/images/journey/path/road.svg",
            "with-start-no-end": "/images/journey/path/road-with-start.svg",
            "no-start-with-end": "/images/journey/path/road-with-end.svg",
            "with-start-with-end": "/images/journey/path/road-with-start-end.svg",
        },
        minSize: [1280, 410],
        textSize: [160, 100],
        contentConfig: null,
        startConfig: {
            marker: null,
            text: {
                pos: [45, 324],
                size: [120, 60],
                align: "center",
                verticalAlign: "middle",
            },
        },
        endConfig: {
            marker: {
                src: "/images/journey/marker/flag-end.svg",
                pos: [1096, 16],
            },
            text: {
                pos: [1115, 55],
                size: [120, 60],
            },
        },
        itemConfigsMap: {
            "1": [
                {
                    marker: {
                        pos: [620, 126],
                    },
                    text: {
                        pos: [670, 75],
                        align: "center",
                        verticalAlign: "middle",
                    },
                },
            ],
            "2": [
                {
                    marker: {
                        pos: [475, 15],
                    },
                    text: {
                        pos: [410, 95],
                        align: "center",
                    },
                },
                {
                    marker: {
                        pos: [775, 315],
                    },
                    text: {
                        pos: [710, 190],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
            ],
            "3": [
                {
                    marker: {
                        pos: [350, 83],
                    },
                    text: {
                        pos: [384, 136],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [625, 166],
                    },
                    text: {
                        pos: [675, 72],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [904, 243],
                    },
                    text: {
                        pos: [958, 260],
                        align: "left",
                    },
                },
            ],
            "4": [
                {
                    marker: {
                        pos: [319, 206],
                    },
                    text: {
                        pos: [369, 223],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [567, 46],
                    },
                    text: {
                        pos: [618, -9],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [711, 301],
                    },
                    text: {
                        pos: [690, 185],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [926, 158],
                    },
                    text: {
                        pos: [975, 175],
                        align: "left",
                    },
                },
            ],
            "5": [
                {
                    marker: {
                        pos: [279, 273],
                    },
                    text: {
                        pos: [334, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [382, 48],
                    },
                    text: {
                        pos: [405, 105],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [625, 166],
                    },
                    text: {
                        pos: [680, 130],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [872, 280],
                    },
                    text: {
                        pos: [930, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [970, 60],
                    },
                    text: {
                        pos: [988, 128],
                        align: "left",
                    },
                },
            ],
            "6": [
                {
                    marker: {
                        pos: [280, 272],
                    },
                    text: {
                        pos: [334, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [362, 67],
                    },
                    text: {
                        pos: [392, 122],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [588, 67],
                    },
                    text: {
                        pos: [638, 13],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [662, 265],
                    },
                    text: {
                        pos: [707, 190],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [888, 265],
                    },
                    text: {
                        pos: [945, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [968, 60],
                    },
                    text: {
                        pos: [988, 128],
                        align: "left",
                    },
                },
            ],
            "7": [
                {
                    marker: {
                        pos: [280, 272],
                    },
                    text: {
                        pos: [345, 280],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [344, 93],
                    },
                    text: {
                        pos: [153, 54],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [504, 18],
                    },
                    text: {
                        pos: [574, -30],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [625, 165],
                    },
                    text: {
                        pos: [440, 142],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [746, 313],
                    },
                    text: {
                        pos: [702, 195],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [908, 234],
                    },
                    text: {
                        pos: [960, 275],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [969, 59],
                    },
                    text: {
                        pos: [988, 128],
                        align: "left",
                    },
                },
            ],
            "8": [
                {
                    marker: {
                        pos: [248, 297],
                    },
                    text: {
                        pos: [135, 176],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [325, 156],
                    },
                    text: {
                        pos: [376, 134],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [427, 23],
                    },
                    text: {
                        pos: [238, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [596, 76],
                    },
                    text: {
                        pos: [645, 22],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [647, 242],
                    },
                    text: {
                        pos: [464, 279],
                        align: "right",
                    },
                },
                {
                    marker: {
                        pos: [804, 312],
                    },
                    text: {
                        pos: [725, 194],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [924, 186],
                    },
                    text: {
                        pos: [980, 180],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [1009, 31],
                    },
                    text: {
                        pos: [830, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
            ],
            "9": [
                {
                    marker: {
                        pos: [267, 285],
                    },
                    text: {
                        pos: [135, 176],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [324, 175],
                    },
                    text: {
                        pos: [376, 154],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [391, 40],
                    },
                    text: {
                        pos: [206, -10],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [559, 40],
                    },
                    text: {
                        pos: [614, -10],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [626, 175],
                    },
                    text: {
                        pos: [680, 130],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [694, 292],
                    },
                    text: {
                        pos: [494, 300],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [855, 292],
                    },
                    text: {
                        pos: [924, 300],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [924, 175],
                    },
                    text: {
                        pos: [980, 142],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [988, 43],
                    },
                    text: {
                        pos: [815, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
            ],
            "10": [
                {
                    marker: {
                        pos: [222, 308],
                    },
                    text: {
                        pos: [97, 192],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [317, 213],
                    },
                    text: {
                        pos: [367, 191],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [356, 74],
                    },
                    text: {
                        pos: [171, 35],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [495, 16],
                    },
                    text: {
                        pos: [410, 85],
                        align: "center",
                    },
                },
                {
                    marker: {
                        pos: [610, 99],
                    },
                    text: {
                        pos: [661, 61],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [646, 243],
                    },
                    text: {
                        pos: [475, 295],
                        align: "right",
                    },
                },
                {
                    marker: {
                        pos: [760, 313],
                    },
                    text: {
                        pos: [705, 190],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [881, 272],
                    },
                    text: {
                        pos: [935, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [927, 146],
                    },
                    text: {
                        pos: [980, 160],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [1018, 27],
                    },
                    text: {
                        pos: [830, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
            ],
        }
    },
    "with-icons": {
        markerSrc: "/images/journey/marker/map-pin-image.svg",
        pathSrc: {
            "no-start-no-end": "/images/journey/path/road.svg",
            "with-start-no-end": "/images/journey/path/road-with-start.svg",
            "no-start-with-end": "/images/journey/path/road-with-end.svg",
            "with-start-with-end": "/images/journey/path/road-with-start-end.svg",
        },
        minSize: [1280, 410],
        textSize: [160, 100],
        contentConfig: {
            picture: {
                scale: 0.9,
            },
            icon: {
                scale: 0.6,
            },
        },
        startConfig: {
            marker: null,
            text: {
                pos: [45, 324],
                size: [120, 60],
                align: "center",
                verticalAlign: "middle",
            },
        },
        endConfig: {
            marker: {
                src: "/images/journey/marker/flag-end.svg",
                pos: [1096, 16],
            },
            text: {
                pos: [1115, 55],
                size: [120, 60],
            },
        },
        itemConfigsMap: {
            "1": [
                {
                    marker: {
                        pos: [609, 136],
                    },
                    text: {
                        pos: [670, 75],
                        align: "center",
                        verticalAlign: "middle",
                    },
                },
            ],
            "2": [
                {
                    marker: {
                        pos: [466, 28],
                    },
                    text: {
                        pos: [410, 95],
                        align: "center",
                    },
                },
                {
                    marker: {
                        pos: [766, 331],
                    },
                    text: {
                        pos: [710, 208],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
            ],
            "3": [
                {
                    marker: {
                        pos: [336, 98],
                    },
                    text: {
                        pos: [394, 136],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [616, 185],
                    },
                    text: {
                        pos: [680, 109],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [897, 250],
                    },
                    text: {
                        pos: [963, 260],
                        align: "left",
                    },
                },
            ],
            "4": [
                {
                    marker: {
                        pos: [310, 214],
                    },
                    text: {
                        pos: [369, 223],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [553, 58],
                    },
                    text: {
                        pos: [626, -16],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [704, 317],
                    },
                    text: {
                        pos: [695, 200],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [917, 165],
                    },
                    text: {
                        pos: [982, 182],
                        align: "left",
                    },
                },
            ],
            "5": [
                {
                    marker: {
                        pos: [271, 284],
                    },
                    text: {
                        pos: [338, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [378, 55],
                    },
                    text: {
                        pos: [405, 113],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [615, 179],
                    },
                    text: {
                        pos: [678, 130],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [864, 290],
                    },
                    text: {
                        pos: [930, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [960, 72],
                    },
                    text: {
                        pos: [988, 132],
                        align: "left",
                    },
                },
            ],
            "6": [
                {
                    marker: {
                        pos: [271, 284],
                    },
                    text: {
                        pos: [338, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [349, 77],
                    },
                    text: {
                        pos: [396, 133],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [578, 77],
                    },
                    text: {
                        pos: [645, 9],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [647, 271],
                    },
                    text: {
                        pos: [700, 184],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [883, 271],
                    },
                    text: {
                        pos: [945, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [959, 71],
                    },
                    text: {
                        pos: [988, 132],
                        align: "left",
                    },
                },
            ],
            "7": [
                {
                    marker: {
                        pos: [271, 284],
                    },
                    text: {
                        pos: [345, 280],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [332, 107],
                    },
                    text: {
                        pos: [157, 60],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [500, 33],
                    },
                    text: {
                        pos: [574, -30],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [615, 186],
                    },
                    text: {
                        pos: [440, 142],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [743, 328],
                    },
                    text: {
                        pos: [705, 200],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [901, 244],
                    },
                    text: {
                        pos: [960, 275],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [959, 70],
                    },
                    text: {
                        pos: [988, 132],
                        align: "left",
                    },
                },
            ],
            "8": [
                {
                    marker: {
                        pos: [242, 308],
                    },
                    text: {
                        pos: [123, 200],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [316, 166],
                    },
                    text: {
                        pos: [379, 148],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [424, 34],
                    },
                    text: {
                        pos: [229, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [588, 93],
                    },
                    text: {
                        pos: [658, 62],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [635, 252],
                    },
                    text: {
                        pos: [464, 279],
                        align: "right",
                    },
                },
                {
                    marker: {
                        pos: [791, 327],
                    },
                    text: {
                        pos: [719, 213],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [913, 206],
                    },
                    text: {
                        pos: [979, 180],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [996, 45],
                    },
                    text: {
                        pos: [822, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
            ],
            "9": [
                {
                    marker: {
                        pos: [253, 298],
                    },
                    text: {
                        pos: [122, 188],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [314, 186],
                    },
                    text: {
                        pos: [383, 166],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [380, 55],
                    },
                    text: {
                        pos: [201, -10],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [554, 55],
                    },
                    text: {
                        pos: [621, -10],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [617, 186],
                    },
                    text: {
                        pos: [684, 144],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [689, 306],
                    },
                    text: {
                        pos: [494, 300],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [844, 306],
                    },
                    text: {
                        pos: [924, 300],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [915, 186],
                    },
                    text: {
                        pos: [983, 159],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [981, 52],
                    },
                    text: {
                        pos: [810, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
            ],
            "10": [
                {
                    marker: {
                        pos: [224, 318],
                    },
                    text: {
                        pos: [127, 205],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [311, 213],
                    },
                    text: {
                        pos: [375, 191],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [349, 78],
                    },
                    text: {
                        pos: [171, 35],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [491, 29],
                    },
                    text: {
                        pos: [410, 85],
                        align: "center",
                    },
                },
                {
                    marker: {
                        pos: [604, 128],
                    },
                    text: {
                        pos: [670, 89],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [638, 260],
                    },
                    text: {
                        pos: [475, 300],
                        align: "right",
                    },
                },
                {
                    marker: {
                        pos: [752, 330],
                    },
                    text: {
                        pos: [708, 205],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [875, 282],
                    },
                    text: {
                        pos: [939, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [918, 156],
                    },
                    text: {
                        pos: [983, 144],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [1004, 39],
                    },
                    text: {
                        pos: [830, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
            ],
        }
    },
    "dots": {
        markerSrc: "/images/journey/marker/dot.svg",
        pathSrc: {
            "no-start-no-end": "/images/journey/path/road.svg",
            "with-start-no-end": "/images/journey/path/road-with-start.svg",
            "no-start-with-end": "/images/journey/path/road-with-end.svg",
            "with-start-with-end": "/images/journey/path/road-with-start-end.svg",
        },
        minSize: [1280, 410],
        textSize: [160, 100],
        contentConfig: null,
        startConfig: {
            marker: null,
            text: {
                pos: [45, 324],
                size: [120, 60],
                align: "center",
                verticalAlign: "middle",
            },
        },
        endConfig: {
            marker: {
                src: "/images/journey/marker/flag-end.svg",
                pos: [1096, 16],
            },
            text: {
                pos: [1115, 55],
                size: [120, 60],
            },
        },
        itemConfigsMap: {
            "1": [
                {
                    marker: {
                        pos: [623, 150],
                    },
                    text: {
                        pos: [670, 75],
                        align: "center",
                        verticalAlign: "middle",
                    },
                },
            ],
            "2": [
                {
                    marker: {
                        pos: [480, 44],
                    },
                    text: {
                        pos: [410, 95],
                        align: "center",
                    },
                },
                {
                    marker: {
                        pos: [780, 344],
                    },
                    text: {
                        pos: [710, 205],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
            ],
            "3": [
                {
                    marker: {
                        pos: [353, 114],
                    },
                    text: {
                        pos: [384, 136],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [630, 199],
                    },
                    text: {
                        pos: [675, 109],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [912, 265],
                    },
                    text: {
                        pos: [958, 260],
                        align: "left",
                    },
                },
            ],
            "4": [
                {
                    marker: {
                        pos: [326, 228],
                    },
                    text: {
                        pos: [369, 223],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [567, 72],
                    },
                    text: {
                        pos: [605, -28],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [718, 331],
                    },
                    text: {
                        pos: [695, 200],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [931, 179],
                    },
                    text: {
                        pos: [975, 175],
                        align: "left",
                    },
                },
            ],
            "5": [
                {
                    marker: {
                        pos: [289, 297],
                    },
                    text: {
                        pos: [334, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [393, 72],
                    },
                    text: {
                        pos: [405, 105],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [630, 189],
                    },
                    text: {
                        pos: [675, 130],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [882, 304],
                    },
                    text: {
                        pos: [930, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [978, 84],
                    },
                    text: {
                        pos: [988, 128],
                        align: "left",
                    },
                },
            ],
            "6": [
                {
                    marker: {
                        pos: [289, 297],
                    },
                    text: {
                        pos: [334, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [371, 91],
                    },
                    text: {
                        pos: [392, 122],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [596, 99],
                    },
                    text: {
                        pos: [638, 9],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [671, 297],
                    },
                    text: {
                        pos: [700, 190],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [896, 289],
                    },
                    text: {
                        pos: [945, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [978, 84],
                    },
                    text: {
                        pos: [988, 128],
                        align: "left",
                    },
                },
            ],
            "7": [
                {
                    marker: {
                        pos: [289, 297],
                    },
                    text: {
                        pos: [345, 280],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [348, 123],
                    },
                    text: {
                        pos: [160, 60],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [513, 48],
                    },
                    text: {
                        pos: [574, -30],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [630, 199],
                    },
                    text: {
                        pos: [440, 142],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [756, 342],
                    },
                    text: {
                        pos: [705, 200],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [916, 256],
                    },
                    text: {
                        pos: [960, 275],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [978, 84],
                    },
                    text: {
                        pos: [988, 128],
                        align: "left",
                    },
                },
            ],
            "8": [
                {
                    marker: {
                        pos: [259, 322],
                    },
                    text: {
                        pos: [127, 205],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [331, 179],
                    },
                    text: {
                        pos: [376, 148],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [437, 50],
                    },
                    text: {
                        pos: [234, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [601, 106],
                    },
                    text: {
                        pos: [658, 62],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [648, 265],
                    },
                    text: {
                        pos: [464, 279],
                        align: "right",
                    },
                },
                {
                    marker: {
                        pos: [804, 342],
                    },
                    text: {
                        pos: [719, 213],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [928, 219],
                    },
                    text: {
                        pos: [970, 180],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [1009, 61],
                    },
                    text: {
                        pos: [830, -30],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
            ],
            "9": [
                {
                    marker: {
                        pos: [267, 316],
                    },
                    text: {
                        pos: [127, 205],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [330, 199],
                    },
                    text: {
                        pos: [374, 166],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [393, 72],
                    },
                    text: {
                        pos: [213, -20],
                        align: "right",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [567, 72],
                    },
                    text: {
                        pos: [607, -20],
                        align: "left",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [630, 199],
                    },
                    text: {
                        pos: [675, 130],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [701, 322],
                    },
                    text: {
                        pos: [494, 300],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [859, 322],
                    },
                    text: {
                        pos: [924, 300],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [930, 199],
                    },
                    text: {
                        pos: [980, 142],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [993, 72],
                    },
                    text: {
                        pos: [830, -30],
                        align: "right",
                        verticalAlign: "bottom",
                    },
                },
            ],
            "10": [
                {
                    marker: {
                        pos: [236, 334],
                    },
                    text: {
                        pos: [127, 205],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [326, 228],
                    },
                    text: {
                        pos: [367, 191],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [365, 99],
                    },
                    text: {
                        pos: [171, 35],
                        align: "right",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [504, 46],
                    },
                    text: {
                        pos: [410, 85],
                        align: "center",
                    },
                },
                {
                    marker: {
                        pos: [620, 141],
                    },
                    text: {
                        pos: [663, 83],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [653, 273],
                    },
                    text: {
                        pos: [475, 295],
                        align: "right",
                    },
                },
                {
                    marker: {
                        pos: [765, 343],
                    },
                    text: {
                        pos: [708, 205],
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
                {
                    marker: {
                        pos: [889, 297],
                    },
                    text: {
                        pos: [935, 300],
                        align: "left",
                    },
                },
                {
                    marker: {
                        pos: [932, 169],
                    },
                    text: {
                        pos: [980, 144],
                        align: "left",
                        verticalAlign: "middle",
                    },
                },
                {
                    marker: {
                        pos: [1018, 57],
                    },
                    text: {
                        pos: [830, -30],
                        align: "right",
                        verticalAlign: "bottom",
                    },
                },
            ],
        }
    },
};
