import React, { Component } from "react";
import { ColGrid, RowGrid } from "legacy-js/react/components/LayoutGrid";
import Button from "legacy-js/react/components/Button2";
import {
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "legacy-js/react/components/UiComponents";
import CheckBox from "legacy-js/react/components/CheckBox";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import ExportDialog from "legacy-js/react/views/PresentationSettings/dialogs/ExportDialog";
import { PdfCompressionTypes } from "legacy-common/constants";

export default class ExportPane extends Component {
    state = { pdfCompressionType: "none" };

    export() {
        ShowDialog(ExportDialog, { presentation: this.props.presentation });
    }

    printToPDF() {
        const { pdfCompressionType } = this.state;
        this.props.presentation.printToPDF(pdfCompressionType);
    }

    handlePdfCompressionToggle = () => {
        this.setState({
            pdfCompressionType: this.state.pdfCompressionType === "none" ? PdfCompressionTypes.HIGH : "none"
        });
    }

    render() {
        const { pdfCompressionType } = this.state;
        return (
            <UIPane className="export-pane">
                <UIPaneHeader>Export Presentation</UIPaneHeader>
                <UIPaneContents>
                    <Section title="Export Presentation">
                        <RowGrid gap={20}>
                            <ColGrid>
                                <Button
                                    icon="cloud_download"
                                    onClick={() => this.export()}
                                    label={<><i style={{ marginRight: "10px" }}></i>Export Presentation...</>}
                                    style={{ backgroundColor: "#11A9E2", fontFamily: "Source Sans Pro", fontSize: "14px", fontWeight: "600", borderRadius: "0px", padding: "10px 20px 10px 20px" }}
                                />
                            </ColGrid>
                            <h3>
                                <strong>IMPORTANT:</strong> Exported
                                presentations do not support Beautiful.ai
                                animations at this time.
                            </h3>
                        </RowGrid>
                    </Section>

                    <Section title="Print Presentation">
                        <RowGrid gap={20}>
                            {/* <ColGrid gap={10}>
                                <CheckBox
                                    value={pdfCompressionType !== "none"}
                                    onChange={this.handlePdfCompressionToggle}
                                    label={
                                        <span>Compress PDF - may result in lower image resolution.</span>
                                    }
                                />
                            </ColGrid> */}
                            <ColGrid>
                                <Button
                                    icon="print"
                                    label={<><i style={{ marginRight: "10px" }}></i>Print Presentation...</>}
                                    onClick={() => this.printToPDF()}
                                    style={{ backgroundColor: "#11A9E2", fontFamily: "Source Sans Pro", fontSize: "14px", fontWeight: "600", borderRadius: "0px", padding: "10px 20px 10px 20px" }}
                                />
                            </ColGrid>
                            <h3>
                                <strong>IMPORTANT:</strong> Browser pop-up
                                blockers may prevent Print Presentation from
                                working. Please set your browser to allow popups
                                from www.beautiful.ai or click on the popup
                                warning in your toolbar once Preparing to Print
                                is completed.
                            </h3>
                        </RowGrid>
                    </Section>
                </UIPaneContents>
            </UIPane>
        );
    }
}
