import { controls } from "legacy-js/editor/ui";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";
import { _ } from "legacy-js/vendor";
import { AssetType } from "legacy-common/constants";

import { ElementSelection } from "../BaseElementEditor";

const TextWithIconSelection = ElementSelection.extend({
    renderControls: function() {
        if (!this.element.showIcon) {
            this.addControl({
                type: controls.BUTTON,
                label: "Add Icon",
                callback: () => {
                    ShowDialog(AddAssetsContainer, {
                        assetType: AssetType.ICON,
                        bindTo: this.element.bindTo,
                        callback: model => {
                            this.element.model.icon = _.cloneDeep(model);
                            this.element.canvas.updateCanvasModel(false);
                        },
                    });
                }
            });
        }
    },
});

export const editors = {
    TextWithIconSelection,
};
