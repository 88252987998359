import { ds } from "js/core/models/dataService";
import { $, _ } from "legacy-js/vendor";
import { app } from "js/namespaces.js";
import { controls } from "legacy-js/editor/ui";
import { getDataSourceControl } from "legacy-js/editor/dataSourceMenu";
import { FormatType } from "legacy-common/constants";
import { ShowDialog, ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import DataLockedDialog from "legacy-js/react/components/Dialogs/DataLockedDialog";

import { Chart_DataTab, ChartTab, EditChartDialog } from "./ChartDialog";
import { FormatOptionsMenu } from "../FormatOptionsMenu";
import { ElementSelection } from "../BaseElementEditor";

const PieChartWedgeSelection = ElementSelection.extend({
    showSelectionBox: false,

    renderControls: function() {
        let $wedgeWidget = this.$el.addEl($.div("ui_widget"));
        let $menu = controls.createPopupButton(this, {
            icon: "gps_fixed",
            showArrow: false,
            items: () => [{
                type: "control",
                view: () => controls.createToggle(this, {
                    label: "Emphasized",
                    property: "emphasized",
                    model: this.element.model,
                    callback: () => ds.selection.rolloverElement = null
                })
            }, {
                type: "control",
                view: () => controls.createColorPicker(this, {
                    label: "Color",
                    includeAuto: true,
                    property: "color",
                    model: this.element.model,
                    selectedColor: this.element.wedgeColor.toRgbString(),
                    paletteColors: this.element.canvas.getTheme().palette.getChartColors()
                })
            }]
        });
        $menu.attr("class", "icon_button").left(-10).top(-10);
        $wedgeWidget.append($menu);

        let angle = Math.degreesToRadians(this.element.startAngle + this.element.angle + 180 * this.element.percentage);
        let offset = this.element.model.emphasized ? (this.element.styles.offset || 10) : 0;

        let selectionBounds = this.getElementSelectionBounds();

        let widgetX = selectionBounds.width / 2 + (this.element.radius + offset) * Math.cos(angle) * app.canvasScale;
        let widgetY = selectionBounds.height / 2 + (this.element.radius + offset) * Math.sin(angle) * app.canvasScale;

        $wedgeWidget.left(widgetX).top(widgetY);
    }
});

const EditPieChartDialog = EditChartDialog.extend({
    renderTabs: function($tabBar) {
        $tabBar.append($.div("tab selected", "Data").data("tab", "data"));
        $tabBar.append($.div("tab", "Pie Chart Options").data("tab", "options"));
    },

    getTabContents(tab) {
        switch (tab) {
            case "data":
                return new PieChart_DataTab({ element: this.element });
            case "options":
                return new PieChart_OptionsTab({ element: this.element });
        }
    },
});

const PieChart_DataTab = Chart_DataTab.extend({
    getColCount: function() {
        return this.getChartData().length;
    },

    getMaxDataLength: function() {
        return 20;
    },

    getChartData: function() {
        return this.element.model.data;
    },

    render: function() {
        this.$el.empty();

        this.chartData = this.element.model.data;

        let $col = this.$el.addEl($.div(""));

        let $importDataControl = getDataSourceControl(this.element.parentElement, this);
        $col.append($importDataControl);

        $col.append(controls.createPopupButton(this, {
            label: "Simple Pie Charts",
            enabled: !this.element.parentElement.hasDataSourceLink(),
            items: [{
                value: "2vertical", label: "Split Vertical"
            }, {
                value: "2horizontal", label: "Split Horizontal"
            }, {
                value: "thirds", label: "Split Third"
            }, {
                value: "quarters", label: "Split Quarter"
            }],
            callback: value => {
                let wedgeCount;
                let startAngle = 0;
                switch (value) {
                    case "2vertical":
                        startAngle = 90;
                        wedgeCount = 2;
                        break;
                    case "2horizontal":
                        startAngle = 0;
                        wedgeCount = 2;
                        break;
                    case "thirds":
                        wedgeCount = 3;
                        startAngle = 0;
                        break;
                    case "quarters":
                        wedgeCount = 4;
                        startAngle = 90;
                        break;
                }
                this.element.model.startAngle = startAngle;

                for (let wedge of this.chartData) {
                    wedge.label = { text: "Slice " + (this.chartData.indexOf(wedge) + 1) };
                    wedge.value = 10;
                    wedge.color = "auto";
                }

                let oldWedgeCount = this.chartData.length;

                if (wedgeCount > oldWedgeCount) {
                    for (let i = oldWedgeCount; i < wedgeCount; i++) {
                        this.chartData.push({ value: 10, label: { text: "Slice " + (i + 1) }, color: "auto" });
                    }
                } else if (wedgeCount < oldWedgeCount) {
                    for (let i = wedgeCount; i < oldWedgeCount; i++) {
                        this.chartData.splice(this.chartData.length - 1);
                    }
                }

                this.element.canvas.updateCanvasModel(true).then(() => {
                    this.refreshRender();
                });
            }
        }));

        var $tableBox = this.$el.addEl($.div("table_box"));

        this.$table = $tableBox.addEl($.table("datatable pie"));

        let $colWidgets = this.$table.addEl($.div("colWidgets"));
        for (let i = 0; i < this.getColCount(); i++) {
            let $colWidget = $colWidgets.addEl($.div("colWidget"));
            $colWidget.append(controls.createPopupButton(this, {
                label: i + 1,
                showArrow: true,
                enabled: !this.element.parentElement.hasDataSourceLink(),
                items: [{
                    value: "add_before", label: "Add Column Before"
                }, {
                    value: "add_after", label: "Add Column After"
                }, {
                    type: "divider"
                }, {
                    value: "delete", label: "Delete Column"
                }],
                callback: item => {
                    switch (item) {
                        case "add_before":
                            this.insertColumn(i);
                            break;
                        case "add_after":
                            this.insertColumn(i + 1);
                            break;
                        case "delete":
                            this.deleteColumn(i);
                            break;
                    }
                }

            }));
        }

        let $name = this.$table.addEl($.tr("name"));
        let $y = this.$table.addEl($.tr("y"));

        $name.append($.td("label locked").text("Label"));
        $y.append($.td("label locked").text("Value"));

        for (let data of this.chartData) {
            let $nameTd = $name.addEl($.td("name").text(data.label.text));
            $nameTd.data("model", data);
            let $valueTd = $y.addEl($.td("y data_value").text(data.value));
            $valueTd.data("model", data);
        }
        this.$table.editableTableWidget();

        this.$table.on("change", "td.name", event => {
            let dataModel = $(event.target).data("model");
            dataModel.label.text = $(event.target).text();
            this.element.canvas.updateCanvasModel(false).then(() => {
                this.refreshRender();
            });
        });
        // listen for changes to update the data in the series
        this.$table.on("change", "td.data_value", event => {
            let dataModel = $(event.target).data("model");
            dataModel.value = parseFloat($(event.target).text());
            this.element.canvas.updateCanvasModel(false).then(() => {
                this.refreshRender();
            });
        });

        // prevent empty values on data_value cells
        this.$table.on("validate", "td.data_value", (event, newValue) => {
            return _.isEmpty(newValue.match(/[a-z]/i)) && newValue > 0;
        });

        let $addColBtn = $tableBox.addEl($.div("add_column_button"));
        $addColBtn.on("click", () => this.addColumn());

        if (!this.element.parentElement.hasDataSourceLink()) {
            let $addColBtn = $tableBox.addEl($.div("add_column_button"));
            $addColBtn.on("click", () => this.addColumn());
        } else {
            this.$table.addClass("datasource_linked");
            $tableBox.on("dblclick", () => {
                ShowDialog(DataLockedDialog, { element: this.element.parentElement, elementEditor: this });
            });
        }

        $colWidgets.width(153 * this.chartData.length);

        return this;
    },

    onShown: function() {
        // $(".colWidgets").width(this.$table[0].scrollWidth - 71);
    },

    addColumn: function() {
        if (this.chartData.length >= this.getMaxDataLength()) {
            return ShowWarningDialog({
                title: "Can't add data",
                message: `Pie charts are limited to ${this.getMaxDataLength()} data points.`,
            });
        }
        this.chartData.push({ label: { text: "" }, value: 10 });
        this.element.canvas.updateCanvasModel(true).then(() => {
            this.refreshRender();
        });
    },

    insertColumn: function(index) {
        if (this.chartData.length >= this.getMaxDataLength()) {
            return ShowWarningDialog({
                title: "Can't add data",
                message: `Pie charts are limited to ${this.getMaxDataLength()} data points.`,
            });
        }

        this.chartData.insert({ label: { text: "" }, value: 10 }, index);
        this.element.canvas.updateCanvasModel(true).then(() => {
            this.refreshRender();
        });
    },

    deleteColumn: function(index) {
        if (this.$table.find("td.name:not(.new_data)").length == 2) {
            return ShowWarningDialog({
                title: "Can't delete slice",
                message: "You must have at least two slices in the pie chart.",
            });
        }
        this.chartData.splice(index, 1);
        this.element.canvas.updateCanvasModel(true).then(() => {
            this.refreshRender();
        });
    },
});

const PieChart_OptionsTab = ChartTab.extend({
    render: function() {
        let $styles = this.$el.addEl($.div("control-group"));

        let $radiusSlider = controls.createSlider(this, {
            label: "Chart Scale",
            min: 0.3,
            max: 1,
            step: .01,
            model: this.element.model,
            property: "chartScale",
            className: "dark",
        });
        $styles.append($radiusSlider);

        $radiusSlider.append(controls.createButton(this, {
            label: "AutoFit",
            callback: () => {
                this.element.autoFit(this.element.calculatedProps);
                this.element.canvas.updateCanvasModel(true);
                this.refreshRender();
            }
        }));

        $styles.append(controls.createSlider(this, {
            label: "Rotate Chart",
            min: -180,
            max: 180,
            step: 1,
            model: this.element.model,
            className: "dark",
            property: "startAngle",
        }));

        $styles.append(controls.createSlider(this, {
            className: "dark",
            label: "Label Scale",
            min: 0.4,
            max: 1.5,
            step: 0.01,
            model: this.element.model,
            property: "labelScale"
        }));
        $styles.append(controls.createMultiToggle(this, {
            label: "Label Position",
            singleLine: true,
            options: [{
                value: "auto", label: "Auto"
            }, {
                value: "outside", label: "Outside"
            }],
            model: this.element.model,
            property: "labelPosition",
        }));

        let $format = this.$el.addEl($.div("control-group"));

        $format.append(controls.createDropdownMenu(this, {
            label: "Format Values",
            value: this.element.format.toTitleCase(),
            menuContents: closeMenu => {
                let $menu = new FormatOptionsMenu({
                    element: this.element,
                    allowedFormats: [FormatType.NONE, FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY],
                    showTableFormatOptions: false
                }).render().$el;
                return $menu;
            },
            onClose: () => {
                this.refreshRender();
            }
        }));

        $format.append(controls.createToggle(this, {
            label: "Sort By Size",
            model: this.element.model,
            property: "sortValues"
        }));
        $format.append(controls.createDropdownMenu(this, {
            label: "Legend",
            items: [{
                value: "none", label: "None"
            }, {
                value: "left", label: "Left"
            }, {
                value: "right", label: "Right"
            }],
            model: this.element.model,
            property: "legendPosition",
            transitionModel: false
        }));

        let $options = this.$el.addEl($.div("control-group"));

        $options.append(controls.createToggle(this, {
            label: "Donut Chart",
            model: this.element.model,
            property: "isDonut"
        }));

        return this;
    }
});

export { EditPieChartDialog };

export const editors = {
    PieChartWedgeSelection,
    EditPieChartDialog,
};
