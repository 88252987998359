import React from "react";
import styled from "styled-components";
import { Button, LinearProgress } from "@material-ui/core";

import { app } from "js/namespaces";
import { FeatureType } from "legacy-common/features";
import { getStaticUrl } from "legacy-js/config";
import { themeColors } from "legacy-js/react/sharedStyles";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import { ds } from "js/core/models/dataService";
import AppController from "legacy-js/core/AppController";
import { BlueButton } from "legacy-js/react/components/UiComponents";
import { openSuggestTemplateForm, openTeamUpgrade, openPricingPage } from "legacy-js/core/utilities/externalLinks";

const ProgressBar = styled(LinearProgress)`
    &&& {        
        width: 200px;
        border-radius: 2px;
        margin: auto;
    }
`;

const CenterAlignContainer = styled.div`
    //height: calc(100% - 80px);
    display: flex;
    align-items: center;
    padding-top: 30px;
`;

const NoticeContainer = styled.div`
  background: white;
  padding: 80px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    margin-bottom: 20px;
    max-width: 525px;
  }

  img {
    max-width: ${({ maxImageWidth = null }) => Math.max(maxImageWidth ?? 0, 400)}px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    color: ${themeColors.darkGray};
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    color: ${themeColors.darkGray};
    max-width: 600px;
  }

  a {
    color: ${themeColors.ui_blue};
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
  }
`;

const ExtraContentContainer = styled.div`
    padding-top: 15px;
`;

export const Notice = props => {
    let {
        image = "shake",
        imageWidth = null,
        title = "This is the title of the notice. It should be replaced.",
        message,
        children,
        showProgressBar = false
    } = props;

    if (image === "sad") {
        image = getStaticUrl(`/images/bai-bot/bai-bot-sad.png`);
        imageWidth = 60;
    } else if (image === "shake") {
        image = getStaticUrl(`/images/bai-bot/bai-bot-shake.gif`);
        imageWidth = 60;
    }

    return (
        <CenterAlignContainer>
            <NoticeContainer maxImageWidth={imageWidth}>
                {
                    image &&
                    <img src={image} width={imageWidth} />
                }
                {
                    showProgressBar &&
                    <ProgressBar />
                }
                {
                    title &&
                    <div className="title">{title}</div>
                }
                {
                    message
                }
                {
                    !!children &&
                    <ExtraContentContainer>
                        {children}
                    </ExtraContentContainer>
                }
            </NoticeContainer>
        </CenterAlignContainer>
    );
};

export const NoMatchNotice = props => {
    props = {
        image: "sad",
        title: "No matches found.",
        ...props,
    };

    return <Notice {...props} />;
};

export const NoMatchTemplatesNotice = ({
    onAddClassicSlide,
}) => {
    let hasCta = app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CLASSIC_SLIDES, AppController.workspaceId);

    return (
        <NoMatchNotice
            message={
                <>
                    <div>Try a different search term or design your slide from scratch with a blank Classic Slide.</div>
                    {
                        hasCta &&
                        <BlueButton
                            style={{ marginBottom: 0 }}
                            onClick={onAddClassicSlide}
                        >Add Classic Slide</BlueButton>
                    }
                    <Button
                        color="primary"
                        onClick={openSuggestTemplateForm}
                    >Suggest a Smart Slide Template</Button>
                </>
            }
        />
    );
};

// TEAM NOTICES

const NoTeamContentNotice = ({
    image,
    titleLead,
    messageLead,
    titleMember,
    messageMember,
    titleNoTeam,
    messageNoTeam,
}) => {
    let isLibOrOwner = ds.teams.isUserLibrarianOrOwner();

    if (UIController.isTeamWorkspace()) {
        if (isLibOrOwner) {
            return (
                <Notice
                    image={image}
                    title={titleLead}
                    message={messageLead}
                />
            );
        } else {
            return (
                <Notice
                    image={image}
                    title={titleMember}
                    message={messageMember}
                />
            );
        }
    } else {
        return (
            <Notice
                image={image}
                title={titleNoTeam}
                message={messageNoTeam}
            />
        );
    }
};

export const NoTeamTemplateNotice = () => (
    <NoTeamContentNotice
        image={getStaticUrl(`/images/notice-banner/team-templates.gif`)}
        titleLead="Create a Template for your team to start from."
        messageLead={
            <p>
                <span>Give your team a headstart, with structure and key slides already in place. </span>
                <br />
                <span>Check out our </span>
                <a
                    target="_blank"
                    href="https://support.beautiful.ai/hc/en-us/articles/4405716068365-Team-Template-Overview"
                >FAQs</a>
                <span> to learn more.</span>
            </p>
        }
        titleMember="There are no published templates in this workspace yet."
        messageMember="Team Templates are created by Team Librarians or Owners to give you a headstart, with structure, key slides and design elements already in place."
        titleNoTeam="Share presentation templates with your team."
        messageNoTeam={
            <>
                <div>Give your team a headstart with presentation structure, key slides and design elements already in place. </div>
                <BlueButton
                    style={{ marginBottom: 0 }}
                    onClick={() => openTeamUpgrade({ cta: "TeamTemplates" })}
                >Get Started</BlueButton>
                {/* <Button
                    color="primary"
                    onClick={() => openSalesCalendar({ cta: "TeamTemplates" })}
                >Talk to an expert</Button> */}
            </>
        }
    />
);

export const NoTeamSlideNotice = () => (
    <NoTeamContentNotice
        image={getStaticUrl(`/images/notice-banner/team-slides.gif`)}
        titleLead="Create a shared library of finished Team Slides."
        messageLead={
            <p>
                <span>Simply create a slide once, tag it, and your team can use it infinitely. Update your slide and it'll update everywhere it's used. Check out our </span>
                <a
                    target="_blank"
                    href="https://support.beautiful.ai/hc/en-us/articles/360047188952-Shared-Slides-Overview"
                >FAQs</a>
                <span> to learn more.</span>
            </p>
        }
        titleMember="No slides have been shared with your team yet."
        messageMember="These are the slides you use over and over. Team Librarians and Owners can create a searchable library of finished slides for you to grab and go. When a slide is updated, it'll update everywhere it's used."
        titleNoTeam="Share slides with your team."
        messageNoTeam={
            <>
                <div>These are the slides you use over and over. Create a slide once, share it, and your team can use it infinitely. When a slide is updated, it'll update everywhere it's used. </div>
                <BlueButton
                    style={{ marginBottom: 0 }}
                    onClick={() => openTeamUpgrade({ cta: "TeamSlides" })}
                >Get Started</BlueButton>
            </>
        }
    />
);

export const SharedSlideNotice = () => (
    <NoTeamContentNotice
        image={getStaticUrl(`/images/notice-banner/team-slide-templates-landscape.gif`)}
        titleLead="Give your team a headstart with a custom library of slide templates."
        messageLead={
            <p>
                <span>Add content or instructional copy that your team can run with. Check out our </span>
                <a
                    target="_blank"
                    href="https://support.beautiful.ai/hc/en-us/articles/360047188952-Shared-Slides-Overview"
                >FAQs</a>
                <span> to learn more.</span>
            </p>
        }
        titleMember="No templates have been shared with your team yet."
        messageMember="Get a headstart with a custom library of slide templates—with content specific to your work already in place."
        titleNoTeam="Get a headstart with a custom library of slide templates."
        messageNoTeam={
            <>
                <div>Add content or instructional copy that your team can run with.</div>
                <BlueButton
                    style={{ marginBottom: 0 }}
                    onClick={() => openTeamUpgrade({ cta: "TeamSlides" })}
                >Get Started</BlueButton>
            </>
        }
    />);

export const SharedSlideGeneralNotice = () => (<Notice
    image={getStaticUrl(`/images/notice-banner/team-slide-templates-landscape.gif`)}
    title="No templates have been shared with your team yet."
    message="Get a headstart with a custom library of slide templates—with content specific to your work already in place."
/>);

export const NoTeamAssetNotice = () => (
    <NoTeamContentNotice
        image={getStaticUrl(`/images/notice-banner/team-assets.gif`)}
        titleLead="Keep your team on-track and on-brand with Team Assets."
        messageLead={
            <p>
                <span>Create a library of logos and brand assets for your team to add to their slides. </span>
                <br />
                <span>Check out our </span>
                <a
                    target="_blank"
                    href="https://support.beautiful.ai/hc/en-us/articles/5671414223757-Team-Assets-"
                >FAQs</a>
                <span> to learn more.</span>
            </p>
        }
        titleMember="No assets have been shared with your team yet."
        messageMember="Stop searching for the right logo or image. Team Librarians and Owners can create a library of brand assets so your presentation is always on brand and up to date."
        titleNoTeam="Share images and logos with your team."
        messageNoTeam={
            <>
                <div>
                    Keep your team's presentations consistently on brand and up to date with a library of approved brand assets. When an asset is updated, it'll update everywhere it's used.
                </div>
                <BlueButton
                    style={{ marginBottom: 0 }}
                    onClick={() => openTeamUpgrade({ cta: "TeamAssets" })}
                >Get Started</BlueButton>
                {/* <Button
                    color="primary"
                    onClick={() => openSalesCalendar({ cta: "TeamAssets" })}
                >Talk to an expert</Button> */}
            </>
        }
    />
);

export const NoMyResourcesThemeNotice = () => {
    const userIsBasicInWorkspace = app.user.features.isFeatureEnabled(FeatureType.UPGRADE, AppController.workspaceId);

    const title = "Keep every slide effortlessly on brand with a custom theme.";
    const message = userIsBasicInWorkspace ? (
        <>
            <p>
                <span>Create a theme unique to your brand with colors, fonts and styling—then save it to use with every new deck. Choose from our built-in fonts, or upgrade to Pro to create a theme using your own fonts. Check out our </span>
                <a
                    target="_blank"
                    href="https://support.beautiful.ai/hc/en-us/categories/360002178751-Theming-and-Branding"
                >FAQs</a>
                <span> to learn more about theming.</span>
            </p>
            <BlueButton
                style={{ marginBottom: 0 }}
                onClick={() => openPricingPage()}
            >Upgrade to Pro</BlueButton>
        </>
    ) : (
        <p>
            <span>Create a theme unique to your brand with colors, fonts and styling—then save it to use with every new deck. Check out our </span >
            <a
                target="_blank"
                href="https://support.beautiful.ai/hc/en-us/categories/360002178751-Theming-and-Branding"
            >FAQs</a>
            <span> to learn more about theming.</span>
        </p>
    );

    return (
        <Notice
            image={getStaticUrl(`/images/notice-banner/themes-landscape.gif`)}
            title={title}
            message={message}
        />
    );
};
