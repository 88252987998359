import React, { Component } from "react";
import styled from "styled-components";

import Api from "js/core/api";
import getLogger from "js/core/logger";
import { getStaticUrl } from "legacy-js/config";
import * as dropboxAuth from "js/core/oauth/dropboxAuth";
import Dropbox from "js/core/utilities/dropbox";
import { downloadFileFromDropbox } from "js/core/utilities/downloadFile";
import { FileType, fileTypesToExtensions } from "legacy-js/core/utilities/fileSourcesAndTypes";
import { PopupBlockedError } from "js/core/utilities/selfClosingWindow";
import { trackActivity } from "js/core/utilities/utilities";
import { BlueButton, TextButton } from "legacy-js/react/components/UiComponents";
import Spinner from "legacy-js/react/components/Spinner";
import { ShowErrorDialog, ShowConfirmationDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap30 } from "legacy-js/react/components/Gap";

const logger = getLogger();

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #A9A9A9;
    padding: 30px 50px;
    margin-bottom: 30px;
`;

interface Props {
    fileTypes: FileType[],
    handleImportedFile: (file: File) => void,
}

interface State {
    userDropboxLinkFetching: boolean,
    userHasDropboxOfflineAccess: boolean,
    userDropboxAccessToken: string,
    disconnecting: boolean,
}

export default class DropboxPicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userDropboxLinkFetching: false,
            userHasDropboxOfflineAccess: false,
            userDropboxAccessToken: "",
            disconnecting: false,
        };
    }

    componentDidMount() {
        this.fetchDropboxTokens();
    }

    fetchDropboxTokens = async () => {
        this.setState({ userDropboxLinkFetching: true });

        try {
            const { hasTokens, accessToken } = await Api.getDropboxTokens.get();
            this.setState({ userDropboxLinkFetching: false, userHasDropboxOfflineAccess: hasTokens, userDropboxAccessToken: accessToken });
        } catch (err) {
            logger.error(err, "[DropboxPicker] Api.getDropboxTokens.get() failed");
            this.setState({ userDropboxLinkFetching: false });
        }
    }

    handleSelectedFile = async file => {
        try {
            if (file.cancelled) {
                logger.info("[DropboxPicker] User cancelled file selection");
                return;
            }

            const fileContent = await downloadFileFromDropbox({ name: file.name, url: file.url });
            this.props.handleImportedFile(fileContent);
        } catch (err) {
            logger.error(err, "[DropboxPicker] this.fetchSpreadsheetData() failed", { fileId: file?.id });
            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: err.message
            });
        }
    }

    handleDropboxAuth = async () => {
        const { userHasDropboxOfflineAccess } = this.state;

        try {
            if (!userHasDropboxOfflineAccess) {
                trackActivity("Dropbox", "DataLinkGrantAccess", null, null, {}, { audit: true, skipAmplitude: false });

                const tokens = await dropboxAuth.grantOfflineAccess();

                this.setState({ userHasDropboxOfflineAccess: true, userDropboxAccessToken: tokens.accessToken });
            }

            await this.openPicker();
        } catch (err) {
            if (err instanceof PopupBlockedError) {
                // User cancelled, ignore
                return;
            }

            logger.error(err, "[DropboxPicker] dropboxAuth.grantOfflineAccess() failed");
        }
    }

    handleDisconnect = async () => {
        ShowConfirmationDialog({
            title: "Disconnect Account?",
            message: (
                <span>
                    Any linked data will no longer automatically update.
                    <br /><br />
                    You can link to this account again at any time.
                </span>
            ),
            buttonOptions: { acceptButtonColor: "red" },
            okButtonLabel: "Disconnect",
            acceptCallback: async () => {
                this.setState({ disconnecting: true });

                try {
                    logger.info("[DropboxPicker] Deleting credentials from server");
                    await Api.unlinkDropboxAuth.delete();

                    this.setState({
                        disconnecting: false,
                        userHasDropboxOfflineAccess: false,
                        userDropboxAccessToken: ""
                    });
                } catch (err) {
                    logger.error(err, "[DropboxPicker] handleDisconnect() failed");
                    this.setState({ disconnecting: false });

                    ShowErrorDialog({
                        error: "Sorry, we could not process your request",
                        message: err.message
                    });
                }
            }
        });
    }

    openPicker = async () => {
        const accessToken = this.state.userDropboxAccessToken;

        if (accessToken) {
            const file = await Dropbox.chooseFile(fileTypesToExtensions(this.props.fileTypes).map(ext => `.${ext}`));
            this.handleSelectedFile(file);
        }
    }

    render() {
        const { userDropboxLinkFetching, userHasDropboxOfflineAccess, disconnecting } = this.state;

        return (
            <Container>
                <img src={getStaticUrl("/images/data-linking/dropbox.svg")} width={50} height={50} />
                <Gap30 />
                {!userDropboxLinkFetching && (<>
                    <BlueButton /** @ts-ignore */
                        onClick={this.handleDropboxAuth}
                    >
                        {userHasDropboxOfflineAccess ? "Select File from Dropbox" : "Connect to Dropbox"}
                    </BlueButton>
                    {userHasDropboxOfflineAccess && (
                        <>
                            <Gap10 />
                            <TextButton /** @ts-ignore */
                                onClick={this.handleDisconnect} color="secondary"
                                disabled={disconnecting}
                            >
                                {disconnecting && <Spinner />}
                                {!disconnecting && "Disconnect Account"}
                            </TextButton>
                        </>
                    )}
                </>)}
            </Container>
        );
    }
}
