import { controls } from "legacy-js/editor/ui";
import { $, _, SVG } from "legacy-js/vendor";
import { ds } from "js/core/models/dataService";
import { AssetType, DragType, ListStyleType, ParagraphStyle } from "legacy-common/constants";
import { ShowDialog, ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import getLogger, { LogGroup } from "js/core/logger";

import { CollectionElementSelection, CollectionItemElementSelection } from "../../CollectionElementEditor";
import { ElementOptionsMenu, ElementRollover } from "../../BaseElementEditor";

import { TextList } from "../../../elements/elements/Lists/TextList";

const logger = getLogger(LogGroup.ELEMENTS);

export const TextListSelection = CollectionElementSelection.extend({

    renderControls() {
        this.createAddItemButton({
            skipAddItemPopup: true,
            model: {
                col: this.element.columns - 1
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "List Style",
            property: "listStyle",
            markStylesAsDirty: true,
            items: [{
                label: "Bullet",
                value: ListStyleType.BULLET,
                icon: "format_list_bulleted"
            }, {
                label: "Numbered",
                value: ListStyleType.NUMBERED,
                icon: "pin"
            }, {
                label: "Text",
                value: ListStyleType.TEXT,
                icon: "notes"
            }, {
                label: "Check",
                value: ListStyleType.CHECKBOX,
                icon: "check"
            }]
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Auto-Arrange",
            items: [{
                value: 1, label: "One Column"
            }, {
                value: 2, label: "Two Columns", enabled: this.element.itemCount > 1
            }, {
                value: 3, label: "Three Columns", enabled: this.element.itemCount > 2
            }],
            value: this.element.autoArrange,
            enabled: !this.element.showColumnHeaders,
            callback: value => {
                this.element.model.autoArrange = value;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const TextListOptionsMenu = ElementOptionsMenu.extend({
    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Column Headers",
            property: "showColumnHeaders",
        });

        this.addControl({
            type: controls.NUMERIC,
            label: "Start Numbering at",
            min: 1,
            max: 999,
            property: "startNum"
        });
    }
});

const TextListColumnSelection = CollectionItemElementSelection.extend({
    getDragAxis() {
        return "x";
    }
});

const TextListItemSelection = CollectionItemElementSelection.extend({

    getDragOptions: function() {
        return {
            type: DragType.INSERT_VERTICAL,
            multipleColumns: true,
            insertGap: this.element.parentElement.styles.vGap,
            transitionOnDrop: true,
            dragTargetOffsetX: 50
        };
    },

    canDrag: function() {
        return this.element.canDrag;
    },

    getDropTargets: function(containerElement) {
        let textList = containerElement.findClosestOfType(TextList);

        // create targets from all the TextListItems in each column
        let targets = textList.itemElements;

        $(".add-column-indicator").remove();

        let columns = textList.calculatedProps.columns;

        let paddedTextListBounds = textList.canvasBounds.deflate(textList.styles.padding);

        // create a target for any empty columns
        for (let column of columns) {
            if (column.items.length === 0) {
                targets.push({
                    id: "column",
                    col: column.col,
                    canvasBounds: new geom.Rect(paddedTextListBounds.left + column.bounds.left, paddedTextListBounds.top + 20, column.bounds.width, paddedTextListBounds.height)
                });
            }
        }

        if (columns.length < textList.maxColumns) {
            let createColumnIndicator = colIndex => {
                let $indicator = app.mainView.editorView.selectionLayer.$el.addEl($.div(`add-column-indicator col${colIndex}`));
                $indicator.append($.div("label", "Add Column"));
                $indicator.append($.div("line"));
                return $indicator;
            };

            let colGap = textList.styles.hGap;

            // for (let column of columns) {
            //     let targetBounds = new geom.Rect(paddedTextListBounds.left + column.bounds.left - colGap - 30, paddedTextListBounds.top + 20, colGap - 20, paddedTextListBounds.height);
            //     targets.push({
            //         id: "add-column",
            //         col: column.col,
            //         canvasBounds: targetBounds
            //     });
            //     let $dropIndicator = createColumnIndicator(column.col);
            //     $dropIndicator.setBounds(this.canvasToSelectionCoordinates(targetBounds));
            // }

            let lastCol = _.last(columns);
            let targetBounds = new geom.Rect(paddedTextListBounds.left + lastCol.bounds.right + 15, paddedTextListBounds.top, colGap + 40, paddedTextListBounds.height);

            targets.push({
                id: "add-column",
                col: lastCol.col + 1,
                canvasBounds: targetBounds
            });

            // TODO update to uuse canvas selectionLayer once i merge that code
            let $dropIndicator = createColumnIndicator(lastCol.col + 1);
            $dropIndicator.setBounds(this.canvasToSelectionCoordinates(targetBounds));
        }
        return targets;
    },

    renderInsertDropIndicator: function(dropTarget, dropEdge) {
        $(".drop-target-indicator").remove();

        if (dropTarget) {
            $(".add-column-indicator").removeClass("hover");

            // are we inserting a new column?
            if (dropTarget.id.startsWith("add-column")) {
                $(`.add-column-indicator.col${dropTarget.col}`).addClass("hover");
                return;
            }

            // are we dropping into an empty column?
            if (dropTarget.id === "column") {
                let containerElement = this.element.parentElement;

                let $dropIndicator = app.mainView.editorView.selectionLayer.$el.addEl($.div("drop-target-indicator"));
                $dropIndicator.setBounds(this.canvasToSelectionCoordinates(dropTarget.canvasBounds));
            } else {
                // render the default drop indicator
                CollectionItemElementSelection.prototype.renderInsertDropIndicator.apply(this, arguments);
            }
        }
    },

    updateModelFromDropAction: async function(dropTarget, dragProps, insertBeforeTarget) {
        let containerElement = dragProps.dragItem.parentElement;
        let textList = containerElement.findClosestOfType(TextList);

        containerElement.model.autoArrange = false;

        if (dropTarget && dropTarget.id.startsWith("add-column")) {
            // shift any items if we are inserting a new column
            if (dropTarget.col < textList.columns) {
                let i = 0;
                for (let col of textList.getItemsInColumns()) {
                    if (i >= dropTarget.col) {
                        for (let item of col) {
                            item.model.col++;
                        }
                    }
                    i++;
                }
            }
            // drop into new column
            dragProps.dragItem.model.col = dropTarget.col;
        } else if (dropTarget && dropTarget.id === "column") {
            // drop into empty column
            dragProps.dragItem.model.col = dropTarget.col;
        } else {
            // drop on existing item
            dragProps.dragItem.model.col = dropTarget.model.col;
            await CollectionItemElementSelection.prototype.updateModelFromDropAction.apply(this, arguments);
        }

        // remove any empty columns
        let itemsInColumns = textList.getItemsInColumns();
        let emptyColCount = 0;
        for (let col of itemsInColumns) {
            if (col.length == 0) {
                emptyColCount++;
            }
            for (let item of col) {
                item.model.col -= emptyColCount;
            }
        }
    },

    onStopDrag: function(event, position, dragProps, options = { forceRender: false }) {
        CollectionItemElementSelection.prototype.onStopDrag.apply(this, arguments);
        $(".add-column-indicator").remove();
    },

    renderControls: function() {
        let $controls = $.div("small-control-list");
        this.$el.append($controls);

        if (!this.element.text.showBody) {
            $controls.append(controls.createButton(this, {
                label: "Add text",
                callback: () => {
                    this.element.text.model.text_format = ParagraphStyle.PARAGRAPH;
                    this.element.text.showBodyPlaceholder = true;
                    this.element.text.canvas.refreshCanvas({ transition: false }).then(() => {
                        ds.selection.element = this.element.text.elements.body;
                    }).catch(err => {
                        this.element.text.showBodyPlaceholder = false;
                        this.element.text.canvas.revertCanvasModel();
                        ShowWarningDialog({
                            title: "Sorry, we were unable to make this change",
                            message: err.message,
                        });
                    });
                }
            }));

            $controls.append(controls.createButton(this, {
                label: "Add sub bullet",
                callback: () => {
                    this.element.text.model.text_format = ParagraphStyle.BULLET_LIST;
                    this.element.text.showBodyPlaceholder = true;
                    this.element.text.canvas.refreshCanvas({ transition: false }).then(() => {
                        ds.selection.element = this.element.text.elements.body;
                    }).catch(err => {
                        this.element.text.showBodyPlaceholder = false;
                        this.element.text.canvas.revertCanvasModel();
                        ShowWarningDialog({
                            title: "Sorry, we were unable to make this change",
                            message: err.message,
                        });
                    });
                }
            }));
        }

        $controls.append(controls.createButton(this, {
            label: "Add another bullet",
            callback: () => {
                let newModel = this.element.parentElement.addItem({ col: this.element.model.col }, this.element.itemIndex + 1);
                this.element.canvas.updateCanvasModel(false).then(() => {
                    let newItem = _.get(this.element.parentElement.elements, newModel.id);
                    if (newItem) {
                        ds.selection.element = newItem.text.title;
                    }
                }).catch(() => {
                    ShowWarningDialog({
                        title: "Sorry, we can't make this change",
                        message: "We aren't able to fit another item to this layout.",
                    });
                });
            }
        }));
    },

    _layout: function() {
        this.$el.find(".small-control-list").left(0).top(this.$el.height() + 5);
    },

    onDeleteItem() {
        let columnItems = this.element.parentElement;
        columnItems.deleteItem(this.element.id);

        if (columnItems.itemCollection.length == 0) {
            columnItems.getRootElement().deleteItem(columnItems.parentElement.id);
        }

        this.element.canvas.updateCanvasModel(this.transitionOnDelete(), true);
    },

});

const TextListCheckBoxRollover = ElementRollover.extend({

    onClick: function(event) {
        if (app.isEditingText) return;

        switch (this.element.model.checkState) {
            case "unchecked":
                this.element.model.checkState = "crossed-out";
                break;
            case "crossed-out":
                this.element.model.checkState = "checked";
                break;
            case "checked":
            default:
                this.element.model.checkState = "unchecked";
                break;
        }
        this.element.canvas.markStylesAsDirty();
        this.element.canvas.updateCanvasModel(false);
    },

});

const TextListIconRollover = ElementRollover.extend({

    renderControls() {
        this.$el.opacity(0);

        let $menu = this.$el.addEl(controls.createPopupButton(this, {
            icon: "star",
            showArrow: false,
            menuContents: closeMenu => {
                let $menu = $.div("pictorial-chart-menu");

                let loadIcon = ($menu, iconId) => {
                    let $item = $menu.addEl($.div("icon"));
                    $item.data("icon", iconId);
                    ds.assets.getAssetById("icon-" + app.currentTheme.get("iconStyle") + "-" + iconId, "icon").then(icon => {
                        const url = icon.get("original");
                        return fetch(url).then(res => res.text()).then(data => {
                            let r = / d="(.*?)"/m;
                            let pathString = r.exec(data)[1];
                            let svg = SVG($item[0]);
                            svg.path(pathString);
                            svg.viewbox(0, 0, 128, 128);
                        });
                    }).catch(err => {
                        logger.error(err, "[TextListIconRollover] failed to load icon", { iconId });
                    });

                    $item.on("click", () => {
                        this.element.model.icon = iconId;
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    });
                };

                let $icons = $menu.addEl($.div("preset-icons"));

                for (let icon of ["thumbs-up", "thumbs-down", "star", "star-half", "star-none", "full", "quarters-three", "contrast", "quarter", "none", "check-yes", "x"]) {
                    loadIcon($icons, icon);
                }

                let $choose = $menu.addEl(controls.createButton(this, {
                    label: "Choose Icon...",
                    callback: () => {
                        closeMenu();
                        ShowDialog(AddAssetsContainer, {
                            assetType: AssetType.ICON,
                            workspaceId: ds.selection.presentation.getWorkspaceId(),
                            callback: model => {
                                const {
                                    content_type,
                                    content_value,
                                } = model;
                                if (content_type == AssetType.ICON) {
                                    this.element.model.iconId = content_value;
                                    this.element.canvas.updateCanvasModel(false);
                                } else {
                                    ShowWarningDialog({
                                        title: "Unsupported asset type",
                                        message: "Please choose an icon from the asset chooser.",
                                    });
                                }
                            },
                        });
                    }
                }));

                return $menu;
            },
        }));

        $menu.opacity(0);
    }

});

export const editors = {
    TextListSelection,
    TextListColumnSelection,
    TextListOptionsMenu,
    TextListItemSelection,
    TextListCheckBoxRollover,
    TextListIconRollover
};
