import { ds } from "js/core/models/dataService";
import { _ } from "legacy-js/vendor";
import { controls } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";

import { TextElementSelection } from "./textEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";

const ProcessDiagramSelection = CollectionElementSelection.extend({
    getAddItemLabel: function() {
        return "Add Step";
    },

    renderControls: function() {
        this.createAddItemButton();
    }
});

const ProcessDiagramChevronsSelection = ProcessDiagramSelection.extend({
    renderControls: function() {
        this.createAddItemButton();

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Layout",
            items: [{
                value: "alternate", label: "Alternate Above and Below"
            }, {
                value: "above", label: "Text Above"
            }, {
                value: "below", label: "Text Below"
            }],
            property: "textLayout",
            markStylesAsDirty: true
        });
    }
});
const ProcessDiagramBoxesSelection = ProcessDiagramSelection.extend({});

const ProcessDiagramBoxItemSelection = CollectionItemElementSelection.extend({});

const ProcessDiagramChevronItemSelection = CollectionItemElementSelection.extend({
    getDragAxis: function() {
        return this.element.totalRows > 1 ? "" : "x";
    },

    getElementDragOffset() {
        return new geom.Point(
            (this.element.selectionPadding.left == undefined ? this.element.selectionPadding : this.element.selectionPadding.left),
            (this.element.selectionPadding.top == undefined ? this.element.selectionPadding : this.element.selectionPadding.top) - this.element.shapeContainer.bounds.top
        );
    },

    renderControls: function() {
        this.createEmphasizeWidget(this.element.showBody);

        if (!this.element.showBody) {
            this.addControl({
                type: controls.BUTTON,
                icon: "add",
                label: "Add Description",
                callback: () => {
                    this.element.showBodyPlaceholder = true;
                    this.element.canvas.updateCanvasModel(true).then(() => {
                        ds.selection.element = this.element.body;
                    });
                }
            });
        }
    }
});

const ProcessDiagramDescriptionSelection = TextElementSelection.extend({
    getCursor: function() {
        return "text";
    },

    renderControls: function() {
        this.setupTextEditor();
        this.createDeleteComponentWidget({
            action: () => {
                this.element.parentElement.model.body = "";
                this.element.parentElement.showBodyPlaceholder = false;
                this.element.canvas.updateCanvasModel(true).then(() => {
                    ds.selection.element = this.element.parentElement.body;
                });
            }
        });
    },

    cleanUp: function() {
        if (!this.element.model.body || this.element.model.body == "" || this.element.model.body.text == "") {
            this.element.parentElement.showBodyPlaceholder = false;
            _.defer(() => {
                this.element.canvas.refreshCanvasAutoRevert({ transition: true });
            });
        }

        TextElementSelection.prototype.cleanUp.apply(this, arguments);
    }
});

export const editors = {
    ProcessDiagramSelection,
    ProcessDiagramChevronsSelection,
    ProcessDiagramBoxesSelection,
    ProcessDiagramBoxItemSelection,
    ProcessDiagramChevronItemSelection,
    ProcessDiagramDescriptionSelection,
};
