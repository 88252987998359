import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Paper, Typography, LinearProgress, DialogContent } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { getStaticUrl } from "legacy-js/config";
import { dialogTheme } from "legacy-js/react/materialThemeOverrides";

const slideGridImage = getStaticUrl("/images/onboarding/slide-grid.jpg");
const spacing = 16;

export const ErrorBanner = styled.div`
     background: rgb(247, 181, 174);
     color: rgb(97, 26, 21);
     padding-top: ${spacing * 1};
     padding-bottom: ${spacing * 1};
     padding-left: ${spacing * 3};
     padding-right: ${spacing * 3};
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const StandalonePage = ({ children, style, maxWidth = 650 }) => (
    <MuiThemeProvider theme={dialogTheme}>
        <div data-cy="standalone-page" style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            background: "url(" + slideGridImage + ") no-repeat top right",
            backgroundSize: "auto 100%",
            ...style
        }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ flexBasis: maxWidth }}>
                    {children}
                </div>
            </div>
        </div>
    </MuiThemeProvider>
);

export const StandaloneForm = ({ children, loading, error, style, formStyle }) => (
    <Paper style={style} elevation={3}>
        {loading && <LinearProgress />}
        {error && <ErrorBanner data-cy="error">{error}</ErrorBanner>}
        <form noValidate style={formStyle}>
            {children}
        </form>
    </Paper>
);

export const StandaloneDialogContent = styled(DialogContent)`
    padding: 20px 40px !important;
    overflow: hidden;
`;

export const FormTitle = ({ children }) => (
    <Typography variant="h6" component="h1" align="center">
        {children}
    </Typography>
);

export const FormContent = ({ children }) => (
    <Typography align="center">
        {children}
    </Typography>
);

export const FormIcon = ({ src, paddingTop = 30 }) => (
    <div style={{ textAlign: "center", paddingTop }}>
        <img src={src} width={75} height={75} />
    </div>
);
