import React, { Component, useRef, useEffect } from "react";
import styled from "styled-components";
import { DialogContent } from "@material-ui/core";
import { BeautifulDialog } from "./BaseDialog";

const DialogContentWrapper = styled(DialogContent)`
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
        font-family: "Source Sans Pro";
        width: 100%;
        height: 500px;
    }
`;

interface Props {
    token: string,
    fileExtensions: string[],
    handleSelectedFile: Function,
    closeDialog: Function
}

function BoxFilePicker({ token, fileExtensions, handleSelectedFile, closeDialog }: Props) {
    const ref = useRef();

    useEffect(() => {
        if (token) {
            // @ts-ignore
            const filePicker = new window.Box.FilePicker();

            filePicker.show("0", token, { // @ts-ignore
                container: ref.current,
                extensions: fileExtensions,
                maxSelectable: 1,
            });

            filePicker.on("choose", files => {
                handleSelectedFile(files[0]);
                closeDialog();
            });
        }
    }, []);

    return (
        <div ref={ref} className="box-picker-container" />
    );
}

export default class BoxFilePickerDialog extends Component<Props> {
    render() {
        return (
            <BeautifulDialog closeButton closeDialog={this.props.closeDialog}>
                <DialogContentWrapper>
                    <BoxFilePicker {...this.props} />
                </DialogContentWrapper>
            </BeautifulDialog>
        );
    }
}
