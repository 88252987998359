import React from "react";
import {
    FormControlLabel,
    Checkbox as MaterialCheckbox,
    Tooltip
} from "@material-ui/core";
import styled from "styled-components";

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: -3px;
  }
`;

class CheckBox extends React.Component {
    render() {
        let {
            id,
            checked,
            value,
            label,
            disabled,
            parentState,
            showUpgradeBadge,
            toolTip,
            color = "default",
        } = this.props;

        let checkbox = (
            <div>
                <StyledFormControlLabel
                    classes={{
                        label: "control-label"
                    }}
                    control={
                        <MaterialCheckbox
                            checked={checked}
                            color={color}
                            onChange={event => {
                                this.props.onChange(event.target.checked, id);
                            }}
                            disabled={!!disabled}
                        />
                    }
                    label={label}
                />
            </div>
        );

        if (toolTip) {
            checkbox = (
                <Tooltip enterDelay={300} title={toolTip}>
                    {checkbox}
                </Tooltip>
            );
        }

        return checkbox;
    }
}

export default CheckBox;
