import jsSHA from "jssha";

function sha256(string) {
    if (string == undefined) {
        string = "";
    }

    const jsSHAInstance = new jsSHA("SHA-256", "TEXT");
    jsSHAInstance.update(string);
    return jsSHAInstance.getHash("HEX");
}

/**
 * Fast and dumm crc function, watch out for collisions!
 */
function crc(text) {
    if (text == undefined || text.length === 0) {
        return "0";
    }

    let char;
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        char = text.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0;
    }
    return (hash < 0 ? hash * -2 : hash).toString();
}

/**
 * Calculates sha256 or crc of the supplied object, invariant to fields reordering
 */
function getObjectHash(object, useCRC = false, seenObjects = []) {
    if (typeof object === "object" && object !== null) {
        if (seenObjects.includes(object)) {
            return "";
        }

        seenObjects.push(object);
    }

    const hash = text => useCRC ? crc(text) : sha256(text);
    const hashObject = object => getObjectHash(object, useCRC, seenObjects);

    if (Array.isArray(object)) {
        const hashedArray = object
            .map(item => hashObject(item));
        hashedArray.sort((a, b) => (a > b ? 1 : -1));
        return hash(hashedArray.join(""));
    }

    if (typeof object === "object" && object !== null) {
        const hashedFields = Object.keys(object)
            .sort((a, b) => (a > b ? 1 : -1))
            .map(key => hashObject(object[key]));
        return hash(hashedFields.join(""));
    }

    return hash(JSON.stringify(object));
}

export default getObjectHash;
