import React, { Component } from "reactn";
import { DialogTitle, DialogActions } from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import { Gap20 } from "legacy-js/react/components/Gap";

export default class InputConfirmationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { value: "" };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { input } = this.props;
        this.setState({ value: input.value || "" });
    }

    handleChange = evt => {
        this.setState({ value: evt.target.value });
    }

    handleKeyPress = event => {
        if (event.key == "Enter") {
            this.props.acceptCallback && this.props.acceptCallback(this.state.value);
            this.props.closeDialog();
        }
    }

    selectTextField = event => {
        // auto select the text field when focused
        event.target.select();
    }

    render() {
        const {
            input = {},
            title,
            message,
            autoSelect = true,
            cancelCallback,
            acceptCallback,
            cancelButtonLabel,
            okButtonLabel
        } = this.props;
        const { value } = this.state;
        const onFocus = autoSelect ? this.selectTextField : () => { };

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {message}
                    {message && <Gap20/>}
                    <TextField
                        id={input.id}
                        onKeyPress={this.handleKeyPress}
                        autoFocus={true}
                        onFocus={onFocus}
                        placeholder={input.placeholder}
                        value={value}
                        fullWidth={true}
                        type={input.type}
                        inputProps={input.inputProps}
                        onChange={this.handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            cancelCallback && cancelCallback();
                            this.props.closeDialog();
                        }}
                    >
                        {cancelButtonLabel || "Cancel"}
                    </Button>
                    <Button
                        onClick={() => {
                            acceptCallback && acceptCallback(value);
                            this.props.closeDialog();
                        }}
                    >
                        {okButtonLabel || "Ok"}
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
