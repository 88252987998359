const lodash = require("lodash");

class InvalidPlanError extends Error { }

const FeatureType = {
    ANALYTICS: "analytics",
    CUSTOM_FONTS: "customFonts",
    FOLDERS: "folders",
    DESKTOP_APP: "desktopApp",
    SECURED_SHARING: "securedSharing",
    DISABLE_LINKS: "disableLinks",
    REVISION_HISTORY: "revisionHistory",
    CREATE_PRESENTATIONS: "createPresentations",
    CREATE_SLIDES: "createSlides",
    PLAYER_SETTINGS: "playerSettings",
    TEAMS: "teams",
    SSO: "sso",
    REMOVE_BAI_BRANDING: "removeBaiBranding",
    VIDEO_UPLOAD: "videoUpload",
    NOTIFICATIONS: "notifications",
    COMMENTS: "comments",
    REMOTE_MEETINGS: "remoteMeetings",
    VIEW_LIBRARY_ITEMS: "viewLibraryItems",
    EDIT_LIBRARY_ITEMS: "editLibraryItems",
    MANAGE_TEAM: "manageTeam",
    MANAGE_ORG: "manageOrg",
    MANAGE_TEAM_BILLING: "manageTeamBilling",
    API: "api",
    AUTO_PLAY_EMBEDDED: "autoPlayEmbedded",
    UPGRADE: "upgrade",
    SMART_SLIDES: "smartSlides",
    CONVERT_TO_CLASSIC: "convertSlides",
    PROHIBIT_EXTERNAL_WORKSPACE_MOVEMENT: "prohibitExternalWorkspaceMovement",
    PROHIBIT_GOOGLE_DRIVE_ACCOUNT_LINKING: "prohibitGoogleDriveAccountLinking",
    PROHIBIT_GENERATIVE_AI: "prohibitGenerativeAI",
    PROHIBIT_EXTERNAL_WORKSPACE_COLLABORATION: "prohibitExternalWorkspaceCollaboration",
    WORKSPACE_PLAYER_SETTINGS_DEFAULTS: "playerSettingsDefaults",
    WORKSPACE_BRANDING_PERMISSIONS: "brandingPermissions",
    WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES: "canAccessInspirationSlides",
    WORKSPACE_CAN_ACCESS_CUSTOM_THEMES: "canAccessCustomThemes",
    WORKSPACE_CAN_ACCESS_STOCK_LIBRARIES: "canAccessStockLibraries",
    WORKSPACE_CAN_ACCESS_WEB_IMAGES: "canAccessWebImages",
    WORKSPACE_CAN_ACCESS_CLASSIC_SLIDES: "canAccessClassicSlides",
    WORKSPACE_CAN_ACCESS_CONVERT_TO_CLASSIC: "canAccessConvertToClassic",
    WORKSPACE_CAN_ACCESS_IMPORT_PPT: "canAccessImportPPT",
    WORKSPACE_ALL_TEAM_MEMBERS_FOLDER_SETTINGS: "allTeamMembersFolderSettings",
    WORKSPACE_CAN_MODIFY_ALL_TEAM_MEMBERS_FOLDER: "canModifyAllTeamMembersFolder",
    WORKSPACE_CAN_ACCESS_ALL_TEAM_MEMBERS_FOLDER: "canAccessAllTeamMembersFolder",
    DESIGNER_BOT: "designerBot",
    DATA_LINKING: "dataLinking",
    ONE_DRIVE_INTEGRATION: "oneDriveIntegration",
    AIRTABLE_INTEGRATION: "airtableIntegration",
    SMARTSHEET_INTEGRATION: "smartsheetIntegration",
    DROPBOX_INTEGRATION: "dropboxIntegration",
    BYNDER_INTEGRATION: "bynderIntegration",
    BOX_INTEGRATION: "boxIntegration",
    GOOGLE_DRIVE_INTEGRATION: "googleDriveIntegration",
    YOUTUBE_INTEGRATION: "youtubeIntegration",
    VIMEO_INTEGRATION: "vimeoIntegration",
    SALESFORCE_INTEGRATION: "salesforceIntegration",
    ADVANCED_SETTINGS: "advancedSettings",
};

const BrandingPermission = {
    ALL: "all",
    OWNER_AND_LIB: "ownerAndLib",
    NONE: "none",
};

const F = FeatureType;

const playerSettingsDefaults = {
    showTitle: true,
    showAuthor: true,
    requireEmail: false,
    showSocialSharing: true,
    showGetDeckUI: false,
    allowPdfDownload: false,
};

const mapBrandingPermissionToRole = (perm, role) => {
    return (
        perm === BrandingPermission.ALL ||
        (
            perm === BrandingPermission.OWNER_AND_LIB &&
            (
                role === "owner" ||
                role === "librarian" ||
                role === "enterprise-owner" ||
                role === "enterprise-librarian"
            )
        )
    );
};

const hydrateWorkspaceSettings = workspaceSettings => {
    workspaceSettings = lodash.cloneDeep(workspaceSettings);
    // Ensure we have default values even if none of the
    //   settings have set on this workspace before
    workspaceSettings.brandingPermissions = {
        inspirationSlides: BrandingPermission.ALL,
        customThemes: BrandingPermission.ALL,
        stockLibraries: BrandingPermission.ALL,
        webImages: BrandingPermission.ALL,
        classicSlides: BrandingPermission.ALL,
        convertToClassic: BrandingPermission.ALL,
        importPPT: BrandingPermission.ALL,
        ...workspaceSettings.brandingPermissions,
    };
    workspaceSettings.allTeamMembersFolderSettings = {
        restrictAccess: false,
        hideFolder: false,
        ...workspaceSettings.allTeamMembersFolderSettings,
    };
    workspaceSettings.playerSettingsDefaults = {
        ...lodash.cloneDeep(playerSettingsDefaults),
        ...workspaceSettings.playerSettingsDefaults,
    };
    return workspaceSettings;
};

const freeFeatures = {
    [F.CREATE_SLIDES]: 9999,
    [F.DROPBOX_INTEGRATION]: true,
};

const freeTeamFeatures = {
    [F.CREATE_SLIDES]: 100,
    [F.DROPBOX_INTEGRATION]: true,
};

const proFeatures = {
    [F.ANALYTICS]: true,
    [F.CUSTOM_FONTS]: true,
    [F.FOLDERS]: true,
    [F.DESKTOP_APP]: true,
    [F.SECURED_SHARING]: true,
    [F.AUTO_PLAY_EMBEDDED]: true,
    [F.DISABLE_LINKS]: true,
    [F.REVISION_HISTORY]: true,
    [F.CREATE_PRESENTATIONS]: 999999,
    [F.PLAYER_SETTINGS]: true,
    [F.CREATE_SLIDES]: 200000,
    [F.REMOVE_BAI_BRANDING]: true,
    [F.VIDEO_UPLOAD]: true,
    [F.COMMENTS]: true,
    [F.NOTIFICATIONS]: true,
    [F.REMOTE_MEETINGS]: true,
    [F.API]: true,
    [F.SMART_SLIDES]: true,
    [F.CONVERT_TO_CLASSIC]: true,
    [F.DESIGNER_BOT]: true,
    [F.DROPBOX_INTEGRATION]: true,
};

const eduFeatures = {
    [F.ANALYTICS]: true,
    [F.CUSTOM_FONTS]: true,
    [F.FOLDERS]: true,
    [F.DESKTOP_APP]: true,
    [F.SECURED_SHARING]: true,
    [F.AUTO_PLAY_EMBEDDED]: true,
    [F.DISABLE_LINKS]: true,
    [F.REVISION_HISTORY]: true,
    [F.CREATE_PRESENTATIONS]: 999999,
    [F.PLAYER_SETTINGS]: true,
    [F.CREATE_SLIDES]: 200000,
    [F.REMOVE_BAI_BRANDING]: true,
    [F.VIDEO_UPLOAD]: true,
    [F.COMMENTS]: true,
    [F.NOTIFICATIONS]: true,
    [F.REMOTE_MEETINGS]: true,
    [F.API]: true,
    [F.SMART_SLIDES]: true,
    [F.CONVERT_TO_CLASSIC]: true,
    [F.DESIGNER_BOT]: true,
    [F.DROPBOX_INTEGRATION]: true,
};

const memberFeatures = {
    ...freeTeamFeatures,
    [F.COMMENTS]: true,
    [F.NOTIFICATIONS]: true,
    [F.TEAMS]: true,
    [F.VIEW_LIBRARY_ITEMS]: true,
    [F.DATA_LINKING]: true,
    [F.BYNDER_INTEGRATION]: true,
    [F.BOX_INTEGRATION]: true,
    [F.GOOGLE_DRIVE_INTEGRATION]: true,
    [F.YOUTUBE_INTEGRATION]: true,
    [F.VIMEO_INTEGRATION]: true,
    [F.ONE_DRIVE_INTEGRATION]: true,
    [F.AIRTABLE_INTEGRATION]: true,
    [F.SMARTSHEET_INTEGRATION]: true,
};

const planFeatures = formatPlans({
    // personal plans
    free: {
        name: "free",
        ...freeFeatures,
        [F.UPGRADE]: true,
    },
    pro: {
        name: "pro",
        ...proFeatures,
    },
    edu: {
        name: "edu",
        ...eduFeatures,
    },

    //
    // TEAM PLANS
    member: {
        ...memberFeatures,
        [F.UPGRADE]: true,
    },
    "member-licensed": {
        ...memberFeatures,
        ...proFeatures,
    },
    librarian: {
        ...memberFeatures,
        ...proFeatures,
        [F.EDIT_LIBRARY_ITEMS]: true,
    },
    owner: {
        ...memberFeatures,
        ...proFeatures,
        [F.EDIT_LIBRARY_ITEMS]: true,
        [F.SSO]: true,
        [F.MANAGE_TEAM]: true,
        [F.MANAGE_ORG]: true,
        [F.MANAGE_TEAM_BILLING]: true,
        [F.ADVANCED_SETTINGS]: true,
        [F.SALESFORCE_INTEGRATION]: true,
    },

    //
    // ENTERPRISE PLANS
    "enterprise-member": {
        ...memberFeatures,
        [F.UPGRADE]: true,
    },
    "enterprise-member-licensed": {
        ...memberFeatures,
        ...proFeatures,
    },
    "enterprise-librarian": {
        ...memberFeatures,
        ...proFeatures,
        [F.EDIT_LIBRARY_ITEMS]: true,
    },
    "enterprise-owner": {
        ...memberFeatures,
        ...proFeatures,
        [F.EDIT_LIBRARY_ITEMS]: true,
        [F.SSO]: true,
        [F.MANAGE_TEAM]: true,
        [F.MANAGE_ORG]: true,
        [F.MANAGE_TEAM_BILLING]: true,
        [F.ADVANCED_SETTINGS]: true,
        [F.SALESFORCE_INTEGRATION]: true,
    },
});

// legacy plan id
planFeatures["-LfRhaqvuLMLJ0qs8aNJ"] = planFeatures["free"];

function formatPlan(planSpec) {
    for (const [k, v] of Object.entries(planSpec)) {
        if (v === true) {
            planSpec[k] = { limit: undefined };
        } else if (typeof v === "number") {
            planSpec[k] = { limit: v };
        } else if (v === false) {
            delete planSpec[k];
        } else if (typeof v === "object") {
            planSpec[k] = {
                ...v,
            };
        }
    }
}

// map permissions to the format which the old planPermissions table used
function formatPlans(plansSpec) {
    for (const planSpec of Object.values(plansSpec)) {
        formatPlan(planSpec);
    }
    return plansSpec;
}

function getFeatures(
    planNameOrTeamRole,
    isEnterprise = false,
    workspaceSettings = {},
) {
    const permissions = planFeatures[planNameOrTeamRole];
    if (!permissions) {
        throw new InvalidPlanError("invalid plan name or team role: " + planNameOrTeamRole);
    }

    const effectiveRole = isEnterprise ? `enterprise-${planNameOrTeamRole}` : planNameOrTeamRole;

    workspaceSettings = hydrateWorkspaceSettings(workspaceSettings);
    workspaceSettings.canAccessInspirationSlides = mapBrandingPermissionToRole(workspaceSettings.brandingPermissions.inspirationSlides, effectiveRole);
    workspaceSettings.canAccessCustomThemes = mapBrandingPermissionToRole(workspaceSettings.brandingPermissions.customThemes, effectiveRole);
    workspaceSettings.canAccessStockLibraries = mapBrandingPermissionToRole(workspaceSettings.brandingPermissions.stockLibraries, effectiveRole);
    workspaceSettings.canAccessWebImages = mapBrandingPermissionToRole(workspaceSettings.brandingPermissions.webImages, effectiveRole);
    workspaceSettings.canAccessClassicSlides = mapBrandingPermissionToRole(workspaceSettings.brandingPermissions.classicSlides, effectiveRole);
    workspaceSettings.canAccessConvertToClassic = mapBrandingPermissionToRole(workspaceSettings.brandingPermissions.convertToClassic, effectiveRole);
    workspaceSettings.canAccessImportPPT = mapBrandingPermissionToRole(workspaceSettings.brandingPermissions.importPPT, effectiveRole);

    workspaceSettings.canModifyAllTeamMembersFolder = (
        !workspaceSettings.allTeamMembersFolderSettings.restrictAccess ||
        planNameOrTeamRole === "owner" ||
        planNameOrTeamRole === "librarian"
    );
    workspaceSettings.canAccessAllTeamMembersFolder = !workspaceSettings.allTeamMembersFolderSettings.hideFolder;
    // Note, we fallback to the old prohibitOpenAIIntegration setting for backwards compatibility
    workspaceSettings.prohibitGenerativeAI = workspaceSettings.prohibitGenerativeAI ?? workspaceSettings.prohibitOpenAIIntegration ?? false;

    if (!permissions.advancedSettings && !isEnterprise) {
        workspaceSettings.prohibitExternalWorkspaceMovement = false;
        workspaceSettings.prohibitExternalWorkspaceCollaboration = false;
        workspaceSettings.prohibitGenerativeAI = false;
    }

    formatPlan(workspaceSettings);

    const result = {
        ...permissions,
        ...workspaceSettings,
    };
    return result;
}

module.exports = {
    InvalidPlanError,
    FeatureType,
    BrandingPermission,
    getFeatures,
    playerSettingsDefaults,
    hydrateWorkspaceSettings,
};
