import React, { Component } from "react";
import styled from "styled-components";
import classNames from "classnames";
import {
    Icon,
    CircularProgress,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    Divider,
    MenuList,
    IconButton
} from "@material-ui/core";

import {
    convertDataURItoBlob,
    getAverageImageColor,
    trimWhiteEdges
} from "js/core/utilities/imageUtilities";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import ImportFileDialog from "legacy-js/react/views/ImportFile/ImportFileDialog";
import { FileType } from "legacy-js/core/utilities/fileSourcesAndTypes";
import Logos from "js/core/models/logos";
import { getLogoPath } from "legacy-common/assetUtils";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { BlueButton } from "legacy-js/react/components/UiComponents";

const UploadImageStyle = styled(MenuItem)`
    gap: 5px;
`;

class UploadImage extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            isPopupOpen: false
        };
    }

    async componentDidMount() {
        const { src } = this.props;
        if (!src) return;
        const signedUrl = await Logos.getSignedUrlAndLoad(getLogoPath(src));
        this.setState({ src: signedUrl });
    }

    setFile = file => {
        if (!file) return;

        // notify, if needed
        if (this.props.onBeforeUploadFile) {
            this.props.onBeforeUploadFile(file);
        }

        this.setState({ loading: true });
        this.hidePopup();

        const fileNameMatch = file.name.match(/^(.+)\.([^.]+)$/);
        const fileName = fileNameMatch ? fileNameMatch[1] : file.name;
        const fileExtension = fileNameMatch ? `.${fileNameMatch[2]}` : "";

        const fileReader = new FileReader();
        fileReader.onload = () => {
            const img = new Image();
            img.onload = async () => {
                let body = file;

                let {
                    disableImageProcessing,
                    extractAverageImageColor,
                    attribute,
                    attribute2,
                    update,
                    onUploadSuccess,
                } = this.props;

                // check if processing should happen
                // if it's a function use the test to determine
                if (typeof disableImageProcessing === "function") {
                    disableImageProcessing = disableImageProcessing(file);
                }

                // if not disabled, process the image
                if (!disableImageProcessing) {
                    const imageData = trimWhiteEdges(img);
                    body = await convertDataURItoBlob(imageData);
                }

                // notify, if needed
                if (extractAverageImageColor) {
                    const avg = getAverageImageColor(img);
                    update({ averageImageColor: avg });
                }

                // upload the image
                const id = `${fileName}-${new Date().getTime().toString()}${fileExtension}`;
                const writeUrl = await Logos.getWriteSignedUrl(id);
                await fetch(writeUrl, { method: "PUT", body });

                // get the image url data and update
                const signedUrl = await Logos.getSignedUrlAndLoad(id);
                update({ [attribute]: id });
                if (attribute2) {
                    update({ [attribute2]: id });
                }
                onUploadSuccess && onUploadSuccess(id);
                this.setState({ loading: false, src: signedUrl });
            };
            img.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);
    };

    removeLogo() {
        let {
            attribute,
            attribute2,
        } = this.props;

        this.props.update({ [attribute]: "" });
        if (attribute2) {
            this.props.update({ [attribute2]: "" });
        }
        this.setState({ src: null });
        this.hidePopup();
    }

    showFilePicker() {
        this.hidePopup();
        ShowDialog(ImportFileDialog, {
            title: "Import Logo",
            fileTypes: [FileType.Image],
            closeOnSuccess: true,
            onSuccess: file => this.setFile(file)
        });
    }

    showPopup(popupAnchorElement) {
        this.setState({ isPopupOpen: true, popupAnchorElement });
    }

    hidePopup() {
        this.setState({ isPopupOpen: false });
    }

    render() {
        const {
            label,
            bgColor,
            classes,
            showSilhouette,
            filePickerOnClick = false,
            renderAsButton = false,
            uploadButtonIsExternal = false,
        } = this.props;
        const { loading, isPopupOpen, popupAnchorElement, src } = this.state;

        const iconStyle = {
            color: "#777777"
        };

        const onClick = event => {
            if (isPopupOpen) {
                this.hidePopup();
            } else {
                this.showPopup(event.target);
            }
        };

        return (
            <FlexBox left middle className={classes.root}>
                <div>
                    {!filePickerOnClick && <Popper
                        style={{ zIndex: 10000,
                            width: 150 }}
                        open={isPopupOpen}
                        anchorEl={popupAnchorElement}
                        transition
                        disablePortal
                        placement="bottom"
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: "top" }}
                            >
                                <Paper className={classes.paper}>
                                    <ClickAwayListener onClickAway={event => {
                                        if (event.target !== popupAnchorElement) {
                                            this.hidePopup();
                                        }
                                    }}>
                                        <div>{isPopupOpen && (<MenuList
                                            alignItems="flex-start">
                                            <UploadImageStyle onClick={() => this.showFilePicker()}>
                                                <Icon style={iconStyle}>cloud_upload</Icon>
                                                Upload
                                            </UploadImageStyle>
                                            {src &&
                                                <Divider style={{ margin: 0 }} />
                                            }
                                            {src &&
                                                <UploadImageStyle
                                                    onClick={() => this.removeLogo()}>
                                                    <Icon style={iconStyle}>delete</Icon>
                                                    Remove
                                                </UploadImageStyle>
                                            }
                                        </MenuList>)}</div>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>}
                    <div
                        onClick={filePickerOnClick ? () => this.showFilePicker() : null}
                        style={{ backgroundColor: bgColor }}
                        className={classes.logoParent}
                    >
                        <FlexBox fill center middle>
                            {
                                showSilhouette
                                    ? (
                                        (
                                            src &&
                                            <img src={src} />
                                        ) ||
                                        <i className="micon person">person</i>
                                    )
                                    : (
                                        src &&
                                        <img src={src} />
                                    )
                            }

                            {
                                !loading &&
                                !uploadButtonIsExternal &&
                                <>
                                    {
                                        renderAsButton &&
                                        <BlueButton
                                            onClick={onClick}
                                        >{this.props.children}</BlueButton>
                                    }
                                    {
                                        !renderAsButton &&
                                        <Icon
                                            onClick={onClick}
                                            style={!src || isPopupOpen ? { display: "block" } : {}}
                                            className={classNames(
                                                classes.circleIconParent,
                                                "uploadIcon"
                                            )}
                                        >camera_alt</Icon>
                                    }
                                </>
                            }

                            {loading && (
                                <CircularProgress
                                    color="primary"
                                    classes={{ root: classes.spinner }}
                                />
                            )}
                        </FlexBox>
                        <label className={classes.label}>{label}</label>
                    </div >
                </div >
                {
                    !loading &&
                    uploadButtonIsExternal &&
                    <IconButton
                        aria-haspopup="true"
                        aria-label="Upload image"
                        onClick={onClick}
                    >
                        <Icon
                            color="primary"
                            style={{ color: "#11a9e2" }}
                            className={classNames(
                                "uploadIcon"
                            )}
                        >cloud_upload</Icon>
                    </IconButton>
                }
            </FlexBox>
        );
    }
}

export default UploadImage;
