import React from "react";
import styled from "styled-components";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import {
    CheckCircle as CheckCircleIcon,
    CloudUpload as CloudUploadIcon
} from "@material-ui/icons";

import getLogger, { LogGroup } from "js/core/logger";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { _ } from "legacy-js/vendor";
import { FeatureType } from "legacy-common/features";
import { serverUrl } from "legacy-js/config";
import AppController from "legacy-js/core/AppController";

import { dialogTheme } from "legacy-js/react/materialThemeOverrides";
import { fontManager } from "js/core/services/fonts";
import { builtInFonts } from "legacy-common/fontConstants";
import { AssetType } from "legacy-common/constants";
import getDefaultFontWeight from "legacy-common/utils/getDefaultFontWeight";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { UploadFontModal } from "legacy-js/editor/ThemeEditor/components/Font/UploadFontModal";
import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";

const logger = getLogger(LogGroup.FONTS);

const Subtitle = styled.div`
    margin-top: 20px;
    margin-bottom: -10px;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;

    >a {
        text-decoration: none;
        color: #11A9E2;
    }
`;

const ContentMessage = styled.div`
    margin-top: 20px;
    margin-bottom: -30px;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #666666;
`;

const FontFamiliesContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    background: #F7FAFC;
    padding: 20px;

    >div {
        margin-bottom: 20px;
    }

    >div:last-child {
        margin-bottom: 0;
    }  
`;

const FontFamilyContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
`;

const FontUploadedIcon = styled(CheckCircleIcon)`
    color: #7FB314;
`;

const UploadFontIconButton = styled(CloudUploadIcon)`
    color: #11A9E2;
    cursor: pointer;
`;

const FontName = styled.div`
    margin-left: 10px;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #222222;
`;

export class UploadFontsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isReady: false,
            pptFontFamilies: [],
            // Default to the user ppt fonts map
            pptFontsMap: _.cloneDeep(app.user.get("pptFontsMap")) ?? {},
            allFontsMapped: false,
            showUpgradeDialog: false
        };

        this.customFonts = [];
        this.builtInFonts = [];
    }

    setStateAsync(stateUpdates) {
        return new Promise(resolve => this.setState(stateUpdates, resolve));
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { pptFontsMap } = this.state;
        const { themeModel } = this.props;
        const pptFontFamilies = Array.from(new Set([themeModel.headerFont, themeModel.bodyFont]));
        await this.setStateAsync({ pptFontFamilies });

        // Loading custom fonts
        const customFontsLoadPromise = ds.assets.getAssetsByType(AssetType.FONT, AppController.workspaceId)
            .then(assets => Promise.all(assets.map(asset => fontManager.loadFont(asset.id))))
            .then(customFonts => {
                // Filtering out fonts that failed to load (fallback fonts)
                return customFonts.filter(font => font.isCustomFont);
            })
            .catch(err => logger.error(err, "[UploadFontsDialog] failed to load custom fonts"));

        // Loading built in fonts
        const builtInFontsLoadPromise = Promise.all(Object.keys(builtInFonts).map(fontId => fontManager.loadFont(fontId)))
            .catch(err => logger.error(err, "[UploadFontsDialog] failed to load built in fonts"));

        const [customFonts, loadedBuiltInFonts] = await Promise.all([customFontsLoadPromise, builtInFontsLoadPromise]);
        this.customFonts = customFonts;
        this.builtInFonts = loadedBuiltInFonts;

        const filteredPPTFontsMap = {};
        Object.entries(pptFontsMap).forEach(([pptFontFamily, { fontId, fontWeight }]) => {
            if (customFonts.some(({ id }) => fontId === id) || loadedBuiltInFonts.some(({ id }) => fontId === id)) {
                filteredPPTFontsMap[pptFontFamily] = { fontId, fontWeight };
            }
        });
        await this.setStateAsync({ pptFontsMap: filteredPPTFontsMap });

        await this.autoMapFonts();

        const { allFontsMapped } = this.state;
        if (allFontsMapped) {
            this.handleDone();
            return;
        }

        await this.setStateAsync({
            isReady: true,
            showUpgradeDialog: !app.user.features.isFeatureEnabled(FeatureType.CUSTOM_FONTS, AppController.workspaceId)
        });
    }

    async autoMapFonts() {
        const { pptFontFamilies, pptFontsMap } = this.state;

        const newPPTFontsMap = pptFontFamilies.reduce(
            (fontsMap, fontFamily) => ({ ...fontsMap, [fontFamily]: pptFontsMap[fontFamily] ?? this.autoMapFont(fontFamily, this.customFonts, this.builtInFonts) }),
            {}
        );

        await this.setStateAsync({
            pptFontsMap: newPPTFontsMap,
            allFontsMapped: !pptFontFamilies.some(fontFamily => !newPPTFontsMap[fontFamily])
        });
    }

    autoMapFont(fontFamily, customFonts, builtInFonts) {
        const findMatchingFont = fontFamily => {
            let matchingFont = customFonts.find(font => font.label.toLowerCase() === fontFamily);
            if (!matchingFont) {
                matchingFont = builtInFonts.find(font => font.label.toLowerCase() === fontFamily);
            }
            if (!matchingFont) {
                matchingFont = customFonts.find(font => fontFamily.startsWith(font.label.toLowerCase()));
            }
            if (!matchingFont) {
                matchingFont = builtInFonts.find(font => fontFamily.startsWith(font.label.toLowerCase()));
            }

            return matchingFont;
        };

        const normalizedFontFamily = fontFamily.split(",")[0].trim().toLowerCase();
        let mappedFont = findMatchingFont(normalizedFontFamily);
        if (!mappedFont && normalizedFontFamily.includes("calibri")) {
            mappedFont = findMatchingFont(normalizedFontFamily.replace("calibri", "carlito"));
        }

        if (!mappedFont) {
            return null;
        }

        const defaultWeight = getDefaultFontWeight(normalizedFontFamily);
        return { fontId: mappedFont.id, fontWeight: mappedFont.getStyle(defaultWeight, false).weight };
    }

    handleUploadCustomFont = forFontFamily => {
        ShowDialog(UploadFontModal, {
            onSetCustomFont: async (fontId, fontWeight) => {
                try {
                    let loadedFont = await fontManager.loadFont(fontId);
                    const { pptFontFamilies } = this.state;

                    // Making sure we don't have a duplicate font record in case this flow was running simultaneously for the same font
                    this.customFonts = [...this.customFonts.filter(font => font.id !== loadedFont.id), loadedFont];
                    // Mapping the new font
                    await this.setStateAsync(prevState => ({
                        pptFontsMap: { ...prevState.pptFontsMap, [forFontFamily]: { fontId, fontWeight } },
                        allFontsMapped: !pptFontFamilies.some(fontFamily => !{ ...prevState.pptFontsMap, [forFontFamily]: { fontId, fontWeight } }[fontFamily])
                    }));
                    // Remap the other fonts
                    await this.autoMapFonts();
                } catch (err) {
                    logger.error(err, "[UploadFontsDialog] onSetCustomFont() failed");
                }
            }
        });
    }

    handleDone = async () => {
        const { themeModel, onFontsUploaded } = this.props;
        const { pptFontsMap } = this.state;

        // Save fonts map to the user model so we can reuse it later
        app.user.update({ pptFontsMap: { ...(app.user.get("pptFontsMap") ?? {}), ...pptFontsMap } });
        await app.user.updatePromise;

        // Header and title fonts
        {
            const originalFontName = themeModel.headerFont;
            if (pptFontsMap[originalFontName]) {
                themeModel.fontHeaderFontId = pptFontsMap[originalFontName].fontId;
                themeModel.fontHeaderWeight = pptFontsMap[originalFontName].fontWeight;

                themeModel.fontTitleFontId = pptFontsMap[originalFontName].fontId;
                themeModel.fontTitleWeight = pptFontsMap[originalFontName].fontWeight;
            } else {
                themeModel.fontHeaderFontId = "carlito";
                themeModel.fontHeaderWeight = 500;

                themeModel.fontTitleFontId = "carlito";
                themeModel.fontTitleWeight = 500;
            }

            themeModel.fontHeaderLetterSpacing = 0;
            themeModel.fontHeaderLineHeight = 1.6;
            themeModel.fontHeaderScaling = 100;

            themeModel.fontTitleLetterSpacing = 0;
            themeModel.fontTitleLineHeight = 1.6;
            themeModel.fontTitleScaling = 100;
        }
        // Body font
        {
            const originalFontName = themeModel.bodyFont;
            if (pptFontsMap[originalFontName]) {
                themeModel.fontBodyFontId = pptFontsMap[originalFontName].fontId;
                themeModel.fontBodyWeight = pptFontsMap[originalFontName].fontWeight;
            } else {
                themeModel.fontBodyFontId = "carlito";
                themeModel.fontBodyWeight = 500;
            }

            themeModel.fontBodyLetterSpacing = 0;
            themeModel.fontBodyLineHeight = 1.6;
            themeModel.fontBodyScaling = 100;
        }

        delete themeModel.needsFontsMap;
        delete themeModel.originalFontHeaderFontId;
        delete themeModel.originalFontTitleFontId;
        delete themeModel.originalFontBodyFontId;

        onFontsUploaded(themeModel);
    }

    render() {
        const { pptFontFamilies, pptFontsMap, allFontsMapped, showUpgradeDialog, isReady } = this.state;
        const { WrapperComponent = BeautifulDialog } = this.props;

        const getContent = () => {
            if (!isReady) {
                return <FetchingClickShield visible={true} />;
            }

            if (showUpgradeDialog) {
                return (<>
                    <DialogTitle>
                        <div>Your PowerPoint theme uses fonts not yet uploaded to your Beautiful.ai account.</div>
                        <Subtitle>This is a Pro feature. Upgrade to upload custom fonts, or skip this and we’ll apply our default fonts. <a href={`${serverUrl}/pricing`} target="_blank">Learn about our plans.</a></Subtitle>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={this.handleDone}
                        >
                            got it
                        </Button>
                    </DialogActions>
                </>);
            }

            return (<>
                <DialogTitle>
                    <div>Upload your fonts</div>
                    <Subtitle>Your PowerPoint theme uses fonts not yet uploaded to your Beautiful.ai account.</Subtitle>
                </DialogTitle>
                <DialogContent>
                    <FontFamiliesContainer>
                        {pptFontFamilies.map((pptFontFamily, idx) => (
                            <FontFamilyContainer key={idx}>
                                {pptFontsMap[pptFontFamily] && <FontUploadedIcon />}
                                {!pptFontsMap[pptFontFamily] && <UploadFontIconButton onClick={() => this.handleUploadCustomFont(pptFontFamily)} />}
                                <FontName>Upload {pptFontFamily}</FontName>
                            </FontFamilyContainer>
                        ))}
                    </FontFamiliesContainer>
                    <ContentMessage>
                        For your Beautiful.ai slides to look consistent when added to your PowerPoint presentation,
                        fonts need to be installed in both systems. If you don’t have these fonts or don’t want to install them now,
                        we’ll use a default font. You can upload them at any time in the Beautiful.ai Theme editor.
                    </ContentMessage>
                </DialogContent>
                <DialogActions>
                    {!allFontsMapped && <Button
                        color="secondary"
                        onClick={this.handleDone}
                    >
                        not now
                    </Button>}
                    <Button
                        color="primary"
                        onClick={this.handleDone}
                        disabled={!allFontsMapped}
                    >
                        done!
                    </Button>
                </DialogActions>
            </>);
        };

        return (<MuiThemeProvider theme={dialogTheme}>
            <WrapperComponent
                preventClose={true}
                closeDialog={() => { }}
            >
                {getContent()}
            </WrapperComponent>
        </MuiThemeProvider >);
    }
}
