import React, { Component } from "react";
import { PositionType } from "legacy-common/constants";
import * as geom from "js/core/utilities/geom";
import { $, _ } from "legacy-js/vendor";

export class PopupMenu extends Component {
    ref = React.createRef();
    state = {
        position: this.props.position,
        positionX: 0,
        positionY: 0,
        width: 0,
        height: 0,
        arrowX: 0,
        arrowY: 0,
        isMounted: false,
    }

    componentDidMount() {
        this.refreshLayout();

        //using following setTimeout code so that the position and height of the popup menu is accurate
        //so that the menu can be placed properly above or below its target
        setTimeout(() => {
            this.refreshLayout();
            this.setState({ isMounted: true });
        }, 100);
    }

    refreshLayout() {
        let { current } = this.ref;
        let { target, offset = 10, position } = this.props;

        let $el = $(current);

        if (($el.offset().top + $el.height()) > window.innerHeight) {
            position = PositionType.TOP;
        } else if ($el.offset().top < 0) {
            position = PositionType.BOTTOM;
        }

        const isPopupTooFarLeftOfViewport = $el.offset().left < 0;
        const isPopupTooFarRightOfViewport = $el.offset().left + $el.width() > window.innerWidth;
        const leftPadding = (isPopupTooFarLeftOfViewport) ? Math.abs($el.offset().left) + 20 : 0;
        const rightPadding = (isPopupTooFarRightOfViewport) ? $el.offset().left / 2 : 0;

        let popupBounds, arrowPosition, arrowTransform;
        switch (position) {
            case PositionType.TOP:
                popupBounds = new geom.Rect(
                    target.offset().left + target.outerWidth() / 2 - $el.outerWidth() / 2 + leftPadding - rightPadding,
                    target.offset().top - offset - $el.outerHeight(),
                    $el.outerWidth(),
                    $el.outerHeight()
                );
                arrowPosition = new geom.Point($el.outerWidth() / 2 - 8 - leftPadding + rightPadding, $el.outerHeight() - 1);
                break;
            case PositionType.BOTTOM:
            default:
                popupBounds = new geom.Rect(
                    target.offset().left + target.outerWidth() / 2 - $el.outerWidth() / 2 + leftPadding - rightPadding,
                    target.offset().top + target.outerHeight() + offset,
                    $el.outerWidth(),
                    $el.outerHeight()
                );
                arrowPosition = new geom.Point($el.outerWidth() / 2 - 8 - leftPadding + rightPadding, -15);
                arrowTransform = null;
        }

        if (popupBounds.left != this.state.positionX || popupBounds.top != this.state.positionY || popupBounds.width != this.state.width || popupBounds.height != this.state.height) {
            this.setState({
                position,
                width: popupBounds.width,
                height: popupBounds.height,
                positionX: popupBounds.x,
                positionY: popupBounds.y,
                arrowX: arrowPosition.x,
                arrowY: arrowPosition.y,
                arrowTransform,
            });
        }
    }

    render() {
        const { position, positionX, positionY, isMounted, arrowX, arrowY } = this.state;
        const { closeDialog, className, style, children } = this.props;

        const popupStyle = {
            left: positionX,
            top: positionY,
            opacity: isMounted ? 1 : 0,
        };

        const arrowStyle = {
            left: arrowX,
            top: arrowY,
        };

        return (
            <div
                className="react-popup-backdrop"
                onClick={event => {
                    if (event.target.className == "react-popup-backdrop") {
                        closeDialog();
                    }
                }}>
                <div
                    ref={this.ref}
                    className="react-popup-menu dropdown_menu"
                    style={popupStyle}
                    onClick={event => event.preventDefault()}
                >
                    <div className={`menu_arrow ${position === PositionType.TOP ? "point-down" : "point-up"}`}
                        style={arrowStyle}/>
                    <div className={`menu_contents custom ${className}`} style={style}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

