import React, { Component } from "reactn";
import styled from "styled-components";
import { LinearProgress, Button, DialogContent, DialogActions } from "@material-ui/core";
import { BeautifulDialog } from "./BaseDialog";
import { FlexBox } from "../LayoutGrid";
import { getStaticUrl } from "legacy-js/config";

const Title = styled.div`
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.5px;
    text-align: center;
`;

const Progress = styled(LinearProgress)`
    &&& {
        width: calc(100% - 30px);
        height: 12px;
        border-radius: 4px;
    }
`;

const Message = styled.div`
    margin-top: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: center;
`;

interface Props {
    title: string,
    message?: string,
    onCancel?: () => void,
}

interface State {
    title: any,
    message: any,
    progress: number,
}

export default class ProgressDialog extends Component<Props, State> {
    taskEnd = 0;
    taskStart = 0;
    taskDuration = 0;
    interval = null;
    intervalStart = 0;

    state = {
        title: this.props.title,
        message: this.props.message,
        progress: null,
    } as State;

    setTitle = title => this.setState({ title });
    setMessage = message => this.setState({ message });
    setProgress = (progress: number) => {
        clearInterval(this.interval);
        this.interval = null;
        this.taskStart = progress;
        this.taskEnd = progress;
        this.taskDuration = 0;
        this.setState({ progress });
    };
    setTask = (target: number, duration: number = 5000) => {
        this.taskStart = this.taskEnd;
        this.taskEnd = target;
        this.taskDuration = duration;
        this.setState({ progress: this.taskStart });

        clearInterval(this.interval);
        this.intervalStart = Date.now();
        this.interval = setInterval(() => {
            let deltaTime = Date.now() - this.intervalStart;
            let interp = Math.min(1, deltaTime / this.taskDuration);
            let progress = this.taskStart + (this.taskEnd - this.taskStart) * interp;
            this.setState({ progress });
        }, 250);
    }

    render = () => {
        const {
            title,
            progress,
            message,
        } = this.state;

        const {
            onCancel,
        } = this.props;

        return (
            <BeautifulDialog>
                <DialogContent> {/* @ts-ignore */}
                    <FlexBox column center>
                        <img
                            src={getStaticUrl(`/images/bai-bot/bai-bot-neutral.svg`)}
                            width={60}
                            height={60}
                            style={{ marginTop: "15px" }}
                        />
                        <Title>{title}</Title>
                        <Progress
                            variant={progress === null ? "indeterminate" : "determinate"}
                            value={progress}
                        />
                        {
                            message &&
                            <Message>{message}</Message>
                        }
                    </FlexBox>
                </DialogContent>
                <DialogActions>
                    {
                        onCancel &&
                        <Button
                            onClick={onCancel}
                        >Cancel</Button>
                    }
                </DialogActions>
            </BeautifulDialog>
        );
    };
}
