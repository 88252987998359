// because we mix namespaces will ES6 modules, certain modules need to be pre-loaded
// in a given order, these are listed below, along with the reason for doing so

// editor
import "legacy-js/react/components/Dialogs/BaseDialog"; // app dialogManager;

// css
import "css/add-assets.scss";
import "css/add-slide.scss";
import "css/analytics.scss";
import "css/app.scss";
import "css/common.scss";
import "css/components.scss";
import "css/controls.scss";
import "css/dialog.scss";
import "css/dialogs.scss";
import "css/editor.scss";
import "css/presentations-list.scss";

// vendor css
import "css/jquery-ui.css";
import "css/spectrum.css";
import "css/x-spreadsheet.css";
