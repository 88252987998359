import * as geom from "js/core/utilities/geom";
import { HeaderPositionType, VerticalAlignType } from "legacy-common/constants";

import { DisplayText } from "./DisplayText";
import { BaseElement } from "../base/BaseElement";
import { layoutHelper } from "../layouts/LayoutHelper";
import { SVGRectElement } from "../base/SVGElement";
import { TextElement } from "../base/TextElement";

export class Header extends BaseElement {
    get textAlign() {
        return this.styles.textAlign || "center";
    }

    get position() {
        return this.model.headerPosition || this.canvas.model.layout.headerPosition;
    }

    get selectionPadding() {
        if (this.model.backgroundColor && this.text.showDescription && this.position == HeaderPositionType.TOP) {
            // account for additional background shape padding
            return { left: 0, right: 0, top: 0, bottom: 20 };
        } else {
            return { left: 0, right: 0, top: 0, bottom: 0 };
        }
    }

    get rolloverPadding() {
        return 0;
    }

    get canSelect() {
        return true;
    }

    get isResizable() {
        return true;
    }

    get fitToContents() {
        return {
            width: false,
            height: true
        };
    }

    get restrictResize() {
        return {
            width: false,
            height: true
        };
    }

    get needsFullSizeToCalcFit() {
        return {
            width: false,
            height: true
        };
    }

    get hasBackground() {
        return this.model.backgroundColor && this.model.backgroundColor != "none";
    }

    _loadStyles(styles) {
        styles.applyStyles(styles._position[this.position]);

        let decoration = this.canvas.getTheme().get("styleDecoration");
        if (decoration === "bar_left" && this.canvas.model.layout.trayLayout === "left_tray") {
            styles.applyStyles({ decoration: null });
        }

        if (decoration.contains("block") && this.model.backgroundColor && this.model.backgroundColor != "none") {
            styles.text.headline.decoration = null;
            styles.text.headline.marginBottom = 30;
            styles.text.description.marginTop = 0;
            styles.text.label.marginTop = 0;
            styles.text.label.marginBottom = 30;
        }

        if ((decoration === "bar_left" || decoration === "bar_top") && (this.model.backgroundColor && this.model.backgroundColor != "none")) {
            styles.applyStyles({ decoration: null });
        }

        if (this.isOnAuthoringCanvas) {
            styles.marginBottom = 20;
        }

        if (this.model.backgroundColor) {
            styles.backgroundFrame.fillColor = this.model.backgroundColor;
        }
    }

    _build() {
        if (this.model.backgroundColor) {
            this.backgroundFrame = this.addElement("backgroundFrame", () => SVGRectElement);
        }

        this.text = this.addElement("text", () => DisplayText, {
            singleLineHeadline: true
        });
    }

    _calcProps(props, options) {
        let { size, allowedSize } = props;

        let backgroundProps;
        if (this.model.backgroundColor) {
            backgroundProps = this.backgroundFrame.createProps();
            backgroundProps.layer = -2;
            backgroundProps.bounds = new geom.Rect(0, 0, 0, 0);
        }

        let textProps;
        if (this.position == HeaderPositionType.TOP) {
            textProps = this.text.calcProps(size);
            textProps.bounds = new geom.Rect(0, 0, textProps.size);
            let height = textProps.size.height;

            if (this.model.backgroundColor && this.model.backgroundColor != "none") {
                backgroundProps.bounds = new geom.Rect(0, 0, allowedSize.width, textProps.size.height + this.styles.paddingTop + this.styles.paddingBottom);

                if (this.text.showDescription) {
                    backgroundProps.bounds.height += 40;
                } else {
                    backgroundProps.bounds.height += 20;
                }
                height += 20;
            }
            return {
                size: new geom.Size(size.width, height)
            };
        } else {
            textProps = this.text.calcProps(size, { scaleTextToFit: true });
            textProps.bounds = new geom.Rect(0, layoutHelper.getVerticalAlignOffset(textProps.size.height, size.height, this.text.styles.verticalAlign ?? VerticalAlignType.MIDDLE), textProps.size);

            if (this.model.backgroundColor) {
                backgroundProps.bounds = new geom.Rect(0, 0, allowedSize.width, allowedSize.height);
            }

            return {
                size: new geom.Size(textProps.size.width, size.height)
            };
        }
    }

    getBackgroundColor(forElement) {
        if (this.backgroundFrame && forElement instanceof TextElement) {
            return this.getShapeFillColor(this.backgroundFrame);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }

    get disableAnimationsByDefault() {
        return true;
    }

    _migrate_9() {
        if (this.model.userFontScale) {
            this.model.userFontScale["CanvasElement/Header/DisplayText/DisplayTextHeadline"] = this.model.userFontScale["CanvasElement/Header/DisplayTextHeadline"];
            this.model.userFontScale["CanvasElement/Header/DisplayText/DisplayTextLabel"] = this.model.userFontScale["CanvasElement/Header/DisplayTextLabel"];
            this.model.userFontScale["CanvasElement/Header/DisplayText/DisplayTextDescription"] = this.model.userFontScale["CanvasElement/Header/DisplayTextDescription"];
            delete this.model.userFontScale["CanvasElement/Header/DisplayTextHeadline"];
            delete this.model.userFontScale["CanvasElement/Header/DisplayTextLabel"];
            delete this.model.userFontScale["CanvasElement/Header/DisplayTextDescription"];
        }
    }
}

export const elements = {
    Header
};
