import * as geom from "js/core/utilities/geom";

import { TextGroup } from "../../base/TextGroup";
import { OrgChartNode } from "./OrgChartNode";

export class BoxOrgChartNode extends OrgChartNode {
    _build() {
        this.text = this.addElement("text", () => TextGroup, {
            showBody: false,
            showAddButton: false,
            autoWidth: false,
            autoHeight: true,
            title: {
                canSelect: false,
                canRollover: false,
                placeholder: "Click to edit",
                spellcheck: false
            },
            body: {
                canSelect: false,
                canRollover: false,
                spellcheck: false
            }
        });
    }

    _calcProps(props, options) {
        const { size } = props;
        if (this.parentElement.isRowSmall(this.rowIndex) && this.styles.small) {
            this.updateStyles(this.styles.small, true);
        }

        const textProps = this.text.calcProps(new geom.Size(size.width, size.height), options);

        if (options.fillHeight) {
            return { size: new geom.Size(textProps.size.width, size.height) };
        }

        return { size: textProps.size };
    }
}
