import GallerySlidesDataService from "./GallerySlidesDataService";
import SlideTemplatesDataService from "./SlideTemplatesDataService";
import UserSlidesDataService from "./UserSlidesDataService";
import TeamSlidesDataService from "./TeamSlidesDataService";

// Singletones export (mimic static classes)
export const gallerySlidesDataService = new GallerySlidesDataService();
export const slideTemplatesDataService = new SlideTemplatesDataService();
export const userSlidesDataService = new UserSlidesDataService();
export const teamSlidesDataService = new TeamSlidesDataService();
