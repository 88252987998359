import * as geom from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";
import { getValueOrDefault } from "js/core/utilities/extensions";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";

class TextBoxGrid extends CollectionElement {
    static get schema() {
        return {
            matchSizes: true,
        };
    }

    getChildItemType() {
        return TextBoxGridItem;
    }

    get maxItemCount() {
        return 6;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" }
        };
    }

    get matchSizes() {
        return getValueOrDefault(this.model.matchSizes, true);
    }

    _calcProps(props, options) {
        const { size } = props;
        this.titleTextScale = 1;
        this.bodyTextScale = 1;

        const layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcTextBoxGridLayout({ flipOrientation: this.model.flipOrientation });

        if (this.titleTextScale != 1 || this.bodyTextScale != 1) {
            layouter.calcTextBoxGridLayout({ flipOrientation: this.model.flipOrientation });
        }

        return { size };
    }
}

class TextBoxGridItem extends CollectionItemElement {
    get selectionPadding() {
        return 0;
    }

    _build() {
        this.title = this.addElement("title", () => TextElement, {
            scaleTextToFit: true,
        });
        this.body = this.addElement("body", () => TextElement, {
            scaleTextToFit: true,
            allowParagraphStyles: true
        });
    }

    _calcProps(props, options) {
        const { size, children } = props;
        const { autoHeight } = options;

        const titleProps = this.title.calcProps(size, {
            autoHeight: true,
            forceTextScale: this.parentElement.matchSizes ? this.parentElement.titleTextScale : null
        });

        const bodyProps = this.body.calcProps(new geom.Size(size.width, size.height - titleProps.size.height), {
            autoHeight,
            forceTextScale: this.parentElement.matchSizes ? this.parentElement.bodyTextScale : null
        });
        bodyProps.bounds = new geom.Rect(0, titleProps.size.height, bodyProps.size);

        this.parentElement.titleTextScale = Math.min(this.parentElement.titleTextScale, this.title.textScale);
        this.parentElement.bodyTextScale = Math.min(this.parentElement.bodyTextScale, this.body.textScale);

        return { size: new geom.Size(size.width, titleProps.size.height + bodyProps.size.height) };
    }
}

export const elements = {
    TextBoxGrid,
};
