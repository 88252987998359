import React from "reactn";
import styled from "styled-components";
import ContentEditable from "react-contenteditable";

import { app } from "js/namespaces";
import { _, tinycolor, $ } from "legacy-js/vendor";
import { TextStyleType } from "legacy-common/constants";
import * as geom from "js/core/utilities/geom";
import { ds } from "js/core/models/dataService";
import { ShowConfirmationDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

import { AuthoringBlock } from "./AuthoringBlock";

function GetTextBlockDefaultStyle(model, backgroundColor = null) {
    let styles;
    switch (model.textStyle) {
        case TextStyleType.HEADING:
            styles = {
                fontFamily: app.currentTheme.get("fontHeaderFontId"),
                fontWeight: app.currentTheme.get("fontHeaderWeight"),
                fontSize: 60,
                letterSpacing: 0,
                lineHeight: 1.2,
                gapTop: 20,
                gapBottom: 20
            };
            break;
        case TextStyleType.TITLE:
            styles = {
                fontFamily: app.currentTheme.get("fontTitleFontId"),
                fontWeight: app.currentTheme.get("fontTitleWeight"),
                fontSize: 26,
                letterSpacing: 0,
                lineHeight: 1.4,
                gapTop: 20,
                gapBottom: 20
            };
            break;
        case TextStyleType.CAPTION:
            styles = {
                fontFamily: app.currentTheme.get("fontTitleFontId"),
                fontWeight: app.currentTheme.get("fontTitleWeight"),
                fontSize: 20,
                letterSpacing: 0,
                lineHeight: 1.4,
                background: "rgba(0,0,0,.2)",
                padding: 20,
                display: "flex",
                alignItems: "center"
            };

            if (backgroundColor && backgroundColor.isDark()) {
                styles.background = "rgba(255,255,255,0.2)";
            } else {
                styles.background = "rgba(0,0,0,.1)";
            }

            break;
        case TextStyleType.BODY:
        case TextStyleType.BULLET_LIST:
        case TextStyleType.NUMBERED_LIST:
        default:
            styles = {
                fontFamily: app.currentTheme.get("fontBodyFontId"),
                fontWeight: app.currentTheme.get("fontBodyWeight"),
                fontSize: 20,
                letterSpacing: 0,
                lineHeight: 1.4,
                gapTop: 10,
                gapBottom: 10
            };
            break;
    }

    return styles;
}

export function GetTextBlockStyle(model, scale = 1, backgroundColor = null) {
    const styles = GetTextBlockDefaultStyle(model, backgroundColor);

    styles.fontSize = _.defaultTo(model.fontSize, styles.fontSize);
    styles.fontFamily = _.defaultTo(model.fontFamily, styles.fontFamily);

    if (model.fontWeight) {
        styles.fontWeight = model.fontWeight;
    }

    styles.textAlign = model.textAlign;
    styles.letterSpacing = _.defaultTo(model.letterSpacing, styles.letterSpacing);
    styles.lineHeight = _.defaultTo(model.lineHeight, styles.lineHeight);
    styles.hyphens = "manual";
    styles.overflowWrap = "normal";

    styles.fontSize = Math.round(styles.fontSize * scale);

    styles.lineHeightAdj = (styles.fontSize * styles.lineHeight - styles.fontSize) / 2 * -1;

    return styles;
}

export const TextBlockContainer = styled.div` 
    position: relative;
    width: 100%;
    font-size: 20px;
    cursor: text;

    ul, ol {
        margin: 0px;
        padding: 10px 0px 0px 40px;

        ul, ol {
            padding-top: 0px;
        }
    }

    li {
        margin-bottom: 20px;
    }

    a {
        color: $newBlue;
        text-decoration: none;
        pointer-events: auto;
        cursor: pointer;
    }
    
    [contenteditable=true]:empty:before {
      content: "${({ placeholder }) => placeholder ?? "Type '/' for styles"}";
      pointer-events: none;
      display: block;
      font-style: italic;
      color: ${({ color }) => tinycolor(color).setAlpha(0.3).toRgbString()};
      opacity: ${({ showPlaceholder }) => showPlaceholder ? 1 : 0};
    }
`;

const LIST_INDENT = 30;
const LIST_DECORATION_WIDTH = 20;

const ListDecoration = styled.div`
  position: absolute;
  left: ${props => props.indent}px;
  top: 0px;
  height: 100%;
`;

const NumberedListItem = styled.div`
    width: 100%;
    height: 100%;
    font-family: ${props => props.styles.fontFamily};
    font-size: ${props => props.styles.fontSize * (props.scale || 1)}px;
    color: ${props => props.color};
    margin-right: 0px;
    line-height: ${props => props.styles.lineHeight};
`;

export class TextBlock extends AuthoringBlock {
    state = {
        isEditing: false
    }

    get blockMargin() {
        return {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };
    }

    get bounds() {
        return geom.Rect.FromBoundingClientRect(this.ref.current.getBoundingClientRect()).inflate(this.blockMargin);
    }

    get textStyle() {
        return this.model.textStyle;
    }

    getTextStyles() {
        const { scale, textAlign, backgroundColor } = this.props;

        const styles = GetTextBlockStyle(this.model, _.defaultTo(scale, 1), backgroundColor);
        styles.color = this.textColor;

        if (this.textStyle == TextStyleType.NUMBERED_LIST) {
            styles.paddingLeft = ((LIST_DECORATION_WIDTH + 5) * styles.fontSize / 20) + this.listIndent * LIST_INDENT;
            // Forcing left text align for list items
            styles.textAlign = "left";
        } else if (this.textStyle == TextStyleType.BULLET_LIST) {
            styles.paddingLeft = (LIST_DECORATION_WIDTH * styles.fontSize / 20) + this.listIndent * LIST_INDENT;
            styles.textAlign = "left";
        } else {
            styles.textAlign = _.defaultTo(styles.textAlign, textAlign);
        }

        styles.textStyle = this.textStyle;

        return styles;
    }

    get textColor() {
        const { canvas } = this.props;

        if (this.model.fontColor) {
            if (!canvas.getTheme().palette.isRawColor(this.model.fontColor)) {
                return canvas.getTheme().palette.getColor(this.model.fontColor).toRgbString();
            }

            return this.model.fontColor;
        }

        return this.autoColor;
    }

    get autoColor() {
        const { backgroundColor, canvas } = this.props;

        if (backgroundColor && backgroundColor.isDark()) {
            return canvas.getTheme().palette.getColor("primary_light").toRgbString();
        } else {
            return canvas.getTheme().palette.getColor("primary_dark").toRgbString();
        }
    }

    get listIndent() {
        return _.defaultTo(this.model.indent, 0);
    }

    get baseFontId() {
        return this.model.fontFamily || this.getTextStyles().fontFamily;
    }

    getListIndentWidth(fontSize) {
        return LIST_INDENT * fontSize / 20;
    }

    renderListDecoration(styles) {
        if (styles.textStyle == TextStyleType.NUMBERED_LIST || styles.textStyle == TextStyleType.BULLET_LIST) {
            const { sequenceNum, scale } = this.props;

            let decoration;
            if (styles.textStyle == TextStyleType.NUMBERED_LIST) {
                decoration = <NumberedListItem scale={scale} styles={styles} color={this.textColor}>{sequenceNum}.</NumberedListItem>;
            } else {
                const r = 4 * styles.fontSize / 20;
                const cy = styles.fontSize * styles.lineHeight / 2;
                decoration = <svg><circle r={r} cx={r} cy={cy} fill={this.autoColor} /></svg>;
            }

            return (
                <ListDecoration indent={this.listIndent * LIST_INDENT}>
                    {decoration}
                </ListDecoration>
            );
        }
    }

    get placeholder() {
        return "Type text";
    }

    get html() {
        const { model } = this.props;
        return _.defaultTo(model.html, "");
    }

    get isEditing() {
        return this.state.isEditing;
    }

    onStartEditing() {
        this.setState({ isEditing: true });
    }

    onStopEditing() {
        this.setState({ isEditing: false });
    }

    onClick = event => {
        const { canvas } = this.props;

        const target = event.target;
        if (!target) {
            return;
        }

        // closest() will also return target if it is a link
        const linkNode = $(target).closest("a")[0];
        if (!linkNode) {
            return;
        }

        // Intercepting link clicks and forcing them to open in
        // new window
        event.stopPropagation();
        event.preventDefault();

        // Ignore clicks on links unless we are playing back
        if (!canvas.isPlayback) {
            return;
        }

        const href = linkNode.getAttribute("href");
        const isPublicLink = ds.selection.presentation.get("link")?.type === "public";

        if (isPublicLink) {
            ShowConfirmationDialog({
                title: "Are You Sure?",
                message: `Clicking this link will open a web page that is not controlled by Beautiful.ai. Are you sure you want to go to ${href}?`,
                acceptCallback: () => {
                    window.open(href, "_blank");
                }
            });
        } else {
            window.open(href, "_blank");
        }
    }

    render() {
        const { canvas, isFirstBlock, isLastBlock, gap } = this.props;
        const { isEditing } = this.state;

        let placeholder;
        if (this.textStyle == TextStyleType.BODY) {
            placeholder = "Type '/' for styles";
        } else {
            placeholder = "Type " + this.textStyle;
        }

        const textStyles = this.getTextStyles();
        if (isEditing) {
            textStyles.color = "transparent";
        }

        // We need to prevent text selection on links which can only be done via disabling contenteditable,
        // but we can't just disable contenteditable always because it'll change the way empty paragraphs are displayed,
        // so we're disabling contentditable only when there's a link and we're in playback (in editor we don't
        // need to disable it because the actual contenteditable will be under the editor's contenteditable which
        // will prevent events from propagating down)
        const isContentEditableDisabled = this.html.includes("</a>") && canvas.isPlayback;

        // determine if the placeholder should be visible
        let { html } = this;

        // if there's no content, then most likey we want to
        // show the placeholder
        let showPlaceholder = !_.trim(html).length;

        // if in playback mode or editing, then we don't want
        // to show the placeholder -- when editing, the "editor"
        // placeholder is activated in a different file
        if (canvas.isPlayback || isEditing) {
            showPlaceholder = false;
        } else if (showPlaceholder) {
            // if the placeholder is still expected to be
            // shown, trim the content (so it causes the placeholder
            // to actually be visible)
            html = "";
        }

        return (
            <TextBlockContainer
                showPlaceholder={showPlaceholder}
                placeholder={placeholder}
                color={this.autoColor}
                isFirstBlock={isFirstBlock}
                isLastBlock={isLastBlock}
                style={{
                    marginTop: (isFirstBlock ? 0 : textStyles.gapTop + gap) + textStyles.lineHeightAdj,
                    marginBottom: (isLastBlock ? 0 : textStyles.gapBottom + gap) + textStyles.lineHeightAdj
                }}
            >
                {this.renderListDecoration(textStyles)}
                <ContentEditable
                    disabled={isContentEditableDisabled}
                    innerRef={this.ref}
                    html={html}
                    onClick={this.onClick}
                    style={{
                        width: "100%",
                        height: "100%",
                        ...textStyles,
                    }}
                />
            </TextBlockContainer>
        );
    }
}
