import styled from "styled-components";

export const Breadcrumb = styled.div`
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 10px;
    color: #444;
    flex-shrink: 0;

    .dark-mode & {
        color: #ccc;
    }
`;
