import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle, DialogContent, Icon, IconButton } from "@material-ui/core";
import { Providers, ProviderState, SimpleProvider } from "@microsoft/mgt-element";
import { FileList, MgtTemplateProps } from "@microsoft/mgt-react";
import type { DriveItem } from "@microsoft/microsoft-graph-types";

import { BeautifulDialog } from "./BaseDialog";
import Spinner from "../Spinner";
import { Gap10 } from "../Gap";

const DialogContentWrapper = styled(DialogContent)`
    margin-bottom: 20px;
    max-height: 500px;
    mgt-file-list {
        --font-family: "Source Sans Pro";
    }

    & .empty-state-text {
        font-weight: 400;
        font-size: 18px;
        color: #666;
        padding: 0px 0px 20px 12px;
    }

    & .current-folder {
        display: flex;
        align-items: center;
        padding: 0 0 20px;

        & .current-folder-card {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 15px 10px 10px;
            font-weight: 600;
            font-size: 14px;

            & .MuiIcon-root {
                opacity: 0.5;
            }
            & span {
                margin-left: 10px;
            }
        }        
    }
`;

interface Props {
    token: string,
    fileExtensions: string[],
    handleSelectedFile: Function,
    closeDialog: Function
}

interface State {
    filterFolders: DriveItem[],
    isPickerReady: boolean
}

export default class OneDriveFilePickerDialog extends Component<Props, State> {
    state = {
        filterFolders: [],
        isPickerReady: false
    }

    componentDidMount() {
        const { token } = this.props;

        if (token) {
            Providers.globalProvider = new SimpleProvider(scopes => Promise.resolve(token));
            Providers.globalProvider.setState(ProviderState.SignedIn);

            this.setState({ isPickerReady: true });
        }
    }

    handleSelectedFile = event => {
        const { fileExtensions, handleSelectedFile, closeDialog } = this.props;

        const driveItem = event.detail as DriveItem;
        if (driveItem.folder) {
            this.setState({ filterFolders: [driveItem, ...this.state.filterFolders] });
        } else if (fileExtensions.includes(/(?:\.([^.]+))?$/.exec(driveItem.name)[1])) {
            handleSelectedFile(driveItem);
            closeDialog();
        }
    }

    render() {
        const { closeDialog } = this.props;
        const { isPickerReady, filterFolders } = this.state;

        return (
            <BeautifulDialog closeButton closeDialog={closeDialog}>
                <DialogTitle>OneDrive File Picker</DialogTitle>
                <DialogContentWrapper>
                    {!isPickerReady && <Spinner />}
                    {isPickerReady && (
                        <>
                            {filterFolders.length ? (
                                <div className="current-folder">
                                    <IconButton size="small" onMouseUp={() => this.setState({ filterFolders: filterFolders.slice(0, -1) })}>
                                        <Icon>arrow_backward</Icon>
                                    </IconButton>
                                    <div className="current-folder-card">
                                        <Icon>folder_open</Icon>
                                        <Gap10 />
                                        {filterFolders[0].name}
                                        <span>({filterFolders[0].folder?.childCount ?? 0})</span>
                                    </div>
                                </div>
                            ) : <></>}
                            <FileList itemId={filterFolders[0]?.id} itemClick={this.handleSelectedFile}>
                                <EmptyStateTemplate template="no-data" />
                            </FileList>
                        </>
                    )}
                </DialogContentWrapper>
            </BeautifulDialog>
        );
    }
}

class EmptyStateTemplate extends Component<MgtTemplateProps> {
    render() {
        return <div className="empty-state-text">No files found</div>;
    }
}
