import React, { Component } from "reactn";
import TagsInput from "react-tagsinput";
import { Chip, Popover } from "@material-ui/core";
import styled from "styled-components";
import Icon from "legacy-js/react/components/Icon";
import { delimiterRegex } from "js/core/utilities/regex";
import { TAG_DELIMITERS } from "legacy-js/react/constants";
import { InviteSuggestions } from "./InviteSuggestions";
import { Gap5 } from "../Gap";
import { Key } from "js/core/utilities/keys";
import { FolderProfile, UserProfile } from "legacy-js/react/components/Avatar";
import { clipboardRead, ClipboardType } from "js/core/utilities/clipboard";

const StyledChip = styled(Chip)`
  background: #D6EFF9 !important;
  border-radius: 3px !important;
  height: 44px !important;
  margin: 3px !important;
  color: #666666 !important;
  font-weight: 600 !important;
  padding-right: 8px !important;

  .profile {
      .name,
      .email {
        color: #666666 !important;
      }
  }

  .avatar.folder > i {
    color: #11A9E2;
  }
`;

const StyledTagsInput = styled(TagsInput)`
  flex-grow: 2;
  padding: 3px;
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  font-size: 12px;
  height: 100%;
`;

const StyledInput = styled.input`
    background: transparent;
    height: 40px;
    border: 0;
    color: #222;
    font-family: Source Sans Pro;
    font-weight: 500;
    outline: none;
    padding: 5px;
    font-size: 14px;
    flex-grow: 2;
`;

const TagLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Error = styled.div`
    display: flex;
    width: 100%;
    color: #ff4400;
    font-size: 14px;
    font-weight: bold;
    align-items: center;
    padding: 10px;
`;

const InviteTag = props => {
    const {
        inviteContext,
        tag,
        keyProp,
    } = props;

    let label;
    if (tag.type === "folder") {
        label = <FolderProfile profile={tag} />;
    } else {
        label = <UserProfile profile={tag} />;
    }

    return (
        <StyledChip
            key={keyProp}
            label={label}
            onDelete={() => inviteContext.deselectItems([tag])}
            deleteIcon={<Icon small light iconName="close" />}
        />
    );
};

export class InviteTagsInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            permission: "write",
            duplicatedEmails: [],
            suggestionsAllowedOpen: false,
            skipNextFocus: false,
        };

        if (this.props.tags?.length) {
            this.handleChangeTags(this.props.tags);
        }

        this.refInput = React.createRef();
    }

    get suggestionsOpen() {
        const {
            inviteContext,
        } = this.props;
        const {
            suggestionsAllowedOpen,
        } = this.state;

        const result = (
            suggestionsAllowedOpen &&
            (
                // Suggestions are only allowed open if the
                //   selection is empty or the filter is non-empty
                !inviteContext.selection.length ||
                !!inviteContext.filter.length
            ) &&
            (
                // Only show the suggestions if we have suggestions to show
                //   or the filter starts with a category tag
                !!inviteContext.filteredAllSuggestions.length ||
                inviteContext.filter.startsWith("@") ||
                inviteContext.filter.startsWith("#")
            ) &&
            // Don't show suggestions if we've got a visible error
            !inviteContext.errors?.length
        );
        return result;
    }

    inputScrollIntoView = () => {
        // Let UI have a frame to update before we try to scroll
        setTimeout(() => {
            this.refInput.current?.scrollIntoView({
                block: "end",
                inline: "end",
                behavior: "smooth",
            });
        });
    }

    handleChangeTags = (tags, changed) => {
        const { inviteContext } = this.props;
        inviteContext.selectItems(changed);
    }

    handleKeyCodes = event => {
        const { inviteContext } = this.props;

        switch (event.keyCode) {
            case Key.BACKSPACE:
                if (!inviteContext.filter) {
                    // Prevent further events
                    event.nativeEvent.stopImmediatePropagation();
                    event.nativeEvent.preventDefault();

                    inviteContext.popItem();
                }
                break;
            case Key.ENTER:
            case Key.TAB:
                // Only select on ENTER/TAB when the suggestions
                //   are open and have available suggestions
                if (
                    inviteContext.selectableSuggestions.length &&
                    this.suggestionsOpen
                ) {
                    // Prevent further events
                    event.nativeEvent.stopImmediatePropagation();
                    event.nativeEvent.preventDefault();

                    inviteContext.selectPendingSelection();
                } else if (inviteContext.filter.length) {
                    // Prevent further events
                    event.nativeEvent.stopImmediatePropagation();
                    event.nativeEvent.preventDefault();

                    inviteContext.selectFilterAsEmail();
                }
                break;
            case Key.SPACE:
            case Key.COMMA:
            case Key.SEMICOLON:
            case Key.UP_ARROW:
            case Key.DOWN_ARROW:
                // Prevent further events
                event.nativeEvent.stopImmediatePropagation();
                event.nativeEvent.preventDefault();

                switch (event.keyCode) {
                    case Key.SPACE:
                    case Key.COMMA:
                    case Key.SEMICOLON:
                        inviteContext.selectFilterAsEmail();
                        break;
                    case Key.UP_ARROW:
                        if (this.suggestionsOpen) {
                            inviteContext.pendingSelectPrev();
                        }
                        break;
                    case Key.DOWN_ARROW:
                        if (this.suggestionsOpen) {
                            inviteContext.pendingSelectNext();
                        }
                        break;
                }
                break;
        }

        this.inputScrollIntoView();
    }

    handleFilter = event => {
        const { inviteContext } = this.props;
        const filter = event.target.value;
        inviteContext.setFilter(filter);

        // Ensure the suggestions are open after the filter has changed
        if (!this.state.suggestionsAllowedOpen) {
            this.setState({
                suggestionsAllowedOpen: true,
            });
        }

        this.inputScrollIntoView();
    }

    handlePaste = async event => {
        const text = await clipboardRead([ClipboardType.TEXT], event);
        if (text) {
            // Prevent the default pasting in the textbox
            event.nativeEvent.stopImmediatePropagation();
            event.nativeEvent.preventDefault();
            const emails = text.split(delimiterRegex).map(d => d.trim());
            const { inviteContext } = this.props;
            inviteContext.selectItems(emails);
        }
    }

    handleFocus = event => {
        if (event.relatedTarget) {
            const { inviteContext } = this.props;

            // Only open the suggestions on focus if we don't have any selections.
            //   This is to minimize overlaying submit buttons.
            if (!inviteContext.selection.length) {
                this.setState({
                    suggestionsAllowedOpen: true,
                });
            }
        }
    }

    handleBlur = event => {
        if (event.relatedTarget) {
            this.setState({
                suggestionsAllowedOpen: false,
            });

            const { inviteContext } = this.props;

            inviteContext.selectFilterAsEmail();
        }
    }

    render() {
        const { inviteContext, inputProps } = this.props;

        return (
            <>
                <div
                    id="invite"
                    className="invite-input"
                >
                    <StyledTagsInput
                        className="tags-input"
                        addKeys={TAG_DELIMITERS}
                        addOnBlur
                        addOnPaste
                        onlyUnique
                        maxTags={50}
                        value={inviteContext.selection}
                        renderTag={props => (
                            <InviteTag
                                {...props}
                                inviteContext={inviteContext}
                                keyProp={props.key}
                            />
                        )}
                        onChange={this.handleChangeTags}
                        renderInput={props => (
                            <StyledInput
                                ref={this.refInput}
                                placeholder={inviteContext.filterPlaceholder}
                                value={inviteContext.filter}
                                onKeyDown={this.handleKeyCodes}
                                onChange={this.handleFilter}
                                onPaste={this.handlePaste}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                {...inputProps}
                            />
                        )}
                        renderLayout={(tagComponents, inputComponent) => (
                            <TagLayout>
                                {tagComponents}
                                {inputComponent}
                            </TagLayout>
                        )}
                    />
                </div>
                {
                    !!inviteContext.errors?.length &&
                    inviteContext.errors.map(error => (
                        <Error key={error}>
                            <Icon iconName="warning" />
                            <Gap5 />
                            <span>{error}</span>
                        </Error>
                    ))
                }
                <Popover
                    disableAutoFocus
                    disableEnforceFocus
                    transitionDuration={{ exit: 0 }}
                    open={this.suggestionsOpen}
                    anchorEl={this.refInput.current}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    onClose={() => {
                        this.setState({
                            suggestionsAllowedOpen: false,
                        });
                    }}
                >
                    <InviteSuggestions
                        {...this.props}
                        onSelect={() => this.inputScrollIntoView()}
                    />
                </Popover>
            </>
        );
    }
}
