import { AssetType, HorizontalAlignType, TrayType, VerticalAlignType } from "legacy-common/constants";
import * as geom from "js/core/utilities/geom";

import { TextGroup } from "../../base/TextGroup";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";

export class IconTextGrid extends CollectionElement {
    getChildItemType() {
        return IconTextGridItem;
    }

    get maxItemCount() {
        return 12;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" }
        };
    }

    get orientation() {
        return this.model.orientation || this.options.orientation || "auto";
    }

    _calcProps(props, options) {
        let { size } = props;
        let layouter = this.getLayouter(props, this.itemElements, size);

        switch (this.orientation) {
            case "auto":
                this.updateStyles(this.styles.vertical, true);
                layouter.calcRowLayout({
                    itemOptions: {
                        orientation: "vertical"
                    }
                });

                if (!layouter.isFit || !layouter.isTextFit) {
                    this.updateStyles(this.styles.horizontal, true);
                    //special case styling when there is a large tray
                    if (this.canvas.model.layout.trayLayout && this.canvas.model.layout.trayLayout.equalsAnyOf(TrayType.RIGHT_INLINE, TrayType.RIGHT_TRAY, TrayType.LEFT_INLINE, TrayType.LEFT_TRAY) && this.canvas.getTrayElement() && this.canvas.getTrayElement().trayWidth > 300) {
                        this.updateStyles(this.styles.horizontal.withLargeTray, true);
                    }
                    layouter.calcColumnLayout({
                        itemOptions: {
                            orientation: "horizontal"
                        }
                    });
                    layouter.alignHorizontally(HorizontalAlignType.CENTER);
                }
                break;
            case "horizontal":
                this.updateStyles(this.styles.horizontal, true);
                //special case styling when there is a large tray
                if (this.canvas.model.layout.trayLayout && this.canvas.model.layout.trayLayout.equalsAnyOf(TrayType.RIGHT_INLINE, TrayType.RIGHT_TRAY, TrayType.LEFT_INLINE, TrayType.LEFT_TRAY) && this.canvas.getTrayElement() && this.canvas.getTrayElement().trayWidth > 300) {
                    this.updateStyles(this.styles.horizontal.withLargeTray, true);
                }
                layouter.calcColumnLayout({ itemOptions: { orientation: "horizontal" } });
                layouter.alignHorizontally(HorizontalAlignType.CENTER);
                break;
            case "vertical":
                this.updateStyles(this.styles.vertical, true);
                layouter.calcRowLayout({ itemOptions: { orientation: "vertical" } });
                break;
        }

        layouter.alignVertically(VerticalAlignType.MIDDLE);

        props.isFit = layouter.isFit;

        return { size };
        // return { size: layouter.size };
    }
}

export class IconTextGridItem extends CollectionItemElement {
    _build() {
        this.media = this.addElement("media", () => FramedMediaElement, {
            model: this.model,
            defaultAssetType: AssetType.ICON,
            allowUnframedImages: false
        });
        this.text = this.addElement("text", () => TextGroup, {
            autoHeight: true,
            model: this.model,
            title: {
                singleLine: true,
            },
            body: {
                allowParagraphStyles: true,
                allowEmptyLines: false
            }
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        this.updateStyles(this.styles[options.orientation || "horizontal"], true);

        let layouter = this.getLayouter(props, [this.media, this.text], size);

        if (options.orientation == "vertical") {
            layouter.calcVerticalBlockLayout({
                contentSize: new geom.Size(this.styles.content.width, this.styles.content.height),
                horizontalAlign: layouter.HorizontalAlignType.CENTER
            });
        } else {
            if (options.bulletAlign == "right") {
                this.text.styles.textAlign = HorizontalAlignType.RIGHT;
                this.text.styles.marginRight = this.text.styles.marginLeft;
                this.text.styles.marginLeft = 0;
            }

            layouter.calcHorizontalBlockLayout({
                contentSize: new geom.Size(this.styles.content.width, this.styles.content.height),
                verticalAlign: layouter.VerticalBlockAlignType.MIDDLE_OR_TOP,
                reverse: options.bulletAlign == "right"
            });
        }

        return {
            size: layouter.size,
            isTextFit: layouter.isTextFit
        };
    }

    _migrate_9() {
        if (this.model.backgroundColor) {
            if (this.model.backgroundColor == this.canvas.model.layout.backgroundColor) {
                this.model.frameType = "none";
            } else {
                this.model.color = this.model.backgroundColor;
            }
        }
    }
}

export const elements = {
    IconTextGrid,
};
