class EditorManager {
    constructor(editors) {
        this.constructors = {};

        for (const className in editors) {
            this._register(className, editors[className]);
        }
    }

    _register(name, ctor) {
        if (name == undefined) {
            throw new Error("cannot register editor");
        }
        this.constructors[name] = ctor;
    }

    get(name) {
        return this.constructors[name];
    }

    has(name) {
        return name in this.constructors;
    }
}

export default new EditorManager();
