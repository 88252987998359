import CycleAnimator from "./CycleAnimator";

export default class ConnectorDashAnimator extends CycleAnimator {
    get duration() {
        return this.element.connectorPath.length * 20;
    }

    handleAnimationFrame(time) {
        let distance = this.element.connectorPath.length;
        let percentComplete = time / this.duration;
        if (this.playReverse) {
            percentComplete = 1 - percentComplete;
        }
        this.element.strokeDashoffset = distance - Math.round(distance * percentComplete);
    }
}
