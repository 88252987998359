import { FormatType } from "legacy-common/constants";

import { BaseElement } from "../../base/BaseElement";
import { RadialBarGraph } from "../infographics/RadialBarGraph";
import CompareValuesItemLabel from "../compare/CompareValuesItemLabel";

export class AuthoringRadialBar extends BaseElement {
    static get schema() {
        return {
            value: 50
        };
    }

    get isAuthoringElement() {
        return true;
    }

    get preserveAspectRatio() {
        return true;
    }

    _build() {
        this.radialBar = this.addElement("radialBar", () => RadialBarGraph, {
            showLabel: this.model.format != FormatType.NONE,
            valueFunction: () => this.percentageValue,
            labelElement: CompareValuesItemLabel
        });
    }

    _calcProps(props, options) {
        const { size } = props;
        return { size };
    }
}
