import React, { Component } from "reactn";
import { FeatureType } from "js/core/models/features";
import {
    Divider,
    UIContainer,
    UINavigation,
    UINavigationItem
} from "legacy-js/react/components/UiComponents";
import Analytics from "legacy-js/react/views/Analytics/Analytics";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import ProBadge from "legacy-js/react/components/ProBadge";
import { trackActivity } from "js/core/utilities/utilities";
import AnalyticsController from "legacy-js/react/views/Analytics/AnalyticsController";
import AppController from "legacy-js/core/AppController";

import "css/presentation-settings.scss";
import ExportPane from "./panes/ExportPane";
import SharePane from "./panes/SharePane";
import VersionHistoryPane from "./panes/VersionHistoryPane";
import CollaboratePane from "./panes/CollaboratePane";
import PlayerSettingsPane from "./panes/PlayerSettingsPane";
import presentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";

function trackAnalytics(id) {
    switch (id) {
        case "share":
            trackActivity("PresentationActions", "ShowShare");
            break;
        case "collaborate":
            trackActivity("PresentationActions", "ShowCollaborate");
            break;
        case "export":
            trackActivity("PresentationActions", "ShowExport");
            break;
        case "player-settings":
            trackActivity("PresentationActions", "ShowPlayerSettings");
            break;
        case "analytics":
            trackActivity("PresentationAnalytics", "Open");
            trackActivity("PresentationActions", "ShowAnalytics");
            break;
        case "version-history":
            trackActivity("PresentationActions", "ShowRecoverSlides");
            break;
    }
}

export default class PresentationSettingsContainer extends Component {
    constructor(props) {
        super();
        this.setGlobal({ alertMessage: null });

        this.state = {
            isOpen: true,
            selectedPaneId: props.startPane || "share"
        };
    }

    componentDidMount() {
        const { presentation } = this.props;
        const { selectedPaneId } = this.state;
        window.history.replaceState({}, "", `/${presentation.id}/settings/${selectedPaneId}`);

        window.addEventListener("popstate", this.handlePopState);
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.handlePopState);
    }

    handlePopState = event => {
        const path = window.location.pathname;
        const pane = path.split("/").pop();

        if (path.includes("settings")) {
            this.setState({ selectedPaneId: pane });
        } else {
            this.props.closeDialog();
        }
    };

    handleBackClicked = () => {
        const currentSlideIndex = presentationEditorController.getCurrentSlideIndex() + 1;

        if (this.state.selectedPaneId === "analytics" && AnalyticsController.showSelectedLink) {
            AnalyticsController.clearSelectedLink();
            this.forceUpdate();
        } else {
            this.setState({ isOpen: false });
        }

        window.history.replaceState({}, "", `/${this.props.presentation.id}/${currentSlideIndex}`);
    };

    handleReturnToLibrary = () => {
        this.props.closeDialog();
        AppController.showLibrary();
    }

    selectPane = pane => {
        trackAnalytics(pane);
        this.setState({ selectedPaneId: pane });
        window.history.pushState({}, "", `/${this.props.presentation.id}/settings/${pane}`);
    };

    renderSelectedPane = () => {
        const { presentation } = this.props;
        const { selectedPaneId } = this.state;

        switch (selectedPaneId || "share") {
            case "share":
                return <SharePane presentation={presentation} />;
            case "collaborate":
                return <CollaboratePane presentation={presentation} />;
            case "analytics":
                return <Analytics presentation={presentation} />;
            case "export":
                return <ExportPane presentation={presentation} />;
            case "player-settings":
                return <PlayerSettingsPane presentation={presentation} />;
            case "version-history":
                return <VersionHistoryPane presentation={presentation} />;
        }
    };

    render() {
        const { presentation } = this.props;
        const { isOpen, selectedPaneId } = this.state;

        return (
            <UIContainer
                isOpen={isOpen}
                className="presentation-settings"
                onClose={this.props.closeDialog}
                onBeforeClose={this.props.onBeforeClose}
            >
                <UINavigation
                    selected={selectedPaneId}
                    onSelect={id => {
                        this.selectPane(id);
                    }}
                    onBack={this.handleBackClicked}
                    fullHeight
                >
                    <UINavigationItem onCustomSelect={this.handleReturnToLibrary} id="return-to-library" title="Return to Library" icon="list" />

                    <Divider color="#666" margin={20} />

                    <UINavigationItem id="share" title="Share" icon="share" />
                    <UINavigationItem id="collaborate" title="Collaborate" icon="people" />
                    <UINavigationItem
                        id="player-settings"
                        title="Player Settings"
                        icon="play_circle_outline"
                        proBadge={
                            <ProBadge
                                show={shouldShowProBadge(FeatureType.PLAYER_SETTINGS, presentation.getWorkspaceId())}
                                analytics={{ cta: "PlayerSettings", ...presentation.getAnalytics() }}
                                workspaceId={presentation.getWorkspaceId()}
                            />
                        }
                    />
                    <UINavigationItem
                        id="analytics"
                        title="Analytics"
                        icon="show_chart"
                        proBadge={
                            <ProBadge
                                show={shouldShowProBadge(FeatureType.ANALYTICS, presentation.getWorkspaceId())}
                                analytics={{ cta: "Analytics", ...presentation.getAnalytics() }}
                                workspaceId={presentation.getWorkspaceId()}
                            />
                        }
                    />
                    <UINavigationItem id="export" title="Export" icon="cloud_download" />
                    <UINavigationItem id="version-history" title="Recover Slides" icon="history" />
                </UINavigation>

                {this.renderSelectedPane()}
            </UIContainer>
        );
    }
}
