import { controls } from "legacy-js/editor/ui";

import { ElementOptionsMenu } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { CreateMediaMenu } from "./pictureEditor";

const LogoGridSelection = CollectionElementSelection.extend({
    getAddItemLabel: function() {
        return "Add Logo";
    },

    renderControls: function() {
        this.createAddItemButton({ skipAddItemPopup: true });
    },

});

const LogoGridItemSelection = CollectionItemElementSelection.extend({
    getWidgetPosition() {
        return "inner";
    },

    getTitle: function() {
        return "Logo";
    },

    renderControls: function() {
        this.setupGridDragging();

        CreateMediaMenu(this, this.element.logo);
    }
});

const LogoGridOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Frame",
            property: "showFrame",
            enabled: this.element.canvas.getBackgroundColor().toHexString() == "#ffffff"
        });
    }
});

export const editors = {
    LogoGridSelection,
    LogoGridItemSelection,
    LogoGridOptionsMenu,
};
