import React, { Component } from "react";

import { UIPane } from "legacy-js/react/components/UiComponents";
import Loadable from "legacy-js/react/components/Loadable";
import Spinner from "legacy-js/react/components/Spinner";
import { trackActivity } from "js/core/utilities/utilities";

const PRESENTATION_TEMPLATE_URL = "https://www.beautiful.ai/all-presentation-templates";
const iframeStyles = {
    border: "none",
    width: "100%",
    height: "100%",
    pointerEvents: "auto"
};

export class ChooseStarterTemplatePane extends Component {
    state = {
        isLoading: true,
        isFinishing: false
    };

    componentDidMount() {
        window.addEventListener("message", this.handleMessageFromIFrame);
    }

    handleMessageFromIFrame = event => {
        if (event.data?.startsWith?.("create:")) {
            this.setState({ isFinishing: true });
            const templateId = event.data.substr(8);
            this.props.onCreate({ type: "starter-template", templateId });
            trackActivity("Presentation", "Start", null, null, { source: "starter-template", template_id: templateId }, { audit: false });
        }
    };

    componentWillUnmount() {
        window.removeEventListener("message", this.handleMessageFromIFrame);
    }

    renderIframe() {
        const { isLoading } = this.state;
        return (
            <>
                <iframe src={PRESENTATION_TEMPLATE_URL}
                    style={iframeStyles}
                    onLoad={() => {
                        this.setState({ isLoading: false });
                    }}
                >
                </iframe>
                <Loadable isLoading={isLoading} />
            </>
        );
    }

    render() {
        const { isFinishing } = this.state;
        return (
            <UIPane>
                {isFinishing ? <Spinner /> : this.renderIframe()}
            </UIPane>
        );
    }
}
