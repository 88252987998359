import React, { Component } from "react";
import { Dialog, IconButton, Icon } from "@material-ui/core";
import type { DialogProps } from "@material-ui/core/Dialog";

import { app } from "js/namespaces";

interface Props extends Partial<DialogProps> {
    preventClose?: boolean;
    closeButton?: boolean;
    closeDialog?: Function;
}

export default class BeautifulDialog extends Component<Props> {
    handleClose = () => {
        const { preventClose, closeDialog } = this.props;

        if (!preventClose && closeDialog) {
            closeDialog();
        }
    }

    render() {
        const { preventClose, closeButton, closeDialog, open = true, ...rest } = this.props;

        const dialogProps = rest || {} as DialogProps;
        if (!("maxWidth" in dialogProps)) {
            dialogProps.maxWidth = "sm";
        }
        if (!("fullWidth" in dialogProps)) {
            dialogProps.fullWidth = true;
        }
        if (dialogProps.hideBackdrop) {
            dialogProps.disableEscapeKeyDown = true;
        }

        return (
            <Dialog
                {...dialogProps}
                className={"beautiful-dialog" + (dialogProps.className ? " " + dialogProps.className : "")}
                classes={{ paperScrollPaper: "dialog-paper", ...(dialogProps.classes || {}) }}
                onClose={(event, reason) => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        if (dialogProps.hideBackdrop) {
                            return;
                        }
                        (event as Event).stopPropagation();
                    }
                    this.handleClose();
                }}
                disableEnforceFocus
                open={open}
            >
                {!preventClose && (closeButton || app.isConstrained) && (
                    <IconButton
                        className="exit-button" onClick={() => this.handleClose()}
                        style={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                )}
                {this.props.children}
            </Dialog>
        );
    }
}
