import React, { Component } from "react";
import { DialogContent } from "@material-ui/core";
import { CompactView, Login, type assetType } from "@bynder/compact-view";
import BeautifulDialog from "./BeautifulDialog";

interface Props {
    callback: (assets: any, additionalInfo: any) => void;
    closeDialog: () => void;
    extensios?: assetType[];
}

class BynderFilePickerDialog extends Component<Props> {
    render() {
        const { closeDialog, extensios, callback } = this.props;

        return (
            <BeautifulDialog
                maxWidth="md"
                fullWidth={false}
                closeButton
                closeDialog={closeDialog}>
                <DialogContent>
                    <Login>
                        <CompactView
                            assetTypes={extensios}
                            mode="SingleSelectFile"
                            onSuccess={(assets, additionalInfo) => {
                                callback(assets, additionalInfo);
                                closeDialog();
                            }}
                        />
                    </Login>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}

export default BynderFilePickerDialog;
