import { Backbone, $, _ } from "legacy-js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import * as browser from "js/core/utilities/browser";
import { CanvasEventType } from "legacy-common/constants";
import setCursor from "js/core/utilities/cursor";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import { FeatureType } from "js/core/models/features";
import { AnchorType } from "js/core/utilities/geom";
import { ShowUpgradeDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import { getLibraryModelFromSlide } from "legacy-common/dataServiceHelpers";

import { TextElement } from "../elements/base/TextElement";
import { BaseElement } from "../elements/base/BaseElement";

const logger = getLogger(LogGroup.EDITOR);

export const SelectionLayer = Backbone.View.extend({
    id: "selection_layer",

    isCanvasGenerating: function() {
        return this.currentCanvas?.layouter?.isGenerating;
    },

    initialize: function({ editor, canvas }) {
        this.currentCanvas = canvas;
        this.currentCanvas.selectionLayer = this;

        this.editor = editor;

        this.listenTo(this.currentCanvas, CanvasEventType.REFRESH, () => this.refreshSelectionLayer());

        this.listenTo(ds.selection, "change:element", () => {
            if (this.isHidden) {
                return;
            }

            if (this.isCanvasGenerating()) {
                this.currentCanvas.layouter.runPostRender(() => this.showElementSelection());
            } else {
                this.showElementSelection();
            }
        });
        this.listenTo(ds.selection, "change:rolloverElement", () => {
            if (this.isHidden) {
                return;
            }

            if (this.isCanvasGenerating()) {
                this.currentCanvas.layouter.runPostRender(() => this.showElementRollover());
            } else {
                this.showElementRollover();
            }
        });

        this.selectionViews = [];
        this.rolloverViews = [];
        this.defaultOverlays = [];
        this.dragOverlays = [];

        this.debouncedShowElementDropTargets = _.debounce(() => this.showElementDropTargets(), 333, { leading: true });

        this.$el.css("pointer-events", "none");

        $(document).on("contextmenu.selection-layer", event => {
            if (!this.currentCanvas || this.isCanvasGenerating() || this.isHidden) {
                return;
            }

            if ($(event.target).hasClass("current_slide") || $(event.target).hasClass("mouse-event-capture")) {
                this.onContextMenu(event);
            }
        });

        $(document).on("mousemove.selection-layer", event => {
            if (!this.currentCanvas || this.isCanvasGenerating() || this.isHidden) {
                return;
            }

            if ($(event.target).closest(".rollover").length > 0) {
                // don't do anything on rollover if we are over a UI element that is a child of an already displayed rollover
                return;
            }

            if ($(event.target).closest(".current_slide").length) {
                this.onMouseMove(event);
            } else {
                ds.selection.rolloverElement = null;
            }
        });

        $(document).on("mousedown.selection-layer", event => {
            if (!this.currentCanvas || this.isCanvasGenerating() || this.isHidden) {
                return;
            }

            if ($(event.target).closest(".current_slide").length ||
            $(event.target).hasClass("selection-box") ||
            // Special case of add video overlay to canvas in a Classic slide (It doesn't support annotations, hence this hacky addition)
            (this.currentCanvas.isAuthoringCanvas() && this.currentCanvas.getCanvasElement().annotations.videoOverlay)
            ) {
                this.onMouseDown(event);
            } else if ($(event.target).attr("id") == "editor") {
                ds.selection.element = null;
            }

            // Will refresh if not in authoring canvas or there's a non-authoring element selected in authoring canvas
            if (!this.currentCanvas.isAuthoringCanvas() || (ds.selection.element && !ds.selection.element.isAuthoringElement)) {
                // Deferring in order to allow the chain triggered by selection change to execute first and set its locks (if needed)
                _.defer(() => this.refreshCollaborationLock());
            }
        });

        $(document).on("click.selection-layer", event => {
            if (!this.currentCanvas || this.isCanvasGenerating() || this.isHidden) {
                return;
            }

            if ($(event.target).closest(".watermark").length) {
                const workspaceId = UIController.getWorkspaceId();
                if (app.user.features.isFeatureEnabled(FeatureType.PLAYER_SETTINGS, workspaceId)) {
                    app.mainView.editorView.showPresentationSettings("player-settings");
                } else {
                    ShowUpgradeDialog({
                        type: UpgradePlanDialogType.REMOVE_BRANDING,
                        analytics: { cta: "Watermark", ...ds.selection.presentation.getAnalytics() },
                        workspaceId
                    });
                }
            }

            if ($(event.target).hasClass("current_slide") || $(event.target).hasClass("mouse-event-capture")) {
                this.onClick(event);
            }
        });

        $(document).on("dblclick.selection-layer", event => {
            if (!this.currentCanvas || this.isCanvasGenerating() || this.isHidden) {
                return;
            }

            if ($(event.target).closest(".current_slide").length || $(event.target).hasClass("mouse-event-capture")) {
                this.onDoubleClick(event);
            }
        });

        $(document).on("keydown.selection-layer", () => {
            if (!this.currentCanvas || this.isCanvasGenerating() || this.isHidden) {
                return;
            }

            // Will refresh if not in authoring canvas or there's a non-authoring element selected in authoring canvas
            if (!this.currentCanvas.isAuthoringCanvas() || (ds.selection.element && !ds.selection.element.isAuthoringElement)) {
                this.refreshCollaborationLock();
            }
        });
    },

    /**
     * Recreates lock if there's a selection (and the previous lock has expired)
     */
    refreshCollaborationLock: function() {
        if (ds.selection.element && !this.currentCanvas.isLockedForCollaborators()) {
            this.currentCanvas.lockSlideForCollaborators(30);
        }
    },

    isLocked: function() {
        if (!this.currentCanvas.isEditable) {
            return true;
        } else if (this.currentCanvas.dataModel.has("libraryItemId")) {
            if (!ds.teams.length) {
                //this is to handle collaborators on a presentation who are not part of the team
                return true;
            }

            const libraryItem = getLibraryModelFromSlide(this.currentCanvas.dataModel);

            // disabled library slides should not be editable unless collaboration locks
            // have been disabled (as in the Team Slides editor)
            if (libraryItem && libraryItem.get("isDisabled")) {
                return true;
            } else {
                //users with the 'member' role in a team should not be able to edit the slide OR
                // librarians should not be able to edit the slide if its not part of their team's resources
                const workspaceId = UIController.getWorkspaceId();
                return (!app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, workspaceId) || !ds.selection.slide.isLibrarySlideInCurrentUserOrg());
            }
        }
        return false;
    },

    // render all the overlays in the selection layer
    refreshSelectionLayer: function() {
        if (!this.currentCanvas) {
            return;
        }

        if (this.currentCanvas.layouter?.isGenerating) {
            return;
        }

        if (app.isPreviewingAnimation) return;

        if (app.isDraggingItem) {
            // while dragging relayout any rollover views but don't recreate them
            for (let rolloverView of this.rolloverViews) {
                rolloverView.layout();
            }
        } else {
            this.showDefaultOverlay();
            this.showElementSelection();
            this.showElementRollover();
        }
    },

    onContextMenu: function(event) {
        // Prevent the default browser context menu from showing
        event.preventDefault();

        if (app.isDraggingItem) return;
        if (app.isEditingImage) return;
        if (this.isDisabled) return;

        this.draggingImage = false; // maybe fix a bug that Diana is seeing where draggingImage=true during annotation dragging

        if (this.isLocked()) {
            return;
        }

        if ($(event.target).closest(".ui_widget").length || $(event.target).closest(".widget_bar").length || $(event.target).closest(".control").length) {
            return;
        }

        const clickedElement = this.currentCanvas.findFirstElementAtPoint(event.clientX, event.clientY, "selectable");

        // when we mousedown on a textElement, we need to pass the mousedown event to the texteditor immediately
        if (clickedElement && !clickedElement.isAuthoringElement) {
            if (clickedElement instanceof TextElement && ds.selection.element == clickedElement) {
                // if the textElement isn't currently selected, select it now and don't wait for the click
                if (ds.selection.element != clickedElement) {
                    ds.selection.element = clickedElement;
                }
                let selectionView = _.find(this.selectionViews, { element: ds.selection.element });
                if (selectionView) {
                    selectionView.onContextMenu(event);
                } else {
                    logger.warn("SelectionLayer wasn't able to find selectionView for TextElement");
                }
            } else {
                // delegate the click to a default overlay if necessary
                const control = this.findFirstControlAtPoint(event.pageX, event.pageY, "control");
                if (control) {
                    control[0].instance && control[0].instance.fire("contextmenu");
                    return;
                }

                // if the clickedElement is a BaseElement and it's not doubleClickToSelect, select it
                if (clickedElement instanceof BaseElement) {
                    if (!clickedElement.doubleClickToSelect) {
                        ds.selection.element = clickedElement;
                    }
                } else {
                    // otherwise pass the click event through
                    clickedElement.trigger("contextmenu", event);
                }

                let selectionView = _.find(this.selectionViews, { element: ds.selection.element });
                if (selectionView?.onContextMenu) {
                    selectionView.onContextMenu(event);
                }

                // pass click through to selected element rolloverElement
                if (ds.selection.rolloverElement && ds.selection.rolloverElement.rolloverOverlay) {
                    // if we aren't selecting an element, pass the click event through to any current rollover overlay
                    ds.selection.rolloverElement.rolloverOverlay.trigger("contextmenu_delegate", event);
                }

                return;
            }
        } else {
            // if we clicked on a widget with an element data attribute, select that element (like the drag_button)
            if ($(event.target).data().element) {
                ds.selection.element = $(event.target).data().element;
                return;
            }

            // deselect any element that was selected
            ds.selection.element = null;

            if (ds.selection.rolloverElement && ds.selection.rolloverElement.rolloverOverlay) {
                // if we aren't selecting an element, pass the click event through to any current rollover overlay
                ds.selection.rolloverElement.rolloverOverlay.trigger("contextmenu_delegate", event);
            }
            if (ds.selection.element && ds.selection.element.overlay) {
                // if we aren't selecting an element, pass the click event through to any current rollover overlay
                ds.selection.element.overlay.trigger("contextmenu_delegate", event);
            }

            if (ds.selection.rolloverElement && (ds.selection.rolloverElement.defaultOverlayNode)) {
                const control = this.findFirstControlAtPoint(event.pageX, event.pageY, "control");
                if (control) {
                    control.trigger("contextmenu");
                }
            }
        }
    },

    onMouseMove: function(event) {
        if (this.isDisabled) return;

        if (app.isDraggingItem) return;
        if (!this.currentCanvas || !this.currentCanvas.layouter || !app.currentCanvas || !app.currentCanvas.layouter || !app.currentCanvas.layouter.isLayedOut) return;
        if (app.mainView.editorView.isShowingSideBar && app.mainView.editorView.sideBar && app.mainView.editorView.sideBar.preventCanvasEditingWhenOpen) return;

        if ($(event.target).closest(".widget_bar").length) {
            ds.selection.rolloverElement = null;
            return;
        }

        const rolloverElement = this.currentCanvas.findFirstElementAtPoint(event.pageX, event.pageY, "rollover");
        ds.selection.rolloverElement = rolloverElement;

        if (rolloverElement && rolloverElement.rolloverOverlay) {
            setCursor(ds.selection.rolloverElement.rolloverOverlay.getCursor() || "pointer");  // set the cursor
        } else {
            setCursor("default");
        }

        // delegate the mousemove event to the current rolloverOverlay for any rollover widgets (like chart Components)
        if (ds.selection.rolloverElement && ds.selection.rolloverElement.rolloverOverlay) {
            ds.selection.rolloverElement.rolloverOverlay.trigger("mousemove_delegate", event);
        }
        if (ds.selection.element && ds.selection.element.overlay) {
            ds.selection.element.overlay.trigger("mousemove_delegate", event);
        }
    },

    onMouseDown: function(event) {
        if (app.isDraggingItem) return;
        if (app.isEditingImage) return;
        if (this.isDisabled) return;

        this.draggingImage = false; // maybe fix a bug that Diana is seeing where draggingImage=true during annotation dragging

        if (this.isLocked()) {
            return;
        }

        if ($(event.target).closest(".ui_widget").length || $(event.target).closest(".widget_bar").length || $(event.target).closest(".control").length) {
            return;
        }

        const clickedElement = this.currentCanvas.findFirstElementAtPoint(event.clientX, event.clientY, "selectable");

        // Allow shift clicks only on text elements
        if (event.shiftKey && !(clickedElement instanceof TextElement)) {
            return;
        }

        // when we mousedown on a textElement, we need to pass the mousedown event to the texteditor immediately
        if (clickedElement && !clickedElement.isAuthoringElement) {
            if (clickedElement instanceof TextElement && (!clickedElement.doubleClickToSelect || ds.selection.element == clickedElement)) {
                let initialClick = false;
                // if the textElement isn't currently selected, select it now and don't wait for the click
                if (ds.selection.element != clickedElement) {
                    ds.selection.element = clickedElement;
                    initialClick = true;
                }

                // if the textElement isn't currently selected, select it now and don't wait for the click
                let selectionView = _.find(this.selectionViews, { element: ds.selection.element });
                if (selectionView) {
                    selectionView.onMouseDown(event, initialClick);
                } else {
                    logger.warn("SelectionLayer wasn't able to find selectionView for TextElement");
                }
                // }
            } else {
                // delegate the click to a default overlay if necessary
                const control = this.findFirstControlAtPoint(event.pageX, event.pageY, "control");
                if (control) {
                    control[0].instance && control[0].instance.fire("click");
                    return;
                }

                // if the clickedElement is a BaseElement and it's not doubleClickToSelect, select it
                if (clickedElement instanceof BaseElement) {
                    if (!clickedElement.doubleClickToSelect) {
                        ds.selection.element = clickedElement;
                    }
                } else {
                    // otherwise pass the click event through
                    clickedElement.trigger("click", event);
                }

                let selectionView = _.find(this.selectionViews, { element: ds.selection.element });
                if (selectionView) {
                    selectionView.onMouseDown(event, true);
                }

                // pass click through to selected element rolloverElement
                if (ds.selection.rolloverElement && ds.selection.rolloverElement.rolloverOverlay) {
                    // if we aren't selecting an element, pass the click event through to any current rollover overlay
                    ds.selection.rolloverElement.rolloverOverlay.trigger("click_delegate", event);
                }

                return;
            }
        } else {
            // if we clicked on a widget with an element data attribute, select that element (like the drag_button)
            if ($(event.target).data().element) {
                ds.selection.element = $(event.target).data().element;
                return;
            }

            // deselect any element that was selected
            ds.selection.element = null;

            if (ds.selection.rolloverElement && ds.selection.rolloverElement.rolloverOverlay) {
                // if we aren't selecting an element, pass the click event through to any current rollover overlay
                ds.selection.rolloverElement.rolloverOverlay.trigger("click_delegate", event);
            }
            if (ds.selection.element && ds.selection.element.overlay) {
                // if we aren't selecting an element, pass the click event through to any current rollover overlay
                ds.selection.element.overlay.trigger("click_delegate", event);
            }

            if (ds.selection.rolloverElement && (ds.selection.rolloverElement.defaultOverlayNode)) {
                const control = this.findFirstControlAtPoint(event.pageX, event.pageY, "control");
                if (control) {
                    control.trigger("click");
                }
            }
        }
    },

    onClick: function(event) {
        if (app.isDraggingItem) return;
        if (this.isDisabled) return;

        if (this.isLocked()) {
            return;
        }

        const clickedElement = this.currentCanvas.findFirstElementAtPoint(event.pageX, event.pageY, "selectable");

        if (clickedElement) {
            this.clickedElement = clickedElement;
            if (ds.selection.element && ds.selection.element.overlay && ds.selection.element == clickedElement) {
                // if we aren't selecting an element, pass the click event through to any current rollover overlay
                clickedElement.overlay.trigger("click_delegate", event);
                return;
            }
        }

        // pass click through to selected element rolloverElement
        if (ds.selection.rolloverElement && ds.selection.rolloverElement.rolloverOverlay) {
            // if we aren't selecting an element, pass the click event through to any current rollover overlay
            ds.selection.rolloverElement.rolloverOverlay.trigger("click_delegate", event);
        }
    },

    onDoubleClick: function(event) {
        if (ds.selection.element instanceof TextElement) return;

        const clickedElement = this.currentCanvas.findFirstElementAtPoint(event.pageX, event.pageY, "doubleClickable");

        if (clickedElement) {
            ds.selection.element = clickedElement;

            if (clickedElement instanceof TextElement) {
                let selectionView = _.find(this.selectionViews, { element: ds.selection.element });
                if (selectionView) {
                    selectionView.onMouseDown(event, true, true);
                } else {
                    logger.warn("SelectionLayer wasn't able to find selectionView for TextElement");
                }
            }
        }
    },

    findFirstControlAtPoint: function(x, y, className) {
        if ((browser.isChrome && browser.getChromeVersion() < 70) || (browser.isSafari && browser.getSafariVersion() < 12)) {
            return this.oldFindControlAtPoint(x, y, className);
        } else {
            const elements = document.elementsFromPoint(x, y);
            for (let element of elements) {
                if ($(element).hasClass(className)) {
                    return $(element);
                }
            }
            return null;
        }
    },

    oldFindControlAtPoint: function(x, y, className) {
        let controls = [];
        let elements = [],
            previousPointerEvents = [],
            current,
            i,
            d;

        // get all elements via elementFromPoint, and remove them from hit-testing in order
        while ((current = document.elementFromPoint(x, y)) && elements.indexOf(current) === -1 && current != null) {
            if ($(current).hasClass(className)) {
                controls.push($(current));
            }

            // push the element and its current style
            elements.push(current);

            previousPointerEvents.push({
                value: current.style.getPropertyValue("pointer-events"),
                priority: current.style.getPropertyPriority("pointer-events")
            });

            // add "pointer-events: none", to get to the underlying element
            current.style.setProperty("pointer-events", "none", "important");
        }

        // restore the previous pointer-events values
        for (i = previousPointerEvents.length - 1; i >= 0; i--) {
            d = previousPointerEvents[i];
            elements[i].style.setProperty("pointer-events", d.value ? d.value : "", d.priority);
        }

        // return our results
        if (controls.length) {
            return $(controls[0]);
        } else {
            return null;
        }
    },

    //---------------------------------------------------------------------------------------------------------------------
    // Selection
    //---------------------------------------------------------------------------------------------------------------------

    showElementSelection: function() {
        // bail out if the currentCanvas is not rendererd.
        if (!this.currentCanvas.isRendered) {
            return;
        }

        let currentSelectionView;
        // remove any previous selectionViews (unless it's for the currently selected element)
        for (const selectionView of this.selectionViews) {
            if (!ds.selection.element || ds.selection.element.overlay != selectionView) {
                selectionView.cleanUp();
                selectionView.remove();
            } else {
                currentSelectionView = selectionView;
            }
        }
        this.selectionViews = [];

        if (this.isLocked()) {
            return;
        }

        if (ds.selection.element && (ds.selection.element.overlay !== currentSelectionView || !this.currentCanvas.isLockedForCollaborators())) { // Selected new element, lock for 30 seconds
            this.currentCanvas.lockSlideForCollaborators(30);
        } else if (!ds.selection.element && this.currentCanvas.isLockedForCollaborators()) { // Unselected while locked
            this.currentCanvas.unlockSlideForCollaborators();
        }

        if (currentSelectionView) {
            this.selectionViews.push(currentSelectionView);
        }

        // make sure the primary element's selection overlay is always rendered
        let showPrimaryElementSelection = false;

        if (!ds.selection.element) {
            showPrimaryElementSelection = true;
        } else {
            if (ds.selection.element !== this.currentCanvas.layouter.primaryElement &&
                ds.selection.element.isChildOf(this.currentCanvas.layouter.primaryElement)) {
                showPrimaryElementSelection = true;
            }
        }

        if (showPrimaryElementSelection && this.currentCanvas.layouter.primaryElement) {
            this.renderSelectionOverlay(this.currentCanvas.layouter.primaryElement.getSelectionElement(), { isPrimaryElement: true });
        }

        if (ds.selection.element) {
            for (let element of ds.selection.element.getElementPath()) {
                // render the selection overlay for every selectable element in the currently selected elements path (except for the primary element which is always rendered above)
                if (element instanceof BaseElement && (element.canSelect || element.doubleClickToSelect) && element.showSelectionUI) {
                    // if the currently selected element already has a visible selectionView, don't recreate it and early out
                    // 1. Prevents recreating the texteditor on each keypress which would break editing
                    // 2. Don't recreate a draggable selection when clicking from editing text to dragging an item and losing the mouse event chain because a new selectionview was created in between losing focus on the text element and starting the dragt
                    if (element.overlay && this.selectionViews.contains(element.overlay)) {
                        element.overlay.layout();
                    } else {
                        this.renderSelectionOverlay(element.getSelectionElement());
                    }
                }
                // check if the element prevents passing through selection to it's parent element
                if (element.passThroughSelection == false) break;
            }
        } else {
            // if there is a currently hidden rolloverElement because we were just selected, show it
            if (ds.selection.rolloverElement) {
                this.showElementRollover();
            }
        }

        let documentFragment = $(document.createDocumentFragment());
        this.selectionViews.forEach(selectionView => {
            if (selectionView.needsRenderAndLayout) {
                documentFragment.append(selectionView.render().$el);
            }
        });
        this.$el.append(documentFragment);

        const canvasBottom = $(".current_slide").offset().top + $(".current_slide").height();
        let hasSelectionBelowCanvas = false;

        this.selectionViews.forEach(selectionView => {
            if (selectionView.needsRenderAndLayout) {
                selectionView.layout();
                selectionView.needsRenderAndLayout = false;
            }
            // check if this selectionView is below the canvas and might intersect with the primary control bar
            if (selectionView.getOffset() != "canvas" && selectionView.$controlBar.offset().top + selectionView.$controlBar.height() > canvasBottom) {
                hasSelectionBelowCanvas = true;
            }
        });

        // hide the primary control bar if this control bar overlaps it
        if (hasSelectionBelowCanvas) {
            $(".change_template").hide();
            $(".primary-controlbar").hide();
        } else {
            $(".change_template").show();
        }
    },

    renderSelectionOverlay: function(element, options = {}) {
        // find the selection overlay class for the element
        let selectionUIType = element.selectionUIType;
        if (!selectionUIType || !this.currentCanvas.editorManager.has(selectionUIType)) {
            for (let className of element.getInheritancePath()) {
                let name = className + "Selection";
                if (this.currentCanvas.editorManager.has(name)) {
                    selectionUIType = name;
                }
            }
        }

        if (!this.currentCanvas.editorManager.has(selectionUIType)) {
            return;
        }

        // create the selectionView view (if the element has one)
        let editor = this.currentCanvas.editorManager.get(selectionUIType);
        let selectionView = new editor(Object.assign({ element }, options));
        if (selectionView) {
            // store a reference to the selectionView view so we can remove later
            this.selectionViews.push(selectionView);
            // give the selected element a reference to the selection overlay
            element.overlay = selectionView;
            selectionView.selectionLayer = this;
            if (options.needsImmediateRenderAndLayout) {
                this.$el.append(selectionView.render().$el);
                selectionView.layout();
            } else {
                selectionView.needsRenderAndLayout = true;
            }
        }
    },

    //---------------------------------------------------------------------------------------------------------------------
    // Rollover
    //---------------------------------------------------------------------------------------------------------------------
    showElementRollover: function() {
        if (app.DEBUG_PREVENT_ROLLOVER) return;

        if (this.draggingImage) return;

        // remove any existing rollover views
        for (let rolloverOverlay of this.rolloverViews) {
            rolloverOverlay.remove();
        }
        this.rolloverViews = [];

        if (ds.selection.rolloverElement) {
            // render rollover overlay views for the rollover element and any parents
            for (let element of ds.selection.rolloverElement.getElementPath().reverse()) {
                if (element.canRollover) {
                    this.renderRolloverOverlay(element);
                }
            }
        } else {
            ds.selection.rolloverElement = null;
            setCursor("default");  // reset the cursor
        }
    },

    renderRolloverOverlay: function(element) {
        if (element.showRollover == false) return;

        // find the rollover overlay class for the element
        let rolloverUIType = element.rolloverUIType;
        if (!rolloverUIType || !this.currentCanvas.editorManager.has(rolloverUIType)) {
            for (let className of element.getInheritancePath()) {
                let name = className + "Rollover";
                if (this.currentCanvas.editorManager.has(name)) {
                    rolloverUIType = name;
                }
            }
        }

        // if we didn't find a rollover overlay for this element, exit out
        if (!this.currentCanvas.editorManager.has(rolloverUIType)) {
            return;
        }

        // instantiate the rollover overlay view
        let editor = this.currentCanvas.editorManager.get(rolloverUIType);
        let rolloverOverlay = new editor({ element });
        if (rolloverOverlay) {
            // store a reference to the rolloverOverlay view so we can remove later
            this.rolloverViews.push(rolloverOverlay);

            // give the element a reference to the view
            element.rolloverOverlay = rolloverOverlay;
            rolloverOverlay.selectionLayer = this;

            // render the rolloverOverlay view
            this.$el.append(rolloverOverlay.render().$el);

            // let the view lay itself out using the element bounds
            rolloverOverlay.layout();

            return rolloverOverlay;
        }
    },

    //---------------------------------------------------------------------------------------------------------------------
    // Default Overlays
    //---------------------------------------------------------------------------------------------------------------------
    showDefaultOverlay: function() {
        if (this.draggingImage) return;

        // remove any existing rollover views
        for (let defaultOverlay of this.defaultOverlays) {
            defaultOverlay.remove();
        }
        this.defaultOverlays = [];

        if (this.currentCanvas.layouter) {
            for (let element of Object.values(this.currentCanvas.layouter.elements)) {
                this.renderDefaultOverlay(element);
            }
        }
    },

    renderDefaultOverlay: function(element) {
        if (element.calculatedProps && element.showDefaultOverlay) {
            let defaultOverlayUIType = element.defaultOverlayUIType;
            if (!defaultOverlayUIType || !this.currentCanvas.editorManager.has(defaultOverlayUIType)) {
                for (let className of element.getInheritancePath()) {
                    let name = className + "DefaultOverlay";
                    if (this.currentCanvas.editorManager.has(name)) {
                        defaultOverlayUIType = name;
                    }
                }
            }

            if (!this.currentCanvas.editorManager.has(defaultOverlayUIType)) {
                return;
            }

            let editor = this.currentCanvas.editorManager.get(defaultOverlayUIType);
            if (editor) {
                let defaultOverlay = new editor({ element });
                if (defaultOverlay) {
                    this.defaultOverlays.push(defaultOverlay);
                    element.defaultOverlay = defaultOverlay;
                    this.$el.append(defaultOverlay.render().$el);
                    defaultOverlay.layout();
                }
            }
        }

        for (let childElement of Object.values(element.elements)) {
            this.renderDefaultOverlay(childElement);
        }
    },

    //---------------------------------------------------------------------------------------------------------------------
    // Drag Over
    //---------------------------------------------------------------------------------------------------------------------
    showElementDropTargets: function() {
        // remove any existing dragOverlay views
        for (let dragOverlay of this.dragOverlays) {
            dragOverlay.$el.off();
            dragOverlay.remove();
        }
        this.dragOverlays = [];

        // remove any existing rolloverOverlay views
        for (let rolloverOverlay of this.rolloverViews) {
            rolloverOverlay.remove();
        }
        this.rolloverViews = [];

        // recurse over all elements and find those with drag flag and create the drop overlay
        let self = this;

        function checkForDropTargets(children) {
            _.each(children, el => {
                if (el.canDropImage && el.canEdit) {
                    self.renderDropTarget(el);
                }
                checkForDropTargets(el.elements);
            });
        }

        _.each(app.currentCanvas.layouter.elements, el => {
            checkForDropTargets(el.elements);
        });
    },

    renderDropTarget: function(element) {
        // get the elements rolloverOverlay view
        let dragOverlay = new this.currentCanvas.ElementDropTarget({ element });
        if (dragOverlay) {
            // store a reference to the rolloverOverlay view so we can remove later
            this.dragOverlays.push(dragOverlay);
            // give the element a reference to the view
            element.dragOverlay = dragOverlay;
            // render the rolloverOverlay view
            this.$el.append(dragOverlay.render().$el);

            dragOverlay.layout();
            this.listenTo(dragOverlay, "file:dragleave file:drop", () => {
                this.onDrop();
            });

            return dragOverlay;
        }
    },

    renderAnchorPoints: function(element) {
        this.$el.find(".anchor-widget").remove();

        if (!element) return;

        if (element.options.allowConnection == false) return;

        let parentCanvasBounds = element.parentElement.canvasBounds;
        let targetBounds = element.anchorBounds.offset(parentCanvasBounds.left, parentCanvasBounds.top).multiply(app.canvasScale);

        const OFFSET = 6;

        if (element.availableAnchorPoints.contains(AnchorType.LEFT)) {
            let $anchorLeft = this.$el.addEl($.div("anchor-widget control")).data("anchor", AnchorType.LEFT);
            $anchorLeft.left(targetBounds.left - OFFSET).top(targetBounds.centerV - OFFSET);
        }

        if (element.availableAnchorPoints.contains(AnchorType.RIGHT)) {
            let $anchorRight = this.$el.addEl($.div("anchor-widget control")).data("anchor", AnchorType.RIGHT);
            $anchorRight.left(targetBounds.right - OFFSET).top(targetBounds.centerV - OFFSET);
        }

        if (element.availableAnchorPoints.contains(AnchorType.TOP)) {
            let $anchorTop = this.$el.addEl($.div("anchor-widget control")).data("anchor", AnchorType.TOP);
            $anchorTop.left(targetBounds.centerH - OFFSET).top(targetBounds.top - OFFSET);
        }

        if (element.availableAnchorPoints.contains(AnchorType.BOTTOM)) {
            let $anchorBottom = this.$el.addEl($.div("anchor-widget control")).data("anchor", AnchorType.BOTTOM);
            $anchorBottom.left(targetBounds.centerH - OFFSET).top(targetBounds.bottom - OFFSET);
        }

        if (element.availableAnchorPoints.contains(AnchorType.FREE)) {
            let $anchorCenter = this.$el.addEl($.div("anchor-widget control")).data("anchor", AnchorType.FREE);
            $anchorCenter.left(targetBounds.centerH - OFFSET).top(targetBounds.centerV - OFFSET);
        }
    },

    //---------------------------------------------------------------------------------------------------------------------
    // Show/Hide widgets
    //---------------------------------------------------------------------------------------------------------------------

    showWidgets: function() {
        this.$el.find(".ui_widget, .widget_bar, .selection-box, .control, .element-default-overlay").css("visibility", "visible");
        $(".spellcheck-group").show();
        this.showElementRollover();
    },

    hideWidgets: function($exception) {
        this.$el.find(".ui_widget, .widget_bar, .selection-box, .control, .element-default-overlay").css("visibility", "hidden");
        $(".spellcheck-group").hide();
        $exception && $exception.css("visibility", "visible");
    },

    show: function() {
        for (const selectionView of this.selectionViews) {
            selectionView.show();
        }
    },

    hide: function() {
        for (const selectionView of this.selectionViews) {
            selectionView.hide();
        }
    },

    remove: function() {
        $(document).off(".selection-layer");
        this.stopListening();
        this.unbind();

        for (const selectionView of this.selectionViews) {
            selectionView.cleanUp();
            selectionView.remove();
        }
        this.selectionViews = [];

        for (const rolloverOverlay of this.rolloverViews) {
            rolloverOverlay.remove();
        }
        this.rolloverViews = [];

        for (const defaultOverlay of this.defaultOverlays) {
            defaultOverlay.remove();
        }
        this.defaultOverlays = [];

        for (const dragOverlay of this.dragOverlays) {
            dragOverlay.$el?.off();
            dragOverlay.remove();
        }
        this.dragOverlays = [];
    }
});
