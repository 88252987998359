import { $, _ } from "legacy-js/vendor";

let splashScreenActivity;

export function RemoveSplashScreen() {
    clearTimeout(splashScreenActivity);

    // hide the view
    const splash = $("#splash");
    splash.addClass("hide-splash");
}

export function RestoreSplashScreen() {
    splashScreenActivity = setTimeout(() => {
        const splash = $("#splash");
        splash.removeClass("hide-splash");
    }, 100);
}

