import { tinycolor } from "legacy-js/vendor";

export default class PolyLineExporter {
    constructor(exporter, element) {
        this.exporter = exporter;
        this.element = element;
    }

    // creates an arrow shape for a line end cap
    drawArrow = (point, relativeTo, color = "000000") => {
        const { exporter, elementBounds, path } = this;
        const { pptx, slide } = exporter;

        const relativeX = elementBounds.x + exporter.convertCoordinate(path[relativeTo][0]);
        const relativeY = elementBounds.y + exporter.convertCoordinate(path[relativeTo][1]);
        const radians = Math.atan2(point.y - relativeY, point.x - relativeX);
        let angle = parseInt(radians * (180 / Math.PI));

        // the PPT shape needs an extra 90 degrees
        angle += 90;

        // make sure to stay within range
        angle %= 360;
        if (angle < 0) {
            angle += 360;
        }

        // draw
        const size = 0.15;
        const x = point.x - (size * 0.5);
        const y = point.y - (size * 0.5);
        slide.addShape(pptx.shapes.ISOSCELES_TRIANGLE, {
            x, y,
            w: size,
            h: size,
            fill: { type: "solid", color },
            rotate: angle
        });
    }

    // creates a dot for a line end cap
    drawPoint = (point, color = "000000") => {
        const { slide, pptx } = this.exporter;

        const size = 0.1;
        const x = point.x - (size * 0.5);
        const y = point.y - (size * 0.5);
        slide.addShape(pptx.shapes.OVAL, {
            x, y,
            w: size,
            h: size,
            fill: { type: "solid", color }
        });
    }

    generate() {
        const { element, exporter } = this;
        const { slide, pptx } = exporter;

        if (!element.calculatedProps.path) return;

        // get the bounds for exporting
        this.elementBounds = exporter.convertRectToSlide(element.calculatedProps.bounds ? element.canvasBounds : element.parentElement.canvasBounds);
        this.path = element.calculatedProps.path;

        // strokes can have a separate strokeOpacity setting so we need to create a single line color from the resolvedStrokeColor and any strokeOpacity
        let lineColor = element.styles.resolved_stroke?.clone();
        if (element.styles.strokeOpacity && element.styles.strokeOpacity < 1) {
            let c = tinycolor(lineColor);
            c.setAlpha(element.styles.strokeOpacity);
            lineColor = c;
        }

        let lineOptions = {
            line: exporter.getColorFromRGB(lineColor?.toRgbString() || "black"),
            lineSize: element.styles.strokeWidth
        };

        let startPoint = {
            x: this.elementBounds.x + exporter.convertCoordinate(this.path[0][0]),
            y: this.elementBounds.y + exporter.convertCoordinate(this.path[0][1])
        };

        // start drawing the paths
        let previousPoint = startPoint;
        for (let i = 1; i < this.path.length; i++) {
            let endPoint = {
                x: this.elementBounds.x + exporter.convertCoordinate(this.path[i][0]),
                y: this.elementBounds.y + exporter.convertCoordinate(this.path[i][1])
            };

            let distance = Math.sqrt(Math.pow(endPoint.x - previousPoint.x, 2) + Math.pow(endPoint.y - previousPoint.y, 2));
            let angleInRadians = Math.atan2(endPoint.y - previousPoint.y, endPoint.x - previousPoint.x);
            let angle = parseInt(angleInRadians * (180 / Math.PI));
            if (angle < 0) angle += 360;

            // angle = 45;
            let shapeLoc = { ...previousPoint };
            if (angle > 0) {
                shapeLoc.x -= distance / 2;
                // adjust start point because api rotates around center point of line
                let offsetX = Math.cos(angleInRadians) * (distance / 2);
                let offsetY = Math.sin(angleInRadians) * (distance / 2);
                shapeLoc.x += Math.cos(angleInRadians) * (distance / 2);
                shapeLoc.y += Math.sin(angleInRadians) * (distance / 2);
            }

            slide.addShape(pptx.shapes.LINE, {
                ...shapeLoc,
                ...lineOptions,
                w: distance,
                h: 0,
                rotate: angle
            });

            previousPoint = endPoint;
        }

        // possible endpoints to draw
        const endPoints = [
            [element.model.startDecoration, startPoint, 1],
            [element.model.endDecoration, previousPoint, this.path.length - 2]
        ];

        // check and draw each one
        for (const [type, point, relativeTo] of endPoints) {
            if (type === "circle") {
                this.drawPoint(point, lineOptions.line);
            } else if (type === "arrow") {
                this.drawArrow(point, relativeTo, lineOptions.line);
            }
        }
    }
}
