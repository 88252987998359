import React, { Component } from "react";

export default class Icon extends Component {
    constructor() {
        super();

        this.state = {
            hover: false,
            isVisible: false
        };

        this.toggleHover = this.toggleHover.bind(this);
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true) {
                if (!this.state.isVisible) {
                    this.setState({ isVisible: true });
                }
            }
        });

        this.observer.observe(this.ref.current);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    toggleHover() {
        this.setState({ hover: !this.state.hover });
    }

    render() {
        const { icon, style, baseUrl, selectAsset } = this.props;
        const { isVisible } = this.state;
        return (
            <div
                className="icon-asset"
                onMouseEnter={this.toggleHover}
                onMouseLeave={this.toggleHover}
                // style={this.state.hover ? {...styles.iconParent, ...styles.hover} : styles.iconParent}
                onClick={() => selectAsset(icon)}
                title={icon.key}
                ref={this.ref}
            >
                {isVisible && <img
                    // style={styles.icon}
                    src={`${baseUrl +
                        "/" +
                        style.toLowerCase()}/${style.toLowerCase()}-${
                        icon.key
                    }.svg`}
                />}
            </div>
        );
    }
}
