import elementManager from "./elementManager";

//
// Only primary elements need to be registered here
//

const Decoration = require("./base/DecorationElement");
elementManager.register(Decoration.elements);

const Header = require("./elements/Header");
elementManager.register(Header.elements);

const Authoring = require("./elements/AuthoringCanvas");
elementManager.register(Authoring.elements);

const Countdown = require("./elements/Countdown");
elementManager.register(Countdown.elements);

const Timer = require("./elements/Timer");
elementManager.register(Timer.elements);

const Agenda = require("./elements/Agenda");
elementManager.register(Agenda.elements);

const BigNumbers = require("./elements/BigNumbers");
elementManager.register(BigNumbers.elements);

const WebView = require("./elements/WebView");
elementManager.register(WebView.elements);

const Calendar = require("./elements/Calendar");
elementManager.register(Calendar.elements);

const VerticalTaskList = require("./elements/VerticalTaskList");
elementManager.register(VerticalTaskList.elements);

const HorizontalTaskList = require("./elements/HorizontalTaskList");
elementManager.register(HorizontalTaskList.elements);

const ArrowBars = require("./elements/ArrowBars");
elementManager.register(ArrowBars.elements);

const RadialBarChart = require("./elements/RadialBarChart");
elementManager.register(RadialBarChart.elements);

const Target = require("./elements/Target");
elementManager.register(Target.elements);

const SimpleGanttChart = require("./elements/GanttChart");
elementManager.register(SimpleGanttChart.elements);

const Compare = require("./elements/compare");
elementManager.register(Compare.elements);

const ContactInfo = require("./elements/ContactInfo");
elementManager.register(ContactInfo.elements);

const Dashboard = require("./elements/Dashboard");
elementManager.register(Dashboard.elements);

const DebugBox = require("./elements/DebugBox");
elementManager.register(DebugBox.elements);

const Embed = require("./elements/Embed");
elementManager.register(Embed.elements);

const Headline = require("./elements/Headline");
elementManager.register(Headline.elements);

const HubAndSpoke = require("./elements/Diagrams/HubAndSpoke");
elementManager.register(HubAndSpoke.elements);

const IconCircle = require("./elements/Diagrams/IconCircle");
elementManager.register(IconCircle.elements);

const Journey = require("./elements/Journey");
elementManager.register(Journey.elements);

const NodeDiagram = require("./elements/NodeDiagram");
elementManager.register(NodeDiagram.elements);

const LogoGrid = require("./elements/LogoGrid");
elementManager.register(LogoGrid.elements);

const OrgChart = require("./elements/OrgChart");
elementManager.register(OrgChart.elements);

const PhotoCollage = require("./elements/PhotoCollage");
elementManager.register(PhotoCollage.elements);

const PhotoTextList = require("./elements/Lists/PhotoTextList");
elementManager.register(PhotoTextList.elements);

const PictorialCharts = require("./elements/PictorialChart");
elementManager.register(PictorialCharts.elements);

const PresentationTitle = require("./elements/PresentationTitle");
elementManager.register(PresentationTitle.elements);

const ProcessDiagram = require("./elements/ProcessDiagram");
elementManager.register(ProcessDiagram.elements);

const ProductScreenshot = require("./elements/ProductScreenshot");
elementManager.register(ProductScreenshot.elements);

const Pyramid = require("./elements/Pyramid");
elementManager.register(Pyramid.elements);

const SliceChart = require("./elements/SliceChart");
elementManager.register(SliceChart.elements);

const SlidePlaceholder = require("./elements/SlidePlaceholder");
elementManager.register(SlidePlaceholder.elements);

const StackDiagram = require("./elements/StackDiagram");
elementManager.register(StackDiagram.elements);

const SwotDiagram = require("./elements/SwotDiagram");
elementManager.register(SwotDiagram.elements);

const Table = require("./elements/Table/Table");
elementManager.register(Table.elements);

const TableV1 = require("./elements/Table/previous/Table.v1");
elementManager.register(TableV1.elements);

const Team = require("./elements/Team");
elementManager.register(Team.elements);

const TextBoxGrid = require("./elements/TextBoxGrid");
elementManager.register(TextBoxGrid.elements);

const TextGrid = require("./elements/Lists/previous/TextGrid");
elementManager.register(TextGrid.elements);

const TextList = require("./elements/Lists/TextList");
elementManager.register(TextList.elements);

const IconsList = require("./elements/Lists/IconsList");
elementManager.register(IconsList.elements);

const Carousel = require("./elements/Lists/Carousel");
elementManager.register(Carousel.elements);

const TextSlide = require("./elements/TextSlide");
elementManager.register(TextSlide.elements);

const Timeline = require("./elements/Timeline");
elementManager.register(Timeline.elements);

const UserTestimonial = require("./elements/UserTestimonial");
elementManager.register(UserTestimonial.elements);

const VennDiagram = require("./elements/VennDiagram");
elementManager.register(VennDiagram.elements);

const Video = require("./elements/Video");
elementManager.register(Video.elements);

const WordCloudElement = require("./elements/WordCloud");
elementManager.register(WordCloudElement.elements);

const XYGraph = require("./elements/Diagrams/XYGraph");
elementManager.register(XYGraph.elements);

const Thermometer = require("./elements/Thermometer");
elementManager.register(Thermometer.elements);

const PPTSlide = require("./elements/PPTSlide");
elementManager.register(PPTSlide.elements);
