import * as geom from "js/core/utilities/geom";

export function calcGridLayout(options = {}) {
    if (!this.items || this.items.length == 0) return this;

    let grid = GridLayouts[this.items.length - 1];
    let layout = grid[Math.min(grid.length - 1, options.gridLayout || 0)];

    let hGap = options.gap;
    let vGap = options.gap;

    // if (options.showGutter) {
    //     hGap = vGap = options.gap;
    // }
    let fullBleed = options.fullBleed;
    let showEdgeGutter = options.showGutter && fullBleed;

    // overlap a couple of pixels when there is no gutter due to anti-aliasing artifact
    let hOverlap = options.showGutter ? 0 : (layout.cols === 3 ? 2 : 1);
    let vOverlap = options.showGutter ? 0 : (layout.rows === 3 ? 2 : 1);

    let colWidth = (this.containerSize.width - (layout.cols - 1) * hGap - (showEdgeGutter ? hGap * 2 : 0)) / layout.cols;
    let rowHeight = (this.containerSize.height - (layout.rows - 1) * vGap - (showEdgeGutter ? vGap * 2 : 0)) / layout.rows;

    for (let i = 0; i < this.items.length; i++) {
        let cell = layout.cells[i];

        if (cell) {
            let left = 0 + (showEdgeGutter ? hGap : 0) + cell[0] * (colWidth + hGap),
                top = 0 + (showEdgeGutter ? vGap : 0) + cell[1] * (rowHeight + vGap),
                width = cell[2] * (colWidth + hGap + hOverlap) - hGap,
                height = cell[3] * (rowHeight + vGap + vOverlap) - vGap;

            let cellProps = this.items[i].calcProps(new geom.Size(width, height));
            cellProps.bounds = new geom.Rect(left, top, width, height);
        }
    }

    this.isFit = true;
    this.size = this.containerSize;

    return this;
}

const GridLayouts = [[
// one cell
    {
        rows: 1,
        cols: 1,
        cells: [[0, 0, 1, 1]],
        autoSplit: true
        // }, {
        //     cols: 2,
        //     rows: 1,
        //     cells: [[0, 0, 1, 1], [1, 0, 1, 1]],
        //     autoSplit: true
        // }, {
        //     cols: 1,
        //     rows: 2,
        //     cells: [[0, 0, 1, 1], [0, 1, 1, 1]],
        //     autoSplit: true
        // }, {
        //     cols: 3,
        //     rows: 1,
        //     cells: [[0, 0, 2, 1], [2, 0, 1, 1]],
        //     autoSplit: true
        // }, {
        //     cols: 3,
        //     rows: 1,
        //     cells: [[0, 0, 1, 1], [1, 0, 2, 1]],
        //     autoSplit: true

    }
], [
//two cells
//     {
//         rows: 1,
//         cols: 1,
//         cells: [[0, 0, 1, 1]],
//         autoSplit: true,
//     },
    {
        cols: 2,
        rows: 1,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1]],
        autoSplit: true
    }, {
        cols: 1,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1]],
        autoSplit: true
    }, {
        cols: 3,
        rows: 1,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1]],
        autoSplit: true
    }, {
        cols: 3,
        rows: 1,
        cells: [[0, 0, 1, 1], [1, 0, 2, 1]],
        autoSplit: true
    }
], [
//three cells
    {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 1, 2], [1, 0, 1, 1], [1, 1, 1, 1]]
    }, {
        cols: 3,
        rows: 1,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1]]
    }, {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [1, 0, 1, 2]]
    }, {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 2, 1], [0, 1, 1, 1], [1, 1, 1, 1]]
    }, {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [0, 1, 2, 1]]
    }, {
        cols: 1,
        rows: 3,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [0, 2, 1, 1]]
    }
], [
//four cells
    {
        cols: 2,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1]]
    }, {
        cols: 2,
        rows: 3,
        cells: [[0, 0, 1, 3], [1, 0, 1, 1], [1, 1, 1, 1], [1, 2, 1, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 2, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 2, 1], [0, 1, 2, 1], [2, 1, 1, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1], [0, 1, 2, 1], [2, 1, 1, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 2, 1], [0, 1, 1, 1], [1, 1, 2, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 3, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1]]
    }, {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 3, 1]]
    }, {
        cols: 4,
        rows: 1,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1]]
    }, {
        cols: 1,
        rows: 4,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [0, 2, 1, 1], [0, 3, 1, 1]]
    }
], [
//five cells
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1]]
    },
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 2, 1]]
    },
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 2], [1, 0, 1, 1], [2, 0, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1]]
    },
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [1, 0, 1, 2], [2, 0, 1, 1], [2, 1, 1, 1]]
    },
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [1, 0, 1, 1], [1, 1, 1, 1], [2, 0, 1, 2]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 4, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 4, 1]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 2, 2], [2, 0, 1, 1], [3, 0, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 0, 2, 2]]
    },
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [0, 1, 1, 1], [1, 0, 2, 2], [3, 0, 1, 1], [3, 1, 1, 1]]
    },
    {
        cols: 6,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 2, 1], [4, 0, 2, 1], [0, 1, 3, 1], [3, 1, 3, 1]]
    },
    {
        cols: 6,
        rows: 2,
        cells: [[0, 0, 3, 1], [3, 0, 3, 1], [0, 1, 2, 1], [2, 1, 2, 1], [4, 1, 2, 1]]
    }
], [
//six cells
    {
        cols: 3,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1]]
    }, {
        cols: 5,
        rows: 3,
        cells: [[0, 0, 5, 2], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1], [4, 2, 1, 1]]
    }, {
        cols: 5,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [4, 0, 1, 1], [0, 1, 5, 2]]
    }, {
        cols: 5,
        rows: 3,
        cells: [[0, 0, 3, 2], [3, 0, 2, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 1, 2, 2]]
    }
], [
//seven cells
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 2], [1, 0, 1, 1], [2, 0, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 0, 1, 1], [3, 1, 1, 1]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 0, 1, 2]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 2, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 2, 1]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 2, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    }, {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 2, 1], [3, 1, 1, 1]]
    }
], [
// eight cells
    {
        cols: 4,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1]]
    }
], [
// nine cells
    {
        cols: 3,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 2, 2], [2, 0, 1, 1], [3, 0, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 2, 2], [0, 1, 1, 1], [1, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 2, 2], [3, 0, 1, 1], [0, 1, 1, 1], [3, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }
], [
// ten cells
    {
        cols: 5,
        rows: 2,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [4, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [4, 1, 1, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 2, 1], [2, 2, 2, 1]]
    }, {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 2, 1], [2, 0, 2, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }
], [
// eleven cells
    {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 2, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }
], [
// twelve cells
    {
        cols: 4,
        rows: 3,
        cells: [[0, 0, 1, 1], [1, 0, 1, 1], [2, 0, 1, 1], [3, 0, 1, 1], [0, 1, 1, 1], [1, 1, 1, 1], [2, 1, 1, 1], [3, 1, 1, 1], [0, 2, 1, 1], [1, 2, 1, 1], [2, 2, 1, 1], [3, 2, 1, 1]]
    }
]];
export { GridLayouts };
