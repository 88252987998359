import React from "reactn";
import {
    DialogTitle,
    DialogActions,
    Dialog,
    Button
} from "@material-ui/core";
import { DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";

export default function ConfirmationDialog(props) {
    const {
        title,
        open,
        okButtonLabel = "Ok",
        cancelButtonLabel = "Cancel",
        onAccept,
        onCancel
    } = props;

    return (
        <Dialog open={open || false} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{props.children}</DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>{cancelButtonLabel}</Button>
                <Button onClick={onAccept} color="primary">
                    {okButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
