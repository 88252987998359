function cartesianProduct(arrays) {
    if (arrays.length == 0) return [[]];
    let subProduct = cartesianProduct(arrays.slice(1));
    return arrays[0].map(
        v => subProduct.map(arr => [v].concat(arr))
    ).reduce((l, r) => l.concat(r), []);
}

let treeMemo = new Map();
function partitionTrees(n) {
    if (n === 1) {
        return [1];
    }
    if (!treeMemo.has(n)) {
        if (n >= 8) {
            let l = Math.floor(n / 2);
            let r = n - l;
            treeMemo.set(n, cartesianProduct([partitionTrees(l), partitionTrees(r)]));
        } else {
            treeMemo.set(n, partitions(n).slice(1).map(
                partition => cartesianProduct(
                    partition.map(
                        k => partitionTrees(k))))
                .reduce((l, r) => l.concat(r), []));
        }
    }
    return treeMemo.get(n);
}

let partMemo = new Map();
function partitions(n) {
    if (n == 1) {
        return [[1]];
    }
    if (!partMemo.has(n)) {
        let res = [[n]];
        for (let ii = 1; ii < n; ii++) {
            for (let part of partitions(n - ii)) {
                res.push([ii].concat(part));
            }
        }
        partMemo.set(n, res);
    }
    return partMemo.get(n);
}

function beside(subs) {
    let zs = subs.map(x => 1 / x.h);
    let sum = zs.reduce((l, r) => l + r, 0);
    zs = zs.map(z => z / sum);

    let l = 0;
    let xs = [];
    for (let z of zs) {
        xs.push(l);
        l += z;
    }
    return {
        h: 1 / sum,
        zs: subs.map((sub, ii) => sub.zs.map(z => z * zs[ii])).reduce((l, r) => l.concat(r), []),
        xs: subs.map((sub, ii) => sub.xs.map(x => x * zs[ii] + xs[ii])).reduce((l, r) => l.concat(r), []),
        ys: subs.map((sub, ii) => sub.ys.map(y => y * zs[ii])).reduce((l, r) => l.concat(r), [])
    };
}

function above(subs) {
    let ys = [];
    let h = 0;
    for (let sub of subs) {
        ys.push(h);
        h += sub.h;
    }
    return {
        h: subs.reduce((l, r) => l + r.h, 0),
        zs: subs.map(sub => sub.zs).reduce((l, r) => l.concat(r), []),
        xs: subs.map(sub => sub.xs).reduce((l, r) => l.concat(r), []),
        ys: subs.map((sub, ii) => sub.ys.map(y => y + ys[ii])).reduce((l, r) => l.concat(r), [])
    };
}

function scorePartitionTree(hs, H, tree) {
    let n = 0;
    function go(f1, f2, t) {
        if (Array.isArray(t)) {
            return f1(t.map(ch => go(f2, f1, ch)));
        } else {
            return {
                h: hs[n++],
                zs: [1],
                xs: [0],
                ys: [0]
            };
        }
    }
    let horizontal = fitToWindow(H, go(beside, above, tree));
    n = 0;
    let vertical = fitToWindow(H, go(above, beside, tree));
    return [score(hs, horizontal), score(hs, vertical)];
}

function fitToWindow(H, layout) {
    let dx = 0, dy = 0, scale = 1;
    if (H < layout.h) {
        scale = H / layout.h;
        dx = 0.5 * (1 - scale);
    } else {
        dy = 0.5 * (H - layout.h);
    }
    return {
        h: H,
        zs: layout.zs.map(z => z * scale),
        xs: layout.xs.map(x => x * scale + dx),
        ys: layout.ys.map(y => y * scale + dy),
        letterboxing: dx + dy
    };
}

function score(hs, layout) {
    let areas = layout.zs.map((z, ii) => z * Math.sqrt(hs[ii]));
    let avgArea = areas.reduce((l, r) => l + r, 0) / areas.length;
    let avgDiff = areas.reduce((l, r) => l + Math.pow(r - avgArea, 2), 0) / areas.length;
    return Object.assign(layout, { score: 1 * Math.pow(layout.letterboxing, 2) + avgDiff });
}

// input:
//  - W: overall width of viewport in pixels, e.g. 400
//  - H: overall height of viewport in pixels, e.g. 600
//  - hs: aspect ratios of pictures (height/width), e.g. [1, 1.5, 1.33, 0.4]
// output:
//  - list of layouts in order from best to worst
//  - each layout has a list {x,y,w,h} for each image
function tileImages(W, H, hs) {
    let layouts = partitionTrees(hs.length)
        .map(tree => scorePartitionTree(hs, H / W, tree))
        .reduce((l, r) => l.concat(r), []);
    layouts.sort((l, r) => l.score - r.score);
    return layouts.map(layout => layout.zs.map(
        (z, ii) => ({
            x: layout.xs[ii] * W,
            y: layout.ys[ii] * W,
            w: z * W,
            h: z * hs[ii] * W
        })));
}

export { tileImages };
