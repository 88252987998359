import * as geom from "js/core/utilities/geom";

import { TextGroup } from "../../base/TextGroup";
import { ContentElement } from "../../base/ContentElement";
import { OrgChartNode } from "./OrgChartNode";

export class CircleOrgChartNode extends OrgChartNode {
    get selectionPadding() {
        return 10;
    }

    _build() {
        if (this.showImage) {
            this.content = this.addElement("content", () => ContentElement);
        }
        this.text = this.addElement("text", () => TextGroup, {
            showBody: false,
            showAddButton: false,
            autoWidth: false,
            autoHeight: true,
            title: {
                canSelect: false,
                canRollover: false,
                spellcheck: false
            },
            body: {
                canSelect: false,
                canRollover: false,
                spellcheck: false
            }
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        size = new geom.Size(120, 120);

        if (this.parentElement.isRowSmall(this.rowIndex) && this.styles.small) {
            this.updateStyles(this.styles.small);
            size = new geom.Size(100, 100);
        }

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, size.height / 2 - textProps.size.height / 2, textProps.size);

        return { size };
    }
}
