import { _ } from "legacy-js/vendor";
import { VerticalAlignType } from "legacy-common/constants";
import { HorizontalAlignType } from "legacy-common/constants";
import * as geom from "js/core/utilities/geom";

import { CollectionElement } from "../../base/CollectionElement";

import { OrgChartRowLabel } from "./OrgChartRowLabel";

import { HiddenOrgChartRootNode } from "./HiddenOrgChartRootNode";
import { BoxOrgChartNode } from "./BoxOrgChartNode";
import { MinimalOrgChartNode } from "./MinimalOrgChartNode";
import { PictureOrgChartNode } from "./PictureOrgChartNode";
import { CircleOrgChartNode } from "./CircleOrgChartNode";
import { LabelOrgChartNode } from "./LabelOrgChartNode";
import { OrgChartConnectors } from "./OrgChartConnectors";

// Layouters
import "./layouters/calcHorizontalTreeLayout";
import "./layouters/calcVerticalTreeLayout";

export class OrgChart extends CollectionElement {
    getChildItemType(itemModel) {
        if (this.layout == "table") {
            return BoxOrgChartNode;
        }

        if (itemModel.parent == null && this.hideRootNode) {
            return HiddenOrgChartRootNode;
        }

        switch (itemModel.nodeType || "node") {
            case "person":
            case "assistant":
            case "emphasized":
            case "node":
            case "picture-node":
                switch (this.model.defaultNodeStyle || "box") {
                    case "box":
                        return BoxOrgChartNode;
                    case "minimal":
                        return MinimalOrgChartNode;
                    case "circle":
                        return CircleOrgChartNode;
                    case "photo1":
                    case "photo2":
                    case "photo3":
                        return PictureOrgChartNode;
                }

                break;
            case "placeholder":
                if (this.model.defaultNodeStyle == "circle") {
                    return CircleOrgChartNode;
                } else {
                    return BoxOrgChartNode;
                }
            case "label":
                return LabelOrgChartNode;
        }
    }

    get supportsAddHotKey() {
        return false;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" },
            style: "text-box"
        };
    }

    get nodeStyle() {
        return this.model.shape || "box";
    }

    get showConnectors() {
        return this.layout != "table";
    }

    get hideRootNode() {
        if (this.layoutDirection == "vertical") {
            return this.model.hideRootNode;
        } else {
            return false;
        }
    }

    get layout() {
        return this.model.layout || "compressed";
    }

    get layoutDirection() {
        return this.model.layoutDirection || "vertical";
    }

    get connectorStyle() {
        return "step";
    }

    get rowCount() {
        return _.maxBy(this.itemElements, node => node.rowIndex).rowIndex + 1;
    }

    get rowLabels() {
        return this.model.rowLabels || {};
    }

    get rootNode() {
        return _.find(this.itemElements, node => node.isRootNode);
    }

    _build() {
        // Removing items w/o parent
        this.itemCollection.forEach(nodeModel => {
            if (nodeModel.parent && !this.itemCollection.some(parent => parent.id === nodeModel.parent)) {
                this.itemCollection.remove(nodeModel);
            }
        });

        // Creating row labels
        this.rowLabelElements = [];
        Object.keys(this.rowLabels).forEach(key => {
            if (this.rowLabels[key]) {
                const rowLabelElement = this.addElement(`rowLabel${key}`, () => OrgChartRowLabel, {
                    model: this.rowLabels[key]
                });
                this.rowLabelElements.push(rowLabelElement);
            }
        });

        if (this.showConnectors) {
            this.connectors = this.addElement("connectors", () => OrgChartConnectors);
        }

        super.buildItems();
    }

    get dirtyProperites() {
        return ["primary.smallRows"];
    }

    _loadStyles(styles) {
        let styleNode;
        if (this.layout == "table") {
            styleNode = styles.layout.table;
        } else {
            styleNode = styles.layout.tree;
        }

        if (this.layoutDirection === "vertical") {
            styles.applyStyles(styleNode.vertical);
        } else if (this.layoutDirection === "horizontal") {
            styles.applyStyles(styleNode.horizontal);
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let availableSize = size.clone();
        if (this.rowLabelElements.length) {
            availableSize = availableSize.deflate({ left: OrgChartRowLabel.LABEL_WIDTH });
        }

        const layouter = this.getLayouter(props, this.itemElements, availableSize);

        const layoutOptions = {
            hideRootNode: this.hideRootNode,
            stackLeafNodes: false,
            compressed: false,
            isTable: false,
            direction: this.layoutDirection
        };

        switch (this.layout) {
            case "table":
                layoutOptions.isTable = true;
                break;
            case "expanded":
                break;
            case "stacked":
                layoutOptions.stackLeafNodes = true;
                break;
            case "compressed":
            default:
                layoutOptions.compressed = true;
                break;
        }

        if (this.layoutDirection == "vertical") {
            layouter.calcVerticalTreeLayout(layoutOptions);
        } else {
            layouter.calcHorizontalTreeLayout(layoutOptions);
        }

        layouter.alignHorizontally(HorizontalAlignType.CENTER);
        layouter.alignVertically(VerticalAlignType.MIDDLE);

        this.rows = layouter.rows;

        if (this.showConnectors) {
            const connectorsProps = this.connectors.calcProps(layouter.size, {
                vGap: layouter.vGap,
                hGap: layouter.hGap,
                connectorStyle: this.connectorStyle,
                hideRootNode: this.hideRootNode,
                layout: this.layout,
                layoutDirection: this.layoutDirection
            });
            connectorsProps.bounds = new geom.Rect(0, 0, layouter.size);
            connectorsProps.layer = -1;
        }

        return { size: layouter.size };
    }

    isRowSmall(rowIndex) {
        if (this.options.forceSmallNodes) {
            return true;
        }

        if (this.model.smallRows) {
            return this.model.smallRows.contains(rowIndex);
        } else {
            return false;
        }
    }

    deleteItem(itemId) {
        const deletedNode = this.getChild(itemId);

        const parent = deletedNode.parentNode;

        let moveIndex = deletedNode.nodeIndex;
        for (const childNode of deletedNode.childNodes) {
            childNode.model.parent = parent.model.id;
            childNode.model.index = moveIndex++;
        }

        super.deleteItem(itemId);

        deletedNode.siblingNodes.forEach((node, idx) => node.model.index = idx);
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get animationElementName() {
        return "Org chart";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}
