import { app } from "js/namespaces";
import React, { Component, setGlobal } from "reactn";
import { RealTimeSearchInput } from "legacy-js/react/components";
import { IconAsset } from "js/core/models/assets";
import Icon from "legacy-js/react/views/AddAssets/Panes/Icon";
import Spinner from "legacy-js/react/components/Spinner";
import { trackActivity } from "js/core/utilities/utilities";
import { UIContainerContext, UIPane, UIPaneContents, UIPaneHeader } from "legacy-js/react/components/UiComponents";
import { Fab, Icon as MuiIcon } from "@material-ui/core";
import { Gap10 } from "legacy-js/react/components/Gap";
import { SUGGEST_ICON_FORM } from "legacy-common/constants";
import { NoMatchNotice } from "legacy-js/react/components/Notice";
import { fetchIconData, createSearchableIconData, iconSearch } from "js/core/services/iconSearch";

export class IconsPane extends Component {
    static contextType = UIContainerContext;

    constructor(props) {
        super(props);
        this.searchRef = new React.createRef();

        this.state = {
            baseUrl: null,
            iconData: null,
            isSearching: false,
            searchInput: null,
            searchIndex: null,
            searchResults: null,
        };
    }

    componentDidMount = async () => {
        const { iconData, manifestLoc } = await fetchIconData();
        this.setState({
            iconData,
            baseUrl: manifestLoc.iconBaseUrl,
        });
        requestAnimationFrame(() => {
            this.setState({
                searchIndex: createSearchableIconData(iconData)
            });
        });
    };

    selectIcon = iconKey => {
        const { addAssetCallback, handleConfirm } = this.props;
        trackActivity("Icon", "Selected", iconKey);
        addAssetCallback(new IconAsset({ id: iconKey }, { autoLoad: false }));
        handleConfirm();
    };

    renderAllIcons = () => {
        const { baseUrl, iconData } = this.state;
        const iconStyle = app.currentTheme?.get("iconStyle") ?? "chunky";
        const iconKeys = Object.keys(iconData);
        return (
            <div className="search-results-wrapper icons">
                <div className="search-results-container icons">
                    {
                        iconKeys.map(iconKey => (
                            <Icon
                                icon={iconData[iconKey]}
                                key={iconKey}
                                style={iconStyle}
                                baseUrl={baseUrl}
                                selectAsset={() => {
                                    this.selectIcon(iconKey);
                                }}
                            />
                        ))
                    }
                </div>
            </div>
        );
    };

    handleSearch = (input, results, query) => {
        this.setState({
            searchInput: input,
            searchResults: results,
            lastQuery: query,
            isSearching: false
        });
        setGlobal({ lastIconSearchTerm: input });
    };

    renderSearchResults = results => {
        const { baseUrl, iconData } = this.state;

        if (!results || results.length === 0) {
            return (
                <NoMatchNotice
                    message={
                        <div>
                            <span>Try a different search term or </span>
                            <a
                                href={SUGGEST_ICON_FORM}
                                target="_blank"
                            >
                                suggest an icon.
                            </a>
                        </div>
                    }
                />
            );
        } else {
            const iconStyle = app.currentTheme?.get("iconStyle") ?? "chunky";
            return (
                <div className="search-results-wrapper icons">
                    <div className="search-results-container icons">
                        {
                            results.map(result => {
                                const iconKey = iconData[result.ref].key;
                                return (
                                    <Icon
                                        icon={iconData[iconKey]}
                                        key={iconKey}
                                        style={iconStyle}
                                        baseUrl={baseUrl}
                                        selectAsset={() => {
                                            this.selectIcon(iconKey);
                                        }}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    };

    render() {
        const { iconData, searchInput, searchResults, isSearching } = this.state;
        const inputFocused = !this.context;

        return (
            <UIPane style={{ position: "relative" }}>
                <UIPaneHeader>
                    <RealTimeSearchInput
                        ref={this.searchRef}
                        searchInput={searchInput}
                        search={iconSearch}
                        focus={inputFocused}
                        onSearch={this.handleSearch}
                        placeholder="Search for icons..."
                    />
                </UIPaneHeader>
                {isSearching ? <Spinner /> : <UIPaneContents style={{ paddingBottom: 80 }}>
                    {searchResults ? (
                        this.renderSearchResults(searchResults)
                    ) : iconData ? (
                        this.renderAllIcons()
                    ) : (
                        <Spinner />
                    )}
                </UIPaneContents>}
                <Fab
                    variant="extended"
                    className="floating-fab expanding-fab"
                    onClick={() => window.open(SUGGEST_ICON_FORM, "_blank")}
                >
                    <MuiIcon className="text-blue">how_to_vote</MuiIcon>
                    <Gap10 />
                    <span>Suggest an icon</span>
                </Fab>
            </UIPane>
        );
    }
}

export default IconsPane;
