import * as geom from "js/core/utilities/geom";
import { HorizontalAlignType, VerticalAlignType, VerticalBlockAlignType } from "legacy-common/constants";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { TextGroup } from "../base/TextGroup";

class Agenda extends CollectionElement {
    static get schema() {
        return {
            startNum: 1
        };
    }

    getChildItemType(itemModel) {
        if (itemModel.type === "section") {
            return AgendaSection;
        } else {
            return AgendaItem;
        }
    }

    get defaultItemData() {
        return {
            type: "item",
            label: { text: "" },
        };
    }

    getCanvasMargins() {
        return {
            left: this.model.showFoldEffect ? 0 : 50,
            top: 50,
            right: 50,
            bottom: 50
        };
    }

    _loadStyles(styles) {
        this.baseVGap = styles.vGap;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcColumnLayout();

        let scaleDelta = 0.05;
        let minScale = 0.5;
        let scale = 1 - scaleDelta;

        this.styles.vGap = this.baseVGap;

        // try smaller scales if normal scale didn't fit
        while (!layouter.isFit && scale >= minScale) {
            this.styles.vGap = this.baseVGap * scale;
            layouter.calcColumnLayout({
                itemOptions: {
                    scale
                }
            });
            scale -= scaleDelta;
        }
        scale += scaleDelta;

        if (!layouter.isFit) {
            layouter.calcColumnLayout({
                forceFit: true,
                minItemHeight: 50,
                itemOptions: {
                    orientation: "horizontal"
                }
            });
        }

        layouter.alignHorizontally(HorizontalAlignType.CENTER);
        layouter.alignVertically(VerticalAlignType.MIDDLE);

        props.isFit = layouter.isFit;

        return { size: layouter.size };
    }
}

class AgendaItem extends CollectionItemElement {
    get name() {
        return "Item";
    }

    get isInteractive() {
        return this.model.linkToSlide != null;
    }

    get interactiveAction() {
        return {
            type: "navigate",
            id: this.model.linkToSlide
        };
    }

    get animationElementName() {
        let itemIndex = 1;
        for (const element of this.parentElement.itemElements) {
            if (element === this) {
                break;
            }

            if (element.type === "AgendaItem") {
                itemIndex++;
            }
        }
        return `Agenda item #${itemIndex}`;
    }

    _build() {
        if (this.parentElement.model.showIndex) {
            let itemIndex = this.parentElement.itemCollection.filter(model => model.type == "item").indexOf(this.model);
            itemIndex += (this.parentElement.model.startNum || 1);

            this.agendaIndex = this.addElement("index", () => TextElement, {
                model: {
                    index: itemIndex
                },
                canEdit: false,
                canSelect: false,
                canRollover: false,
                isTabbable: false,
            });
        }
        this.text = this.addElement("text", () => TextGroup, {
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        // Scale to 1 if no scale defined to ensure idempotency
        let scale = options.scale ?? 1;
        this.agendaIndex && this.agendaIndex.scaleStyleValues(scale);
        this.text.scaleStyleValues(scale);

        if (this.agendaIndex) {
            let indexSize = new geom.Size(this.styles.index.width, this.styles.index.height);
            let layouter = this.getLayouter(props, [this.agendaIndex, this.text], size);
            layouter.calcHorizontalBlockLayout({
                verticalAlign: VerticalBlockAlignType.MIDDLE_TITLE,
                contentSize: indexSize
            });
            return { size: layouter.size };
        } else {
            let textProps = this.text.calcProps(size);
            return { size: textProps.size };
        }
    }
}

class AgendaSection extends CollectionItemElement {
    get name() {
        return "Section";
    }

    get animationElementName() {
        let sectionIndex = 1;
        for (const element of this.parentElement.itemElements) {
            if (element === this) {
                break;
            }

            if (element.type === "AgendaSection") {
                sectionIndex++;
            }
        }
        return `Agenda section #${sectionIndex}`;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            bindTo: "title",
            canStyle: false,
            placeholder: "Type your section",
            autoWidth: true,
            autoHeight: true,
            breakType: "even"
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        // Scale to 1 if no scale defined to ensure idempotency
        this.text.scaleStyleValues(options.scale ?? 1);

        let textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, textProps.size);

        props.isTextFit = textProps.isTextFit;

        return { size: textProps.size };
    }
}

export { Agenda, AgendaItem };

export const elements = {
    Agenda,
};
