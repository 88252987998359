import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import getLogger, { LogGroup } from "js/core/logger";
import { app } from "js/namespaces";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import { ds } from "js/core/models/dataService";
import permissionDS from "legacy-js/react/views/PresentationSettings/dataservices/PermissionsDataService";
import { trackActivity } from "js/core/utilities/utilities";

const logger = getLogger(LogGroup.PERMISSIONS);

const StyledDialogTitle = styled(DialogTitle)`
    display: flex;
    width: 100%;
 `;

const StyledDialogActions = styled(DialogActions)`
    display: flex;
    justify-content: space-between;
`;

class InviteEditCollaboratorDialog extends Component {
    state = {
        fetching: false
    }

    handleInviteCollaborator = () => {
        const { user, closeDialog, old_value } = this.props;

        this.setState({ fetching: true });

        const emails = [user.email];
        const permissions = { emails, permission: "write", customMessage: "" };
        permissionDS.createPermission(permissions, ds.selection.presentation.id, false)
            .then(() => closeDialog(true))
            .catch(err => logger.error(err, "[InviteEditCollaboratorDialog] permissionDS.createPermission() failed"));
        const props = {
            old_value: old_value || null,
            new_value: "write",
            recipient_emails: emails,
            sender_email: app.user.getEmail(),
            location: "assign"
        };
        trackActivity("Collab", "Invite", null, null, props, { audit: true });
    };

    render() {
        const { message, title, closeDialog } = this.props;
        const { fetching } = this.state;

        return (
            <BeautifulDialog closeDialog={() => closeDialog(false)}>
                <StyledDialogTitle disableTypography={true}>
                    <h2>{title}</h2>
                </StyledDialogTitle>
                <DialogContent>
                    <p>{message}</p>
                </DialogContent>
                <StyledDialogActions >
                    <Button disabled={fetching} onClick={() => closeDialog(false)}>Nevermind</Button>
                    <Button disabled={fetching} onClick={this.handleInviteCollaborator} color="primary">Invite Collaborator</Button>
                </StyledDialogActions>
            </BeautifulDialog>
        );
    }
}

export default InviteEditCollaboratorDialog;
