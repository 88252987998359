import React, { Component } from "react";
import styled from "styled-components";

import { THUMBNAIL_SIZES } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { _ } from "legacy-js/vendor";
import Thumbnails from "js/core/models/thumbnails";
import { themeColors } from "legacy-js/react/sharedStyles";
import { UIPane } from "legacy-js/react/components/UiComponents";
import Spinner from "legacy-js/react/components/Spinner";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap5 } from "legacy-js/react/components/Gap";
import { gallerySlidesDataService, slideTemplatesDataService } from "legacy-js/react/views/AddSlide/DataServices";
import { UIActionType, isUIActionRestricted } from "js/core/uiActionsRestrictors";

import { SearchPreview, SearchPreviewHeader } from "legacy-js/react/views/AddSlide/Panes/SearchSlidesPane";
import { Thumbnail, ThumbnailBadge, ThumbnailContainer, ThumbnailGrid } from "./Components/ThumbnailGrid";

const templateCategories = [{
    id: "popular",
    name: "Popular Slides",
    description: "The templates our users love most",
    thumbnail: "Category-Icon-Basics.png",
    suggestions: ["key messages", "bullets", "team"]
}, {
    id: "organization",
    name: "Structural Slides",
    description: "Presentation must-haves",
    thumbnail: "Category-Icon-Basics.png",
    suggestions: ["agenda", "new section", "thank you", "bio slide", "contact"]
}, {
    id: "basic",
    name: "Essential Slides",
    description: "Common slides",
    thumbnail: "Category-Icon-Basics.png",
    suggestions: ["key takeaway", "photo with text", "section break", "speaker intros", "big headline"]
}, {
    id: "data",
    name: "Data & Chart Slides",
    description: "Charts, stats, and tables",
    thumbnail: "Category-Icon-Data.png",
    suggestions: ["revenue over time", "market share", "survey results", "financial model"]
}, {
    id: "impact",
    name: "Impact Slides",
    description: "Images and headlines",
    thumbnail: "Category-Icon-Impact.png",
    suggestions: ["our mission", "our values", "provocative question", "inspirational quote", "call to action"]
}, {
    id: "diagram",
    name: "Diagrams & Timeline Slides",
    description: "Process and timelines",
    thumbnail: "Category-Icon-Diagrams.png",
    suggestions: ["progress over time", "project schedule", "step-by-step", "hierarchy"]
}, {
    id: "comparisons",
    name: "Comparison Slides",
    description: "Compare, group, and rank",
    thumbnail: "Category-Icon-Relationships.png",
    suggestions: ["change over time", "compare groups", "hierarchy", "us vs them"]
}, {
    id: "people",
    name: "People Slides",
    description: "Teams and organizations",
    thumbnail: "Category-Icon-People.png",
    suggestions: ["culture", "contact info", "org structure", "team bios"]
}, {
    id: "product",
    name: "Product & Customer Slides",
    description: "Features and competitors",
    thumbnail: "Category-Icon-Product.png",
    suggestions: ["product features", "our services", "case study", "competition", "screenshots"]
}];

export class SlideTemplateSearchPreview extends Component {
    static defaultProps = {
        label: "Start from a Smart Template"
    }

    render() {
        const {
            items,
            onSelected,
            showPreview = true
        } = this.props;

        return (
            <SearchPreview>
                <ThumbnailGrid
                    columns={5}
                    colGap={40}
                    rowGap={10}
                    items={items.map(item => ({ ...item, showPreview }))}
                    thumbnailClass={SlideTemplateThumbnail}
                    onItemSelected={onSelected}
                />
            </SearchPreview>
        );
    }
}

export class SlideTemplatesByCategoryList extends Component {
    state = {
        isLoaded: false,
        slideTemplates: []
    }

    componentDidMount() {
        slideTemplatesDataService.search({})
            .then(slideTemplates => {
                this.setState({ isLoaded: true, slideTemplates });
            });
    }

    render() {
        const { onSelected, showUserTheme, showPreview = true } = this.props;
        const { isLoaded, slideTemplates } = this.state;

        if (!isLoaded) {
            return <UIPane><Spinner /></UIPane>;
        }

        return templateCategories.map(category => {
            const templatesInCategory = _.filter(
                slideTemplates,
                template =>
                    template.category.includes(category.id) &&
                    !isUIActionRestricted(UIActionType.ADD_SLIDE, { templateId: template.id })
            ).map(template => ({ ...template, showPreview }));

            return (
                <SearchPreview key={category.id}>
                    <SearchPreviewHeader>
                        {category.name}
                    </SearchPreviewHeader>
                    <ThumbnailGrid
                        columns={5}
                        colGap={40}
                        rowGap={10}
                        items={templatesInCategory}
                        thumbnailClass={SlideTemplateThumbnail}
                        onItemSelected={onSelected}
                        showThemeThumbnails={showUserTheme}
                    />
                </SearchPreview>
            );
        });
    }
}

const SlideTemplatePreviewContainer = styled.div`
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -40%);

    opacity: 0;
    pointer-events: none;

    transition: opactiy 0.3s;

    background: white;
    box-shadow: 0 3px 14px rgba(0,0,0,0.33);

    z-index: 1;
    padding: 15px;
    
    img.current,
    img.next {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    
    .previews {
        border: 1px solid #eee;
        opacity: 0;
        position: relative;
        overflow: hidden;
        width: 220px;
        height: 125px;
    }

    .summary {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        padding: 15px 0 0 0;

        label {
            color: ${themeColors.ui_blue};
            font-size: 14px;
            letter-spacing: 0;
            font-weight: bold;
            text-transform: uppercase;
            transition: all 0.3s;
        }

        p {
            color: ${themeColors.gray};
            font-size: 12px;
            transition: all 0.3s;
            text-align: center;
        }
    }

    &.ready {
        .previews {
            opacity: 1;
            transition: all 0.4s;
        }
    }

    .next {
        transform: translateX(100%);
    }

    &.cycle {
        img {
            transition: all 1s cubic-bezier(0.15, 1, 0.6, 1);
        }

        .next {
            transform: translateX(0);
        }

        .current {
            transform: translateX(-100%);
        }
    }
`;

const SlideTemplateThumbnailContainer = styled(ThumbnailContainer)`
    
    .thumbnail {
        transition: all 0.3s;
    }

    .description {
        text-transform: uppercase;
    }

    &:hover {

        .slide-template-preview-container {
            opacity: 1;
        }

        .learn-more {
            opacity: 1;
        }

        .description {
            p {
                opacity: 1;
            }

            label {
                color: ${themeColors.ui_blue};
            }
        }

        .description.is-authoring {
            label {
                color: ${themeColors.ui_blue}
            }
        }
    }
  
    & > .learn-more {
        background: ${themeColors.ui_blue};
        padding: 6px 10px;
        color: white;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        position: absolute;
        left: calc(100% - 20px);
        top: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 300ms;
        cursor: pointer;
    }
`;

class SlideTemplatePreview extends React.PureComponent {
    previewRef = React.createRef()

    urls = []
    index = 0

    state = {
        ready: false
    }

    componentDidMount() {
        this.loadPreviews();
    }

    componentWillUnmount() {
        clearInterval(this._cycleSlides);
    }

    startCycle = () => {
        const SLIDE_CYCLE_INTERVAL = 1500;

        // handling a slow to load preview scenario
        if (this._cycleImmediately) {
            clearInterval(this._cycleSlides);
            this.cycleSlides();
        }

        // queue up the slide cycling
        if (!this._cycleSlides) {
            this._cycleSlides = setInterval(this.cycleSlides, SLIDE_CYCLE_INTERVAL);
        }
    }

    cycleSlides = () => {
        const { current: previewer } = this.previewRef;

        // appears to be disconnected
        if (!previewer) {
            // stop the animation
            clearInterval(this._cycleSlides);
            return;
        }

        // prepare to show
        const { urls, index } = this;
        const { length: total } = urls;

        // if there's not enough slides, just mark this
        // as needing to cycle as soon as possible
        if (total <= 1 && this.index === 0) {
            this._cycleImmediately = true;
            return;
        }

        // get the elements to update
        const [current, next] = previewer.querySelectorAll("img");

        // reset the animation
        current.src = urls[index % total];
        next.src = urls[(index + 1) % total];
        previewer.classList.remove("cycle");
        requestAnimationFrame(() => previewer.classList.add("cycle"));

        // bump forward
        this.index++;
    }

    loadPreviews = async () => {
        const { slides } = this.props;

        // load each preview
        for (const slide of slides) {
            // must have a result
            if (!slide) {
                continue;
            }

            // load the thumbnail
            const { id, modifiedAt } = slide;
            const url = await Thumbnails.getSignedUrlAndLoad(id, modifiedAt, null, THUMBNAIL_SIZES.small.suffix);
            this.urls.push(url);

            // can display
            this.setState({ ready: true });

            // can animate
            this.startCycle();
        }
    }

    render() {
        const {
            urls, previewRef,
            props: { title, description },
            state: { ready }
        } = this;

        return (
            <SlideTemplatePreviewContainer className={ready ? "slide-template-preview-container ready" : ""} ref={previewRef}>
                <div className="previews" >
                    <img className="current" src={urls[0]} />
                    <img className="next" />
                    {!ready && <Spinner />}
                </div>
                <div className="summary" >
                    <label>{title}</label>
                    <p>{description}</p>
                </div>
            </SlideTemplatePreviewContainer>
        );
    }
}

export class SlideTemplateThumbnail extends Component {
    state = {
        hasPreview: false,
        previewSlides: false
    }

    // extra preview hover component
    preview = null

    async componentDidMount() {
        const { item: { id: templateId } } = this.props;

        // try and load matching slides
        const slideCount = 3;
        const results = await gallerySlidesDataService.search({ templateId, size: slideCount });

        // verify there's something to show
        const previewSlides = _.compact(results.slice(0, slideCount));
        this.setState({ previewSlides: previewSlides.length === slideCount ? previewSlides : null });
    }

    attachPreview = () => {
        const { hasPreview, previewSlides } = this.state;

        if (!hasPreview && previewSlides) {
            const { item: { title, description } } = this.props;
            this.preview = <SlideTemplatePreview title={title} description={description} slides={previewSlides} />;
            this.setState({ hasPreview: true });
        }
    }

    render() {
        const { onClick, item: { id, isDev, isComingSoon, icon, title, showPreview = true, isSmart } } = this.props;
        const { hasPreview } = this.state;

        // handle hover previews
        const activatePreview = showPreview ? { onMouseEnter: this.attachPreview } : {};

        return (
            <SlideTemplateThumbnailContainer dataId={id} onClick={onClick} {...activatePreview} >
                {hasPreview && this.preview}

                <Thumbnail url={getStaticUrl(`/images/template-icons/${icon}.svg`)}>
                    {isDev && <ThumbnailBadge type="warning">Dev Only</ThumbnailBadge>}
                    {isComingSoon && <ThumbnailBadge type="info">Coming Soon</ThumbnailBadge>}
                </Thumbnail>
                <Gap5 />
                <FlexBox>
                    <div className={`description ${!isSmart && "is-authoring"}`} >
                        {title && <label>{title}</label>}
                    </div>
                </FlexBox>
            </SlideTemplateThumbnailContainer>
        );
    }
}

