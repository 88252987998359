import React, { useState, useRef } from "react";
import styled from "styled-components";

import { Popover } from "@material-ui/core";

const StyledPopover = styled(Popover)`
    &&& {
        pointer-events: none;
        
        .MuiPaper-root {
            width: 200px;
            transform: translateY(-12px) !important;
            overflow: visible;
            background: rgba(255,255,255,0.95);
            border-radius: 2px;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
        }
    }
`;

const PopoverTriangle = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);

    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;    
    border-top: 10px solid rgba(255,255,255,0.95);
`;

const Title = styled.div`
    margin: 10px 10px 5px 10px;

    font-weight: 600;
    font-size: 12px;
    line-height: 150%;   
    text-transform: uppercase;  
    color: #666666;
`;

const Subtitle = styled.div`
    margin: 0 10px 10px 10px;

    font-size: 11px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #666666;
`;

function Tooltip({ title, subtitle, children }) {
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const childrenContainerRef = useRef();

    return (
        <>
            <div
                ref={childrenContainerRef}
                onMouseEnter={() => setPopoverOpen(true)}
                onMouseLeave={() => setPopoverOpen(false)}
            >
                {children}
            </div>
            <StyledPopover
                anchorEl={childrenContainerRef.current}
                open={isPopoverOpen}
                disableRestoreFocus
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <PopoverTriangle />
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </StyledPopover>
        </>
    );
}

export default Tooltip;
