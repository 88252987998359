function sanitizeJavascriptInjection(url, fallbackValue = "/") {
    // Whitespace between the letters in 'javascript' still parse, so we
    //   have to account for non-alpha characters between the letters.
    //   We also test with case insensitivity.
    const startsWithJavascript =
        /^j[^a-z]*a[^a-z]*v[^a-z]*a[^a-z]*s[^a-z]*c[^a-z]*r[^a-z]*i[^a-z]*p[^a-z]*t/i;
    if (startsWithJavascript.test(url)) {
        return fallbackValue;
    }

    return url;
}

function sanitizeRelativeUrl(url, fallbackValue = "/") {
    if (url.includes("//")) {
        return fallbackValue;
    }

    return sanitizeJavascriptInjection(url, fallbackValue);
}

module.exports = sanitizeRelativeUrl;
