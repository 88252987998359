import React, { Component } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";

import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "legacy-js/config";
import * as smartsheetAuth from "js/core/oauth/smartsheetAuth";
import { PopupBlockedError } from "js/core/utilities/selfClosingWindow";
import { trackActivity } from "js/core/utilities/utilities";
import { BlueButton, TextButton } from "legacy-js/react/components/UiComponents";
import Spinner from "legacy-js/react/components/Spinner";
import { ShowDialog, ShowErrorDialog, ShowConfirmationDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import SmartsheetFilePickerDialog from "legacy-js/react/components/Dialogs/SmartsheetFilePickerDialog";
import { Gap10, Gap30 } from "legacy-js/react/components/Gap";
import { spreadsheetDataToXlsxWorkbook, SpreadsheetData } from "js/core/utilities/xlsx";

const logger = getLogger(LogGroup.DATA_SOURCE);

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #A9A9A9;
    padding: 30px 50px;
    margin-bottom: 30px;
    & .label-text {
        font-size: 18px;
        font-weight: 600;
    }
    & .description-text {
        text-align: center; 
        color: #666666;
        font-size: 16px;
        font-weight: 400;
    }
`;

interface Props {
    handleAddedData: Function,
    handleDisconnect: Function,
}

interface State {
    userSmartsheetLinkFetching: boolean,
    userHasSmartsheetOfflineAccess: boolean,
    userSmartsheetAccessToken: string,
    spreadsheetDataFetching: boolean,
    spreadsheetData: SpreadsheetData,
    disconnecting: boolean,
}

export default class SmartsheetSelectorPane extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userSmartsheetLinkFetching: false,
            userHasSmartsheetOfflineAccess: false,
            userSmartsheetAccessToken: "",
            spreadsheetDataFetching: false,
            spreadsheetData: null,
            disconnecting: false,
        };
    }

    componentDidMount() {
        this.fetchSmartsheetTokens();
    }

    fetchSmartsheetTokens = async () => {
        this.setState({ userSmartsheetLinkFetching: true });

        try {
            const { hasTokens, accessToken } = await Api.getSmartsheetTokens.get();
            this.setState({ userSmartsheetLinkFetching: false, userHasSmartsheetOfflineAccess: hasTokens, userSmartsheetAccessToken: accessToken });
        } catch (err) {
            logger.error(err, "[SmartsheetSelectorPane] Api.getSmartsheetTokens.get()() failed");
            this.setState({ userSmartsheetLinkFetching: false });
        }
    }

    setSpreadsheetDataFetching(spreadsheetDataFetching: boolean, spreadsheetData?: SpreadsheetData) {
        this.setState({ spreadsheetDataFetching, spreadsheetData });
    }

    fetchSpreadsheetData = async (spreadsheetId: string) => {
        this.setSpreadsheetDataFetching(true);

        let spreadsheetData: SpreadsheetData = null;
        try {
            spreadsheetData = await Api.smartsheetSpreadsheets.get({ spreadsheetId });
            this.setSpreadsheetDataFetching(false, spreadsheetData);

            const workbookData: XLSX.WorkBook = spreadsheetDataToXlsxWorkbook(spreadsheetData);
            this.props.handleAddedData(workbookData, spreadsheetData);
        } catch (err) {
            logger.error(err, "[SmartsheetSelectorPane] Api.smartsheetSpreadsheets.get() failed", { spreadsheetId });
            this.setSpreadsheetDataFetching(false);

            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: err.message
            });
        }
    }

    handleSelectedFile = async file => {
        try {
            await this.fetchSpreadsheetData(file.id);
        } catch (err) {
            logger.error(err, "[SmartsheetSelectorPane] fetchSpreadsheetData() failed", { fileId: file.id });
            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: err.message
            });
        }
    }

    handleSmartsheetAuth = async () => {
        if (this.state.spreadsheetDataFetching) return;

        try {
            if (!this.state.userHasSmartsheetOfflineAccess) {
                trackActivity("Smartsheet", "DataLinkGrantAccess", null, null, {}, { audit: true, skipAmplitude: false });

                const tokens = await smartsheetAuth.grantOfflineAccess();

                this.setState({ userHasSmartsheetOfflineAccess: true, userSmartsheetAccessToken: tokens.accessToken });
            }

            await this.openPicker();
        } catch (err) {
            if (err instanceof PopupBlockedError) {
                // User cancelled, ignore
                return;
            }

            logger.error(err, "[SmartsheetSelectorPane] smartsheetAuth.grantOfflineAccess() failed");
        }
    }

    handleDisconnect = async () => {
        if (this.state.disconnecting) return;

        ShowConfirmationDialog({
            title: "Disconnect Account?",
            message: (
                <span>
                    Any linked data will no longer automatically update.
                    <br /><br />
                    You can link to this account again at any time.
                </span>
            ),
            buttonOptions: { acceptButtonColor: "red" },
            okButtonLabel: "Disconnect",
            acceptCallback: async () => {
                this.setState({ disconnecting: true });

                try {
                    logger.info("[SmartsheetSelectorPane] Deleting credentials from server");
                    await Api.unlinkSmartsheetAuth.delete();

                    this.setState({
                        disconnecting: false,
                        userHasSmartsheetOfflineAccess: false,
                        userSmartsheetAccessToken: ""
                    });

                    await this.props.handleDisconnect();
                } catch (err) {
                    logger.error(err, "[SmartsheetSelectorPane] handleDisconnect() failed");
                    this.setState({ disconnecting: false });

                    ShowErrorDialog({
                        error: "Sorry, we could not process your request",
                        message: err.message
                    });
                }
            }
        });
    }

    openPicker = async () => {
        const accessToken = this.state.userSmartsheetAccessToken;

        if (accessToken) {
            ShowDialog(SmartsheetFilePickerDialog, { token: accessToken, handleSelectedFile: this.handleSelectedFile });
        }
    }

    render() {
        const { userSmartsheetLinkFetching, userHasSmartsheetOfflineAccess, spreadsheetDataFetching, disconnecting } = this.state;

        return (
            <Container>
                <img src={getStaticUrl("/images/data-linking/smartsheet.svg")} width={50} height={50} />
                <Gap30 />
                <span className="label-text">Import from or link data to Smartsheet file.</span>
                <Gap10 />
                {!userSmartsheetLinkFetching && (<>
                    <span className="description-text">Linked data will update automatically whenever your source file is changed.</span>
                    <Gap30 />
                    <BlueButton /** @ts-ignore */
                        onClick={this.handleSmartsheetAuth}
                    >
                        {spreadsheetDataFetching && <Spinner />}
                        {!spreadsheetDataFetching && (userHasSmartsheetOfflineAccess ? "Select File from Smartsheet" : "Connect to Smartsheet")}
                    </BlueButton>
                    {userHasSmartsheetOfflineAccess && (
                        <>
                            <Gap10 />
                            <TextButton /** @ts-ignore */
                                onClick={this.handleDisconnect} color="secondary"
                                disabled={spreadsheetDataFetching}
                            >
                                {disconnecting && <Spinner />}
                                {!disconnecting && "Disconnect Account"}
                            </TextButton>
                        </>
                    )}
                </>)}
            </Container>
        );
    }
}
