import React, { Component, Fragment } from "reactn";
import moment from "moment";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import Avatar from "legacy-js/react/components/Avatar";
import Icon from "legacy-js/react/components/Icon";
import ConfirmationDialog from "legacy-js/react/components/Dialogs/ConfirmationDialog";
import linkDS, { LinkType } from "legacy-js/react/views/PresentationSettings/dataservices/LinkDataService";
import EditLinkDialog from "legacy-js/react/views/PresentationSettings/dialogs/EditLinkDialog";
import EditInviteDialog from "legacy-js/react/views/PresentationSettings/dialogs/EditInviteDialog";
import { ShowDialog, ShowUpgradeDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import EditEmbedCodeDialog from "legacy-js/react/views/PresentationSettings/dialogs/EditEmbedCodeDialog";
import { FlexSpacer, Gap10 } from "legacy-js/react/components/Gap";
import { PresentationPrivacyType } from "js/core/models/presentation";
import { FeatureType } from "js/core/models/features";
import ProBadge from "legacy-js/react/components/ProBadge";
import { app } from "js/namespaces";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import { trackActivity } from "js/core/utilities/utilities";
import { _ } from "legacy-js/vendor";
import NotificationsService from "js/core/services/notifications";
import EditSocialShareDialog from "../dialogs/EditSocialShareDialog";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import getLogger, { LogGroup } from "js/core/logger";
import { ShowSnackBar } from "legacy-js/react/components/Dialogs/SnackBar";

const logger = getLogger(LogGroup.LINKS);

function trackLinkStatusChange(props) {
    trackActivity("Sharing", "ChangeLinkStatus", null, null, props, { audit: true });
}

function getPropsForDeleteLink(pendingLinkToDelete, type, presentation) {
    return {
        presentation_id: presentation.id,
        link_ids: [pendingLinkToDelete.get("id")],
        old_value: pendingLinkToDelete.get("enabled") ? "enabled" : "disabled",
        new_value: "deleted",
        workspace_id: presentation.getWorkspaceId(),
        type
    };
}

class LinkItem extends Component {
    handleEditLink = () => {
        this.setState({ menuAnchorEl: null });
        this.props.onEditLink(this.props.link);
    };

    handleDisableLink = type => {
        const { presentation, link } = this.props;
        const workspaceId = presentation.getWorkspaceId();
        if (!app.user.features.isFeatureEnabled(FeatureType.DISABLE_LINKS, workspaceId)) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "DisableLink", ...presentation.getAnalytics() },
                workspaceId
            });
            this.setState({ menuAnchorEl: null });
            return;
        }

        const analyticsProps = {
            presentation_id: presentation.id,
            link_ids: [link.get("id")],
            old_value: link.get("enabled") ? "enabled" : "disabled",
            new_value: !link.get("enabled") ? "enabled" : "disabled",
            workspace_id: presentation.getWorkspaceId(),
            type
        };
        link.update({ enabled: !link.get("enabled") });
        this.setState({ menuAnchorEl: null });
        trackLinkStatusChange(analyticsProps);
    };

    handleDeleteLink = () => {
        this.setState({ menuAnchorEl: null });
        this.props.onDeleteLink(this.props.link);
    };

    renderOptionsMenu(menuItems) {
        const { menuAnchorEl } = this.state;

        return (
            <Fragment>
                <Gap10 />
                <IconButton
                    onClick={event =>
                        this.setState({ menuAnchorEl: event.currentTarget })
                    }
                >
                    <Icon iconName="more_vert" style={{ color: "#333" }} />
                </IconButton>
                <Menu
                    id="menu"
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={() => this.setState({ menuAnchorEl: null })}
                >
                    {menuItems}
                </Menu>
            </Fragment>
        );
    }
}

export class LinksList extends Component {
    render() {
        const { presentation } = this.props;

        if (presentation.links.length) {
            return (
                <Fragment>
                    <SharableLinksList presentation={presentation} />
                    <InvitesList presentation={presentation} />
                    <EmbedsList presentation={presentation} />
                    <SocialLinksList presentation={presentation} />
                </Fragment>
            );
        } else {
            return (
                <div className="empty-list-warning">
                    There are no links for this presentation
                </div>
            );
        }
    }
}

class SharableLinkItem extends LinkItem {
    state = {};
    embedRef = React.createRef();

    analyticsProps(link) {
        return {
            link_ids: [link.get("id")],
            is_new: false,
            type: "public_link",
            ...this.props.presentation.getAnalytics()
        };
    }

    copyToClipboard = () => {
        const { link } = this.props;

        clipboardWrite({
            [ClipboardType.TEXT]: this.embedRef.current.value,
        });
        ShowSnackBar({
            message: "Shareable URL was copied to the clipboard"
        });
        const props = this.analyticsProps(link);
        trackActivity("Sharing", "CopyLink", null, null, props, { audit: true });
    };

    render() {
        const { presentation, link } = this.props;

        const viewCount = link.get("views") || 0;
        const privacySetting = presentation.getPrivacySetting();

        const enabled =
            link.get("enabled") && (
                (privacySetting === PresentationPrivacyType.PUBLIC && (link.get("type") === LinkType.PUBLIC || link.get("type") === LinkType.PROTECTED)) ||
                (privacySetting === PresentationPrivacyType.SECURED && link.get("type") === LinkType.PROTECTED)
            );

        return (
            <div className={`link ${enabled ? "" : "disabled-link"}`}>
                {link.get("type") === LinkType.PUBLIC && <Icon iconName="link" />}
                {link.get("type") === LinkType.PROTECTED && <Icon iconName="lock" />}
                <div className="name">{link.get("name")}</div>
                <div className="created">
                    {moment(link.get("createdAt")).format("MMM DD YYYY")}
                </div>

                <div className={`view-state ${viewCount > 0 ? "viewed" : ""}`}>
                    {viewCount} view{viewCount == 1 ? "" : "s"}
                </div>

                <div className="expires">
                    {(link.has("expiresAt") && Date.now() < new Date(link.get("expiresAt")).getTime()) &&
                        <span>
                            Expires {moment(link.get("expiresAt")).format("MMM Do YYYY [at] h:mm a")}
                        </span>
                    }
                    {(link.has("expiresAt") && Date.now() >= new Date(link.get("expiresAt")).getTime()) &&
                        <span style={{ color: "orangered" }}>
                            Expired {moment(link.get("expiresAt")).format("MMM Do YYYY [at] h:mm a")}
                        </span>
                    }
                </div>

                <FlexSpacer />
                <input
                    ref={this.embedRef}
                    className="link-url"
                    type="text"
                    value={link.getLinkURL()}
                    readOnly
                />
                {!enabled ? (
                    <div className="disabled-label">Disabled</div>
                ) : (
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disabled={link.isLinkExpired()}
                        onClick={this.copyToClipboard}
                    >
                        Copy Link
                    </Button>
                )}

                {this.renderOptionsMenu([
                    <MenuItem key="edit" onClick={this.handleEditLink}>
                        Edit Link...
                    </MenuItem>,
                    <MenuItem
                        key="disable"
                        onClick={() => this.handleDisableLink("public_link")}
                        divider
                    >
                        {enabled ? "Disable Link" : "Enable Link"}
                        <ProBadge
                            show={shouldShowProBadge(FeatureType.DISABLE_LINKS, presentation.getWorkspaceId())}
                            analytics={{ cta: "DisableLink", ...this.analyticsProps(link) }}
                            workspaceId={presentation.getWorkspaceId()}
                        />
                    </MenuItem>,
                    <MenuItem key="delete" onClick={this.handleDeleteLink}>
                        Delete Link
                    </MenuItem>
                ])}
            </div>
        );
    }
}

export class SharableLinksList extends Component {
    state = {};

    render() {
        const { presentation } = this.props;

        const publicLinks = _.filter(
            presentation.links.models,
            link => link.get("type") == LinkType.PUBLIC
        );
        const protectedLinks = _.filter(
            presentation.links.models,
            link => link.get("type") == LinkType.PROTECTED
        );

        const sharableLinks = [...publicLinks, ...protectedLinks];
        if (sharableLinks.length) {
            return (
                <div className="links">
                    {sharableLinks.map(link => (
                        <SharableLinkItem
                            key={link.id}
                            presentation={presentation}
                            link={link}
                            onEditLink={this.handleEditLink}
                            onDeleteLink={this.handleDeleteLink}
                        />
                    ))}
                    {this.renderConfirmDeleteDialog()}
                </div>
            );
        } else {
            return null;
        }
    }

    handleEditLink = link => {
        const { presentation } = this.props;
        ShowDialog(EditLinkDialog, {
            link: link,
            presentation,
            callback: () => this.forceUpdate(),
            onClose: () => this.forceUpdate()
        });
    };

    handleDeleteLink = link => {
        this.setState({
            showConfirmDelete: true,
            pendingLinkToDelete: link
        });
    };

    handleConfirmationOk = () => {
        const { presentation } = this.props;
        const { pendingLinkToDelete } = this.state;
        const props = getPropsForDeleteLink(pendingLinkToDelete, "public_link", presentation);
        this.setState({ showConfirmDelete: false });
        linkDS
            .deleteLink(presentation, pendingLinkToDelete.get("id"))
            .then(() => {
                trackLinkStatusChange(props);
                this.setState({ pendingLinkToDelete: null });
            })
            .catch(err => {
                logger.error(err, "[SharableLinksList] handleConfirmationOk() failed", { presentationId: this.global.presentation?.id });
            });
    };

    handleConfirmationCancel = () => {
        this.setState({ showConfirmDelete: false });
    };

    renderConfirmDeleteDialog() {
        let { showConfirmDelete, pendingLinkToDelete } = this.state;

        return (
            <ConfirmationDialog
                open={showConfirmDelete}
                onAccept={this.handleConfirmationOk}
                onCancel={this.handleConfirmationCancel}
                title="Delete Link?"
            >
                <p>
                    Deleting the{" "}
                    <strong>
                        {pendingLinkToDelete && pendingLinkToDelete.get("name")}
                    </strong>{" "}
                    link will immediately prevent access to this presentation
                    using that URL.
                </p>
            </ConfirmationDialog>
        );
    }
}

class InviteLinkItem extends LinkItem {
    state = {};

    analyticsProps(linkIds, recipientEmail) {
        const { presentation } = this.props;

        return {
            link_ids: linkIds,
            is_new: false,
            type: "email",
            sender_email: app.user.getEmail(),
            recipient_emails: [recipientEmail],
            ...presentation.getAnalytics()
        };
    }

    handleResendEmail = (linkIds, recipientEmail) => {
        const props = this.analyticsProps(linkIds, recipientEmail);
        trackActivity("Sharing", "EmailLink", null, null, props, { audit: true });

        Promise.all(linkIds.map(linkId => NotificationsService.notifyOnPresentationLinkCreated(linkId)))
            .then(() => {
                ShowSnackBar({
                    message: "Invite was resent"
                });
            })
            .catch(err => {
                logger.error(err, "[InviteLinkItem] handleResendEmail() failed", { presentationId: this.global.presentation?.id });
            });
    };

    render() {
        let { presentation, link } = this.props;
        let viewCount = link.get("views") || 0;
        let enabled =
            link.get("enabled") &&
            presentation.getPrivacySetting() != PresentationPrivacyType.PRIVATE;

        return (
            <div className={`link ${enabled ? "" : "disabled-link"}`}>
                {this.renderInviteName(link)}
                <div className="created">
                    {moment(link.get("createdAt")).format("MMM DD YYYY")}
                </div>
                <div className={`view-state ${viewCount > 0 ? "viewed" : ""}`}>
                    {viewCount} view{viewCount == 1 ? "" : "s"}
                </div>

                <div className="expires">
                    {(link.has("expiresAt") && Date.now() < new Date(link.get("expiresAt")).getTime()) &&
                        <span>
                            Expires {moment(link.get("expiresAt")).format("MMM Do YYYY [at] h:mm a")}
                        </span>
                    }
                    {(link.has("expiresAt") && Date.now() >= new Date(link.get("expiresAt")).getTime()) &&
                        <span style={{ color: "orangered" }}>
                            Expired {moment(link.get("expiresAt")).format("MMM Do YYYY [at] h:mm a")}
                        </span>
                    }
                </div>

                <FlexSpacer />

                {!enabled ? (
                    <div className="disabled-label">Disabled</div>
                ) : (
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            this.handleResendEmail(
                                [link.id],
                                link.get("email")
                            );
                        }}
                    >
                        Resend Email
                    </Button>
                )}

                {this.renderOptionsMenu([
                    <MenuItem key="edit" onClick={this.handleEditLink}>
                        Edit Link...
                    </MenuItem>,
                    <MenuItem
                        key="disable"
                        onClick={() => this.handleDisableLink("invite")}
                        divider
                    >
                        {link.get("enabled") ? "Disable Link" : "Enable Link"}
                        {
                            <ProBadge
                                show={shouldShowProBadge(FeatureType.DISABLE_LINKS, presentation.getWorkspaceId())}
                                analytics={{
                                    cta: "DisableLink", ...this.analyticsProps(
                                        [link.id],
                                        link.get("email")
                                    )
                                }}
                                workspaceId={presentation.getWorkspaceId()}
                                style={{ right: -10, position: "relative" }}
                            />
                        }
                    </MenuItem>,
                    <MenuItem key="delete" onClick={this.handleDeleteLink}>
                        Delete Link
                    </MenuItem>
                ])}
            </div>
        );
    }

    renderInviteName(link) {
        let contact = link.get("email");
        if (typeof contact == "object") {
            return (
                <Fragment>
                    <Avatar url={contact.photoUrl} />
                    <div className="name">{contact.email}</div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Avatar url="" />
                    <div className="name">{contact}</div>
                </Fragment>
            );
        }
    }
}

export class InvitesList extends Component {
    state = {};

    render() {
        const { presentation } = this.props;

        const links = _.filter(
            presentation.links.models,
            link => link.get("type") == LinkType.INVITE
        );

        if (links.length) {
            return (
                <div className="links">
                    {links.map(link => (
                        <InviteLinkItem
                            key={link.id}
                            presentation={presentation}
                            link={link}
                            onDeleteLink={this.handleDeleteLink}
                            onEditLink={this.handleEditLink}
                        />
                    ))}
                    {this.renderConfirmDeleteDialog()}
                </div>
            );
        } else {
            return null;
        }
    }

    handleEditLink = link => {
        const { presentation } = this.props;
        ShowDialog(EditInviteDialog, {
            link: link,
            presentation,
            onClose: () => this.forceUpdate(),
            callback: expiresAt => {
                link.update({ expiresAt });
                this.forceUpdate();
            }
        });
    };

    handleDeleteLink = linkId => {
        this.setState({
            showConfirmDelete: true,
            pendingLinkToDelete: linkId
        });
    };

    handleConfirmationOk = () => {
        const { presentation } = this.props;
        const { pendingLinkToDelete } = this.state;
        const props = getPropsForDeleteLink(pendingLinkToDelete, "invite", presentation);
        this.setState({ showConfirmDelete: false });
        linkDS
            .deleteLink(presentation, pendingLinkToDelete.get("id"))
            .then(() => {
                trackLinkStatusChange(props);
                this.setState({ pendingLinkToDelete: null });
            })
            .catch(err => {
                logger.error(err, "[InvitesList] handleConfirmationOk() failed", { presentationId: this.global.presentation?.id });
            });
    };

    handleConfirmationCancel = () => {
        this.setState({ showConfirmDelete: false });
    };

    renderConfirmDeleteDialog() {
        let { showConfirmDelete, pendingLinkToDelete } = this.state;

        let contact, contactName;

        if (pendingLinkToDelete) {
            contact = pendingLinkToDelete.get("email");
            contactName =
                typeof contact == "object"
                    ? contact.name || contact.email
                    : contact;
        }

        return (
            <ConfirmationDialog
                open={showConfirmDelete}
                onAccept={this.handleConfirmationOk}
                onCancel={this.handleConfirmationCancel}
                title="Revoke Invitation?"
            >
                <p>
                    Deleting the invitation to <strong>{contactName}</strong>{" "}
                    will immediately revoke their permission to view this
                    presentation.
                </p>
                <p>
                    <strong>
                        This action cannot be undone and you will need to resend
                        an invite if you want to restore permission.
                    </strong>
                </p>
            </ConfirmationDialog>
        );
    }
}

class EmbedLinkItem extends LinkItem {
    state = {};

    embedRef = React.createRef();

    copyToClipboard = () => {
        const { link } = this.props;

        clipboardWrite(
            {
                [ClipboardType.HTML]: this.embedRef.current.value,
                [ClipboardType.TEXT]: this.embedRef.current.value,
            },
            true,
        );
        ShowSnackBar({
            message: "Embed code was copied to the clipboard"
        });
        const props = this.analyticsProps(link);
        trackActivity("Sharing", "CopyLink", null, null, props, { audit: true });
    };

    analyticsProps(link) {
        return {
            link_ids: [link.get("id")],
            is_new: false,
            type: "embed_code",
            ...this.props.presentation.getAnalytics()
        };
    }

    render() {
        let { presentation, link } = this.props;

        let viewCount = link.get("views") || 0;
        let enabled =
            link.get("enabled") &&
            presentation.getPrivacySetting() == PresentationPrivacyType.PUBLIC;

        return (
            <div className={`link ${enabled ? "" : "disabled-link"}`}>
                <Icon iconName="add_to_queue" />
                <div className="name">{link.get("name")}</div>
                <div className="created">
                    {moment(link.get("createdAt")).format("MMM DD YYYY")}
                </div>
                <div className={`view-state ${viewCount > 0 ? "viewed" : ""}`}>
                    {viewCount} view{viewCount == 1 ? "" : "s"}
                </div>

                <FlexSpacer />

                <input
                    ref={this.embedRef}
                    className="link-url"
                    type="text"
                    value={presentation.getEmbedCode(link)}
                    readOnly
                />
                {!enabled ? (
                    <div className="disabled-label">Disabled</div>
                ) : (
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={this.copyToClipboard}
                    >
                        Copy Embed Code
                    </Button>
                )}
                {this.renderOptionsMenu([
                    <MenuItem key="edit" onClick={this.handleEditLink} divider>
                        Edit Embed...
                    </MenuItem>,
                    <MenuItem
                        key="disable"
                        onClick={() => this.handleDisableLink("embed_code")}
                        divider
                    >
                        {link.get("enabled") ? "Disable Embed" : "Enable Embed"}
                        {
                            <ProBadge
                                show={shouldShowProBadge(FeatureType.DISABLE_LINKS, presentation.getWorkspaceId())}
                                analytics={{ cta: "DisableLink", ...this.analyticsProps(link) }}
                                workspaceId={presentation.getWorkspaceId()}
                                style={{ right: -10, position: "relative" }}
                            />
                        }
                    </MenuItem>,
                    <MenuItem key="delete" onClick={this.handleDeleteLink}>
                        Delete Embed
                    </MenuItem>
                ])}
            </div>
        );
    }
}

export class EmbedsList extends Component {
    state = {};

    render() {
        const { presentation } = this.props;

        const links = _.filter(
            presentation.links.models,
            link => link.get("type") == LinkType.EMBED
        );

        if (links.length) {
            return (
                <Fragment>
                    <div className="links">
                        {links.map(link => (
                            <EmbedLinkItem
                                key={link.id}
                                presentation={presentation}
                                link={link}
                                onDeleteLink={this.handleDeleteLink}
                                onEditLink={this.handleEditLink}
                            />
                        ))}
                        {this.renderConfirmDeleteDialog()}
                    </div>
                </Fragment>
            );
        } else {
            return null;
        }
    }

    handleDeleteLink = linkId => {
        this.setState({
            showConfirmDelete: true,
            pendingLinkToDelete: linkId
        });
    };

    handleEditLink = link => {
        ShowDialog(EditEmbedCodeDialog, {
            link: link,
            callback: () => this.forceUpdate()
        });
    };

    handleConfirmationOk = () => {
        const { presentation } = this.props;
        const { pendingLinkToDelete } = this.state;
        const props = getPropsForDeleteLink(pendingLinkToDelete, "embed_code", presentation);
        this.setState({ showConfirmDelete: false });
        linkDS
            .deleteLink(presentation, pendingLinkToDelete.get("id"))
            .then(() => {
                trackLinkStatusChange(props);
                this.setState({ pendingLinkToDelete: null });
            })
            .catch(err => {
                logger.error(err, "[EmbedsList] handleConfirmationOk() failed", { presentationId: this.global.presentation?.id });
            });
    };

    handleConfirmationCancel = () => {
        this.setState({ showConfirmDelete: false });
    };

    renderConfirmDeleteDialog() {
        let { showConfirmDelete, pendingLinkToDelete } = this.state;

        return (
            <ConfirmationDialog
                open={showConfirmDelete}
                onAccept={this.handleConfirmationOk}
                onCancel={this.handleConfirmationCancel}
                title="Delete embed?"
            >
                <p>
                    Deleting this embed will immediately disable playback on any
                    websites, blogs, or other places the embed code has been
                    added.
                </p>
                <p>
                    <strong>This action cannot be undone.</strong>
                </p>
            </ConfirmationDialog>
        );
    }
}

class SocialLinkItem extends LinkItem {
    state = {};

    embedRef = React.createRef();

    analyticsProps() {
        return {
            type: "social",
            ...this.props.presentation.getAnalytics()
        };
    }

    copyToClipboard = () => {
        clipboardWrite({
            [ClipboardType.TEXT]: this.embedRef.current.value,
        });
        ShowSnackBar({
            message: "Embed code was copied to the clipboard"
        });
    };

    render() {
        let { presentation, link } = this.props;
        let viewCount = link.get("views") || 0;
        let enabled =
            link.get("enabled") &&
            presentation.getPrivacySetting() == PresentationPrivacyType.PUBLIC;

        return (
            <div className={`link ${enabled ? "" : "disabled-link"}`}>
                <Icon iconName="share" />
                <div className="name">{link.getLinkName()}</div>
                <div className="created">
                    {moment(link.get("createdAt")).format("MMM DD YYYY")}
                </div>
                <div className={`view-state ${viewCount > 0 ? "viewed" : ""}`}>
                    {viewCount} view{viewCount == 1 ? "" : "s"}
                </div>

                <FlexSpacer />

                <input
                    ref={this.embedRef}
                    className="link-url"
                    type="text"
                    value={link.getLinkURL()}
                    readOnly
                />
                {!enabled && <div className="disabled-label">Disabled</div>}
                {this.renderOptionsMenu([
                    <MenuItem key="edit" onClick={this.handleEditLink} divider>
                        Edit Link...
                    </MenuItem>,
                    <MenuItem
                        key="disable"
                        onClick={() =>
                            this.handleDisableLink(
                                link.getLinkName().toLowerCase()
                            )
                        }
                        divider
                    >
                        {link.get("enabled") ? "Disable Link" : "Enable Link"}
                        {
                            <ProBadge
                                show={shouldShowProBadge(FeatureType.DISABLE_LINKS, presentation.getWorkspaceId())}
                                analytics={{ cta: "DisableLink", ...this.analyticsProps() }}
                                workspaceId={presentation.getWorkspaceId()}
                                style={{ right: -10, position: "relative" }}
                            />
                        }
                    </MenuItem>,
                    <MenuItem key="delete" onClick={this.handleDeleteLink}>
                        Delete Link
                    </MenuItem>
                ])}
            </div>
        );
    }
}

export class SocialLinksList extends Component {
    state = {};

    render() {
        const { presentation } = this.props;

        let links = _.filter(
            presentation.links.models,
            link => link.get("type") == LinkType.SOCIAL
        );

        if (links.length) {
            return (
                <div className="links">
                    {links.map(link => (
                        <SocialLinkItem
                            key={link.id}
                            presentation={presentation}
                            link={link}
                            onDeleteLink={this.handleDeleteLink}
                            onEditLink={this.handleEditLink}
                        />
                    ))}
                    {this.renderConfirmDeleteDialog()}
                </div>
            );
        } else {
            return null;
        }
    }

    handleEditLink = link => {
        ShowDialog(EditSocialShareDialog, {
            link: link,
            presentation: this.props.presentation,
            onClose: () => this.forceUpdate(),
        });
    };

    handleDeleteLink = linkId => {
        this.setState({
            showConfirmDelete: true,
            pendingLinkToDelete: linkId
        });
    };

    handleConfirmationOk = () => {
        const { presentation } = this.props;
        const { pendingLinkToDelete } = this.state;
        const props = getPropsForDeleteLink(
            pendingLinkToDelete,
            pendingLinkToDelete.getLinkName(),
            presentation
        );
        this.setState({ showConfirmDelete: false });
        linkDS
            .deleteLink(presentation, pendingLinkToDelete.get("id"))
            .then(() => {
                this.setState({ pendingLinkToDelete: null });
                trackLinkStatusChange(props);
            })
            .catch(err => {
                logger.error(err, "[SocialLinksList] handleConfirmationOk() failed", { presentationId: this.global.presentation?.id });
            });
    };

    handleConfirmationCancel = () => {
        this.setState({ showConfirmDelete: false });
    };

    renderConfirmDeleteDialog() {
        let { showConfirmDelete, pendingLinkToDelete } = this.state;

        return (
            <ConfirmationDialog
                open={showConfirmDelete}
                onAccept={this.handleConfirmationOk}
                onCancel={this.handleConfirmationCancel}
                title="Delete social share link?"
            >
                <p>
                    Deleting the{" "}
                    {pendingLinkToDelete && pendingLinkToDelete.get("social")}{" "}
                    social sharing link will immediately disable playback where
                    that link was shared or reshared and cannot be undone.
                </p>
                <p>
                    Please note that deleting this link will NOT delete the
                    actual post or tweet containing the link from your social
                    network. If you wish to delete the actual post or tweet, you
                    must use the tools provided by the social network service.
                </p>
            </ConfirmationDialog>
        );
    }
}

