import firebase from "firebase/compat/app";
import React, { Component } from "reactn";
import {
    DialogContent,
    ShowErrorDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import { auth } from "js/firebase/auth";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField
} from "@material-ui/core";

export class ReauthPasswordDialog extends Component {
    constructor() {
        super();
        this.state = { password: "" };
    }

    handleChangePassword = event => {
        this.setState({ password: event.target.value });
    };

    reauthenticate = () => {
        let user = auth().currentUser;

        auth().reauthenticateWithCredential(
            user,
            firebase.auth.EmailAuthProvider.credential(
                user.email,
                this.state.password
            )
        )
            .then(() => {
                this.props.callback && this.props.callback();
                this.props.closeDialog();
            })
            .catch(err => {
                ShowErrorDialog({
                    error: "Invalid or incorrect password entered",
                    message: err.message
                });
            });
    };

    handleCancelButton = () => {
        this.props.closeDialog();
        this.props.onCancel && this.props.onCancel();
    };

    render() {
        return (
            <Dialog open={true} disableEnforceFocus={true}>
                <DialogTitle>
                    Please authenticate your account to make this change.
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Password"
                        placeholder="Enter your password"
                        type="password"
                        onChange={this.handleChangePassword}
                        fullWidth={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelButton}>Cancel</Button>
                    <Button onClick={this.reauthenticate} color="primary">
                        Reauthenticate
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
