import { ds } from "js/core/models/dataService";

export function getLibraryModelFromSlide(slideModel) {
    const orgId = slideModel.presentation.get("orgId");
    if (slideModel.presentation.getWorkspaceId() !== "personal") {
        return ds.teams.defaultTeamForOrg(orgId).libraryItems.findWhere({ contentId: slideModel.get("id") });
    }
    return null;
}

module.exports = {
    getLibraryModelFromSlide,
};
