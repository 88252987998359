import { Component } from "react";
import React from "reactn";
import { BeautifulDialog } from "./BaseDialog";
import { Key } from "js/core/utilities/keys";

export default class EditTextDialog extends Component {
    inputRef = React.createRef();

    componentDidMount() {
        // Ben: for some reason the blur event fires with the focus event
        //   and this dialog disappears, timeout seems to fix it
        setTimeout(() => {
            this.inputRef.current.focus();
            this.inputRef.current.select();
        });
    }

    render() {
        const {
            targetPt,
            target,
            minWidth = 200,
            maxWidth,
            value,
            callback,
            closeDialog,
        } = this.props;

        const saveChanges = value => {
            if (callback) {
                callback(value);
            }
            closeDialog();
        };

        const style = {
            width: 250,
            height: 50,
        };

        if (target) {
            const screenBounds = target.screenBounds.inflate(target.selectionPadding);

            // resolve the width to use
            style.width = Math.max(minWidth, screenBounds.width);
            if (!isNaN(maxWidth)) {
                style.width = Math.min(style.width, maxWidth);
            }

            // update the position
            style.left = screenBounds.centerH - style.width / 2;
            style.top = screenBounds.centerV - style.height / 2;
        } else {
            style.left = targetPt.x - style.width / 2;
            style.top = targetPt.y;
        }

        return (
            <BeautifulDialog
                style={style}
                PaperProps={{
                    style: {
                        margin: "0",
                        width: "100%",
                        height: "100%",
                        maxHeight: "none",
                    },
                }}
            >
                <input
                    ref={this.inputRef}
                    id="input-edit-text"
                    type="text"
                    style={{
                        width: "100%",
                        height: "100%",
                        fontSize: "2rem",
                        boxSizing: "border-box",
                        textAlign: "center",
                    }}
                    defaultValue={value}
                    onBlur={ev => {
                        ev.stopPropagation();
                        saveChanges(ev.target.value);
                    }}
                    onKeyDown={ev => {
                        if (ev.which === Key.ENTER || ev.which === Key.TAB) {
                            saveChanges(ev.target.value);
                        } else if (ev.which === Key.ESCAPE) {
                            closeDialog();
                        }
                    }}
                />
            </BeautifulDialog>
        );
    }
}
