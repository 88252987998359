import React from "reactn";
import { AuthoringElementType, HeaderPositionType } from "legacy-common/constants";
import { ds } from "js/core/models/dataService";
import { $ } from "legacy-js/vendor";
import { reactMount, reactUnmount } from "legacy-js/react/renderReactRoot";

import { ElementSelection } from "../BaseElementEditor";
import { AuthoringLayer } from "../authoring/AuthoringLayer";

const AuthoringCanvasSelection = ElementSelection.extend({
    renderControls: function() {
        // We only want to render the authoring layer when there are no child elements selected
        if (ds.selection.element && ds.selection.element.isChildOf(this.element)) {
            return;
        }

        this.renderAuthoringLayer();
    },

    renderAuthoringLayer: function() {
        this.authoringRef = React.createRef();

        reactMount(
            <AuthoringLayer
                ref={this.authoringRef}
                containerElement={this.element}
            />,
            $("#selection_layer")[0]
        );

        this.hasAuthoringLayer = true;
    },

    cleanUp: function() {
        if (this.hasAuthoringLayer) {
            reactUnmount($("#selection_layer")[0]);
        }
    }
});

function AuthoringCanvasAddContentMenu(authoringCanvas, menu) {
    return [{
        title: "Header",
        options: [{
            icon: "header_top",
            label: "Top Header",
            enabled: true,
            selected: authoringCanvas.itemElements.some(item => item.model.type === AuthoringElementType.HEADER),
            callback: () => {
                const header = authoringCanvas.itemElements.find(item => item.model.type === AuthoringElementType.HEADER);
                if (header) {
                    // store the deleted header data in the elements.header model so we can restore if needed
                    authoringCanvas.canvas.model.elements.header = {
                        label: header.model.label,
                        title: header.model.title,
                        description: header.model.description
                    };
                    authoringCanvas.deleteItem(header.id);
                } else {
                    const model = {
                        type: AuthoringElementType.HEADER,
                        x: 0,
                        y: 0,
                        width: authoringCanvas.calculatedProps.bounds.width,
                        height: authoringCanvas.calculatedProps.bounds.height,
                        headerPosition: HeaderPositionType.TOP,
                        label: authoringCanvas.canvas.model.elements.header?.label,
                        title: authoringCanvas.canvas.model.elements.header?.title,
                        description: authoringCanvas.canvas.model.elements.header?.description
                    };
                    authoringCanvas.addItem(model);
                }
                authoringCanvas.canvas.updateCanvasModel(false);
            }
        }]
    },
    {
        title: "Footer",
        options: [{
            icon: "footer",
            label: "Footer",
            enabled: true,
            selected: authoringCanvas.itemElements.some(item => item.model.type === AuthoringElementType.FOOTER),
            callback: () => {
                const footer = authoringCanvas.itemElements.find(item => item.model.type === AuthoringElementType.FOOTER);
                if (footer) {
                    authoringCanvas.deleteItem(footer.id);
                } else {
                    const model = {
                        type: AuthoringElementType.FOOTER,
                        x: 0,
                        y: authoringCanvas.calculatedProps.bounds.height - authoringCanvas.canvas.styleSheet.Footer.height,
                        width: authoringCanvas.calculatedProps.bounds.width,
                        height: authoringCanvas.canvas.styleSheet.Footer.height
                    };
                    authoringCanvas.addItem(model);
                }
                authoringCanvas.canvas.updateCanvasModel(false);
            }
        }]
    }];
}

export const editors = {
    AuthoringCanvasSelection,
    AuthoringCanvasAddContentMenu
};
