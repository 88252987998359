import * as geom from "js/core/utilities/geom";
import { AssetType, HorizontalAlignType } from "legacy-common/constants";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { ContentElement } from "../base/ContentElement";
import { TextGroup } from "../base/TextGroup";

class Team extends CollectionElement {
    getChildItemType() {
        return TeamItem;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" }
        };
    }

    get maxItemCount() {
        return 8;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size);

        this.updateStyles(this.styles.vertical, true);

        layouter.calcRowLayout({ itemOptions: { orientation: "vertical" } });

        if (!layouter.isFit) {
            this.updateStyles(this.styles.horizontal, true);
            layouter.calcRowLayout({ itemOptions: { orientation: "horizontal" } });
        }

        if (!layouter.isFit) {
            layouter.calcRowLayout({
                minItemHeight: 100,
                itemOptions: { orientation: "horizontal" }
            });
        }

        if (!layouter.isFit) {
            this.updateStyles(this.styles.small, true);
            const layouterOptions = {
                minItemHeight: 50,
                itemOptions: { orientation: "auto" }
            };
            if (this.itemElements.length > 8) {
                layouter.calcColumnLayout(layouterOptions);
            } else {
                layouter.calcRowLayout(layouterOptions);
            }
        }

        layouter.alignVertically();

        props.isFit = layouter.isFit;

        return { size: layouter.size };
    }
}

class TeamItem extends CollectionItemElement {
    get name() {
        return "Team Member";
    }

    _build() {
        this.content = this.addElement("content", () => ContentElement, {
            defaultAssetType: AssetType.IMAGE
        });
        this.text = this.addElement("text", () => TextGroup, {
            autoHeight: true,
            title: {
                singleLine: true
            }
        });
    }

    _calcProps(props, options) {
        let { size } = props;
        let layouter = this.getLayouter(props, [this.content, this.text], size);

        if (options.orientation == "vertical") {
            layouter.calcVerticalBlockLayout({
                contentSize: new geom.Size(this.styles.content.width, this.styles.content.height),
                horizontalAlign: layouter.HorizontalAlignType.CENTER
            });
        } else {
            layouter.calcHorizontalBlockLayout({
                verticalAlign: layouter.VerticalBlockAlignType.MIDDLE_OR_TOP
            });
        }
        layouter.alignHorizontally(HorizontalAlignType.CENTER);

        props.isFit = layouter.isFit;

        return { size: layouter.size };
    }
}

export const elements = {
    Team,
};
