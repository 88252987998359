import { ds } from "js/core/models/dataService";
import { controls, parseAsDuration } from "legacy-js/editor/ui";
import { _, $, numeral } from "legacy-js/vendor";
import EditTextDialog from "legacy-js/react/components/Dialogs/EditTextDialog";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { prefixZeros } from "js/core/utilities/utilities";

import { ElementSelection } from "../BaseElementEditor";

// maximum length for a timer
const MAXIMUM_TIMER_LENGTH_IN_SECONDS = 120 * 60;
const MINIMUM_EMPHASIS_TIME_DIFFERENCE_IN_SECONDS = 10;
const MINIMUM_TIMER_LENGTH_IN_SECONDS = 15;

const TimerSelection = ElementSelection.extend({

    createEmphasisControls($menu) {
        let $row = $.div("control timer_emphasis_settings");
        const value = secondsToTimeDisplay(this.element.model.emphasize);

        // add a time selector
        const $duration = controls.createTimePicker($menu, {
            type: "duration",
            label: "Emphasize remaining time",
            value,
            callback: seconds => {
                if (seconds) {
                    setModel(this.element.model, { emphasize: seconds });
                    this.element.canvas.updateCanvasModel();
                }
            }
        });

        $row.append($duration);

        return $row;
    },

    createAdvancementControls($menu) {
        let $row = $.div();

        const $toggle = controls.createToggle($menu, {
            label: "Advance slide when complete",
            labelAtEnd: true,
            model: this.model,
            property: "autoAdvanceSlide"
        });

        $row.append($toggle);

        return $row;
    },

    createTimePickerMenu() {
        let $menu = $.div("timer-menu");
        const value = secondsToTimeDisplay(this.element.model.duration);

        // create the row of controls
        const $row = $.div("control timer_editor_row as_timepicker");

        // add a time selector
        const $duration = controls.createTimePicker($menu, {
            type: "duration",
            label: "Timer duration",
            value,
            callback: seconds => {
                if (seconds) {
                    setModel(this.element.model, { duration: seconds });
                    this.element.canvas.updateCanvasModel();
                }
            }
        });

        // assemble
        $row.append($duration);
        $menu.append($row);

        // return the menu
        return $menu;
    },

    renderControls: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Set Duration",
            icon: "timer",
            menuContents: (...args) => this.createTimePickerMenu.apply(this, args)
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "settings",
            showArrow: false,
            menuContents: closeMenu => {
                let $menu = $.div();
                let $emphasis = this.createEmphasisControls.call(this, $menu);
                let $advancement = this.createAdvancementControls.call(this, $menu);

                $menu.append($emphasis);
                $menu.append($advancement);

                return $menu;
            }
        });
    },
});

const TimerLabelSelection = ElementSelection.extend({
    showSelectionBox: false,

    setup: function() {
        const timer = this.element.getParentOfType("Timer");

        // center the editor over the time display
        // TODO: is there a better way to determine this
        const { left: x, top: y, width } = timer.elements.time.screenBounds;
        const targetPt = { x: x + (width / 2), y: y - 15 };

        ShowDialog(EditTextDialog, {
            targetPt,
            maxWidth: 150,
            value: secondsToTimeDisplay(timer.model.duration),
            callback: value => {
                const { seconds, minutes } = parseAsDuration(value);
                const duration = seconds + (minutes * 60);

                // requires a valid number and some length
                if (numeral.validateEx(duration) && duration > 0) {
                    setModel(timer.model, { duration });

                    // save changes
                    this.canvas.saveCanvasModel();

                    // force the clock to reset
                    timer.resetTimer();
                }

                // clear selection
                ds.selection.element = null;
            },
        });
    },

    onMouseDown: function() {
        ds.selection.selection = null;
    },

});

// handles keeping model values correct
function setModel(model, { duration = model.duration, emphasize = model.emphasize }) {
    model.duration = duration;
    model.emphasize = emphasize;

    // ensure ranges
    model.duration = Math.max(MINIMUM_TIMER_LENGTH_IN_SECONDS, Math.min(model.duration, MAXIMUM_TIMER_LENGTH_IN_SECONDS));
    model.emphasize = Math.min(model.duration - MINIMUM_EMPHASIS_TIME_DIFFERENCE_IN_SECONDS, Math.max(0, model.emphasize));
}

// quick time format
function secondsToTimeDisplay(seconds) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes}:${prefixZeros(seconds % 60, 2)}`;
}

export const editors = {
    TimerLabelSelection,
    TimerSelection
};
