import React, { Component } from "reactn";

const IconButton = React.forwardRef(function IconButton(props, ref) {
    return (
        <div ref={ref} id={props.id} className="icon-button" onClick={props.onClick}>
            <i className="micon">
                {props.icon}
            </i>
        </div>
    );
});

export default IconButton;
