import { app } from "js/namespaces.js";
import { controls } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { TrayType, TrayElementType } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { _, $ } from "legacy-js/vendor";
import { ds } from "js/core/models/dataService";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";

import { CreatePhotoCollageControls } from "./PhotoCollageEditor";
import { ElementDefaultOverlay, ElementSelection } from "../BaseElementEditor";

const TrayContainerDefaultOverlay = ElementDefaultOverlay.extend({
    className: "default_overlay trayContainerDefaultOverlay showFrame",

    render: function() {
        let availableElementTypes = this.element.canvas.slideTemplate.availableTrayElements;

        let $options = this.$el.addEl($.div("options"));

        if (availableElementTypes.length > 1) {
            $options.addEl($.div("label", "Add Content"));
        }

        availableElementTypes.contains(TrayElementType.IMAGE) && $options.append(controls.createButton(this, {
            icon: "add_a_photo",
            className: "large_icon",
            label: "Images",
            callback: () => {
                ShowDialog(AddAssetsContainer, {
                    workspaceId: ds.selection.presentation.getWorkspaceId(),
                    callback: assetModel => {
                        this.element.model.aspectRatio = "fill";
                        this.element.model.frame = "none";
                        this.element.model.showShadow = false;
                        this.element.model.showGutter = false;
                        this.element.model.trayElement = TrayElementType.IMAGE;
                        this.element.model.items = [assetModel];
                        this.element.canvas.updateCanvasModel(false);
                    },
                });
            }
        }));

        availableElementTypes.contains(TrayElementType.TEXT) && $options.append(controls.createButton(this, {
            icon: "text_fields",
            className: "large_icon",
            label: "Text",
            callback: () => {
                this.element.model.trayElement = TrayElementType.TEXT;
                this.element.model.trayColor = "theme";
                this.element.model.tray = {
                    textStyle: "headline",
                };
                this.element.canvas.updateCanvasModel(false);
            }
        }));

        availableElementTypes.contains(TrayElementType.BULLET_LIST) && $options.append(controls.createButton(this, {
            icon: "format_list_bulleted",
            className: "large_icon",
            label: "List",
            callback: () => {
                this.element.model.trayElement = TrayElementType.TEXT;
                this.element.model.trayColor = "theme";
                this.element.model.textStyle = "bullet_list";
                this.element.model.listStyle = "bullets";
                this.element.canvas.updateCanvasModel(false);
            }
        }));

        return this;
    }
});

const TrayContainerSelection = ElementSelection.extend({
    showSelectionBox: true,
    captureMouseEvents: false,

    getOffset: function() {
        let selectedCell = ds.selection.element.findClosestOfType("PhotoCollageItem");
        if (selectedCell && selectedCell.canvasBounds.bottom > this.element.canvasBounds.bottom - 50 && selectedCell.showText && selectedCell.contentBlocks.contentBlockFrame.bounds.bottom > selectedCell.bounds.height - 50) {
            return 50;
        }
        return 20;
    },

    renderControls: function() {
        this.renderTrayImageControls();
        this.renderTrayResizer();
    },

    renderTrayLayoutOptions: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Layout",
            menuContents: closeMenu => {
                let $menu = $.div("styles_menu");

                let textElementLayouts = this.element.canvas.layouter.getElementLayoutOptions(TrayElementType.TEXT);
                let items = [];
                _.find(textElementLayouts, { type: "tray-left" }) && $menu.append(this.createStylesThumbnail(null, getStaticUrl(`/images/ui/layouts/tray-left.png`), TrayType.LEFT_TRAY));
                _.find(textElementLayouts, { type: "tray-right" }) && $menu.append(this.createStylesThumbnail(null, getStaticUrl(`/images/ui/layouts/tray-right.png`), TrayType.RIGHT_TRAY));
                _.find(textElementLayouts, { type: "inline-left" }) && $menu.append(this.createStylesThumbnail(null, getStaticUrl(`/images/ui/layouts/inline-left.png`), TrayType.LEFT_INLINE));
                _.find(textElementLayouts, { type: "inline-right" }) && $menu.append(this.createStylesThumbnail(null, getStaticUrl(`/images/ui/layouts/inline-right.png`), TrayType.RIGHT_INLINE));
                _.find(textElementLayouts, { type: "background" }) && $menu.append(this.createStylesThumbnail(null, getStaticUrl(`/images/ui/layouts/background.png`), TrayType.BACKGROUND));

                $menu.on("click", ".styles_thumbnail", event => {
                    let value = $(event.currentTarget).attr("data-value");
                    this.element.canvas.model.layout.trayLayout = value;

                    if (this.element.model.trayElement == TrayElementType.IMAGE) {
                        let trayModel = this.element.model;
                        let canvas = this.element.canvas;

                        // when switching a fit aspect ratio photo collage to another tray layout, resize the traywidth to match the calculated size of the photocollage
                        if (this.element.trayElement.model.aspectRatio == "fit") {
                            if (value.equalsAnyOf(TrayType.RIGHT_TRAY, TrayType.LEFT_TRAY, TrayType.BACKGROUND)) {
                                let fitSize = this.element.trayElement.calcSize(new geom.Size(canvas.CANVAS_WIDTH * 2 / 3, canvas.CANVAS_HEIGHT), {
                                    aspectRatio: "fit",
                                    layoutType: "vertical",
                                });
                                if (fitSize.height == canvas.CANVAS_HEIGHT) {
                                    trayModel.trayWidth = Math.min(fitSize.width, canvas.CANVAS_WIDTH * 2 / 3);
                                } else {
                                    // this is a special case because we don't allow tray layouts where fitting the aspect ratio of the image(s) would result in space above/below the image
                                    // ie. the image aspect ratio is too wide for our available tray width
                                    this.element.trayElement.model.aspectRatio = "fill";
                                }
                            } else {
                                let fitSize = this.element.trayElement.calcSize(new geom.Size(canvas.CANVAS_WIDTH * 2 / 3, canvas.layouter.elements.primary.bounds.height), {
                                    aspectRatio: "fit",
                                    layoutType: "vertical",
                                });
                                trayModel.trayWidth = Math.min(fitSize.width, canvas.CANVAS_WIDTH / 2);
                            }
                            if (this.element.model.showGutter) {
                                this.element.model.trayWidth += this.element.gutterSize * 2;
                            }
                        }
                    }

                    this.element.canvas.updateCanvasModel(true);
                });
                return $menu;
            }
        });
    },

    renderTrayResizer: function() {
        // fit in tray is not resizable
        // if (this.element.isTray && this.element.model.aspectRatio == "fit"){
        //     return;
        // }

        this.$trayResizer = this.$widgets.addEl($.div("ui_widget"));
        this.$trayResizer.append($.div("tray_resizer"));

        let constrainDrag;

        // calculate the maxWidth by directly asking the trayElement - if we ask the tray, it will use the current trayWidth
        let maxTrayWidth = this.element.canvas.layouter.canvasElement.maxTrayWidth;
        let minTrayWidth = this.element.canvas.layouter.canvasElement.minTrayWidth;
        let maxTrayHeight = this.element.canvas.layouter.canvasElement.maxTrayHeight;
        let minTrayHeight = this.element.canvas.layouter.canvasElement.minTrayHeight;

        // if this is an inline image tray with a fit aspect ratio, limit trayWidth to fit the image
        if (this.element.isInline && this.element.trayElementType == TrayElementType.IMAGE) {
            //TODO
            // const size = this.element.trayElement.calculatedSize;
            // if (!this.element.fitSize || this.element.canvasBounds.height !== this.element.fitSizeHeight) {
            //     // store fitSize on element to keep state, skip these element calcs if inputs are the same
            //     this.element.fitSize = this.element.trayElement.calcSize(new geom.Size(1280, this.element.canvasBounds.height));
            //     this.element.fitSizeHeight = this.element.canvasBounds.height;
            //     //revert back
            //     this.element.trayElement.calcSize(size);
            // }
            // maxTrayWidth = Math.min(maxTrayWidth, this.element.fitSize.width);
        }

        let dragHandleOffset = 10;
        let direction;

        switch (this.element.trayLayout) {
            case TrayType.LEFT_TRAY:
            case TrayType.LEFT_INLINE:
                this.$trayResizer.right(10);
                constrainDrag = new geom.Rect(this.element.canvasBounds.left + minTrayWidth, 0, maxTrayWidth - minTrayWidth - dragHandleOffset, this.element.canvas.CANVAS_HEIGHT);
                direction = "horizontal";
                break;
            case TrayType.RIGHT_INLINE:
            case TrayType.RIGHT_TRAY:
                this.$trayResizer.left(-10);
                constrainDrag = new geom.Rect(this.element.canvasBounds.right - maxTrayWidth - dragHandleOffset, 0, maxTrayWidth - minTrayWidth, this.element.canvas.CANVAS_HEIGHT);
                direction = "horizontal";
                break;
        }

        let refresh = value => {
            if (direction == "vertical") {
                this.element.model.trayHeight = value;
            } else {
                this.element.model.trayWidth = value;
            }

            this.element.canvas.refreshCanvas()
                .then(() => {
                    this.lastTrayWidth = value;
                }).catch(err => {
                    this.element.model.trayWidth = this.lastTrayWidth;
                    this.element.canvas.refreshCanvasAutoRevert();
                });
        };

        this.$trayResizer.makeDraggable({
            axis: direction == "vertical" ? "y" : "x",
            constrainDrag: constrainDrag,
            start: event => {
                app.isTrayDragging = true;
                app.mainView.editorView.selectionLayer.hideWidgets(this.$trayResizer);
            },
            drag: (event, position) => {
                if (this.element.canvas.layouter.isGenerating) {
                    return;
                }

                let traySize;
                switch (this.element.trayLayout) {
                    case TrayType.LEFT_TRAY:
                    case TrayType.LEFT_INLINE:
                        traySize = position.canvasPosition.x + dragHandleOffset - this.element.canvasBounds.left;
                        break;
                    case TrayType.RIGHT_TRAY:
                    case TrayType.RIGHT_INLINE:
                        traySize = this.element.canvasBounds.right - position.canvasPosition.x - dragHandleOffset;
                        break;
                }
                refresh(traySize);
            },
            stop: event => {
                event.stopPropagation();
                app.isTrayDragging = false;
                if (this.element.canvas.layouter.canvasElement.elements.primary.updatesOwnModelAfterResize) {
                    this.element.canvas.refreshCanvas();
                } else {
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        app.mainView.editorView.selectionLayer.showWidgets();
                    });
                }
            }
        });
    },

    _layout: function(bounds) {
        this.$trayResizer.top(bounds.height / 2 - 10);
    },

    renderTrayImageControls: function() {
        if (this.element.trayElement.model.scale > 1.5) {
            this.showWarning("Warning", "Image is scaled beyond 1.5X and may appear pixelated");
        }

        CreatePhotoCollageControls(this, this.element.trayElement, {
            addItemLabel: "Add Cell"
        });
    },

});

export const editors = {
    TrayContainerDefaultOverlay,
    TrayContainerSelection,
};
