import React, { Component } from "reactn";
import styled from "styled-components";
import { LinearProgress, MenuItem, Icon, Button, Divider, Popover } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";

import { app } from "js/namespaces";
import getDefaultFontWeight from "legacy-common/utils/getDefaultFontWeight";
import { FeatureType } from "js/core/models/features";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { UploadFontModal } from "legacy-js/editor/ThemeEditor/components/Font/UploadFontModal";
import { NestedMenuItem } from "legacy-js/react/components/NestedMenuItem";
import { GridBox } from "legacy-js/react/components/LayoutGrid";

const FontMapContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span:nth-child(1) {
        width: 40%;
    }
    > div {
        width: 40%;
        height: 32px;
    }
    > div > div:focus {
        background: none;
    }
`;

const LinearProgressContainer = styled.div`
    width: 100%;
    height: 40px;
    padding-top: 18px;
`;

const FontSelectFrame = styled.div`
    border: 1px solid #dadddf;
    cursor: pointer;

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;

    &:hover {
        border: 1px solid #555;
    }

    >div,>li {
        pointer-events: none;
    }   
`;

const FontMenuItem = styled(MenuItem)`
    &&& {
        display: flex;
    }
    img {
        height: 20px;
        pointer-events: none;
    }
    span {
        color: rgb(51, 51, 51);
    }
`;

const FontMenuItemWithDivider = styled(MenuItem)`
    &&& {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    span {
        color: rgb(51, 51, 51);
    }
`;

const ThemeFontIcon = styled(Icon)`
    &&& {
        font-size: 20px;
        color: rgb(51, 51, 51);
        margin-right: 5px;
    }
`;

const UploadFontButton = styled(Button)`
    width: 100%;
    height: 45px;
`;

export class FontMap extends Component {
    constructor() {
        super();

        this.state = {
            isPopupOpen: false
        };

        this.isFontMappedManually = false;

        this.fontSelectFrameRef = React.createRef();
    }

    get normalizedFontFamily() {
        const { sourceFontFamily } = this.props;
        return sourceFontFamily.split(",")[0].trim();
    }

    componentDidMount() {
        this.autoMapFont();
    }

    componentDidUpdate() {
        this.autoMapFont();
    }

    findMatchingFont(fontFamily) {
        const { customFonts, builtInFonts } = this.props;

        fontFamily = fontFamily.toLowerCase();

        let selectedFont = customFonts.find(font => font.label.toLowerCase() === fontFamily);
        if (!selectedFont) {
            selectedFont = builtInFonts.find(font => font.label.toLowerCase() === fontFamily);
        }
        if (!selectedFont) {
            selectedFont = customFonts.find(font => fontFamily.startsWith(font.label.toLowerCase()));
        }
        if (!selectedFont) {
            selectedFont = builtInFonts.find(font => fontFamily.startsWith(font.label.toLowerCase()));
        }

        return selectedFont;
    }

    autoMapFont() {
        const { onFontMapped, selectedFontId } = this.props;

        if (!this.isFontMappedManually) {
            let selectedFont = this.findMatchingFont(this.normalizedFontFamily);
            if (!selectedFont && this.normalizedFontFamily.toLowerCase().includes("calibri")) {
                selectedFont = this.findMatchingFont(this.normalizedFontFamily.toLowerCase().replace("calibri", "carlito"));
            }

            const defaultWeight = getDefaultFontWeight(this.normalizedFontFamily);
            if (selectedFont && selectedFont.id !== selectedFontId) {
                onFontMapped(selectedFont.id, selectedFont.getStyle(defaultWeight, false).weight);
            }
        }
    }

    handleUploadCustomFont = () => {
        const {
            onFontMapped,
            onFontUploaded,
        } = this.props;

        this.setState({ isPopupOpen: false });

        ShowDialog(UploadFontModal, {
            onSetCustomFont: async (fontId, fontWeight) => {
                this.isFontMappedManually = true;
                onFontUploaded(fontId, fontWeight);
                onFontMapped(fontId, fontWeight);
            }
        });
    }

    handleSelectFontStyle = (fontId, fontWeight) => {
        const { onFontMapped } = this.props;

        this.setState({ isPopupOpen: false });

        this.isFontMappedManually = true;
        onFontMapped(
            fontId === "__theme" ? null : fontId,
            fontId === "__theme" ? null : fontWeight
        );
    }

    render() {
        const {
            fetching,
            customFonts,
            builtInFonts,
            selectedFontId,
            selectedFontWeight,
            workspaceId,
            showThemeFont = true,
            defaultSelectedFontId = "__theme",
            defaultSelectedFontWeight = 400
        } = this.props;
        const { isPopupOpen } = this.state;

        if (fetching) {
            return <LinearProgressContainer><LinearProgress /></LinearProgressContainer>;
        }

        const canUploadCustomFonts = app.user.features.isFeatureEnabled(FeatureType.CUSTOM_FONTS, workspaceId);

        const fontId = selectedFontId ?? defaultSelectedFontId;
        const fontWeight = selectedFontWeight ?? defaultSelectedFontWeight;

        let CurrentFont = null;
        if (fontId === "__theme") {
            CurrentFont = <FontMenuItem value="__theme"><ThemeFontIcon color="primary">auto_awesome</ThemeFontIcon><span>Theme font</span></FontMenuItem>;
        } else {
            const font = customFonts.find(font => font.id === fontId) ?? builtInFonts.find(font => font.id === fontId);
            if (font) {
                const fontStyle = font.getStyle(fontWeight, false);
                CurrentFont = (<FontMenuItem>
                    <img
                        height={24}
                        src={fontStyle.imageData}
                    />
                </FontMenuItem>);
            }
        }

        return (<FontMapContainer>
            <span><b>{this.normalizedFontFamily}</b></span>
            <span>⟶</span>

            <FontSelectFrame onClick={() => this.setState({ isPopupOpen: true })} ref={this.fontSelectFrameRef}>
                {CurrentFont}
                <ArrowDropDownIcon style={{ color: "#555" }} />
            </FontSelectFrame>

            <Popover
                open={isPopupOpen}
                anchorEl={this.fontSelectFrameRef.current}
                onClose={() => this.setState({ isPopupOpen: false })}
            >
                <GridBox rows gap={5}>
                    {showThemeFont &&
                        <FontMenuItem value="__theme" onMouseDown={() => this.handleSelectFontStyle("__theme", null)}>
                            <ThemeFontIcon color="primary">auto_awesome</ThemeFontIcon>
                            <span>Theme font</span>
                        </FontMenuItem>
                    }

                    {customFonts.length > 0 && <FontMenuItemWithDivider disabled={true} value={"__custom"}><span>Uploaded fonts</span></FontMenuItemWithDivider>}
                    {customFonts.length > 0 && customFonts.sort(font => font.label).map(font => (
                        <NestedMenuItem
                            key={font.id}
                            contents={<img src={font.imageData} height={24} />}>
                            {font.styles.filter(fontStyle => !fontStyle.italic).map((fontStyle, index) => (
                                <FontMenuItem
                                    key={index}
                                    onMouseDown={() => this.handleSelectFontStyle(font.id, fontStyle.weight)}
                                >
                                    <img
                                        height={24}
                                        src={fontStyle.imageData}
                                    />
                                </FontMenuItem>
                            ))}
                        </NestedMenuItem>
                    ))}

                    {builtInFonts.length > 0 && <FontMenuItemWithDivider disabled={true} value={"__builtIn"}><span>Built-in fonts</span></FontMenuItemWithDivider>}
                    {builtInFonts.length > 0 && builtInFonts.sort(font => font.label).map(font => (
                        <NestedMenuItem
                            key={font.id}
                            contents={<img src={font.imageData} height={24} />}>
                            {font.styles.filter(fontStyle => !fontStyle.italic).map((fontStyle, index) => (
                                <FontMenuItem
                                    key={index}
                                    onMouseDown={() => this.handleSelectFontStyle(font.id, fontStyle.weight)}
                                >
                                    <img
                                        height={24}
                                        src={fontStyle.imageData}
                                    />
                                </FontMenuItem>
                            ))}
                        </NestedMenuItem>
                    ))}

                    {canUploadCustomFonts && <Divider />}
                    {canUploadCustomFonts &&
                        <UploadFontButton onMouseDown={this.handleUploadCustomFont}>
                            <Icon color="primary">
                                cloud_upload
                            </Icon>
                            Upload font
                        </UploadFontButton>
                    }
                </GridBox>
            </Popover>
        </FontMapContainer >);
    }
}
