import * as geom from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";

function calcStraightConnectorLayout({ item, index, points, styles, correctedCenter, show_arrows, pointing_in, circle_radius, start_angle }) {
    item.calcConnector = calcConnector;
    item.connectorFits = true;

    function calcConnector(fraction = 1) {
        const fullDistance = correctedCenter.distance(points[index]);
        let point1 = points[index].lerp(correctedCenter, Math.clamp((item.styles.ContentElement.width / 2) / fullDistance, 0, 1));

        //force the start point to not consider any ellipse stretching.
        const angle = start_angle + Math.PI * 2 * index / this.itemCount;
        // Note - we flip the y values in our computations.
        let point2 = new geom.Point(Math.cos(angle) * circle_radius, -Math.sin(angle) * circle_radius);
        point2 = point2.plus(correctedCenter);

        let [linePath, arrowPath] = Shape.drawCurve([point1, point2], fraction, {
            reverse: !pointing_in,
            startOffset: styles.arrowArcs.startOffset,
            endOffset: styles.arrowArcs.endOffset,
            showArrow: show_arrows,
            arrowOffset: styles.arrowHeads.offset,
            arrowLength: styles.arrowHeads.arrowLength,
            arrowSpread: styles.arrowHeads.spread
        });

        return { linePath, arrowPath };
    }
}

function calcArcConnectorLayout({ shape, angles, items, index, styles, show_arrows }) {
    let itemCount = items.length;
    let item = items[index];

    let angle = angles[index];

    // create arc data
    let nextIndex = (index + 1) % angles.length;
    let nextItem = items[nextIndex];
    let nextAngle = angles[nextIndex];
    if (nextAngle < angle + 0.001) nextAngle += 2 * Math.PI;
    let arc = shape.arc(angle, nextAngle);

    let topLeft = shape.point(angle).minus(item.styles.content.width / 2, item.styles.content.width / 2);
    let nextTopLeft = shape.point(nextAngle).minus(item.styles.content.width / 2, item.styles.content.width / 2);
    if (item) arc = arc.removeShapeByHit(p => item.content.hitCheck(p.minus(topLeft)));
    if (nextItem) arc = arc.removeShapeByHit(p => nextItem.content.hitCheck(p.minus(nextTopLeft)));

    if (arc.start >= arc.end) {
        item.connectorFits = false;
    } else {
        item.connectorFits = true;
    }

    item.calcConnector = calcConnector.bind(null, item);

    function calcConnector(item, fraction = 1) {
        let index = item.itemIndex;

        let startOffset = styles.arrowArcs.startOffset || 0;
        let endOffset = styles.arrowArcs.endOffset || 0;
        let arrowOffset = styles.arrowHeads.offset || 0;
        let arrowLen = styles.arrowHeads.arrowLength || 12;

        if (show_arrows) {
            endOffset += arrowLen / 2;
        }

        let distance = arc.distance() * fraction;

        if (String(endOffset).match(/%$/)) {
            endOffset = parseFloat(endOffset) * distance / 100;
        }
        if (String(startOffset).match(/%$/)) {
            startOffset = parseFloat(startOffset) * distance / 100;
        }
        if (String(arrowOffset).match(/%$/)) {
            arrowOffset = parseFloat(arrowOffset) * distance / 100;
            arrowOffset = Math.clamp(arrowOffset, startOffset, distance - endOffset);
        }
        if (arrowOffset === 0) {
            endOffset += 2;
        }
        if (itemCount === 1 && index === 0) {
            endOffset = 0;
        }

        return {
            linePath: calcConnectorLine({ startOffset, distance, endOffset }),
            arrowPath: show_arrows && calcConnectorArrow({ distance, arrowOffset, arrowLen, index, fraction })
        };
    }

    function calcConnectorLine({ startOffset, distance, endOffset }) {
        let leftAngle = arc.angleAtDistance(startOffset);
        let rightAngle = arc.angleAtDistance(Math.max(distance - endOffset, startOffset));
        return arc.shape.arc(leftAngle, rightAngle).toPath();
    }

    function calcConnectorArrow({ distance, arrowOffset, arrowLen, index, fraction }) {
        let arrowSpread = styles.arrowHeads.spread || 0.4;

        let arrowEndAngle = arc.angleAtDistance(distance - arrowOffset);
        let arrowStartAngle = arc.angleAtDistance(distance - arrowOffset - arrowLen);
        if (itemCount === 1) {
            arrowEndAngle = index === 0 ? -Math.PI / 2 : Math.PI / 2;
            while (arrowEndAngle < arc.start) arrowEndAngle += 2 * Math.PI;
            arrowEndAngle = arc.start + fraction * (arrowEndAngle - arc.start);
            let arrowArc = arc.shape.arc(arc.start, arrowEndAngle);
            arrowStartAngle = arrowArc.angleAtDistance(arrowArc.distance() - arrowLen);
        }

        let ap1 = arc.shape.point(arrowStartAngle);
        let ap2 = arc.shape.point(arrowEndAngle);

        let arrowHeading = ap2.minus(ap1).angle();

        let arrowStart = ["M", ap2.x, ap2.y].join(" ");
        let arrowLeft = ["L",
            ap2.x - arrowLen * Math.cos(arrowHeading - arrowSpread),
            ap2.y - arrowLen * Math.sin(arrowHeading - arrowSpread)].join(" ");
        let arrowRight = ["L",
            ap2.x - arrowLen * Math.cos(arrowHeading + arrowSpread),
            ap2.y - arrowLen * Math.sin(arrowHeading + arrowSpread)].join(" ");
        let arrowBack = ["L", ap2.x, ap2.y].join(" ");
        return [arrowStart, arrowLeft, arrowRight, arrowBack].join(" ");
    }
}

export { calcArcConnectorLayout, calcStraightConnectorLayout };
