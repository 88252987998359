import * as geom from "js/core/utilities/geom";

export function BestFitTextToHeight(textElement, textOptions, height, minWidth, maxWidth) {
    let { fitValue } = binarySearch({ min: minWidth,
        max: maxWidth,
        layout: value => {
            let textProps = textElement.calcProps(new geom.Size(value, height), textOptions);
            return {
                isFit: textProps.isTextFit
            };
        } });

    return new geom.Size(fitValue, height);
}

export function binarySearch({ min, max, layout, lastValue, props }) {
    let value = Math.ceil((min + max) / 2);

    if (value === lastValue) {
        return props;
    }
    lastValue = value;

    let newProps = layout(value);
    newProps.fitValue = value;

    if (newProps.isFit) {
        return binarySearch({ min, max: value, layout, lastValue, props: newProps });
    } else {
        return binarySearch({ min: value, max, layout, lastValue, props: newProps });
    }
}

export function FindBestFit({ min, max, precision = 20, layout, preCheckMin = false, preCheckMax = false, doFinalLayout = false, forceScale }) {
    if (forceScale) {
        return layout(forceScale);
    }

    if (min == max) {
        return layout(max);
    }

    if (preCheckMax) {
        let props = layout(max);
        if (props.isFit) {
            props.fitValue = max;
            return props;
        }
    }

    if (preCheckMin) {
        let props = layout(min);
        if (props.isFit) {
            props.fitValue = min;
            return props;
        }
    }

    let results = binarySearchDown({ min, max, precision, layout });

    if (doFinalLayout && results.fitValue !== undefined) {
        // if doFinalLayout, we will do one more layout at the final fitValue to ensure that the calculatedProps of the tree are correct
        return layout(results.fitValue);
    }

    return results;
}

export function binarySearchDown({ min, max, precision = 20, layout, lastValue, lastGoodProps }) {
    let value = Math.ceil((min + max) * precision / 2) / precision;

    if (value === lastValue) {
        if (lastGoodProps) {
            return lastGoodProps;
        } else {
            return layout(value);
        }
    }
    lastValue = value;

    let props = layout(value);
    props.fitValue = value;
    if (props.isFit) {
        lastGoodProps = props;  // store good props
        // we fit at this value but we still need to search up to see if any larger values also fit
        return binarySearchDown({ min: value, max, precision, layout, lastValue, lastGoodProps });
    } else {
        // we didn't fit at this value so search down to see if any smaller values will fit
        return binarySearchDown({ min, max: value, precision, layout, lastValue, lastGoodProps });
    }
}

