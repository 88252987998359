import React, { useEffect } from "react";
import styled from "styled-components";

import { getStaticUrl } from "legacy-js/config";

import GeneratePresentationController from "../controllers/GeneratePresentationController";

const Container = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    >img {
        height: 300px;
        width: 300px;
    }
`;

const GeneratingOutlineView = GeneratePresentationController.withState(
    function GeneratingOutlineView() {
        return (<Container>
            <img src={getStaticUrl("/images/ai-search/presentation-loading.gif")} />
        </Container>);
    }
);

export default GeneratingOutlineView;
