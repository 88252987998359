import React from "react";
import {
    DialogTitle,
    DialogActions,
    Dialog,
    Button
} from "@material-ui/core";
import JSONPretty from "react-json-pretty";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import { clipboardWrite, ClipboardType } from "js/core/utilities/clipboard";
import debug from "js/core/debug";

export function DebugInfoDialog({ closeDialog = () => {} }) {
    return (
        <BeautifulDialog open closeDialog={closeDialog} closeButton>
            <DialogTitle>Debug Info</DialogTitle>
            <DialogContent>
                <JSONPretty json={debug.info} />
            </DialogContent>
            <DialogActions style={{ alignItems: "space-between" }}>
                <Button onClick={() => {
                    clipboardWrite({
                        [ClipboardType.TEXT]: JSON.stringify(debug.info, null, 2)
                    }).then(closeDialog);
                }} >Copy to Clipboard</Button>
            </DialogActions>
        </BeautifulDialog>
    );
}
