import { _ } from "legacy-js/vendor";
import { HeaderPositionType, PositionType, TrayType } from "legacy-common/constants";
import { controls } from "legacy-js/editor/ui";
import { getStaticUrl } from "legacy-js/config";
import { ShowDialogAsync } from "legacy-js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "legacy-js/react/components/Dialogs/BadFitDialog";

import { createResizer } from "../EditorComponents/Resizer";
import { ElementSelection } from "../BaseElementEditor";

const HeaderSelection = ElementSelection.extend({
    showSelectionBox: true,

    getOffset() {
        return 10;
    },

    renderControls: function() {
        let canvas = this.element.canvas;

        if (canvas.model.layout.headerPosition == HeaderPositionType.LEFT && !this.element.isOnAuthoringCanvas) {
            let lastFitValue;
            createResizer({
                view: this,
                element: this.element,
                position: PositionType.RIGHT,
                minSize: 200,
                maxSize: 1280 / 2,
                handleType: "tray",
                showSelectionBox: true,
                onResize: async value => {
                    canvas.model.layout.headerWidth = value;
                    await canvas.refreshCanvas()
                        .then(() => {
                            lastFitValue = value;
                        })
                        .catch(err => {
                            canvas.model.layout.headerWidth = lastFitValue;
                            canvas.refreshCanvas();
                        });
                }
            });
        }

        if (!this.element.isOnAuthoringCanvas) {
            let headerOptions = [];
            if (canvas.slideTemplate.availableHeaderPositions.contains("top")) {
                headerOptions.push({
                    value: HeaderPositionType.TOP,
                    label: "Top",
                    image: getStaticUrl(`/images/ui/trays/header_top.png`)
                });
            }
            if (canvas.slideTemplate.availableHeaderPositions.contains("left") && (canvas.model.layout.trayLayout == TrayType.NONE || canvas.model.layout.trayLayout == null)) {
                headerOptions.push({
                    value: HeaderPositionType.LEFT,
                    label: "Left",
                    image: getStaticUrl(`/images/ui/trays/header_left.png`)
                });
            }

            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "Position",
                menuClass: "icon-menu twocol",
                items: headerOptions,
                callback: value => {
                    canvas.model.layout.headerPosition = value;
                    this.element.markStylesAsDirty();
                    canvas.updateCanvasModel(true).catch(err => {
                        ShowDialogAsync(BadFitDialog, {
                            title: "Sorry, the content on your slide won't fit with the header on the left."
                        });
                    });
                }
            });
        }

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            label: "Background",
            model: this.element.model,
            property: "backgroundColor",
            includeNone: true,
            omitCurrentBackgroundColor: true,
            showBackgroundColors: true,
        });
    },

});

export const editors = {
    HeaderSelection
};

