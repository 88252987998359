import React, { Component } from "reactn";
import { app } from "js/namespaces";
import { tinycolor } from "legacy-js/vendor";
import { PopupMenu } from "legacy-js/react/views/Editor/PopupMenu";
import { FlexBox, RowGrid } from "legacy-js/react/components/LayoutGrid";
import { Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { Fragment } from "react";
import { ColorChit, ColorPalette } from "legacy-js/react/views/Editor/Components/ColorPalette";
import { Button, Checkbox, Icon } from "@material-ui/core";
import { BackgroundStyleType, PaletteColorType } from "legacy-common/constants";
import styled from "styled-components";
import { getStaticUrl } from "legacy-js/config";

const StyleOption = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-left: 7px;
    font-size: 10px !important;
    color: #666;
    margin-right: 10px;
    font-weight: 600;
  }
`;

export function StylePresetOption(props) {
    let color;
    if (props.color == "auto" || props.color == null) {
        color = app.currentTheme.palette.getColor(app.currentCanvas.getSlideColor());
    } else {
        color = app.currentTheme.palette.getColor(props.color);
    }

    let isLight = color._r > 245 && color._g > 245 && color._b > 245;

    if (isLight) {
        color = "#ccc";
    } else {
        color = color.toRgbString();
    }

    switch (props.type) {
        case "outlined":
            return (
                <StyleOption onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="22" height="22" stroke={color} strokeWidth="2"/>
                    </svg>
                    <label>{props.label}</label>
                </StyleOption>
            );
        case "filled":
            return (
                <StyleOption onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" fill={color}/>
                    </svg>
                    <label>{props.label}</label>
                </StyleOption>
            );
        case "muted":
            return (
                <StyleOption onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" fill={color} fillOpacity="0.35"/>
                    </svg>
                    <label>{props.label}</label>
                </StyleOption>
            );
        case "fillAndStroke":
            return (
                <StyleOption onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="22" height="22" stroke={color} fill={color}
                            fillOpacity="0.35" strokeWidth="2"/>
                    </svg>
                    <label>{props.label}</label>
                </StyleOption>
            );
    }
}

const PaletteWarning = styled.div`
  position: absolute;
  top: 12px;
  background: rgba(255,255,255,0.85);
  padding: 10px;
  width: 100%;
  left: 0px;
  text-align: center;
  z-index: 100;
  font-weight: 600;
  color: black;
`;

export class ColorPalettePopupMenu extends Component {
    state = {
        showPresets: true,
        selectedColor: this.props.selectedColor
    }

    handleSelectColor(value) {
        this.setState({ selectedColor: value });
        this.props.onSelectColor(value);

        if (!this.props.showDecorationStyles) {
            this.props.closeDialog();
        }
    }

    render() {
        let { selectedColor } = this.state;
        let {
            palette,
            showDecorationStyles,
            showMutedDecorationStyles = true,
            position,
            onSelectDecorationStyle,
            autoLabel
        } = this.props;

        let selected = palette.find(c => c.value == selectedColor);
        let color;
        if (selected) {
            color = tinycolor(selected.color);
        }

        let presets = (
            <Fragment>
                <div className="color_palette" style={{ padding: 15 }}>
                    {palette.map(color => (
                        <ColorChit key={color.value}
                            {...color}
                            selected={color.value == selectedColor}
                            onClick={() => this.handleSelectColor(color.value)}
                            autoLabel={autoLabel}
                        />
                    ))}
                </div>

                {showDecorationStyles && showDecorationStyles() &&
                    <FlexBox left style={{ background: color?.getLuminance() > .95 ? "#ddd" : "#fff", padding: 15 }}>
                        <StylePresetOption type="outlined" color={selectedColor} label="Outlined"
                            onClick={() => onSelectDecorationStyle("outlined")}/>
                        {showMutedDecorationStyles &&
                            <StylePresetOption type="muted" color={selectedColor} label="Muted"
                                onClick={() => onSelectDecorationStyle("muted")}/>
                        }
                        <StylePresetOption type="filled" color={selectedColor} label="Filled"
                            onClick={() => onSelectDecorationStyle("filled")}/>
                        {showMutedDecorationStyles &&
                            <StylePresetOption type="fillAndStroke" color={selectedColor} label="Fill & Stroke"
                                onClick={() => onSelectDecorationStyle("fillAndStroke")}/>
                        }
                    </FlexBox>
                }

            </Fragment>
        );

        let advanced = (
            <Fragment>
                <FlexBox>
                    <label>Fill</label>
                    <ColorChit color="red"/>
                    <Gap10/>
                    <label>Stroke</label>
                    <ColorChit color="blue"/>
                    <input type="number"/>
                </FlexBox>
                <Gap20/>
                <div className="toggle-button" onClick={() => this.setState({ showPresets: true })}>
                    Show Presets
                </div>
            </Fragment>
        );

        return (
            <PopupMenu {...this.props} position={position} className="style-popup-menu beautiful-ui" style={{ padding: 0 }}>
                {this.state.showPresets ? presets : advanced}
            </PopupMenu>
        );
    }
}

