import React, { Component } from "react";
import { DialogActions, DialogTitle } from "@material-ui/core";
import {
    BeautifulDialog,
    DialogContent,
    ShowDialog,
    ShowErrorDialog,
    ShowMessageDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import { BlueOutlinedButton, BlueButton } from "legacy-js/react/components/UiComponents";
import { TEAM_USER_ROLES } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { app } from "js/namespaces";
import { ReauthPasswordDialog } from "./ReauthPasswordDialog";
import Api from "js/core/api";
import getLogger from "js/core/logger";
import AppController from "legacy-js/core/AppController";
import { auth } from "js/firebase/auth";

const logger = getLogger();

export default class MemberAddedToOrgDialog extends Component {
    state = {
        emailVerified: !!app.user.getAuthUser().emailVerified,
        teamInviteUrl: ""
    };

    componentDidMount() {
        const { notification } = this.props;
        const { teamInviteUrl } = notification.event;

        this.setState({
            teamInviteUrl
        });

        if (!app.user.getAuthUser().emailVerified) {
            app.user.waitForVerifiedEmail().then(() => {
                return Api.enrichNotification.get({ notificationState: "unread", notificationId: notification.id });
            }).then(enrichmentData => {
                this.setState({
                    teamInviteUrl: enrichmentData.event.teamInviteUrl,
                    emailVerified: true
                });
            });
        }
    }

    renderMessage(role) {
        switch (role) {
            case TEAM_USER_ROLES.OWNER:
                return "You have been asked to join as an Owner, which means you'll have the ability to manage the team, team slides and theme.";
            case TEAM_USER_ROLES.LIBRARIAN:
                return "You have been asked to join as a Librarian, which means you'll have the ability to manage team slides and theme.";
            default:
                return "Now you can build decks together, faster. Keep everything in one place and everyone on brand with team slides and theme.";
        }
    }

    updateEmail = async () => {
        auth()
            .sendEmailVerification(app.user
                .getAuthUser())
            .then(() => {
                ShowMessageDialog({
                    title: "Verification email sent.",
                    message:
                        "Please check your email for a verification link and return to this page after you click the link."
                });
            })
            .catch(err => {
                switch (err.code) {
                    case "auth/requires-recent-login":
                        ShowDialog(ReauthPasswordDialog, {
                            callback: success => {
                                this.updateEmail();
                            }
                        });
                        break;
                    case "auth/invalid-email":
                    case "auth/email-already-in-use":
                    default:
                        ShowErrorDialog({
                            error: "Error sending verification email.",
                            message: err.message
                        });
                        break;
                }
            });
    };

    render() {
        const { triggeredByUser, teamName, toUserRole, id } = this.props.notification.event;
        const { emailVerified, teamInviteUrl } = this.state;
        return (
            <BeautifulDialog>
                <img style={{ width: 50, margin: "20px auto 0" }} src={getStaticUrl("/images/invite-icon.svg")} />
                <DialogTitle>Join the team!</DialogTitle>
                <DialogContent>
                    <p>{triggeredByUser.displayName} has invited you to join <span style={{ color: "#159bcd", fontWeight: 900 }}>{teamName}.</span></p>
                    <p>
                        {this.renderMessage(toUserRole)}
                    </p>
                    {!emailVerified && <p>You must verify your email before you can accept this invitation.</p>}
                </DialogContent>
                <DialogActions>
                    <BlueOutlinedButton
                        onClick={this.props.closeDialog}
                    >
                        Maybe Later
                    </BlueOutlinedButton>
                    {emailVerified ? (
                        <BlueButton onClick={() => {
                            window.open(teamInviteUrl);
                            AppController.notificationsService.markAsRead(id)
                                .then(() => this.setState({ isOpen: false }))
                                .catch(err => logger.error(err, "[MemberAddedToOrgDialog] AppController.notificationsService.markAsRead() failed", { id }));
                            this.props.closeDialog();
                        }}>
                            Join Now
                        </BlueButton>
                    ) : (
                        <BlueButton onClick={this.updateEmail}>
                            Verify Email
                        </BlueButton>
                    )}

                </DialogActions>
            </BeautifulDialog>
        );
    }
}
