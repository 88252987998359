import { _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { ds } from "js/core/models/dataService";
import { BaseElement } from "./BaseElement";
import { TextElement } from "./TextElement";
import { HorizontalAlignType, ContentBlockType, TextStyleType } from "legacy-common/constants";
import { FindBestFit } from "../layouts/FindBestFit";
import { layoutHelper } from "../layouts/LayoutHelper";

/*
    TextGroup consists of a title textElement and an optional body textElement layed out vertically

    options:
        showBody: whether the body text is optional. set to TRUE to force display of a body placeholder and prevent deletion of body
        autoWidth: passes the autoWidth option through to the child title and body textElements

        title and body options will be passed through to appropriate child textElement
 */

export class TextGroup extends BaseElement {
    static get schema() {
        return {
            titleTextStyle: "title"
        };
    }

    get requiresParentSelection() {
        return false;
    }

    get showTitle() {
        return true;
    }

    get showBody() {
        if (this.body && ds.selection.element === this.body) return true;
        if (this.showBodyPlaceholder) return true;
        if (this.options.showBody === true) return true;
        if (this.model.body && !_.isEmpty(this.model.body.text)) return true;
        return false;
    }

    get showBodyPlaceholder() {
        return this._showBodyPlaceholder === undefined ? false : this._showBodyPlaceholder;
    }

    set showBodyPlaceholder(value) {
        this._showBodyPlaceholder = value;
    }

    get canResize() {
        return this.options.canResize ?? false;
    }

    get textAlign() {
        if (this.title.allowAlignment && this.model.textAlign) {
            return this.model.textAlign;
        } else {
            return this.styles.textAlign || HorizontalAlignType.LEFT;
        }
    }

    get scaleTextToFit() {
        return this.options.scaleTextToFit === undefined ? false : this.options.scaleTextToFit;
    }

    get minHeight() {
        return this.title.styles.fontSize + (this.showBody ? this.body.styles.fontSize : 0);
    }

    get showAddButton() {
        return this.options.showAddButton === undefined ? true : this.options.showAddButton;
    }

    get isTextFit() {
        return this.calculatedProps.isTextFit;
    }

    get firstLineTextHeight() {
        return this.title.calculatedProps?.textLayout.lines[0].fontHeight || 0;
    }

    _build() {
        this.title = this.addElement("title", () => TextGroupTitle, Object.assign({
            singleLine: true,
            autoHeight: true,
            allowStyling: true,
            autoWidth: this.options.autoWidth,
            blockType: ContentBlockType.TITLE,
            allowAlignment: this.options.allowAlignment,
            forceRefreshOnKeyPress: this.options.forceRefreshOnKeyPress,
            allowedTextStyles: this.options.allowTextStyles ? [TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.BODY, TextStyleType.CAPTION] : null,
            textStylePropertyName: "titleTextStyle",
            matchUserScaleWithSiblings: this.options.matchUserScaleWithSiblings
        }, this.options.title));

        if (this.showBody) {
            this.body = this.addElement("body", () => TextGroupBody, Object.assign({
                autoHeight: true,
                allowStyling: true,
                autoWidth: this.options.autoWidth,
                blockType: ContentBlockType.BODY,
                allowAlignment: this.options.allowAlignment,
                forceRefreshOnKeyPress: this.options.forceRefreshOnKeyPress,
                matchUserScaleWithSiblings: this.options.matchUserScaleWithSiblings
            }, this.options.body));
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let textSize = size;
        if (this.canResize) {
            textSize.width = this.model.textWidth ?? size.width;
        }

        let autoWidth = options.autoWidth ?? false;
        let autoHeight = options.autoHeight ?? true;

        let textAlign = options.textAlign || this.textAlign;

        let minScale, maxScale;

        if (options.forceTextScale) {
            minScale = maxScale = options.forceTextScale;
        } else if (options.scaleTextToFit) {
            minScale = options.minTextScale ?? 0.5;
            maxScale = options.maxTextScale ?? 1;
            minScale = Math.min(minScale, maxScale);
        } else {
            minScale = maxScale = 1;
        }

        let { fitValue: textScale, titleProps, bodyProps } = FindBestFit({
            min: minScale,
            max: maxScale,
            preCheckMax: true,
            layout: scale => {
                let availableHeight = size.height;

                this.title.calculatedProps = null;
                let titleProps = this.title.calcProps(textSize, {
                    autoWidth: autoWidth,
                    autoHeight: autoHeight,
                    forceTextScale: scale,
                    textAlign
                });
                availableHeight -= titleProps.size.height;

                let isFit = titleProps.isTextFit;

                let bodyProps;
                if (this.showBody) {
                    this.body.styles.marginTop = this.styles.body.marginTop * scale;
                    bodyProps = this.body.calcProps(new geom.Size(textSize.width, availableHeight), {
                        autoWidth: autoWidth,
                        autoHeight: autoHeight,
                        forceTextScale: scale,
                        textAlign
                    });
                    isFit = isFit && bodyProps.isTextFit;
                }

                return {
                    isFit,
                    titleProps,
                    bodyProps
                };
            }
        });

        this.title.calculatedProps = titleProps;
        titleProps.bounds = new geom.Rect(0, 0, titleProps.size);

        let isTextFit = titleProps.isTextFit;
        let actualTextHeight = titleProps.size.height;
        let actualTextWidth = titleProps.textLayout.size.width;

        if (this.showBody) {
            this.body.calculatedProps = bodyProps;
            bodyProps.bounds = new geom.Rect(0, titleProps.bounds.bottom, bodyProps.size);

            actualTextHeight += bodyProps.size.height;
            isTextFit = isTextFit && bodyProps.isTextFit;
            actualTextWidth = Math.max(actualTextWidth, bodyProps.textLayout.size.width);

            layoutHelper.horizontalAlignItems([this.title, this.body], autoWidth ? new geom.Size(actualTextWidth, 0) : size, textAlign);
        }

        let calculatedWidth = autoWidth ? actualTextWidth : textSize.width;
        let calculatedHeight = autoHeight ? actualTextHeight : textSize.height;

        return {
            isTextFit,
            size: new geom.Size(calculatedWidth, calculatedHeight),
            textSize: new geom.Size(actualTextWidth, actualTextHeight),
            textScale
        };
    }
}

class TextGroupTitle extends TextElement {
    get passThroughSelection() {
        return false;
    }
}

class TextGroupBody extends TextElement {
    get passThroughSelection() {
        return false;
    }
}

