import React from "react";
import { Divider, Typography } from "@material-ui/core";
import { app } from "js/namespaces";

export default function LabelledDivider({ label }) {
    return (
        <>
            <Divider />
            <Typography
                variant="body2"
                style={{
                    textAlign: "center",
                    position: "relative",
                    top: -11,
                    marginLeft: -10,
                    paddingLeft: 5,
                    paddingRight: 5,
                    background: app.isDarkMode ? "#4C4E55" : "white",
                    display: "inline-block",
                    left: "50%",
                    color: app.isDarkMode ? "#999" : "#999"
                }}>
                {label}
            </Typography>
        </>
    );
}
