import editorManager from "./editorManager";

export const editors = {};

function register(moduleEditors) {
    for (const className in moduleEditors) {
        editorManager._register(className, moduleEditors[className]);
    }
}

import * as VideoOverlayEditor from "./elements/VideoOverlay/VideoOverlayEditor";
register(VideoOverlayEditor.editors);

import * as PPTSlideEditor from "./elements/PPTSlideEditor";
register(PPTSlideEditor.editors);

import * as AuthoringEditor from "./elements/AuthoringEditor";
register(AuthoringEditor.editors);

import * as AgendaEditor from "./elements/AgendaEditor";
register(AgendaEditor.editors);

import * as BigNumbersEditor from "./elements/BigNumbersEditor";
register(BigNumbersEditor.editors);

import * as WebViewEditor from "./elements/WebViewEditor";
register(WebViewEditor.editors);

import * as CalendarEditor from "./elements/CalendarEditor";
register(CalendarEditor.editors);

import * as VerticalTaskListsEditor from "./elements/VerticalTaskListsEditor";
register(VerticalTaskListsEditor.editors);

import * as HorizontalTaskListsEditor from "./elements/HorizontalTaskListsEditor";
register(HorizontalTaskListsEditor.editors);

import * as AnnotationLayerEditor from "./elements/AnnotationLayerEditor";
register(AnnotationLayerEditor.editors);

import * as CountdownEditor from "./elements/CountdownEditor";
register(CountdownEditor.editors);

import * as TimerEditor from "./elements/TimerEditor";
register(TimerEditor.editors);

import * as ArrowBarsEditor from "./elements/ArrowBarsEditor";
register(ArrowBarsEditor.editors);

import * as RadialBarChartEditor from "./elements/RadialBarChartEditor";
register(RadialBarChartEditor.editors);

import * as TargetEditor from "./elements/TargetEditor";
register(TargetEditor.editors);

import * as SimpleGanttChartEditor from "./elements/GanttChartEditor";
register(SimpleGanttChartEditor.editors);

import * as CanvasTextBlockEditor from "./elements/CanvasTextBlockEditor";
register(CanvasTextBlockEditor.editors);

import * as ChartEditor from "./elements/ChartEditor";
register(ChartEditor.editors);

import * as CompareEditor from "./elements/CompareEditor";
register(CompareEditor.editors);

import * as ConnectorsEditor from "./elements/ConnectorsEditor";
register(ConnectorsEditor.editors);

import * as ContactInfoEditor from "./elements/ContactInfoEditor";
register(ContactInfoEditor.editors);

import * as ContentBlockEditor from "./elements/ContentBlockEditor";
register(ContentBlockEditor.editors);

import * as ContentItemEditor from "./elements/ContentItemEditor";
register(ContentItemEditor.editors);

import * as ContentEditor from "./elements/ContentEditor";
register(ContentEditor.editors);

import * as DashboardEditor from "./elements/DashboardEditor";
register(DashboardEditor.editors);

import * as DebugBoxEditor from "./elements/DebugBoxEditor";
register(DebugBoxEditor.editors);

import * as DisplayTextEditor from "./elements/DisplayTextEditor";
register(DisplayTextEditor.editors);

import * as FooterEditor from "./elements/FooterEditor";
register(FooterEditor.editors);

import * as HeaderEditor from "./elements/HeaderEditor";
register(HeaderEditor.editors);

import * as HeadlineEditor from "./elements/HeadlineEditor";
register(HeadlineEditor.editors);

import * as HubAndSpokeEditor from "./elements/Diagrams/HubAndSpokeEditor";
register(HubAndSpokeEditor.editors);

import * as CircularArrowsEditor from "./elements/CircularArrowsEditor";
register(CircularArrowsEditor.editors);

import * as IconCircleEditor from "./elements/Diagrams/IconCircleEditor";
register(IconCircleEditor.editors);

import * as JourneyEditor from "./elements/JourneyEditor";
register(JourneyEditor.editors);

import * as LogoGridEditor from "./elements/LogoGridEditor";
register(LogoGridEditor.editors);

import * as NodeEditor from "./elements/NodeEditor";
register(NodeEditor.editors);

import * as NodeDiagramEditor from "./elements/NodeDiagramEditor";
register(NodeDiagramEditor.editors);

import * as OrgChartEditor from "./elements/OrgChartEditor";
register(OrgChartEditor.editors);

import * as PhotoCollageEditor from "./elements/PhotoCollageEditor";
register(PhotoCollageEditor.editors);

import * as PhotoTextListEditor from "./elements/Lists/PhotoTextListEditor";
register(PhotoTextListEditor.editors);

import * as PictorialChartEditor from "./elements/PictorialChartEditor";
register(PictorialChartEditor.editors);

import * as PieChartEditor from "./elements/PieChartEditor";
register(PieChartEditor.editors);

import * as PresentationTitleEditor from "./elements/PresentationTitleEditor";
register(PresentationTitleEditor.editors);

import * as ProcessDiagramEditor from "./elements/ProcessDiagramEditor";
register(ProcessDiagramEditor.editors);

import * as ProductScreenshotEditor from "./elements/ProductScreenshotEditor";
register(ProductScreenshotEditor.editors);

import * as PyramidEditor from "./elements/PyramidEditor";
register(PyramidEditor.editors);

import * as SliceChartEditor from "./elements/SliceChartEditor";
register(SliceChartEditor.editors);

import * as SlidePlaceholderEditor from "./elements/SlidePlaceholderEditor";
register(SlidePlaceholderEditor.editors);

import * as StackDiagramEditor from "./elements/StackDiagramEditor";
register(StackDiagramEditor.editors);

import * as StreetMapEditor from "./elements/StreetMapEditor";
register(StreetMapEditor.editors);

import * as SwotDiagramEditor from "./elements/SwotDiagramEditor";
register(SwotDiagramEditor.editors);

import * as TableEditor from "./elements/TableEditor";
register(TableEditor.editors);

import * as TeamEditor from "./elements/TeamEditor";
register(TeamEditor.editors);

import * as CarouselEditor from "./elements/Lists/CarouselEditor";
register(CarouselEditor.editors);

import * as TextGroupEditor from "./elements/TextGroupEditor";
register(TextGroupEditor.editors);

import * as TextWithIconEditor from "./elements/TextWithIconEditor";
register(TextWithIconEditor.editors);

import * as TextListEditor from "./elements/Lists/TextListEditor";
register(TextListEditor.editors);

import * as IconsListEditor from "./elements/Lists/IconsListEditor";
register(IconsListEditor.editors);

import * as TextBoxGridEditor from "./elements/Lists/TextBoxGridEditor";
register(TextBoxGridEditor.editors);

import * as TimelineEditor from "./elements/TimelineEditor";
register(TimelineEditor.editors);

import * as TrayEditor from "./elements/TrayEditor";
register(TrayEditor.editors);

import * as UserTestimonialEditor from "./elements/UserTestimonialEditor";
register(UserTestimonialEditor.editors);

import * as VennDiagramEditor from "./elements/VennDiagramEditor";
register(VennDiagramEditor.editors);

import * as VideoEditor from "./elements/VideoEditor";
register(VideoEditor.editors);

import * as WordCloudEditor from "./elements/WordCloudEditor";
register(WordCloudEditor.editors);

import * as XYGraphEditor from "./elements/Diagrams/XYGraphEditor";
register(XYGraphEditor.editors);

import * as iconEditor from "./elements/iconEditor";
register(iconEditor.editors);

import * as pictureEditor from "./elements/pictureEditor";
register(pictureEditor.editors);

import * as textEditor from "./elements/textEditor";
register(textEditor.editors);

import * as EmbedEditor from "./elements/EmbedEditor";
register(EmbedEditor.editors);

import * as ThermometerEditor from "./elements/ThermometerEditor";
register(ThermometerEditor.editors);
