import React, { Component } from "react";
import moment from "moment";
import SessionDetail from "./SessionDetail";
import { formatter } from "js/core/utilities/formatter";
import Spinner from "legacy-js/react/components/Spinner";
import { FetchStatus } from "legacy-js/react/constants";
import ErrorMessage from "legacy-js/react/components/ErrorMessage";
import linkDS from "../../PresentationSettings/dataservices/LinkDataService";
import { trackActivity } from "js/core/utilities/utilities";
import AnalyticsController from "legacy-js/react/views/Analytics/AnalyticsController";

const PAGE_SIZE = 20;

class LinkViews extends Component {
    constructor() {
        super();
        this.state = { pageOffset: 0 };
    }

    componentWillMount() {
        this.setState({
            styles: {
                opacity: 0
            }
        });
    }

    componentDidMount() {
        this.setState({
            styles: {
                opacity: 1
            }
        });
    }

    loadMoreViews() {
        // let activeUser = this.props.analytics.activeUser;
        let { pageOffset } = this.state;
        let newOffset = pageOffset + PAGE_SIZE;
        this.setState({ pageOffset: newOffset });
        // let ipaddress = activeUser.username === 'logged out' ? activeUser.ipaddress : null;
        AnalyticsController.fetchViewsForUser(PAGE_SIZE, newOffset);
    }

    render() {
        const { presentation, analytics } = this.props;
        const linkAnalytics = analytics.selectedLink;

        let totalViews = linkAnalytics.totalViews;
        let data, status;
        if (linkAnalytics.views) {
            if (linkAnalytics.views.data) {
                data = linkAnalytics.views.data.map((row, index) => {
                    return {
                        timestamp: row.time,
                        duration: row.totaltime / 1000,
                        isSelected:
                            row.groupid == linkAnalytics.selectedSessionId,
                        groupid: row.groupid,
                        originalData: row
                    };
                });
            }
            status = linkAnalytics.views.status;
        } else {
            status = { type: FetchStatus.LOADING };
        }

        switch (status.type) {
            case FetchStatus.LOADING:
                return <Spinner />;
            case FetchStatus.ERROR:
                return (
                    <ErrorMessage
                        title="An occurred while loading analytics data"
                        message={status.message}
                    />
                );
            case FetchStatus.SUCCESS:
            default:
                if (data.length == 0) {
                    return (
                        <div className="no-data">
                            This link has not been viewed yet
                        </div>
                    );
                } else {
                    let loadMore;
                    if (totalViews > this.state.pageOffset + PAGE_SIZE) {
                        if (status.type == FetchStatus.LOADING_MORE) {
                            loadMore = (
                                <div className="load-more with-spinner">
                                    <Spinner />
                                </div>
                            );
                        } else {
                            loadMore = (
                                <div
                                    className="load-more"
                                    onClick={() => this.loadMoreViews()}
                                >
                                    Load More
                                </div>
                            );
                        }
                    }

                    return (
                        <div>
                            <div className="section-label">Viewing Details</div>
                            <div className="user-views">
                                {data.map(row => {
                                    return (
                                        <UserViewItem
                                            {...row}
                                            presentation={presentation}
                                            key={row.groupid}
                                            isSelected={row.isSelected}
                                            linkAnalytics={linkAnalytics}
                                            loadMore={() =>
                                                this.loadMoreViews()
                                            }
                                        ></UserViewItem>
                                    );
                                })}
                                {loadMore}
                            </div>
                        </div>
                    );
                }
        }
    }
}

class UserViewItem extends Component {
    onClick = () => {
        const {
            presentation,
            isSelected,
            linkAnalytics,
            groupid,
        } = this.props;

        if (!isSelected) {
            const analyticsProps = {
                presentation_id: presentation.get("id"),
                link_id: linkAnalytics.linkid,
                type: this.getLinkType(linkAnalytics)
            };
            trackActivity("LinkAnalytics", "ShowSessionDetail", null, null, analyticsProps);
            AnalyticsController.setActiveSession(groupid);
        }
    };

    getLinkType(linkAnalytics) {
        let linkType = null;
        if (linkAnalytics.linkid) {
            let link = linkDS.getLinkById(this.props.presentation, linkAnalytics.linkid);
            if (link) {
                linkType =
                    link.get("type") === "social"
                        ? link.get("name")
                        : link.get("type");
            }
        }
        return linkType;
    }

    render() {
        const {
            isSelected,
            timestamp,
            duration,
        } = this.props;

        let className = "user-view-label";
        let sessionDetail;
        if (isSelected) {
            className += " selected";
            sessionDetail = <SessionDetail />;
        }

        return (
            <div className="user-view-item">
                <div className={className} onClick={this.onClick}>
                    <div className="marker"></div>
                    <div className="label">
                        {moment(timestamp)
                            .format("ddd, MMM Do YYYY h:mm:ss a")}{" "}
                        <span className="duration">
                            {formatter.formatDuration(
                                duration,
                                "seconds",
                                "h [hrs], m [min], s [sec]"
                            )}
                        </span>
                        <span className="username">{this.renderUsername()}</span>
                    </div>
                </div>
                {sessionDetail}
            </div>
        );
    }

    renderUsername() {
        const {
            originalData: {
                username,
            },
        } = this.props;

        if (username == "logged out") {
            return "Anonymous viewer";
        } else {
            return username;
        }
    }
}

export default AnalyticsController.withState(LinkViews);
