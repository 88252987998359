import React, { Component } from "reactn";
import { _ } from "legacy-js/vendor";
import { Button, TextField, DialogTitle, DialogActions } from "@material-ui/core";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import { FlexSpacer } from "../Gap";

export default class EditLinkDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { value: "" };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { input } = this.props;
        const value = _.trim(input.value);
        const isEditingLink = !!value;
        this.setState({ value, isEditingLink });
    }

    handleChange = evt => {
        this.setState({ value: evt.target.value });
    }

    handleKeyPress = event => {
        if (event.key == "Enter") {
            this.handleCommit();
        }
    }

    handleRemove = () => {
        this.props.removeCallback && this.props.removeCallback();
        this.props.closeDialog();
    }

    handleCommit = () => {
        this.props.acceptCallback && this.props.acceptCallback(this.state.value);
        this.props.closeDialog();
    }

    handleCancel = () => {
        this.props.cancelCallback && this.props.cancelCallback();
        this.props.closeDialog();
    }

    selectTextField = event => {
        // auto select the text field when focused
        event.target.select();
    }

    render() {
        const { input = {}, autoSelect = true } = this.props;
        const { value, isEditingLink } = this.state;
        const title = `${isEditingLink ? "Edit" : "Add New"} Link`;
        const onFocus = autoSelect ? this.selectTextField : () => { };

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <TextField
                        id={input.id}
                        onKeyPress={this.handleKeyPress}
                        autoFocus={true}
                        onFocus={onFocus}
                        placeholder={input.placeholder}
                        value={value}
                        fullWidth={true}
                        type={input.type}
                        inputProps={input.inputProps}
                        onChange={this.handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    {isEditingLink && (
                        <>
                            <Button onClick={this.handleRemove} >Remove</Button>
                            <FlexSpacer />
                        </>
                    )}
                    <Button onClick={this.handleCancel} >Cancel</Button>
                    <Button onClick={this.handleCommit} >OK</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
