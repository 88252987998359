import React, { Component } from "react";
import { tinycolor, _ } from "legacy-js/vendor";

export function getSVGStyleProps(styles) {
    let s = {};

    if (styles) {
        if (styles.resolved_fillColor !== undefined) {
            s.fill = styles.resolved_fillColor.toRgbString();
        } else {
            s.fill = "none";
        }

        if (styles.fill !== undefined) {
            s.fill = styles.fill;
        }

        if (styles.fillOpacity !== undefined) {
            s.fillOpacity = styles.fillOpacity;
        }

        if (styles.resolved_strokeColor !== undefined) {
            s.stroke = styles.resolved_strokeColor.toRgbString();
        }

        if (styles.stroke !== undefined) {
            s.stroke = styles.stroke;
        }

        if (styles.animation !== undefined) {
            s.animation = styles.animation;
        }

        s.strokeWidth = styles.strokeWidth;
        s.strokeOpacity = styles.strokeOpacity;
        s.strokeDasharray = styles.strokeDash;
        s.opacity = styles.opacity;
        s.strokeDashoffset = styles.strokeDashoffset;
        s.strokeLinecap = (styles.strokeLinecap && styles.strokeLinecap === "circle") ? "round" : styles.strokeLinecap;

        if (styles.filter !== undefined && styles.filter != "none") {
            s.filter = `url(#${styles.filter})`;
        }

        return s;
    }
}

export function getTransformProps(bounds, transition) {
    let props = {
        width: bounds.width,
        height: bounds.height,
        style: {
            position: "absolute",
            transform: `translateX(${bounds.left}px) translateY(${bounds.top}px)`
        }
    };

    if (transition) {
        props.style.transition = ELEMENT_TRANSITION;
    }

    return props;
}

export function getSVGTextProps(styles) {
    let props = {};
    props.fontFamily = styles.fontId;
    props.fontWeight = styles.fontWeight;
    props.fontSize = styles.fontSize;
    if (styles.resolved_fontColor) {
        props.fill = styles.resolved_fontColor.toRgbString();
    }
    return props;
}

export let ELEMENT_TRANSITION_DURATION = 300;
export let ELEMENT_TRANSITION = `${ELEMENT_TRANSITION_DURATION}ms`;

export function getElementTransition(transition) {
    if (transition) {
        return ELEMENT_TRANSITION;
    } else {
        return "none";
    }
}

export function getSVGProps(props) {
    let p = {};
    let s = {};
    p.style = s;

    p.id = props.id;
    p.className = props.className;
    p.clipPath = props.clipPath;
    p.filter = props.filter;

    if (props.bounds != undefined) {
        p.width = props.bounds.width;
        p.height = props.bounds.height;
        s.transform = `translateX(${props.bounds.left}px) translateY(${props.bounds.top}px)`;
    }

    if (props.opacity != undefined) {
        s.opacity = props.opacity;
    } else {
        s.opacity = 1;
    }

    if (props.transition != undefined) {
        s.transitionProperty = "transform, opacity";
        s.transitionDuration = getElementTransition(props.transition);
        s.transitionTimingFunction = "ease-in-out";
    }

    if (props.fadeIn) {
        s.animationName = "element-fade-in";
        s.animationDuration = ELEMENT_TRANSITION;
        s.transition = null;
    }

    if (props.styles != undefined) {
        s = Object.assign(s, getSVGStyleProps(props.styles));
    }

    return p;
}

export function getHTMLStyles(styles) {
    let s = _.clone(styles);

    // styles.resolved_fillColor = styles.resolved_fillColor.toRgbString() || styles.fill;
    // styles.resolved_strokeColor = styles.resolved_strokeColor.toRgbString() || styles.stroke;

    if (styles.resolved_fillColor && styles.resolved_fillColor.name != "none") {
        let bgColor = styles.resolved_fillColor;
        if (styles.fillOpacity && styles.fillOpacity < 1) {
            let c = tinycolor(bgColor);
            c.setAlpha(styles.fillOpacity);
            bgColor = c.toRgbString();
        }
        s.backgroundColor = bgColor;
    } else {
        s.backgroundColor = null;
    }

    if (styles.resolved_strokeColor && styles.resolved_strokeColor.name != "none" && styles.strokeWidth > 0) {
        s.borderStyle = styles.strokeDash ? "dashed" : "solid";
        s.borderWidth = styles.strokeWidth;

        let borderColor = styles.resolved_strokeColor;
        if (styles.strokeOpacity && styles.strokeOpacity < 1) {
            let c = tinycolor(borderColor);
            c.setAlpha(styles.strokeOpacity);
            borderColor = c.toRgbString();
        }
        s.borderColor = borderColor;
    } else {
        s.borderStyle = "none";
    }

    if (styles.filter) {
        switch (styles.filter) {
            case "softShadow":
                s.boxShadow = `5px 5px 15px rgba(0,0,0,0.2)`;
                break;
            case "textShadow":
                s.filter = "url(#textShadow)";
                break;
            // case "softShadow":
            //     s.filter = "url(#softShadow)";
            //     break;
        }
    }

    if (styles.shadow) {
        s.boxShadow = `${styles.shadow.offsetX}px ${styles.shadow.offsetY}px ${styles.shadow.blur}px rgba(0,0,0,${styles.shadow.opacity})`;
    }

    if (styles.cornerRadius) {
        s.borderRadius = styles.cornerRadius + "px";
    }

    return s;
}

export function convertAlignToFlex(align) {
    switch (align) {
        case "left":
        case "top":
            return "flex-start";
        case "right":
        case "bottom":
            return "flex-end";
        default:
            return "center";
    }
}

export function convertPaddingToCSS(padding) {
    return `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`;
}

export function getHTMLProps(props, styles, transition) {
    let p = {};
    let s = {};
    p.style = s;

    p.id = props.id;
    p.className = props.className;

    if (props.bounds != undefined) {
        if (!props.isDocumentView) {
            s.position = "absolute";
        }
        s.top = props.bounds.top;
        s.left = props.bounds.left;
        s.width = props.bounds.width;
        s.height = props.bounds.height;
    }

    if (props.rotate) {
        s.transform = `rotate(${props.rotate}deg)`;
        s.transformOrigin = "center center";
    }

    if (props.opacity != undefined) {
        s.opacity = props.opacity;
    } else {
        s.opacity = 1;
    }

    if (props.fadeIn) {
        s.animationName = "element-fade-in";
        s.animationDuration = ELEMENT_TRANSITION;
    } else if (props.fadeOut) {
        s.animationName = "element-fade-out";
        s.animationFillMode = "forwards";
        s.animationDuration = ELEMENT_TRANSITION;
    } else if (transition) {
        if (props.animationName) {
            s.animationName = props.animationName;
            s.animationDuration = ELEMENT_TRANSITION;
        } else {
            s.transitionDuration = getElementTransition(transition);
            s.transitionProperty = "transform, top, left, width, height, opacity";
            s.transitionTimingFunction = "ease-in-out";
        }
    }

    if (props.margins) {
        s.top += props.margins.top;
        s.left += props.margins.left;
        s.width -= props.margins.left + props.margins.right;
        s.height -= props.margins.top + props.margins.bottom;
    }

    if (styles != undefined) {
        s = Object.assign(s, getHTMLStyles(styles));
    }

    if (props.clipPath) {
        s.clipPath = props.clipPath;
    }

    if (props.zIndex) {
        s.zIndex = props.zIndex;
    }

    if (window.debug.showLayoutDebugBox) {
        s.outline = "solid 1px red";
    }

    s.pointerEvents = "none";

    // Force hardware acceleration for safari to avoid shadow/filter rendering issues
    if ((props.styles?.filter && props.styles?.filter !== "none") || (props.styles?.shadow && props.styles?.shadow !== "none")) {
        s.transform = `translate3d(0, 0, 0) ${s.transform || ""}`;
    }

    return p;
}

export function getClipPath(shape, size, cornerRadius = 0, withFudge = false, canvasScale = 1) {
    switch (shape) {
        case "circle":
            if (withFudge) {
                // Fudge need for Video bleed. Amount needed is based on inverse canvas scale.
                const minScale = 0.11;
                const maxScale = 1.3;
                const interp = 1.0 - ((canvasScale - minScale) / (maxScale - minScale));
                const fudge = 3.5 * interp;
                return `circle(${Math.min(size.width, size.height) / 2 + fudge}px at ${size.width / 2}px  ${size.height / 2}px)`;
            } else {
                return `circle(${Math.min(size.width, size.height) / 2}px at ${size.width / 2}px  ${size.height / 2}px)`;
            }
        case "rect":
            if (cornerRadius) {
                if (withFudge) {
                    // -0.5 and -0.1 fudge to prevent Videos from border bleeding
                    return `inset(-0.5px -0.1px 0.0px -0.1px round ${cornerRadius}px)`;
                } else {
                    return `inset(0 0 0 0 round ${cornerRadius}px)`;
                }
            } else {
                return null;
            }
        case "octagon":
            return `polygon(14% 14%, 50% 0, 86% 14%, 100% 50%, 86% 86%, 50% 100%, 14% 86%, 0 50%)`;
    }
}

export class DivGroup extends Component {
    constructor() {
        super();
        this.ref = React.createRef();
    }

    render() {
        return (
            <div ref={this.ref}
                {...getHTMLProps(this.props, this.props.styles, this.props.transition)}
            >
                {this.props.children}
            </div>
        );
    }
}

export class SVGGroup extends Component {
    constructor() {
        super();
        this.ref = React.createRef();
    }

    render() {
        let { overflowHidden, filter, className, animation, children } = this.props;

        return (
            <svg
                ref={this.ref}
                className={className}
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                    overflow: overflowHidden ? "hidden" : "visible",
                    animation,
                    filter
                }}
            >{children}</svg>
        );
    }
}

export class SVGRect extends Component {
    render() {
        return (
            <rect
                x={this.props.bounds.left}
                y={this.props.bounds.top}
                width={this.props.bounds.width}
                height={this.props.bounds.height}
                style={getSVGStyleProps(this.props.styles)}
            />
        );
    }
}

