import React, { Component } from "react";
import styled from "styled-components";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";

const MobileNotesContainer = styled.div`
  padding: 70px;
  color: #eee;
  font-size: 3em;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  flex-shrink: 10;
  margin-bottom: 2em;
`;

const NotesContainer = styled.div`
  padding: 20px;
  color: #eee;
  font-size: 1em;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
`;

export class SlideNotes extends Component {
    render() {
        const { notes, isMobileOrTablet } = this.props;

        const sanitizedNotes = sanitizeHtmlText(notes);
        if (isMobileOrTablet) {
            return (
                <MobileNotesContainer dangerouslySetInnerHTML={{ __html: sanitizedNotes }} />
            );
        } else {
            return (
                <NotesContainer dangerouslySetInnerHTML={{ __html: sanitizedNotes }} />
            );
        }
    }
}

