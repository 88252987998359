import React, { useState } from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap20 } from "legacy-js/react/components/Gap";

import { PaneContent, PaneFooter } from "../../../components/PaneComponents";
import PromptInput from "../../../components/PromptInput";
import FileInput from "../../../components/FileInput";
import DocumentTextInput from "../../../components/DocumentTextInput";
import WebpageUrlInput from "../../../components/WebpageUrlInput";
import GenerationHeader from "../../../components/GenerationHeader";

import GeneratePresentationController, {
    ContextSource,
    ContextSourceType,
    GeneratePresentationState,
    StartGenerationParams
} from "../controllers/GeneratePresentationController";

const promptExamples = [
    "Investor pitch deck for a Series A SaaS company in the B2C FinTech space",
    "Board deck for Q1 revenue, growth, and new customers",
    "What is Google's business model?",
    "How the Grinch stole Christmas",
    "A pitch deck for a mobile app that sells train tickets called 'Ticket to Ride'",
    "Presentation on the workplace transformation and the future of work",
];

const ContextSourceContainer = styled.div`
    background: white;
    border: solid 1px #ccc;
    padding: 20px;
    flex-grow: 1;
`;

const Tab = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    gap: 10px;
    background: white;
    padding: 10px 14px;
    border: solid 1px #ccc;
    border-bottom-color: ${props => props.selected ? "white" : "#ccc"};
    background: ${props => props.selected ? "white" : "#f1f1f1"};
    position: relative;
    top: 1px;
`;

const DocToDeckView = GeneratePresentationController.withState(
    function DocToDeckView({ startGenerationParams, aiCreditsBalance }: GeneratePresentationState) {
        const [contextSourceType, setContextSourceType] = useState<ContextSourceType>(ContextSourceType.TEXT);
        const [contextSource] = useState<ContextSource>({ type: ContextSourceType.TEXT });
        const [prompt, setPrompt] = useState<string>(startGenerationParams?.prompt ?? "");

        const handleStartGeneration = () => {
            const params: StartGenerationParams = {
                prompt,
                files: [],
                contextUrls: [],
                documentText: "",
            };

            switch (contextSourceType) {
                case ContextSourceType.WEBPAGE:
                    params.contextUrls.push(contextSource.url);
                    break;
                case ContextSourceType.TEXT:
                    params.documentText = contextSource.text;
                    break;
                case ContextSourceType.FILE:
                    params.files.push(contextSource.file);
                    break;
            }

            GeneratePresentationController.generatePresentationOutline(params);
        };

        const handleEnterKeyPress = () => {
            handleStartGeneration();
        };

        return (
            <>
                <GenerationHeader
                    header="Convert a document or webpage into a presentation."
                />
                <PaneContent>
                    <PromptInput
                        label="Provide any instructions to help the AI convert your document to a presentation"
                        prompt={prompt}
                        onPromptChange={setPrompt}
                        promptPlaceholder="Describe the topic of your presentation..."
                        promptExamples={promptExamples}
                        actionButtonText="Generate Presentation"
                        onActionButtonClick={handleStartGeneration}
                    />
                    <Gap20 />
                    <FlexBox left gap={5}>
                        <Tab onClick={() => setContextSourceType(ContextSourceType.TEXT)}
                            selected={contextSourceType === ContextSourceType.TEXT}>
                            <Icon>edit</Icon>Convert a document
                        </Tab>
                        <Tab onClick={() => setContextSourceType(ContextSourceType.WEBPAGE)}
                            selected={contextSourceType === ContextSourceType.WEBPAGE}>
                            <Icon>language</Icon>Convert a webpage
                        </Tab>
                    </FlexBox>

                    <ContextSourceContainer>
                        {contextSourceType === ContextSourceType.FILE &&
                            <FileInput contextSource={contextSource} />}
                        {contextSourceType === ContextSourceType.TEXT &&
                            <DocumentTextInput contextSource={contextSource} />}
                        {contextSourceType === ContextSourceType.WEBPAGE &&
                            <WebpageUrlInput contextSource={contextSource} />}
                    </ContextSourceContainer>

                </PaneContent>
                <PaneFooter
                    showBackButton={true}
                    onBackButtonClick={() => GeneratePresentationController.showChooseFlow()}
                    aiCreditsBalance={aiCreditsBalance}
                />
            </>
        );
    }
);

export default DocToDeckView;
