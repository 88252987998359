const { TextStyleEnum } = require("../constants");

class SvgTextModel {
    static get breakWordRegex() {
        return new RegExp(/([\s!"#$%&()*+,.:;<=>?@\[\]^_-`{|}~“”])/);
    }

    constructor(model) {
        if (model == null || typeof model !== "object") {
            if (model == undefined) {
                model = { text: "" };
            } else {
                model = { text: model };
            }
        }
        if (model.text == null) {
            model.text = "";
        }
        this.setText(model.text.toString(), model.styles);
    }

    getStyleType(style) {
        if (style.bold && style.italic) {
            return TextStyleEnum.BOLDITALIC;
        } else if (style.bold) {
            return TextStyleEnum.BOLD;
        } else if (style.italic) {
            return TextStyleEnum.ITALIC;
        } else {
            return TextStyleEnum.REGULAR;
        }
    }

    setText(text = "", styles = []) {
        this.text = text.toString();
        this.styles = styles;
        this.links = [];

        // generate the array of paragraph and words used by the textLayouter
        this.paragraphs = [];
        let charIndex = 0;

        let hasOpenQuote = null;

        // convert objects with numerical keys into arrays so the .find method is available
        if (!Array.isArray(this.styles)) {
            this.styles = Object.values(this.styles);
        }

        const paragraphs = this.text.split(String.fromCodePoint(13));
        paragraphs.forEach((p, i) => {
            let paragraph = { words: [] };
            for (let w of p.split(SvgTextModel.breakWordRegex)) {
                if (w != "") {
                    let style = this.styles.find(s => charIndex >= s.start && charIndex < s.end);
                    if (!style) {
                        style = { bold: false, italic: false, link: null, color: null };
                    }

                    if (["“", '"'].find(c => c === w)) {
                        if (hasOpenQuote === null) {
                            hasOpenQuote = w;
                            w = "“";
                        } else {
                            w = "”";
                            hasOpenQuote = null;
                        }
                    }

                    paragraph.words.push({
                        text: w,
                        style: this.getStyleType(style),
                        color: style.color,
                        link: style.link
                    });
                    charIndex += w.length;
                }
            }
            if (paragraphs.length - 1 > i) {
                // if there are more paragraphs, add a newline char at end of paragraph
                paragraph.words.push({
                    text: String.fromCodePoint(13),
                    style: TextStyleEnum.REGULAR,
                    link: null
                });
                charIndex += 1;
            }
            this.paragraphs.push(paragraph);
        });
    }
}

module.exports = SvgTextModel;
