export const TAX_ID_TYPES = [
    "ad_nrt",
    "ae_trn",
    "ar_cuit",
    "au_abn",
    "au_arn",
    "bg_uic",
    "bh_vat",
    "bo_tin",
    "br_cnpj",
    "br_cpf",
    "ca_bn",
    "ca_gst_hst",
    "ca_pst_bc",
    "ca_pst_mb",
    "ca_pst_sk",
    "ca_qst",
    "ch_vat",
    "cl_tin",
    "cn_tin",
    "co_nit",
    "cr_tin",
    "de_stn",
    "do_rcn",
    "ec_ruc",
    "eg_tin",
    "es_cif",
    "eu_oss_vat",
    "eu_vat",
    "gb_vat",
    "ge_vat",
    "hk_br",
    "hu_tin",
    "id_npwp",
    "il_vat",
    "in_gst",
    "is_vat",
    "jp_cn",
    "jp_rn",
    "jp_trn",
    "ke_pin",
    "kr_brn",
    "kz_bin",
    "li_uid",
    "mx_rfc",
    "my_frp",
    "my_itn",
    "my_sst",
    "ng_tin",
    "no_vat",
    "no_voec",
    "nz_gst",
    "om_vat",
    "pe_ruc",
    "ph_tin",
    "ro_tin",
    "rs_pib",
    "ru_inn",
    "ru_kpp",
    "sa_vat",
    "sg_gst",
    "sg_uen",
    "si_tin",
    "sv_nit",
    "th_vat",
    "tr_tin",
    "tw_vat",
    "ua_vat",
    "us_ein",
    "uy_ruc",
    "ve_rif",
    "vn_tin",
    "za_vat"
];

export const TAX_ID_DATA: { countryCode: string; type: string; name: string; placeholder: string, isLocal?: boolean }[] = [
    {
        countryCode: "AD",
        type: "ad_nrt",
        name: "Andorran NRT number",
        placeholder: "A-123456-Z"
    },
    {
        countryCode: "AR",
        type: "ar_cuit",
        name: "Argentinian tax ID number",
        placeholder: "12-3456789-01"
    },
    {
        countryCode: "AU",
        type: "au_abn",
        name: "Australian Business Number (AU ABN)",
        placeholder: "12345678912"
    },
    {
        countryCode: "AU",
        type: "au_arn",
        name: "Australian Taxation Office Reference Number",
        placeholder: "123456789123",
        isLocal: true
    },
    {
        countryCode: "AT",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "ATU12345678"
    },
    {
        countryCode: "BH",
        type: "bh_vat",
        name: "Bahraini VAT Number",
        placeholder: "123456789012345"
    },
    {
        countryCode: "BE",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "BE0123456789"
    },
    {
        countryCode: "BO",
        type: "bo_tin",
        name: "Bolivian tax ID",
        placeholder: "123456789",
        isLocal: true
    },
    {
        countryCode: "BR",
        type: "br_cnpj",
        name: "Brazilian CNPJ number",
        placeholder: "01.234.456/5432-10",
        isLocal: true
    },
    {
        countryCode: "BR",
        type: "br_cpf",
        name: "Brazilian CPF number",
        placeholder: "123.456.789-87",
        isLocal: true
    },
    {
        countryCode: "BG",
        type: "bg_uic",
        name: "Bulgaria Unified Identification Code",
        placeholder: "123456789",
        isLocal: true
    },
    {
        countryCode: "BG",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "BG0123456789"
    },
    {
        countryCode: "CA",
        type: "ca_bn",
        name: "Canadian BN",
        placeholder: "123456789",
        isLocal: true
    },
    {
        countryCode: "CA",
        type: "ca_gst_hst",
        name: "Canadian GST/HST number",
        placeholder: "123456789RT0002"
    },
    {
        countryCode: "CA",
        type: "ca_pst_bc",
        name: "Canadian PST number (British Columbia)",
        placeholder: "PST-1234-5678",
        isLocal: true
    },
    {
        countryCode: "CA",
        type: "ca_pst_mb",
        name: "Canadian PST number (Manitoba)",
        placeholder: "123456-7",
        isLocal: true
    },
    {
        countryCode: "CA",
        type: "ca_pst_sk",
        name: "Canadian PST number (Saskatchewan)",
        placeholder: "1234567",
        isLocal: true
    },
    {
        countryCode: "CA",
        type: "ca_qst",
        name: "Canadian QST number (Québec)",
        placeholder: "1234567890TQ1234",
        isLocal: true
    },
    {
        countryCode: "CL",
        type: "cl_tin",
        name: "Chilean TIN",
        placeholder: "12.345.678-K"
    },
    {
        countryCode: "CN",
        type: "cn_tin",
        name: "Chinese tax ID",
        placeholder: "123456789012345678"
    },
    {
        countryCode: "CO",
        type: "co_nit",
        name: "Colombian NIT number",
        placeholder: "123.456.789-0"
    },
    {
        countryCode: "CR",
        type: "cr_tin",
        name: "Costa Rican tax ID",
        placeholder: "1-234-567890"
    },
    {
        countryCode: "HR",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "HR12345678912"
    },
    {
        countryCode: "CY",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "CY12345678Z"
    },
    {
        countryCode: "CZ",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "CZ1234567890"
    },
    {
        countryCode: "DK",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "DK12345678"
    },
    {
        countryCode: "DO",
        type: "do_rcn",
        name: "Dominican RCN number",
        placeholder: "123-4567890-1"
    },
    {
        countryCode: "EC",
        type: "ec_ruc",
        name: "Ecuadorian RUC number",
        placeholder: "1234567890001"
    },
    {
        countryCode: "EG",
        type: "eg_tin",
        name: "Egyptian Tax Identification Number",
        placeholder: "123456789"
    },
    {
        countryCode: "SV",
        type: "sv_nit",
        name: "El Salvadorian NIT number",
        placeholder: "1234-567890-123-4"
    },
    {
        countryCode: "EE",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "EE123456789"
    },
    {
        countryCode: "FI",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "FI12345678"
    },
    {
        countryCode: "FR",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "FRAB123456789"
    },
    {
        countryCode: "GE",
        type: "ge_vat",
        name: "Georgian VAT",
        placeholder: "123456789"
    },
    {
        countryCode: "DE",
        type: "de_stn",
        name: "German Tax Number (Steuernummer)",
        placeholder: "1234567890",
        isLocal: true
    },
    {
        countryCode: "DE",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "DE123456789"
    },
    {
        countryCode: "GR",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "EL123456789"
    },
    {
        countryCode: "HK",
        type: "hk_br",
        name: "Hong Kong BR number",
        placeholder: "12345678"
    },
    {
        countryCode: "HU",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "HU12345678"
    },
    {
        countryCode: "HU",
        type: "hu_tin",
        name: "Hungary tax number (adószám)",
        placeholder: "12345678-1-23",
        isLocal: true
    },
    {
        countryCode: "IS",
        type: "is_vat",
        name: "Icelandic VAT",
        placeholder: "123456"
    },
    {
        countryCode: "IN",
        type: "in_gst",
        name: "Indian GST number",
        placeholder: "12ABCDE3456FGZH"
    },
    {
        countryCode: "ID",
        type: "id_npwp",
        name: "Indonesian NPWP number",
        placeholder: "12.345.678.9-012.345"
    },
    {
        countryCode: "IE",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "IE1234567AB"
    },
    {
        countryCode: "IL",
        type: "il_vat",
        name: "Israel VAT",
        placeholder: "000012345"
    },
    {
        countryCode: "IT",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "IT12345678912"
    },
    {
        countryCode: "JP",
        type: "jp_cn",
        name: "Japanese Corporate Number (*Hōjin Bangō*)",
        placeholder: "1234567891234",
        isLocal: true
    },
    {
        countryCode: "JP",
        type: "jp_rn",
        name: "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
        placeholder: "12345",
        isLocal: true
    },
    {
        countryCode: "JP",
        type: "jp_trn",
        name: "Japanese Tax Registration Number (*Tōroku Bangō*)",
        placeholder: "T1234567891234",
        isLocal: true
    },
    {
        countryCode: "KZ",
        type: "kz_bin",
        name: "Kazakhstani Business Identification Number",
        placeholder: "123456789012"
    },
    {
        countryCode: "KE",
        type: "ke_pin",
        name: "Kenya Revenue Authority Personal Identification Number",
        placeholder: "P000111111A"
    },
    {
        countryCode: "LV",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "LV12345678912"
    },
    {
        countryCode: "LI",
        type: "li_uid",
        name: "Liechtensteinian UID number",
        placeholder: "CHE123456789"
    },
    {
        countryCode: "LT",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "LT123456789123"
    },
    {
        countryCode: "LU",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "LU12345678"
    },
    {
        countryCode: "MY",
        type: "my_frp",
        name: "Malaysian FRP number",
        placeholder: "12345678",
        isLocal: true
    },
    {
        countryCode: "MY",
        type: "my_itn",
        name: "Malaysian ITN",
        placeholder: "C 1234567890",
        isLocal: true
    },
    {
        countryCode: "MY",
        type: "my_sst",
        name: "Malaysian SST number",
        placeholder: "A12-3456-78912345",
        isLocal: true
    },
    {
        countryCode: "MT",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "MT12345678"
    },
    {
        countryCode: "MX",
        type: "mx_rfc",
        name: "Mexican RFC number",
        placeholder: "ABC010203AB9"
    },
    {
        countryCode: "NL",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "NL123456789B12"
    },
    {
        countryCode: "NZ",
        type: "nz_gst",
        name: "New Zealand GST number",
        placeholder: "123456789"
    },
    {
        countryCode: "NG",
        type: "ng_tin",
        name: "Nigerian Tax Identification Number",
        placeholder: "12345678-0001"
    },
    {
        countryCode: "NO",
        type: "no_vat",
        name: "Norwegian VAT number",
        placeholder: "123456789MVA"
    },
    {
        countryCode: "NO",
        type: "no_voec",
        name: "Norwegian VAT on e-commerce number",
        placeholder: "1234567"
    },
    {
        countryCode: "OM",
        type: "om_vat",
        name: "Omani VAT Number",
        placeholder: "OM1234567890"
    },
    {
        countryCode: "PE",
        type: "pe_ruc",
        name: "Peruvian RUC number",
        placeholder: "12345678901"
    },
    {
        countryCode: "PH",
        type: "ph_tin",
        name: "Philippines Tax Identification Number",
        placeholder: "123456789012"
    },
    {
        countryCode: "PL",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "PL1234567890"
    },
    {
        countryCode: "PT",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "PT123456789"
    },
    {
        countryCode: "RO",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "RO1234567891"
    },
    {
        countryCode: "RO",
        type: "ro_tin",
        name: "Romanian tax ID number",
        placeholder: "1234567890123",
        isLocal: true
    },
    {
        countryCode: "RU",
        type: "ru_inn",
        name: "Russian INN",
        placeholder: "1234567891"
    },
    {
        countryCode: "RU",
        type: "ru_kpp",
        name: "Russian KPP",
        placeholder: "123456789",
        isLocal: true
    },
    {
        countryCode: "SA",
        type: "sa_vat",
        name: "Saudi Arabia VAT",
        placeholder: "123456789012345"
    },
    {
        countryCode: "RS",
        type: "rs_pib",
        name: "Serbian PIB number",
        placeholder: "123456789"
    },
    {
        countryCode: "SG",
        type: "sg_gst",
        name: "Singaporean GST",
        placeholder: "M12345678X"
    },
    {
        countryCode: "SG",
        type: "sg_uen",
        name: "Singaporean UEN",
        placeholder: "123456789F",
        isLocal: true
    },
    {
        countryCode: "SK",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "SK1234567891"
    },
    {
        countryCode: "SI",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "SI12345678"
    },
    {
        countryCode: "SI",
        type: "si_tin",
        name: "Slovenia tax number (davčna številka)",
        placeholder: "12345678",
        isLocal: true
    },
    {
        countryCode: "ZA",
        type: "za_vat",
        name: "South African VAT number",
        placeholder: "4123456789"
    },
    {
        countryCode: "KR",
        type: "kr_brn",
        name: "Korean BRN",
        placeholder: "123-45-67890"
    },
    {
        countryCode: "ES",
        type: "es_cif",
        name: "Spanish NIF number (previously Spanish CIF number)",
        placeholder: "A12345678",
        isLocal: true
    },
    {
        countryCode: "ES",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "ESA1234567Z"
    },
    {
        countryCode: "SE",
        type: "eu_vat",
        name: "European VAT number",
        placeholder: "SE123456789123"
    },
    {
        countryCode: "CH",
        type: "ch_vat",
        name: "Switzerland VAT number",
        placeholder: "CHE-123.456.789 MWST"
    },
    {
        countryCode: "TW",
        type: "tw_vat",
        name: "Taiwanese VAT",
        placeholder: "12345678"
    },
    {
        countryCode: "TH",
        type: "th_vat",
        name: "Thai VAT",
        placeholder: "1234567891234"
    },
    {
        countryCode: "TR",
        type: "tr_tin",
        name: "Turkish Tax Identification Number",
        placeholder: "0123456789"
    },
    {
        countryCode: "UA",
        type: "ua_vat",
        name: "Ukrainian VAT",
        placeholder: "123456789"
    },
    {
        countryCode: "AE",
        type: "ae_trn",
        name: "United Arab Emirates TRN",
        placeholder: "123456789012345"
    },
    {
        countryCode: "GB",
        type: "eu_vat",
        name: "Northern Ireland VAT number",
        placeholder: "XI123456789",
        isLocal: true
    },
    {
        countryCode: "GB",
        type: "gb_vat",
        name: "United Kingdom VAT number",
        placeholder: "GB123456789"
    },
    {
        countryCode: "US",
        type: "us_ein",
        name: "United States EIN",
        placeholder: "12-3456789"
    },
    {
        countryCode: "UY",
        type: "uy_ruc",
        name: "Uruguayan RUC number",
        placeholder: "123456789012"
    },
    {
        countryCode: "VE",
        type: "ve_rif",
        name: "Venezuelan RIF number",
        placeholder: "A-12345678-9"
    },
    {
        countryCode: "VN",
        type: "vn_tin",
        name: "Vietnamese tax ID number",
        placeholder: "1234567890"
    }
];
