const variations = {

    tasklist: [
        {
            label: "Kanban Board",
            template: "kanban"
        }, {
            label: "Horizontal Task List",
            template: "horizontal-task-list"
        }
    ],

    calendar: [
        {
            label: "Month Calendar",
            template: "calendar-month",
            model: {
                primary: {
                    calendarType: "month"
                }
            }
        }, {
            label: "Week Calendar",
            template: "calendar-week",
            model: {
                primary: {
                    calendarType: "week"
                }
            }
        }
    ],

    target: [
        {
            label: "Target",
            template: "target",
            icon: "target",
            model: {
                primary: {
                    items: [
                        {
                            segments: [{
                                color: "auto"
                            }],
                        }, {
                            segments: [{
                                color: "auto"
                            }],
                        }, {
                            segments: [{
                                color: "auto"
                            }],
                        }
                    ]
                }
            }
        }, {
            label: "Segmented Ring",
            template: "target",
            icon: "segmented-ring",
            model: {
                primary: {
                    innerRadius: 80,
                    items: [
                        {
                            segments: [{
                                color: "auto",
                                labelType: "inside",
                                label: "Section 1"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 2"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 3"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 4"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 5"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 6"
                            }]
                        }
                    ]
                }
            }
        }

    ],

    title: [
        {
            label: "Plain",
            icon: "title",
            model: {
                primary: {
                    decoration: "none"
                }
            }
        },
        {
            label: "Left Bar",
            icon: "title_bar_left",
            model: {
                primary: {
                    decoration: "left_border"
                }
            }
        },
        {
            label: "Top Bar",
            icon: "title_bar_top",
            model: {
                primary: {
                    decoration: "top_border"
                }
            }
        },
        {
            label: "Border",
            icon: "title_border",
            model: {
                primary: {
                    decoration: "frame"
                }
            }
        },
        {
            label: "Block",
            icon: "title_block",
            model: {
                primary: {
                    decoration: "block"
                }
            }
        },
        {
            label: "Underline",
            icon: "title_underline",
            model: {
                primary: {
                    decoration: "underline"
                }
            }
        },

    ],

    compare: [
        // {
        //     label: "Arrow Bars",
        //     template: "arrow_bars"
        // },
        {
            label: "Radial Bars",
            template: "compare_radial_bars"
        }, {
            label: "Circles",
            template: "compare_circles"
        }, {
            label: "Vertical Bars",
            template: "compare_vertical_bars"
        }, {
            label: "Horizontal Bars",
            template: "compare_horizontal_bars"
        }, {
            label: "Vertical Pictograph",
            template: "compare_vertical_pictograph"
        }
    ],

    stackdiagram: [
        {
            label: "Hierarchy",
            template: "stackDiagram",
            icon: "stack_hierarchy",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 12, height: 4
                    }, {
                        x: 0, y: 4, width: 6, height: 4
                    }, {
                        x: 6, y: 4, width: 6, height: 4
                    }, {
                        x: 0, y: 8, width: 4, height: 4
                    }, {
                        x: 4, y: 8, width: 4, height: 4
                    }, {
                        x: 8, y: 8, width: 4, height: 4
                    }]
                }
            }
        },
        {
            label: "Architecture",
            template: "stackDiagram",
            icon: "stack_architecture",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 12, height: 3
                    }, {
                        x: 0, y: 3, width: 3, height: 6
                    }, {
                        x: 3, y: 3, width: 3, height: 6
                    }, {
                        x: 6, y: 3, width: 3, height: 6
                    }, {
                        x: 9, y: 3, width: 3, height: 6
                    }, {
                        x: 0, y: 9, width: 12, height: 3
                    }]
                }
            }
        },
        {
            label: "Side By Side",
            template: "stackDiagram",
            icon: "stack_sidebyside",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, height: 6, width: 3
                    }, {
                        x: 0, y: 6, height: 3, width: 6
                    }, {
                        x: 3, y: 0, width: 3, height: 6,
                    }, {
                        x: 6, y: 0, width: 3, height: 6,
                    }, {
                        x: 0, y: 9, width: 6, height: 3,
                    }, {
                        x: 6, y: 6, width: 6, height: 3,
                    }, {
                        x: 9, y: 0, width: 3, height: 6,
                    }, {
                        x: 6, y: 9, width: 6, height: 3,
                    }]
                }
            }
        },
        {
            label: "Four Square",
            template: "stackDiagram",
            icon: "stack_foursquare",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 6, height: 6
                    }, {
                        x: 0, y: 6, width: 6, height: 6
                    }, {
                        x: 6, y: 0, width: 6, height: 6
                    }, {
                        x: 6, y: 6, width: 6, height: 6
                    }]
                }
            }
        },
    ],

    pyramid: [
        {
            label: "Pyramid",
            template: "pyramid",
            icon: "pyramid",
            model: {
                primary: {
                    capSize: 0,
                    baseSize: 60,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Funnel",
            template: "pyramid",
            icon: "funnel",
            model: {
                primary: {
                    capSize: 65,
                    baseSize: 15,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Wedge",
            template: "pyramid",
            icon: "pyramid_wedge",
            model: {
                primary: {
                    capSize: 35,
                    baseSize: 65,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Volcano",
            template: "pyramid",
            icon: "pyramid_volcano",
            model: {
                primary: {
                    capSize: 7,
                    baseSize: 65,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Aztec Pyramid",
            template: "pyramid",
            icon: "pyramid_step",
            model: {
                primary: {
                    capSize: 0,
                    baseSize: 60,
                    step: true,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Staircase",
            template: "pyramid",
            icon: "pyramid_staircase",
            model: {
                primary: {
                    capSize: 10,
                    baseSize: 40,
                    step: true,
                    inverted: false,
                    capAlign: "left"
                }
            }
        },
    ],

    list: [{
        label: "Icon List",
        template: "textgrid_vertical_icons"
    },
    {
        label: "Bullets",
        template: "textlist",
        model: {
            primary: {
                listStyle: "bullets"
            }
        }
    },
    {
        label: "Numbered",
        template: "textlist",
        icon: "textgrid_numbered",
        model: {
            primary: {
                listStyle: "numbered"
            }
        }
    },
    {
        label: "Photo List",
        template: "textgrid_photos"
    },
    {
        label: "Carousel",
        template: "textgrid_carousel",
        cleanupMethod: "onRemoveCarouselVariation"
    },
    {
        label: "Text Boxes",
        template: "textBoxGrid"
    }],

    process: [{
        label: "Process Diagram",
        template: "step_chevrons"
    }, {
        label: "Numbered Boxes",
        template: "step_boxes"
    }],

    nodes: [{
        label: "Looping Process",
        template: "step_circular",
        getModel: slide => {
            const prevTemplate = slide.get("template_id");

            const model = {
                primary: {
                    shape_is_polygon: false
                }
            };

            if (prevTemplate === "hub_and_spoke") {
                model.primary.connectors = null;
            }

            return model;
        }
    }, {
        label: "Straight-line Looping Process",
        template: "step_circular",
        getModel: slide => {
            const prevTemplate = slide.get("template_id");

            const model = {
                primary: {
                    shape_is_polygon: true
                }
            };

            if (prevTemplate === "hub_and_spoke") {
                model.primary.connectors = null;
            }

            return model;
        }
    }, {
        label: "Hub and Spoke",
        template: "hub_and_spoke",
        getModel: slide => {
            const prevTemplate = slide.get("template_id");

            if (prevTemplate === "step_circular") {
                return {
                    primary: {
                        connectors: null
                    }
                };
            }

            return {};
        }
    }],

    timeline: [{
        label: "Timeline",
        template: "timeline",
        getModel: slide => {
            const prevTemplate = slide.get("template_id");

            const primary = slide.dataState.elements.primary;
            if (prevTemplate !== "timeline") {
                const primary = slide.dataState.elements.primary;

                primary.items.forEach((item, index) => {
                    item.x = (index + 1) / (primary.items.length + 1);
                });

                if (primary.milestoneStyle) {
                    primary.showContent = primary.milestoneStyle === "with-icons";
                }
            }

            return {
                primary,
            };
        }
    }, {
        label: "Journey",
        template: "journey",
        getModel: slide => {
            const prevTemplate = slide.get("template_id");

            const primary = slide.dataState.elements.primary;
            if (prevTemplate === "timeline") {
                const primary = slide.dataState.elements.primary;

                primary.items = primary.items.sort((a, b) => {
                    return a.x < b.x ? -1 : 1;
                });

                if (primary.showContent) {
                    primary.milestoneStyle = "with-icons";
                } else if (primary.milestoneStyle === "with-icons") {
                    primary.milestoneStyle = "no-icons";
                }
            }

            return {
                primary,
            };
        }
    }],

    imagegrid: [{
        label: "Headline With Images",
        template: "headline_image",
        model: {
            primary: {
                headlinePosition: "left"
            }
        }
    }, {
        label: "Image And Text Collage",
        template: "collage"
    }],

    xygraph: [{
        label: "XY Plot",
        template: "xygraph",
        icon: "chart_scatter",
        model: {
            primary: {
                showAxis: true,
                showGrid: true,
                showAxisLabels: true,
                showArrowHeads: true,
                showQuadrants: false,
                showQuadrantLabels: false
            }
        }
    }, {
        label: "Quadrants",
        template: "xygraph",
        icon: "xy_axis",
        model: {
            primary: {
                showAxis: false,
                showGrid: true,
                showAxisLabels: false,
                showArrowHeads: true,
                showQuadrants: true,
                showQuadrantLabels: true
            }
        }
    }, {
        label: "Bubbles",
        template: "xygraph",
        icon: "bubbles",
        model: {
            primary: {
                showAxis: false,
                showGrid: false,
                getShowAxisLabels: false,
                showArrowHeads: false,
                showQuadrants: false,
                showQuadrantLabels: false
            }
        }
    }],

    chart: [{
        label: "One Chart",
        icon: "charts_one",
        callbackMethod: "setItemCount",
        callbackArguments: 1
    }, {
        label: "Two Charts",
        icon: "charts_two",
        callbackMethod: "setItemCount",
        callbackArguments: 2
    }, {
        label: "Three Charts",
        icon: "charts_three",
        callbackMethod: "setItemCount",
        callbackArguments: 3
    }, {
        label: "Four Charts",
        icon: "charts_four",
        callbackMethod: "setItemCount",
        callbackArguments: 4
    }],

    contact: [{
        label: "Map",
        icon: "",
        model: {
            primary: {
                styleVariation: "left_map"
            }
        }
    }, {
        label: "Picture On Left",
        icon: "",
        model: {
            primary: {
                styleVariation: "left_picture"
            }
        }
    }, {
        label: "Picture On Top",
        icon: "",
        model: {
            primary: {
                styleVariation: "top_picture"
            }
        }
    }],

};

module.exports = variations;
