import { controls } from "legacy-js/editor/ui";

import { TextElementSelection } from "./textEditor";
import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";

const JourneySelection = CollectionElementSelection.extend({

    renderControls: function() {
        this.createAddItemButton();
    }
});

const JourneyItemSelection = CollectionItemElementSelection.extend({

    dropTargetOverlapTreshold: 0.0,

    renderControls: function() {
        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            includeAuto: true,
            property: "color",
        });
    },

    getDropTargets: function(containerElement) {
        return containerElement.itemElements;
    },

    calcDropScore: function(dragBounds, target, position) {
        // We only care that the drag position overlaps
        //  the target bounds for scoring
        const { x, y } = position.elementPosition;
        return target.bounds.contains(x, y) ? 100 : 0;
    },

    getName: function() {
        return `JourneyItem #${this.element.getItemIndex() + 1}`;
    },
});

const JourneyStartSelection = TextElementSelection.extend({
    getCursor: function() {
        return "text";
    },

    renderControls: function() {
        TextElementSelection.prototype.renderControls.apply(this, arguments);

        this.createDeleteComponentWidget({
            target: this.element,
            action: () => {
                if (this.element.id === "start_marker") {
                    this.element.getRootElement().model.showStartMarker = false;
                } else
                if (this.element.id === "end_marker") {
                    this.element.getRootElement().model.showEndMarker = false;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });

        if (this.element.id === "end_marker") {
            this.addControl({
                type: controls.COLOR_PALETTE_PICKER,
                includeAuto: true,
                property: "endColor",
            });
        }
    }
});

const JourneyEndSelection = ElementSelection.extend({
    getCursor: function() {
        return "text";
    },

    renderControls: function() {
        this.createDeleteComponentWidget({
            target: this.element,
            action: () => {
                this.element.getRootElement().model.showEndMarker = false;
                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            includeAuto: true,
            property: "color",
        });
    }
});

const JourneyOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        //this.addControl({
        //    type: controls.NUMERIC,
        //    label: "Scale",
        //    property: "scale",
        //    min: "1",
        //    max: "20",
        //    step: ".1"
        //});
        this.addControl({
            type: controls.TOGGLE,
            label: "Start Marker",
            property: "showStartMarker"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "End Marker",
            property: "showEndMarker"
        });

        // this.addControl({
        //     type: controls.TOGGLE,
        //     label: "Milestone Icons",
        //     property: "withIcons"
        // });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Milestone Style",
            property: "milestoneStyle",
            transitionModel: false,
            items: [
                {
                    value: "no-icons",
                    label: "Map Pin",
                    icon: "location_on",
                },
                {
                    value: "with-icons",
                    label: "Icon",
                    icon: "grade",
                    // icon: getStaticUrl("/images/journey/menu-icons/icon-pin-image.svg"),
                },
                {
                    value: "dots",
                    label: "Dot",
                    icon: "fiber_manual_record",
                    // icon: "/images/journey/menu-icons/icon-dot.svg",
                },
            ]
        });
    }
});

export const editors = {
    JourneySelection,
    JourneyItemSelection,
    JourneyStartSelection,
    JourneyEndSelection,
    JourneyOptionsMenu,
};
