const classicTemplates = [
    {
        id: "blank",
        icon: "classic-blank",
        title: "Blank",
        model: {}
    },
    {
        id: "classic-title",
        icon: "classic-title",
        title: "Title Slide",
        model: {
            elements: [
                {
                    "type": "shape",
                    "width": 920.171875,
                    "height": 223.1875,
                    "shape": "rect",
                    "fill": "none",
                    "stroke": "none",
                    "textAlign": "left",
                    "verticalAlign": "bottom",
                    "fitToText": false,
                    "blocks": [
                        {
                            "type": "text",
                            "textStyle": "heading",
                            "html": "",
                            "textAlign": "center"
                        }
                    ],
                    "x": 180,
                    "y": 147,
                    "isLocked": false,
                    "shadow": "none",
                    "opacity": 100,
                    "strokeWidth": 1,
                    "strokeStyle": "solid",
                    "blockGap": 0,
                    "textInset": 20
                },
                {
                    "type": "shape",
                    "width": 920.171875,
                    "height": 65.625,
                    "shape": "rect",
                    "fill": "none",
                    "stroke": "none",
                    "textAlign": "left",
                    "verticalAlign": "top",
                    "fitToText": true,
                    "blocks": [
                        {
                            "type": "text",
                            "textStyle": "title",
                            "html": "",
                            "textAlign": "center"
                        }
                    ],
                    "x": 179.9140625,
                    "y": 384.1875,
                    "isLocked": false,
                    "shadow": "none",
                    "opacity": 100,
                    "strokeWidth": 1,
                    "strokeStyle": "solid",
                    "blockGap": 0,
                    "textInset": 20
                }
            ]
        }
    },
    {
        id: "classic-text",
        icon: "classic-text",
        title: "Text",
        model: {
            elements: [
                {
                    "type": "header",
                    "x": 0,
                    "y": 0,
                    "width": 1280,
                    "height": 137.1,
                    "headerPosition": "top",
                    "isLocked": false,
                    "label": {
                        "text": ""
                    },
                    "title": {
                        "text": ""
                    },
                    "shadow": "none",
                    "opacity": 100
                },
                {
                    "type": "shape",
                    "width": 1200,
                    "height": 494,
                    "shape": "rect",
                    "fill": "none",
                    "stroke": "none",
                    "textAlign": "left",
                    "verticalAlign": "top",
                    "fitToText": false,
                    "x": 40,
                    "y": 186,
                    "isLocked": false,
                    "blockGap": 0,
                    "textInset": 20,
                    "blocks": [
                        {
                            "type": "text",
                            "textStyle": "body",
                            "html": ""
                        }
                    ],
                    "shadow": "none",
                    "opacity": 100,
                    "strokeWidth": 1,
                    "strokeStyle": "solid"
                }
            ]
        }
    },
    {
        id: "classic-title-only",
        icon: "classic-title-only",
        title: "Title only",
        model: {
            elements: [
                {
                    "type": "header",
                    "x": 0,
                    "y": 0,
                    "width": 1280,
                    "height": 137.1,
                    "headerPosition": "top",
                    "isLocked": false,
                    "label": {
                        "text": ""
                    },
                    "title": {
                        "text": ""
                    },
                    "shadow": "none",
                    "opacity": 100
                }
            ]
        }
    },
    {
        id: "classic-text-and-image",
        icon: "classic-text-and-image",
        title: "Text and Image",
        model: {
            elements: [
                {
                    "type": "header",
                    "x": 0,
                    "y": 0,
                    "width": 1280,
                    "height": 137.1,
                    "headerPosition": "top",
                    "isLocked": false,
                    "label": {
                        "text": ""
                    },
                    "title": {
                        "text": ""
                    },
                    "shadow": "none",
                    "opacity": 100
                },
                {
                    "type": "shape",
                    "width": 571,
                    "height": 494,
                    "shape": "rect",
                    "fill": "none",
                    "stroke": "none",
                    "textAlign": "left",
                    "verticalAlign": "top",
                    "fitToText": false,
                    "x": 40,
                    "y": 186,
                    "isLocked": false,
                    "blockGap": 0,
                    "textInset": 20,
                    "blocks": [
                        {
                            "type": "text",
                            "textStyle": "body",
                            "html": ""
                        }
                    ]
                },
                {
                    "type": "content",
                    "x": 640,
                    "y": 186,
                    "width": 600.1368301026225,
                    "height": 494.1391106043329,
                    "element": {
                        "content_value": "114f5dd4b952fa49867c902d1054d886ca0c084ee517d9b8c842e18816641b41-I",
                        "content_type": "image",
                        "aoiLeft": 0,
                        "aoiRight": 4272,
                        "aoiTop": 0,
                        "aoiBottom": 2848,
                        "scale": 0.17350390119534162,
                        "version": 1,
                        "frameType": "none"
                    },
                    "isLocked": false,
                    "opacity": 100
                }
            ]
        }
    },
    {
        id: "classic-two-columns",
        title: "Two Columns",
        icon: "classic-two-columns",
        model: {
            elements: [
                {
                    "type": "header",
                    "x": 0,
                    "y": 0,
                    "width": 1280,
                    "height": 137.1,
                    "headerPosition": "top",
                    "isLocked": false,
                    "title": {
                        "text": ""
                    },
                    "shadow": "none",
                    "opacity": 100,
                },
                {
                    "type": "shape",
                    "width": 585.9977194982896,
                    "height": 494,
                    "shape": "rect",
                    "fill": "none",
                    "stroke": "none",
                    "textAlign": "left",
                    "verticalAlign": "top",
                    "fitToText": false,
                    "x": 40,
                    "y": 186,
                    "isLocked": false,
                    "blockGap": 0,
                    "textInset": 20,
                    "shadow": "none",
                    "opacity": 100,
                    "strokeWidth": 1,
                    "strokeStyle": "solid",
                    "blocks": [
                        {
                            "type": "text",
                            "textStyle": "body",
                            "html": ""
                        }
                    ]
                },
                {
                    "type": "shape",
                    "width": 585.9977194982896,
                    "height": 494,
                    "shape": "rect",
                    "fill": "none",
                    "stroke": "none",
                    "textAlign": "left",
                    "verticalAlign": "top",
                    "fitToText": false,
                    "x": 654.0022805017104,
                    "y": 186,
                    "isLocked": false,
                    "blockGap": 0,
                    "textInset": 20,
                    "blocks": [
                        {
                            "type": "text",
                            "textStyle": "body",
                            "html": ""
                        }
                    ],
                    "shadow": "none",
                    "opacity": 100,
                    "strokeWidth": 1,
                    "strokeStyle": "solid"
                }
            ],
        }
    }
];

classicTemplates.forEach(template => template.version = 9);

module.exports = classicTemplates;
