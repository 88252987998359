import styled from "styled-components";
import { app } from "js/namespaces";
import { themeColors } from "legacy-js/react/sharedStyles";

export const SelectionBox = styled.div.attrs(({ showBorder = true, bounds, rotate }) => ({
    style: {
        outline: showBorder ? `dotted 1px ${themeColors.ui_blue}` : "none",
        transform: `rotate(${rotate}deg)`,
        ...bounds.multiply(app.currentCanvas.canvasScale).toObject()
    }
}))`
    position: absolute;
    font-size: 20px;
    pointer-events: auto;
    //cursor: move;
`;

export const RolloverBox = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.multiply(app.currentCanvas.canvasScale).toObject()
    }
}))`
    position: absolute;
    pointer-events: none;
    outline: dotted 1px #b5d2de;
`;

export const BoundsBox = styled.div.attrs(({ bounds, rotate }) => ({
    style: {
        transform: `rotate(${rotate}deg)`,
        ...bounds.toObject()
    }
}))`
    position: absolute;
    outline: ${props => props.showOutline ? `dotted 1px ${themeColors.ui_blue}` : "none"};
`;

export const ReverseScaleBox = styled.div.attrs(({ bounds }) => ({
    style: {
        width: bounds.width / app.currentCanvas.canvasScale,
        height: bounds.height / app.currentCanvas.canvasScale,
        transform: `scale(${app.currentCanvas.canvasScale})`
    }
}))`
    position: absolute;
    left: 0px;
    top: 0px;
    transform-origin: 0 0;
`;
