import React, { Component } from "react";
import { MenuItem } from "@material-ui/core";

import { PopupMenu } from "legacy-js/react/components/PopupMenu";

import { ControlBar } from "../../../EditorComponents/ControlBar";

export class DividerBlockEditor extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = {
            style: props.block.model.style,
            dividerWidth: props.block.model.dividerWidth
        };
    }

    componentDidMount() {
        // Remove current selection
        window.getSelection().removeAllRanges();
    }

    handleChangeStyle = value => {
        const { block, refreshCanvasAndSaveChanges } = this.props;

        this.setState({ style: value });
        block.model.style = value;
        refreshCanvasAndSaveChanges();
    }

    handleChangeWidth = value => {
        const { block, refreshCanvasAndSaveChanges } = this.props;

        this.setState({ dividerWidth: value });
        block.model.dividerWidth = value;
        refreshCanvasAndSaveChanges();
    }

    render() {
        const { containerBounds } = this.props;

        return (
            <ControlBar bounds={containerBounds} position="above" offset={10}>
                <PopupMenu label="Style" showArrow onChange={this.handleChangeStyle} childrenAreMenuItems>
                    <MenuItem value="spacer">Spacer</MenuItem>
                    <MenuItem value="line">Line</MenuItem>
                    <MenuItem value="dashed">Dashed</MenuItem>
                    <MenuItem value="dotted">Dotted</MenuItem>
                    <MenuItem value="fancy">Fancy</MenuItem>
                </PopupMenu>
                <PopupMenu label="Width" showArrow onChange={this.handleChangeWidth} childrenAreMenuItems>
                    <MenuItem value="full">Full</MenuItem>
                    <MenuItem value="half">Half</MenuItem>
                    <MenuItem value="short">Short</MenuItem>
                </PopupMenu>
            </ControlBar>
        );
    }
}
