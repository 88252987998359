import React, { Component } from "react";
import styled from "styled-components";
import { Icon, MenuItem } from "@material-ui/core";
import { PopupMenu } from "legacy-js/react/components/PopupMenu";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 0px;
    height: 34px;
    border: none;
    .MuiIcon-root {
      opacity: 0.5;
      font-size: 20px;
    }
    label {
      font-size: 15px;
    }
`;

const StyledInput = styled.input`
    width: 100%;
    border: none;
    background: none;
    text-align: right;
    font-weight: 600;
    font-size: 16px;
    outline: none;
    position: relative;
    top: -1px;
  
     /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`;

export class NumberStepper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ?? "",
            isChanged: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    handleSelect = value => {
        this.props.onChange(parseInt(value));
    }

    handleCommitValue = () => {
        if (!this.state.isChanged) return;

        const { minValue = 0, maxValue = 99 } = this.props;
        this.props.onChange(parseInt(Math.clamp(this.state.value, minValue, maxValue)));
    }

    handleChange = event => {
        this.setState({ value: event.target.value, isChanged: true });
    }

    handleFocus = event => {
        event.target.select();
    }

    handleDecrementValue = event => {
        let { value, minValue = 0, maxValue = 99 } = this.props;

        if (event.shiftKey) {
            value -= 10;
        } else {
            value -= 1;
        }
        value = parseInt(Math.clamp(value, minValue, maxValue));
        this.props.onChange(value);
    }

    handleIncrementValue = event => {
        let { value, minValue = 0, maxValue = 99 } = this.props;

        if (event.shiftKey) {
            value += 10;
        } else {
            value += 1;
        }
        value = parseInt(Math.clamp(value, minValue, maxValue));
        this.props.onChange(value);
    }

    render() {
        const { value, menuItems } = this.props;

        return (
            <Container>
                <Icon onClick={this.handleDecrementValue}>keyboard_arrow_down</Icon>
                <PopupMenu
                    label={value}
                    onChange={this.handleSelect}
                    style={{ padding: "0px 3px" }}
                    childrenAreMenuItems
                >
                    {menuItems.map(value => <MenuItem onMouseDown={event => event.preventDefault()} key={value} value={value}>{value}</MenuItem>)}
                </PopupMenu>
                <Icon onClick={this.handleIncrementValue}>keyboard_arrow_up</Icon>
            </Container>
        );
    }
}
