class DataService {
    protected static SEARCH_QUERY_SIZE: number = 500;
    protected static SEARCH_QUERY_MIN_SCORE: number = Number.NEGATIVE_INFINITY;

    protected preparePromise: Promise<void>;

    protected async prepare(): Promise<void> {
        throw new Error("Not implemented");
    }

    public async ensurePrepared(): Promise<void> {
        if (!this.preparePromise) {
            this.preparePromise = this.prepare();
        }
        await this.preparePromise;
    }
}

export default DataService;
