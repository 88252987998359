import React from "react";
import styled from "styled-components";

export const Gap5 = props => <Gap {...props} size={5} />;
export const Gap10 = props => <Gap {...props} size={10} />;
export const Gap15 = props => <Gap {...props} size={15} />;
export const Gap20 = props => <Gap {...props} size={20} />;
export const Gap30 = props => <Gap {...props} size={30} />;
export const Gap50 = props => <Gap {...props} size={50} />;

export const Gap = ({ size, className = "", ...rest }) => {
    return (
        <div
            {...rest}
            data-testid="gap"
            className={`gap ${className}`}
            style={{
                width: size,
                height: size,
                flexShrink: 0,
            }}
        />
    );
};

const StyledFlexSpacer = styled.div`
    flex-grow: ${props => props.flexGrow};
`;

export const FlexSpacer = ({ flexGrow = 2, children = null }) => {
    return <StyledFlexSpacer style={{ flexGrow }}>{children}</StyledFlexSpacer>;
};

