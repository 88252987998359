import * as geom from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";
import { getValueOrDefault } from "js/core/utilities/extensions";
import { VerticalAlignType, HorizontalAlignType } from "legacy-common/constants";
import { Shape } from "js/core/utilities/shapes";

import { TextElement } from "../base/TextElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextGroup } from "../base/TextGroup";
import { Icon } from "../base/MediaElements/IconElement";
import { SVGPathElement } from "../base/SVGElement";

export class HorizontalTaskLists extends CollectionElement {
    static get schema() {
        return {
            showTitles: true,
            itemStyle: "task",
            taskLayout: "stack"
        };
    }

    getChildItemType() {
        return HorizontalTaskListsRow;
    }

    get lockColumns() {
        return getValueOrDefault(this.options.lockColumns, false);
    }

    get itemStyle() {
        return this.model.itemStyle;
    }

    get showTitles() {
        return this.model.showTitles;
    }

    get taskLayout() {
        return this.model.taskLayout;
    }

    _calcProps(props, options) {
        let { size } = props;

        let maxTitleWidth = 0;
        if (this.showTitles) {
            for (let row of this.itemElements) {
                maxTitleWidth = Math.max(maxTitleWidth, row.rowHeader.calcProps(size, { autoWidth: true }).size.width);
            }
            maxTitleWidth = Math.min(200, maxTitleWidth);

            // const MAX_ROW_HEADER_WIDTH = 200;
            // // calculate the optimal header width from all the rows
            // for (let row of this.itemElements) {
            //     let rowHeaderProps = row.rowHeader.calcProps(new geom.Size(MAX_ROW_HEADER_WIDTH, size.height / this.itemCount), {
            //         autoWidth: true,
            //     });
            //     maxHeaderWidth = Math.max(maxHeaderWidth, rowHeaderProps.size.width);
            // }
        }

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcVerticalLayout({
            itemOptions: {
                titleWidth: maxTitleWidth
            }
        });
        layouter.alignHorizontally(HorizontalAlignType.CENTER);
        layouter.alignVertically(VerticalAlignType.MIDDLE);

        return { size };
    }
}

class HorizontalTaskListsRow extends CollectionItemElement {
    get selectionPadding() {
        return 10;
    }

    get canDrag() {
        return !this.parentElement.lockColumns;
    }

    get canDelete() {
        return !this.parentElement.lockColumns;
    }

    get showTitle() {
        return this.parentElement.showTitles;
    }

    _build() {
        if (this.showTitle) {
            this.rowHeader = this.addElement("label", () => RowHeaderTextElement, {
                placeholder: "Type title",
                autoWidth: true,
                autoHeight: true
                // forceRefreshOnKeyPress: true
            });
        }
        this.taskList = this.addElement("taskList", () => HorizontalTaskList);
    }

    _calcProps(props, options) {
        let { size } = props;

        // let headerWidth = 0;

        if (this.showTitle) {
            let headerProps = this.rowHeader.calcProps(new geom.Size(options.titleWidth, size.height), options);
            headerProps.bounds = new geom.Rect(0, size.height / 2 - headerProps.size.height / 2, headerProps.size);
            headerProps.bounds.left = options.titleWidth - headerProps.bounds.width;
        }

        let taskListProps = this.taskList.calcProps(new geom.Size(size.width - options.titleWidth, size.height));
        taskListProps.bounds = new geom.Rect(options.titleWidth, 0, taskListProps.size);

        return { size: new geom.Size(options.titleWidth + taskListProps.size.width, size.height) };
    }
}

class RowHeaderTextElement extends TextElement {
    get selectionPadding() {
        return 0;
    }
}

export class HorizontalTaskList extends CollectionElement {
    getChildItemType() {
        return HorizontalTaskElement;
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }

    get minItemCount() {
        return 1;
    }

    _loadStyles(styles) {
        styles.applyStyles(styles.itemStyle[this.getRootElement().itemStyle]);
    }

    _calcProps(props, options) {
        let { size } = props;
        let rootElement = this.getRootElement();

        let fixedItemWidth;

        switch (rootElement.taskLayout) {
            case "stack":
                // leave null so items try to use their calculated width
                break;
            case "fill":
                fixedItemWidth = (size.width - (this.itemCount - 1) * this.styles.hGap) / this.itemCount;
                break;
            case "fit":
                let maxRow = _.maxBy(this.getRootElement().itemCollection, c => c.items.length).items.length;
                fixedItemWidth = (size.width - (maxRow - 1) * this.styles.hGap) / maxRow;

                break;
        }

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.distributeHorizontally({
            verticalAlign: VerticalAlignType.MIDDLE,
            gap: this.styles.hGap,
            itemOptions: {
                fixedItemWidth: fixedItemWidth
            },
            reserveMinWidths: true
        });

        props.isFit = layouter.isFit;

        return { size: new geom.Size(layouter.size.width, size.height) };
    }
}

class HorizontalTaskElement extends CollectionItemElement {
    get minWidth() {
        return 100;
    }

    get selectionPadding() {
        return 0;
    }

    get hasIcon() {
        return this.model.icon && this.model.icon != "none";
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPathElement);

        if (this.hasIcon) {
            this.icon = this.addElement("icon", () => Icon, {
                icon: this.model.icon,
            });
        }

        this.text = this.addElement("text", () => TextGroup, {
            showAddButton: false,
            autoHeight: true,
            title: {
                allowAlignment: true,
                scaleTextToFit: true,
                uiOffset: 20,
                constrainWidth: true
            }
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        // if (this.getRootElement().itemStyle == "process" && this.itemIndex == 0) {
        //     this.styles.paddingLeft = 20;
        // } else {
        //     this.styles.paddingLeft = 15;
        // }
        //
        let availableSize = size.clone();

        // this.styles.paddingRight = this.hasIcon ? (this.styles.paddingLeft + 40) : this.styles.paddingLeft;

        if (options.fixedItemWidth) {
            size.width = options.fixedItemWidth - this.styles.paddingLeft - this.styles.paddingRight;
            if (this.hasIcon) {
                size.width -= 40;
            }
        }

        let textProps = this.text.calcProps(size, { autoWidth: !options.fixedItemWidth });
        textProps.bounds = new geom.Rect(0, size.height / 2 - textProps.size.height / 2, textProps.size);

        if (options.fixedItemWidth) {
            size = new geom.Size(options.fixedItemWidth - this.styles.paddingLeft - this.styles.paddingRight, size.height);
        } else {
            size = new geom.Size(textProps.size.width, size.height);
            if (this.hasIcon) {
                size.width += 40;
            }
        }

        if (this.hasIcon) {
            let iconProps = this.icon.calcProps(new geom.Size(30, 30));
            iconProps.bounds = new geom.Rect(size.width - iconProps.size.width, size.height / 2 - iconProps.size.height / 2, iconProps.size);
        }

        let shapeProps = this.shape.createProps();
        shapeProps.bounds = new geom.Rect(0, 0, size).inflate(this.styles.padding).zeroOffset();
        shapeProps.isDecoration = true;
        shapeProps.layer = -1;

        switch (this.getRootElement().itemStyle) {
            case "process":
                // if (this.itemIndex == 0) {
                //     this.styles.paddingLeft = 20;
                // }
                shapeProps.path = Shape.drawChevron(new geom.Rect(0, 0, size).inflate(this.styles.padding).zeroOffset().inflate({
                    left: 10,
                    right: 10
                }), 30, this.itemIndex == 0).toPathData();
                break;
            case "task":
            default:
                shapeProps.path = Shape.drawRect(new geom.Rect(0, 0, size).inflate(this.styles.padding).zeroOffset(), 6);
        }

        return { size };
    }

    getBackgroundColor(forElement) {
        if (forElement !== this.shape && forElement?.isChildOf(this)) {
            return this.getShapeFillColor(this.shape);
        }

        return super.getBackgroundColor(forElement);
    }
}

export const elements = {
    HorizontalTaskLists
};
