import React, { Component } from "react";
import { Button, DialogActions, DialogTitle, MenuItem, Select } from "@material-ui/core";

import { presentations as presentationsApi } from "apis/callables";
import { getStaticUrl } from "legacy-js/config";
import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";
import { FormIcon } from "legacy-js/react/views/Onboarding/Standalone";
import permissionsDS from "legacy-js/react/views/PresentationSettings/dataservices/PermissionsDataService";
import { _ } from "legacy-js/vendor";
import { FlexBox } from "../LayoutGrid";

const logger = getLogger(LogGroup.TEAMS);

const baiBotImage = getStaticUrl("/images/bai-bot/bai-bot-shake.gif");

export default class MoveWorkspacesDialog extends Component {
    constructor(props) {
        super(props);

        const {
            workspaces,
            currentWorkspaceId
        } = props;

        this.state = {
            isLoading: true,
            workspace: currentWorkspaceId === "personal" ? workspaces.find(w => w.id !== "personal").id : "personal",
            movedPresentations: [],
            unmovedPresentations: [],
            presentationPermissions: {},
            uniquePermisions: []
        };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { presentations } = this.props;

        const movedPresentations = presentations.filter(p => p.permissions.owner);
        const unmovedPresentations = presentations.filter(p => !p.permissions.owner);

        const teamIds = ds.teams.models.map(x => x.attributes.id);

        const apiRequests = movedPresentations.map(presentation =>
            permissionsDS.getPermissions(presentation.id, teamIds)
                .catch(err => {
                    logger.error(err, "[MoveWorkspacesDialog] permissionsDS.getPermissions() failed");
                })
        );
        Promise.allSettled(apiRequests)
            .then(results => {
                // Map the permissions by each presentationId
                let presentationPermissions = results
                    .filter(result => result.status === "fulfilled")
                    .reduce((presentationPermissions, result, index) => {
                        const presentationId = movedPresentations[index].id;
                        presentationPermissions[presentationId] = result.value
                            .filter(permission => permission.type !== "owner");
                        return presentationPermissions;
                    }, {});

                return presentationPermissions;
            })
            .then(presentationPermissions => {
                const uniquePermisions = _.flatten(Object.values(presentationPermissions))
                    .reduce((uniquePermissions, permission) => {
                        // Only retain the permission if one isn't already present for this user
                        if (!uniquePermissions.filter(x => x.id === permission.id).length) {
                            uniquePermissions.push(permission);
                        }
                        return uniquePermissions;
                    }, []);
                this.setState({
                    isLoading: false,
                    movedPresentations,
                    unmovedPresentations,
                    presentationPermissions,
                    uniquePermisions,
                });
            });
    }

    handleMovePresentations = async () => {
        const { closeDialog } = this.props;
        const {
            movedPresentations,
            presentationPermissions,
            workspace,
        } = this.state;

        this.setState({ isLoading: true });

        await Promise.all(movedPresentations.map(async presentation => {
            const deleteCalls = _.flatten(
                Object.entries(presentationPermissions)
                    .map(([presentationId, permissions]) => {
                        return permissions.map(perm => {
                            const pendingPermToDelete = {
                                //subject can be email or team name depending on if its a user email or team name
                                //probably should rename id to something else
                                subject: perm.email || perm.name,
                                id: perm.id,
                                pending: perm.pending || false,
                                type: perm.type,
                                level: perm.level === "team" ? "team" : "user"
                            };

                            if (pendingPermToDelete.level === "team") {
                                return permissionsDS
                                    .deleteTeamPermission(pendingPermToDelete.id, pendingPermToDelete, presentationId)
                                    .catch(err => {
                                        logger.error(err, "[MoveWorkspacesDialog] permissionsDS.deleteTeamPermission() failed");
                                    });
                            }
                        });
                    })
            );

            await Promise.allSettled(deleteCalls);

            await presentationsApi.moveWorkspace({ id: presentation.id, newWorkspaceId: workspace });
        }));

        closeDialog();
    }

    render() {
        const {
            closeDialog,
            workspaces,
            currentWorkspaceId
        } = this.props;

        const {
            isLoading,
            workspace,
            unmovedPresentations,
            uniquePermisions,
        } = this.state;

        return (
            <BeautifulDialog closeDialog={closeDialog}>
                <FetchingClickShield visible={isLoading} backgroundColor={"white"} />
                <DialogTitle>
                    <FormIcon src={baiBotImage} />
                    <FlexBox middle fillWidth style={{ textAlign: "center" }}>
                        <span>
                            {
                                !isLoading && uniquePermisions.length > 0
                                    ? "Are you sure you want to move these presentations?"
                                    : "Move presentations"
                            }
                        </span>
                    </FlexBox>
                </DialogTitle>
                <DialogContent>
                    {
                        uniquePermisions.length > 0 &&
                        <>
                            <div style={{ textAlign: "center" }}>
                                Any presentations currently shared in a Team Folder will be removed from<br />those folders and the collaborators will no longer have access.
                            </div>
                            <br />
                        </>
                    }
                    <div style={{ display: "flex", width: "100%" }} disableTypography={true}>
                        <h2 style={{ margin: "6px 0 0 0" }}>Move to:</h2>
                        <Select
                            style={{ marginLeft: "20px", flex: 1 }}
                            value={workspace}
                            onChange={event => {
                                this.setState({ workspace: event.target.value });
                            }}
                        >
                            {workspaces.filter(workspace => workspace.id !== currentWorkspaceId).map(ws => {
                                if (ws.id === "personal") {
                                    return <MenuItem key={ws.id} value={ws.id}>Personal</MenuItem>;
                                } else {
                                    const defaultTeam = ds.teams.find(team => team.get("isDefault") && team.get("orgId") === ws.id);
                                    return <MenuItem key={ws.id} value={ws.id}>{defaultTeam.get("name")}</MenuItem>;
                                }
                            })}
                        </Select>
                    </div>
                    {unmovedPresentations.length > 0 &&
                        <div>
                            <p>The following presentations could not be moved because they were shared with you.
                                You can only move presentations that you own.</p>
                            <p><b><i>{unmovedPresentations.map(p => p.name).join(", ")}</i></b></p>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={this.handleMovePresentations} color="primary">Move Presentations</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
