import React from "react";
import Icon from "./Icon";
import { Button as MaterialButton } from "@material-ui/core";

export default function Button(props) {
    let content;
    if (props.icon) {
        content = <Icon iconName={props.icon} />;
    } else if (props.image) {
        content = <img src={props.image} />;
    }

    return (
        <MaterialButton
            {...props}
            className={props.className}
            color={props.color || "primary"}
            onClick={props.onClick}
            disabled={props.disabled}
            size={props.size}
            variant={props.variant || "contained"}
        >
            {content}
            <label>{props.label || props.children}</label>
        </MaterialButton>
    );
}
