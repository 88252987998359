import React, { Component } from "react";
import styled from "styled-components";
import classNames from "classnames";

import { UIController } from "legacy-js/editor/dialogs/UIController";
import { openTeamUpgrade } from "legacy-js/core/utilities/externalLinks";

import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { FolderProfile, UserProfile } from "legacy-js/react/components/Avatar";
import { BlueButton } from "legacy-js/react/components/UiComponents";
import { Gap10 } from "legacy-js/react/components/Gap";
import Loadable from "legacy-js/react/components/Loadable";

const SelectableProfile = styled.div`
  padding: 10px 20px 10px 14px;
  width: 100%;

  &.selected {
    background: #D6EFF9;
    cursor: pointer;
  }

  .avatar.folder > i,
  .avatar.team > i {
    color: #11A9E2;
  }
`;

const NoMatch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 14px 30px;
  font-size: 15px;
  color: #666;
`;

const ListHeader = styled.div`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #A9A9A9;
    margin: 10px;
`;

export class InviteSuggestions extends Component {
    refListWrapper = React.createRef();

    componentDidUpdate = () => {
        if (this.refListWrapper.current) {
            const parent = this.refListWrapper.current?.ref?.current || this.refListWrapper.current;
            const selected = parent.querySelector(".selected");
            if (selected) {
                // Scroll into view next frame so React can finish rendering first
                setTimeout(() =>
                    selected.scrollIntoView({
                        block: "nearest",
                        inline: "nearest",
                        behavior: "smooth",
                    })
                );
            }
        }
    }

    handleClick = (event, item) => {
        // Prevent focus
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.preventDefault();

        const {
            inviteContext,
            onSelect,
        } = this.props;

        inviteContext.selectItems([item]);
        onSelect && onSelect(item);
    }

    render = () => {
        const {
            inviteContext,
            sourceName,
            maxHeight = "calc(30vh)",
        } = this.props;

        const isOrgWorkspace = UIController.isTeamWorkspace();
        const showUpgradeCTA = (this.props.showUpgradeCTA ?? true) && !isOrgWorkspace;

        const {
            filteredNewSuggestions,
            filteredOldSuggestions,
            oldFirst,
        } = inviteContext;

        const renderProfile = (item, indexPendingSelection, selectable = true) => (
            <SelectableProfile
                key={item.id || item.email}
                className={classNames({
                    selectable,
                    selected: indexPendingSelection === inviteContext.pendingSelectionIndex,
                })}
                // We bind to mouse down instead of click so we can prevent focus
                onMouseDown={event => selectable && this.handleClick(event, item)}
                onMouseEnter={() => selectable && inviteContext.setPendingSelectionIndex(indexPendingSelection)}
            >
                {
                    (
                        item.type === "user" ||
                        item.type === "email"
                    ) &&
                    <UserProfile
                        profile={item}
                        highlight={inviteContext.getFilterWithoutCategories()}
                    />
                }
                {
                    item.type === "folder" &&
                    <FolderProfile
                        profile={item}
                        highlight={inviteContext.getFilterWithoutCategories()}
                    />
                }
            </SelectableProfile>
        );
        const domNewSuggestions = (
            <>
                {
                    !!filteredNewSuggestions.length &&
                    <ListHeader>{inviteContext.labelCategoryNew}</ListHeader>
                }
                {
                    filteredNewSuggestions.map((item, index) => {
                        const indexPendingSelection = oldFirst ? filteredOldSuggestions.length + index : index;
                        return renderProfile(item, indexPendingSelection);
                    })
                }
            </>
        );
        const domOldSuggestions = (
            <>
                {
                    !!filteredOldSuggestions.length &&
                    <ListHeader>{inviteContext.labelCategoryOld}</ListHeader>
                }
                {
                    filteredOldSuggestions.map((item, index) => {
                        const indexPendingSelection = oldFirst ? index : filteredNewSuggestions.length + index;
                        return renderProfile(item, indexPendingSelection);
                    })
                }
            </>
        );

        return (
            <FlexBox
                ref={this.refListWrapper}
                id="inviteSuggestions"
                className={`source-${sourceName}`}
                vertical
                left
                fillWidth
                top
                style={{ maxHeight, overflowY: "auto", position: "relative" }}
                onMouseDown={event => {
                    // Prevent closing the list if it's clicked on
                    event.stopPropagation();
                    event.preventDefault();
                }}
            >
                {
                    inviteContext.filteredAllSuggestions.length
                        ? (
                            oldFirst ? <>
                                {domOldSuggestions}
                                {domNewSuggestions}
                            </> : <>
                                {domNewSuggestions}
                                {domOldSuggestions}
                            </>
                        ) : (
                            showUpgradeCTA
                                ? <NoMatch>
                                    <p>Work together, faster. Collaborate with your team in one click when you upgrade to our Team Plan.</p>
                                    <Gap10 />
                                    <BlueButton
                                        onClick={() => openTeamUpgrade({ cta: `InviteSuggestions:${sourceName}` })}
                                    >Create a Workspace</BlueButton>
                                </NoMatch>
                                : (
                                    inviteContext.filter
                                        ? <NoMatch><i>No matches</i></NoMatch>
                                        : <Loadable />
                                )
                        )
                }
            </FlexBox>
        );
    }
}
