import React from "react";
import styled from "styled-components";

import { getStaticUrl } from "legacy-js/config";
import { BetaBadge } from "legacy-js/react/components/BetaBadge";
import { Gap20 } from "legacy-js/react/components/Gap";

const Container = styled.div`
  width: 100%;
  display: flex;
  font-family: 'Source Sans Pro';
  font-style: normal;
  letter-spacing: 0.5px;
  background: #333;
  color: white;
  height: 50px;
`;

const DesignerBot = styled.img`
  height: 50px;
`;

const Header = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
`;

interface GenerationHeaderProps {
    header: string;
}

function GenerationHeader({ header }: GenerationHeaderProps) {
    return (
        <Container>
            <DesignerBot src={getStaticUrl("/images/bai-bot/bai-bot-shake.gif")} />
            <Gap20 />
            <Header>
                {header} <BetaBadge small />
            </Header>
        </Container>
    );
}

export default GenerationHeader;
