import styled from "styled-components";
import React, { Component } from "reactn";
import { _ } from "legacy-js/vendor";
import { SearchBar } from "legacy-js/react/views/AddSlide/Panes/Components/SearchBox";
import { FlexSpacer, Gap10, Gap20, Gap30 } from "legacy-js/react/components/Gap";
import { Fragment } from "react";
import { Icon, IconButton, MenuItem, Slider } from "@material-ui/core";
import { IconMenu } from "legacy-js/react/components/UiComponents";
import { themeColors } from "legacy-js/react/sharedStyles";
import { app } from "js/namespaces";

const StyledLibraryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //flex-grow: 2;
  //padding-right: 30px;
  .material-icons {
    color: #11A9E2;
  }
`;

export class PresentationListHeader extends Component {
    constructor(props) {
        super();

        this.state = {
            thumbnailSize: props.thumbnailSize
        };

        this.searchBarRef = React.createRef();
    }

    handleSetSort = sort => {
        this.props.onSetSort && this.props.onSetSort(sort);
    }

    handleSearch = value => {
        this.props.onSearch && this.props.onSearch(value);
        if (!value) {
            this.setState({ showSearchBar: false });
        }
    }

    // handleSetViewType = viewType => {
    //     this.props.onSetViewType && this.props.onSetViewType(viewType);
    // }

    toggleViewType = () => {
        const viewType = this.props.viewType == "grid" ? "list" : "grid";
        this.props.onSetViewType && this.props.onSetViewType(viewType);
    }

    handleShowSearchBar = () => {
        this.setState({ showSearchBar: true });
        _.defer(() => {
            this.searchBarRef.current.focusInput();
        });
    }

    handleHideSearchBar = () => {
        this.setState({ showSearchBar: false });
    }

    render() {
        let { ds, showSearchBar } = this.state;
        let { sortOptions, viewType = "grid" } = this.props;

        return (
            <StyledLibraryHeader>
                <IconButton size="small" onClick={this.handleShowSearchBar}
                    style={{ color: themeColors.ui_blue }}><Icon>search</Icon></IconButton>

                {(showSearchBar || app.isMobileOrTablet) &&
                    <Fragment>
                        <SearchBar
                            ref={this.searchBarRef}
                            showIcon={false}
                            placeholder="Search presentations"
                            onSearch={this.handleSearch}
                            onBlurOnEmpty={this.handleHideSearchBar}
                        />
                    </Fragment>
                }
                {
                    (viewType == "grid" || app.isConstrained) &&
                    <Fragment>
                        {
                            !app.isConstrained &&
                            <Gap20 />
                        }
                        {
                            !app.isMobileOrTablet &&
                            app.integrator &&
                            <Gap10 />
                        }
                        <IconMenu icon="sort">
                            {sortOptions.map(option => (
                                <MenuItem
                                    key={option.value}
                                    selected={this.props.sort.field === option.value}
                                    onClick={() => this.handleSetSort({
                                        field: option.value,
                                        reverse: option.reverse
                                    })}>
                                    {option.icon && <Icon>{option.icon}</Icon>}
                                    {option.label}
                                </MenuItem>
                            ))}
                        </IconMenu>
                    </Fragment>
                }

                {
                    !app.isConstrained &&
                    <Fragment>
                        <Gap20 />
                        <IconButton onClick={() => this.toggleViewType()}>
                            <Icon>{viewType == "grid" ? "view_module" : "view_list"}</Icon>
                        </IconButton>
                        <Gap30 />
                    </Fragment>
                }
                {
                    app.isConstrained &&
                    <Fragment>
                        <IconButton onClick={() => this.toggleViewType()}>
                            <Icon>{viewType == "grid" ? "view_module" : "view_list"}</Icon>
                        </IconButton>
                    </Fragment>
                }
            </StyledLibraryHeader>
        );
    }
}
