import React from "reactn";
import { Icon } from "@material-ui/core";
import { ShowUpgradeDialog } from "./Dialogs/BaseDialog";
import { UpgradePlanDialogType } from "../views/MarketingDialogs/UpgradePlanDialog";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import { FeatureType } from "js/core/models/features";
import { app } from "js/namespaces";

export function WarningWatermark(props) {
    const {
        presentation,
        cta,
        notCentered,
    } = props;

    if (!presentation) {
        throw new Error("presentation is required");
    }

    if (!cta) {
        throw new Error("cta is required");
    }

    const stylePanel = {
        display: "flex",
        justifyContent: notCentered ? "start" : "center",
        alignItems: "center",
        padding: "20px",
        fontSize: "14px",
    };
    const styleIcon = {
        color: "#ffaa00",
        marginRight: "20px",
        fontSize: "32px",
    };
    const workspaceId = UIController.getWorkspaceId();

    const handleUpgrade = event => {
        event.stopPropagation();

        ShowUpgradeDialog({
            type: UpgradePlanDialogType.UPGRADE_PLAN,
            analytics: {
                cta,
                ...presentation.getAnalytics(),
            },
            workspaceId,
        });
    };

    // Only render the warning if the preso has smart slide
    //   and the user doesn't have free access to them.
    const hasSmartSlides = presentation.hasSmartSlides() || !presentation.slides.length;
    const renderWarning =
           !app.user.features.isFeatureEnabled(FeatureType.SMART_SLIDES, workspaceId) &&
        hasSmartSlides;

    return renderWarning ? (
        <div className="warning-watermark" style={stylePanel}>
            <Icon style={styleIcon}>auto_awesome</Icon>
            <span>
                <span>Smart Slides are a Pro feature and will include our watermark. </span>
                <a className="link-blue" onClick={handleUpgrade}>Upgrade to Pro</a>
                <span> to get unlimited access to Smart Slides and remove all watermarks.</span>
            </span>
        </div>
    ) : null;
}
