import React from "react";

import getLogger, { LogGroup } from "js/core/logger";
import { getExperiments } from "js/core/services/experiments";

const logger = getLogger(LogGroup.APP);

export class Experiment extends React.Component {
    constructor(props) {
        super(props);

        this._isUnmounted = false;

        this.state = {
            loading: true
        };
    }

    async componentDidMount() {
        const { id } = this.props;

        try {
            const { [id]: { enabled } } = await getExperiments([id]);
            if (!this._isUnmounted) {
                this.setState({ loading: false, enabled });
            }
        } catch (err) {
            // failsafe
            logger.error(err, "[Experiment] componentDidMount() failed");
            if (!this._isUnmounted) {
                this.setState({ loading: false, enabled: false });
            }
        }
    }

    componentWillUnmount() {
        this._isUnmounted = true;
    }

    render() {
        const { children } = this.props;
        const { loading, enabled } = this.state;

        if (loading) {
            return null;
        }

        return children(enabled);
    }
}
