import { _ } from "legacy-js/vendor";
import { HiliteType } from "legacy-common/constants";

import { CollectionItemElement } from "../../base/CollectionElement";

export class OrgChartNode extends CollectionItemElement {
    get nodeStyle() {
        return this.parentElement.model.defaultNodeStyle || "box";
    }

    get nodeType() {
        return this.model.nodeType || "person";
    }

    get nodeSize() {
        return this.model.nodeSize || "normal";
    }

    get showImage() {
        return this.parentElement.model.showImage || false;
    }

    get isRootNode() {
        return this.parentNode == null;
    }

    get isAssistant() {
        return this.model.isAssistant;
    }

    get parentNode() {
        return this.parentElement.itemElements.find(node => node.model.id == this.model.parent);
    }

    isChildNodeOf(node) {
        if (this.isRootNode) return false;
        if (this.parentNode == node) {
            return true;
        } else {
            return this.parentNode.isChildNodeOf(node);
        }
    }

    get childNodes() {
        return _.sortBy(this.parentElement.itemElements.filter(node => node.model.parent == this.model.id && !node.isAssistant), "nodeIndex");
    }

    get assistantNodes() {
        return _.sortBy(this.parentElement.itemElements.filter(node => node.model.parent == this.model.id && node.isAssistant), "nodeIndex");
    }

    get children() {
        return this.childNodes;
    }

    get siblingNodes() {
        if (this.parentNode) {
            return _.sortBy(this.parentNode.childNodes, "nodeIndex");
        } else {
            return _.sortBy(this.parentElement.itemElements.filter(node => node.model.parent == null), "nodeIndex");
        }
    }

    get rightSiblingNode() {
        return _.find(this.siblingNodes, { nodeIndex: this.nodeIndex + 1 });
    }

    get leftSiblingNode() {
        return _.find(this.siblingNodes, { nodeIndex: this.nodeIndex - 1 });
    }

    get nodeIndex() {
        return this.model.index || 0;
    }

    get rowIndex() {
        if (this.parentNode) {
            if (this.parentNode.assistantNodes.length && !this.isAssistant) {
                return this.parentNode.rowIndex + 2;
            } else {
                return this.parentNode.rowIndex + 1;
            }
        } else {
            return 0;
        }
    }

    get isLeftEdgeNode() {
        return this.nodeIndex == 0;
    }

    get isRightEdgeNode() {
        return this.nodeIndex == this.siblingNodes.length - 1;
    }

    get selectionPadding() {
        return 0;
    }

    get connectorType() {
        return this.model.connectorType || "solid";
    }

    getHiliteType() {
        if (this.nodeType == "placeholder") {
            return false;
        } else {
            return super.getHiliteType();
        }
    }

    get nodeXOffset() {
        return 0;
    }

    _loadStyles(styles) {
        if (this.nodeType === "placeholder") {
            styles.applyStyles(styles.placeholder);
        }

        const hiliteType = this.getHiliteType();
        if (hiliteType === HiliteType.DEEMPHASIZED) {
            styles.applyStyles(styles.deemphasized);
        } else if (hiliteType === HiliteType.EMPHASIZED) {
            styles.applyStyles(styles.emphasized);
        }
    }

    calcChildrenWidth(options = {}) {
        let hGap = this.getRootElement().styles.hGap;

        let children = this.childNodes;
        if (children.length && (!options.stackBottomSiblings || _.some(children, child => child.childNodes.length))) {
            let totalWidth = -hGap;
            for (let childNode of children) {
                totalWidth += childNode.calcChildrenWidth() + hGap;
            }
            // return totalWidth;
            return Math.max(totalWidth, this.calculatedSize.width);
        } else {
            return this.calculatedSize.width;
        }
    }
}
