const lodash = require("lodash");

function isArray(obj) {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const int = parseInt(key);
        if (int < 0 || int.toString() !== key) {
            return false;
        }
    }

    return true;
}

function isBlank(value) {
    return (
        lodash.isNil(value) || // null, undefined
        value === "" || // empty string
        (
            lodash.isObjectLike(value) && // objects, arrays, sets
            lodash.isEmpty(value)
        )
        // excluded: false, 0
    );
}

// this will convert an object of numbers into an array, with null values if an index is missing.
function toArray(obj) {
    const max = Object.keys(obj).reduce(function(a, b) {
        return Math.max(a, parseInt(b));
    });
    const result = [];
    for (let i = 0; i < max + 1; i++) {
        if (obj.hasOwnProperty(i.toString())) {
            result[i] = obj[i];
        } else {
            result[i] = null;
        }
    }
    return result;
}

function normalizeMerge(attrs) {
    if (!lodash.isObject(attrs) || (isArray(attrs) && attrs.length === 0)) {
        return attrs;
    }
    const normalizedAttrs = {};
    let hasAttrs = false;
    for (let key in attrs) {
        if (attrs.hasOwnProperty(key)) {
            const normalizedValue = normalizeMerge(attrs[key]);
            if (normalizedValue !== null) {
                normalizedAttrs[key] = normalizedValue;
                hasAttrs = true;
            }
        }
    }
    if (!hasAttrs) {
        return null;
    }
    if (isArray(normalizedAttrs)) {
        return toArray(normalizedAttrs);
    }
    return normalizedAttrs;
}

function mergeChangesRecursive(originalAttrs, changes) {
    const newAttrs = {};
    const keys = new Set(Object.keys(originalAttrs).concat(Object.keys(changes)));
    for (let key of keys) {
        if (originalAttrs.hasOwnProperty(key) && changes.hasOwnProperty(key)) {
            const originalVal = originalAttrs[key];
            const attrVal = changes[key];
            if (lodash.isObject(originalVal) && lodash.isObject(attrVal)) {
                newAttrs[key] = mergeChangesRecursive(originalVal, attrVal);
            } else {
                newAttrs[key] = changes[key];
            }
        } else if (changes.hasOwnProperty(key)) {
            newAttrs[key] = changes[key];
        } else if (originalAttrs.hasOwnProperty(key)) {
            newAttrs[key] = originalAttrs[key];
        }
    }
    return newAttrs;
}

function _flattenChanges(updates, original, currentPathParts = [], flatChanges = {}) {
    if (lodash.isObjectLike(updates) && !lodash.isEmpty(updates) && lodash.isObjectLike(original) && !lodash.isEmpty(original) && updates.constructor.name === original.constructor.name) {
        // Both objects of same type, so call recursively for each key
        Object.entries(updates).forEach(([key, updatesValue]) => {
            _flattenChanges(updatesValue, original[key], [...currentPathParts, key], flatChanges);
        });
    } else {
        // Otherwise replace the entire field
        const path = currentPathParts.join(".");
        if (!path) {
            flatChanges = updates;
        } else {
            flatChanges[currentPathParts.join(".")] = updates;
        }
    }

    return flatChanges;
}

/**
 * Generates a flat object of changes from a changeset object.
 * @param {Object} changes Changeset object
 * @param {Boolean} useMongoNotation
 * @returns {Object}
 */
function flattenChanges(changesetUpdates, changesetOriginal, useMongoNotation = false) {
    const flatChanges = _flattenChanges(changesetUpdates, changesetOriginal, []);

    if (!useMongoNotation) {
        return flatChanges;
    }

    const mongoUpdateQuery = {
        $set: {},
        $unset: {}
    };
    Object.entries(flatChanges).forEach(([key, value]) => {
        if (value === null) {
            // Nulls will be removed to mimic Firebase behavior
            mongoUpdateQuery.$unset[key] = "";
        } else {
            mongoUpdateQuery.$set[key] = value;
        }
    });
    return mongoUpdateQuery;
}

/**
 * This will merge a change set original or update to a deepclone of an object.
 * @param {Object} originalAttrs
 * @param {Object} changes - Changes to apply to the originalAttrs.
 * @param {boolean} normalize - If true, this will remove null values.
 * @return {Object} Clone of originalAttrs merged with the changes.
 */
function mergeChanges(originalAttrs, changes, normalize = true) {
    const merge = mergeChangesRecursive(originalAttrs, changes);
    if (normalize) {
        return normalizeMerge(merge) || {};
    } else {
        return merge;
    }
}

/**
 * Recursively compare two objects to figure out the difference between them. This algorithm follows how data
 * is stored in firebase where null values are equivalent to no value set. This means if a property is null from
 * the originalObj and the property is unset in updateObj, the algorithm will not detect a change.
 * Note - the results are deep clones so they will not be mutated if you change the source or vice versa.
 * @param {Object} originalObj
 * @param {Object} updateObj
 * @param {boolean} [removeMissingKeys=false] - If true, remove any missing root level attributes. This is essentially
 * replacing the originalObj with the updateObj.
 * @return {{original: {}, update: {}, hasUpdates: boolean}}
 */
function computeChangeSet(originalObj, updateObj, removeMissingKeys = false) {
    let keys = Object.keys(updateObj);
    if (removeMissingKeys || Array.isArray(updateObj)) {
        keys = keys.concat(Object.keys(originalObj));
    }
    keys = new Set(keys);
    const originalVals = {};
    const updateVals = {};
    let hasUpdates = false;
    for (let key of keys) {
        const updateIsNullable = isBlank(updateObj[key]);
        const originalIsNullable = isBlank(originalObj[key]);

        if (updateObj.hasOwnProperty(key) && originalObj.hasOwnProperty(key) && !updateIsNullable && !originalIsNullable) {
            const originalVal = originalObj[key];
            const updateVal = updateObj[key];
            if (lodash.isObjectLike(originalVal) && lodash.isObjectLike(updateVal) && originalVal.constructor.name === updateVal.constructor.name) {
                const changeSet = computeChangeSet(originalVal, updateVal, removeMissingKeys);
                if (changeSet.hasUpdates) {
                    originalVals[key] = changeSet.original;
                    updateVals[key] = changeSet.update;
                    hasUpdates = true;
                }
            } else if (originalVal !== updateVal) {
                originalVals[key] = originalVal;
                updateVals[key] = updateVal;
                hasUpdates = true;
            }
        } else if (updateObj.hasOwnProperty(key) && !updateIsNullable) {
            originalVals[key] = null;
            updateVals[key] = updateObj[key];
            hasUpdates = true;
        } else if (originalObj.hasOwnProperty(key) && !originalIsNullable) {
            originalVals[key] = originalObj[key];
            updateVals[key] = null;
            hasUpdates = true;
        }
    }
    return {
        original: originalVals,
        update: updateVals,
        hasUpdates: hasUpdates
    };
}

module.exports = {
    mergeChanges,
    flattenChanges,
    computeChangeSet
};
