import React from "react";

import { _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { ContentBlockType, FormatType } from "legacy-common/constants";
import { formatter } from "js/core/utilities/formatter";
import { getSVGStyleProps, getTransformProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { Icon } from "../base/MediaElements/IconElement";
import { layoutHelper } from "../layouts/LayoutHelper";

class PictorialCharts extends CollectionElement {
    getChildItemType() {
        return PictorialChartItem;
    }

    get defaultItemData() {
        let defaultShape = "male";
        if (this.model.items.length > 0) {
            defaultShape = _.last(this.model.items).shape;
        }
        return {
            value: 75,
            shape: defaultShape,
            description: ""
        };
    }

    get maxItemCount() {
        return 6;
    }

    get maxValue() {
        // Will ensure max value is never less than 100
        return _.max([...this.itemCollection.map(model => model.value), 100]);
    }

    _calcProps(props, options) {
        const { size, styles, children } = props;

        let vGap = this.styles.vGap;

        let multipleRows = this.itemCount > 1;

        let availableItemSize = new geom.Size(Math.min(size.width, this.styles.maxItemWidth), size.height);

        let unscaledWidth = 0;
        let unscaledHeight = 0;
        for (let item of this.itemElements) {
            let itemSize = item.calcProps(availableItemSize, { scale: 1, multipleRows }).size;
            unscaledWidth = Math.max(unscaledWidth, itemSize.width);
            unscaledHeight += itemSize.height + vGap;
        }
        unscaledHeight -= vGap;

        let scale = Math.min(1, size.height / unscaledHeight, size.width / unscaledWidth);

        let scaledHeight = unscaledHeight * scale;
        vGap *= scale;

        let y = 0;
        for (let item of this.itemElements) {
            let itemProps = item.calcProps(availableItemSize, { scale, multipleRows });
            itemProps.bounds = new geom.Rect(0, y, itemProps.size);
            y += itemProps.size.height + vGap;
        }

        let offsetY = size.height / 2 - layoutHelper.getTotalBoundsOfItems(this.itemElements).size.height / 2;
        let maxWidth = _.maxBy(this.itemElements, el => el.calculatedProps.bounds.width).calculatedProps.bounds.width;

        for (let item of this.itemElements) {
            item.calculatedProps.bounds.left = size.width / 2 + maxWidth / 2 - item.calculatedProps.bounds.width;
            item.calculatedProps.bounds.top += offsetY;
        }

        return { size };
    }
}

class PictorialChartItem extends CollectionItemElement {
    static get schema() {
        return {
            format: FormatType.PERCENT,
            value: 50
        };
    }

    get format() {
        return this.model.format || FormatType.PERCENT;
    }

    get formatOptions() {
        return this.model.formatOptions || formatter.getDefaultFormatOptions();
    }

    get currentValue() {
        if (this.isAnimating) {
            return this.model.value * this.animationState.value;
        }

        return this.model.value;
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format === FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    get showDescription() {
        return !_.isEmpty(this.model.description) || this.showDescriptionPlaceholder == true;
    }

    get rolloverPadding() {
        return 0;
    }

    get labelText() {
        let text;
        if (this.format == FormatType.PERCENT) {
            text = this.formatValue(this.currentValue);
        } else {
            text = this.formatValue(this.model.labelValue ?? this.currentValue);
        }

        if (this.isAnimating) {
            text = text.slice(0, this.labelTextLength);
            if (text.endsWith(".")) {
                text = text.slice(0, text.length - 1);
            }
        }

        return text;
    }

    _build() {
        if (this.model.value == undefined) {
            this.model = {
                value: 75,
                iconId: "figure-man",
                description: ""
            };
        }

        this.icons = this.addElement("icons", () => PictorialChartItemIcons);

        this.label = this.addElement("label", () => PictorialChartItemLabel, {
            model: {
                label: this.labelText
            },
            autoWidth: true,
            autoHeight: true
        });

        if (this.showDescription) {
            this.description = this.addElement("description", () => TextElement, {
                rollover: "PictorialChartDescriptionRollover",
                selection: "PictorialChartDescriptionSelection",
                autoHeight: true,
                allowedBlockTypes: [ContentBlockType.HEADING, ContentBlockType.TITLE, ContentBlockType.BODY, ContentBlockType.DIVIDER, ContentBlockType.CAPTION]
            });
        }
    }

    _calcProps(props, options) {
        const { size, children } = props;

        if (this.isAnimating) {
            this.label.updateText(this.labelText);
        }

        this.label.scaleStyleValues(options.scale);

        const labelProps = this.label.calcProps(size);
        if (this.isAnimating) {
            labelProps.bounds = new geom.Rect(0, 0, new geom.Size(this.labelWidth, labelProps.size.height));
        } else {
            labelProps.bounds = new geom.Rect(0, 0, labelProps.size);
            this.labelWidth = labelProps.size.width;
            this.labelTextLength = this.labelText.length;
        }

        const iconsSize = new geom.Size(size.width - this.labelWidth, labelProps.size.height);
        const iconsProps = this.icons.calcProps(iconsSize, { scale: options.scale, maxValue: this.parentElement.maxValue, multipleRows: options.multipleRows });
        iconsProps.bounds = new geom.Rect(this.labelWidth, 0, iconsProps.size);

        let totalWidth = this.labelWidth + iconsProps.size.width;
        let totalHeight = iconsProps.size.height;

        if (this.showDescription) {
            let descriptionWidth = totalWidth;
            let descriptionLeft = 0;
            if (this.parentElement.model.fitIcons) {
                descriptionWidth = totalWidth - labelProps.bounds.width;
                descriptionLeft = labelProps.bounds.width;
            }

            this.description.scaleStyleValues(options.scale);
            const descriptionProps = this.description.calcProps(new geom.Size(descriptionWidth, size.height));
            descriptionProps.bounds = new geom.Rect(descriptionLeft, this.label.bounds.bottom, descriptionProps.size);
            totalHeight += descriptionProps.size.height;
        }

        return { size: new geom.Size(totalWidth, totalHeight) };
    }

    get animationElementName() {
        return `Icons #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Fill in",
            prepare: () => {
                this.animationState.fadeInProgress = 0;
                this.animationState.value = this.icons.animationState.value = 0;
                if (this.showDescription) {
                    this.description.animationState.fadeInProgress = 0;
                }
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = Math.min(1, progress * 3);
                this.animationState.value = this.icons.animationState.value = progress;
                if (this.showDescription) {
                    this.description.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                }
                return this;
            }
        }];
    }
}

class PictorialChartItemLabel extends TextElement {
    get canEdit() {
        return false;
    }
}

class PictorialChartItemIcons extends BaseElement {
    get iconId() {
        return this.model.iconId || "figure-man";
    }

    get currentValue() {
        if (this.parentElement.isAnimating) {
            return this.model.value * this.animationState.value;
        }

        return this.model.value;
    }

    _build() {
        this.icon = this.addElement("icon", () => Icon, {
            icon: this.iconId,
            preventExport: true,
            preventRender: true,
            canSelect: false
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const womanIconWidth = 45.265594482421875;
        const womanIconHeight = 87.8494644165039;

        let iconWidth = options.multipleRows ? womanIconWidth : this.icon.innerViewbox.width;
        let iconHeight = options.multipleRows ? womanIconHeight : this.icon.innerViewbox.height;

        const iconScale = 128 / iconHeight;
        const outerScale = size.height / 128;

        let scale = iconScale * outerScale;

        iconWidth *= scale;

        if (options.multipleRows && this.iconId != "figure-woman" && this.iconId != "figure-man") {
            scale *= 0.75;
        }
        let outerIconSize = this.icon.viewbox.multiply(scale);

        // calculate the icon props
        const iconProps = this.icon.calcProps(this.icon.viewbox.size, { iconScale: 1 }); // width doesn't matter because we are just getting aspect ratio to fit to the height
        // iconProps.iconColor = this.model.color || this.icon.styles.fillColor;

        let minGap = 10;

        let iconCount, iconGap;
        if (this.getRootElement().model.fitIcons) {
            iconCount = 10;
            iconGap = this.styles.hGap ?? 0;
        } else {
            iconCount = Math.floor(size.width / (iconWidth + minGap));
            iconGap = (size.width - (iconWidth * iconCount)) / iconCount;
        }

        let iconsBounds = [];
        let x = iconWidth / 2 - outerIconSize.width / 2;
        for (let i = 0; i < iconCount; i++) {
            iconsBounds.push({ transform: `translateX(${x}px) translateY(${size.height / 2 - outerIconSize.height / 2}px) scale(${scale}) ` });
            x += iconWidth + iconGap;
        }

        let totalWidth = iconCount * (iconWidth + iconGap) - iconGap;

        const maskBounds = new geom.Rect(-10, -10, totalWidth * (this.currentValue / Math.max(options.maxValue, 100)) + 10, size.height + 20);

        this.markStylesAsDirty();

        return { size: new geom.Size(totalWidth, size.height), maskBounds, iconsBounds, iconProps };
    }

    renderChildren(transition) {
        const props = this.calculatedProps;
        const { iconsBounds, maskBounds } = props;

        // special case because we are calling icon renderchildren instead of normal render pipelie
        return (
            <SVGGroup key={this.id}>
                <g className="backgroundIcons" {...getSVGStyleProps(this.styles.unhilited_icons)}>
                    {iconsBounds.map((transform, idx) => {
                        return <g key={idx} style={transform}>{this.icon.renderChildren(props.iconProps)}</g>;
                    })}
                </g>
                <clipPath id={`${this.uniqueId}-clip-path`}>
                    <rect {...getTransformProps(maskBounds)} />
                </clipPath>
                <g className="foregroundIcons" clipPath={`url(#${this.uniqueId}-clip-path)`} {...getSVGStyleProps(this.styles.hilited_icons)}>
                    {iconsBounds.map((transform, idx) => {
                        return <g key={idx} style={transform}>{this.icon.renderChildren(props.iconProps)}</g>;
                    })}
                </g>
            </SVGGroup>
        );
    }
}

export const elements = {
    PictorialCharts,
    PictorialChartItem
};
