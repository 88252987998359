import { $, _ } from "legacy-js/vendor";
import { ds } from "js/core/models/dataService";
import { controls, CreateColorChit } from "legacy-js/editor/ui";
import { ShowDialog, ShowDialogAsync } from "legacy-js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "legacy-js/react/components/Dialogs/BadFitDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";

import { ElementDefaultOverlay, ElementSelection } from "../BaseElementEditor";
import { CreateMediaMenu, CreateImageFrameMenu } from "./pictureEditor";
import { mergeMediaElementModelDefaults } from "legacy-common/assetUtils";

const ContentElementDefaultOverlay = ElementDefaultOverlay.extend({

    render() {
        if (this.element.addContentButtonRef?.current) {
            $(this.element.addContentButtonRef.current).off("click");
            $(this.element.addContentButtonRef.current).on("click", () => {
                ds.selection.element = this.element;
                ShowDialog(AddAssetsContainer, {
                    assetType: this.element.defaultAssetType,
                    bindTo: this.element.bindTo,
                    callback: model => {
                        mergeMediaElementModelDefaults(
                            this.element.model,
                            model,
                        );
                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(false)
                            .catch(() => {
                                ShowDialogAsync(BadFitDialog, {
                                    title: "Sorry, the content on your slide won't fit with this image",
                                });
                            });
                    },
                });
            });
        }

        return this;
    },

    cleanUp() {
        if (this.element.addContentButtonRef?.current) {
            $(this.element.addContentButtonRef.current).off("click");
        }
    }
});

const ContentElementSelection = ElementSelection.extend({
    getOffset() {
        return 0;
    },

    renderControls() {
        CreateMediaMenu(this, this.element);
    },
});

const LogoElementSelection = ElementSelection.extend({
    renderControls: function() {
        if (!this.element.showDefaultOverlay) {
            this.addControl({
                type: controls.SLIDER,
                property: "scale"
            });
        }
    }
});

export const AdjustableContentElementSelection = ElementSelection.extend({
    getOffset() {
        return 20;
    },

    renderControls() {
        CreateMediaMenu(this, this.element);
        CreateImageFrameMenu(this, this.element);
        CreateColorChit(this, this.element, { showBackgroundColors: true, showTextColors: false });
    },
});

export const editors = {
    ContentElementDefaultOverlay,
    ContentElementSelection,
    AdjustableContentElementSelection
};
