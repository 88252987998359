import React, { Component } from "react";
import ReactChart from "legacy-js/react/components/ReactChart";
import { formatter } from "js/core/utilities/formatter";
import AnalyticsController from "legacy-js/react/views/Analytics/AnalyticsController";

class ViewingAggregates extends Component {
    constructor(props) {
        super(props);

        const { presentation, analytics } = props;
        const sips = presentation.getSips();

        let series = [];
        if (analytics.viewsPerSlide) {
            series = [
                {
                    type: "column",
                    name: "Seconds",
                    data: sips
                        .map(slideId => analytics.viewsPerSlide.find(row => row.slideid === slideId))
                        .map(row => (row ? Math.round(row.averagetime) : 0)),
                },
                {
                    yAxis: 1,
                    type: "line",
                    name: "Views",
                    data: sips
                        .map(slideId => analytics.viewsPerSlide.find(row => row.slideid === slideId))
                        .map(row => (row ? Math.round(row.count) : 0)),
                }
            ];
        }

        this.state = {
            categories: sips.map((slideId, i) => i + 1),
            series,
            tooltip: null,
        };
    }

    async componentDidMount() {
        const { presentation, analytics } = this.props;

        if (analytics.viewsPerSlide) {
            const thumbnailUrls = await Promise.all(
                presentation.getSips().map(slideId => presentation.getThumbnailUrl(slideId, "small", true))
            );
            const tooltip = {
                formatter: function() {
                    if (this.series.name === "Seconds") {
                        return (
                            `<div>` +
                                `<div><img src="${thumbnailUrls[this.point.index]}" width="192" height="108" /></div>` +
                                `<div style='font-size: 10px; color: #333'>Slide ${this.point.x + 1}</div>` +
                                `<div style='font-size: 12px; color: #333; font-weight: bold'>` +
                                    formatter.formatDuration(this.point.y, "seconds", "h [hrs] m [mins] s [secs]") +
                                `</div>` +
                            `</div>`
                        );
                    }
                    if (this.series.name === "Views") {
                        const viewsText = `${this.point.y} View${this.point.y !== 1 ? "s" : ""}`;
                        return (
                            `<div>` +
                                `<div><img src="${thumbnailUrls[this.point.index]}" width="192" height="108" /></div>` +
                                `<div style='font-size: 10px; color: #333'>Slide ${this.point.x + 1}</div>` +
                                `<div style='font-size: 12px; color: #333; font-weight: bold'>${viewsText}</div>` +
                            `</div>`
                        );
                    }
                },
                useHTML: true,
            };

            this.setState({ tooltip });
        }
    }

    render() {
        const { presentation, analytics } = this.props;
        const {
            categories,
            series,
            tooltip,
        } = this.state;

        return (
            <div className="chart-panel">
                <label>Average View Time Per Slide</label>
                <ReactChart
                    status={analytics.status}
                    categories={categories}
                    series={series}
                    tooltip={tooltip}
                    height={200}
                    yAxisTitle="Seconds"
                    yAxis2Title="Num of Views"
                    useThumbnailFormatter={false}
                    chartConfig={{
                        plotOptions: {
                            line: {
                                color: "white",
                                lineWidth: 3
                            }
                        },
                        yAxis: [
                            {
                                min: 0,
                                // minRange: 0.1,
                                softMax: 10,
                                max: 100
                            },
                            {
                                min: 0,
                                minRange: 0.1,
                                softMax: 4,
                                allowDecimals: false
                            }
                        ],
                    }}
                />
            </div>
        );
    }
}

export default AnalyticsController.withState(ViewingAggregates);
