import React, { Component } from "react";
import styled from "styled-components";
import type { DriveItem } from "@microsoft/microsoft-graph-types";

import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "legacy-js/config";
import * as oneDriveAuth from "js/core/oauth/oneDriveAuth";
import { downloadFileFromOneDrive } from "js/core/utilities/downloadFile";
import { PopupBlockedError } from "js/core/utilities/selfClosingWindow";
import { trackActivity } from "js/core/utilities/utilities";
import { FileType, fileTypesToExtensions } from "legacy-js/core/utilities/fileSourcesAndTypes";
import { BlueButton, TextButton } from "legacy-js/react/components/UiComponents";
import Spinner from "legacy-js/react/components/Spinner";
import { ShowDialog, ShowConfirmationDialog, ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import OneDriveFilePickerDialog from "legacy-js/react/components/Dialogs/OneDriveFilePickerDialog";
import { Gap10, Gap30 } from "legacy-js/react/components/Gap";

const logger = getLogger(LogGroup.DATA_SOURCE);

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #A9A9A9;
    padding: 30px 50px;
    margin-bottom: 30px;
`;

interface Props {
    fileTypes: FileType[],
    handleImportedFile: (file: File) => void,
}

interface State {
    userHasOneDriveOfflineAccess: boolean,
    userOneDriveLinkFetching: boolean,
    userOneDriveAccessToken: string,
    disconnecting: boolean,
}

export default class OneDrivePicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userHasOneDriveOfflineAccess: false,
            userOneDriveLinkFetching: false,
            userOneDriveAccessToken: "",
            disconnecting: false,
        };
    }

    componentDidMount() {
        this.fetchOneDriveAuthTokens();
    }

    fetchOneDriveAuthTokens = async () => {
        this.setState({ userOneDriveLinkFetching: true });

        try {
            const { hasTokens, accessToken } = await Api.getOneDriveTokensForScope.get({ scope: oneDriveAuth.SCOPES.join(" ") });
            this.setState({ userOneDriveLinkFetching: false, userHasOneDriveOfflineAccess: hasTokens, userOneDriveAccessToken: accessToken });
        } catch (err) {
            logger.error(err, "[OneDrivePicker] Api.getOneDriveTokensForScope.get() failed");
            this.setState({ userOneDriveLinkFetching: false });
        }
    }

    handleSelectedFile = async (selectedOneDriveFile: DriveItem) => {
        try {
            const fileData = await downloadFileFromOneDrive({
                url: selectedOneDriveFile["@microsoft.graph.downloadUrl"],
                name: selectedOneDriveFile.name,
            });

            this.props.handleImportedFile(fileData);
        } catch (err) {
            logger.error(err, "[OneDrivePicker] downloadFileFromOneDrive() failed");
            ShowErrorDialog({ message: "Unable to download file from OneDrive" });
        }
    }

    handleOneDriveAuth = async () => {
        const { userHasOneDriveOfflineAccess } = this.state;

        try {
            if (!userHasOneDriveOfflineAccess) {
                trackActivity("OneDrive", "DataLinkGrantAccess", null, null, {}, { audit: true, skipAmplitude: false });

                const tokens = await oneDriveAuth.grantOfflineAccess();

                this.setState({ userHasOneDriveOfflineAccess: true, userOneDriveAccessToken: tokens.accessToken });
            }

            this.openPickerUI();
        } catch (err) {
            if (err instanceof PopupBlockedError) {
                // User cancelled, ignore
                return;
            }

            logger.error(err, "[OneDrivePicker] oneDriveAuth.grantOfflineAccess() failed");
        }
    }

    handleDisconnect = async () => {
        if (this.state.disconnecting) return;

        ShowConfirmationDialog({
            title: "Disconnect Account?",
            message: (
                <span>
                    Any linked data will no longer automatically update.
                    <br /><br />
                    You can link to this account again at any time.
                </span>
            ),
            buttonOptions: { acceptButtonColor: "red" },
            okButtonLabel: "Disconnect",
            acceptCallback: async () => {
                this.setState({ disconnecting: true });

                try {
                    logger.info("[OneDrivePicker] Deleting credentials from server");
                    await Api.unlinkOneDriveAuth.delete();

                    this.setState({
                        disconnecting: false,
                        userHasOneDriveOfflineAccess: false,
                        userOneDriveAccessToken: ""
                    });
                } catch (err) {
                    logger.error(err, "[OneDrivePicker] handleDisconnect() failed");
                    this.setState({ disconnecting: false });

                    ShowErrorDialog({
                        error: "Sorry, we could not process your request",
                        message: err.message
                    });
                }
            }
        });
    };

    openPickerUI = async () => {
        const accessToken = this.state.userOneDriveAccessToken;

        if (accessToken) {
            ShowDialog(OneDriveFilePickerDialog, {
                token: accessToken,
                handleSelectedFile: this.handleSelectedFile,
                fileExtensions: fileTypesToExtensions(this.props.fileTypes)
            });
        }
    }

    render() {
        const { userHasOneDriveOfflineAccess, userOneDriveLinkFetching, disconnecting } = this.state;

        return (
            <Container>
                <img src={getStaticUrl("/images/file_hosting/onedrive.svg")} width={50} height={50} />
                <Gap30 />
                {!userOneDriveLinkFetching && (<>
                    <BlueButton /** @ts-ignore */
                        onClick={this.handleOneDriveAuth}
                    >
                        {userHasOneDriveOfflineAccess ? "Select File from OneDrive" : "Connect to OneDrive"}
                    </BlueButton>
                    {userHasOneDriveOfflineAccess && (
                        <>
                            <Gap10 />
                            <TextButton /** @ts-ignore */
                                onClick={this.handleDisconnect} color="secondary"
                            >
                                {disconnecting && <Spinner />}
                                {!disconnecting && "Disconnect Account"}
                            </TextButton>
                        </>
                    )}
                </>)}
            </Container>
        );
    }
}
