import React, { Component, useRef, useState } from "reactn";
import styled from "styled-components";
import sanitize from "sanitize-filename";

import {
    Popover,
    MenuItem,
    MenuList
} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";

import { app } from "js/namespaces";
import { FeatureType } from "legacy-common/features";
import { downloadFromUrl, trackActivity } from "js/core/utilities/utilities";
import { AssetType, ASSET_RESOLUTION } from "legacy-common/constants";
import { ds } from "js/core/models/dataService";
import getLogger from "js/core/logger";
import { getStaticUrl } from "legacy-js/config";
import { ShowDialogAsync, ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { loadImage } from "js/core/utilities/promiseHelper";
import AiGenerationService, { InsufficientCreditsError, TooManyRequestsError } from "js/core/services/aiGeneration";
import AppController from "legacy-js/core/AppController";
import EndTrialDialog from "legacy-js/react/views/UserOptions/dialogs/EndTrialDialog";

import { SearchInput } from "legacy-js/react/components";
import {
    BlueButton,
    UIContainerContext,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "legacy-js/react/components/UiComponents";
import ImageThumbnailGrid from "legacy-js/react/views/AddAssets/Components/ImageThumbnailGrid";
import { Notice } from "legacy-js/react/components/Notice";
import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";
import { openPricingPage } from "legacy-js/core/utilities/externalLinks";
import { ShowInsufficientCreditsDialog, ShowTooManyRequestsDialog } from "legacy-js/react/components/Dialogs/CredtsErrorDialog";

const logger = getLogger();

const imageStyles = [
    "Professional illustration",
    "Renaissance oil painting",
    "Hand drawn sketch",
    "Realistic photo",
    "Digital art",
    "By Andy Warhol"
];

function getShadow(color) {
    const r = 1;
    const n = Math.ceil(2 * Math.PI * r);
    let shadow = "";
    for (let i = 0; i < n; i++) {
        const theta = 2 * Math.PI * i / n;
        shadow += "drop-shadow(" + (r * Math.cos(theta)) + "px " + (r * Math.sin(theta)) + "px 2px " + color + ") ";
    }
    return shadow;
}

const CreditsInfoContainer = styled.div`
    position: absolute;
    bottom: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    z-index: 99999;
    filter: ${getShadow("white", 3)};

    >span {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #000000;
        white-space: nowrap;
    }
`;

const GetCreditsButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;   
    width: 104px;
    height: 28px;
    background: #11A9E2;
    border-radius: 2px;
    margin-left: 20px;
    pointer-events: auto;
    
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    transition: 300ms;
    user-select: none;

    &:hover {
        background: rgb(11, 118, 158);
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }   
`;

const DalleLogoContainer = styled.div`
    position: absolute;
    bottom: 10px;
    left: 20px;
    display: flex;
    align-items: center;
    z-index: 99999;
    filter: ${getShadow("white", 3)};

    >img {
        margin-right: 10px;
    }

    >a {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #666666;
        text-decoration: none;

        &:hover {
            color: #11A9E2;
        }
    }
`;

const DialogMessageContainer = styled.div`
    >p:first-child {
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.5px;
        color: #222222;
    }

    >p {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0.5px;
    }
`;

const TipContainer = styled.div.attrs(({ visible }) => ({
    style: {
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? "auto" : "none"
    }
}))`
    position: absolute;
    left: 60px;
    top: -15px;
    padding-top: 20px;

    transition: opacity 300ms;
    z-index: 999999;
`;

const TipBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 2px;  

    >span {
        margin-left: 10px;
        width: 500px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #666666;

        >a {
            text-decoration: none;
        }
    }
`;

const StyleSelectButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;  
    background: #FFFFFF;
    cursor: pointer;

    >span {
        margin-left: 10px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #666666;
        white-space: nowrap;
    }
`;

const NoCreditsAvailableMesssage = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
    color: #222222;
    text-transform: none;

    >a {
        text-decoration: none;
    }
`;

const ThumbnailContainer = styled.div.attrs(({ width }) => ({
    style: {
        width: `${width}px`,
        height: `${width}px`
    }
}))`
    position: relative;
`;

const ThumbnailContainerImage = styled.img.attrs(({ showOutline }) => ({
    style: {
        outline: showOutline ? "1px solid #50bbe6" : "none"
    }
}))`
    width: 100%;
`;

const ThumbnailContainerBottomBar = styled.div.attrs(({ visible }) => ({
    style: {
        opacity: visible ? 1 : 0
    }
}))`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: black;
    padding: 5px 10px;
    transition: opacity 300ms;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >span {
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #FFFFFF;
        margin-right: 10px;
    }

    >div {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            color: #fefefe;
        }

        >svg {
            color: #666666;
        }
    }
`;

function Thumbnail({ width, readUrl, attribution, onSelect, onSetSearchQuery }) {
    const ref = useRef();

    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const [isHoveringOverContainer, setIsHoveringOverContainer] = useState(false);

    const handleOpenPopover = () => {
        setPopoverOpen(true);
    };

    const handleClosePopover = () => {
        setPopoverOpen(false);
        setIsHoveringOverContainer(false);
    };

    const handleMouseEnter = () => {
        setIsHoveringOverContainer(true);
    };

    const handleMouseLeave = () => {
        if (!isPopoverOpen) {
            setIsHoveringOverContainer(false);
        }
    };

    const handleDownload = () => {
        downloadFromUrl(readUrl, sanitize(attribution));
        handleClosePopover();
    };

    const handleSetSearchQuery = () => {
        onSetSearchQuery(attribution);
        handleClosePopover();
    };

    return (<>
        <ThumbnailContainer
            width={width}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ThumbnailContainerImage
                src={readUrl}
                onClick={onSelect}
                showOutline={isHoveringOverContainer}
            />
            <ThumbnailContainerBottomBar
                visible={isHoveringOverContainer}
            >
                <span>"{attribution}"</span>
                <div
                    ref={ref}
                    onClick={handleOpenPopover}
                >
                    <MoreVertIcon />
                </div>
            </ThumbnailContainerBottomBar>
        </ThumbnailContainer>
        <Popover
            open={isPopoverOpen}
            anchorEl={ref.current}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            onClose={handleClosePopover}
        >
            <MenuList>
                <MenuItem value={0} onClick={handleDownload}>Download Image</MenuItem>
                <MenuItem value={1} onClick={handleSetSearchQuery}>Use this prompt again</MenuItem>
            </MenuList>
        </Popover>
    </>);
}

export class AISearchPane extends Component {
    static contextType = UIContainerContext;
    static searchResultsLength = 1;

    constructor() {
        super();

        this.state = {
            isLoading: true,
            isSearching: false,
            searchQuery: "",
            searchResults: [],
            creditsBalance: 0,
            workspaceId: AppController.workspaceId,
            orgId: AppController.orgId,
            isHoveringOverSearchInput: false,
            isHoveringOverTip: false,
            isStyleSelectMenuOpen: false
        };

        this.styleSelectButtonRef = React.createRef();
        this.thumbnailButtonsRefs = [...new Array(AISearchPane.searchResultsLength)].map(() => React.createRef());
    }

    componentDidMount() {
        (async () => {
            await Promise.all([
                this.loadCreditsBalance(),
                this.loadRecentlyUsedAssets()
            ]);

            this.setState({ isLoading: false });
        })().catch(err => logger.error(err, "[AISearchPane] load failed"));
    }

    async loadCreditsBalance() {
        const { workspaceId } = this.state;
        const creditsBalance = await AiGenerationService.getCreditsBalance(workspaceId);
        this.setState({ creditsBalance });
    }

    async loadRecentlyUsedAssets() {
        const { orgId } = this.state;

        let assets = await ds.assets.getAssetsByType(AssetType.IMAGE, orgId);

        // Make sure all assets loaded
        await Promise.all(assets.map(asset => asset.load()));

        // Keep only dall-e generated images
        assets = assets.filter(asset => asset.get("source") === "dall-e");

        assets
            // Do correct sorting
            .sort((a, b) => {
                const userAssetA = ds.userAssets.get(a.id);
                const userAssetB = ds.userAssets.get(b.id);
                const timeA = userAssetA?.get("lastUsed") ?? userAssetA?.get("modifiedAt");
                const timeB = userAssetB?.get("lastUsed") ?? userAssetB?.get("modifiedAt");
                return timeB - timeA;
            })
            // Only load up to 100 results and ignore the already loaded assets
            .slice(0, 100);

        // Load preview urls and return results
        const searchResults = await Promise.all(assets.map(async asset => {
            const readUrl = await asset.getURL(ASSET_RESOLUTION.MEDIUM);
            return {
                readUrl,
                assetId: asset.id,
                attribution: asset.get("attribution")
            };
        }));

        this.setState({ searchResults });
    }

    doImageSearch = async searchQuery => {
        const { workspaceId } = this.state;

        this.setState({ isSearching: true, searchQuery, isHoveringOverSearchInput: false, isHoveringOverTip: false });

        try {
            const { response: { assets }, remainingCreditsBalance: creditsBalance } = await AiGenerationService.generateImages(searchQuery, AISearchPane.searchResultsLength);

            // Preload all images for proper caching and smoother ux
            await Promise.all(assets.map(({ readUrl }) => loadImage(readUrl)));

            this.setState(({ searchResults }) => ({
                isSearching: false,
                searchResults: [...assets, ...searchResults],
                creditsBalance
            }));
            trackActivity("Image", "Generate", null, null, {
                prompt: searchQuery,
                assetId: assets[0].assetId,
                creditsBalance
            });
        } catch (err) {
            logger.error(err, "[AISearchPane] doImageSearch() failed", { workspaceId, searchQuery });

            if (err instanceof InsufficientCreditsError) {
                ShowInsufficientCreditsDialog(() => this.loadCreditsBalance());
            } else if (err instanceof TooManyRequestsError) {
                ShowTooManyRequestsDialog();
            } else {
                ShowErrorDialog({
                    error: "Sorry, we couldn't process your request",
                    message: err.message
                });
            }

            this.setState({
                isSearching: false,
                searchQuery: ""
            });
        }
    }

    handleSelectImage = async assetId => {
        const { addAssetCallback, handleConfirm } = this.props;

        if (this.isAlreadyClicked) {
            return;
        }
        this.isAlreadyClicked = true;

        this.setState({ isLoading: true });

        const asset = await ds.assets.getAssetById(assetId, AssetType.IMAGE);
        addAssetCallback(asset);

        handleConfirm();
    }

    handleClearSearch = () => {
        this.setState({ searchQuery: "" });
    }

    handleHoverOverSearchInput = isEntered => {
        const stateUpdates = { isHoveringOverSearchInput: isEntered };
        if (isEntered) {
            stateUpdates.isHoveringOverTip = true;
        }
        this.setState(stateUpdates);
    }

    handleHoverOverTip = isEntered => {
        this.setState({ isHoveringOverTip: isEntered });
    }

    handleOpenStyleSelectMenu = () => {
        this.setState({ isStyleSelectMenuOpen: true });
    }

    handleCloseStyleSelectMenu = () => {
        this.setState({ isStyleSelectMenuOpen: false });
    }

    handleStyleSelected = selectedStyle => {
        this.handleCloseStyleSelectMenu();
        this.setState(({ searchQuery }) => ({ searchQuery: `${searchQuery}, ${selectedStyle}` }));
    }

    handleSetSearchQuery = searchQuery => {
        this.setState({ searchQuery });
    }

    handleEndTrial = () => {
        ShowDialogAsync(EndTrialDialog)
            .then(() => this.loadCreditsBalance());
    };

    render() {
        const {
            isLoading,
            isSearching,
            searchResults,
            searchQuery,
            creditsBalance,
            isHoveringOverSearchInput,
            isHoveringOverTip,
            isStyleSelectMenuOpen,
            workspaceId
        } = this.state;

        const uiPaneContentsStyle = { paddingTop: 20, position: "relative" };

        const DalleLogo = (<DalleLogoContainer>
            <img src={getStaticUrl("/images/ai-search/dall-e-logo.png")} />
            <a href="https://openai.com/dall-e-2/" target="_blank">Powered by DALL·E</a>
        </DalleLogoContainer>);

        const hasFeature = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, workspaceId);
        if (!hasFeature) {
            return (<UIPane>
                <UIPaneHeader></UIPaneHeader>
                <UIPaneContents style={uiPaneContentsStyle}>
                    <Notice
                        image={getStaticUrl("/images/ai-search/ai-search.gif")}
                        imageWidth={400}
                        title="Describe what you are looking for and let ImageBot generate an image unique to your story."
                        message={
                            <>
                                <div>Upgrade to Beautiful.ai Pro to create one of a kind AI generated images.</div>
                                <BlueButton
                                    onClick={() => openPricingPage()}
                                >Get Started</BlueButton>
                            </>
                        }
                    />
                </UIPaneContents>
                {DalleLogo}
            </UIPane>);
        }

        return (<>
            <UIPane>
                <FetchingClickShield visible={isLoading} backgroundColor="white" />
                <UIPaneHeader>
                    {creditsBalance === 0 && <NoCreditsAvailableMesssage>
                        All available credits have been used.
                        &nbsp;<a onClick={this.handleEndTrial}>Start your subscription now</a> to generate new images.
                    </NoCreditsAvailableMesssage>}
                    {creditsBalance !== 0 && <>
                        <SearchInput
                            prefilledSearch={searchQuery}
                            handleSubmit={this.doImageSearch}
                            placeholder="Type a detailed description of your image..."
                            handleClearSearch={this.handleClearSearch}
                            focus={!this.context}
                            disabled={isSearching}
                            onChange={this.handleSetSearchQuery}
                            onMouseEnter={() => this.handleHoverOverSearchInput(true)}
                            onMouseLeave={() => this.handleHoverOverSearchInput(false)}
                        />
                        <StyleSelectButtonContainer ref={this.styleSelectButtonRef} onClick={this.handleOpenStyleSelectMenu}>
                            <img src={getStaticUrl("/images/ai-search/bulb.svg")} />
                            <span>In the style of...</span>
                            <ArrowDropDownIcon style={{ color: "#666666" }} />
                        </StyleSelectButtonContainer>
                    </>}
                </UIPaneHeader>
                <UIPaneContents style={uiPaneContentsStyle}>
                    {isSearching &&
                        <Notice
                            image={getStaticUrl("/images/bai-bot/bai-bot-happy.png")}
                            imageWidth={100}
                            showProgressBar
                            title="Generating your image... Please wait."
                        />
                    }
                    {!isSearching && <>
                        {searchResults.length > 0 &&
                            <ImageThumbnailGrid columns={2}>
                                {searchResults.map(({ assetId, attribution, readUrl }) => (
                                    <Thumbnail
                                        key={assetId}
                                        readUrl={readUrl}
                                        attribution={attribution}
                                        onSelect={() => this.handleSelectImage(assetId)}
                                        onSetSearchQuery={this.handleSetSearchQuery}
                                    />
                                ))}
                            </ImageThumbnailGrid>
                        }
                        {searchResults.length === 0 &&
                            <Notice
                                image={getStaticUrl("/images/ai-search/ai-search.gif")}
                                imageWidth={400}
                                title="Describe what you are looking for and let DesignerBot generate an image unique to your story."
                            />
                        }
                    </>}
                    <TipContainer
                        visible={isHoveringOverSearchInput || isHoveringOverTip}
                        onMouseEnter={() => this.handleHoverOverTip(true)}
                        onMouseLeave={() => this.handleHoverOverTip(false)}
                    >
                        <TipBox>
                            <img src={getStaticUrl("/images/bai-bot/new-bot.svg")} />
                            <span><b>Tip!</b> Be specific and detailed in your prompt—including subject, setting, and mood.
                                For best results, we recommend stylized imagery and avoiding photorealistic images of
                                people and faces. Learn more about crafting an effective prompt
                                &nbsp;<a href="https://openai.com/dall-e-2/" target="_blank">here</a>.
                            </span>
                        </TipBox>
                    </TipContainer>
                </UIPaneContents>
                {DalleLogo}
                {!isLoading && <CreditsInfoContainer>
                    {creditsBalance === -1 && <span>Free while in beta</span>}
                    {creditsBalance !== -1 && <span>{creditsBalance} Credits Available</span>}
                </CreditsInfoContainer>}
            </UIPane>
            <Popover
                open={isStyleSelectMenuOpen}
                anchorEl={this.styleSelectButtonRef.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={this.handleCloseStyleSelectMenu}
            >
                <MenuList>
                    {imageStyles.map((style, idx) => <MenuItem key={idx} value={style} onClick={() => this.handleStyleSelected(style)}>{style}</MenuItem>)}
                </MenuList>
            </Popover>
        </>);
    }
}

export default AISearchPane;
