import React from "react";

function Icon({ iconName, small, large, light, dark, style, onClick }) {
    let iconClasses = ["micon"];
    if (small) {
        iconClasses.push("small");
    } else if (large) {
        iconClasses.push("large");
    }

    if (light) {
        iconClasses.push("light");
    } else if (dark) {
        iconClasses.push("dark");
    }

    return iconName?.length ? (
        <i style={style} className={iconClasses.join(" ")} onClick={onClick}>
            {iconName}
        </i>
    ) : null;
}

export default Icon;
