import { _, Backbone, $ } from "legacy-js/vendor";

import { DebugBox } from "../elements/elements/DebugBox";

class CanvasLayouter {
    constructor(canvas, template) {
        _.extend(this, Backbone.Events);

        this.canvas = canvas;
        this.template = template;

        this.elements = {};
    }

    destroy() {
        this.clearElements();
        this.stopListening();

        return null;
    }

    clearElements() {
        _.each(this.elements, element => {
            element.remove();
        });
    }

    get primaryElement() {
        return this.elements.primary;
    }

    render() {
        // to be overridden
    }

    createElement(id, elementClass) {
        if (!elementClass) elementClass = DebugBox;

        this.model.elements[id] = this.model.elements[id] || {};

        if (this.elements[id]) return this.elements[id];

        let element = new (elementClass)({
            id: id,
            canvas: this.canvas
        });
        this.elements[id] = element;

        return element;
    }

    removeElement(id) {
        let element = this.elements[id];

        if (element) {
            delete this.elements[id];
            delete this[id];

            $(element.svg.node).velocity("transition.fadeOut", {
                complete: () => {
                    element.svg.remove();
                }
            });
        }
    }

    clear() {
        this.elements = {};
    }
}

export { CanvasLayouter };
