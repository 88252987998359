import React from "react";
import styled from "styled-components";

const SignupButtonContainer = styled.div.attrs(({ style = {} }) => ({ style }))`
    border-radius: 2px;
    background-color: #50bbe6;
    color: white;     
    cursor: pointer;  
    
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;        
    letter-spacing: 2px;
    
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #23aae0;
    }
`;

function SignupButton({ style = {} }) {
    return (
        <SignupButtonContainer
            variant="contained"
            color="primary"
            onClick={() => window.location = "/signup"}
            disableElevation
            style={style}
            data-testing-info="deck-signup-button"
        >
            sign up free
        </SignupButtonContainer>
    );
}

export default SignupButton;
