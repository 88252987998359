import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";

export const LinkType = {
    PUBLIC: "public",
    PROTECTED: "protected",
    INVITE: "invite",
    EMBED: "embed",
    SOCIAL: "social",
    MEETING: "meeting",
    INTEGRATOR: "integrator",
};

export default class linkDS {
    static createPublicLink(presentation, name) {
        return presentation.links.createLink({
            name: name ?? "Public Link",
            type: LinkType.PUBLIC
        });
    }

    static createProtectedLink(presentation, name) {
        return presentation.links.createLink({
            name: name ?? "Protected Link",
            type: LinkType.PROTECTED,
        });
    }

    static createEmbedLink(presentation) {
        return presentation.links.createLink({
            name: "Embed Link",
            type: LinkType.EMBED
        });
    }

    static createIntegratorLink(presentation) {
        return presentation.links.createLink({
            name: `integration-${app.integrator.type}`,
            type: LinkType.INTEGRATOR
        });
    }

    static createInviteLink(presentation, email, customMessage, expiresAt, allowCommenting) {
        return presentation.links.createLink({
            name: "Invite",
            email,
            customMessage,
            expiresAt,
            allowCommenting,
            type: LinkType.INVITE
        });
    }

    static createSocialLink(presentation, socialNetwork, allowCommenting) {
        return presentation.links.createLink({
            name: socialNetwork,
            type: LinkType.SOCIAL,
            allowCommenting,
        });
    }

    static createMeetingLink(presentation) {
        let meetingId = new Date().getTime().toString().substr(4);
        let hostPIN = Math.round(Math.random() * 899) + 100;
        // TODO: check if meeting id conflicts

        return presentation.links.createLink({
            name: "My meeting",
            type: LinkType.MEETING,
            meetingId,
            hostPIN,
            hasAudio: false
        });
    }

    static updateLink(link, options) {
        if ("password" in options) {
            link.updatePassword(options.password);
        }

        delete options.password;
        return link.update(options);
    }

    static saveLink(presentation, link) {
        return presentation.links.addLink(link);
    }

    static deleteLink(presentation, id) {
        return presentation.links.deleteLink(id);
    }

    static getLinkById(presentation, id) {
        return presentation.links.get(id);
    }

    static getPresentation() {
        return ds.selection.presentation;
    }
}

/**
 * Validates password requirements for a presentation link
 * (currently, we just require non-empty password)
 */
export function validateLinkPassword(password) {
    if (!password) {
        return "Password is required";
    }
}
