import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces.js";
import { controls } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { $, _, SVG } from "legacy-js/vendor";
import { AssetType } from "legacy-common/constants";
import { ShowDialog, ShowWarningDialog, ShowDialogAsync } from "legacy-js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "legacy-js/react/components/Dialogs/BadFitDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";
import getLogger, { LogGroup } from "js/core/logger";

import { HorizontalTaskLists } from "../../elements/elements/HorizontalTaskList";

import { ElementOptionsMenu } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";

const logger = getLogger(LogGroup.ELEMENTS);

const HorizontalTaskListsSelection = CollectionElementSelection.extend({

    getAddItemLabel: function() {
        return "Add Row";
    },

    renderControls: function() {
        this.createAddItemButton();

        this.addControl({
            id: "horiz-task-style",
            type: controls.POPUP_BUTTON,
            label: "Style",
            items: () => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    model: this.element.model,
                    transitionModel: true,
                    markStylesAsDirty: true,
                    property: "itemStyle",
                    items: [{
                        value: "task",
                        label: "Task",
                        icon: "/images/ui/horizontal-tasks-style-task.svg"
                    }, {
                        value: "process",
                        label: "Process",
                        icon: "/images/ui/horizontal-tasks-style-process.svg",
                    }]
                })
            }]
        });

        this.addControl({
            id: "horiz-task-layout",
            type: controls.POPUP_BUTTON,
            label: "Layout",
            items: () => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    model: this.element.model,
                    transitionModel: true,
                    property: "taskLayout",
                    items: [{
                        value: "stack",
                        label: "Stack",
                        icon: "/images/ui/horizontal-tasks-layout-stack.svg"
                    }, {
                        value: "fill",
                        label: "Fill",
                        icon: "/images/ui/horizontal-tasks-layout-fill.svg",
                    }, {
                        value: "fit",
                        label: "Fit",
                        icon: "/images/ui/horizontal-tasks-layout-fit.svg"
                    }]
                })
            }]
        });
    }
});

const HorizontalTaskListsOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Titles",
            property: "showTitles"
        });
    }
});

const HorizontalTaskListsRowSelection = CollectionItemElementSelection.extend({
    captureMouseEvents: false,

    getDragAxis: function() {
        return "y";
    },

    renderControls: function() {
        this.$addItem = this.$el.addEl(controls.createButtonWidget({
            icon: "add",
            className: "large",
            callback: () => {
                this.element.taskList.addItem();
                this.element.canvas.updateCanvasModel(false).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we can't fit any more items in this row",
                    });
                });
            }
        }));
    },

    _layout: function() {
        if (this.$addItem) {
            if (this.element.taskList.itemElements.length) {
                this.$addItem.left((this.element.taskList.bounds.left + _.maxBy(this.element.taskList.itemElements, element => element.bounds.right).bounds.right + 20) * this.canvasScale + 20);
            }
            this.$addItem.top(this.element.bounds.height / 2 * this.canvasScale);
        }
    }

});

const HorizontalTaskElementSelection = CollectionItemElementSelection.extend({

    canDrag: function() {
        return this.element.canDrag;
    },

    getWidgetPosition() {
        return "inner";
    },

    getOffset() {
        return 10;
    },

    renderControls: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Icon",
            property: "icon",
            menuContents: closeMenu => {
                let $menu = $.div("pictorial-chart-menu");

                let loadIcon = ($menu, iconId) => {
                    let $item = $menu.addEl($.div("icon"));
                    $item.data("icon", iconId);

                    if (iconId != "none") {
                        ds.assets.getAssetById(iconId, "icon").then(icon => {
                            const url = icon.get("original");
                            if (url.startsWith("http")) {
                                return fetch(url).then(res => {
                                    return res.text();
                                });
                            } else {
                                return Promise.resolve(url);
                            }
                        }).then(svgData => {
                            $item.append(svgData);
                        });
                    } else {
                        $item.append($.div("none", "NONE"));
                    }

                    $item.on("click", () => {
                        this.element.model.icon = iconId;
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    });
                };

                let $icons = $menu.addEl($.div("preset-icons"));
                loadIcon($icons, "none");

                loadIcon($icons, "check-yes");
                loadIcon($icons, "x");
                loadIcon($icons, "thumbs-up");
                loadIcon($icons, "thumbs-down");
                loadIcon($icons, "star");
                loadIcon($icons, "star-half");
                loadIcon($icons, "star-none");
                loadIcon($icons, "question");
                loadIcon($icons, "warning");

                loadIcon($icons, "full");
                loadIcon($icons, "quarters-three");
                loadIcon($icons, "contrast");
                loadIcon($icons, "quarter");
                loadIcon($icons, "number-one");
                loadIcon($icons, "number-two");
                loadIcon($icons, "number-three");
                loadIcon($icons, "number-four");
                loadIcon($icons, "number-five");
                loadIcon($icons, "number-six");
                loadIcon($icons, "number-seven");
                loadIcon($icons, "number-eight");
                loadIcon($icons, "number-nine");

                let $choose = $menu.addEl(controls.createButton(this, {
                    label: "Choose Icon...",
                    callback: () => {
                        closeMenu();
                        ShowDialog(AddAssetsContainer, {
                            assetType: AssetType.ICON,
                            workspaceId: ds.selection.presentation.getWorkspaceId(),
                            callback: model => {
                                const {
                                    content_type,
                                    content_value,
                                } = model;
                                if (content_type == AssetType.ICON) {
                                    this.element.model.icon = content_value;
                                    this.element.canvas.updateCanvasModel(false);
                                } else {
                                    ShowWarningDialog({
                                        title: "Unsupported asset type",
                                        message: "Please choose an icon from the asset chooser.",
                                    });
                                }
                            },
                        });
                    }
                }));

                return $menu;
            }
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true,
            includeNone: true,
            includePositiveNegative: true
        });
    },

    onStartDrag: function(dragPosition = null) {
        app.isDraggingItem = true;
        ds.selection.rolloverElement = null;
        this.element.isDragging = true;
        this.element.showDragDropTarget = this.showDragDropTarget;
        app.mainView.editorView.selectionLayer.hideWidgets($(".rollover_hilite, .drag_button"));
        // Explicitly assigning drag position to ensure smooth dragging when changed parent
        if (dragPosition) {
            this.element.dragPosition = dragPosition;
            // this.element.parentElement.refreshElement();
        }
    },

    onStopDrag: function(event) {
        event.stopPropagation();
        this.element.isDragging = false;
        this.element.canvas.updateCanvasModel(false)
            .then(() => {
                ds.selection.rolloverElement = this.element;
                ds.selection.element = this.element;
                app.mainView.editorView.selectionLayer.showWidgets();
                ds.selection.element.overlay && ds.selection.element.overlay.layout();
            })
            .catch(err => {
                logger.error(err, "[HorizontalTaskElementSelection] onStopDrag() failed", { slideId: this.element.canvas.dataModel.id });
            });
    },

    onDrag: function(event, position, dragProps) {
        if (this.element.canvas.layouter.isGenerating) {
            return;
        }

        // We have to calculate a new drag position based on the canvas position because its parent may have been changed
        // during the drag so the element position may not be correct due to that
        const getDragPosition = () => this.element.bounds.position.plus(position.canvasPosition.minus(this.element.canvasBounds.position));
        this.element.dragPosition = getDragPosition();

        if (!this.canvas.layouter.isGenerating) {
            const newParentElement = this.calcDropTarget(position);
            this.element.canvas.refreshCanvas()
                .then(() => {
                    if (newParentElement) {
                        this.element = newParentElement.itemElements.find(element => element && element.id === this.element.id);
                        // this.element.bounds has changed after the refreshCanvas(), so we have to calc a new drag position
                        this.onStartDrag(getDragPosition());
                    }
                });
        }
    },

    /**
     * Calculates a drop target, moves the element to it if found.
     * Returns a new parent element in case the element was moved into another collection.
     */
    calcDropTarget: function(position) {
        if (this.isRefreshingCanvas) {
            return null;
        }

        const { canvasPosition } = position;

        const dragBounds = new geom.Rect(canvasPosition, this.element.bounds.size);

        let score = 0;
        let dropTarget;

        const rootContainer = this.element.findClosestOfType(HorizontalTaskLists);
        const taskLists = rootContainer.itemElements.map(rowElement => rowElement.taskList);
        taskLists.forEach(taskList => {
            taskList.itemElements.forEach(target => {
                if (target !== this.element) {
                    const overlap = dragBounds.intersection(target.canvasBounds).area();
                    if (overlap > score) {
                        score = overlap;
                        dropTarget = target;
                    }
                }
            });
        });

        if (score > 0) {
            if ((score > dropTarget.canvasBounds.area() * 0.67) || (score > dragBounds.area() * 0.67)) {
                const dropTargetIndex = dropTarget.itemIndex;
                this.element.parentElement.deleteItem(this.element.id);
                dropTarget.parentElement.addItem(this.element.model, dropTargetIndex);
                if (dropTarget.parentElement !== this.element.parentElement) {
                    return dropTarget.parentElement;
                }
            }
        } else {
            // Checking if the element is hovering over another row and appending it to the end of it
            for (const taskList of taskLists.filter(taskList => taskList !== this.element.parentElement)) {
                if (dragBounds.intersection(taskList.canvasBounds.inflate({ right: 1000 })).area() > dragBounds.area() * 0.67) {
                    this.element.parentElement.deleteItem(this.element.id);
                    taskList.addItem(this.element.model);
                    return taskList;
                }
            }
        }

        return null;
    }
});

export const editors = {
    HorizontalTaskListsSelection,
    HorizontalTaskListsRowSelection,
    HorizontalTaskElementSelection,
    HorizontalTaskListsOptionsMenu
};
