import * as geom from "js/core/utilities/geom";
import { AssetType } from "legacy-common/constants";

import { BaseElement } from "../base/BaseElement";
import { ContentElement } from "../base/ContentElement";

class PPTSlidePreviewImage extends ContentElement {
    get canSelect() {
        return false;
    }

    get canRollover() {
        return false;
    }
}

export class PPTSlide extends BaseElement {
    static get schema() {
        return {
            previewImage: {
                content_type: AssetType.IMAGE,
                content_value: null
            }
        };
    }

    get canSelect() {
        return true;
    }

    getCanvasMargins() {
        return { left: 0, top: 0, right: 0, bottom: 0 };
    }

    _build() {
        this.previewImage = this.addElement("asset", () => PPTSlidePreviewImage, {
            model: this.model.previewImage,
            allowScaleBelowFill: false,
            allowImageScaling: false,
            allowBackdrop: false
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const previewImageProps = this.previewImage.calcProps(size, { fitAsset: true });
        previewImageProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }

    getAnimations() {
        return [];
    }
}

export const elements = {
    PPTSlide
};

