import React from "reactn";
import * as geom from "js/core/utilities/geom";
import { getStaticUrl } from "legacy-js/config";
import { SVGGroup } from "legacy-js/core/utilities/svgHelpers";

import { BaseElement } from "../base/BaseElement";
import { SVGElement } from "../base/SVGElement";
import { TextElement } from "../base/TextElement";

class SlidePlaceholderBackground extends SVGElement {
    get backgroundImageUrl() {
        return getStaticUrl("/images/placeholderbg.svg");
    }

    _load() {
        return new Promise(resolve => {
            const image = new Image();
            image.onload = () => {
                this.imageSize = new geom.Size(image.naturalWidth, image.naturalHeight);
                resolve();
            };
            image.src = this.backgroundImageUrl;
        });
    }

    renderSVG(props, styles) {
        return (<SVGGroup ref={this.ref} key={this.id}>
            <image
                x={-50}
                y={-50}
                href={this.backgroundImageUrl}
                onLoad={this.getImageOnLoadPromiseResolver(this.backgroundImageUrl)}
            />
        </SVGGroup>);
    }
}

class SlidePlaceholder extends BaseElement {
    getCanvasMargins() {
        return {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
    }

    _build() {
        this.canvas.$el.addClass("slide_placeholder_canvas");

        this.background = this.addElement("background", () => SlidePlaceholderBackground, {
            preventExport: true
        });

        this.text = this.addElement("text", () => TextElement, {
            placeholder: "Add a note about this slide",
            scaleTextToFit: true,
            allowStyling: false
        });
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const backgroundProps = this.background.calcProps(size);
        backgroundProps.bounds = new geom.Rect(0, 0, size);

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }
}

export const elements = {
    SlidePlaceholder,
};
