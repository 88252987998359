class ElementManager {
    constructor() {
        this.constructors = {};
    }

    register(moduleElements) {
        if (moduleElements == undefined) {
            throw new Error("Module not initialized, possible circular dependency?");
        }

        for (const className in moduleElements) {
            if (className == undefined) {
                throw new Error("Cannot register editor");
            }

            this.constructors[className] = moduleElements[className];
        }
    }

    get(name) {
        return this.constructors[name];
    }

    has(name) {
        return name in this.constructors;
    }
}

export default new ElementManager();
