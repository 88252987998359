import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import { PusherEventType } from "common/constants";
import { app } from "js/namespaces";

import type { SpreadsheetData } from "js/core/utilities/xlsx";
import type { IDataSource, SourceType } from "common/interfaces/models";

const logger = getLogger(LogGroup.DATA_SOURCE);

export enum ElementTypesSupported {
    Table = "Table",
    Chart = "Chart",
    PieChart = "PieChart",
    WaterfallChart = "WaterfallChart",
}

export enum DataState {
    Loading = "loading",
    Updated = "updated",
    Disconnected = "disconnected",
    Errored = "errored",
}

const POLL_INTERVAL_MS = 1 * 60 * 1000; // every 1 min

/**
 * Instance per dataSource entry in mongo model, handles refreshing datasource from api,
 * listening to pusher changes, and notifying registered callbacks.
 */
export class DataSourceManager {
    dataSourceId: string
    dataStateChangedCallbacks: Function[]

    pusherChannel: ExtendedChannel
    pollTimeout: ReturnType<typeof setTimeout>

    constructor(dataSourceId: string, dataStateChangedCb: Function) {
        this.dataSourceId = dataSourceId;
        this.dataStateChangedCallbacks = [];

        if (dataStateChangedCb) this.registerCallback(dataStateChangedCb);

        this.startApiPolling();
        this.listenForUpdates();

        this.refreshData();
    }

    refreshData = async (skipDataRefresh?: boolean) => {
        try {
            if ((window as any).isPlayer) return;

            this.updateCallbacks(DataState.Loading);

            const latestData = await DataSourceManager.fetchById(this.dataSourceId, skipDataRefresh);

            if (latestData?.oauthDisconnected) {
                this.updateCallbacks(DataState.Disconnected);
            } else {
                this.updateCallbacks(DataState.Updated, latestData);
            }

            return latestData;
        } catch (err) {
            logger.error(err, "failed to refresh data", { dataSourceId: this.dataSourceId });

            this.updateCallbacks(DataState.Errored, null, err);
        }
    }

    updateCallbacks(state: DataState, data?: any, err?: Error) {
        this.dataStateChangedCallbacks.forEach(cb => cb(state, data, err));

        if (state === DataState.Disconnected) {
            this.removeListeners();
        }
    }

    registerCallback(dataStateChangedCb: Function) {
        this.dataStateChangedCallbacks.push(dataStateChangedCb);
    }

    async listenForUpdates() {
        try {
            this.pusherChannel = await pusher.subscribe(`data-sources-${this.dataSourceId}`);
            this.pusherChannel.bind(PusherEventType.DATA_RECORD_UPDATED, this.refreshData);
        } catch (err) {
            logger.error(err, "failed to create pusher channel", { dataSourceId: this.dataSourceId });
        }
    }

    async startApiPolling() {
        if (this.pollTimeout) {
            clearTimeout(this.pollTimeout);
        }

        const execOnTimeout = async () => {
            await this.refreshData();

            this.pollTimeout = setTimeout(execOnTimeout, POLL_INTERVAL_MS);
        };

        this.pollTimeout = setTimeout(execOnTimeout, POLL_INTERVAL_MS);
    }

    removeListeners(dataStateChangedCb?: Function) {
        if (dataStateChangedCb) {
            const cbIndex = this.dataStateChangedCallbacks.findIndex(cb => cb === dataStateChangedCb);
            if (cbIndex >= 0) {
                this.dataStateChangedCallbacks.splice(cbIndex, 1);
            }
        } else {
            this.dataStateChangedCallbacks = [];
        }

        if (!this.dataStateChangedCallbacks.length) {
            instances.delete(this.dataSourceId);

            if (this.pusherChannel) {
                this.pusherChannel.unbind(PusherEventType.DATA_RECORD_UPDATED, this.refreshData);
                if (!this.pusherChannel.isInUse) {
                    pusher.unsubscribe(this.pusherChannel.name);
                }
            }

            if (this.pollTimeout) {
                clearTimeout(this.pollTimeout);
            }
        }
    }

    // static api wrappers

    static async fetchOrCreate(sourceType: SourceType, spreadsheetData: SpreadsheetData): Promise<IDataSource> {
        try {
            return await Api.dataSources.post({ sourceType, spreadsheetData });
        } catch (err) {
            logger.error(err, "Failed to create new datasource entry", { sourceType, spreadsheetDataId: spreadsheetData.id });
        }
    }

    static async fetchById(dataSourceId: string, skipDataRefresh?: boolean): Promise<IDataSource> {
        try {
            return await Api.dataSources.get({ dataSourceId, skipDataRefresh });
        } catch (err) {
            logger.error(err, "Failed to fetch existing datasource", { dataSourceId });
        }
    }

    static async fetchBySourceType(sourceType: SourceType): Promise<IDataSource[]> {
        try {
            return await Api.dataSources.get({ sourceType });
        } catch (err) {
            logger.error(err, "Failed to fetch datasources by source type", { sourceType });
        }
    }

    static async fetchOwnerIds(dataSourceId: string): Promise<string[]> {
        try {
            const res = await Api.dataSources.put({ dataSourceId, action: "getDataSourceOwners" });
            return res.ownerIds;
        } catch (err) {
            logger.error(err, "Failed to fetch datasource created by user", { dataSourceId });
        }
    }
}

const instances: Map<string, DataSourceManager> = new Map();

export function getDataSourceManagerInstance(
    params: { dataSourceId: string, dataStateChangedCb?: Function, createIfMissing?: boolean }
): DataSourceManager {
    const { dataSourceId, dataStateChangedCb, createIfMissing = true } = params;

    const existingInstance = instances.get(dataSourceId);
    if (existingInstance) {
        if (dataStateChangedCb) {
            existingInstance.registerCallback(dataStateChangedCb);
        }
        return existingInstance;
    }

    if (createIfMissing) {
        const newInstance = new DataSourceManager(dataSourceId, dataStateChangedCb);
        instances.set(dataSourceId, newInstance);
        return newInstance;
    }
}
