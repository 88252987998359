import React from "reactn";

export default function ErrorMessage(props) {
    return (
        <div className="error-panel">
            <div className="error-title">
                <i className="micon">warning</i>
                {props.title}
            </div>
            <div className="error-message">{props.message}</div>
        </div>
    );

    //     <div className="dialog_container" style={{background: "rgba(0, 0, 0, 0.33)"}}>
    //         <div className="warning_dialog modal_dialog">
    //             <div className="header">
    //                 <div className="title">An Error Has Occurred:</div>
    //             </div>
    //             <div className="contents">{props.errorMessage}</div>
    //         </div>
    //     </div>
    // )
}
