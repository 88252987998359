import React from "reactn";

import { _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import { getStaticUrl } from "legacy-js/config";
import * as geom from "js/core/utilities/geom";
import { getClipPath, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { ShapeType } from "legacy-common/constants";

import { BaseElement } from "./BaseElement";
import { DivDecorationElement } from "./SVGElement";

class Frame extends BaseElement {
    get canSelect() {
        return false;
    }

    get canRollover() {
        return false;
    }

    get clip() {

    }
}

class BoxFrame extends Frame {
    _build() {
        this.frame = this.addElement("frame", () => DivDecorationElement);
    }

    get layer() {
        return -1;
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const frameProps = this.frame.calcProps(size);
        frameProps.bounds = new geom.Rect(0, 0, size);

        switch (this.options.frameStyle) {
            case "light":
                this.frame.updateStyles({
                    strokeColor: "white",
                    shadow: {
                        blur: 14,
                        offsetX: 0,
                        offsetY: 0,
                        opacity: 0.2
                    }
                });
                break;
            case "dark":
            default:
                this.frame.updateStyles({
                    strokeColor: "black"
                });
                break;
        }

        this.frame.updateStyles({ strokeWidth: this.canvas.styleSheet.decorationVariables[this.canvas.styleSheet.BoxFrame.strokeWidth.substr(1)] });
        const clipBounds = new geom.Rect(0, 0, size).deflate(this.frame.styles.strokeWidth);

        return {
            size,
            clipBounds
        };
    }
}

class CircleFrame extends Frame {
    _build() {
        this.frame = this.addElement("frame", () => DivDecorationElement);
    }

    get layer() {
        return -1;
    }

    _calcProps(props, options) {
        const { size } = props;
        const frameProps = this.frame.createProps();
        frameProps.bounds = new geom.Rect(0, 0, size.square()).centerInRect(new geom.Rect(0, 0, size));

        this.frame.styles.borderRadius = "50%";
        this.frame.styles.strokeWidth = this.canvas.styleSheet.decorationVariables[this.canvas.styleSheet.BoxFrame.strokeWidth.substr(1)];

        switch (this.options.frameStyle) {
            case "circle-light":
                this.frame.updateStyles({
                    strokeColor: "white",
                    shadow: {
                        blur: 14,
                        offsetX: 0,
                        offsetY: 0,
                        opacity: 0.2
                    }
                });
                break;
            case "circle-dark":
                this.frame.updateStyles({
                    strokeColor: "black"
                });
                break;
            default:
                this.frame.styles.strokeWidth = 0;
                break;
        }

        const clipBounds = frameProps.bounds;
        const frameClipPath = getClipPath("circle", clipBounds.size, 0, false, this.canvas.getScale());

        return { size, clipBounds, frameClipPath };
    }
}

class ImageFrame extends Frame {
    get hasClipPath() {
        return this.frameDef.clipPath;
    }

    get frameDef() {
        // note: added trim because there were a few device id's with extra spaces that i repaired
        let def = _.find(DeviceFrames, { id: this.model.frame.trim() || "iphone_x" });
        if (!def) {
            def = DeviceFrames[0];
        }
        return def;
    }

    get frameURL() {
        return getStaticUrl("/images/frames/" + this.frameDef.file);
    }

    _load() {
        return new Promise(resolve => {
            const image = new Image();
            image.src = this.frameURL;

            image.onload = () => {
                this.imageSize = new geom.Size(image.naturalWidth, image.naturalHeight);
                resolve();
            };
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const scale = geom.fitImageToRect(this.imageSize, size);
        const scaledImageSize = this.imageSize.scale(scale);

        return {
            size: scaledImageSize,
            imageScale: scale,
            clipBounds: new geom.Rect(this.frameDef.clip).multiply(scale),
            customClip: this.frameDef.clipPath,
            clipScale: this.frameDef.clipScale
        };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        return <SVGGroup key={this.id}><image href={this.frameURL} style={{ transform: `scale(${props.imageScale})` }} /></SVGGroup>;
    }
}

const DeviceFrames = [{
    id: "none",
    name: "No Frame",
    file: "",
    thumbnail: "frame_none.png",
    category: "Decorative",
},
{
    id: ShapeType.RECT,
    name: "Rect",
    file: "",
    thumbnail: "Square.svg",
    category: "Decorative"
},
{
    id: ShapeType.CIRCLE,
    name: "Circle",
    file: "",
    thumbnail: "Circle.svg",
    category: "Decorative"
},
{
    id: ShapeType.ROUNDED_RECT,
    name: "Rounded Rect",
    file: "",
    thumbnail: "Rounded.svg",
    category: "Decorative"
},
{
    id: ShapeType.OCTAGON,
    name: "Octagon",
    file: "",
    thumbnail: "Octagon.svg",
    category: "Decorative"
},
//     {
//     id: "light",
//     name: "Light Frame",
//     file: "",
//     thumbnail: "frame_white.png",
//     category: "Decorative",
// },
// {
//     id: "dark",
//     name: "Dark Frame",
//     file: "",
//     thumbnail: "frame_dark.png",
//     category: "Decorative",
// },
// {
//     id: "circle",
//     name: "Circle Frame",
//     file: "",
//     thumbnail: "frame_circle.png",
//     category: "Decorative",
// },
// {
//     id: "circle-light",
//     name: "Circle Light Frame",
//     file: "",
//     thumbnail: "frame_circle_light.png",
//     category: "Decorative",
// },
// {
//     id: "circle-dark",
//     name: "Circle Dark Frame",
//     file: "",
//     thumbnail: "frame_circle_dark.png",
//     category: "Decorative",
// },
{
    id: "polaroid",
    name: "Polaroid",
    file: "polaroid2.png",
    thumbnail: "polaroid.png",
    category: "Decorative",
    isFront: true,
    clip: {
        left: 100,
        top: 72,
        width: 635,
        height: 650
    }
},
{
    id: "apple_imac",
    name: "Apple iMac",
    file: "Apple iMac.png",
    thumbnail: "Apple iMac.png",
    category: "Computers",
    clip: {
        left: 49,
        top: 52,
        width: 1111,
        height: 623
    }
},
{
    id: "apple_macbook_air_13",
    name: 'Apple Macbook Air 13"',
    file: "Apple Macbook Air 13.png",
    thumbnail: "Apple Macbook Air 13.png",
    category: "Computers",
    clip: {
        left: 262,
        top: 80,
        width: 1440,
        height: 900
    }
},
{
    id: "apple_macbook_space_grey",
    name: "Apple Macbook",
    file: "Apple-Macbook-Space-Grey.png",
    thumbnail: "Apple-Macbook-Space-Grey.png",
    category: "Computers",
    clip: {
        left: 213,
        top: 72,
        width: 1296,
        height: 809
    }
},
{
    id: "dell_xps_15",
    name: 'Dell XPS 15"',
    file: "Dell XPS 15.png",
    thumbnail: "Dell XPS 15.png",
    category: "Computers",
    clip: {
        left: 272,
        top: 21.33,
        width: 1438,
        height: 810
    }
},
{
    id: "microsoft_surface_book",
    name: "Microsoft Surface Book",
    file: "Microsoft Surface Book.png",
    thumbnail: "Microsoft Surface Book.png",
    category: "Computers",
    clip: {
        left: 233,
        top: 65,
        width: 1252,
        height: 834
    }
},
{
    id: "apple_thunderbolt_display",
    name: "Apple Thunderbolt Display",
    file: "Thunderbolt Display 27.png",
    thumbnail: "Thunderbolt Display 27.png",
    category: "Displays",
    clip: {
        left: 52,
        top: 52,
        width: 1205,
        height: 679
    }
},
{
    id: "dell_ultrasharp_hd_5k",
    name: "Dell Ultrasharp HD 5K Display",
    file: "Dell UltraSharp Ultra HD 5K Monitor 27.png",
    thumbnail: "Dell UltraSharp Ultra HD 5K Monitor 27.png",
    category: "Displays",
    clip: {
        left: 38.24,
        top: 40,
        width: 1173,
        height: 660
    }
},
{
    id: "sony_w850C",
    name: "Sony W85OC 75",
    file: "Sony W85OC 75.png",
    thumbnail: "Sony W85OC 75.png",
    category: "Displays",
    clip: {
        left: 24,
        top: 13,
        width: 1286,
        height: 721
    }
},
{
    id: "iphone_x",
    name: "Apple iPhone X",
    file: "Apple iPhone X Silver.png",
    thumbnail: "Apple iPhone X Silver.png",
    category: "Phones",
    isFront: true,
    clip: {
        left: 50,
        top: 64,
        width: 404,
        height: 872
    },
    // clipPath: "M876 26V27C876 54.7939 854.794 90 806 90H320C271.206 90 250 54.7939 250 27V26C250 13.145 250 0 227 0H130C48.935 0 0 48.935 0 130V2306C0 2387.07 48.935 2436 130 2436H995C1076.07 2436 1125 2387.07 1125 2306V130C1125 48.935 1076.07 0 995 0H899C876 0 876 13.145 876 26Z",
    // clipScale: 0.358
},
{
    id: "apple_iphone_5c",
    name: "Apple iPhone 5c",
    file: "Apple iPhone 5c.png",
    thumbnail: "Apple iPhone 5c.png",
    category: "Phones",
    clip: {
        left: 41,
        top: 147,
        width: 396,
        height: 703
    },
    isHidden: true
},
{
    id: "apple_iphone_7_matte_black",
    name: "Apple iPhone 7 Black",
    file: "Apple iPhone 7 Matte Black.png",
    thumbnail: "Apple iPhone 7 Matte Black.png",
    category: "Phones",
    clip: {
        left: 63,
        top: 155,
        width: 388,
        height: 690
    }
},
{
    id: "apple_iphone_7_silver",
    name: "Apple iPhone 7 Silver",
    file: "Apple iPhone 7 Silver.png",
    thumbnail: "Apple iPhone 7 Silver.png",
    category: "Phones",
    clip: {
        left: 63,
        top: 155,
        width: 388,
        height: 690
    }
},
{
    id: "google_pixel",
    name: "Google Pixel",
    file: "Google Pixel.png",
    thumbnail: "Google Pixel.png",
    category: "Phones",
    clip: {
        left: 25,
        top: 101.6,
        width: 435,
        height: 774
    }
},
{
    id: "samsung_galaxy_s7_black",
    name: "Samsung Galaxy S7 Black",
    file: "Samsung Galaxy S7 Black.png",
    thumbnail: "Samsung Galaxy S7 Black.png",
    category: "Phones",
    clip: {
        left: 42,
        top: 118,
        width: 429,
        height: 762
    }
},
{
    id: "samsung_galaxy_s7_silver",
    name: "Samsung Galaxy S7 Silver",
    file: "Samsung Galaxy S7 Silver.png",
    thumbnail: "Samsung Galaxy S7 Silver.png",
    category: "Phones",
    clip: {
        left: 43,
        top: 118,
        width: 427,
        height: 761
    }
},
{
    id: "apple_watch",
    name: "Apple Watch",
    file: "apple watch.png",
    thumbnail: "apple watch.png",
    category: "Other",
    clip: {
        left: 76,
        top: 240,
        width: 314,
        height: 390
    }
},
{
    id: "ipad_portrait",
    name: "Apple iPad Portrait",
    file: "Apple iPad Air 2 Silver.png",
    thumbnail: "Apple iPad Air 2 Silver.png",
    category: "Other",
    clip: {
        left: 60,
        top: 107,
        width: 590,
        height: 785
    }
},
{
    id: "ipad_landscape",
    name: "Apple iPad Landscape",
    file: "Apple iPad Air 2 Silver Landscape.png",
    thumbnail: "Apple iPad Air 2 Silver Landscape.png",
    category: "Other",
    clip: {
        left: 108,
        top: 66,
        width: 786,
        height: 590
    }
},
{
    id: "microsoft_surface_pro_4",
    name: "Microsoft Surface Pro 4",
    file: "Microsoft Surface Pro 4.png",
    thumbnail: "Microsoft Surface Pro 4.png",
    category: "Other",
    clip: {
        left: 107,
        top: 89,
        width: 1232,
        height: 822
    }
},
{
    id: "cisco_board_pro",
    name: "Webex BoardPro",
    file: "Cisco Board Pro.png",
    thumbnail: "Cisco Board Pro.png",
    category: "Cisco",
    clip: {
        left: 128,
        top: 59,
        width: 1179,
        height: 662
    }
},
{
    id: "cisco_board_pro_wall",
    name: "Webex BoardPro Wall",
    file: "Cisco Board Pro Wall Mount.png",
    thumbnail: "Cisco Board Pro Wall Mount.png",
    category: "Cisco",
    clip: {
        left: 153,
        top: 110,
        width: 1692,
        height: 952
    },
},
{
    id: "cisco_desk",
    name: "Webex Desk",
    file: "Cisco Desk.png",
    thumbnail: "Cisco Desk.png",
    category: "Cisco",
    clip: {
        left: 200,
        top: 130,
        width: 1600,
        height: 900
    }
},
{
    id: "cisco_desk_mini",
    name: "Webex Desk Mini",
    file: "Cisco Desk Mini.png",
    thumbnail: "Cisco Desk Mini.png",
    category: "Cisco",
    clip: {
        left: 196,
        top: 84,
        width: 1225,
        height: 693
    }
}];

export { ImageFrame, BoxFrame, CircleFrame, DeviceFrames };
