import { _ } from "js/vendor";

const loremIpsum = {
    titles: ["Collaborate Effectively", "Organize", "Synchronize", "Innovate", "Delegate Options", "Administrate", "Incubate", "Interactive", "Relationships", "Communicate", "Coordinate Steps", "Growth Strategy", "Empower People", "Visualize Customer Growth", "Build Market Information"],
    paragraphs: ["Collaboratively administrate empowered markets via plug-and-play networks.", "Dynamically procrastinate B2C users after installed base benefits.", "Dramatically visualize customer directed convergence without revolutionary ROI.", "Efficiently unleash cross-media information without cross-media value.", "Quickly maximize timely deliverables for real-time schemas.", "Dramatically maintain clicks-and-mortar solutions without functional solutions.", "Completely synergize resource taxing relationships via premier niche markets.", "Professionally cultivate one-to-one customer service with robust ideas.", "Objectively innovate empowered manufactured products whereas parallel platforms.", "Holistically predominate extensible testing procedures for reliable supply chains.", "Proactively envisioned multimedia based expertise and cross-media growth strategies.", "Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing.", "Interactively coordinate proactive e-commerce via process-centric 'outside the box' thinking.", "Completely pursue scalable customer service through sustainable potentialities.", "Collaboratively administrate turnkey channels whereas virtual e-tailers.", "Objectively seize scalable metrics whereas proactive e-services.", "Seamlessly empower fully researched growth strategies and interoperable internal or 'organic' sources.", "Interactively procrastinate high-payoff content without backward-compatible data.", "Quickly cultivate optimal processes and tactical architectures.", "Globally incubate standards compliant channels before scalable benefits."],
    bulletpoints_titles: ["Collaboratively administrate empowered markets via plug-and-play networks.", "Efficiently unleash cross-media information with proactive e-services.", "Interactively coordinate proactive e-commerce via process-centric 'outside the box' thinking.", "Globally incubate standards compliant channels before scalable benefits.", "Completely pursue scalable customer service through sustainable potentialities.", "Visualize Customer Growth.", "Build Market Information", "Quickly maximize timely deliverables for real-time schemas.", "Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing.", "Quickly cultivate optimal processes and tactical architectures."],
    bulletpoints: ["Propogate issues", "Eliminate duplication processes", "Cultivate optimal growth culture", "Tactical architecture", "Incubate compliant standards", "Energy", "Physical presence", "Sustainable practices", "Interactive", "Urgent timeline", "Outside the box", "What is the reason?"],

    getTitle: function() {
        return loremIpsum.titles[Math.round((loremIpsum.titles.length - 1) * Math.random())];
    },

    getParagraph: function(count) {
        var text = "";
        for (var i = 0; i < count; i++) {
            text += loremIpsum.paragraphs[Math.round((loremIpsum.paragraphs.length - 1) * Math.random())];
        }
        return text;
    },

    getBulletPointTitle: function() {
        return loremIpsum.bulletpoints_titles[Math.round((loremIpsum.bulletpoints_titles.length - 1) * Math.random())];
    },

    getBulletPoint: function() {
        var count = Math.round(Math.random() * 8) + 1;

        var text = "<ul>";
        _.each(_.sample(loremIpsum.bulletpoints, count), bullet => {
            text += "<li>" + bullet + "</li>";
        });
        text += "</ul>";
        return text;
    }
};

export default loremIpsum;
