import React, { Component } from "react";
import { formatter } from "js/core/utilities/formatter";

export default class PerDayCount extends Component {
    render() {
        const {
            stat,
            label,
        } = this.props;

        return (
            <div className="per-day-count">
                <div className="per-day-count-value">
                    <label>
                        {formatter.formatNumber(
                            stat,
                            "0,0"
                        )}
                    </label>
                </div>
                <div className="per-day-label">{label}</div>
            </div>
        );
    }
}
