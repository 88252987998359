import React, { Component } from "react";
import { Divider, TextField } from "@material-ui/core";
import styled from "styled-components";

import { _ } from "legacy-js/vendor";
import { getStaticUrl } from "legacy-js/config";
import { Gap10, Gap20, Gap30 } from "legacy-js/react/components/Gap";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { tinycolor } from "legacy-js/vendor";
import { PopupMenu, PopupMenuPaddedContainer } from "legacy-js/react/components/PopupMenu";

const Thumbnails = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  height: 68px;
  
  label {
    font-size: 10px;
    }
`;

export class ShadowEditor extends Component {
    state = {
        shadowX: 0,
        shadowY: 0,
        shadowBlur: 10,
        shadowColor: "black",
        shadowOpacity: 0.2,
        label: "None"
    }

    constructor(props) {
        super(props);

        let shadow = props.shadow;

        let model = {
            shadowX: shadow.x,
            shadowY: shadow.y,
            shadowBlur: shadow.blur,
            shadowColor: shadow.color,
            shadowOpacity: shadow.opacity
        };

        this.state = model;
        this.state.label = this.getPresetLabel(model);
    }
    handleChange = event => {
        this.props.onChange(event.target.value);
    }

    getPresetLabel = model => {
        const { shadowX, shadowY, shadowBlur, shadowOpacity } = model;

        if (shadowX == undefined && shadowY == undefined && shadowBlur == undefined && shadowOpacity == undefined) {
            return "None";
        }

        let label = "Custom";
        for (let type of ["none", "drop", "soft", "block"]) {
            let preset = this.getPresetModel(type);

            if (shadowX == preset.shadowX && shadowY == preset.shadowY && shadowBlur == preset.shadowBlur && shadowOpacity == preset.shadowOpacity) {
                label = type.toTitleCase();
                break;
            }
        }

        return label;
    }

    updateShadowModel(model) {
        let { shadowX, shadowY, shadowBlur, shadowColor, shadowOpacity } = model;
        let color = tinycolor(shadowColor).setAlpha(shadowOpacity);

        let shadowModel = {
            color: color.toRgbString(),
            x: shadowX,
            y: shadowY,
            blur: shadowBlur
        };

        this.props.onChange(shadowModel);
    }

    setShadowProps = props => {
        let model = {
            shadowX: this.state.shadowX,
            shadowY: this.state.shadowY,
            shadowBlur: this.state.shadowBlur,
            shadowColor: this.state.shadowColor,
            shadowOpacity: this.state.shadowOpacity
        };

        for (let [key, value] of Object.entries(props)) {
            model[key] = value;
        }

        this.updateShadowModel(model);
        this.setState(model);
        this.setState({ label: this.getPresetLabel(model) });
    }

    getPresetModel(type) {
        switch (type) {
            case "none":
                return {
                    shadowX: 0,
                    shadowY: 0,
                    shadowBlur: 0,
                    shadowColor: "black",
                    shadowOpacity: 0
                };
            case "drop":
                return {
                    shadowX: 3,
                    shadowY: 5,
                    shadowBlur: 6,
                    shadowColor: "black",
                    shadowOpacity: 0.4
                };
            case "soft":
                return {
                    shadowX: 0,
                    shadowY: 0,
                    shadowBlur: 10,
                    shadowColor: "black",
                    shadowOpacity: 0.4
                };
            case "block":
                return {
                    shadowX: 10,
                    shadowY: 10,
                    shadowBlur: 0,
                    shadowColor: "black",
                    shadowOpacity: 0.5
                };
        }
    }

    handleSetPreset = type => {
        let model = this.getPresetModel(type);
        this.updateShadowModel(model);
        this.setState(model);
        this.setState({ label: this.getPresetLabel(model) });
    }

    render() {
        let { shadowX, shadowY, shadowBlur, shadowColor, shadowOpacity, label } = this.state;

        return (
            <PopupMenu label={label} dialogStyle showArrow>
                <PopupMenuPaddedContainer>
                    <FlexBox vertical left>
                        <Thumbnails>
                            <FlexBox vertical onClick={() => this.handleSetPreset("none")}>
                                <img src={getStaticUrl("/images/ui/shadows/noshadow.png")} />
                                <label>None</label>
                            </FlexBox>
                            <FlexBox vertical onClick={() => this.handleSetPreset("drop")}>
                                <img src={getStaticUrl("/images/ui/shadows/dropshadow.png")} />
                                <label>Drop</label>
                            </FlexBox>
                            <FlexBox vertical onClick={() => this.handleSetPreset("soft")}>
                                <img src={getStaticUrl("/images/ui/shadows/softshadow.png")} />
                                <label>Soft</label>
                            </FlexBox>
                            <FlexBox vertical onClick={() => this.handleSetPreset("block")}>
                                <img src={getStaticUrl("/images/ui/shadows/blockshadow.png")} />
                                <label>Block</label>
                            </FlexBox>
                        </Thumbnails>
                        <Gap20 />
                        <Divider />
                        <Gap20 />
                        <FlexBox left top>
                            <div>
                                <FlexBox left middle>
                                    <label style={{ width: 20 }}>X</label>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        value={_.defaultTo(shadowX, 0)}
                                        onChange={event => this.setShadowProps({
                                            shadowX: parseInt(event.target.value),
                                        })}
                                        inputProps={{
                                            min: -50,
                                            max: 50
                                        }}
                                    />
                                </FlexBox>
                                <Gap10 />
                                <FlexBox left middle>
                                    <label style={{ width: 20 }}>Y</label>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        value={_.defaultTo(shadowY, 0)}
                                        onChange={event => this.setShadowProps({
                                            shadowY: parseInt(event.target.value),
                                        })}
                                        inputProps={{
                                            min: -50,
                                            max: 50
                                        }}
                                    />
                                </FlexBox>
                            </div>
                            <Gap30 />
                            <div>
                                <FlexBox left middle>
                                    <label style={{ width: 60 }}>Blur</label>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        value={_.defaultTo(shadowBlur, 0)}
                                        onChange={event => this.setShadowProps({
                                            shadowBlur: parseInt(event.target.value),
                                        })}
                                        inputProps={{
                                            min: 0,
                                            max: 50
                                        }}
                                    />
                                </FlexBox>
                                <Gap10 />
                                <FlexBox>
                                    <label style={{ width: 60 }}>Opacity</label>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        value={_.defaultTo(shadowOpacity, 0) * 100}
                                        onChange={event => this.setShadowProps({
                                            shadowOpacity: parseInt(event.target.value) / 100,
                                        })}
                                        inputProps={{
                                            min: 0,
                                            max: 100
                                        }}
                                    />
                                </FlexBox>
                            </div>
                        </FlexBox>
                    </FlexBox>
                </PopupMenuPaddedContainer>
            </PopupMenu>

        );
    }
}

