import React, { Component, useRef, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Tooltip } from "@material-ui/core";
import { Spin as Hamburger } from "hamburger-react";
import {
    ArrowDropDown as ArrowDropDownIcon,
    Create as CreateIcon,
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
    ViewModule as ViewModuleIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
    ChromeReaderMode as ChromeReaderModeIcon,
    ModeCommentOutlined as ModeCommentIcon,
    PhoneAndroid,
    Person as PersonIcon,
} from "@material-ui/icons";

import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap10, Gap15, Gap20 } from "legacy-js/react/components/Gap";

import PresentationActionsMenu from "./PresentationActionsMenu";
import { usePrev } from "legacy-js/react/hooks/usePrev";

import { isFullsceenAllowed, isFullscreen, toggleFullscreen } from "../helpers/fullscreen";
import { stopPropagation } from "../helpers/stopPropagation";

const expandDuration = 400;
const fadeDuration = 400;
const MAX_TEXT_CONTAINER_WIDTH = 260;
const MAX_TEXT_WIDTH = 230; // manually adjusted by trial and error

export const ControlStates = {
    CONTRACTED: "contracted",
    HIDDEN: "hidden",
    EXPANDED: "expanded"
};

export const ExternalTransitions = {
    MOUSE_IN_BOUNDS: "mouseInBounds",
    MOUSE_OUT_OF_BOUNDS: "mouseOutOfBounds",
    MOUSE_CLICK_ON_CANVAS: "mouseClickOnCanvas",
    TOGGLE_EXPANDED: "toggleExpanded"
};

const Transitions = {
    NONE: "none",
    EXPANDING: {
        START: "expandingStart",
        END: "expandingEnd",
    },
    CONTRACTING: {
        START: "contractingStart",
        END: "contractingEnd",
        HIDING: {
            START: "contractingHidingStart",
            END: "contractingHidingEnd"
        }
    },
    HIDING: {
        START: "hidingStart",
        END: "hidingEnd"
    },
    SHOWING: {
        START: "showingStart",
        END: "showingEnd"
    }
};

const TransitionDelays = {
    MOUSE_OUT_OF_BOUNDS: 500,
    MOUSE_IN_BOUNDS: 500,
    CONTRACTING: expandDuration,
    SHOWING: fadeDuration,
    HIDING: fadeDuration - 100,
};

const isHiding = transition=> [
    Transitions.CONTRACTING.HIDING.START,
    Transitions.CONTRACTING.HIDING.END,
    Transitions.HIDING.START,
    Transitions.HIDING.END
].includes(transition);

const isContracting = transition=> [
    Transitions.CONTRACTING.START,
    Transitions.CONTRACTING.HIDING.START,
    Transitions.CONTRACTING.HIDING.END
].includes(transition);

const noFade = keyframes` from {opacity: 0;} to {opacity: 0;}`;

const fadeIn = keyframes` from {opacity: 0;} to {opacity: 1;}`;

const fadeOut = keyframes` from {opacity: 1;} to {opacity: 0;}`;

const fadeControlsContainer = ({ controlTransition }) => {
    switch (controlTransition) {
        case Transitions.CONTRACTING.END:
            return fadeIn;
        case Transitions.SHOWING.START:
            return fadeIn;
        case Transitions.CONTRACTING.HIDING.START:
            return noFade;
    }
};

const bottomBarVisibility = ({ controlState, controlTransition }) => {
    if (controlTransition === Transitions.HIDING.START) {
        return "visible";
    } else if (controlState === ControlStates.HIDDEN) {
        return "hidden";
    } else {
        return "visible";
    }
};

const bottomBarWidth = ({ controlState, controlTransition, actionsMenuIsOpen }) => {
    return (controlState === ControlStates.EXPANDED && !isContracting(controlTransition)) ||
    actionsMenuIsOpen ? "1200px" : "44px";
};

const fadeBottomBar = ({ controlTransition }) => {
    switch (controlTransition) {
        case Transitions.SHOWING.START:
            return fadeIn;
        case Transitions.HIDING.START:
            return fadeOut;
        case Transitions.CONTRACTING.HIDING.END:
            return fadeOut;
    }
};

const Divider = styled.div`
    width: 1px;
    min-width: 1px;
    height: 24px;
    background: #ccc;
`;

const Spacer = styled.div`
    width: 20px;
    min-width: 20px;
    height: 20px;

    @media (max-width: 1100px) {
        width: 20px;
        min-width: 20px;
    }
`;

const ShrinkZone = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
`;

const ControlContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 100px;
    cursor: pointer;
`;

const HamburgerContainer = styled.div`
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    margin: -2px;
`;

const BottomBarAnchor = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
`;

const BottomBarContainer = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 44px;
    padding: 0;

    animation: ${fadeBottomBar} ease-out ${fadeDuration}ms;
    visibility: ${props => bottomBarVisibility(props)};
    
    @media (max-width: 800px) {
        .hide-800 {
            display: none;
`;

const BottomBar = styled.div.attrs(({
    controlState,
    controlTransition,
    actionsMenuIsOpen
}) => ({
    style: {
        maxWidth: bottomBarWidth({ controlState, controlTransition, actionsMenuIsOpen }),
        transformOrigin: "bottom right",
    }
}))`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 44px;
    background: rgba(0, 0, 0, 0.7);
    padding: 0;
    border-radius: 100px;
    transition: max-width ease-out ${expandDuration}ms, 
                visibility ${expandDuration}ms linear;

    > ${HamburgerContainer} {
        & div {
            visibility: ${props => bottomBarVisibility(props)}
        }
    }

    > ${ControlContainer} {
        animation: ${fadeControlsContainer} ease-out ${fadeDuration}ms;
    }

    @media (max-width: 800px) {
        .hide-800 {
            display: none;
`;

const LongTextContainer = styled.div`
    max-width: ${MAX_TEXT_CONTAINER_WIDTH}px;
    width: ${props => props.isLongText ? `${MAX_TEXT_CONTAINER_WIDTH}px` : "100%"};
`;

const StyledLongText = styled.div`
    color: #ccc;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 44px;

    overflow: hidden;
    text-overflow: ellipsis;
    width: ${props=> props.isScrolling ? `${MAX_TEXT_CONTAINER_WIDTH}px` : `100%` };
    vertical-align: middle;
    
    span {
        line-height: 44px;
        vertical-align: middle;
        display: inline-block;
        transition: ${props => props.disableTransition ? `none` : `transform ${props.scrollDuration}s linear` };
        transform: ${props => (props.isHover && props.isLongText) ? `translateX(-${props.scrollPercent}%)` : `translateX(0%)`};
        overflow:${props=> props.isScrolling ? `visible` : `hidden` }; ;
        text-overflow: ellipsis;
        width: ${props=> props.isScrolling ? `${MAX_TEXT_CONTAINER_WIDTH}px` : `100%` };
        max-width: ${props=> props.isScrolling ? `${MAX_TEXT_CONTAINER_WIDTH}px` : `100%`};
    }
`;

const CreatorContainer = styled.div`
    display: flex;
    align-items: center;
    height: 30px;

    > img {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        float: left;
        margin-right: 10px;
    }

    > svg {
        color: #ccc;
        font-size: 30px;
        margin-right: 10px;
    }

    > span {
        color: #ccc;
        font-size: 14px;
        letter-spacing: 0.5px;
        white-space: nowrap;
    }
`;

const SlideIndex = styled.div`
    user-select: none;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    margin-left: 8px;
    margin-right: 8px;
    white-space: nowrap;
`;

const ButtonWithLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e1e1e1;
    cursor: pointer;
    height: 44px;
    width: 44px;
    border-radius: 100px;

    label {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &:hover {
        color: #11a9e2;
    }
`;

const OptionsButton = styled(ButtonWithLabel)`
    width: 72px;
    margin-right: 0;
`;

function getStyledIconButton(Icon, style = {}) {
    return styled(Icon).attrs(() => ({ style }))`
        color: #ccc;
        cursor: pointer;

        &:hover {
            color: #11a9e2;
        }
    `;
}

const StyledStopDrawingIcon = styled.svg`
    fill: #ccc;
    cursor: pointer;

    &:hover {
        fill: #11a9e2;
    }
`;

class StopDrawingIcon extends Component {
    render() {
        return (
            <StyledStopDrawingIcon
                width="24"
                height="24"
                viewBox="0 0 24 24"
                {...this.props}
            >
                <path
                    d="M12.126 8.125L14.063 6.188L17.81 9.935L15.873 11.873L12.126 8.125ZM20.71 5.63L18.37 3.29C18.1826 3.10375 17.9292 2.99921 17.665 2.99921C17.4008 2.99921 17.1474 3.10375 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7C20.8844 6.81454 20.9815 6.56956 20.9815 6.315C20.9815 6.06044 20.8844 5.81546 20.71 5.63ZM2 5L8.63 11.63L3 17.25V21H6.75L12.38 15.38L18 21L20 19L4 3L2 5Z"
                />
            </StyledStopDrawingIcon>
        );
    }
}

const PrevSlideButton = getStyledIconButton(NavigateBeforeIcon);
const NextSlideButton = getStyledIconButton(NavigateNextIcon);
const ThumbnailsButton = getStyledIconButton(ViewModuleIcon);
const StartDrawingButton = getStyledIconButton(CreateIcon);

// TODO: Can change this to `getStyledIconButton(EditOffIcon)` after updating to new MaterialUI version (and remove existing components)
const StopDrawingButton = getStyledIconButton(StopDrawingIcon);

function calcTextWidth(text) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = "14px sourcesanspro";
    ctx.imageSmoothingEnabled = false;
    const textMetrics = ctx.measureText(text);
    return textMetrics.width;
}

function useScrollLongText(props) {
    const {
        ref,
        controlTransition,
        isHover,
    } = props;

    const [isScrolling, setIsScrolling] = useState(false);
    const [disableTransition, setDisableTransition] = useState(false);

    useEffect(()=>{
        const handleTransitionStart = ()=>{
            if (isHover) {
                setIsScrolling(true);
            }
        };
        const handleTransitionEnd = ()=>{
            if (!isHover) {
                setIsScrolling(false);
            }
        };

        if (controlTransition === Transitions.CONTRACTING.END ||
                controlTransition === Transitions.CONTRACTING.HIDING.END) {
            setDisableTransition(true);
            setIsScrolling(false);
        } else {
            setDisableTransition(false);
            ref.current.addEventListener("transitionstart", handleTransitionStart);
            ref.current.addEventListener("transitionend", handleTransitionEnd);
        }

        return () =>{
            ref.current.removeEventListener("transitionstart", handleTransitionStart);
            ref.current.removeEventListener("transitionend", handleTransitionEnd);
        };
    },
    [isHover, controlTransition]);
    return { isScrolling, disableTransition };
}

function ScrollingTextContainer(props) {
    {
        const {
            text,
            controlTransition
        } = props;
        const ref = useRef(null);
        const [isHover, setIsHover] = useState(false);

        const textWidth = calcTextWidth(text);
        const isLongText = textWidth > MAX_TEXT_WIDTH;

        const scrollPercent = Math.abs(Math.ceil((textWidth - MAX_TEXT_WIDTH) / MAX_TEXT_WIDTH * 100));
        const scrollDuration = 2 * scrollPercent / 100;

        const {
            isScrolling,
            disableTransition
        } = useScrollLongText(
            {
                ref,
                controlTransition,
                isHover,
            }
        );

        return (<LongTextContainer
            onMouseOver={()=>{
                setIsHover(true);
            }}
            onMouseOut={()=> {
                setIsHover(false);
            }}
            isLongText={isLongText}
            maxWidth={MAX_TEXT_CONTAINER_WIDTH}
        >
            <StyledLongText
                data-testing-info="deck-presentation-name"
                isHover={isHover}
                isLongText={isLongText}
                isScrolling={isScrolling}
                scrollPercent = {scrollPercent}
                scrollDuration = {scrollDuration}
                disableTransition = {disableTransition}
                maxWidth = {MAX_TEXT_CONTAINER_WIDTH}
                ref={ref}
            >
                <span>{text}</span>
            </StyledLongText>
        </LongTextContainer>);
    }
}

function ThumbnailsControl(props) {
    const {
        visible,
        onClick,
    } = props;

    return (
        <>
            <Gap15/>
            <ControlContainer
                visible={visible}
                onClick={onClick}>
                <ThumbnailsButton/>
            </ControlContainer>
        </>);
}

function FullscreenControl(props) {
    const {
        controlState,
        visible,
        onClick,
        inFullscreen,
    } = props;
    return (
        <>
            <Gap15/>
            <ControlContainer
                visible={visible}
                onClick={onClick}>
                <ButtonWithLabel>
                    {
                        inFullscreen
                            ? <FullscreenExitIcon/>
                            : <FullscreenIcon/>
                    }
                </ButtonWithLabel>
            </ControlContainer>
        </>);
}

function StopDrawingControl(props) {
    const {
        visible,
        onClick,
    } = props;

    return (
        <>
            <Gap15/>
            <ControlContainer
                visible={visible}
                onClick={onClick}>
                <StopDrawingButton/>
            </ControlContainer>
        </>);
}

function PlayerControls(props) {
    const {
        visible,
        registerUserInteraction,
        showThumbnailsPane,
        advanceToSlide,
        currentSlideIndex,
        isPresenterAvailable,
        togglePresenter,
        isDrawing,
        toggleDrawing,
        hasViewerComments,
        toggleComments,
        toggleNotes,
        isPlayerSettingsPreview,
        actionsMenuIsOpen,
        onToggleActionsMenu,
        onControlRemotely,
        slidesCount = 1,
        isRemoteControlLeader,
        canControlRemotely,
        showTitle,
        showAuthor,
        presentationName,
        creator,
        trackSlideNavigation,
        controlState,
        setControlState,
        externalTransition
    } = props;

    const nextTransitionRef = useRef(null);
    const moreButtonRef = useRef();

    const allowFullscreenOption = isFullsceenAllowed();
    const inFullscreen = isFullscreen();

    // Close tooltips if the controls are collapsed or hidden
    const [activeTooltip, setActiveTooltip] = useState(null);
    const handleTooltipClose = name => {
        if (name === activeTooltip) {
            setActiveTooltip(null);
        }
    };
    const [expanded, setExpanded] = useState(false);
    const prevVisible = usePrev(visible);
    const prevExpanded = usePrev(expanded);

    if (!!activeTooltip && ((prevVisible && !visible) || (prevExpanded && !expanded))) {
        setActiveTooltip(null);
    }

    const [controlTransition, setControlTransition] = useState(Transitions.NONE);

    const bottomBarVisible = (visible && (actionsMenuIsOpen ||
            (controlState !== ControlStates.HIDDEN || controlTransition === Transitions.HIDING.START)));
    const hamburgerVisible = visible || actionsMenuIsOpen;
    const controlsVisible = visible && !isContracting(controlTransition) &&
        (controlState === ControlStates.CONTRACTED || controlTransition === Transitions.HIDING.START);
    const hamburgerToggled = actionsMenuIsOpen ||
        (controlState === ControlStates.EXPANDED && !isContracting(controlTransition));

    // Apply external transition signals
    useEffect(() => {
        applyTransition(externalTransition);
    }, [externalTransition]);

    useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(nextTransitionRef.current);
    }, []);

    const scheduleTransition = (transition, delay = 1) => {
        nextTransitionRef.current = setTimeout(() => {
            applyTransition(transition);
        }, delay);
    };

    const applyTransition = transition => {
        clearTimeout(nextTransitionRef.current);

        switch (transition) {
            case ExternalTransitions.MOUSE_IN_BOUNDS:
                if (isHiding(controlTransition)) {
                    scheduleTransition(Transitions.SHOWING.START);
                } else {
                    scheduleTransition(Transitions.SHOWING.START, TransitionDelays.MOUSE_IN_BOUNDS);
                }
                break;
            case ExternalTransitions.MOUSE_OUT_OF_BOUNDS:
                if (controlState === ControlStates.HIDDEN) {
                    setControlTransition(Transitions.NONE);
                } else {
                    scheduleTransition(Transitions.HIDING.START, TransitionDelays.MOUSE_OUT_OF_BOUNDS);
                }
                break;

            case ExternalTransitions.TOGGLE_EXPANDED:
                if (controlState === ControlStates.EXPANDED) {
                    setControlTransition(Transitions.CONTRACTING.START);
                    scheduleTransition(Transitions.CONTRACTING.START);
                } else if (controlState === ControlStates.CONTRACTED) {
                    scheduleTransition(Transitions.EXPANDING.START);
                }
                break;

            case ExternalTransitions.MOUSE_CLICK_ON_CANVAS:
                if (controlState === ControlStates.EXPANDED) {
                    setControlTransition(Transitions.CONTRACTING.START);
                    scheduleTransition(Transitions.CONTRACTING.START);
                } else if (controlState === ControlStates.CONTRACTED) {
                    scheduleTransition(Transitions.HIDING.START, TransitionDelays.MOUSE_OUT_OF_BOUNDS);
                }
                break;

            case Transitions.SHOWING.START:
                if (controlState === ControlStates.HIDDEN) {
                    setControlTransition(Transitions.SHOWING.START);
                    setControlState(ControlStates.CONTRACTED);
                    scheduleTransition(Transitions.NONE, TransitionDelays.SHOWING);
                } else if (isContracting(controlTransition)) {
                    scheduleTransition(Transitions.CONTRACTING.END, TransitionDelays.SHOWING / 2);
                }
                break;

            case Transitions.EXPANDING.START:
                setControlTransition(Transitions.EXPANDING.START);
                scheduleTransition(Transitions.EXPANDING.END);
                break;

            case Transitions.EXPANDING.END:
                setControlTransition(Transitions.NONE);
                setControlState(ControlStates.EXPANDED);
                break;

            case Transitions.CONTRACTING.START:
                scheduleTransition(Transitions.CONTRACTING.END, TransitionDelays.CONTRACTING);
                break;

            case Transitions.CONTRACTING.END:
                setControlTransition(Transitions.CONTRACTING.END);
                setControlState(ControlStates.CONTRACTED);
                break;

            case Transitions.HIDING.START:
                if (controlState === ControlStates.CONTRACTED) {
                    setControlTransition(Transitions.HIDING.START);
                    setControlState(ControlStates.HIDDEN);
                    scheduleTransition(Transitions.HIDING.END, TransitionDelays.HIDING);
                } else if (controlState === ControlStates.EXPANDED) {
                    setControlTransition(Transitions.CONTRACTING.HIDING.START);
                    scheduleTransition(Transitions.CONTRACTING.HIDING.START, TransitionDelays.CONTRACTING);
                }
                break;

            case Transitions.CONTRACTING.HIDING.START:
                setControlTransition(Transitions.CONTRACTING.HIDING.END);
                scheduleTransition(Transitions.CONTRACTING.HIDING.END, TransitionDelays.HIDING);
                break;

            case Transitions.CONTRACTING.HIDING.END:
                setControlState(ControlStates.HIDDEN);
                setControlTransition(Transitions.NONE);
                break;

            case Transitions.HIDING.END:
                setControlTransition(Transitions.NONE);
                break;

            case Transitions.NONE:
                setControlTransition(Transitions.NONE);
                break;
        }
    };

    return (
        <BottomBarAnchor>
            {visible &&
                <BottomBarContainer
                    controlState={controlState}
                    controlTransition={controlTransition}
                >
                    {bottomBarVisible &&
                    <BottomBar
                        controlState={controlState}
                        controlTransition={controlTransition}
                        visible={bottomBarVisible}
                        prevVisible={prevVisible}
                        prevExpanded={prevExpanded}
                        expanded={expanded || actionsMenuIsOpen}
                        actionsMenuIsOpen={actionsMenuIsOpen}
                        onClick={event => {
                            registerUserInteraction?.();
                            stopPropagation(event);
                        }}
                    >
                        <>
                            {hamburgerVisible &&
                                <HamburgerContainer
                                    visible={hamburgerVisible}
                                >
                                    <Hamburger
                                        toggled={hamburgerToggled}
                                        toggle={() =>{
                                            isDrawing && toggleDrawing?.();
                                            applyTransition(ExternalTransitions.TOGGLE_EXPANDED, controlState);
                                        }}
                                        direction="left"
                                        color="#ccc"
                                        size={18}
                                        rounded
                                    />
                                </HamburgerContainer>}
                            {allowFullscreenOption &&
                                !isDrawing &&
                                <>
                                    {controlsVisible &&
                                        <FullscreenControl
                                            onClick={() => {
                                                !isPlayerSettingsPreview && toggleFullscreen?.();
                                            }}
                                            inFullscreen={inFullscreen}
                                        />
                                    }
                                </>
                            }
                            {controlsVisible &&
                                !isDrawing &&
                                <ThumbnailsControl
                                    onClick={() => {
                                        registerUserInteraction?.();
                                        showThumbnailsPane?.();
                                    }}
                                />}
                            {controlsVisible &&
                                isDrawing &&
                                <StopDrawingControl
                                    onClick={() => {
                                        registerUserInteraction?.();
                                        toggleDrawing?.();
                                    }}/>}
                        </>

                        <ShrinkZone>
                            <Gap20/>
                            {showTitle &&
                                <ScrollingTextContainer
                                    data-testing-info="deck-presentation-name"
                                    text={presentationName}
                                    controlTransition={controlTransition}
                                />

                            }

                            {showTitle &&
                                showAuthor &&
                                <>
                                    <Gap20/>
                                    <Divider/>
                                    <Gap20/>
                                </>
                            }

                            {showAuthor &&
                                creator &&
                                <CreatorContainer>
                                    {creator.photoURL && <img src={creator.photoURL}></img>}
                                    {!creator.photoURL && <PersonIcon/>}
                                    <ScrollingTextContainer
                                        text={creator.displayName}
                                        controlTransition = {controlTransition}
                                    />
                                </CreatorContainer>
                            }

                            {(showTitle ||
                                    showAuthor) &&
                                    <Spacer/>
                            }

                            {canControlRemotely &&
                                <>
                                    <Tooltip
                                        open={activeTooltip === "control-from-phone"}
                                        onOpen={() => setActiveTooltip("control-from-phone")}
                                        onClose={() => handleTooltipClose("control-from-phone")}
                                        className="hide-800"
                                        title="Control from Phone"
                                        arrow
                                        placement="top"
                                    >
                                        <ButtonWithLabel
                                            onClick={() => {
                                                registerUserInteraction?.();
                                                onControlRemotely?.();
                                            }}
                                        >
                                            <PhoneAndroid style={{ fontSize: 26 }}/>
                                        </ButtonWithLabel>
                                    </Tooltip>
                                    <Gap20 className="hide-800"/>
                                </>
                            }

                            <FlexBox middle>
                                <PrevSlideButton onClick={() => {
                                    registerUserInteraction?.(false);
                                    advanceToSlide(-1);
                                    trackSlideNavigation({ method: "buttons", deviceType: "desktop" });
                                }}/>
                                <SlideIndex>{currentSlideIndex + 1} / {slidesCount}</SlideIndex>
                                <NextSlideButton onClick={() => {
                                    registerUserInteraction?.(false);
                                    advanceToSlide(1);
                                    trackSlideNavigation({ method: "buttons", deviceType: "desktop" });
                                }}/>
                            </FlexBox>

                            <Gap20 className="hide-800"/>
                            <Tooltip
                                open={activeTooltip === "drawing"}
                                onOpen={() => setActiveTooltip("drawing")}
                                onClose={() => handleTooltipClose("drawing")}
                                className="hide-800"
                                title={isDrawing ? "Stop Drawing" : "Start Drawing"}
                                arrow
                                placement="top"
                            >
                                {isDrawing
                                    ? <StopDrawingButton
                                        onClick={() => {
                                            registerUserInteraction?.();
                                            toggleDrawing?.();
                                        }}/>
                                    : <StartDrawingButton onClick={() => {
                                        registerUserInteraction?.();
                                        handleTooltipClose("drawing");
                                        setTimeout(() => {
                                            toggleDrawing?.();
                                        }, TransitionDelays.CONTRACTING - 100);
                                        applyTransition(ExternalTransitions.TOGGLE_EXPANDED, controlState);
                                    }}/>
                                }

                            </Tooltip>

                            <Gap20 className="hide-800"/>

                            <Tooltip
                                open={activeTooltip === "slide-grid"}
                                onOpen={() => setActiveTooltip("slide-grid")}
                                onClose={() => handleTooltipClose("slide-grid")}
                                className="hide-800"
                                title="Slide Grid"
                                arrow
                                placement="top"
                            >
                                <ThumbnailsButton onClick={() => {
                                    registerUserInteraction?.();
                                    showThumbnailsPane?.();
                                }}/>
                            </Tooltip>

                            {allowFullscreenOption &&
                                <>
                                    <Gap20 className="hide-800"/>
                                    <Tooltip
                                        open={activeTooltip === "fullscreen"}
                                        onOpen={() => setActiveTooltip("fullscreen")}
                                        onClose={() => handleTooltipClose("fullscreen")}
                                        className="hide-800"
                                        title={inFullscreen ? "Exit Fullscreen" : "Fullscreen"}
                                        arrow
                                        placement="top"
                                    >
                                        <ButtonWithLabel onClick={() => {
                                            registerUserInteraction?.();
                                            !isPlayerSettingsPreview && toggleFullscreen?.();
                                        }}>
                                            {
                                                inFullscreen
                                                    ? <FullscreenExitIcon/>
                                                    : <FullscreenIcon/>
                                            }
                                        </ButtonWithLabel>
                                    </Tooltip>
                                </>
                            }
                            {isPresenterAvailable &&
                                !isRemoteControlLeader &&
                                <>
                                    <Gap20 className="hide-800"/>
                                    <Tooltip
                                        open={activeTooltip === "presenter"}
                                        onOpen={() => setActiveTooltip("presenter")}
                                        onClose={() => handleTooltipClose("presenter")}
                                        className="hide-800"
                                        title="Presenter"
                                        arrow
                                        placement="top"
                                    >
                                        <ButtonWithLabel onClick={() => {
                                            registerUserInteraction?.();
                                            togglePresenter?.();
                                        }}>
                                            <ChromeReaderModeIcon style={{ fontSize: 22, marginBottom: 2 }}/>
                                        </ButtonWithLabel>
                                    </Tooltip>
                                </>
                            }
                            {isRemoteControlLeader &&
                                <>
                                    <Gap20 className="hide-800"/>
                                    <Tooltip
                                        open={activeTooltip === "show-notes"}
                                        onOpen={() => setActiveTooltip("show-notes")}
                                        onClose={() => handleTooltipClose("show-notes")}
                                        className="hide-800"
                                        title="Show Notes"
                                        arrow
                                        placement="top"
                                    >
                                        <ButtonWithLabel onClick={() => {
                                            registerUserInteraction?.();
                                            toggleNotes?.();
                                        }}>
                                            <ChromeReaderModeIcon style={{ fontSize: 22, marginBottom: 2 }}/>
                                        </ButtonWithLabel>
                                    </Tooltip>
                                </>
                            }
                            {hasViewerComments &&
                                <>
                                    <Gap20 className="hide-800"/>
                                    <Tooltip
                                        open={activeTooltip === "comments"}
                                        onOpen={() => setActiveTooltip("comments")}
                                        onClose={() => handleTooltipClose("comments")}
                                        className="hide-800"
                                        title="Comments"
                                        arrow
                                        placement="top"
                                    >
                                        <ButtonWithLabel onClick={() => {
                                            registerUserInteraction?.();
                                            toggleComments?.();
                                        }}>
                                            <ModeCommentIcon style={{ fontSize: 22, marginTop: 2 }}/>
                                        </ButtonWithLabel>
                                    </Tooltip>
                                </>
                            }

                            {/*<Gap20/>*/}
                            <Spacer/>
                            <OptionsButton style={{ marginRight: 0 }}
                                ref={moreButtonRef}
                                onClick={() => (onToggleActionsMenu(true))}
                            >
                                <label>Options</label>
                                {/*<Gap10/>*/}
                                <ArrowDropDownIcon/>
                            </OptionsButton>

                            <PresentationActionsMenu
                                {...props}
                                open={actionsMenuIsOpen}
                                onClose={() => onToggleActionsMenu(false)}
                                anchorEl={moreButtonRef.current}
                            />
                        </ShrinkZone>
                    </BottomBar>}
                </BottomBarContainer>}
        </BottomBarAnchor>
    );
}

export default PlayerControls;
