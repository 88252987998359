import React, { Component } from "react";
import styled from "styled-components";

import { ArrowRight } from "@material-ui/icons";

import { themeColors } from "legacy-js/react/sharedStyles";
import { Gap10 } from "legacy-js/react/components/Gap";

import { ThumbnailGrid } from "./ThumbnailGrid";

const SearchPreview = styled.div`
  background: white;
  padding: 10px 20px;
  margin: 0px 0px 20px;
  overflow: hidden;
`;

const SearchPreviewTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #111;
`;

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const SearchPreviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  p {
    font-size: 13px;
    color: ${themeColors.ui_blue};
    text-transform: uppercase;
    font-weight: bolder;
    
    &:hover {
      cursor: pointer;
    }
  }

  .expander {
    margin-top: 2px;
    cursor: pointer;
    
    .MuiSvgIcon-root {
      font-size: 28px;
      margin: 7px -5px;
      transition: transform 300ms;
    }
    
    &.expanded {
      .MuiSvgIcon-root {
        transform: rotate(90deg);
      }
    }
  }
`;

export class SlideSearchPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: !!props.expanded
        };
    }

    get allowCollapse() {
        const { allowCollapse = true } = this.props;

        return allowCollapse;
    }

    componentDidUpdate(prevProps) {
        const { expanded } = this.props;

        if (prevProps.expanded !== expanded) {
            this.setState({ expanded });
        }
    }

    handleToggleExpand = () => {
        if (this.allowCollapse) {
            this.setState(({ expanded }) => ({ expanded: !expanded }));
        }
    }

    render() {
        const {
            thumbnail,
            items,
            title = "",
            onSelected,
            onSelectViewAll,
            showThemeThumbnails,
            selectViewAllTitle = "View All Results",
            showUserTheme
        } = this.props;

        const { expanded } = this.state;

        if (items.length === 0) {
            return null;
        }

        return (<SearchPreview>
            <SearchPreviewHeader>
                <StyledHeader>
                    {this.allowCollapse &&
                        <div
                            className={`expander ${expanded ? "expanded" : "collapsed"}`}
                            onClick={this.handleToggleExpand}
                        >
                            <ArrowRight />
                        </div>
                    }
                    {title && <SearchPreviewTitle onClick={this.handleToggleExpand}>{title}</SearchPreviewTitle>}
                </StyledHeader>
                {onSelectViewAll && <p onClick={onSelectViewAll}>{selectViewAllTitle}</p>}
            </SearchPreviewHeader>
            {expanded &&
                <>
                    <Gap10 />
                    <ThumbnailGrid
                        forcedColumnCount={4}
                        columns={3}
                        colGap={30}
                        rowGap={20}
                        items={items}
                        thumbnailClass={thumbnail}
                        onItemDoubleClick={onSelected}
                        onItemSelected={onSelected}
                        showThemeThumbnails={showThemeThumbnails || showUserTheme}
                    />
                    <Gap10 />
                </>
            }
        </SearchPreview>);
    }
}
