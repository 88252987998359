import React from "react";
import styled from "styled-components";

const StyledDesignerBotIcon = styled.svg`
    transform: scale(1) !important;
    position: relative;
`;

function DesignerBotIcon({ style = {} }) {
    return (
        <StyledDesignerBotIcon
            width="24"
            height="24"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={{ transform: "scale(.85)",
                ...style }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8334 4.82669C11.5692 4.50521 12.0834 3.77101 12.0834 2.91671C12.0834 1.76611 11.1506 0.833374 10 0.833374C8.84945 0.833374 7.91671 1.76611 7.91671 2.91671C7.91671 3.77101 8.43091 4.50521 9.16671 4.82669V7.50004H5.33337C4.2288 7.50004 3.33337 8.39547 3.33337 9.50004V10.4167C1.95266 10.4167 0.833374 11.536 0.833374 12.9167C0.833374 14.2974 1.95266 15.4167 3.33337 15.4167V16.3334C3.33337 17.4379 4.2288 18.3334 5.33337 18.3334H14.6667C15.7713 18.3334 16.6667 17.4379 16.6667 16.3334V15.4167C18.0474 15.4167 19.1667 14.2974 19.1667 12.9167C19.1667 11.536 18.0474 10.4167 16.6667 10.4167V9.50004C16.6667 8.39547 15.7713 7.50004 14.6667 7.50004H10.8334V4.82669ZM14.5834 12.9167C14.5834 13.8372 13.8372 14.5834 12.9167 14.5834C11.9962 14.5834 11.25 13.8372 11.25 12.9167C11.25 11.9962 11.9962 11.25 12.9167 11.25C13.8372 11.25 14.5834 11.9962 14.5834 12.9167ZM7.08337 14.5834C8.00385 14.5834 8.75004 13.8372 8.75004 12.9167C8.75004 11.9962 8.00385 11.25 7.08337 11.25C6.1629 11.25 5.41671 11.9962 5.41671 12.9167C5.41671 13.8372 6.1629 14.5834 7.08337 14.5834Z"
            />
        </StyledDesignerBotIcon>
    );
}

export default DesignerBotIcon;
