import { controls } from "legacy-js/editor/ui";
import { $ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import { ShapeType } from "legacy-common/constants";
import renderReactRoot from "legacy-js/react/renderReactRoot";

import { ElementOptionsMenu } from "../../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../../CollectionElementEditor";
import { ImageFrameMenu } from "../../EditorComponents/ImageFrameMenu";
import { CreateMediaMenu } from "../pictureEditor";

const PhotoTextListSelection = CollectionElementSelection.extend({

    renderControls: function() {
        this.createAddItemButton();

        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "filter_frames",
            showArrow: false,
            customMenuClass: "frame-popup",
            menuContents: closeMenu => {
                let $menu = $.div();
                renderReactRoot(ImageFrameMenu, {
                    onSelect: frame => {
                        for (let item of this.element.itemElements) {
                            item.model.frameType = frame;
                            if (frame == ShapeType.NONE) {
                                item.media?.content?.assetElement?.resetMediaTransform && item.media.content.assetElement.resetMediaTransform();
                            }
                        }
                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    }
                }, $menu[0]);
                return $menu;
            },
            transitionModel: false
        });
    },

    renderResizer: function() {
        this.$trayResizer = this.$widgets.addEl($.div("ui_widget"));
        this.$trayResizer.append($.div("tray_resizer vertical"));

        let constrainDrag;

        // calculate the maxWidth by directly asking the trayElement - if we ask the tray, it will use the current trayWidth
        let maxTrayWidth = this.element.canvas.layouter.canvasElement.maxTrayWidth;
        let minTrayWidth = this.element.canvas.layouter.canvasElement.minTrayWidth;
        let maxTrayHeight = this.element.canvas.layouter.canvasElement.maxTrayHeight;
        let minTrayHeight = this.element.canvas.layouter.canvasElement.minTrayHeight;

        let dragHandleOffset = 10;
        let direction;

        this.$trayResizer.makeDraggable({
            axis: "y",
            constrainDrag: constrainDrag,
            start: event => {
                app.mainView.editorView.selectionLayer.hideWidgets(this.$trayResizer);
            },
            drag: (event, position) => {
                if (this.element.canvas.layouter.isGenerating) {
                    return;
                }

                let y = position.elementPosition.y - this.element.bounds.top;

                this.element.model.mediaHeight = this.element.bounds.height - y;
                this.element.canvas.refreshCanvas();
            },
            stop: event => {
                event.stopPropagation();
                this.element.canvas.updateCanvasModel(false).then(() => {
                    // app.mainView.editorView.selectionLayer.showWidgets(this.$trayResizer);
                });
            }
        });
    },

    // _layout: function(bounds) {
    //     this.$trayResizer.top(0);
    // },

});

const PhotoTextListItemSelection = CollectionItemElementSelection.extend({
    showSelectionBox: true,

    getWidgetPosition() {
        // if (this.element.aspectRatio == "fit") {
        return "outer";
        // } else {
        //     return "inner";
        // }
    },

    renderControls() {
        CreateMediaMenu(this, this.element.media.content);

        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "filter_frames",
            showArrow: false,
            customMenuClass: "frame-popup",
            menuContents: closeMenu => {
                let $menu = $.div();
                renderReactRoot(ImageFrameMenu, {
                    onSelect: frame => {
                        this.element.model.frameType = frame;
                        if (frame == ShapeType.NONE) {
                            this.element.media?.content?.assetElement?.resetMediaTransform && this.element.media.content.assetElement.resetMediaTransform();
                        }
                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    }
                }, $menu[0]);
                return $menu;
            },
            transitionModel: false
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            showAuto: true,
            showBackgroundColors: true,
            showDecorationStyles: () => (true)
        });
    }
});

const PhotoTextListOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        // this.addControl({
        //     type: controls.MULTI_TOGGLE,
        //     label: "Media Aspect Ratio",
        //     options: [{
        //         value: "fill", label: "Fixed Size"
        //     }, {
        //         value: "fit", label: "Preserve Aspect Ratio"
        //     }],
        //     property: "aspectRatio",
        //     callback: value => {
        //         if (value == "fit") {
        //             // when switching to fit, reset the transform on all images
        //             for (let item of this.element.itemElements) {
        //                 if (item.content && item.content.assetElement && item.content.assetElement instanceof Picture) {
        //                     item.content.assetElement.resetMediaTransform();
        //                 }
        //             }
        //         }
        //     }
        // });

        this.addControl({
            type: controls.SLIDER,
            label: "Media Height",
            property: "mediaHeight",
            min: 100,
            max: this.element.calculatedProps.size.height - this.element.calculatedProps.maxTextHeight,
            step: 1
        });

        // this.addControl({
        //     type: controls.OPTIONS_LIST,
        //     property: "frame",
        //     label: "Frame",
        //     selectedValue: this.element.model.frame || "none",
        //     items: [{
        //         value: "none",
        //         label: "None",
        //         img: getStaticUrl("/images/frames/frame_none.png")
        //     }, {
        //         value: "light",
        //         label: "White",
        //         img: getStaticUrl("/images/frames/frame_white.png")
        //     }, {
        //         value: "dark",
        //         label: "Dark",
        //         img: getStaticUrl("/images/frames/frame_dark.png")
        //     }],
        //     callback: value => {
        //         this.element.model.frame = value;
        //         this.element.canvas.updateCanvasModel(false);
        //     }
        // });
    }
});

export const editors = {
    PhotoTextListSelection,
    PhotoTextListItemSelection,
    PhotoTextListOptionsMenu,
};
