import { BaseElement } from "../base/BaseElement";
import React from "reactn";
import { getSVGStyleProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { getValueOrDefault } from "js/core/utilities/extensions";
import * as geom from "js/core/utilities/geom";

export class GridComponent extends BaseElement {
    get exportAsImage() {
        return true;
    }

    get gridSize() {
        return getValueOrDefault(this.calculatedProps.options.gridSize, 10);
    }

    get majorTickSpan() {
        return getValueOrDefault(this.calculatedProps.options.majorTickSpan, 4);
    }

    get showMinorGridLines() {
        return getValueOrDefault(this.calculatedProps.options.showMinorGridLines, true);
    }

    _calcProps(props, options) {
        const { size } = props;
        return { size };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        const { size, styles } = props;

        const bounds = new geom.Rect(0, 0, size);

        const verticalGridLineCount = Math.floor(bounds.width / this.gridSize);
        const horizontalGridLineCount = Math.floor(bounds.height / this.gridSize);

        let verticalGridLines = [];
        for (let x = 0; x <= verticalGridLineCount; x++) {
            if (x % this.majorTickSpan == 0 || this.showMinorGridLines) {
                verticalGridLines.push(
                    <line
                        key={`${x}-vertical`}
                        x1={bounds.left + x * this.gridSize}
                        y1={bounds.bottom - horizontalGridLineCount * this.gridSize}
                        x2={bounds.left + x * this.gridSize}
                        y2={bounds.bottom}
                        style={getSVGStyleProps((x % this.majorTickSpan == 0) ? this.styles.majorGridLine : this.styles.minorGridLine)}
                    />
                );
            }
        }

        let horizontalGridLines = [];
        for (let y = 0; y <= horizontalGridLineCount; y++) {
            if (y % this.majorTickSpan == 0 || this.showMinorGridLines) {
                horizontalGridLines.push(
                    <line
                        key={`${y}-horizontal`}
                        x1={bounds.left}
                        y1={bounds.bottom - y * this.gridSize}
                        x2={bounds.right}
                        y2={bounds.bottom - y * this.gridSize}
                        style={getSVGStyleProps((y % this.majorTickSpan == 0) ? this.styles.majorGridLine : this.styles.minorGridLine)}
                    />
                );
            }
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                {verticalGridLines}
                {horizontalGridLines}
            </SVGGroup>
        );
    }
}

