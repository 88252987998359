import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    Divider
} from "@material-ui/core";
import Icon from "legacy-js/react/components/Icon";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

class DropDown extends React.Component {
    constructor() {
        super();
        this.state = { isOpen: false, items: [], selectedIndex: 0 };
    }

    componentDidMount() {
        const { selectedIndex, items } = this.props;

        this.setState({ items: [...items], selectedIndex: selectedIndex || 0 });
    }

    handleClose = () => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({
            isOpen: false
        });
    };

    handleToggle = isOpen => {
        this.setState({ isOpen: !isOpen });
    };

    handleSelect = async (items, selectedIndex, dropdownId) => {
        const { onSelect } = this.props;

        if (onSelect) {
            const selectedValue = items[selectedIndex]?.value || "";

            // Close the dropdown list while we wait
            //   for confirmation of our selection.
            this.setState({ isOpen: false });

            const success = await onSelect(selectedValue, dropdownId);
            if (!success) {
                return;
            }
        }

        //NOTE this is a hack for PermissionsList.js
        //TODO refactor CollaboratePane and SharePane to no longer use this.
        if (items[selectedIndex].value === "owner") {
            this.setState({ isOpen: false });
        } else {
            this.setState({ isOpen: false, selectedIndex: selectedIndex });
        }
    };

    render() {
        const { classes, dropdownId, variant, color, customClass, aboveAll = false, dataTest = undefined } = this.props;
        const { isOpen, selectedIndex, items } = this.state;

        let popupStyles;
        if (isOpen && this.anchorEl) {
            popupStyles = {
                minWidth: this.anchorEl.offsetWidth
            };
        }

        const selectedItem = items.find((item, index) => index === selectedIndex);

        return (
            <div className={classes.root}>
                <Button
                    className={classNames(classes.button, customClass)}
                    classes={{ label: classes.buttonLabel }}
                    variant={variant}
                    color={color}
                    ref={node => {
                        this.anchorEl = node;
                    }}
                    onClick={() => {
                        this.handleToggle(isOpen);
                    }}
                    data-test={dataTest}
                >
                    {selectedItem && (
                        <>
                            {selectedItem.icon && (
                                <span className={classes.icon}>
                                    <Icon iconName={selectedItem.icon} />
                                </span>
                            )}
                            {selectedItem.label}
                        </>
                    )}
                    <Icon iconName="arrow_drop_down" />
                </Button>
                <Popper
                    className={classes.paper}
                    open={isOpen}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal={!aboveAll}
                    placement="bottom"
                    style={{ zIndex: 10000 }}
                    modifiers={{
                        flip: {
                            enabled: false
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: aboveAll ? "window" : "scrollParent",
                        },
                        arrow: {
                            enabled: false
                        }
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === "bottom"
                                        ? "center top"
                                        : "center bottom"
                            }}
                        >
                            <Paper
                                className={classes.paper}
                                style={popupStyles}
                            >
                                <ClickAwayListener
                                    onClickAway={this.handleClose}
                                >
                                    <MenuList>
                                        {items.length &&
                                            items.map((item, index) => {
                                                if (item.type === "divider") {
                                                    return (
                                                        <Divider />
                                                    );
                                                } else {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            onClick={() =>
                                                                this.handleSelect(
                                                                    items,
                                                                    index,
                                                                    dropdownId
                                                                )
                                                            }
                                                            value={item.value}
                                                        // classes={{root: classes.menuItem}}
                                                        >
                                                            {item.icon && (
                                                                <span className={classes.icon}>
                                                                    <Icon iconName={item.icon} />
                                                                </span>
                                                            )}
                                                            {item.label}
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}

DropDown.propTypes = {
    classes: PropTypes.object,
    dropdownId: PropTypes.string
};

const StyledDropDown = withStyles({
    root: {
        fontFamily: "Source Sans Pro"
    },
    button: {
        fontSize: "12px",
        textTransform: "capitalize"
    },
    buttonLabel: {
        columnGap: 0,
        whiteSpace: "nowrap",
    },
    paper: {
        borderRadius: "1px",
        zIndex: "1",
        backgroundColor: "white"
    },
    menuList: {
        padding: "3px 0px"
    },
    icon: {
        marginRight: 7,
        marginBottom: -5
    }
})(DropDown);

export default StyledDropDown;
