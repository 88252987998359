import React from "react";
import styled from "styled-components";

const ShortcutsContainer = styled.div`
padding: 5px 0;
`;

const ShortcutItem = styled.div`
display: flex;
justify-content: space-between;
padding: 5px 20px;
font-size: 14px;
`;

const ShortcutItemLabel = styled.div`
color: #eee;
`;

const ShortcutItemShortcut = styled.div`
color: #11a9e2;
margin-left: 20px;
`;

const ShortcutItemSeparator = styled.span`
    font-size: 12px;
    color: #aaa;
    margin: 0 4px;
`;

const shortcuts = {
    "Next Slide": (
        <>
            <span>&#9654;</span>
            <ShortcutItemSeparator>OR</ShortcutItemSeparator>
            <span>&#9660;</span>
            <ShortcutItemSeparator>OR</ShortcutItemSeparator>
            <span>Page Dn</span>
        </>
    ),
    "Prev Slide": (
        <>
            <span>&#9664;</span>
            <ShortcutItemSeparator>OR</ShortcutItemSeparator>
            <span>&#9650;</span>
            <ShortcutItemSeparator>OR</ShortcutItemSeparator>
            <span>Page Up</span>
        </>
    ),
    "First Slide": "Home",
    "Last Slide": "End",
    "Slide Grid": "Space",
    "Blank Out Screen": (
        <>
            <span>B</span>
            <ShortcutItemSeparator>OR</ShortcutItemSeparator>
            <span>Period</span>
        </>
    ),
    "Exit Fullscreen": "Esc",
    "Exit Player": "Esc",
};

export const PlayerShortcutView = ({
    exclude = [],
}) => {
    let filteredShortcuts = Object.entries(shortcuts)
        .filter(([label]) => !exclude.includes(label));
    return (
        <ShortcutsContainer>
            {
                filteredShortcuts.map(([label, shortcut]) => (
                    <ShortcutItem key={label}>
                        <ShortcutItemLabel>{label}</ShortcutItemLabel>
                        <ShortcutItemShortcut>{shortcut}</ShortcutItemShortcut>
                    </ShortcutItem>
                ))
            }
        </ShortcutsContainer>
    );
};
