import { _ } from "legacy-js/vendor";
import React, { Component } from "reactn";
import { ds } from "js/core/models/dataService";
import Avatar, { FolderAvatar, TeamAvatar } from "legacy-js/react/components/Avatar";
import DropDown from "legacy-js/react/components/DropDown";
import Spinner from "legacy-js/react/components/Spinner";
import Icon from "legacy-js/react/components/Icon";
import { BlueButton } from "legacy-js/react/components/UiComponents";
import permissionsDS from "legacy-js/react/views/PresentationSettings/dataservices/PermissionsDataService";
import { FetchStatus } from "legacy-js/react/constants";
import ConfirmationDialog from "legacy-js/react/components/Dialogs/ConfirmationDialog";
import ErrorMessage from "legacy-js/react/components/ErrorMessage";
import { app } from "js/namespaces";
import { trackActivity } from "js/core/utilities/utilities";
import getLogger, { LogGroup } from "js/core/logger";
import { ShowDialog, ShowSnackBar } from "legacy-js/react/components/Dialogs/BaseDialog";
import TransferOwnershipDialog from "../dialogs/TransferOwnershipDialog";
import { TEAM_USER_ROLES } from "legacy-common/constants";
import { FeatureType } from "legacy-common/features";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { ResendInviteDialog } from "legacy-js/react/components/InviteInput/ResendInviteDialog";
import Api from "js/core/api";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import AppController from "legacy-js/core/AppController";
import { FlexSpacer } from "legacy-js/react/components/Gap";
import { Button } from "@material-ui/core";
import { ManageTeamFolderMembersDialog } from "../../TeamResources/dialogs/TeamFolderDialogs";
import styled from "styled-components";

const logger = getLogger(LogGroup.PERMISSIONS);

const PermissionLevel = {
    TEAM: "team",
    USER: "user"
};

const PermisionRow = styled.div`
    .view-members {
        display: none;
        margin: -2px 0;
    }

    &:hover {
        .view-members {
            display: block;
        }
    }
`;

class PermissionsList extends Component {
    state = { pendingPermToDelete: {} };

    componentWillMount() {
        if (
            this.props.refreshOnMount ||
            !this.global.presentationPermissions ||
            this.global.presentationPermissions.status.type == FetchStatus.UNSET
        ) {
            permissionsDS.fetchPermissions(this.props.presentation.id);
        }
    }

    handleResendInvite = user => {
        let email = user.email;
        let presentationId = this.props.presentation.id;
        let permission = user.write ? "write" : "read";

        ShowDialog(ResendInviteDialog, {
            user,
            onSendEmail: async () => {
                const payload = {
                    emails: [email],
                    permission,
                    customMessage: "",
                };
                await permissionsDS.createPermission(payload, presentationId, true);

                const props = {
                    presentation_id: presentationId,
                    permission,
                    recipient_email: email,
                    sender_email: app.user.getEmail(),
                };
                trackActivity("Collab", "ResendInvite", null, null, props, { audit: true });
                ShowSnackBar({ message: `Invitation Resent to ${email}` });
            },
            onCopyInviteLink: async () => {
                let { inviteUrl } = await Api.inviteUrl.post({
                    presentationId,
                    invitedUser: user,
                });

                await clipboardWrite({
                    [ClipboardType.TEXT]: inviteUrl,
                });

                const props = {
                    presentation_id: presentationId,
                    permission,
                    recipient_email: email,
                    sender_email: app.user.getEmail(),
                    inviteUrl,
                };
                trackActivity("Collab", "CopyInviteLink", null, null, props, { audit: true });
                ShowSnackBar({ message: "Invitation Copied to Clipboard" });
            },
        });
    }

    render() {
        let presentationPermissions = this.global.presentationPermissions;
        let permissions = _.filter(
            presentationPermissions.permissions,
            perm => perm.invite == false || perm.invite == undefined
        );

        const owner = permissions.filter(perm => perm.type === "owner")[0];

        switch (presentationPermissions.status.type) {
            case FetchStatus.ERROR:
                return (
                    <ErrorMessage
                        title="An error occurred while loading permissions"
                        message={presentationPermissions.status.message}
                    />
                );
            case FetchStatus.LOADING:
                return (
                    <div className="permissions-list">
                        <Spinner />
                    </div>
                );
            case FetchStatus.SUCCESS:
                if (permissions.length) {
                    return (
                        <div className="permissions-list">
                            {permissions.map(perm => {
                                if (perm.type === "owner") {
                                    return this.renderOwner(perm);
                                } else if (perm.level === PermissionLevel.TEAM) {
                                    return this.renderTeam(perm, owner);
                                } else {
                                    return (
                                        <div key={perm.email} className="permission">
                                            <FlexBox className="left">
                                                <Avatar url={perm.photoURL} />
                                                <div className="name">{this.renderName(perm)}</div>
                                                {this.renderPermissionDropdown(perm)}
                                                {
                                                    !app.isConstrained &&
                                                    perm.pending &&
                                                    <div className="pending">
                                                        <span>pending signup</span>
                                                    </div>
                                                }
                                            </FlexBox>
                                            {
                                                !app.isConstrained &&
                                                <FlexBox className="right">
                                                    <BlueButton
                                                        className="resend-invite-button"
                                                        onClick={() => this.handleResendInvite(perm)}
                                                    >Resend Invite</BlueButton>
                                                </FlexBox>
                                            }
                                        </div>
                                    );
                                }
                            })}
                            {this.renderConfirmDeleteDialog()}
                        </div>
                    );
                } else {
                    return (
                        <div className="empty-list-warning">
                            No additional users have access to collaborate on this presentation.
                        </div>
                    );
                }
        }

        return null;
    }

    isOwner() {
        return (this.props.presentation.get("userId") === app.user.id);
    }

    renderName(perm) {
        if (perm.displayName) {
            return (
                <>
                    <span className="fullName">{perm.displayName}</span>
                    <span className="email">{perm.email}</span>
                </>
            );
        } else {
            return <span className="fullName">{perm.email}</span>;
        }
    }

    handleSelectIndividualPermission(perm, value) {
        if (value === "owner") {
            ShowDialog(TransferOwnershipDialog, {
                resourceId: this.props.presentation.id,
                newOwner: perm,
                callback: () => permissionsDS.fetchPermissions(this.props.presentation.id)
            });
        } else {
            permissionsDS.updatePermissions(perm, value, this.props.presentation.id);
        }
    }

    renderPermissionDropdown(perm) {
        let handleSelect = async value => {
            // On mobile, we have a remove option.
            //   If it's selected, delete the invitation.
            if (value === "remove") {
                return await new Promise(resolve => {
                    this.dropdownResolve = resolve;
                    this.handleDeleteInvitation(perm);
                });
            } else {
                perm.level === PermissionLevel.TEAM
                    ? permissionsDS.updateTeamPermissions(perm, value, this.props.presentation.id)
                    : this.handleSelectIndividualPermission(perm, value);
            }

            return true;
        };

        const dropdownItems = [
            { label: "Can Edit", value: "write", type: "edit" },
            { label: "Can View", value: "read", type: "view" }
        ];

        if (this.isOwner() && perm.level !== PermissionLevel.TEAM) {
            dropdownItems.push({ label: "Make Owner", value: "owner", type: "owner" });
        }

        dropdownItems.push({ type: "divider" });
        dropdownItems.push({ label: "Revoke Access", value: "remove", type: "remove" });

        let selectedIndex = 0;
        dropdownItems.forEach((item, index) => {
            if (item.type === perm.type && item.value !== "owner") {
                selectedIndex = index;
            }
        });

        return app.user.getEmail() !== perm.email ? (
            <DropDown
                variant="text"
                onSelect={handleSelect}
                items={dropdownItems}
                dropdownId={perm.id}
                selectedIndex={selectedIndex}
                type="transparent"
                dataTest={`permissions-list-dropdown-${perm.email}`}
                aboveAll
            />
        ) : (
            <div className="current-user">You</div>
        );
    }

    renderOwner(owner) {
        return (
            <div key={owner.email} className="permission">
                <FlexBox className="left">
                    <Avatar url={owner.photoURL} />
                    <div className="name">{this.renderName(owner)}</div>
                    <div className="owner">Owner</div>
                </FlexBox>
            </div>
        );
    }

    showMembers(team) {
        const {
            disallowSelfRemoval,
        } = this.props;

        ShowDialog(ManageTeamFolderMembersDialog, {
            folder: team,
            disableSettings: true,
            disallowSelfRemoval,
        });
    }

    renderTeam(team, owner) {
        const teamUser = team.members[app.user.id];
        const orgTeam = this.props.orgId && ds.teams.defaultTeamForOrg(this.props.orgId);
        const isOwnerWhoExistsInTeam = (
            orgTeam?.getUserRole() == TEAM_USER_ROLES.OWNER ||
            teamUser?.role == TEAM_USER_ROLES.OWNER
        );

        const isDefault = ds.teams.get(team.id).get("isDefault");

        let canModify = (
            (
                owner.uid === app.user.id ||
                isOwnerWhoExistsInTeam
            ) &&
            (
                // If we're dealing with the default team, we need permission to modify it
                !isDefault ||
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_MODIFY_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId)
            )
        );

        return (
            <PermisionRow key={team.id} className="permission">
                <FlexBox left fillWidth>
                    {
                        isDefault &&
                        <>
                            <TeamAvatar />
                            <div className="name">
                                <span className="fullName">All Team Members</span>
                            </div>
                        </>
                    }
                    {
                        !isDefault &&
                        <>
                            <FolderAvatar />
                            <div className="name">
                                <span className="fullName">{team.name}</span>
                            </div>
                        </>
                    }
                    {/*Only the owner of a presentation or an admin of the team can update view/edit permission of the presentation
                 or remove the presentation permission for a team*/}
                    {
                        canModify &&
                        this.renderPermissionDropdown(team)
                    }
                    <div className="pending"></div>
                    <div className="view-members">
                        <Button
                            variant="text"
                            onClick={() => this.showMembers(team)}
                        >View Members</Button>
                    </div>
                    {
                        canModify &&
                        <>
                            <FlexSpacer />
                            <div className="delete-button">
                                <Icon iconName="close" onClick={() => this.handleDeleteInvitation(team)} />
                            </div>
                        </>
                    }
                </FlexBox>
            </PermisionRow>
        );
    }

    handleDeleteInvitation = perm => {
        this.setState({
            pendingPermToDelete: {
                //subject can be email or team name depending on if its a user email or team name
                //probably should rename id to something else
                subject: perm.email || perm.name,
                id: perm.id,
                pending: perm.pending || false,
                type: perm.type,
                level: perm.level === PermissionLevel.TEAM ? PermissionLevel.TEAM : PermissionLevel.USER
            }
        });
    };

    resolveDropdown(success) {
        if (this.dropdownResolve) {
            this.dropdownResolve(success);
            this.dropdownResolve = null;
        }
    }

    handleConfirmationOk = async () => {
        const { pendingPermToDelete } = this.state;
        const { onDelete, orgId } = this.props;
        try {
            if (pendingPermToDelete.level === PermissionLevel.TEAM) {
                const props = {
                    old_value: pendingPermToDelete.type === "edit" ? "Edit" : "View",
                    new_value: "Revoked",
                    team_id: pendingPermToDelete.id
                };
                await permissionsDS.deleteTeamPermission(pendingPermToDelete.id, pendingPermToDelete, this.props.presentation.id);
            } else {
                const props = {
                    old_value: pendingPermToDelete.type === "edit" ? "Edit" : "View",
                    new_value: "Revoked",
                    recipient_pending: pendingPermToDelete.pending,
                    recipient_email: pendingPermToDelete.email
                };
                await permissionsDS.deletePermission(pendingPermToDelete.subject, pendingPermToDelete, this.props.presentation.id);

                if (pendingPermToDelete.id === app.user.getEmail()) {
                    app.router.navigate("/", { trigger: true });
                }
                trackActivity("Collab", "AccessRevoked", null, null, props, { audit: true });
            }

            this.setState({
                pendingPermToDelete: {
                    id: null,
                    subject: null,
                    pending: null,
                    type: null
                }
            });
            this.resolveDropdown(true);

            onDelete && onDelete();
        } catch (err) {
            logger.error(err, "PermissionsList handleConfirmationOk() failed", { orgId });
            this.resolveDropdown(false);
        }
    };

    handleConfirmationCancel = () => {
        this.setState({
            pendingPermToDelete: {
                id: null,
                subject: null,
                pending: null,
                type: null
            }
        });
        this.resolveDropdown(false);
    };

    renderConfirmDeleteDialog() {
        const { pendingPermToDelete } = this.state;
        const isOpen = pendingPermToDelete.subject != null;
        return (
            <ConfirmationDialog
                open={isOpen}
                onAccept={this.handleConfirmationOk}
                onCancel={this.handleConfirmationCancel}
                title="Revoke Access?"
            >
                <p>
                    Deleting the access for <strong>{pendingPermToDelete.subject}</strong> will immediately revoke
                    that{" "}
                    {pendingPermToDelete.level === PermissionLevel.TEAM ? "team" : "user"}
                    's permission to collaborate on this presentation and remove it from their library.
                </p>
                <p>
                    This action cannot be undone. If you wish to restore access to this user, you will need to re-invite
                    them as a collaborator.
                </p>
            </ConfirmationDialog>
        );
    }
}

export default PermissionsList;
