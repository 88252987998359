import React, { FC, Fragment, ReactNode } from "react";
import { FlexBox } from "./LayoutGrid";
import styled from "styled-components";
import classNames from "classnames";
import { Icon } from "@material-ui/core";

const Root = styled.div`
&{
    height: 60px;
    padding: 10px;
}
`;

const CrumbDash = styled.div`
& {
    width: 48px;
    height: 0px;
    border: 1.5px solid #999;
    margin: 0 5px;

    &.complete {
        border-color: #11A9E2;
    }
}
`;

const CrumbContent = styled.span`
& {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;

    &>.MuiIcon-root {
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
    }
}
`;

const CrumbLabel = styled.div`
& {
    color: #999;
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
}
`;

const CrumbNode = styled.div`
& {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    background: #EEE;
    color: #999;

    &>${CrumbLabel} {
        position: absolute;
        bottom: -15px; // gap + font-size

        display: none;
    }

    &.active {
        background: #D6EFF9;
        color: #11A9E2;

        &>${CrumbLabel} {
            display: block;
        }
    }

    &.complete {
        background: #11A9E2;
        color: #FFF;
    }
}
`;

type CrumbData = {
    content: ReactNode,
    label: string,
};

type Props = Partial<{
    activeIndex: number,
    completeContent: ReactNode,
    crumbs: CrumbData[],
}>;

export const Breadcrumbs: FC<Props> = ({
    activeIndex = 0,
    completeContent = <Icon style={{ paddingTop: 1 }}>check</Icon>,
    crumbs = [],
}) => {
    return (
        <Root>
            <FlexBox>
                {
                    crumbs.map(({ content, label }, index) => (
                        <Fragment key={`crumb-${index}`}>
                            {
                                index > 0 &&
                                <CrumbDash
                                    className={classNames({
                                        complete: index <= activeIndex,
                                    })}
                                />
                            }
                            <CrumbNode
                                className={classNames({
                                    active: index === activeIndex,
                                    complete: index < activeIndex,
                                })}
                            >
                                <CrumbContent>{index < activeIndex ? completeContent : content}</CrumbContent>
                                <CrumbLabel>{label}</CrumbLabel>
                            </CrumbNode>
                        </Fragment>
                    ))
                }
            </FlexBox>
        </Root>
    );
};
