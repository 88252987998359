import React, { useEffect, useState } from "react";
import { FlexBox } from "./LayoutGrid";
import { getStaticUrl } from "legacy-js/config";
import styled from "styled-components";

const Text = styled.span`
    text-transform: capitalize;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
`;

export const LoadingBot = ({
    labels = [], labelDelay = 1500, pad = 100,
}) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        let interval = null;
        if (labels.length) {
            interval = setInterval(() => {
                setIndex(prevIndex => (prevIndex + 1) % labels.length);
            }, labelDelay);
        }

        return () => clearInterval(interval);
    }, [labels]);

    return (
        <FlexBox column style={{ padding: pad }}>
            <img src={getStaticUrl("/images/bai-bot/bai-bot-loop.gif")} />
            {
                labels.length &&
                <FlexBox>
                    <Text>{labels[index]}</Text>
                </FlexBox>
            }
        </FlexBox>
    );
};
