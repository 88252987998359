import { Key } from "js/core/utilities/keys";

export const TAG_DELIMITERS = [
    Key.TAB,
    Key.ENTER,
    Key.SPACE,
    Key.COMMA,
    Key.SEMICOLON,
];

export const FetchStatus = {
    UNSET: "unset",
    LOADING: "loading",
    LOADING_MORE: "loading-more",
    SUCCESS: "success",
    ERROR: "error"
};
