import React, { Component } from "react";
import Loadable from "legacy-js/react/components/Loadable";
import { RoundIconButton, UIPane } from "legacy-js/react/components/UiComponents";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
    ShowInputDialog,
    ShowWarningDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap10 } from "legacy-js/react/components/Gap";
import { Icon, MenuItem } from "@material-ui/core";
import { Breadcrumb } from "legacy-js/Components/Breadcrumb";
import { TeamFoldersController, TeamFoldersDataService } from "legacy-js/core/dataServices/TeamFoldersDataService";
import { List, ListContextMenu, ListField, ListIconButton } from "legacy-js/react/components/List";
import { SearchBarContainer, UIPaneResultsContainer } from "legacy-js/react/views/AddSlide/Panes/Components/SearchBox";
import { Notice } from "legacy-js/react/components/Notice";
import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";

import { ManageTeamFolderMembersDialog } from "../dialogs/TeamFolderDialogs";
import AllTeamMembersFolderSettingsDialog from "../dialogs/AllTeamMembersFolderSettingsDialog";

export default class TeamFoldersPane extends Component {
    state = {
        teamFolders: [],
        isLoading: true,
    }

    componentDidMount() {
        this.teamFolderDS = new TeamFoldersDataService({
            onCollectionChanged: teamFolders => {
                const index = teamFolders.findIndex(x => x.isDefault);
                if (index > -1) {
                    // Remove the default folder from the list temporarily
                    const defaultFolder = teamFolders.splice(index, 1)[0];

                    // Special case the default folder
                    defaultFolder.name = "All Team Members";
                    defaultFolder.rowOverrides = {
                        fields: [
                            <ListField
                                field="name"
                                name="Folder"
                                width="2fr"
                                type="bold"
                                sortable
                                renderer={folder => <FlexBox left><Icon>apartment</Icon><Gap10 />{folder.name}</FlexBox>}
                            />,
                            <ListField
                                field="members"
                                name="Members"
                                sortable
                                renderer={folder => `${folder.memberCount} ${folder.memberCount != 1 ? "people" : "person"}`}
                            />,
                            <ListField
                                field="presentations"
                                name="Presentations"
                                sortable
                                renderer={folder => `${folder.presentationCount} ${"presentation".pluralize(folder.presentationCount != 1)}`}
                            />,
                            <ListIconButton
                                showOnHover
                                iconName="settings"
                                onClick={this.handleAllTeamMembersFolderSettings}
                            />,
                        ],
                    };

                    // Move the default folder to the top of the list
                    teamFolders = [
                        defaultFolder,
                        ...teamFolders,
                    ];
                }

                this.setState({ teamFolders, isLoading: false });
            }
        });
    }

    componentWillUnmount() {
        this.teamFolderDS.unsubscribe();
    }

    handleAllTeamMembersFolderSettings = () => {
        ShowDialog(AllTeamMembersFolderSettingsDialog);
    }

    handleAddFolder = async () => {
        const name = await ShowInputDialog({
            title: "Create Team Folder",
            maxChars: 50
        });

        if (name) {
            try {
                // create the folder and add the current user by default
                const folder = await TeamFoldersController.createTeamFolder(name, true);

                // show the ManageTeamFolderMembersDialog so the user can share with others
                ShowDialog(ManageTeamFolderMembersDialog, { folder });
            } catch (err) {
                ShowWarningDialog({ title: "Sorry, we were unable to create the team folder", message: err });
            }
        }
    }

    handleEditFolder = folder => {
        ShowDialog(ManageTeamFolderMembersDialog, { folder });
    }

    handleRenameFolder = async folder => {
        const name = await ShowInputDialog({
            title: "Rename Team Folder",
            maxChars: 50,
            value: folder.name
        });

        if (!name) {
            return;
        }

        const { teamFolders } = this.state;
        this.setState({ teamFolders: teamFolders.map(f => f.id === folder.id ? { ...f, fetching: true } : f) });

        try {
            await TeamFoldersController.renameTeamFolder(folder, name);
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to rename folder",
                message: err.message
            });
        }
    }

    handleDeleteFolder = async folder => {
        if (await ShowConfirmationDialog({
            title: "Are you sure you want to delete this team folder?",
            message: "This action can not be undone."
        })) {
            try {
                const { teamFolders } = this.state;
                this.setState({ teamFolders: teamFolders.map(f => f.id === folder.id ? { ...f, fetching: true } : f) });

                await TeamFoldersController.deleteTeamFolder(folder);
            } catch (err) {
                ShowErrorDialog({
                    title: "Unable to delete folder",
                    message: err.message
                });
            }
        }
    }

    render() {
        const { teamFolders, isLoading } = this.state;

        return (
            <UIPane>
                <SearchBarContainer className="search-container">
                    <Breadcrumb>Team Folders</Breadcrumb>
                    <RoundIconButton onClick={this.handleAddFolder}><Icon>add</Icon></RoundIconButton>
                </SearchBarContainer>
                <UIPaneResultsContainer>
                    <FetchingClickShield visible={isLoading} backgroundColor="#eee" />
                    {teamFolders.length > 0 &&
                        <List
                            data={teamFolders}
                            showHeader
                            onDoubleClickRow={folder => this.handleEditFolder(folder)}
                        >
                            <ListField
                                field="name"
                                name="Folder"
                                width="2fr"
                                type="bold"
                                sortable
                                renderer={folder => <FlexBox left><Icon>folder_open</Icon><Gap10 />{folder.name}</FlexBox>}
                            />
                            <ListField
                                field="members"
                                name="Members"
                                sortable
                                renderer={folder => `${folder.memberCount} ${folder.memberCount != 1 ? "people" : "person"}`}
                            />
                            <ListField
                                field="presentations"
                                name="Presentations"
                                sortable
                                renderer={folder => `${folder.presentationCount} ${"presentation".pluralize(folder.presentationCount != 1)}`}
                            />
                            <ListContextMenu showOnHover>
                                <MenuItem
                                    key="edit"
                                    divider
                                    onClick={this.handleEditFolder}>
                                    <Icon>edit</Icon>Share Folder...
                                </MenuItem>
                                <MenuItem
                                    key="rename"
                                    divider
                                    onClick={this.handleRenameFolder}>
                                    <Icon>label</Icon>Rename...
                                </MenuItem>
                                <MenuItem
                                    key="delete"
                                    onClick={this.handleDeleteFolder}>
                                    <Icon>delete</Icon>Delete Folder
                                </MenuItem>
                            </ListContextMenu>
                        </List>
                    }
                    {teamFolders.length == 0 &&
                        <FlexBox fill>
                            <Notice
                                title="You haven't added any Team Folders yet"
                                message="Team Folders make it easy for you to organize and share your presentations with all or some of your team members."
                            />
                        </FlexBox>
                    }
                </UIPaneResultsContainer>
            </UIPane>
        );
    }
}
