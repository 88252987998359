import React, { Component } from "react";
import styled from "styled-components";

import { _ } from "legacy-js/vendor";
import { ds } from "js/core/models/dataService";
import BeautifulDialog from "legacy-js/react/components/Dialogs/BeautifulDialog";
import {
    SlideTemplatesByCategoryList,
    SlideTemplateSearchPreview
} from "legacy-js/react/views/AddSlide/Panes/SlideTemplatesPane";
import { SearchThrottleWait } from "legacy-common/constants";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { SlideSearchInput } from "legacy-js/react/views/AddSlide/Panes/Components/SearchBox";
import Spinner from "legacy-js/react/components/Spinner";
import { slideTemplatesDataService } from "legacy-js/react/views/AddSlide/DataServices";
import { NoMatchNotice } from "legacy-js/react/components/Notice";
import { trackActivity } from "js/core/utilities/utilities";
import { switchSlideTemplate } from "js/core/services/sharedModelManager";

const TitleLabel = styled.label`
  color: #222;
  font-size: 20px;
  font-weight: 600;
`;

const OuterContainer = styled.div`
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
`;

const StyledWarning = styled.div`
    padding: 15px 20px 0;
    font-size: 12px;
    color: #666;
`;

const SearchBarContainer = styled.div`
  padding: 10px 10px;
  background: #f9f9f9;
`;

export default class SwitchTemplateDialog extends Component {
    constructor() {
        super();

        this.fetchTemplateResultsThrottled = _.throttle(this.fetchTemplateResults, SearchThrottleWait, { leading: false });
    }

    state = {
        query: "",
        slideTemplateResults: [],
        isTemplateResultsLoaded: true
    }

    handleTemplateSelected = template => {
        const { canvas, closeDialog } = this.props;

        trackActivity("Slide", "SwitchTemplate", null, null, { template_id: template.id }, { audit: false });
        switchSlideTemplate(canvas, template.id);
        ds.selection.presentation?.trigger("slideTemplateChanged");
        closeDialog();
    }

    handleSearch = async query => {
        this.setState({ query });

        if (query.length > 0) {
            this.setState({ isTemplateResultsLoaded: false });
            this.fetchTemplateResultsThrottled(query);
        }
    }

    fetchTemplateResults = async query => {
        const slideTemplateResults = await slideTemplatesDataService.search({ fullTextSearchQuery: query });
        this.setState({
            slideTemplateResults,
            isTemplateResultsLoaded: true,
        });
    }

    render() {
        const { query, slideTemplateResults, isTemplateResultsLoaded } = this.state;

        return (
            <BeautifulDialog maxWidth="lg" closeButton closeDialog={this.props.closeDialog}>
                <OuterContainer>
                    <FlexBox left>
                        <Gap10 />
                        <TitleLabel>Switch Smart Slide</TitleLabel>
                    </FlexBox>
                    <Gap20 />
                    <SearchBarContainer>
                        <SlideSearchInput
                            onSearch={this.handleSearch}
                            placeholder="Search for a slide..."
                            showShortCuts
                        />
                    </SearchBarContainer>
                    <Gap20 />
                    <ScrollContainer>
                        {isTemplateResultsLoaded &&
                            <>
                                {query.length === 0 &&
                                    <SlideTemplatesByCategoryList
                                        onSelected={this.handleTemplateSelected}
                                        showPreview={false}
                                    />
                                }
                                {query.length !== 0 && <>
                                    {slideTemplateResults.length > 0 &&
                                        <SlideTemplateSearchPreview
                                            items={slideTemplateResults}
                                            onSelected={this.handleTemplateSelected}
                                            showPreview={false}
                                            label="Search Results"
                                        />
                                    }
                                    {slideTemplateResults.length === 0 &&
                                        <NoMatchNotice />
                                    }
                                </>}
                            </>
                        }
                        {!isTemplateResultsLoaded && <Spinner />}
                    </ScrollContainer>
                    <StyledWarning>
                        Some data may be lost when switching slides. You can revert to a previous version in slide version history.
                    </StyledWarning>
                </OuterContainer>
            </BeautifulDialog>
        );
    }
}
