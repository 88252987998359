import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Button, Icon, MenuItem, Popover, TextField, DialogActions, DialogTitle } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";

import * as browser from "js/core/utilities/browser";
import { AuthoringBlockType, HorizontalAlignType, TextStyleType, VerticalAlignType, AssetType } from "legacy-common/constants";
import { builtInFonts } from "legacy-common/fontConstants";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { fontManager } from "js/core/services/fonts";
import { Key } from "js/core/utilities/keys";
import { emailRegex } from "js/core/utilities/regex";
import { UIMenuThumbnail, UIMenuThumbnailGrid } from "legacy-js/editor/selection/components/StyledComponents";
import { BeautifulDialog, DialogContent, ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { FlexSpacer, Gap10, Gap5 } from "legacy-js/react/components/Gap";
import { GridBox } from "legacy-js/react/components/LayoutGrid";
import { $, _ } from "legacy-js/vendor";
import {
    getSelection,
    getSelectionState,
    removeFormattingFromHtmlText,
    convertSpansToFontElements
} from "js/core/utilities/htmlTextHelpers";
import { sanitizeHtml } from "js/core/utilities/dompurify";
import { NestedMenuItem } from "legacy-js/react/components/NestedMenuItem";
import { PopupMenu, PopupMenuPaddedContainer } from "legacy-js/react/components/PopupMenu";
import { LabeledContainer } from "legacy-js/react/components/LabeledContainer";
import { ColorPicker } from "legacy-js/react/components/ColorPicker";

import { stopEventPropagation } from "../AuthoringHelpers";
import { ControlBar, ControlBarGroup } from "../../../EditorComponents/ControlBar";
import { InputSlider } from "../../../EditorComponents/InputSlider";
import { NumberStepper } from "../../../EditorComponents/NumberStepper";

const logger = getLogger(LogGroup.FONTS);

const FontSelect = styled.button`
     border: solid 1px #ccc;
      padding: 5px 12px;
      display: flex;
      align-items: center;
      background: white;
      font-family: "Source Sans Pro", sans-serif;
      
      label {
        font-size: 13px;
        text-transform: none;
      }
      
      .drop-down-arrow {
        color: #999;
        } 
`;

const FontMenuItem = styled(MenuItem)`
    &&& {
        display: flex;
    }
    img {
        height: 20px;
        pointer-events: none;
    }
    span {
        color: rgb(51, 51, 51);
    }
`;

const FontMenuItemWithDivider = styled(MenuItem)`
    &&& {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        text-transform: uppercase;
        font-size: 11px;
    }
    span {
        color: rgb(51, 51, 51);
    }
`;

const TextFormatBar = styled.div`
  display: flex;
  align-items: center;
  
  .MuiToggleButton-root {
    padding: 0px;
  } 
`;

export class TextFormatWidgetBar extends Component {
    state = {
        builtInFonts: [],
        customFonts: []
    };

    updateSelectionStylesOnSelectionChange = true;

    componentDidMount() {
        const { isMultiSelectMode } = this.props;

        // Loading custom fonts
        this.customFontsLoadPromise = ds.assets.getAssetsByType(AssetType.FONT, ds.selection.presentation.getWorkspaceId())
            .then(assets => Promise.all(assets.map(asset => fontManager.loadFont(asset.id))))
            .then(customFonts => new Promise(resolve => {
                // Filtering out fonts that failed to load (fallback fonts)
                this.setState({ customFonts: customFonts.filter(font => font.isCustomFont) }, resolve);
            }))
            .catch(err => logger.error(err, "TextFormatWidgetBar failed to load custom fonts"));

        // Loading built in fonts
        this.builtInFontsLoadPromise = Promise.all(Object.keys(builtInFonts).map(fontId => fontManager.loadFont(fontId)))
            .then(builtInFonts => new Promise(resolve => {
                this.setState({ builtInFonts }, resolve);
            }))
            .catch(err => logger.error(err, "TextFormatWidgetBar failed to load built in fonts"));

        // Getting current selection styles
        this.updateSelectionStyles();

        // Subscribing for selection changes
        if (!isMultiSelectMode) {
            this.instanceId = _.uniqueId();
            $(document).on("selectionchange.textEditor" + this.instanceId, () => {
                if (this.updateSelectionStylesOnSelectionChange) {
                    this.updateSelectionStyles();
                }
            });
        }
    }

    componentWillUnmount() {
        const { isMultiSelectMode } = this.props;

        if (!isMultiSelectMode) {
            $(document).off(".textEditor" + this.instanceId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.selectedBlocks, this.props.selectedBlocks)) {
            this.updateSelectionStyles();
        }

        const { fontFamily } = this.state;
        if (prevState.fontFamily !== fontFamily) {
            // Updating current font styles
            this.setState({ currentFontStyles: null });
            this.loadCurrentFontStyles()
                .catch(err => logger.error(err, "TextFormatWidgetBar loadCurrentFontStyles() failed", { fontFamily }));

            if (fontFamily && fontFamily !== "mixed") {
                // Making sure we have the current font loaded
                Promise.all([this.builtInFontsLoadPromise, this.customFontsLoadPromise])
                    .then(() => {
                        const { customFonts, builtInFonts } = this.state;
                        if (!builtInFonts.some(font => font.id === fontFamily) && !customFonts.some(font => font.id === fontFamily)) {
                            return fontManager.loadFont(fontFamily)
                                .then(loadedFont => {
                                    // Making sure we don't have a duplicate font record in case this flow was running simultaneously for the same font
                                    this.setState(prevState => ({ customFonts: [...prevState.customFonts.filter(font => font.id !== loadedFont.id), loadedFont] }));
                                });
                        }
                    })
                    .catch(err => logger.error(err, "TextFormatWidgetBar fontManager.loadFont() failed", { fontFamily }));
            }
        }
    }

    async loadCurrentFontStyles() {
        const { fontFamily } = this.state;

        if (fontFamily !== "mixed") {
            const font = await fontManager.loadFont(fontFamily);
            this.setState({ currentFontStyles: font.styles });
        }
    }

    createTempNode(html) {
        const $dummy = $.div("").attr("contenteditable", true).css({
            position: "absolute",
            top: 0,
            left: 0,
            userSelect: "text",
            MozUserSelect: "text"
        });
        $dummy.html(html);
        $("body").append($dummy);
        return $dummy;
    }

    updateSelectionStyles() {
        const { containers, selectedBlocks, isMultiSelectMode } = this.props;

        if (isMultiSelectMode) {
            // Get the styles from the aggregate of all the selected blocks
            const blocksProps = [];
            for (const block of selectedBlocks.filter(block => block.type === AuthoringBlockType.TEXT)) {
                const node = block.ref.current;
                const nodeStyles = window.getComputedStyle(node);
                const textStyles = block.getTextStyles();

                const blockProps = {};
                blockProps.textStyle = block.textStyle;
                blockProps.fontFamily = textStyles.fontFamily;
                blockProps.fontWeight = textStyles.fontWeight || parseInt(nodeStyles.getPropertyValue("font-weight"));
                blockProps.fontSize = textStyles.fontSize;
                blockProps.fontColor = block.model.fontColor || "auto";
                blockProps.autoColor = block.autoColor;
                blockProps.fontColor = block.model.fontColor || "auto";
                blockProps.letterSpacing = textStyles.letterSpacing;
                blockProps.lineHeight = textStyles.lineHeight;
                blocksProps.push(blockProps);

                const $temp = this.createTempNode(block.model.html);
                window.getSelection().selectAllChildren($temp[0]);
                blockProps.isBold = document.queryCommandState("bold");
                blockProps.isItalic = document.queryCommandState("italic");
                blockProps.isUnderline = document.queryCommandState("underline");
                blockProps.isSubscript = document.queryCommandState("subscript");
                blockProps.isSuperscript = document.queryCommandState("superscript");
                blockProps.isStrikeThrough = document.queryCommandState("strikeThrough");
                $temp.remove();

                blockProps.fontSize = Math.round(blockProps.fontSize / block.scale);
            }

            const mergedBlockProps = this.getMergedProperties(blocksProps);
            const containerProps = this.getMergedProperties(_.map(containers, container => _.pick(container.model, "textAlign", "verticalAlign")));

            this.setState({ ...mergedBlockProps, ...containerProps });
        } else {
            const selection = getSelection();
            if (!selection) {
                return;
            }

            // Get the styles from the selection range
            const selectedBlock = selectedBlocks[0];
            const range = selection.getRangeAt(0);

            const isLink = range.startContainer.parentNode.tagName === "A" || range.endContainer.parentNode.tagName === "A";

            let selectedNode = range.commonAncestorContainer;
            while (selectedNode.nodeName === "#text") {
                selectedNode = selectedNode.parentElement;
            }
            const nodeStyles = window.getComputedStyle(selectedNode);
            const textStyles = selectedBlock.getTextStyles();

            let fontFamily, fontWeight, fontSize, fontColor;
            if (selection.isCollapsed) {
                fontFamily = textStyles.fontFamily;
                fontWeight = textStyles.fontWeight || parseInt(nodeStyles.getPropertyValue("font-weight"));
                fontSize = textStyles.fontSize;
                fontColor = selectedBlock.model.fontColor || "auto";
            } else {
                fontFamily = nodeStyles.getPropertyValue("font-family");
                fontWeight = parseInt(nodeStyles.getPropertyValue("font-weight"));
                fontSize = parseInt(nodeStyles.getPropertyValue("font-size").replace("px", ""));
                fontColor = document.queryCommandValue("foreColor");
            }

            fontSize = Math.round(fontSize / selectedBlock.scale);

            this.setState({
                selectionBounds: range.getBoundingClientRect(),
                textStyle: selectedBlock.textStyle,
                isBold: document.queryCommandState("bold"),
                isItalic: document.queryCommandState("italic"),
                isUnderline: document.queryCommandState("underline"),
                isSubscript: document.queryCommandState("subscript"),
                isSuperscript: document.queryCommandState("superscript"),
                isStrikeThrough: document.queryCommandState("strikeThrough"),
                fontColor,
                autoColor: selectedBlock.autoColor,
                isLink: isLink,
                fontFamily,
                fontWeight,
                fontSize,
                letterSpacing: textStyles.letterSpacing,
                lineHeight: textStyles.lineHeight,
                textAlign: textStyles.textAlign,
                verticalAlign: containers[0].model.verticalAlign
            });
        }
    }

    getMergedProperties(collection) {
        const props = {};
        for (const item of collection) {
            for (const [key, value] of Object.entries(item)) {
                if (props.hasOwnProperty(key) && props[key] != value) {
                    switch (key) {
                        case "fontSize":
                            props[key] = Math.max(props[key] || 0, value);
                            break;
                        default:
                            props[key] = "mixed";
                    }
                } else {
                    props[key] = value;
                }
            }
        }

        return props;
    }

    handleFormat = format => {
        const { isMultiSelectMode, refreshCanvasAndSaveChanges, textRef, stopHandlingChanges, startHandlingChanges, updateHtml, setSelection, runPostUpdate } = this.props;

        if (!isMultiSelectMode) {
            const selection = window.getSelection();
            if (selection.type === "Caret") {
                // Selecting current word
                selection.modify("extend", "forward", "word");
                selection.collapseToEnd();
                selection.modify("extend", "backward", "word");
            } else if (selection.type === "None") {
                // Selecting all text
                selection.selectAllChildren(textRef.current);
            }

            // Saving current state to be able to restore it after the update
            const selectionStateBeforeUpdate = getSelectionState(textRef.current);

            // Formatting
            if (format === "BOLD") {
                stopHandlingChanges();
                document.execCommand(format);
                startHandlingChanges();

                convertSpansToFontElements(textRef.current);

                runPostUpdate(() => setSelection(selectionStateBeforeUpdate));
                updateHtml(sanitizeHtml(textRef.current.innerHTML));
            } else {
                document.execCommand(format);

                runPostUpdate(() => {
                    const selectionStateAfterUpdate = getSelectionState(textRef.current);
                    if (selectionStateAfterUpdate.start !== selectionStateBeforeUpdate.start || selectionStateAfterUpdate.end !== selectionStateBeforeUpdate.end) {
                        // Restoring the selection if it has changed which may happen in some cases,
                        // refer to BA-8789
                        setSelection(selectionStateBeforeUpdate);
                    }
                });
            }
        } else {
            const { selectedBlocks } = this.props;
            for (const block of selectedBlocks.filter(block => block.type === AuthoringBlockType.TEXT)) {
                const $temp = this.createTempNode(block.model.html);
                window.getSelection().selectAllChildren($temp[0]);
                document.execCommand(format);
                block.model.html = $temp.html();
                $temp.remove();
            }

            this.updateSelectionStyles();
            refreshCanvasAndSaveChanges();
        }
    }

    handleSelectColor = colorKey => {
        this.updateFontStyle({ fontColor: colorKey });
    }

    handleSetTextAlign = textAlign => {
        const { containers, isMultiSelectMode } = this.props;

        if (isMultiSelectMode) {
            // Update containers' models as well
            for (const container of containers) {
                container.model.textAlign = textAlign;
            }
        }

        this.updateSelectedBlocksModels({ textAlign });
    }

    handleSetVerticalAlign = align => {
        this.updateContainerModels({ verticalAlign: align });
    }

    handleShowFontMenu = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            fontMenuAnchorEl: event.currentTarget,
        });
    }

    handleCloseFontMenu = event => new Promise(resolve => {
        const { isMultiSelectMode, textRef, setSelection } = this.props;

        event.preventDefault();
        event.stopPropagation();

        if (!isMultiSelectMode && browser.isSafari) {
            const selectionState = getSelectionState(textRef.current);
            this.setState({
                fontMenuAnchorEl: null
            }, () => {
                _.defer(() => {
                    setSelection(selectionState);
                    resolve();
                });
            });
        } else {
            this.setState({
                fontMenuAnchorEl: null
            }, resolve);
        }
    });

    handleSelectFont = (fontId, weight) => {
        if (fontId === "__theme") {
            this.updateFontStyle({ fontFamily: "__theme", fontWeight: "__theme" });
        } else {
            this.updateFontStyle({ fontFamily: fontId, fontWeight: weight });
        }
    }

    handleSetFontSize = fontSize => {
        this.updateFontStyle({ fontSize });
    }

    handleChangeLink = () => {
        const { textRef } = this.props;

        const selection = window.getSelection();
        if (selection.type === "Caret") {
            // Selecting current word
            selection.modify("extend", "forward", "word");
            selection.collapseToEnd();
            selection.modify("extend", "backward", "word");
        } else if (selection.type === "None") {
            // Selecting all text
            selection.selectAllChildren(textRef.current);
        }
        const range = selection.getRangeAt(0);
        const link = range.startContainer.parentNode.getAttribute("href");

        ShowDialog(AddLinkDialog, {
            value: link,
            onRemoveLink:
                () => {
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand("unlink");
                },
            onSetLink:
                value => {
                    if (!value.startsWith("http") && !value.startsWith("mailto")) {
                        if (value.match(emailRegex)) {
                            value = `mailto:${value}`;
                        } else {
                            value = `https://${value}`;
                        }
                    }

                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand("createLink", false, value);
                }
        });
    }

    handleSetTextStylePreset = style => {
        const { selectedBlocks, refreshCanvasAndSaveChanges } = this.props;

        for (const block of selectedBlocks) {
            block.model.textStyle = style;

            if ((block.model.textStyle == TextStyleType.NUMBERED_LIST || block.model.textStyle == TextStyleType.BULLET_LIST) && (style == TextStyleType.NUMBERED_LIST || style == TextStyleType.BULLET_LIST)) {
                continue; // don't reset styles when switching list types
            }
            block.model.fontFamily = undefined;
            block.model.fontSize = undefined;
            block.model.letterSpacing = undefined;
            block.model.lineHeight = undefined;
            // Clean up formatting and only preserve line breaks
            block.model.html = removeFormattingFromHtmlText(block.model.html || "");
        }

        this.updateSelectionStyles();
        refreshCanvasAndSaveChanges();
    }

    updateFontStyle = async ({ fontSize, fontFamily, fontWeight, fontColor }) => {
        const canvas = this.props.containers[0].canvas;

        if (fontFamily && fontFamily !== "__theme") {
            try {
                // Preloading the selected font for smoother transition
                const font = await fontManager.loadFont(fontFamily);
                await Promise.all(font.styles.map(style => style.loadCssFont()));
            } catch (err) {
                logger.error(err, "TextFormatWidgetBar updateFontStyle() fontManager.loadFont() failed", { fontFamily });
            }
        }

        const writeUpdatesToModel = () => {
            const modelUpdates = {
                fontSize,
                fontFamily: fontFamily === "__theme" ? null : fontFamily,
                fontWeight: fontWeight === "__theme" ? null : fontWeight,
                fontColor: fontColor === "auto" ? null : fontColor
            };

            this.updateSelectedBlocksModels(modelUpdates, true);
        };

        const { isMultiSelectMode, selectedBlocks, stopHandlingChanges, startHandlingChanges, updateHtml, textRef, runPostUpdate, setSelection } = this.props;

        if (isMultiSelectMode) {
            // We aren't editing text so update all the blocks at the model level
            for (const block of selectedBlocks) {
                block.ref.current.querySelectorAll("font").forEach(fontElement => {
                    if (fontSize) {
                        fontElement.style.removeProperty("font-size");
                    }
                    if (fontFamily) {
                        fontElement.style.removeProperty("font-family");
                    }
                    if (fontWeight) {
                        fontElement.style.removeProperty("font-weight");
                    }
                    if (fontColor) {
                        fontElement.removeAttribute("color");
                    }
                });
            }

            writeUpdatesToModel();
            return;
        }

        this.updateSelectionStylesOnSelectionChange = false;
        const setPostUpdateTasks = () => {
            runPostUpdate(() => {
                setSelection(selectionState);
                this.updateSelectionStylesOnSelectionChange = true;
                this.updateSelectionStyles();
            });
        };

        const selectionState = getSelectionState(textRef.current);

        // we are editing text so there can only be a single selectedBlock
        const selectedBlock = selectedBlocks[0];
        const contentEditableElement = textRef.current;
        const selection = getSelection();
        if (selection && selection.anchorNode !== contentEditableElement && !selection.isCollapsed) {
            if (!fontSize) {
                const range = selection.getRangeAt(0);
                let styleOwnerElement = range.commonAncestorContainer;
                while (styleOwnerElement.nodeName === "#text") {
                    styleOwnerElement = styleOwnerElement.parentElement;
                }
                // We have to restore font size after running the command from below
                fontSize = parseInt(window.getComputedStyle(styleOwnerElement).getPropertyValue("font-size").replace("px", ""));
            }

            stopHandlingChanges();
            document.execCommand("fontSize", false, "7");
            startHandlingChanges();

            const updatedElements = [];
            contentEditableElement.querySelectorAll("font").forEach(fontElement => {
                if (fontElement.getAttribute("size") === "7") {
                    updatedElements.push(fontElement);
                }
            });

            const removeStyles = element => {
                element.querySelectorAll("font").forEach(fontElement => {
                    if (fontSize) {
                        fontElement.style.removeProperty("font-size");
                    }
                    if (fontFamily) {
                        fontElement.style.removeProperty("font-family");
                    }
                    if (fontWeight) {
                        fontElement.style.removeProperty("font-weight");
                    }
                    if (fontColor) {
                        fontElement.style.removeProperty("color");
                    }

                    // Remove empty font elements
                    if (!fontElement.getAttribute("style")) {
                        fontElement.replaceWith(...fontElement.childNodes);
                    }
                });
            };

            if (updatedElements.length === 1 && updatedElements[0].outerHTML === contentEditableElement.innerHTML) {
                removeStyles(updatedElements[0]);
                setPostUpdateTasks();
                updateHtml(sanitizeHtml(updatedElements[0].innerHTML));
                writeUpdatesToModel();
            } else if (updatedElements.length > 0) {
                updatedElements.forEach(updatedElement => {
                    updatedElement.removeAttribute("size");

                    removeStyles(updatedElement);

                    if (fontSize) {
                        updatedElement.style.setProperty("font-size", `${fontSize / selectedBlock.getTextStyles().fontSize}em`);
                    }
                    if (fontFamily) {
                        if (fontFamily === "__theme") {
                            updatedElement.style.removeProperty("font-family");
                        } else {
                            updatedElement.style.setProperty("font-family", fontFamily);
                        }
                    }
                    if (fontWeight) {
                        if (fontWeight === "__theme") {
                            const themeWeight = selectedBlock.getTextStyles().fontWeight;
                            updatedElement.style.setProperty("font-weight", themeWeight);
                        } else {
                            updatedElement.style.setProperty("font-weight", fontWeight);
                        }
                    }
                    if (fontColor) {
                        if (fontColor === "auto") {
                            updatedElement.style.removeProperty("color");
                        } else {
                            if (!canvas.getTheme().palette.isRawColor(fontColor)) {
                                updatedElement.style.setProperty("color", canvas.getTheme().palette.getColor(fontColor).toRgbString());
                            } else {
                                updatedElement.style.setProperty("color", fontColor);
                            }
                        }
                    }

                    setPostUpdateTasks();
                    updateHtml(sanitizeHtml(contentEditableElement.innerHTML));
                });
            } else {
                removeStyles(contentEditableElement);
                setPostUpdateTasks();
                updateHtml(sanitizeHtml(contentEditableElement.innerHTML));
                writeUpdatesToModel();
            }
        } else {
            let shouldUpdateState = false;
            contentEditableElement.querySelectorAll("font")
                .forEach(fontElement => {
                    const fontSize = fontElement.style.getPropertyValue("font-size");
                    if (fontSize && fontSize.endsWith("em")) {
                        const sizeInEm = parseFloat(fontSize.replace("em", ""));
                        const actualSize = sizeInEm * selectedBlock.getTextStyles().fontSize;
                        const newSizeInEm = actualSize / fontSize;
                        fontElement.style.setProperty("font-size", `${newSizeInEm}em`);
                        shouldUpdateState = true;
                    }

                    if (fontFamily) {
                        fontElement.style.removeProperty("font-family");
                        shouldUpdateState = true;
                    }
                    if (fontWeight) {
                        fontElement.style.removeProperty("font-weight");
                        shouldUpdateState = true;
                    }
                    if (fontColor) {
                        fontElement.style.removeProperty("color");
                        shouldUpdateState = true;
                    }
                });

            if (shouldUpdateState) {
                setPostUpdateTasks();
                updateHtml(sanitizeHtml(contentEditableElement.innerHTML));
            }

            writeUpdatesToModel();
        }
    }

    updateSelectedBlocksModels = (updates, ignoreUndefined = false, saveChanges = true) => {
        const { selectedBlocks, refreshCanvasAndSaveChanges, refreshElement } = this.props;

        for (const selectedBlock of selectedBlocks) {
            Object.entries(updates).forEach(([key, value]) => {
                if (value === undefined && ignoreUndefined) {
                    return;
                }
                selectedBlock.model[key] = value;
            });
        }

        this.updateSelectionStyles();

        if (saveChanges) {
            refreshCanvasAndSaveChanges();
        } else {
            refreshElement();
        }
    }

    updateContainerModels = (updates, ignoreUndefined = false, saveChanges = true) => {
        const { containers, refreshCanvasAndSaveChanges, refreshElement } = this.props;

        for (const container of containers) {
            Object.entries(updates).forEach(([key, value]) => {
                if (value === undefined && ignoreUndefined) {
                    return;
                }
                container.model[key] = value;
            });
        }

        this.updateSelectionStyles();

        if (saveChanges) {
            refreshCanvasAndSaveChanges();
        } else {
            refreshElement();
        }
    }

    render() {
        const { bounds, isMultiSelectMode } = this.props;
        const {
            letterSpacing, lineHeight, isLink, isBold, isItalic, isSubscript, isSuperscript, isUnderline, isStrikeThrough, fontColor, autoColor,
            textAlign, verticalAlign, textStyle, fontFamily, fontWeight, fontSize, builtInFonts, customFonts, currentFontStyles, fontMenuAnchorEl
        } = this.state;

        const allowedTextStyles = [TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.BODY, TextStyleType.CAPTION, TextStyleType.BULLET_LIST, TextStyleType.NUMBERED_LIST];

        const fontWeights = [];
        if (currentFontStyles) {
            currentFontStyles.forEach(style => {
                if (!fontWeights.includes(parseInt(style.weight))) {
                    fontWeights.push(parseInt(style.weight));
                }
            });
        }
        if (fontWeight && !fontWeights.includes(fontWeight)) {
            fontWeights.push(fontWeight);
        }

        const isSelectedFontLoading = fontFamily !== "mixed" && !builtInFonts.some(font => font.id === fontFamily) && !customFonts.some(font => font.id === fontFamily);

        let currentFontLabel = customFonts.find(font => font.id == fontFamily)?.getStyle(fontWeight, false)?.label;
        if (!currentFontLabel) {
            currentFontLabel = builtInFonts.find(font => font.id == fontFamily)?.getStyle(fontWeight, false)?.label;
        }
        if (!currentFontLabel && isSelectedFontLoading) {
            currentFontLabel = "Loading...";
        }
        if (fontFamily == "mixed") {
            currentFontLabel = "Mixed";
        }

        return (
            <ControlBar bounds={bounds} position="above" offset={10} gap={3}>
                <ControlBarGroup>
                    <TextFormatBar>
                        <PopupMenu id="text-style" label={textStyle} showArrow>
                            <UIMenuThumbnailGrid columns="4">
                                {allowedTextStyles.map(style => (
                                    <UIMenuThumbnail
                                        key={style}
                                        value={style}
                                        label={style}
                                        path={`/images/ui/contentblocktypes/${style}.svg`}
                                        onClick={this.handleSetTextStylePreset}
                                    />
                                ))}
                            </UIMenuThumbnailGrid>
                        </PopupMenu>
                        <ColorPicker
                            color={fontColor}
                            autoColor={autoColor}
                            showAuto
                            showTextColors
                            onChange={this.handleSelectColor}
                            showColorPicker
                            position="above"
                        />
                        <NumberStepper
                            value={fontSize}
                            minValue={6}
                            maxValue={500}
                            onChange={this.handleSetFontSize}
                            menuItems={[15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100]}
                        />
                        <Gap5 />
                    </TextFormatBar>
                </ControlBarGroup>

                <ControlBarGroup>
                    <TextFormatBar>
                        <ToggleButton
                            value="bold"
                            selected={!!isBold}
                            onChange={() => this.handleFormat("BOLD", !isBold)}
                        >
                            <Icon>format_bold</Icon>
                        </ToggleButton>
                        <Gap10 />
                        <ToggleButton
                            value="italic"
                            selected={!!isItalic}
                            onChange={() => this.handleFormat("ITALIC", !isItalic)}
                        >
                            <Icon>format_italic</Icon>
                        </ToggleButton>

                        <PopupMenu
                            id="text-format"
                            icon="font_download"
                            showArrow={true}
                            position="above"
                            popupWidth={900}
                        >
                            <PopupMenuPaddedContainer>
                                <GridBox columns gap={40}>
                                    <GridBox rows gap={15}>
                                        <LabeledContainer icon="font_download" label="Font">
                                            <FontSelect onClick={this.handleShowFontMenu}>
                                                <label>{currentFontLabel}</label>
                                                <Icon className="drop-down-arrow">arrow_drop_down</Icon>
                                            </FontSelect>
                                            <Popover
                                                open={!!fontMenuAnchorEl}
                                                anchorEl={fontMenuAnchorEl}
                                                onClose={this.handleCloseFontMenu}
                                            >
                                                <GridBox rows gap={5}>
                                                    {isSelectedFontLoading && <FontMenuItem disabled={true} value={fontFamily}><span>Loading...</span></FontMenuItem>}
                                                    <Fragment>
                                                        {/*<FontMenuItem key={"__theme"} value={"__theme"}><ThemeFontIcon*/}
                                                        {/*    color="primary">auto_awesome</ThemeFontIcon><span>Theme font</span></FontMenuItem>*/}
                                                        {customFonts.length > 0 &&
                                                            <FontMenuItemWithDivider disabled={true} value={"__custom"}><span>Uploaded fonts</span></FontMenuItemWithDivider>}

                                                        {fontMenuAnchorEl && customFonts.sort(font => font.label).map(font => (
                                                            <NestedMenuItem
                                                                key={font.id}
                                                                contents={<img src={font.imageData} height={24} />}>
                                                                {font.styles.filter(fontStyle => !fontStyle.italic).map((fontStyle, index) => (
                                                                    <FontMenuItem
                                                                        key={index}
                                                                        onMouseDown={event => {
                                                                            this.handleCloseFontMenu(event)
                                                                                .then(() => this.handleSelectFont(font.id, fontStyle.weight));
                                                                        }}
                                                                    >
                                                                        <img
                                                                            height={24}
                                                                            src={fontStyle.imageData}
                                                                        />
                                                                    </FontMenuItem>
                                                                ))}
                                                            </NestedMenuItem>
                                                        ))}

                                                        {builtInFonts.length > 0 &&
                                                            <FontMenuItemWithDivider disabled={true} value={"__custom"}><span>Built-in fonts</span></FontMenuItemWithDivider>}

                                                        {fontMenuAnchorEl && builtInFonts.sort(font => font.label).map(font => (
                                                            <NestedMenuItem
                                                                key={font.id}
                                                                contents={<img src={font.imageData} height={24} />}>
                                                                {font.styles.filter(fontStyle => !fontStyle.italic).map((fontStyle, index) => (
                                                                    <FontMenuItem
                                                                        key={index}
                                                                        onMouseDown={event => {
                                                                            this.handleCloseFontMenu(event)
                                                                                .then(() => this.handleSelectFont(font.id, fontStyle.weight));
                                                                        }}
                                                                    >
                                                                        <img
                                                                            height={24}
                                                                            src={fontStyle.imageData}
                                                                        />
                                                                    </FontMenuItem>
                                                                ))}
                                                            </NestedMenuItem>
                                                        ))}
                                                    </Fragment>
                                                </GridBox>
                                            </Popover>
                                        </LabeledContainer>
                                        <LabeledContainer icon="text_format" label="Character Styles">
                                            <div>
                                                <ToggleButton
                                                    value="link"
                                                    selected={!!isLink}
                                                    disabled={isMultiSelectMode}
                                                    onChange={this.handleChangeLink}
                                                >
                                                    <Icon>insert_link</Icon>
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="strikethrough"
                                                    selected={isStrikeThrough}
                                                    onChange={() => this.handleFormat("STRIKETHROUGH", !isStrikeThrough)}
                                                >
                                                    <Icon>format_strikethrough</Icon>
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="underline"
                                                    selected={isUnderline}
                                                    onChange={() => this.handleFormat("UNDERLINE", !isUnderline)}
                                                >
                                                    <Icon>format_underlined</Icon>
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="subscript"
                                                    selected={isSubscript}
                                                    onChange={() => this.handleFormat("SUBSCRIPT", !isSubscript)}
                                                >
                                                    <Icon>subscript</Icon>
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="superscript"
                                                    selected={isSuperscript}
                                                    onChange={() => this.handleFormat("SUPERSCRIPT", !isSuperscript)}
                                                >
                                                    <Icon>superscript</Icon>
                                                </ToggleButton>
                                            </div>
                                        </LabeledContainer>
                                    </GridBox>

                                    <GridBox rows gap={15}>
                                        <LabeledContainer icon="text_rotation_none" label="Letter Spacing">
                                            <InputSlider
                                                value={letterSpacing}
                                                onChange={letterSpacing => this.updateSelectedBlocksModels({ letterSpacing }, false, false)}
                                                onChangeCommitted={letterSpacing => this.updateSelectedBlocksModels({ letterSpacing })}
                                                sliderMin={-2}
                                                sliderMax={5}
                                                inputMin={-10}
                                                inputMax={100}
                                                step={0.1}
                                            />
                                        </LabeledContainer>
                                        <LabeledContainer icon="format_line_spacing" label="Line Height">
                                            <InputSlider
                                                value={lineHeight}
                                                onChange={lineHeight => this.updateSelectedBlocksModels({ lineHeight }, false, false)}
                                                onChangeCommitted={lineHeight => this.updateSelectedBlocksModels({ lineHeight })}
                                                sliderMin={0.75}
                                                sliderMax={2}
                                                inputMin={0}
                                                inputMax={10}
                                                step={0.05}
                                            />
                                        </LabeledContainer>
                                    </GridBox>
                                </GridBox>
                            </PopupMenuPaddedContainer>
                        </PopupMenu>

                    </TextFormatBar>
                </ControlBarGroup>
                <ControlBarGroup>
                    <TextFormatBar>
                        <PopupMenu
                            icon={`format_align_${textAlign == "mixed" ? "left" : textAlign}`}
                            showArrow={false}
                            position="above"
                        >
                            <PopupMenuPaddedContainer>
                                <LabeledContainer label="Text Align">
                                    <ToggleButton
                                        value="left"
                                        selected={textAlign == HorizontalAlignType.LEFT}
                                        onChange={() => this.handleSetTextAlign(HorizontalAlignType.LEFT)}
                                    >
                                        <Icon>format_align_left</Icon>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="center"
                                        selected={textAlign == HorizontalAlignType.CENTER}
                                        onChange={() => this.handleSetTextAlign(HorizontalAlignType.CENTER)}
                                    >
                                        <Icon>format_align_center</Icon>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="right"
                                        selected={textAlign == HorizontalAlignType.RIGHT}
                                        onChange={() => this.handleSetTextAlign(HorizontalAlignType.RIGHT)}
                                    >
                                        <Icon>format_align_right</Icon>
                                    </ToggleButton>
                                </LabeledContainer>
                            </PopupMenuPaddedContainer>
                        </PopupMenu>
                        <PopupMenu
                            icon={`vertical_align_${textAlign == "mixed" ? "top" : (verticalAlign == VerticalAlignType.MIDDLE ? "center" : verticalAlign)}`}
                            showArrow={false}
                            position="above"
                        >
                            <PopupMenuPaddedContainer>
                                <LabeledContainer label="Vertical Align">
                                    <ToggleButton
                                        value="top"
                                        selected={verticalAlign == VerticalAlignType.TOP}
                                        onChange={() => this.handleSetVerticalAlign(VerticalAlignType.TOP)}
                                    >
                                        <Icon>vertical_align_top</Icon>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="middle"
                                        selected={verticalAlign == VerticalAlignType.MIDDLE}
                                        onChange={() => this.handleSetVerticalAlign(VerticalAlignType.MIDDLE)}
                                    >
                                        <Icon>vertical_align_center</Icon>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="bottom"
                                        selected={verticalAlign == VerticalAlignType.BOTTOM}
                                        onChange={() => this.handleSetVerticalAlign(VerticalAlignType.BOTTOM)}
                                    >
                                        <Icon>vertical_align_bottom</Icon>
                                    </ToggleButton>
                                </LabeledContainer>

                            </PopupMenuPaddedContainer>
                        </PopupMenu>
                    </TextFormatBar>
                </ControlBarGroup>
            </ControlBar>
        );
    }
}

class AddLinkDialog extends Component {
    state = {
        value: this.props.value
    }

    handleChange = event => {
        this.setState({ value: event.target.value });
    }

    handleKeyDown = event => {
        event.stopPropagation();
        if (event.which == Key.ENTER) {
            event.preventDefault();
            this.props.closeDialog();
            this.props.onSetLink(this.state.value);
        }
    }

    render() {
        const { closeDialog, onSetLink, onRemoveLink } = this.props;
        const { value } = this.state;

        return (
            <BeautifulDialog
                closeDialog={this.props.closeDialog}
                {...stopEventPropagation}
            >
                <DialogTitle>Add Link</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus={true}
                        placeholder="Type url"
                        value={value}
                        fullWidth={true}
                        type="text"
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeDialog();
                            onRemoveLink();
                        }}
                    >Remove Link</Button>
                    <FlexSpacer />
                    <Button
                        onClick={() => {
                            closeDialog();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            closeDialog();
                            onSetLink(value);
                        }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
