import React from "react";
import styled from "styled-components";
import { ShowDialog, ShowDialogAsync, ShowErrorDialog } from "./BaseDialog";
import { themeColors } from "legacy-js/react/sharedStyles";
import EndTrialDialog from "legacy-js/react/views/UserOptions/dialogs/EndTrialDialog";
import ErrorDialog from "./ErrorDialog";

const MessageBlock = styled.div`
a {
    color: ${themeColors.ui_blue};
    text-decoration: none;
    pointer-events: auto;
    cursor: pointer;
}
`;

export const ShowInsufficientCreditsDialog = (loadCreditsBalance = null) => {
    let dialog = null;
    dialog = ShowDialog(ErrorDialog, {
        title: "You don't have enough credits.",
        message: (
            <>
                <MessageBlock>
                    Credits are used to create AI generated slides from added context (1 credit) and images (2 credits). Once your trial ends and your subscription starts, access to these features will be free of charge while in beta.
                </MessageBlock>
                <br />
                <MessageBlock>
                    <a onClick={async () => {
                        const result = await ShowDialogAsync(EndTrialDialog);
                        if (result) {
                            loadCreditsBalance && loadCreditsBalance();
                            dialog.close();
                        }
                    }}>Start your subscription now</a> for continued access to these features.
                </MessageBlock>
            </>
        ),
        acceptButtonText: "Got It",
        icon: "/images/bai-bot/bai-bot-neutral.svg",
    });
};

export const ShowTooManyRequestsDialog = () => {
    ShowErrorDialog({
        title: "You're making too many requests.",
        message: "Sorry, but you're making too many requests. Please try again later.",
        acceptButtonText: "Got It",
        icon: "/images/bai-bot/bai-bot-neutral.svg"
    });
};
