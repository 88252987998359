import React, { Component } from "react";

import { BlueButton, UIPane } from "legacy-js/react/components/UiComponents";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { themeColors } from "legacy-js/react/sharedStyles";
import { ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { PresentationTemplatesPaneContents } from "legacy-js/react/views/TeamResources/panes/PresentationTemplatesPane";
import { trackActivity } from "js/core/utilities/utilities";
import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";

export class ChooseTeamTemplatePane extends Component {
    state = {
        selectedTemplate: null,
        fetching: false
    }

    handleSelectTemplate = selectedTemplate => {
        this.setState({
            selectedTemplate
        });
    }

    handleConfirmSelection = selectedTemplate => {
        this.setState({
            selectedTemplate
        }, () => {
            this.createPresentation();
        });
    }

    handleClickCreatePresentation = () => {
        this.createPresentation();
    }

    createPresentation() {
        const { onCreate } = this.props;
        const { selectedTemplate } = this.state;

        if (!selectedTemplate) {
            ShowWarningDialog({ title: "Please select a template first." });
            return;
        }

        this.setState({ fetching: true });

        onCreate({ type: "team-template", template: selectedTemplate });
        trackActivity("Presentation", "Start", null, null, { source: "team-template", template_id: selectedTemplate.id }, { audit: false });
    }

    render() {
        const { fetching, selectedTemplate } = this.state;

        return (
            <UIPane style={{
                width: "calc(100vw - 260px)",
                background: themeColors.lightGray,
            }}>
                <FetchingClickShield visible={fetching} backgroundColor="#eeeeee" />
                <PresentationTemplatesPaneContents
                    editable={false}
                    onSelection={this.handleSelectTemplate}
                    onConfirm={this.handleConfirmSelection}
                />
                <FlexBox right fillWidth style={{ padding: "20px" }}>
                    <BlueButton
                        id="create-presentation-btn"
                        onClick={this.handleClickCreatePresentation}
                        disabled={!selectedTemplate}
                    >
                        Create Presentation From Template
                    </BlueButton>
                </FlexBox>
            </UIPane>
        );
    }
}

