import React, { Component } from "reactn";
import styled from "styled-components";

import { $, _ } from "legacy-js/vendor";
import { AuthoringBlockType, HorizontalAlignType, TextStyleType } from "legacy-common/constants";
import { getValueOrDefault } from "js/core/utilities/extensions";
import * as geom from "js/core/utilities/geom";
import { reactMount, reactUnmount } from "legacy-js/react/renderReactRoot";

import { CodeBlock } from "./AuthoringBlocks/CodeBlock";
import { DividerBlock } from "./AuthoringBlocks/DividerBlock";
import { TextBlock } from "./AuthoringBlocks/TextBlock";
import { EquationBlock } from "./AuthoringBlocks/EquationBlock";

const BlockContainer = styled.div`
    width: 100%;
`;

export function calculateBlockContainerSize(model, width, scale, canvas) {
    const gap = _.defaultTo(model.blockGap, 30);

    const container = (
        <BlockContainer>
            {model.blocks.map((block, index) => {
                const blockProps = {
                    index: index,
                    model: block,
                    canvas: canvas,
                    isFirstBlock: index == 0,
                    isLastBlock: index == model.blocks.length - 1,
                    scale: scale,
                    sequenceNum: 0,
                    gap
                };

                switch (block.type) {
                    case AuthoringBlockType.EQUATION:
                        return <EquationBlock key={index} {...blockProps} />;
                    case AuthoringBlockType.CODE:
                        return <CodeBlock key={index} {...blockProps} />;
                    case AuthoringBlockType.DIVIDER:
                        return <DividerBlock key={index} {...blockProps} />;
                    case AuthoringBlockType.TEXT:
                        return <TextBlock key={index} {...blockProps} />;
                }
            })}
        </BlockContainer>
    );

    const $tempNode = $("body").addEl($.div()).css({
        position: "absolute",
        top: 0,
        left: 0,
        width: width,
        background: "red",
        // display: "table"  //TODO ask Igor why he changed to table because this breaks the margin height from line height adj
    });
    reactMount(container, $tempNode[0]);

    const bounds = $tempNode[0].getBoundingClientRect();

    reactUnmount($tempNode[0]);
    $tempNode.remove();

    return new geom.Size(bounds.width, bounds.height);
}

export class AuthoringBlockContainer extends Component {
    constructor(props) {
        super(props);

        this.blockRefs = {};
    }

    get backgroundColor() {
        const { canvas, model } = this.props;

        if (model.fill && model.fill != "none") {
            if (model.fill == "auto") {
                return canvas.getTheme().palette.getColor(canvas.getSlideColor());
            } else {
                return canvas.getTheme().palette.getColor(model.fill);
            }
        } else {
            return canvas.getBackgroundColor();
        }
    }

    get textAlign() {
        const { model } = this.props;
        return getValueOrDefault(model.textAlign, HorizontalAlignType.CENTER);
    }

    get blocks() {
        const { model } = this.props;
        return model.blocks.map(blockModel => this.blockRefs[blockModel.id] ? this.blockRefs[blockModel.id].current : null);
    }

    get isTextBox() {
        const { model } = this.props;
        return model.fill == "none" && (model.stroke == "none" || model.strokeWidth == 0);
    }

    get model() {
        const { model } = this.props;
        return model;
    }

    render() {
        const { model, gap, scale, canvas } = this.props;

        let indentSequenceNums = {};

        return (
            <BlockContainer>
                {model.blocks.map((block, index) => {
                    const indent = block.indent || 0;
                    let listItemSequenceNum;
                    if (block.textStyle == TextStyleType.NUMBERED_LIST) {
                        if (!indentSequenceNums[indent]) {
                            indentSequenceNums[indent] = 1;
                            listItemSequenceNum = 1;
                        } else {
                            indentSequenceNums[indent]++;
                            listItemSequenceNum = indentSequenceNums[indent];
                        }
                    } else if (block.indent == 0) {
                        indentSequenceNums = {};
                    }

                    if (!this.blockRefs[block.id]) {
                        this.blockRefs[block.id] = React.createRef();
                    }

                    const blockProps = {
                        ref: this.blockRefs[block.id],
                        index: index,
                        model: block,
                        canvas: canvas,
                        backgroundColor: this.backgroundColor,
                        textAlign: this.textAlign,
                        isFirstBlock: index == 0,
                        isLastBlock: index == model.blocks.length - 1,
                        scale: scale,
                        gap: gap,
                        sequenceNum: listItemSequenceNum
                    };

                    switch (block.type) {
                        case AuthoringBlockType.EQUATION:
                            return <EquationBlock key={index} {...blockProps} />;
                        case AuthoringBlockType.CODE:
                            return <CodeBlock key={index} {...blockProps} />;
                        case AuthoringBlockType.DIVIDER:
                            return <DividerBlock key={index} {...blockProps} />;
                        case AuthoringBlockType.TEXT:
                            return <TextBlock key={index} {...blockProps} />;
                    }
                })}
            </BlockContainer>
        );
    }
}
