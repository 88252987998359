import React from "react";

import { LoadingPage } from "../../components/LoadingPage";
import { FirebaseAuthConsumer } from "./FirebaseAuthContext";
import { FirebaseUserProvider } from "./FirebaseUserContext";

/**
 * Shows a spinner until Firebase Auth has initalized.
 */
export class FirebaseAuthInitalizer extends React.Component {
    render() {
        return (
            <FirebaseAuthConsumer>
                {auth => (
                    !auth
                        ? <LoadingPage />
                        : (
                            <FirebaseUserProvider>
                                {this.props.children}
                            </FirebaseUserProvider>
                        )
                )}
            </FirebaseAuthConsumer>
        );
    }
}
