import React from "react";
import moment from "moment";
import styled from "styled-components";

import { _ } from "legacy-js/vendor";
import { API_ERROR_CODE, AssetType } from "legacy-common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import * as geom from "js/core/utilities/geom";
import isConnected from "js/core/utilities/isConnected";
import Api from "js/core/api";
import { isOfflinePlayer, isRenderer, getStaticUrl } from "legacy-js/config";
import { getCSSTransform } from "js/core/utilities/geom";
import { getElementTransition } from "legacy-js/core/utilities/svgHelpers";
import { DecorationType } from "legacy-common/constants";
import { sanitizeHtml } from "js/core/utilities/dompurify";

import { MediaElement } from "./MediaElement";

const logger = getLogger(LogGroup.ELEMENTS);

// This prevents border bleed when using a clip-path over a video element
const calcBorderRadius = decorationStyles => {
    switch (decorationStyles?.shape) {
        case DecorationType.CIRCLE:
        case DecorationType.OCTAGON:
            return "50%";
        default:
            return "0";
    }
};

const VideoContainer = styled.div.attrs(({ labelFontSize, showBlackBackground, enablePointerEvents, decorationStyles }) => ({
    style: {
        fontSize: `${labelFontSize}px`,
        backgroundColor: showBlackBackground ? "black" : "transparent",
        pointerEvents: enablePointerEvents ? "all" : "none",
        borderRadius: calcBorderRadius(decorationStyles),
    }
}))`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    >h1,>a {
        width: 90%;
        text-align: center;
        color: #ffffff;
        font-size: 1em;
    }

    >a {
        pointer-events: all;
        cursor: pointer;
        text-decoration: none;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }

    >div {
        background-color: black;
        position: absolute;
        transform-origin: top left;

        video {
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
`;

const PreviewImage = styled.img`
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%
`;

const PreviewLogoContainer = styled.a`
margin: auto;   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: all;
`;

const PreviewLogo = styled.img.attrs(({ shadowColor, scaleX }) => ({
    style: {
        filter: `drop-shadow(0px 0px 20px ${shadowColor})`,
        width: `${200 / scaleX}px`
    }
}))``;

export class VideoElement extends MediaElement {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();

        this.retryCount = 0;

        this.prevStartTime = null;
        this.prevEndTime = null;

        this._prev_content_value = null;
    }

    get mediaSize() {
        if (this.model?.assetProps) {
            const {
                assetProps: {
                    originalSize: {
                        width,
                        height,
                    },
                },
            } = this.model;
            return new geom.Size(width + (this.styles.imagePadding || 0) * 2, height + (this.styles.imagePadding || 0) * 2);
        } else {
            return new geom.Size(0, 0);
        }
    }

    refreshRenderElement() {
        this.canvas.refreshElement(this, false, true);
    }

    get isInteractive() {
        return !!this.model.assetProps?.controls;
    }

    get interactiveAction() {
        return {
            type: "video",
        };
    }

    get showDefaultOverlay() {
        return this.videoType === "none";
    }

    get videoType() {
        const url = this._activeVideoUrl;
        if (url) {
            if (["youtube", "youtu.be"].some(x => url.contains(x))) {
                return "youtube";
            } else if (url.contains("vimeo")) {
                if (url.contains("external")) {
                    return "stock";
                } else {
                    return "vimeo";
                }
            } else {
                return "upload";
            }
        }
        return "none";
    }

    get publicVideoUrl() {
        if (this.model.content_url) {
            return this.model.content_url;
        }

        if (["vimeo", "youtube"].includes(this.videoType)) {
            return this._activeVideoUrl;
        }

        return null;
    }

    get isAvailableOffline() {
        return isOfflinePlayer && this.videoType === "upload";
    }

    get videoElement() {
        return (
            !isRenderer &&
            this.canBeBackgroundVideo &&
            [...this.wrapperRef?.current?.children].find(x => x instanceof HTMLVideoElement)
        );
    }

    get isPlaying() {
        return !this.videoElement?.paused;
    }

    get canPlayAudio() {
        const result = !!(
            this.videoElement &&
            (
                this.videoElement.mozHasAudio ||
                this.videoElement.webkitAudioDecodedByteCount ||
                this.videoElement.audioTracks?.length
            )
        );
        return result;
    }

    get hasAudio() {
        return this.canPlayAudio && !this.videoElement.muted;
    }

    get _needsConsent() {
        return this.canvas.isPlayback && !this.videoElement.muted;
    }

    get autoMuted() {
        const {
            assetProps: {
                autoPlay,
                muted,
            },
        } = this.model;

        // Only allow autoPlay with audio if we're in slidedeck playback
        if (autoPlay && !this.canvas.isPlayback) {
            return true;
        }

        return muted;
    }

    get canBeBackgroundVideo() {
        return this.videoType === "upload" || this.videoType === "stock";
    }

    get isCacheValid() {
        if (isRenderer) {
            return false;
        }

        if (!this.cachedHtml) {
            return false;
        }

        if (this.cachedExpiry && this.cachedExpiry < moment().valueOf()) {
            return false;
        }

        return _.isEqual(this.cachedModel, this.model);
    }

    get maxRetryCount() {
        return 1;
    }

    saveCache(html, expire = false) {
        this.cachedHtml = html;
        this.cachedExpiry = expire ? moment().valueOf() + (5 * 60 * 1000) : null;
        this.cachedModel = _.cloneDeep(this.model);
    }

    clearCache() {
        this.cachedHtml = null;
        this.cachedExpiry = null;
        this.cachedModel = null;
    }

    get adjustedCache() {
        const {
            assetProps: {
                startTime,
                autoPlay,
            },
        } = this.model;

        let html = this.cachedHtml;
        if (this.videoType === "youtube") {
            // youtube doesn't pass through the autoplay or start properties so fix them now
            html = html.replace("feature=oembed", `feature=oembed&rel=0&modestbranding=1&mute=${this.autoMuted ? 1 : 0}&autoplay=${autoPlay ? 1 : 0}&start=${startTime}`);
        } else {
            html = html
                .replace("autoplay=1", `autoplay=${autoPlay ? 1 : 0}`)
                .replace("muted=1", `muted=${this.autoMuted ? 1 : 0}`)
                .replace('" width', `#t=${this.canvas.isPlayback ? startTime : 0}s" width`);
        }

        // Setting with and height of the iframe to 100%
        html = html.replace(/height=[^s]+/, "");
        html = html.replace(/width=[^s]+/, `width=\"100%\" height=\"100%\"`);

        return html;
    }

    getDefaultMediaTransform(size) {
        if (this.model?.assetProps?.controls) {
            return this.calcFitMedia(new geom.Rect(0, 0, size));
        } else {
            return this.calcFilledMedia(new geom.Rect(0, 0, size));
        }
    }

    playFromStart() {
        if (this.isAvailableOffline || isConnected.connected) {
            if (this.canvas.isCurrentCanvas) {
                if (this.canvas.isPlayback && !this.allowedToAnimate) {
                    return;
                }

                const togglePlayback = () => this.togglePlayback({
                    value: this.model.assetProps.autoPlay,
                    refresh: false,
                    reset: true,
                });

                if (this.canvas.layouter.isGenerating) {
                    this.canvas.layouter.runPostRender(togglePlayback);
                } else {
                    togglePlayback();
                }
            }
        }
    }

    togglePlayback(props) {
        props = {
            value: null,
            refresh: true,
            reset: false,
            ...props,
        };
        if (this.videoElement) {
            if (props.reset) {
                this.videoElement.currentTime = this.model.assetProps.startTime;
            }
            const play = props.value === null ? this.videoElement.paused : props.value;
            if (play) {
                this.videoElement.play();
            } else {
                this.videoElement.pause();
            }
            props.refresh && this.refreshRenderElement();
        }
    }

    toggleAudio(props) {
        props = {
            value: null,
            refresh: true,
            ...props,
        };
        if (this.videoElement) {
            const audio = props.value === null ? this.videoElement.muted : props.value;
            this.videoElement.muted = !audio;
            props.refresh && this.refreshRenderElement();
        }
    }

    toggleControls(props) {
        props = {
            value: null,
            refresh: true,
            ...props,
        };
        if (this.videoElement) {
            const controls = props.value === null ? !this.model.assetProps.controls : props.value;

            this.videoElement.controls = controls;

            this.model.assetProps.controls = controls;
            this.model.assetProps.autoPlay = !controls;
            this.model.assetProps.loop = !controls;
            this.model.assetProps.muted = !controls;

            this.togglePlayback({ value: !controls, refresh: false });
            this.toggleAudio({ value: controls, refresh: false });

            props.refresh && this.refreshRenderElement();
        }
    }

    _build() {
    }

    async _load() {
        this.labelText = null;
        this.labelUrl = null;

        const {
            content_url,
            content_value,
            previewUrl,
        } = this.model;

        // Only change the urls if the content_value has changed
        if (this._prev_content_value !== content_value) {
            // Set the active url
            this._activeVideoUrl = content_url;
            this._previewUrl = previewUrl;
            if (!this._activeVideoUrl && content_value) {
                const videoAsset = await ds.assets.getAssetById(content_value, AssetType.VIDEO);
                this._activeVideoUrl = await videoAsset.getURL();
                this.asset = videoAsset;

                const previewAssetId = videoAsset.get("previewAssetId");
                if (previewAssetId) {
                    const previewAsset = await ds.assets.getAssetById(previewAssetId, AssetType.IMAGE);
                    this._previewUrl = await previewAsset.getURL();
                }
            } else {
                this.asset = null;
            }

            this._prev_content_value = content_value;
        }

        if (this.videoType === "none") {
            return;
        }

        if (!this.isAvailableOffline && !isConnected.connected) {
            this.labelText = "Video is not available offline";
            return;
        }

        if (this.isCacheValid) {
            return;
        }

        if (this.videoType === "upload" || this.videoType === "stock") {
            this.clearCache();
        } else {
            const oembedUrl = this.videoType === "vimeo"
                ? `https://www.vimeo.com/api/oembed.json?url=${encodeURIComponent(this._activeVideoUrl)}&autoplay=1&byline=false&title=false&muted=1`
                : `https://www.youtube.com/oembed?url=${encodeURIComponent(this._activeVideoUrl)}&format=json`;

            try {
                const { html, thumbnail_url } = await Api.oembed.post({ url: oembedUrl });

                this._previewUrl = thumbnail_url;

                // YouTube and Vimeo always have controls
                this.model.assetProps.controls = true;
                this.model.assetProps.loop = false;

                this.saveCache(html);
            } catch (err) {
                logger.error(err, "[VideoElement] Api.oembed.post() failed", { slideId: this.canvas.dataModel?.id, oembedUrl });
                if (err.code === API_ERROR_CODE.FORBIDDEN) {
                    this.labelText = "The permissions on the video do not allow oembed";
                } else {
                    this.labelText = "Could not load video";
                }
            }
        }

        if (isRenderer && !this._previewUrl) {
            this.labelText = "Video";
        }
    }

    _prepareToShowElement() {
        if (isRenderer) {
            return;
        }

        // Automatically animate when the video element
        //   is visible on the current slide
        if (this.canvas.isCurrentCanvas) {
            this.allowedToAnimate = true;
            this.playFromStart();
        }
    }

    _stopElement() {
        if (isRenderer) {
            return;
        }

        this.allowedToAnimate = false;
        this.togglePlayback({
            value: false,
            refresh: false,
        });

        // don't fire off async refreshCanvas if this was called because we are closing the player
        if (this.canvas.isPlayback && this.canvas.playerView && this.canvas.playerView.closing) {
            return;
        }
    }

    onLoadedMetadata() {
        this.retryCount = 0;

        if (this.canvas.isCurrentCanvas) {
            this.playFromStart();
        }
    }

    async onError() {
        // Wait for 1s and retry rendering again if we failed the first time
        if (this.retryCount < this.maxRetryCount) {
            ++this.retryCount;

            this.clearCache();
            this._prev_content_value = null;

            await new Promise(resolve => setTimeout(resolve, 1000));

            try {
                await this._load();
                this.refreshRenderElement();
            } catch (err) {
                logger.error(err, "[VideoElement] this._load() failed", { slideId: this.canvas.dataModel?.id });
            }
        }
    }

    onTimeUpdate() {
        if (this.videoElement && !this.videoElement.paused) {
            let {
                assetProps: {
                    startTime = 0.0,
                    endTime = this.videoElement.duration,
                    loop = this.videoElement.duration < 10.0,
                    speed = 1.0,
                }
            } = this.model;

            this.videoElement.playbackRate = speed;

            // Ensure we're not out of bounds of the duration of the video
            // We use the slightly less than the duration to prevent the
            // auto looping webkit browsers like to do.
            const duration = Math.max(0, this.videoElement.duration - 0.1);
            startTime = Math.min(duration, startTime);
            endTime = Math.min(duration, endTime);

            if (this.videoElement.currentTime >= endTime) {
                if (loop && startTime !== endTime) {
                    this.videoElement.currentTime = startTime;
                } else {
                    this.videoElement.pause();
                    this.videoElement.currentTime = endTime;
                }
            } else if (this.videoElement.currentTime < startTime) {
                this.videoElement.currentTime = startTime;
            }
        }
    }

    clearHoverTimeout() {
        if (this.hoverTimeout) {
            clearTimeout(this.hoverTimeout);
            this.hoverTimeout = null;
        }
    }

    showControls() {
        if (this.videoElement && this.model.assetProps?.controls) {
            this.clearHoverTimeout();
            this.videoElement.controls = true;
            this.hoverTimeout = setTimeout(() => {
                this.videoElement.controls = false;
            }, 2000);
        }
    }

    hideControls() {
        this.clearHoverTimeout();
        if (this.videoElement) {
            this.videoElement.controls = false;
        }
    }

    renderChildren(transition) {
        const {
            bounds,
            transformProps,
            size
        } = this.calculatedProps;

        const {
            assetProps: { controls, loop }
        } = this.model;

        let styleWrapper;
        if (controls) {
            // Fit the video
            styleWrapper = {
                top: bounds.top,
                left: bounds.left,
                width: bounds.width,
                height: bounds.height,
            };
        } else {
            // Fill the video
            styleWrapper = {
                top: 0,
                left: 0,
                width: this.mediaSize.width,
                height: this.mediaSize.height,
                transform: getCSSTransform(transformProps),
                transition: getElementTransition(transition),
            };
        }

        // Remove the ugly white borders for vimeo embeds
        if (this.videoType === "vimeo") {
            styleWrapper.top -= 2;
            styleWrapper.left -= 2;
            styleWrapper.width += 2;
            styleWrapper.height += 2;
        }

        const styleVideo = {
            width: "100%",
            height: "100%",
        };

        const labelFontSize = Math.min(size.width / 15, 42);

        let logoUrl;
        if (isRenderer) {
            logoUrl = this.videoType === "youtube"
                ? getStaticUrl("/images/ui/vendor/youtube-logo.png")
                : this.videoType === "vimeo"
                    ? getStaticUrl("/images/ui/vendor/vimeo-logo.png")
                    : null;
        }

        return (
            <VideoContainer
                key={this.id}
                enablePointerEvents={controls && this.canvas.isPlayback}
                labelFontSize={labelFontSize}
                showBlackBackground={!!this.labelText}
                decorationStyles={this.parentElement?.decoration?.styles}
            >
                {
                    this.labelText && !this.labelUrl &&
                    <h1>{this.labelText}</h1>
                }
                {
                    this.labelText && this.labelUrl &&
                    <a href={this.labelUrl}>{this.labelText}</a>
                }
                {
                    !this.labelText && isRenderer && this._previewUrl &&
                    <div
                        style={styleWrapper}
                        ref={this.wrapperRef}
                    >
                        <PreviewImage src={this._previewUrl} alt="video" onLoad={this.getImageOnLoadPromiseResolver(this._previewUrl)} />
                        {logoUrl && <PreviewLogoContainer href={this.publicVideoUrl}>
                            <PreviewLogo
                                scaleX={transformProps.scaleX}
                                src={logoUrl}
                                shadowColor={this.videoType === "youtube" ? "white" : "black"}
                                onLoad={this.getImageOnLoadPromiseResolver(logoUrl)}
                            />
                        </PreviewLogoContainer>}
                    </div>
                }
                {!this.labelText && !isRenderer &&
                    <>
                        {
                            this.cachedHtml &&
                            <div
                                style={styleWrapper}
                                ref={this.wrapperRef}
                                dangerouslySetInnerHTML={{ __html: sanitizeHtml(this.adjustedCache) }}
                            ></div>
                        }
                        {
                            !this.cachedHtml &&
                            <div
                                style={styleWrapper}
                                ref={this.wrapperRef}
                            >
                                <video
                                    src={this._activeVideoUrl}
                                    poster={this._previewUrl}
                                    style={styleVideo}
                                    disablePictureInPicture
                                    controlsList="nodownload"
                                    loop={loop}
                                    muted={this.autoMuted}
                                    onLoadedMetadata={() => this.onLoadedMetadata()}
                                    onError={() => this.onError()}
                                    onTimeUpdate={() => this.onTimeUpdate()}
                                    playsInline
                                />
                            </div>
                        }
                    </>
                }
            </VideoContainer>
        );
    }
}
