import { _ } from "js/vendor";

/**
 * Breaks an items array into lines that have a width that is the closest to the goalWidth.
 * @param items
 * @param maxWidth
 * @param [options] See computeLineBreaks for options.
 * @return {null}
 */
function breakLines(items, maxWidth, options) {
    const results = computeLineBreaks(items, maxWidth, options);
    return results ? results.lines : null;
}

/**
 * Algorithm is a dynamic programming algorithm where we compute the best fits from the last line to the first line.
 * The best solution is based on the shortest distance from the goal width of each line and penalizes if the line is larger
 * than the previous line.
 *
 * Example: "Hello world today is up" with a goal width of 5 chars
 * First iteration computes the best line breaks for the string "up" - resulting in [["up"]]
 * Second iteration computes the best line breaks for the string "is up" - first trying "is" + the best results for "up" then trying out
 *      "is up". The result is [["is ", "up"]] is closer to the goal width.
 * Third iteration computes the best line breaks for the string "today is up" - trying "today" + best results for "is up" then
 *      "today is" + best results for "up" and then "today is up". In this case the best solution it "today" + "is up" getting [["today "],["is ", "up"]]
 * ...
 *
 * This keeps going until all permutations have been computed.
 *
 * For the line count, we also store all perumtations at the line count.
 *
 * Strategies for using with minLines and maxLines:
 * 1. If you want a set line count, set both to the same value.
 * 2. If you want to prioritize a line count, run the algorithm with a set value and if it is null, rerun the algorithm
 *      without a maxLines value to find the best match. This is faster than running a high line count.
 *
 * @param items
 * @param maxWidth
 * @param [options]
 * @param [options.goalWidth=maxWidth] The width to target.
 * @param [options.minLines=1] Setting a minLines greater than 1 will keep track of the best fit for each line count that
 * is possible and then find the best match greater than that line count. This will slow down the algorithm so use only when
 * needed.
 * @param [options.maxLines=null] Setting a maxLines will keep track of the best fit for each line count that is possible
 * and then find the best match up to the maxLines. This will slow down the algorithm, so use only when needed.
 * @param [options.spacing=0] Spacing to add between each item.
 * @return {*}
 */
function computeLineBreaks(items, maxWidth, options) {
    options = Object.assign({
        goalWidth: maxWidth,
        maxLines: null,
        minLines: 1,
        spacing: 0
    }, options);

    const breakPointIndices = computeValidBreakPointIndices(items);

    // Check if we have minLines set to be greater than 1.
    // If so, the max lines would be the total number of breakpoints.
    if (options.minLines > 1 && options.maxLines == null) {
        options.maxLines = Math.min(breakPointIndices.length - 1, 1000);
    }

    const results = new Array(breakPointIndices.length);
    // Base case
    results[breakPointIndices.length - 1] = {
        0: {
            cost: 0,
            width: 0,
            lines: []
        }
    };

    // Build the results in reverse so we can take advantage of caching the results.
    // Skip the last entry as that is the length of the items array.
    for (let i = breakPointIndices.length - 2; i >= 0; i--) {
        const start = breakPointIndices[i];

        // Compute all permutations!
        const best = {};

        for (let j = i + 1; j < breakPointIndices.length; j++) {
            const end = breakPointIndices[j];

            const line = items.slice(start, end);
            const width = getLineWidth(line) + (options.spacing * (line.length - 1));

            // only stop if this isn't the first nonbreaking item.
            if (width > maxWidth && j > i + 1) {
                break;
            }

            // We already know the best result for the current breakpoint index, so pull that up to compute the total result!
            const bestAtIndex = results[j];

            if (options.maxLines == null) {
                //simple case, find the best with no line restrictions
                computeBest(best, bestAtIndex[0], line, width, options.goalWidth);
            } else {
                //compute the breaks for each line count
                for (let lineCount of Object.keys(bestAtIndex).map(Number)) {
                    if (lineCount - 1 < options.maxLines) {
                        computeBest(best, bestAtIndex[lineCount], line, width, options.goalWidth, lineCount + 1);
                    }
                }
            }
        }
        results[i] = best;
    }

    let bestBreaks = null;

    if (options.maxLines == null) {
        bestBreaks = results[0][0];
    } else {
        for (let i = options.minLines; i <= options.maxLines; i++) {
            const targetBreak = results[0][i];
            if (targetBreak && (!bestBreaks || targetBreak.cost < bestBreaks.cost)) {
                bestBreaks = targetBreak;
            }
        }
    }

    if (!bestBreaks || bestBreaks.lines.length === 0) {
        return null;
    }

    return {
        cost: bestBreaks.cost,
        lines: bestBreaks.lines.map(line => {
            return line.map(item => item.value);
        })
    };
}

function computeBest(best, bestPrevious, line, width, goalWidth, targetIndex = 0) {
    const cost = bestPrevious.cost +
        Math.pow(goalWidth - width, 2) + //Distance from goal width
        Math.pow(Math.max(0, bestPrevious.width - width), 2); //nudge picking to choose the shorter new line

    if (!best[targetIndex] || best[targetIndex].cost >= cost) {
        best[targetIndex] = {
            cost: cost,
            width: width,
            lines: [line].concat(bestPrevious.lines)
        };
    }
}

/**
 * By default, this will assume all items can break unless a canBreak value is set to false.
 * @param items
 * @return {[number]}
 */
function computeValidBreakPointIndices(items) {
    const breakPointIndices = [0]; //you can always break on the first item
    let canBreak = false;
    for (let i = 1; i < items.length; i++) {
        const item = items[i];
        if ("canBreak" in item) {
            if (canBreak && item.canBreak === false) {
                breakPointIndices.push(i);
            }
            canBreak = item.canBreak !== false;
        } else {
            breakPointIndices.push(i);
        }
    }
    breakPointIndices.push(items.length);
    return breakPointIndices;
}

function getLineWidth(line) {
    let width = 0;
    for (let i = 0; i < line.length - 1; i++) {
        width += line[i].width;
    }
    if (line.length > 0 && !line[line.length - 1].canBreak) {
        width += line[line.length - 1].width;
    }
    return width;
}

// Check the maximum number of items that can be fit into the given columns.
function maxFit(nColumns, colSpacing, maxHeight, heights) {
    let n = 0;
    while (nColumns-- && nColumns > -1) {
        let heightLeft = maxHeight + colSpacing;
        while (heightLeft > 0 && heights.length) {
            heightLeft -= heights[0] + colSpacing;
            if (heightLeft >= 0) {
                heights.shift();
                n++;
            }
        }
    }
    return n;
}

function breakColumns(minColumns, maxColumns, colSpacing, maxHeight, calcLayoutsForCol) {
    let best, bestIsFit = false, bestHasTextClipped = false;
    for (let cols = minColumns; cols <= maxColumns; cols++) {
        tryCols(cols);
    }

    function tryCols(cols, forceFit = false) {
        // the new column width is the bounds width / the number of columns or the defined colWidth, whichever is smallest
        let items = calcLayoutsForCol(cols);
        let itemHeights = items.map(item => item.size.height);
        let isFit = items.every(item => item.isFit);
        let isTextClipped = items.some(item => item.isTextClipped);

        // calculate the total height of all the items at the new column width
        let totalItemHeight = itemHeights.reduce((l, r) => l + r, 0) + (itemHeights.length - cols) * colSpacing;
        let evenColumnHeight = Math.ceil(totalItemHeight / cols);

        // Check how many items can actually fit - use the greedy linebreaking algorithm, which fits the most items
        let fittingItemsLength = maxFit(cols, colSpacing, maxHeight, itemHeights.slice(0));
        if (fittingItemsLength < itemHeights.length) {
            isFit = false;
        }

        if (forceFit) {
            fittingItemsLength = itemHeights.length;
        }

        const itemsToBreak = itemHeights.slice(0, fittingItemsLength).map(h => ({ value: h, width: h }));
        if (itemsToBreak.length === 0) {
            return;
        }

        let lines = computeLineBreaks(
            itemsToBreak,
            maxHeight,
            {
                spacing: colSpacing,
                goalWidth: evenColumnHeight,
                minLines: cols,
                maxLines: cols
            });

        // try no maxLines limit
        if (!lines) {
            lines = computeLineBreaks(itemsToBreak, maxHeight, {
                spacing: colSpacing,
                goalWidth: evenColumnHeight,
                minLines: cols
            });
        }

        // there is no solution for the goalWidth.
        if (!lines) {
            return;
        }

        if (lines.lines.length > cols) {
            lines.lines[cols - 1].push(..._.flatten(lines.lines.slice(cols)));
            lines.lines = lines.lines.slice(0, cols);
        }

        if (forceFit || !best || isFit && !bestIsFit ||
            (isFit === bestIsFit && !isTextClipped && bestHasTextClipped) ||
            (isFit === bestIsFit && isTextClipped === bestHasTextClipped && best.cost > lines.cost)) {
            best = lines;
            bestIsFit = isFit;
            bestHasTextClipped = isTextClipped;
        }
    }

    if (!bestIsFit) {
        tryCols(maxColumns, true);
    }

    return { heights: best && best.lines, isFit: bestIsFit, isTextClipped: bestHasTextClipped };
}

function fitIntoColumns(columns, colSpacing, maxHeight, minItemHeight, itemHeights) {
    function tryCols(cols, tryHeights) {
        // calculate the total height of all the items at the new column width
        let totalItemHeight = tryHeights.reduce((l, r) => l + r, 0) + (tryHeights.length - cols) * colSpacing;
        let evenColumnHeight = Math.ceil(totalItemHeight / cols);

        // Check how many items can actually fit - use the greedy linebreaking algorithm, which fits the most items
        // let fittingItemsLength = maxFit(cols, colSpacing, maxHeight, itemHeights.slice(0));
        // if (fittingItemsLength < itemHeights.length) {
        //     isFit = false;
        // }

        let results = computeLineBreaks(
            tryHeights.slice(0, tryHeights.length).map(h => ({ value: h, width: h })),
            maxHeight,
            {
                spacing: colSpacing,
                goalWidth: evenColumnHeight,
                minLines: cols,
                maxLines: cols
            });
        return results ? results.lines : false;
    }

    const delta = 10;

    let results = false;

    loop:
    while (_.some(itemHeights, h => h > minItemHeight)) {
        let largestHeight = _.max(itemHeights);
        itemHeights[_.lastIndexOf(itemHeights, largestHeight)] = largestHeight - delta;
        results = tryCols(columns, itemHeights);
        if (results) {
            break loop;
        }
    }

    return results;
}

export { breakLines, breakColumns, fitIntoColumns, computeLineBreaks };
