import { getHTMLProps, getSVGStyleProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import React from "reactn";
import { _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { DecorationType } from "legacy-common/constants";
import { Shape } from "js/core/utilities/shapes";
import { sanitizeSvg } from "js/core/utilities/dompurify";

import { BaseElement } from "./BaseElement";

export class DecorationElement extends BaseElement {
    constructor(props) {
        super(props);

        this.backgroundRef = React.createRef();
        this.foregroundRef = React.createRef();
    }

    get canSelect() {
        return false;
    }

    get canRollover() {
        return false;
    }

    get isDecoration() {
        return true;
    }

    get DOMNode() {
        return null;
    }

    _calcProps(props, options) {
        let { size } = props;
        return { size };
    }

    renderElement(transition) {
        throw new Error("Decoration element must be rendered with renderDecorationElement()");
    }

    hasStyleValueDefined(styleValue) {
        if (!styleValue) {
            return false;
        }

        if (styleValue === "none") {
            return false;
        }

        return true;
    }

    /**
     * Renders the decoration, returns an array where the first element is a background decoration
     * which should be placed behind the parent element, and the second element is a foreground that
     * should be placed in front of the parent element.
     * WARNING: background and/or foreground can be null!
     * @returns [background, foreground]
     */
    renderDecorationElement(props, transition) {
        const { styles } = props;

        // if ((this.model.color == null || this.model.color == "auto") && this.getBackgroundColor(this).isColor && this.styles.fillColor != "none") {
        //     this.styles.resolved_fillColor = this.canvas.getTheme().palette.getColor("white", this.canvas.getTheme().palette.getColorMods(this.styles.fillColor));
        // }

        this.hasForeground = this.hasBackground = false;

        if (this.styles.type === "svg") {
            // Always putting svg decorations on the background
            this.hasBackground = true;
            return [this.renderSVG(props, "background", this.backgroundRef, transition), null];
        }

        let background, foreground = null;

        if (this.hasStyleValueDefined(this.styles.fillColor) || this.hasStyleValueDefined(this.styles.fill)) {
            background = this.renderDecoration(props, this.styles, "background", this.backgroundRef, transition);
            // Used for ppt export
            this.hasBackground = true;
        }

        if (this.hasStyleValueDefined(this.styles.strokeColor) || this.hasStyleValueDefined(this.styles.stroke)) {
            const foregroundStyles = _.cloneDeep(this.styles);
            delete foregroundStyles.fillColor;
            delete foregroundStyles.resolved_fillColor;
            if (background) {
                delete foregroundStyles.filter; // Don't double render filter shadow if already applied to fill
                delete foregroundStyles.shadow;
            }
            foreground = this.renderDecoration(props, foregroundStyles, "foreground", this.foregroundRef, transition);
            // Used for ppt export
            this.hasForeground = true;
        }

        this.isRendered = true;

        return [background, foreground];
    }

    renderDecoration(props, styles, key, ref, transition) {
        switch (styles.shape) {
            case DecorationType.RECT:
                return this.renderRect(props, styles, key, ref, transition);
            case DecorationType.CIRCLE:
                return this.renderCircle(props, styles, key, ref, transition);
            case DecorationType.OCTAGON:
                return this.renderOctagon(props, styles, key, ref, transition);
        }
    }

    getBackgroundColor(forElement) {
        if (forElement !== this) {
            switch (this.styles.shape) {
                case DecorationType.RECT:
                case DecorationType.CIRCLE:
                    return this.styles.fillColor;
                default:
                    return this.parentElement.getParentBackgroundColor(this.parentElement);
            }
        } else {
            return this.parentElement.getParentBackgroundColor(forElement);
        }
    }

    renderRect(props, styles, key, ref, transition) {
        return <div key={key} ref={ref} {...getHTMLProps(props, styles, transition)} />;
    }

    renderCircle(props, styles, key, ref, transition) {
        const bounds = props.bounds.square().centerInRect(props.bounds);
        const htmlProps = getHTMLProps({ ...props, bounds }, styles, transition);
        htmlProps.style.borderRadius = "50%";

        return <div key={key} ref={ref} {...htmlProps} />;
    }

    renderOctagon(props, styles, key, ref, transition) {
        let size = props.bounds.size.square();
        let polygon = Shape.drawOctagon(size.width).offset(new geom.Rect(0, 0, size).centerInRect(props.bounds).position).toPolygonData();

        return (
            <SVGGroup key={key}>
                <polygon
                    ref={ref}
                    id={this.id}
                    points={polygon}
                    style={getSVGStyleProps(styles)}
                />
            </SVGGroup>
        );
    }

    renderSVG(props, key, ref, transition) {
        let { bounds } = props;

        let svgDef = this.styles.def;
        svgDef = svgDef.replace(/(\$.*?)(?=[ }'"])/gm, match => {
            return this.canvas.styleSheet.variables[match.substr(1)];
        });

        // replace palette colors in svg definition
        if (svgDef.contains("decorationColor")) {
            svgDef = svgDef.replace(/decorationColor/g, "slide");
        }
        svgDef = svgDef.replace(/fill='(.*?)'|stroke='(.*?)'/g, (str, fillColor, strokeColor) => {
            if (fillColor && !fillColor.startsWith("url")) {
                str = str.replace(fillColor, this.canvas.getTheme().palette.getForeColor(fillColor, this.getSlideColor(), this.getBackgroundColor()).toRgbString());
            }
            if (strokeColor) {
                str = str.replace(strokeColor, this.canvas.getTheme().palette.getForeColor(strokeColor, this.getSlideColor(), this.getBackgroundColor()).toRgbString());
            }
            return str;
        });

        const decorationSVG = {
            __html: sanitizeSvg(parseSVGForDecoration(svgDef, props, this.styles, this.parentElement.calculatedProps))
        };

        return (
            <SVGGroup key={key}>
                <g ref={ref} dangerouslySetInnerHTML={decorationSVG} style={{ transition: "fill 300ms" }}></g>
            </SVGGroup>
        );
    }
}

function parseSVGForDecoration(def, props, styles, parentProps) {
    let paddedBounds = props.bounds.deflate(styles.margins || 0);

    const svg = def.replace(/#{(.*?)}/g, (match, value) => {
        let expression = "";
        for (let component of value.split(" ")) {
            switch (component) {
                case "tw":
                    expression += parentProps.textBounds.width;
                    break;
                case "th":
                    expression += parentProps.textBounds.height;
                    break;
                case "tl":
                    expression += parentProps.bounds.left + parentProps.textBounds.left;
                    break;
                case "tr":
                    expression += parentProps.bounds.left + parentProps.textBounds.right;
                    break;
                case "tc":
                    expression += parentProps.bounds.left + parentProps.bounds.width / 2;
                    break;
                case "tm":
                    expression += parentProps.textBounds.centerV;
                    break;
                case "tt":
                    expression += parentProps.textBounds.top;
                    break;
                case "ct":
                    expression += parentProps.bounds.width / 2 - parentProps.textBounds.width / 2;
                    break;
                case "tb":
                    expression += parentProps.textBounds.bottom;
                    break;
                case "first_char_width":
                    expression += parentProps.textLayout.lines[0].words[0].glyphWidths[0] * 2;
                    break;
                case "first_char_left":
                    expression += parentProps.textLayout.lines[0].words[0].x;
                    break;
                case "first_char_top":
                    expression += parentProps.textLayout.lines[0].words[0].y;
                    break;
                case "last_char_right":
                    expression += _.last(_.last(parentProps.textLayout.lines).words).x + _.last(_.last(parentProps.textLayout.lines).words).width;
                    break;
                case "last_char_top":
                    expression += _.last(_.last(parentProps.textLayout.lines).words).y;
                    break;
                case "marginBottom":
                    expression += parentProps.styles.marginBottom || 0;
                    break;
                default:
                    if (component.indexOf("w") > -1) {
                        expression += paddedBounds.width * (parseInt(component) / 100);
                    } else if (component.indexOf("h") > -1) {
                        expression += paddedBounds.height * (parseInt(component) / 100);
                    } else {
                        expression += component;
                    }
            }
            expression += " ";
        }

        return eval(expression);
    });
    if (svg == "") {
        return "<g/>";
    } else {
        return svg;
    }
}

export const elements = {
    DecorationElement
};
