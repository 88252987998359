import { SUGGEST_TEMPLATE_FORM } from "common/constants";
import { app } from "js/namespaces";
import { FeatureType } from "js/core/models/planPermissions";
import { getPricingPageUrl } from "js/core/utilities/pricing";
import { trackActivity } from "js/core/utilities/utilities";
import AppController from "legacy-js/core/AppController";
import { isPPTAddin } from "js/config";

export const openSalesCalendar = (analytics = {}) => {
    const userIsBasicInWorkspace = app.user.features.isFeatureEnabled(FeatureType.UPGRADE, AppController.workspaceId);
    analytics.plan = userIsBasicInWorkspace ? "basic" : app.user.analyticsPersonalPlan;
    trackActivity("Sales", "ShowCalendar", null, null, analytics);
    window.open("https://calendly.com/beautifulteam/demo-call-with-beautiful-ai-upgrade-to-team", "_blank");
};

export const openPricingPage = (personalPlan = "pro", analytics = null) => {
    const userIsBasicInWorkspace = AppController.user.features.isFeatureEnabled(FeatureType.UPGRADE, AppController.workspaceId);
    const currentPlan = userIsBasicInWorkspace ? "basic" : personalPlan;

    if (analytics) {
        if (!analytics.cta) {
            throw new Error("analytics.cta is required");
        }

        trackActivity("Upgrade", "ShowPricingPage", null, null, analytics);
    }

    // if the user is in the PPT addin, we need to show a dialog instead of opening a new tab
    if (isPPTAddin) {
        window.location = `/ppt-addin/choosePlan?hideBasic=true&emphasizePlan=pro&hasTakenTrial=${AppController.user.hasTakenTrial}&hasTakenTeamTrial=${AppController.user.hasTakenTeamTrial}`;
    } else {
        window.open(getPricingPageUrl(AppController.user.hasTakenTrial, AppController.user.has("hasTakenTeamTrial"), currentPlan), "_blank");
    }
};

export const openTeamUpgrade = (analytics = {}, callback) => {
    const userIsBasicInWorkspace = AppController.user.features.isFeatureEnabled(FeatureType.UPGRADE, AppController.workspaceId);
    analytics.plan = userIsBasicInWorkspace ? "basic" : AppController.user.analyticsPersonalPlan;
    trackActivity("Upgrade", "ShowTeamPricingPage", null, null, analytics);

    // if the user is in the PPT addin, we need to show a dialog instead of opening a new tab
    if (isPPTAddin) {
        window.location = `/ppt-addin/choosePlan?hideBasic=true&emphasizePlan=team&hasTakenTrial=${AppController.user.hasTakenTrial}&hasTakenTeamTrial=${AppController.user.hasTakenTeamTrial}`;
    } else {
        window.open("/team-upgrade", "_blank");
    }
};

export const openSuggestTemplateForm = () => {
    window.open(SUGGEST_TEMPLATE_FORM, "_blank");
};
