import React from "reactn";

import { getStaticUrl, isRenderer } from "legacy-js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { openTeamUpgrade } from "legacy-js/core/utilities/externalLinks";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import InputDialog from "legacy-js/react/components/Dialogs/InputDialog";
import { renderReactDialog } from "legacy-js/react/renderReactRoot";
import {
    PersonalRemoveBrandingDialog,
    PersonalUpgradePlanDialog,
    TeamUpgradePlanDialog,
    UpgradePlanDialogType
} from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import { $ } from "legacy-js/vendor";
import ConfirmationScaryDialog from "./ConfirmationScaryDialog";
import ErrorDialog from "./ErrorDialog";
import MessageDialog from "./MessageDialog";

export { ShowSnackBar } from "./SnackBar";

const logger = getLogger(LogGroup.DIALOGS);

class DialogManager {
    constructor() {
        this.openDialogs = [];
    }

    registerDialog(dialog) {
        if (!dialog) {
            logger.error("[DialogManager] registerDialog() dialog is undefined");
            return;
        }

        if (dialog.lockSlide && app.currentCanvas?.canvasController) {
            const canvasController = app.currentCanvas.canvasController;
            canvasController.lockSlideForCollaborators(60 * 60);
            canvasController.freezeCollaboratorsLock = true;
        }

        if (dialog.allowUndo === false && app.undoManager) {
            app.undoManager.enabled = false;
        }

        this.openDialogs.push(dialog);
    }

    unregisterDialog(dialog) {
        if (!dialog) {
            logger.error("[DialogManager] unregisterDialog() dialog is undefined");
            return;
        }

        if (dialog.lockSlide && app.currentCanvas?.canvasController) {
            const canvasController = app.currentCanvas.canvasController;
            canvasController.freezeCollaboratorsLock = false;
            canvasController.unlockSlideForCollaborators();
        }

        if (dialog.allowUndo === false && app.undoManager) {
            app.undoManager.enabled = true;
        }

        this.openDialogs = this.openDialogs.filter(d => d !== dialog);
    }
}

app.dialogManager = new DialogManager();

export { default as DialogContent } from "./DialogContent";

export { default as BeautifulDialog } from "./BeautifulDialog";

export const ShowDialog = (dialog, props = {}) => {
    if (isRenderer) {
        logger.warn("ShowDialog() renderer should not show dialogs");
        return;
    }
    return renderReactDialog(dialog, props);
};

export const ShowDialogAsync = (dialog, props = {}) => {
    if (isRenderer) {
        logger.warn("ShowDialogAsync() renderer should not show dialogs");
        return;
    }
    return new Promise(resolve => {
        renderReactDialog(dialog, {
            ...props,
            onClose: result => {
                props.onClose && props.onClose(result);
                resolve(result);
            }
        });
    });
};

export const ShowInputDialog = props => ShowDialogAsync(InputDialog, props);

export const ShowMessageDialog = props => ShowDialogAsync(MessageDialog, props);

export const ShowConfirmationDialog = props => ShowDialogAsync(ConfirmationScaryDialog, props);

export const ShowErrorDialog = props => ShowDialogAsync(ErrorDialog, props);

export const ShowWarningDialog = (props = { title: null, message: null, acceptCallback: null }) => {
    // Mitch: I don't love this but we need to remove any open dropdown menus when a warning
    //  is shown and it's tricky to do that within a callback on a control in a popupmenu
    $(".dropdown_menu").remove();

    return ShowErrorDialog({
        ...props,
        acceptOnClose: true
    });
};

export const ShowOfflineDialog = () => ShowErrorDialog({
    title: "Sorry!",
    message: "Your connection appears to be offline, and this functionality is only available while you are online."
});

export const ShowUnsupportedFileTypesDialog = () => ShowErrorDialog({
    title: "Sorry, we don't support that type of file",
    message: "Please select a JPEG, PNG, GIF, or SVG image file or a video file."
});

export const ShowConvertedToClassicDialog = () => ShowMessageDialog({
    title: <>
        <img width={40} src={getStaticUrl("/images/dialogs/convert-to-classic.svg")} />
        <div>You've removed the design guardrails by converting to Classic.</div>
    </>,
    optOutProperty: "showConvertedToClassicDialog",
    message: "You can revert to the Smart Slide in Version History.",
    align: "center"
});

export const ShowUpgradeDialog = ({ type, workspaceId, analytics, closeDialog = () => { } }) => {
    if (!type) {
        throw new Error("'type' is required");
    }
    if (!workspaceId) {
        throw new Error("'workspaceId' is required");
    }
    if (!analytics) {
        throw new Error("'analytics' is required");
    }

    let view;
    if (workspaceId === "personal") {
        switch (type) {
            case UpgradePlanDialogType.UPGRADE_PLAN:
                view = PersonalUpgradePlanDialog;
                break;
            case UpgradePlanDialogType.REMOVE_BRANDING:
                view = PersonalRemoveBrandingDialog;
                break;
            case UpgradePlanDialogType.UPGRADE_PLAN_TO_TEAM:
                openTeamUpgrade(analytics);
                return;
            case UpgradePlanDialogType.TEAM_NOOP:
                // we don't have a dialog for this case currently,
                // Add Slide has it's own CTA
                return;
        }
    } else {
        switch (type) {
            case UpgradePlanDialogType.UPGRADE_PLAN:
            default:
                view = TeamUpgradePlanDialog;
                break;
        }
    }

    trackActivity("Upgrade", "ShowDialog", null, null, analytics);

    ShowDialog(view, {
        workspaceId,
        analytics,
        onClose: result => closeDialog(result)
    });
};
