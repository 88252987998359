import { _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import { FeatureType } from "legacy-common/features";
import * as appSearch from "js/core/services/appSearch";
import { appVersion } from "legacy-js/config";
import { getCanvasBundle } from "legacy-js/canvas";
import AppController from "legacy-js/core/AppController";

import DataService from "./DataService";

interface SlideTemplatesDataServiceSearchQuery {
    templateId?: string,
    fullTextSearchQuery?: string,
    size?: number,
    minScore?: number,
    showHiddenTemplates?: boolean
}

interface SlideTemplatesDataServiceDataItem {
    id: string,
    icon: string,
    title: string,
    description: string,
    category: string,
    tags: string,
    hideTemplate: boolean,
    score: number
}

class SlideTemplatesDataService extends DataService {
    private slideTemplatesData: SlideTemplatesDataServiceDataItem[];

    protected async prepare(): Promise<void> {
        const { slideTemplates: { slideTemplates } } = await getCanvasBundle(appVersion);
        this.slideTemplatesData = Object.entries(slideTemplates)
            .map(([id, template]) => ({
                id,
                icon: (template as any).icon,
                title: (template as any).title,
                description: (template as any).description,
                category: (template as any).category,
                tags: (template as any).tags,
                hideTemplate: (template as any).hideTemplate ?? false,
                score: Number.POSITIVE_INFINITY
            }));
    }

    public async search(query: SlideTemplatesDataServiceSearchQuery): Promise<SlideTemplatesDataServiceDataItem[]> {
        await this.ensurePrepared();

        const {
            templateId,
            fullTextSearchQuery,
            size = SlideTemplatesDataService.SEARCH_QUERY_SIZE,
            minScore = SlideTemplatesDataService.SEARCH_QUERY_MIN_SCORE,
            showHiddenTemplates
        } = query;

        let searchResults: SlideTemplatesDataServiceDataItem[];
        if (fullTextSearchQuery) {
            const { results: appsearchResults } = await appSearch.search({
                workspaceId: "personal", // Force personal workspace
                searchEngine: appSearch.SearchEngine.TEAMPLATES,
                query: fullTextSearchQuery,
                // Always use max size for appsearch to mitigate the subsequent filtering by templates not being removed
                page: { size: SlideTemplatesDataService.SEARCH_QUERY_SIZE },
                filters: {
                    template: templateId ? [templateId] : undefined,
                    hide_template: !showHiddenTemplates ? ["false"] : undefined
                }
            });

            searchResults = appsearchResults
                // Filter by score
                .filter(result => result._meta.score >= minScore)
                // Map to gallery slides
                .map(({ id: { raw: templateId }, _meta: { score } }): SlideTemplatesDataServiceDataItem | null => {
                    const slideTemplate = this.slideTemplatesData.find(template => template.id === templateId);
                    if (!slideTemplate) {
                        return null;
                    }
                    // Not forgetting score
                    return { ..._.cloneDeep(slideTemplate), score };
                });
        } else {
            searchResults = _.cloneDeep(this.slideTemplatesData);
        }

        return searchResults
            // Remove non existing templates
            .filter(template => !!template)
            // Filter by template id (if requested, for appsearch request this would have been done already)
            .filter(template => !templateId || (template.id.toLowerCase() === templateId.toLowerCase()))
            // Filter out hidden templates (if needed, for appsearch request this would have been done already)
            .filter(template => showHiddenTemplates || !template.hideTemplate)
            // Don't include authoring templates if we're not flagged to do so
            .filter(template => (
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CLASSIC_SLIDES, AppController.workspaceId) ||
                template.id !== "authoring"
            ))
            // Sort by score desc
            .sort((a, b) => b.score - a.score)
            // Respect size
            .slice(0, size);
    }
}

export default SlideTemplatesDataService;
