// Used to figure out font weight by its face name, rough but works in most cases
const fontWeightMapping = {
    "extra thin": 100,
    "extrathin": 100,
    "ultra thin": 100,
    "ultrathin": 100,
    "thin": 100,

    "extra light": 200,
    "ultra light": 200,
    "extralight": 200,
    "ultralight": 200,

    "lite": 300,
    "light": 300,

    "regular": 400,
    "normal": 400,

    "medium": 500,
    "dark": 500,

    "semi bold": 600,
    "demi bold": 600,
    "semibold": 600,
    "demibold": 600,
    "demi": 600,

    "bold": 700,

    "extra bold": 800,
    "ultra bold": 800,
    "ultra": 800,

    "ultra black": 900,
    "extra black": 900,
    "black": 900,
    "heavy": 900,
    "fat": 900,
};

function getDefaultFontWeight(fontFamily) {
    const normalizedFontFamily = fontFamily.split(",")[0].trim().toLowerCase();
    for (const [weighName, weight] of Object.entries(fontWeightMapping)) {
        if (normalizedFontFamily.endsWith(` ${weighName}`)) {
            return weight;
        }
    }

    return 400;
}

module.exports = getDefaultFontWeight;
