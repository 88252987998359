import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getStroke } from "perfect-freehand";

const average = (a, b) => (a + b) / 2;

function getSvgPathFromStroke(points, closed = true) {
    const len = points.length;

    if (len < 4) {
        return ``;
    }

    let a = points[0];
    let b = points[1];
    const c = points[2];

    let result = `M${a[0].toFixed(2)},${a[1].toFixed(2)} Q${b[0].toFixed(
        2
    )},${b[1].toFixed(2)} ${average(b[0], c[0]).toFixed(2)},${average(
        b[1],
        c[1]
    ).toFixed(2)} T`;

    for (let i = 2, max = len - 1; i < max; i++) {
        a = points[i];
        b = points[i + 1];
        result += `${average(a[0], b[0]).toFixed(2)},${average(a[1], b[1]).toFixed(
            2
        )} `;
    }

    if (closed) {
        result += "Z";
    }

    return result;
}

const OverlayContainer = styled.canvas.attrs({
    style: {
        width: "100vw", height: "100vh",
    },
})`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    cursor: crosshair;
`;

const DrawingOverlay = ({
    strokeWidth = 8, strokeColor = "#FF4500", fadeTimeout = 5000,
}) => {
    const canvasRef = useRef(null);
    const [drawing, setDrawing] = useState(false);
    const polylinesRef = useRef([]);
    const [opacity, setOpacity] = React.useState(1);
    const [startFadeTimer, setStartFadeTimer] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(Date.now());

    const strokeOptions = {
        size: strokeWidth,
        smoothing: 0.5,
        thinning: 0.5,
        streamline: 0.5,
        easing: t => t,
        start: {
            taper: 10,
            cap: true,
        },
        end: {
            taper: 10,
            cap: true,
        },
        color: strokeColor
    };

    useEffect(() => {
        const handleResize = () => {
            const canvas = canvasRef.current;
            polylinesRef.current = [];
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        window.addEventListener("resize", handleResize);
        handleResize();
        return function cleanup() {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const startDrawing = event => {
        if (event.button !== 0) {
            return;
        }
        setDrawing(true);
        const x = event.clientX;
        const y = event.clientY;
        const polyline = { points: [{ x, y }] };
        polylinesRef.current.push(polyline);
        setOpacity(1);
        setStartFadeTimer(false);
    };

    const draw = event => {
        if (!drawing) return;

        const x = event.clientX;
        const y = event.clientY;
        const polyline = polylinesRef.current[polylinesRef.current.length - 1];
        polyline.points.push({ x, y });
    };

    const stopDrawing = () => {
        setDrawing(false);
        const polyline = polylinesRef.current[polylinesRef.current.length - 1];
        polyline.timeUpdated = Date.now();
        setOpacity(1);
        setLastUpdated(Date.now());
        setStartFadeTimer(true);
    };

    const drawPolyline = (polyline, context) => {
        const { points } = polyline;

        const outlinePoints = getStroke(points, strokeOptions);
        const pathData = getSvgPathFromStroke(outlinePoints);
        const polylinePath = new Path2D(pathData);
        context.globalAlpha = opacity;
        context.fillStyle = strokeColor;
        context.fill(polylinePath);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const fadeInterval = setInterval(() => {
            context.clearRect(0, 0, canvas.width, canvas.height);
            let timeElapsed = 0;
            if (startFadeTimer) {
                timeElapsed = Date.now() - lastUpdated;
            }

            if (timeElapsed >= fadeTimeout) {
                setOpacity(prevState=>prevState - 0.02); // set speed of fade out

                if (opacity <= 0) {
                    setStartFadeTimer(false);
                    polylinesRef.current = [];
                } else {
                    polylinesRef.current.forEach((polyline, index) => {
                        drawPolyline(polyline, context);
                    });
                }
            } else {
                polylinesRef.current.forEach((polyline, index) => {
                    drawPolyline(polyline, context);
                });
            }
        }, 15);
        return () => clearInterval(fadeInterval);
    }, [startFadeTimer, fadeTimeout, opacity]);

    return (<OverlayContainer
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseUp={stopDrawing}
        onMouseMove={draw}
        onClick={event => event.stopPropagation()}
    />);
};

export default DrawingOverlay;
