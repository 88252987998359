import React, { Component } from "react";
import { Select } from "@material-ui/core";
import styled from "styled-components";

const StyledSelect = styled(Select)`
    .MuiSelect-root {
        background: white;
    }
    .MuiSelect-select {
        text-transform: capitalize;
        padding: 6px 32px 6px 12px;
        font-size: 13px;
        
        .tooltip-badge {
            display: none;
        }
    }
`;

export class Dropdown extends Component {
    render() {
        const { onChange, className } = this.props;
        return (
            <StyledSelect {...this.props} variant="outlined" onChange={event => onChange(event.target.value)} className={className}>
                {this.props.children}
            </StyledSelect>
        );
    }
}

