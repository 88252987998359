import * as geom from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";
import React from "reactn";
import { ElementTextBlockPositionType, HeaderPositionType, ShowFooterType, TrayType } from "legacy-common/constants";
import { app } from "js/namespaces";
import getLogger, { LogGroup } from "js/core/logger";

import { CanvasBackground } from "./CanvasBackground";
import { Header } from "../elements/Header";
import { Footer } from "../elements/Footer";
import { TrayContainer } from "./TrayContainer";
import { ElementAttribution, ElementTextBlock } from "../elements/CanvasTextBoxes";
import { AnnotationLayer } from "../elements/AnnotationLayer";
import { BaseElement } from "./BaseElement";

const logger = getLogger(LogGroup.ELEMENTS);

export class CanvasElement extends BaseElement {
    constructor(props) {
        super(props);

        this.refFilters = React.createRef();
    }

    get isSelected() {
        return true;
    }

    getSelectableParent() {
        return this;
    }

    get alwaysRefreshStyles() {
        return false;
    }

    get headerPosition() {
        if (this.template.allowHeader) {
            return this.model.layout.headerPosition || HeaderPositionType.TOP;
        } else {
            return HeaderPositionType.NONE;
        }
    }

    get headerWidth() {
        return this.model.layout.headerWidth || 400;
    }

    get canShowFooter() {
        return this.template.allowFooter && this.trayLayout != TrayType.BOTTOM_TRAY && this.model.layout.elementTextBlockPosition != ElementTextBlockPositionType.TRAY;
    }

    get showFooter() {
        if (!this.canShowFooter) return false;

        // if the layout showFooter was set, always show the footer
        if (this.model.layout.showFooter == ShowFooterType.ON) {
            return true;
        } else if (this.model.layout.showFooter == ShowFooterType.OFF) {
            return false;
        } else {
            // otherwise use the theme's showFooterByDefault property UNLESS the template is set to preventDefaultFooter
            return app.currentTheme.get("showFooterByDefault") == true && this.template.preventDefaultFooter == false;
        }
    }

    get elementTextBlockPosition() {
        return this.model.layout.elementTextBlockPosition || ElementTextBlockPositionType.NONE;
    }

    get showAttribution() {
        return Boolean(this.model.layout.showElementAttribution);
    }

    get trayLayout() {
        try {
            if (!this.model.layout) {
                logger.warn("[CanvasElement] this.model.layout missing", { slideId: this.canvas.dataModel?.id });
                throw new Error("Cannot open presentation due to an error. Please refresh your browser.");
            }
            let trayLayout = this.model.layout.trayLayout || this.template.defaultTrayLayout || TrayType.NONE;
            if (trayLayout == TrayType.NONE || this.template.availableTrayLayouts.contains(trayLayout)) {
                return trayLayout;
            } else {
                logger.warn("[CanvasElement] Invalid tray layout defined for this slide - " + trayLayout + " is not permitted for a " + this.template.constructor.id + " template.", { slideId: this.canvas.dataModel?.id });
                this.model.layout.trayLayout = TrayType.NONE;
                return TrayType.NONE;
            }
        } catch (err) {
            logger.error(err, "trayLayout() failed", { slideId: this.canvas.dataModel?.id });
            return TrayType.NONE;
        }
    }

    get maxTrayWidth() {
        if (this.elements.primary) {
            return this.canvas.CANVAS_WIDTH - this.elements.primary.minWidth;
        } else {
            return this.canvas.CANVAS_WIDTH / 2;
        }
    }

    get minTrayWidth() {
        return 200;
    }

    get maxTrayHeight() {
        if (this.elements.primary) {
            return this.canvas.CANVAS_HEIGHT - this.elements.primary.minHeight;
        } else {
            return this.canvas.CANVAS_HEIGHT / 2;
        }
    }

    get minTrayHeight() {
        return 200;
    }

    refreshElement(transition) {
        // We don't want to reach the top of the tree for this function, in this case you should
        // call canvas.refreshCanvas() instead.
        throw new Error("Can not refresh the whole canvas, use canvas.refreshCanvas() insted");
    }

    get canRefreshElement() {
        return false;
    }

    get childrenLayers() {
        return {
            background: 0,
            header: this.overlay ? 9 : 1,
            tray: 2,
            primary: 3,
            footer: 4,
            elementTextBlock: 5,
            elementAttribution: 6,
            annotations: 7,
            overlay: 8
        };
    }

    _build() {
        this.background = this.addElement("background", () => CanvasBackground, {
            model: {
                backgroundColor: this.model.layout.backgroundColor,
                backgroundStyle: this.model.layout.backgroundStyle
            }
        });

        if (this.headerPosition != HeaderPositionType.NONE) {
            if (!this.model.elements.header) {
                this.model.elements.header = {};
            }
            this.header = this.addElement("header", () => Header, { model: this.model.elements.header });
        }

        if (!this.model.elements.primary) {
            this.model.elements.primary = {};
        }
        this.primary = this.addElement("primary", () => this.canvas.elementManager.get(this.template.elementType), { model: this.model.elements.primary });

        if (this.showFooter) {
            if (!this.model.elements.footer) {
                this.model.elements.footer = {};
            }
            this.footer = this.addElement("footer", () => Footer, { model: this.model.elements.footer });
        }

        if (this.elementTextBlockPosition !== ElementTextBlockPositionType.NONE) {
            if (!this.model.elements.elementTextBlock) {
                this.model.elements.elementTextBlock = {};
            }
            this.elementTextBlock = this.addElement("elementTextBlock", () => ElementTextBlock, { model: this.model.elements.elementTextBlock });
        }

        if (this.trayLayout !== TrayType.NONE) {
            if (!this.model.elements.tray) {
                this.model.elements.tray = {};
            }
            this.tray = this.addElement("tray", () => TrayContainer, { model: this.model.elements.tray });
        }

        if (this.showAttribution) {
            if (!this.model.elements.elementAttribution) {
                this.model.elements.elementAttribution = {};
            }
            this.elementAttribution = this.addElement("elementAttribution", () => ElementAttribution, { model: this.model.elements.elementAttribution });
        }

        if (this.background.backgroundOverlay) {
            this.overlay = this.addElement("overlay", () => this.background.backgroundOverlay);
        }

        if (!this.model.elements.annotations) {
            this.model.elements.annotations = {};
        }
        this.annotations = this.addElement("annotations", () => AnnotationLayer, { model: this.model.elements.annotations });
    }

    _calcProps(props, options) {
        let { size } = props;

        let canvasBounds = new geom.Rect(0, 0, size);
        let contentBounds = new geom.Rect(0, 0, size);

        let footerHeight = this.canvas.styleSheet.Footer.height;

        this.background.calcProps(canvasBounds.size);

        // let trayProps;
        if (this.trayLayout != TrayType.NONE) {
            contentBounds = this.calcTray(props, contentBounds, canvasBounds);
        }
        // layout.children.tray = trayProps;
        let bodyBounds = contentBounds.clone();

        let canvasMargin = this.primary.getCanvasMargins();
        if (this.showAttribution) {
            canvasMargin.bottom = 20;
        }

        // // remove element minimum heights from availableSecondaryHeight to see if they'll fit
        let availableSecondaryHeight = bodyBounds.height - this.elements.primary.minHeight;
        if (this.showAttribution) {
            availableSecondaryHeight -= this.elements.elementAttribution.minHeight;
        }
        if (this.elementTextBlockPosition !== ElementTextBlockPositionType.NONE) {
            availableSecondaryHeight -= this.elements.elementTextBlock.minHeight;
        }

        if (this.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
            bodyBounds = this.calcTextBlock(props, contentBounds, bodyBounds, availableSecondaryHeight);
        }

        bodyBounds = this.calcHeader(props, contentBounds, bodyBounds, canvasMargin, canvasBounds);

        if (this.showFooter) {
            bodyBounds = this.calcFooter(props, bodyBounds, footerHeight, canvasBounds);
        }

        let primaryBounds = bodyBounds.deflate(canvasMargin);

        if (availableSecondaryHeight < 0) {
            logger.warn("[CanvasElement] not enough space for secondary items", { availableSecondaryHeight, slideId: this.canvas.slide.id });
        }

        // if everything will fit - reset availableSecondaryHeight
        availableSecondaryHeight = primaryBounds.height - this.elements.primary.minHeight;

        // layout attribution
        if (this.showAttribution) {
            ({
                primaryBounds,
                availableSecondaryHeight
            } = this.calcAttribution(props, primaryBounds, availableSecondaryHeight));
        }

        if (this.elementTextBlockPosition == ElementTextBlockPositionType.INLINE) {
            primaryBounds = this.calcTextBlock(props, contentBounds, primaryBounds, availableSecondaryHeight);
        }

        if (this.trayLayout != TrayType.NONE) {
            primaryBounds = this.calcInlineTray(props, primaryBounds, contentBounds);
        }

        primaryBounds.top = Math.round(primaryBounds.top);
        primaryBounds.height = Math.round(primaryBounds.height);

        let primaryProps = this.primary.calcProps(primaryBounds.size, { isTryingLayout: options.isTryingLayout });
        primaryProps.bounds = primaryBounds;

        let annotationsProps = this.annotations.calcProps(canvasBounds.size);
        annotationsProps.bounds = canvasBounds;

        if (this.overlay) {
            let overlayProps = this.overlay.calcProps(canvasBounds.size);
            overlayProps.bounds = canvasBounds;
        }

        // Assigning correct layers to the children
        Object.entries(this.elements).forEach(([id, element]) => {
            element.calculatedProps.layer = this.childrenLayers[id];
        });

        return { size };
    }

    calcHeader(props, contentBounds, bodyBounds, canvasMargin, canvasBounds) {
        let { children } = props;

        let headerProps;
        switch (this.headerPosition) {
            case HeaderPositionType.TOP:
                // calculate how much space the header is allowed to take based on certain layout parameters:
                let maxHeaderHeight = canvasBounds.height - this.elements.primary.minHeight;
                if (this.showFooter) {
                    maxHeaderHeight -= this.canvas.styleSheet.Footer.spacing;
                }
                if (this.elementTextBlockPosition !== ElementTextBlockPositionType.NONE) {
                    maxHeaderHeight -= this.elements.elementTextBlock.minHeight;
                }
                if (this.headerPosition === HeaderPositionType.TOP) {
                    canvasMargin.top = 0;
                }
                maxHeaderHeight -= canvasMargin.top + canvasMargin.bottom;

                headerProps = this.header.calcProps(new geom.Size(contentBounds.width, maxHeaderHeight), {});
                headerProps.bounds = new geom.Rect(contentBounds.left, contentBounds.top, contentBounds.width, headerProps.size.height);

                bodyBounds = bodyBounds.deflate({ top: headerProps.size.height });
                break;
            case HeaderPositionType.LEFT:
                let headerHeight = contentBounds.height;
                if (this.elementTextBlockPosition === ElementTextBlockPositionType.TRAY) {
                    // headerHeight -= this.elements.elementTextBlock.minHeight;
                    headerHeight -= this.elements.elementTextBlock.bounds.height;
                }

                headerProps = this.header.calcProps(new geom.Size(this.headerWidth, headerHeight), {});
                headerProps.bounds = new geom.Rect(contentBounds.left, headerHeight / 2 - headerProps.size.height / 2, headerProps.size);

                bodyBounds = bodyBounds.deflate({ left: headerProps.size.width });
                break;
        }
        return bodyBounds;
    }

    calcFooter(props, bodyBounds, footerHeight, canvasBounds) {
        let { children } = props;

        let footerBounds = new geom.Rect(0, canvasBounds.height - footerHeight, canvasBounds.width, footerHeight);

        let footerProps = this.footer.calcProps(footerBounds.size);
        footerProps.bounds = footerBounds;

        if (this.elements.primary.reserveFooterSpace) {
            bodyBounds = bodyBounds.deflate({ bottom: this.canvas.styleSheet.Footer.spacing });
        } else {
            //TODO
            // // this is a bit hacky to make sure the footer is over the primary element here
            // // we need to set the layer for export support but because we aren't sorting root elements by layer
            // // we also explicitly move the footer svg to front...
            // this.elements.footer.layer = 100;
            // this.elements.footer.svg.front();
        }

        return bodyBounds;
    }

    calcTray(props, contentBounds, canvasBounds) {
        let { children } = props;

        let border = 0;
        if (app.currentTheme.get("styleBackground") == "border") {
            border = this.canvas.styleSheet.$decorationStrokeWidth * 2;
        }

        let trayProps;
        switch (this.trayLayout) {
            case TrayType.NONE:
                break;
            case TrayType.LEFT_TRAY:
                trayProps = this.tray.calcProps(contentBounds.deflate(border).size);
                trayProps.bounds = new geom.Rect(border, border, trayProps.size);
                contentBounds = new geom.Rect(trayProps.size.width + this.canvas.styleSheet.TrayContainer.sideTrayEdgeMargin, 0, canvasBounds.width - trayProps.size.width - this.canvas.styleSheet.TrayContainer.sideTrayEdgeMargin, canvasBounds.height);
                break;
            case TrayType.BACKGROUND:
                trayProps = this.tray.calcProps(contentBounds.deflate(border).size);
                trayProps.bounds = new geom.Rect(border, border, trayProps.size);
                break;
            case TrayType.RIGHT_TRAY:
                // default:
                trayProps = this.tray.calcProps(contentBounds.deflate(border).size);
                trayProps.bounds = new geom.Rect(canvasBounds.width - trayProps.size.width - border, border, trayProps.size);
                contentBounds = new geom.Rect(0, 0, canvasBounds.width - trayProps.size.width - this.canvas.styleSheet.TrayContainer.sideTrayEdgeMargin, canvasBounds.height);
                break;
        }
        return contentBounds;
    }

    calcTextBlock(props, contentBounds, primaryBounds, availableSecondaryHeight) {
        let { children } = props;

        let elementTextBlockProps;
        switch (this.elementTextBlockPosition) {
            case ElementTextBlockPositionType.TRAY:
                elementTextBlockProps = this.elementTextBlock.calcProps(new geom.Size(contentBounds.width, Math.max(availableSecondaryHeight, this.elements.elementTextBlock.minHeight)));
                elementTextBlockProps.bounds = new geom.Rect(contentBounds.left, contentBounds.bottom - elementTextBlockProps.size.height, contentBounds.width, elementTextBlockProps.size.height);
                primaryBounds = primaryBounds.deflate({ bottom: elementTextBlockProps.size.height });
                break;
            case ElementTextBlockPositionType.INLINE:
                if (this.headerPosition == HeaderPositionType.LEFT && this.header.hasBackground) {
                    this.elementTextBlock.styles.marginLeft = this.elementTextBlock.styles.marginRight = 0;
                    elementTextBlockProps = this.elementTextBlock.calcProps(new geom.Size(primaryBounds.width, Math.max(availableSecondaryHeight, this.elements.elementTextBlock.minHeight)));
                    elementTextBlockProps.bounds = new geom.Rect(primaryBounds.left, primaryBounds.bottom - elementTextBlockProps.size.height, primaryBounds.width, elementTextBlockProps.size.height);
                } else {
                    elementTextBlockProps = this.elementTextBlock.calcProps(new geom.Size(contentBounds.width, Math.max(availableSecondaryHeight, this.elements.elementTextBlock.minHeight)));
                    elementTextBlockProps.bounds = new geom.Rect(contentBounds.left, primaryBounds.bottom - elementTextBlockProps.size.height, contentBounds.width, elementTextBlockProps.size.height);
                }
                primaryBounds = primaryBounds.deflate({ bottom: elementTextBlockProps.size.height });
                break;
        }

        return primaryBounds;
    }

    calcAttribution(props, primaryBounds, availableSecondaryHeight) {
        let { children } = props;

        if (this.showAttribution) {
            // Ensure that the side margin is always 50.
            let canvasMargin = this.primary.getCanvasMargins();
            const attributionBounds = primaryBounds.deflate({
                left: Math.max(50 - canvasMargin.left, 0),
                right: Math.max(50 - canvasMargin.right, 0),
            });

            let attributionWidth = attributionBounds.width;
            if (this.canvas.showBrandingWatermark()) {
                attributionWidth -= 140;
            }

            let attributionProps = this.elementAttribution.calcProps(new geom.Size(attributionWidth, Math.max(availableSecondaryHeight, this.elements.elementAttribution.minHeight)));
            attributionProps.bounds = new geom.Rect(attributionBounds.left, attributionBounds.bottom - attributionProps.size.height, attributionWidth, attributionProps.size.height);

            primaryBounds = primaryBounds.deflate({ bottom: attributionProps.size.height });
            availableSecondaryHeight -= attributionProps.size.height;
        }

        return {
            primaryBounds,
            availableSecondaryHeight,
        };
    }

    calcInlineTray(props, primaryBounds, contentBounds) {
        let { children } = props;

        let trayProps;

        switch (this.trayLayout) {
            case TrayType.LEFT_INLINE:
                trayProps = this.tray.calcProps(new geom.Size(contentBounds.width, primaryBounds.height));
                trayProps.bounds = new geom.Rect(primaryBounds.left, primaryBounds.top, trayProps.size.width, primaryBounds.height);
                primaryBounds = primaryBounds.deflate({ left: trayProps.size.width + this.canvas.styleSheet.TrayContainer.inlineTrayEdgeMargin });
                break;
            case TrayType.RIGHT_INLINE:
                trayProps = this.tray.calcProps(new geom.Size(contentBounds.width, primaryBounds.height));
                trayProps.bounds = new geom.Rect(primaryBounds.right - trayProps.size.width, primaryBounds.top, trayProps.size.width, primaryBounds.height);
                primaryBounds = primaryBounds.deflate({ right: trayProps.size.width + this.canvas.styleSheet.TrayContainer.inlineTrayEdgeMargin });
                break;
        }
        return primaryBounds;
    }
}
