import React from "react";

import { ds } from "js/core/models/dataService";
import { $, Backbone, _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import { isSafari } from "js/core/utilities/browser";
import { controls } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { HorizontalAlignType, CanvasEventType, PositionType, TextEditorEvent } from "legacy-common/constants";
import { Key, isOSXEndKeyCombination, isOSXHomeKeyCombination } from "js/core/utilities/keys";
import LoremIpsum from "js/core/utilities/loremIpsum";
import { getStaticUrl, isPPTAddin } from "legacy-js/config";
import { renderReactDialog } from "legacy-js/react/renderReactRoot";
import { TextSizePopupMenu } from "legacy-js/react/views/Editor/TextSizePopupMenu";
import { SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { getValueOrDefault } from "js/core/utilities/extensions";
import { ShowDialog, ShowWarningDialog, ShowDialogAsync } from "legacy-js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "legacy-js/react/components/Dialogs/BadFitDialog";
import { isFirefox } from "js/core/utilities/browser";
import EditLinkDialog from "legacy-js/react/components/Dialogs/EditLinkDialog";
import SvgTextModel from "legacy-common/utils/SvgTextModel";
import { ClipboardType, clipboardRead, clipboardWrite } from "js/core/utilities/clipboard";
import getLogger, { LogGroup } from "js/core/logger";

import { ContentBlockCollection } from "../../elements/elements/ContentBlock";
import { TextGroup } from "../../elements/base/TextGroup";

import { ElementDefaultOverlay, ElementRollover, ElementSelection } from "../BaseElementEditor";

const logger = getLogger(LogGroup.ELEMENTS);

const TextElementDefaultOverlay = ElementDefaultOverlay.extend({
    render() {
        let $stripes = $.div("");
        $stripes.css({
            width: "100%",
            height: "100%",
            background: "repeating-linear-gradient(-45deg, #D3E9F6, #D3E9F6 10px, #50bbe6 10px, #50bbe6 20px)",
            opacity: 0.5
        });
        this.$el.append($stripes);

        let $warning = $.div("text-clip-warning", "TEXT IS CLIPPED");
        this.$el.append($warning);

        return this;
    }
});

const TextElementRollover = ElementRollover.extend({
    captureMouseEvents: false,

    getCursor: function() {
        return "text";
    },
});

const TextElementSelection = ElementSelection.extend({
    showSelectionBox: true,

    getOffset() {
        return "top";
    },

    get inputFilter() {
        return null;
    },

    renderControls: function() {
        this.setupTextEditor();
    },

    setupTextEditor: function() {
        if (this.textEditor) {
            this.textEditor.remove();
        }

        app.isEditingText = true;

        if (this.element.canEdit) {
            this.textEditor = new TextEditor(this.element, this.inputFilter);
            this.renderStyleBar();
            this.textEditor.on(TextEditorEvent.SELECTION_CHANGED, _.throttle(selection => {
                this.renderStyleBar(selection);
            }, 50));
        }

        if (this.element.options.canDelete) {
            this.addDeleteButton(() => {
                this.element.trigger("deleteButtonClicked");
            });
        }
    },

    cleanUp: function() {
        app.isEditingText = false;
        if (this.textEditor) {
            this.element.removeRenderUIFunction(this.textEditor.renderTextSelection);
            this.textEditor.remove();
            this.textEditor = null;
        }

        this.element.canvas.layouter.refreshRender();

        $(document).off(".typing");
    },

    onContextMenu: function(event) {
        this.textEditor.onContextMenu(event);
    },

    onMouseDown: function(event, initialClick, doubleClickToSelect) {
        if (this.element.canEdit) {
            if (initialClick && this.element.options.selectAllOnEdit) {
                this.textEditor.selectAll();
            } else {
                this.textEditor.onMouseDown(event, initialClick, doubleClickToSelect);
            }
        }
    },

    renderStyleBar: function(selection = { start: 0, end: 0 }) {
        if (this.$styleBar) {
            this.$styleBar.remove();
        }

        if (!this.element.allowStyling && !this.element.allowAlignment) {
            return;
        }

        if (!this.element.showStyleBar) {
            return;
        }

        if (!this.textEditor) {
            return;
        }

        this.$styleBar = $.div("text-style-bar widget_bar");

        let style;
        if (selection.start >= this.textEditor.styleMap.length) {
            if (this.textEditor.styleMap.length) {
                style = _.last(this.textEditor.styleMap);
            } else {
                style = {
                    bold: false,
                    italic: false,
                    link: null
                };
            }
        } else {
            style = this.textEditor.styleMap[selection.start];
        }

        if (this.element.allowedTextStyles.length > 1) {
            this.$styleBar.addEl(controls.createPopupButton(this, {
                label: this.element.model[this.element.textStylePropertyName],
                menuClass: "icon-menu fourcol",
                model: this.element.model,
                markStylesAsDirty: true,
                property: this.element.textStylePropertyName,
                transitionModel: false,
                items: _.map(this.element.allowedTextStyles, style => {
                    return {
                        value: style,
                        label: style,
                        image: getStaticUrl(`/images/ui/contentblocktypes/${style}.svg`)
                    };
                }),
            }, null, () => this.renderStyleBar(selection)));
        }

        if (this.element.allowStyling) {
            this.$styleBar.addEl(controls.createButtonToggle(this, {
                icon: "format_bold",
                value: style.bold,
                callback: value => {
                    this.textEditor.toggleBold();
                }
            }));

            if (this.element.hasItalicFont) {
                this.$styleBar.addEl(controls.createButtonToggle(this, {
                    icon: "format_italic",
                    value: style.italic,
                    callback: value => {
                        this.textEditor.toggleItalic();
                    }
                }));
            }

            // check if over a link
            const activeLink = this.textEditor.getLinkAtCursor();
            const hasActiveLink = !!activeLink;
            const hasSelection = selection.start != selection.end;

            this.$styleBar.addEl(controls.createButtonToggle(this, {
                icon: "link",
                value: hasActiveLink ? style.link : "",
                enabled: hasSelection || hasActiveLink,
                callback: () => {
                    if (hasActiveLink) {
                        this.textEditor.editLink(activeLink);
                    } else {
                        this.textEditor.editLink();
                    }
                }
            }));

            if (this.element.allowUserColor) {
                this.$styleBar.addEl(controls.createColorPalettePicker(this, {
                    selectedColor: style.color ?? "auto",
                    includeAuto: true,
                    autoLabel: "AUTO",
                    showBackgroundColors: true,
                    getAutoColor: () => {
                        if (style.bold) {
                            return this.element.boldFontColor;
                        } else {
                            return this.element.fontColor;
                        }
                    },
                    getBackgroundColor: () => {
                        return this.element.getParentBackgroundColor(this.element);
                    },
                    callback: value => {
                        this.textEditor.setColor(value);
                    }
                }));
            }

            if (this.element.allowUserScale) {
                const SCALE_DELTA = 0.05;

                this.$styleBar.addEl($.div("divider"));

                let $userScaleLabel = this.$styleBar.addEl($.div("user-scale-label"));
                let refreshUserScaleLabel = () => {
                    $userScaleLabel.text(Math.round(this.element.userFontScale * 100) + "%");
                };
                refreshUserScaleLabel();

                $userScaleLabel.on("mousedown", () => {
                    this.textEditor && this.textEditor.clearSelection();
                    this.selectionLayer.hideWidgets($(".text-style-bar, .text-style-bar > div"));

                    let isLayoutNotFitWarningDisplayed = false;
                    renderReactDialog(TextSizePopupMenu, {
                        target: $userScaleLabel,
                        element: this.element,
                        onUpdate: value => {
                            // Ignoring updates when the warning is displayed
                            if (isLayoutNotFitWarningDisplayed) {
                                return;
                            }

                            const previousValue = this.element.userFontScale;
                            this.element.userFontScale = value;
                            this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true })
                                .catch(err => {
                                    isLayoutNotFitWarningDisplayed = true;
                                    ShowDialogAsync(BadFitDialog, {
                                        title: "Sorry, we aren't able to fit all elements into the new layout",
                                        acceptCallback: () => this.isLayoutNotFitWarningDisplayed = false,
                                    });
                                    // Reverting the scale and refreshing canvas again
                                    this.element.userFontScale = previousValue;
                                    return this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
                                })
                                .finally(() => refreshUserScaleLabel());
                        },
                        onComplete: () => {
                            this.element.canvas.saveCanvasModel();
                        },
                        onClose: () => {
                            this.element.canvas.updateCanvasModel(false).then(() => {
                                this.textEditor.layout();
                                this.textEditor.drawSelection();
                                this.selectionLayer.showWidgets();
                            });
                        },
                    });
                });
            }
        }

        if (this.element.options.allowParagraphStyles) {
            this.$styleBar.addEl($.div("divider"));
            this.$styleBar.addEl(controls.createButtonToggle(this, {
                icon: "notes",
                value: this.element.paragraphStyle == "paragraph",
                callback: value => {
                    this.element.model.text_format = "paragraph";
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        this.textEditor.layout();
                        this.textEditor.drawSelection();
                        this.renderStyleBar(selection);
                    });
                }
            }));

            this.$styleBar.addEl(controls.createButtonToggle(this, {
                icon: "format_list_bulleted",
                value: this.element.paragraphStyle == "bullet_list",
                callback: value => {
                    this.element.model.text_format = "bullet_list";
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        this.textEditor.layout();
                        this.textEditor.drawSelection();
                        this.renderStyleBar(selection);
                    });
                }
            }));

            this.$styleBar.addEl(controls.createButtonToggle(this, {
                icon: "format_list_numbered",
                value: this.element.paragraphStyle == "numbered_list",
                callback: value => {
                    this.element.model.text_format = "numbered_list";
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        this.textEditor.layout();
                        this.textEditor.drawSelection();
                        this.renderStyleBar(selection);
                    });
                }
            }));
        }

        if (this.element.allowAlignment) {
            let textAlign;
            if (this.element.parentElement instanceof TextGroup) {
                textAlign = this.element.parentElement.model.textAlign;
            } else if (this.element.findClosestOfType(ContentBlockCollection)) {
                textAlign = this.element.findClosestOfType(ContentBlockCollection).model.textAlign;
            } else {
                textAlign = this.element.model.textAlign;
            }

            let setAlign = value => {
                if (this.element.parentElement instanceof TextGroup) {
                    this.element.parentElement.model.textAlign = value;
                } else if (this.element.findClosestOfType(ContentBlockCollection)) {
                    this.element.findClosestOfType(ContentBlockCollection).model.textAlign = value;
                } else {
                    this.element.model.textAlign = value;
                }
                this.element.canvas.refreshCanvasAutoRevert({ transition: true }).then(() => {
                    this.element.canvas.saveCanvasModel();
                    this.renderStyleBar(selection);
                    this.textEditor.layout();
                });
            };
            this.$styleBar.addEl($.div("divider"));
            this.$styleBar.addEl(controls.createButtonToggle(this, {
                icon: "format_align_left",
                value: textAlign == HorizontalAlignType.LEFT,
                callback: value => setAlign(HorizontalAlignType.LEFT)
            }));

            this.$styleBar.addEl(controls.createButtonToggle(this, {
                icon: "format_align_center",
                value: textAlign == HorizontalAlignType.CENTER,
                callback: value => setAlign(HorizontalAlignType.CENTER)
            }));

            this.$styleBar.addEl(controls.createButtonToggle(this, {
                icon: "format_align_right",
                value: textAlign == HorizontalAlignType.RIGHT,
                callback: value => setAlign(HorizontalAlignType.RIGHT)
            }));
        }

        // if (this.element instanceof ContentBlockTextElement) {
        //     let collectionElement = this.element.findClosestOfType(CollectionElement);
        //
        //     if (collectionElement && collectionElement.itemCount > 1) {
        //         this.$styleBar.addEl($.div("divider"));
        //         this.$styleBar.addEl(controls.createButton(this, {
        //             icon: "arrow_upward",
        //             enabled: this.element.parentElement.itemIndex > 0,
        //             callback: () => {
        //                 let index = this.element.parentElement.itemIndex;
        //                 collectionElement.deleteItem(this.element.parentElement.id);
        //                 collectionElement.addItem(this.element.parentElement.model, index - 1);
        //                 this.element.canvas.updateCanvasModel(true);
        //             }
        //         }));
        //         this.$styleBar.addEl(controls.createButton(this, {
        //             icon: "arrow_downward",
        //             enabled: this.element.parentElement.itemIndex < collectionElement.itemCount - 1,
        //             callback: () => {
        //                 let index = this.element.parentElement.itemIndex;
        //                 collectionElement.deleteItem(this.element.parentElement.id);
        //                 collectionElement.addItem(this.element.parentElement.model, index + 1);
        //                 this.element.canvas.updateCanvasModel(true);
        //             }
        //         }));
        //     }
        // }

        this.$el.addEl(this.$styleBar);
        this.layoutStyleBar();
    },

    layoutStyleBar: function() {
        if (this.$styleBar) {
            this.$styleBar.left(this.$el.width() / 2 - this.$styleBar.width() / 2).top(-this.$styleBar.height() - this.element.styleBarOffset);
        }
    },

    _layout: function() {
        this.layoutStyleBar();
    }

});

class TextEditor {
    static convertStyleMapToStyleRanges(styleMap, offset = 0) {
        // serialize the styleMap into grouped chunks for optimized storage
        let styles = [];
        let curStyle;
        for (let i = 0; i < styleMap.length; i++) {
            let map = styleMap[i];
            if (!curStyle || curStyle.bold != map.bold || curStyle.italic != map.italic || curStyle.link != map.link || curStyle.color != map.color) {
                if (curStyle) {
                    curStyle.end = i + offset;
                    styles.push(curStyle);
                }
                curStyle = {
                    bold: map.bold,
                    italic: map.italic,
                    link: map.link,
                    start: i + offset,
                    color: map.color
                };
            }
        }
        if (curStyle) {
            curStyle.end = styleMap.length + offset;
            styles.push(curStyle);
        }
        return styles;
    }

    get DBLCLICKTIMER() {
        return 500;
    }

    get hasSelection() {
        const selection = this.getSelection();
        return selection.start !== selection.end;
    }

    pasteText = "";
    clearInputTracking = false;

    constructor(element, inputFilter) {
        _.extend(this, Backbone.Events);

        this.id = _.uniqueId("texteditor");

        this.element = element;
        this.clickCount = 0;
        this.dirty = false;
        this.defaultStyleToBold = null;

        this.element.isEditingText = true;
        this.inputFilter = inputFilter;

        this.loadFromModel();

        this.lastGoodValue = this.element.model[this.element.bindTo] ? this.element.model[this.element.bindTo].text || "" : "";

        this.element.addRenderUIFunction(this.renderTextSelection);
        this.element.canvas.layouter.refreshRender();

        // create a hidden text input box to manage key events, IME input, and focus
        this.$textInput = $("body").addEl(
            $.input("text", "")
                .addClass("text-input")
                .attr("id", "text-input")
                .attr("autocomplete", "off")
        );

        let forceOverwrite = false;
        let inputCount = 0;
        this.$textInput.on("keydown", event => {
            if (event.which == Key.TAB) {
                event.stopPropagation();
                app.mainView.editorView.handleTabKey(event);
                return;
            }
            this.onKeyDown(event);

            if (!this.isTyping) {
                $(".small-control-list").opacity(0);
                this.isTyping = true;
            }
        });

        $(document).on("mousemove.typing", event => {
            if (this.isTyping) {
                this.isTyping = false;
                $(".small-control-list").opacity(1);
            }
        });

        this.$textInput.on("input", event => {
            if (this.clearInputTracking) {
                inputCount = 0;
                forceOverwrite = false;
                this.prevInputLength = 0;
                this.clearInputTracking = false;
            }
            const val = event.originalEvent.data;
            const overwritePrevPress = (
                val !== "'" &&
                (
                    forceOverwrite ||
                    inputCount > 0
                )
            );
            this.onValInput(event, val, overwritePrevPress);
            ++inputCount;
            forceOverwrite = false;
        });

        this.$textInput.on("keypress", event => {
            inputCount = 0;

            // If we the event is an enter key, handle it
            if (event.which === Key.ENTER) {
                this.onValInput(event, null, false);
            }
        });

        this.$textInput.on("compositionend", event => {
            inputCount = 0;

            // Firefox handles composition event lifecycles
            //   differently than chrome or safari
            if (isFirefox) {
                forceOverwrite = true;
            }
        });

        this.$textInput.on("blur", event => {
            if (event.relatedTarget) {
                const isContenEditable = event.relatedTarget.getAttribute("contenteditable") === "true";
                const isInput = ["input", "textarea"].includes(event.relatedTarget.tagName.toLowerCase());

                // should not refocus immediately
                if (isInput || isContenEditable) {
                    // for other input fields, try and return focus if possible
                    if (isInput) {
                        const tryReturnFocus = () => {
                            _.defer(() => this.$textInput.focus());
                            event.relatedTarget.removeEventListener("blur", tryReturnFocus);
                        };

                        event.relatedTarget.addEventListener("blur", tryReturnFocus);
                    }

                    // don't refocus immediately
                    return;
                }
            }

            if (ds.selection.element == this.element) {
                event.preventDefault();
                _.defer(() => this.$textInput.focus());
            }
        });

        this.$textInput.on("cut", event => {
            this.copyToClipboard(true);
            event.preventDefault();
        });
        this.$textInput.on("copy", event => {
            this.copyToClipboard(false);
            event.preventDefault();
        });
        this.$textInput.on("paste", event => {
            this.pasteFromClipboard(event);
            event.preventDefault();
        });

        // Always save the text state if we are refreshing from outside the text editor so that text is not lost
        this.listenTo(this.element.canvas, CanvasEventType.BEFORE_MODEL_CHANGE, options => {
            if (options.target !== this) {
                this.saveModel();
            }
        });

        _.defer(() => {
            this.$textInput.focus();
        });
    }

    loadFromModel() {
        this.text = this.element.textModel.text;
        // expand out the styles into per-character style properties used by the textEditor
        this.styleMap = [];
        for (let i = 0; i < this.text.length; i++) {
            let style;
            if (this.element.textModel.styles) {
                style = _.omit(_.find(this.element.textModel.styles, s => i >= s.start && i < s.end), ["start", "end"]);
            }
            if (!style) {
                style = { bold: false, italic: false, link: null };
            }

            this.styleMap.push(style);
        }
    }

    layout() {
        // turn off any svg filters on the text (like textStroke) which create visual artifacts while editing text
        // this.element.textContainer.svg.style("filter", "");

        // this.uiGroup.setRectangleBounds(new geom.Rect(this.element.styles.marginLeft + this.element.offsetX, this.element.styles.marginTop + this.element.offsetY, this.element.textBounds.size));
    }

    deferredSaveModel() {
        if (this.deferredSave) {
            return;
        }
        this.deferredSave = true;
        setTimeout(() => {
            this.deferredSave = false;
            this.saveModel();
        }, 5000); //save every 5 seconds if the data has not been saved.
    }

    saveModel() {
        if (!this.dirty || !this.element || !this.element.canvas) {
            return;
        }
        this.dirty = false;
        this.element.canvas.saveCanvasModel();
    }

    async updateModel(forceRefresh = false, forceRender = false) {
        this.dirty = true;

        const styles = TextEditor.convertStyleMapToStyleRanges(this.styleMap);

        // set the model
        if (this.text !== "") {
            this.element.model[this.element.bindTo] = {
                text: this.text,
                styles: styles
            };
        } else {
            this.element.model[this.element.bindTo] = { text: "" };
        }
        this.element.textModel = new SvgTextModel(this.element.textModelValue);

        let shouldRefresh = getValueOrDefault(forceRefresh || this.element.options.forceRefreshOnKeyPress, false);

        let forceRefreshCanvas = false;
        if (!this.element.hasAllGlyphs) {
            // Force refresh so the element can correcly load fallback fonts
            // upon the next _load() call
            shouldRefresh = true;
            forceRefreshCanvas = true;
        }

        // if the element is using autosizing, we have to refresh the canvas on each change
        if (!shouldRefresh) {
            if (this.element.calculatedProps) {
                if (this.element.calculatedProps.autoWidth || !this.element.isTextFit) {
                    shouldRefresh = true;
                } else {
                    // if the this.element isn't using autosizing width, we only need to refresh canvas if it's width has changed (or number of lines)
                    const oldElementSize = new geom.Size(this.element.calculatedProps.size.width, this.element.calculatedProps.textLayout.size.height);
                    const oldFontSize = this.element.calculatedProps.fontSize;

                    const recalcProps = this.element.recalcProps();
                    const newElementSize = new geom.Size(recalcProps.size.width, recalcProps.textLayout.size.height);

                    if (!oldElementSize.equals(newElementSize) || recalcProps.fontSize !== oldFontSize) {
                        shouldRefresh = true;
                    }
                }
            } else {
                shouldRefresh = true;
            }
        }

        if (shouldRefresh) {
            if (!forceRefreshCanvas && this.element.canRefreshElement) {
                this.element.refreshElement(false);
            } else {
                return this.element.canvas.refreshCanvas({ target: this, forceRender }).then(() => {
                    this.layout();
                    this.lastGoodValue = this.element.model[this.element.bindTo].text || "";
                }).catch(err => {
                    // revert to the last good value
                    this.element.model[this.element.bindTo].text = this.lastGoodValue || "";
                    this.text = this.element.model[this.element.bindTo].text || "";
                    // and refresh the canvas again to layout at the last good value model state
                    return this.element.canvas.refreshCanvasAutoRevert({ target: this }).then(() => {
                        this.layout();

                        // flash the ui to indicate that the user can't type anymore characters
                        let $warningUI = $("#selection_layer").addEl($.div("text-edit-warning"));
                        $warningUI.setBounds(this.element.selectionBounds.multiply(this.element.canvas.getScale()));
                        $warningUI.velocity("transition.fadeOut", {
                            onComplete: () => {
                                $warningUI.remove();
                            }
                        }
                        );
                    }).catch(() => {
                        this.text = this.element.model[this.element.bindTo].text || "";
                    });
                });
            }
        } else {
            this.lastGoodValue = this.element.model[this.element.bindTo].text || "";
        }
    }

    getContextMenu(text, style) {
        let menuItems = [];

        if (this.element.allowStyling) {
            menuItems.push(
                { label: "Cut", value: "cut", icon: "content_cut", enabled: this.hasSelection },
                { label: "Copy", value: "copy", icon: "content_copy", enabled: this.hasSelection },
                { label: "Paste", value: "paste", icon: "content_paste", enabled: !!this.pasteText && this.pasteText.length > 0 },
                { type: "divider" },
                { label: "Emphasize", value: "emphasize", icon: "format_bold", enabled: this.hasSelection },
                { label: "Italicize", value: "italicize", icon: "format_italic", enabled: this.hasSelection },
            );
            if (style && style.link) {
                menuItems.push(
                    { label: "Edit Link...", value: "add_hyperlink", icon: "insert_link" },
                    { label: "Remove Link", value: "remove_hyperlink", icon: "remove" },
                );
            } else {
                menuItems.push(
                    { label: "Link...", value: "add_hyperlink", icon: "insert_link", enabled: this.hasSelection }
                );
            }
        }

        return {
            def: {
                items: menuItems
            },
            callback: value => {
                switch (value) {
                    case "copy":
                        this.copyToClipboard(false);
                        break;
                    case "cut":
                        this.copyToClipboard(true);
                        break;
                    case "paste":
                        this.pasteFromClipboard();
                        break;
                    case "emphasize":
                        this.toggleBold();
                        break;
                    case "italicize":
                        this.toggleItalic();
                        break;
                    case "add_hyperlink":
                        this.editLink();
                        break;
                    case "remove_hyperlink":
                        this.removeLink();
                        break;
                    default:
                        // assumes all other values are spelling suggestions
                        //word.text = value;
                        this.insertString(value, this.selectionStart, this.selectionEnd);
                        this.updateModel().then(() => {
                            this.saveModel();
                            this.setSelection(this.selectionStart, this.selectionStart + value.length);
                        });
                        break;
                }
            }
        };
    }

    toggleStyle(style) {
        let { start, end } = this.getSelection();

        let word = this.getWordSelectionAtIndex(start) || this.getWordSelectionAtIndex(end);

        start = Math.min(start, word.start);
        end = Math.max(end, word.end);

        let isStyleSet;

        if (!this.styleMap.length) {
            this.defaultStyleToBold = true;
        } else if (start === end && start === this.styleMap.length) {
            isStyleSet = !(this.styleMap[start - 1][style]);
            this.defaultStyleToBold = isStyleSet;
        } else {
            isStyleSet = !(this.styleMap[start][style]);
            this.defaultStyleToBold = null;
        }

        for (let i = start; i < end; i++) {
            this.styleMap[i][style] = isStyleSet;
        }

        this.updateModel(true).then(() => {
            this.saveModel();
        });
    }

    toggleBold() {
        this.toggleStyle("bold");
    }

    toggleItalic() {
        this.toggleStyle("italic");
    }

    setColor(color) {
        let { start, end } = this.getSelection();

        let word = this.getWordSelectionAtIndex(start) || this.getWordSelectionAtIndex(end);

        start = Math.min(start, word.start);
        end = Math.max(end, word.end);

        for (let i = start; i < end; i++) {
            this.styleMap[i].color = color;
        }
        this.updateModel(true).then(() => {
            this.saveModel();
        });
    }

    editLink(range) {
        if (!range) {
            range = this.getSelection();
        }

        let { start, end } = range;
        let word = this.getWordSelectionAtIndex(start) || this.getWordSelectionAtIndex(end);

        start = Math.min(start, word.start);
        end = Math.max(end, word.end);

        let style = this.styleMap[start];

        if (style) {
            //this if is to remove focus from whatever canvas element the focus may be on
            if (ds.selection.element) {
                ds.selection.element = null;
            }

            ShowDialog(EditLinkDialog, {
                input: {
                    id: "link",
                    type: "text",
                    placeholder: "Type url...",
                    value: `${style.link || ""}`
                },

                removeCallback: () => {
                    this.removeLink();
                },

                acceptCallback: value => {
                    let url = value;
                    for (let i = start; i < end; i++) {
                        this.styleMap[i].link = url;
                    }

                    this.updateModel(true).then(() => {
                        this.saveModel();
                    });
                }
            });
        }
    }

    getLinkAtCursor() {
        let { start } = this.getSelection();
        let linkStart;
        let linkEnd;
        let link;

        // find the start of the link
        let index = 0;
        let count = Math.max(this.styleMap.length - start, start);
        while (count-- > 0) {
            const compareFront = this.styleMap[start - index]?.link;
            const compareBack = this.styleMap[start + index]?.link;

            // make sure the link that started the search
            // stays the same -- this will prevent issues
            // if two different links are side by side
            if (!link) {
                link = compareFront || compareBack;
            }

            // if there's still not a link, this it's likely this
            // isn't inside of link text
            if (!link) {
                return;
            }

            // if checking towards the front matches, keep track
            if (compareFront === link) {
                linkStart = start - index;
            }

            // if checking towards the back matches, keep track
            if (compareBack === link) {
                linkEnd = start + index;
            }

            index++;
        }

        // validate this captured something
        if (isNaN(linkStart) || isNaN(linkEnd)) {
            return;
        }

        // return the link range - add one for the last character to be included
        return { start: linkStart, end: linkEnd + 1 };
    }

    removeLink(range) {
        // try and detect the range
        if (!range) {
            range = this.getLinkAtCursor();
        }

        // still nothing? just give up
        if (!range) {
            return;
        }

        let { start, end } = range;

        let word = this.getWordSelectionAtIndex(start) || this.getWordSelectionAtIndex(end);

        start = Math.min(start, word.start);
        end = Math.max(end, word.end);

        for (let i = start; i < end; i++) {
            this.styleMap[i].link = null;
        }

        this.updateModel().then(() => {
            this.saveModel();
        });
    }

    onContextMenu(event) {
        if (!this.element.canEdit) return;

        const scale = this.element.canvas.getScale();

        const screenBounds = this.element.getScreenBounds();
        const selectionScreenBounds = this.element.selectionBounds.offset(this.element.canvas.$el.offset().left, this.element.canvas.$el.offset().top);

        if (!screenBounds.contains(event.clientX, event.clientY)) {
            if (!selectionScreenBounds.contains(event.clientX, event.clientY)) {
                return;
            }
        }

        const mouseX = (event.clientX - screenBounds.left) / scale;
        const mouseY = (event.clientY - screenBounds.top) / scale;

        // get the caret index that was clicked on
        const index = this.getIndexAtPoint(mouseX, mouseY);

        if (event.shiftKey || event.metaKey) return;

        event.stopPropagation();

        // select word before showing context menu
        let textSelection;
        const noSelection = this.getSelection().end - this.getSelection().start === 0;
        const selectionChanged = this.hasSelectionChanged(index);
        const hasWhitespace = /\s/g.test(this.text.slice(this.getSelection().start, this.getSelection().end));
        if (noSelection || (selectionChanged && !hasWhitespace)) {
            textSelection = this.getWordSelectionAtIndex(index);
        } else {
            textSelection = this.getSelection();
        }
        this.setSelection(textSelection.start, textSelection.end);
        const menu = this.getContextMenu(
            this.text.slice(textSelection.start, textSelection.end),
            this.styleMap[textSelection.start],
        );
        let onClose = () => { };
        controls._renderMenu(menu.def, () => onClose(), menu.callback)
            .then($menu => {
                onClose = () => {
                    $menu.clickShield(false);
                    $menu.remove();
                };

                $("body").append($menu);
                $menu.left(Math.min(event.pageX, window.innerWidth - $menu.width())).top(Math.min(event.pageY, window.innerHeight - $menu.height()));

                $menu.clickShield(() => {
                    $menu.clickShield(false);
                    $menu.remove();
                }, true);
            });
    }

    onMouseDown(event, initialClick, doubleClickToSelect) {
        if (!this.element.canEdit) return;

        const scale = this.element.canvas.getScale();

        const screenBounds = this.element.getScreenBounds();
        const selectionScreenBounds = this.element.selectionBounds.offset(this.element.canvas.$el.offset().left, this.element.canvas.$el.offset().top);

        if (!screenBounds.contains(event.clientX, event.clientY)) {
            if (!selectionScreenBounds.contains(event.clientX, event.clientY)) {
                return;
            }
        }

        const mouseX = (event.clientX - screenBounds.left) / scale;
        const mouseY = (event.clientY - screenBounds.top) / scale;

        // get the caret index that was clicked on
        const index = this.getIndexAtPoint(mouseX, mouseY);

        // right-click selects word before showing context menu
        if (event.button == 2) {
            if (event.shiftKey || event.metaKey) return;

            let textSelection;
            const noSelection = this.getSelection().end - this.getSelection().start === 0;
            const selectionChanged = this.hasSelectionChanged(index);
            const hasWhitespace = /\s/g.test(this.text.slice(this.getSelection().start, this.getSelection().end));
            if (noSelection || initialClick || (selectionChanged && !hasWhitespace)) {
                textSelection = this.getWordSelectionAtIndex(index);
            } else {
                textSelection = this.getSelection();
            }
            this.setSelection(textSelection.start, textSelection.end);
            const menu = this.getContextMenu(
                this.text.slice(textSelection.start, textSelection.end),
                this.styleMap[textSelection.start],
            );
            let onClose = () => { };
            controls._renderMenu(menu.def, () => onClose(), menu.callback)
                .then($menu => {
                    onClose = () => {
                        $menu.clickShield(false);
                        $menu.remove();
                    };

                    $("body").append($menu);
                    $menu.left(Math.min(event.pageX, window.innerWidth - $menu.width())).top(Math.min(event.pageY, window.innerHeight - $menu.height()));

                    $menu.clickShield(() => {
                        $menu.clickShield(false);
                        $menu.remove();
                    }, true);
                });
            return;
        }

        // track double/triple click on text
        const timestamp = new Date().getTime();
        if (timestamp < this.lastMouseDown + this.DBLCLICKTIMER && index == this.lastMouseIndex) {
            this.clickCount++;
        } else {
            this.clickCount = 1;
        }
        this.lastMouseDown = timestamp;

        this.showCursor = true; // make sure the cursor will be visible immediately

        let trackMouseMove = false;
        if (this.clickCount == 2) {
            // double-click to select word
            const wordSelection = this.getWordSelectionAtIndex(index);
            this.setSelection(wordSelection.start, wordSelection.end);
        } else if (this.clickCount >= 3) {
            // triple-click to select paragraph
            for (const p of this.getParagraphRanges()) {
                if (index >= p[0] && index <= p[1]) {
                    this.setSelection(p[0], p[1]);
                }
            }
        } else if (event.shiftKey && !initialClick && _.isNumber(this.selectionStart) && _.isNumber(this.selectionEnd)) {
            // shift + click
            if (index < this.selectionStart || index > this.selectionEnd) {
                this.setSelection(Math.min(index, this.selectionStart), Math.max(index, this.selectionEnd));
            } else {
                if (index - this.selectionStart < this.selectionEnd - index) {
                    this.setSelection(index, this.selectionEnd);
                } else {
                    this.setSelection(this.selectionStart, index);
                }
            }
        } else {
            // single click
            this.lastMouseIndex = index; //store the last index clicked on
            this.setSelection(index, index);
            trackMouseMove = true;
        }

        // listen for mousemove and mouseup to drag-select text
        if (!doubleClickToSelect && trackMouseMove) {
            $(window).on("mousemove.selectText", event => {
                app.isDraggingInText = true;
                event.stopPropagation();
                const mouseX = (event.clientX - screenBounds.left) / scale;
                const mouseY = (event.clientY - screenBounds.top) / scale;

                const index = this.getIndexAtPoint(Math.max(0, mouseX), Math.max(0, mouseY));

                // when double-clicking to select a word and then dragging, selection should extend to entire word chunks
                if (this.clickCount == 2) {
                    let wordSelection = this.getWordSelectionAtIndex(index);
                    if (index < this.selectionStart) {
                        this.setSelection(this.selectionStart, wordSelection.start);
                    } else {
                        this.setSelection(this.selectionStart, wordSelection.end);
                    }
                } else {
                    if (index < this.lastMouseIndex) {
                        // when dragging right to left
                        this.setSelection(index, this.lastMouseIndex);
                    } else {
                        // when dragging left to right
                        this.setSelection(this.lastMouseIndex, index);
                    }
                }
            });
            $(window).one("mouseup", event => {
                event.stopPropagation();
                app.isDraggingInText = false;
                $(window).off("mousemove.selectText");
            });
        }

        if (this.$textInput) {
            this.$textInput.focus();
        }

        this.saveModel();

        return false; // otherwise the textInput will lose focus
    }

    onKeyDown(event) {
        this.deferredSaveModel();
        let { which } = event;

        // check for unusual key scenarios
        if (isOSXEndKeyCombination(event)) {
            which = Key.END;
        } else if (isOSXHomeKeyCombination(event)) {
            which = Key.HOME;
        }

        switch (which) {
            case Key.KEY_Z:
                if (app.tour) {
                    return;
                }
                if (event.metaKey || event.ctrlKey) {
                    this.saveModel();
                    if (event.shiftKey) {
                        app.undoManager.redo();
                    } else {
                        app.undoManager.undo();
                    }
                    // reload the text so typing wont resurrect the state before undo/redo.
                    this.loadFromModel();
                    // Clamp the selection to the text length.
                    this.setSelection(Math.min(this.selectionStart, this.text.length), Math.min(this.selectionEnd, this.text.length));
                    event.stopPropagation();
                    event.preventDefault();
                    return;
                }
                break;
            case Key.KEY_A:
                //command-a
                if (event.metaKey || event.ctrlKey) {
                    this.selectAll();
                    this.saveModel();
                    return true;
                }
                break;
            case Key.KEY_B:
                //command-b
                if (event.metaKey || event.ctrlKey) {
                    this.element.allowStyling && this.toggleBold();
                    event.stopPropagation();
                    event.preventDefault();
                    return true;
                }
                break;
            case Key.KEY_I:
                //command-i
                if (event.metaKey || event.ctrlKey) {
                    this.element.allowStyling && this.toggleItalic();
                    event.stopPropagation();
                    event.preventDefault();
                    return true;
                }
                break;
            case Key.KEY_K:
                //command-k
                if (event.metaKey || event.ctrlKey) {
                    if (this.element.allowStyling) {
                        const link = this.getLinkAtCursor();
                        this.editLink(link);
                    }
                    event.stopPropagation();
                    event.preventDefault();
                    return true;
                }
                break;
            case Key.ESCAPE:
                this.saveModel();
                ds.selection.element = null;
                return true;
            case Key.ENTER: {
                const lastLine = _.last(this.element.textModel.paragraphs);
                let selectionRight = Math.max(this.selectionStart, this.selectionEnd);
                if (this.element.allowEmptyLines === false && selectionRight === this.text.length && lastLine.words.length === 0) {
                    this.trigger(TextEditorEvent.ENTER_ON_BLANK_LINE);
                    // We only open a group for the first enter hit, thus theres a chance there is no group created for
                    // elements that do not allow multiple lines,
                    if (app.undoManager.undoGroupIsOpen) {
                        app.undoManager.closeGroup();
                    }
                    event.stopPropagation();
                    event.preventDefault();
                    return false;
                } else {
                    if (this.element.singleLine && !(event.ctrlKey || event.altKey)) {
                        this.trigger(TextEditorEvent.ENTER_KEY, this, event);
                        event.stopPropagation();
                        event.preventDefault();
                        return false;
                    } else {
                        // do nothing and let the ENTER key be inserted normally
                    }
                }
                break;
            }

            case Key.DELETE:
                // delete
                this.clearInputTracking = true;
                if (this.text.length == 0) {
                    this.trigger("deleteKeyOnEmpty");
                } else {
                    if (this.selectionStart == this.selectionEnd && this.selectionStart < this.text.length) {
                        this.text = this.text.slice(0, this.selectionStart) + this.text.slice(this.selectionStart + 1);
                        this.styleMap = this.styleMap.slice(0, this.selectionStart).concat(this.styleMap.slice(this.selectionStart + 1));

                        this.updateModel().then(() => {
                            this.setSelection(this.selectionStart, this.selectionStart);
                        });
                    } else {
                        // delete trailing space when deleting a word
                        if ((this.selectionStart == 0 || this.text[this.selectionStart - 1] == " ") && this.text[this.selectionEnd] == " ") {
                            this.selectionEnd += 1;
                        }
                        // delete leading space when deleting a word followed by puncuation
                        if ((this.selectionStart > 0 && this.text[this.selectionStart - 1] == " ") && SvgTextModel.breakWordRegex.test(this.text[this.selectionEnd])) {
                            this.selectionStart -= 1;
                        }

                        this.text = this.text.slice(0, Math.min(this.selectionStart, this.selectionEnd)) + this.text.slice(Math.max(this.selectionStart, this.selectionEnd));
                        this.styleMap = this.styleMap.slice(0, Math.min(this.selectionStart, this.selectionEnd)).concat(this.styleMap.slice(Math.max(this.selectionStart, this.selectionEnd)));

                        this.updateModel().then(() => {
                            this.setSelection(Math.min(this.selectionStart, this.selectionEnd));
                        });
                    }
                }

                return true;
            case Key.BACKSPACE:
                // backspace
                this.clearInputTracking = true;
                if (this.text.length > 0) {
                    if (this.selectionStart != 0 || this.selectionEnd != 0) {
                        if (this.selectionStart == this.selectionEnd) {
                            this.text = this.text.slice(0, this.selectionStart - 1) + this.text.slice(this.selectionEnd);
                            this.styleMap = this.styleMap.slice(0, this.selectionStart - 1).concat(this.styleMap.slice(this.selectionEnd));

                            this.updateModel().then(() => {
                                this.setSelection(this.selectionStart - 1);
                            });
                        } else {
                            // delete trailing space when deleting a word
                            if ((this.selectionStart == 0 || this.text[this.selectionStart - 1] == " ") && this.text[this.selectionEnd] == " ") {
                                this.selectionEnd += 1;
                            }
                            // delete leading space when deleting a word followed by puncuation
                            if ((this.selectionStart > 0 && this.text[this.selectionStart - 1] == " ") && SvgTextModel.breakWordRegex.test(this.text[this.selectionEnd])) {
                                this.selectionStart -= 1;
                            }

                            this.text = this.text.slice(0, Math.min(this.selectionStart, this.selectionEnd)) + this.text.slice(Math.max(this.selectionStart, this.selectionEnd));
                            this.styleMap = this.styleMap.slice(0, Math.min(this.selectionStart, this.selectionEnd)).concat(this.styleMap.slice(Math.max(this.selectionStart, this.selectionEnd)));

                            this.updateModel().then(() => {
                                this.setSelection(Math.min(this.selectionStart, this.selectionEnd));
                            });
                        }
                    } else {
                        this.trigger(TextEditorEvent.BACKSPACE_ON_BEGINNING);
                    }
                    event.preventDefault();
                    return true;
                } else {
                    this.trigger(TextEditorEvent.BACKSPACE_ON_BLANK);
                    event.preventDefault();
                    return false;
                }
            case Key.RIGHT_ARROW:
                //right-arrow
                event.preventDefault();
                this.moveSelectionRight(event.shiftKey, (event.ctrlKey || event.altKey) ? "word" : (event.metaKey ? "line" : "char"));
                return true;
            case Key.LEFT_ARROW:
                //left-arrow
                event.stopPropagation();
                this.moveSelectionLeft(event.shiftKey, (event.ctrlKey || event.altKey) ? "word" : (event.metaKey ? "line" : "char"));
                return true;
            case Key.UP_ARROW:
                //up-arrow
                event.stopPropagation();
                if (this.selectionEnd > 0) {
                    this.moveSelectionUp(event.shiftKey, (event.ctrlKey || event.altKey) ? "paragraph" : (event.metaKey ? "all" : "char"));
                } else {
                    this.trigger(TextEditorEvent.UP_ARROW);
                }
                return true;
            case Key.DOWN_ARROW:
                //down-arrow
                event.stopPropagation();
                if (this.selectionEnd < this.text.length) {
                    this.moveSelectionDown(event.shiftKey, (event.ctrlKey || event.altKey) ? "paragraph" : (event.metaKey ? "all" : "char"));
                } else {
                    this.trigger(TextEditorEvent.DOWN_ARROW);
                }
                return true;
            case Key.HOME:
                // home key
                event.stopPropagation();
                if (event.shiftKey) {
                    this.setSelection(this.selectionStart, 0);
                } else {
                    this.setSelection(0, 0);
                }
                return true;
            case Key.END:
                // end key
                event.stopPropagation();
                if (event.shiftKey) {
                    this.setSelection(this.selectionStart, this.text.length);
                } else {
                    this.setSelection(this.text.length, this.text.length);
                }
                return true;
            case Key.KEY_E: {
                // debug lorem ipsum generator
                if (window.isDevelopment || app.user.get("admin")) {
                    let loremText;
                    if ((event.ctrlKey && !event.altKey) || event.metaKey) {
                        if (this.element.id == "title" || this.element.id == "label" || this.element.id == "titleDecoration" || this.element.id == "headline" || this.element.id == "content") {
                            if (event.shiftKey) {
                                loremText = LoremIpsum.getBulletPointTitle();
                            } else {
                                loremText = LoremIpsum.getTitle();
                            }
                        } else {
                            if (event.shiftKey) {
                                loremText = LoremIpsum.getBulletPoint();
                            } else {
                                loremText = LoremIpsum.getParagraph(1);
                            }
                        }

                        if (this.selectionStart > 0) {
                            loremText = " " + loremText;
                        }
                        this.insertString(loremText, this.selectionStart, this.selectionEnd);
                        this.updateModel().then(() => {
                            this.setSelection(this.selectionStart + loremText.length);
                        });
                        event.preventDefault();

                        return true;
                    }
                }
                break;
            }
        }
    }

    prevInputLength = 0;
    onValInput(event, val, overwritePrevPress) {
        if (!event.metaKey && (!event.ctrlKey || event.altKey)) {
            if (!val) {
                val = String.fromCodePoint(event.which);
            }

            if (this.inputFilter && val.match(this.inputFilter) === null) {
                return;
            }

            let start = this.selectionStart;
            let end = this.selectionEnd;

            // If we're set to overwrite and there is no selection,
            //   select backwards the previous input length
            if (overwritePrevPress && start === end) {
                start = Math.max(this.selectionStart - this.prevInputLength, 0);
            }

            this.insertString(val, start, end);

            this.prevInputLength = val.length;
            start = end = Math.min(start, end) + this.prevInputLength;

            this.updateModel().then(() => {
                this.setSelection(start, end);
            });
        }
    }

    insertString(str, selectionStart, selectionEnd) {
        if (str || str === "") {
            // Using the shift-arrow selection shortcuts can result in a range being "flipped", so we need to ensure
            // that the end is always greater than the start.
            if (selectionStart > selectionEnd) {
                [selectionStart, selectionEnd] = [selectionEnd, selectionStart];
            }
            this.text = this.text.slice(0, selectionStart) + str + this.text.slice(selectionEnd);

            const insertStyles = [];
            for (let i = 0; i < str.length; i++) {
                const styleIndex = Math.clamp(selectionStart - 1, 0, this.styleMap.length - 1);
                const style = _.clone(this.styleMap[styleIndex]) || {
                    bold: false,
                    italic: false,
                    link: null
                };
                if (this.defaultStyleToBold != null) {
                    style.bold = this.defaultStyleToBold;
                }
                insertStyles.push(style);
            }

            this.styleMap = this.styleMap.slice(0, selectionStart).concat(insertStyles, this.styleMap.slice(selectionEnd));
        }
    }

    //------------------------------------------------------------------------------------------------------------------------------
    // region Selection
    //------------------------------------------------------------------------------------------------------------------------------

    get selectionStart() {
        return this._selectionStart || 0;
    }

    set selectionStart(value) {
        this._selectionStart = value;
    }

    get selectionEnd() {
        return this._selectionEnd || 0;
    }

    set selectionEnd(value) {
        this._selectionEnd = value;
    }

    get TEXT_SELECTION_ADJUSTMENT() {
        return 0.3;
    }

    drawSelection() {
        if (this.element.canvas.layouter) {
            if (!this.element.canvas.layouter.isGenerating) {
                this.element.canvas.layouter.refreshRender(false);
            }
        } else {
            logger.warn("[TextEditor] attempting to draw text selection when there is no layouter. Is the text editor orphaned?");
        }
    }

    renderTextSelection = () => {
        let pos = this.getPositionAtCharIndex(this.selectionStart);

        if (this.cursorFlash) {
            clearInterval(this.cursorFlash);

            // check if the cursor has moved at all - we always
            // want to show the cursor if its changed positions
            if (pos.x !== this.lastCursorPosition?.x || pos.y !== this.lastCursorPosition?.y) {
                this.showCursor = true;
            }
        }

        if (this.showCursor == undefined) {
            this.showCursor = true;
        }

        let renderChildren = [];

        let fontSize = this.element.textLayouter.fontSize("regular");
        let lineHeight = this.element.textLayouter.fontHeight("regular", fontSize);
        if (this.selectionStart == this.selectionEnd) {
            let x = pos.x + fontSize / 20;
            let y = pos.y;

            y -= lineHeight * this.TEXT_SELECTION_ADJUSTMENT;
            lineHeight *= (1 + (this.TEXT_SELECTION_ADJUSTMENT * 2));

            renderChildren.push(<line key={`${this.id}-${this.element.id}-selection-line`} x1={Math.max(1, x)} y1={y} x2={Math.max(1, x)} y2={y + lineHeight}
                stroke="#B2D7FF" strokeWidth={2} opacity={this.showCursor ? 1 : 0} />);

            this.cursorFlash = setInterval(() => {
                this.lastCursorPosition = pos;
                this.showCursor = !this.showCursor;
                this.drawSelection();
            }, 700);

            // reposition the invisible textinput so IME popups appear in correct location
            if (this.$textInput) {
                let scale = this.element.canvas.getScale();
                this.$textInput.left(this.element.screenBounds.left + x * scale).top(this.element.screenBounds.top + y * scale).height(lineHeight);
            }
        } else {
            // this.selectionGroup.show();

            let startPos = this.getPositionAtCharIndex(Math.min(this.selectionStart, this.selectionEnd));
            let endPos = this.getPositionAtCharIndex(Math.max(this.selectionStart, this.selectionEnd));
            let width = endPos.x - startPos.x;

            let offsetY = 0;

            let drawSelectionBox = (x1, x2, y, height) => {
                let width = (x2 - x1);
                x1 += fontSize / 20; // magic adjust x for better spacing around letters
                y = y + offsetY;

                y -= height * this.TEXT_SELECTION_ADJUSTMENT;
                height *= (1 + (this.TEXT_SELECTION_ADJUSTMENT * 2));

                // this.uiGroup.rect(width, height).move(x1, y).fill("#B2D7FF").addClass("selection");
                renderChildren.push(<rect key={`${x1}-${y}`} x={x1} y={y} width={width} height={height} fill="#B2D7FF" />);
            };

            if (startPos.y == endPos.y) {
                drawSelectionBox(startPos.x, endPos.x, startPos.y, lineHeight);
            } else {
                drawSelectionBox(startPos.x, this.element.textBounds.width, startPos.y, lineHeight);

                for (let line = startPos.lineIndex + 1; line < endPos.lineIndex; line++) {
                    drawSelectionBox(0, this.element.textBounds.width, this.element.textLayout.lines[line].y, lineHeight);
                }
                drawSelectionBox(0, endPos.x, endPos.y, lineHeight);
            }
        }

        // let textBounds = this.element.calculatedProps.textLayout.textBounds;

        return (<SVGGroup key={`${this.id}-${this.element.id}-text-selection`}>
            <g className="text-selection"
                style={{ transform: `translateX(${this.element.offsetX}px) translateY(${this.element.offsetY}px)` }}>{renderChildren}</g>
        </SVGGroup>);
    }

    clearSelection() {
        // if (this.uiGroup) {
        //     this.uiGroup.clear();
        // }

        if (this.cursorFlash) {
            clearInterval(this.cursorFlash);
        }
    }

    selectAll() {
        this.setSelection(0, this.text.length);
    }

    getSelection() {
        return {
            start: Math.min(this.selectionStart, this.selectionEnd),
            end: Math.max(this.selectionStart, this.selectionEnd)
        };
    }

    setSelection(start, end) {
        var count = this.text.length;
        start = Math.max(0, Math.min(start, count));
        if (end != undefined) {
            end = Math.max(0, Math.min(end, count));
        } else {
            end = start;
        }
        this.selectionStart = start;
        this.selectionEnd = end;
        this.drawSelection();

        this.trigger(TextEditorEvent.SELECTION_CHANGED, { start: this.selectionStart, end: this.selectionEnd });
    }

    isLinkSelected() {
        const { start, end } = this.getSelection();
        return !!this.styleMap[start].link;
    }

    setSelectionToEnd() {
        this.setSelection(this.text.length, this.text.length);
    }

    hasSelectionChanged(index) {
        return this.selectionStart !== this.getWordSelectionAtIndex(index).start || this.selectionEnd !== this.getWordSelectionAtIndex(index).end;
    }

    get CHAR_Y_ADJUSTMENT() {
        return 10;
    } // getPositionAtCharIndex adds a bit of padding to make it easier to click on a line but this can result incorrect results when checking for cursor position so this is number is used to adjust

    moveSelectionLeft(extend, chunk) {
        let delta = 1;
        let pos;
        switch (chunk) {
            case "word":
                delta++;
                while (SvgTextModel.breakWordRegex.test(this.text[this.selectionEnd - delta]) == false) {
                    delta += 1;
                    if (this.selectionEnd - delta < 0) break;
                }
                delta -= 1;
                break;
            case "line":
                pos = this.getPositionAtCharIndex(this.selectionEnd);
                delta = this.selectionEnd - this.getCharIndexAtLine(pos.lineIndex);
                break;
        }

        if (extend) {
            if (this.selectionEnd > this.selectionStart) {
                this.setSelection(this.selectionStart, this.selectionEnd - delta);
            } else {
                this.setSelection(this.selectionStart, this.selectionEnd - delta);
            }
        } else {
            if (this.selectionStart == this.selectionEnd) {
                this.setSelection(this.selectionStart - delta);
            } else {
                this.setSelection(Math.min(this.selectionStart, this.selectionEnd));
            }
        }
    }

    moveSelectionRight(extend, chunk) {
        let delta = 1;
        let pos;
        switch (chunk) {
            case "word":
                while (SvgTextModel.breakWordRegex.test(this.text[this.selectionEnd + delta]) == false) {
                    delta += 1;
                    if (this.selectionEnd + delta > this.text.length) break;
                }
                break;
            case "line":
                pos = this.getPositionAtCharIndex(this.selectionEnd);
                delta = this.getCharIndexAtLine(pos.lineIndex + 1) - this.selectionEnd - 1;
                break;
        }

        if (extend) {
            this.setSelection(this.selectionStart, this.selectionEnd + delta);
        } else {
            if (this.selectionStart == this.selectionEnd) {
                this.setSelection(this.selectionStart + delta);
            } else {
                this.setSelection(Math.max(this.selectionStart, this.selectionEnd));
            }
        }
    }

    moveSelectionUp(extend, chunk) {
        switch (chunk) {
            case "all":
                if (extend) {
                    this.setSelection(this.selectionStart, 0);
                } else {
                    this.setSelection(0);
                }
                break;
            case "paragraph":
                let endSelection = this.selectionEnd - 2;
                while (endSelection >= 0) {
                    if (this.text[endSelection] == String.fromCharCode(13)) break;
                    endSelection--;
                }
                if (extend) {
                    this.setSelection(this.selectionStart, endSelection + 1);
                } else {
                    this.setSelection(endSelection + 1);
                }

                break;
            default:
                var pos = this.getPositionAtCharIndex(this.selectionEnd);
                if (pos.lineIndex > 0) {
                    // find pos in previous line
                    var newIndex = this.getIndexAtScaledPoint(pos.x, this.element.textLayout.lines[pos.lineIndex - 1].y + this.CHAR_Y_ADJUSTMENT);
                    if (extend) {
                        this.setSelection(this.selectionStart, newIndex);
                    } else {
                        this.setSelection(newIndex);
                    }
                } else {
                    // move cursor to beginning of text
                    if (extend) {
                        this.setSelection(this.selectionStart, 0);
                    } else {
                        this.setSelection(0);
                    }
                }
        }
    }

    moveSelectionDown(extend, chunk) {
        switch (chunk) {
            case "all":
                if (extend) {
                    this.setSelection(this.selectionStart, this.text.length);
                } else {
                    this.setSelection(this.text.length);
                }
                break;
            case "paragraph":
                let endSelection = this.selectionEnd + 1;
                while (endSelection < this.text.length) {
                    if (this.text[endSelection] == String.fromCharCode(13)) break;
                    endSelection++;
                }
                if (extend) {
                    this.setSelection(this.selectionStart, endSelection);
                } else {
                    this.setSelection(endSelection);
                }

                break;
            default:
                let pos = this.getPositionAtCharIndex(this.selectionEnd);
                if (pos.lineIndex < this.element.textLayout.lines.length - 1) {
                    let newIndex = this.getIndexAtScaledPoint(pos.x, this.element.textLayout.lines[pos.lineIndex + 1].y + this.CHAR_Y_ADJUSTMENT);
                    if (extend) {
                        this.setSelection(this.selectionStart, newIndex);
                    } else {
                        this.setSelection(newIndex);
                    }
                } else {
                    let charCount = this.text.length;
                    if (extend) {
                        this.setSelection(this.selectionStart, charCount);
                    } else {
                        this.setSelection(charCount);
                    }
                }
        }
    }

    getParagraphRanges() {
        let ranges = [];
        let start = 0;
        for (let p of this.text.split(String.fromCharCode(13))) {
            ranges.push([start, start + p.length]);
            start += p.length + 1;
        }
        return ranges;
    }

    getWordSelectionAtIndex(index) {
        let start = index;
        let end = index;
        while (start > 0) {
            start--;
            if (SvgTextModel.breakWordRegex.test(this.text[start])) {
                start++;
                break;
            }
        }
        while (end < this.text.length) {
            if (SvgTextModel.breakWordRegex.test(this.text[end])) {
                break;
            }
            end++;
        }
        return { start, end };
    }

    getCharIndexAtLine(lineIndex) {
        let index = 0;
        for (let ii = 0; ii < lineIndex; ii++) {
            for (let word of this.element.textLayout.lines[ii].words) {
                index += word.text.length;
            }
        }
        return index;
    }

    getPositionAtCharIndex(index) {
        let x = 0;
        let y = 0;
        let chIndex = 0;

        let lineIndex = 0;
        let indexInWord = 0;

        loop: for (let line of this.element.textLayout.lines) {
            y = line.y;

            if (line.words.length == 0) {
                switch (this.element.textLayouter.textAlign) {
                    case "center":
                        x = this.element.textLayout.size.width / 2;
                        break;
                    case "right":
                        x = this.element.textLayout.size.width;
                        break;
                    default:
                        x = 0;
                }
            } else {
                x = line.words[0].x;
            }

            lineIndex = this.element.textLayout.lines.indexOf(line);

            for (let word of line.words) {
                let wordLength = word.text.length;
                if (chIndex + wordLength > index) {
                    indexInWord = index - chIndex;
                    x += _.sum(_.take(word.glyphWidths, indexInWord));
                    break loop;
                }
                chIndex += wordLength;
                x += _.sum(word.glyphWidths);
            }
        }

        return { x, y, indexInWord, lineIndex };
    }

    getIndexAtPoint(xLoc, yLoc) {
        xLoc = xLoc - this.element.offsetX;
        yLoc = yLoc - this.element.offsetY;

        return this.getIndexAtScaledPoint(xLoc, yLoc);
    }

    getIndexAtScaledPoint(xLoc, yLoc) {
        let index = 0;
        for (let line of this.element.textLayout.lines) {
            if (line.words.length === 0) continue;

            // check if we are in the gap between the previous line and this line
            if (yLoc < line.y) {
                return index - 1;
            }

            // check if we are within this line
            if (yLoc >= line.y && yLoc < line.y + line.height) {
                if (xLoc <= line.words[0].x) {
                    return index;
                }
                for (let word of line.words) {
                    if (xLoc > word.x && xLoc < word.x + this.element.textLayouter.calculateWordWidth(word)) {
                        var xPos = word.x;
                        for (var c = 0; c < word.glyphWidths.length; c++) {
                            if (xLoc < xPos + word.glyphWidths[c] / 2) {
                                return index;
                            }
                            xPos += word.glyphWidths[c];
                            index += 1;
                        }
                        return index;
                    } else {
                        index += word.text.length;
                    }
                }
                if (this.element.textLayout.lines.indexOf(line) < this.element.textLayout.lines.length - 1) {
                    index--; // adjust loc to be at end of clicked line and not start of next line
                }
                return index; // loc is to the right of line so index is end of line
            }
            index += line.charCount;
        }

        return this.text.length;
    }

    //endregion

    copyToClipboard(cut) {
        let selection = this.getSelection();
        let selectedText = this.text.slice(selection.start, selection.end);
        clipboardWrite({
            [ClipboardType.TEXT]: selectedText,
        }).then(() => {
            if (cut) {
                // delete trailing space when deleting a word
                if ((selection.start == 0 || this.text[selection.start - 1] == " ") && this.text[selection.end] == " ") {
                    selection.end += 1;
                }
                // delete leading space when deleting a word followed by puncuation
                if ((selection.start > 0 && this.text[selection.start - 1] == " ") && SvgTextModel.breakWordRegex.test(this.text[selection.end])) {
                    selection.start -= 1;
                }

                this.insertString("", selection.start, selection.end);
                this.updateModel().then(() => {
                    this.setSelection(selection.start, selection.start);
                });
            }
        });
    }

    async pasteFromClipboard(event) {
        // If we get an error, then we don't have permission to paste
        try {
            const processPaste = text => { //save current text so undo/redo will only affect the pasted data.
                this.saveModel();

                text = text.replace(/[\u0000-\u000C\u000E-\u001F]/g, "");
                if (this.element.singleLine) {
                    text = text.replace(/\n/g, " ");
                }
                const maxLength = this.element.pasteCharLimit;
                const pastedTextLength = text.length;
                const currentTextLength = this.text.length;
                if (currentTextLength + pastedTextLength > maxLength) {
                    ShowWarningDialog({
                        title: "Your text was truncated",
                        message: `The existing text combined with the text you pasted is longer than ${maxLength} characters and has been truncated.`
                    });
                }
                text = text.slice(0, maxLength - currentTextLength);

                const selection = this.getSelection();
                this.insertString(text, selection.start, selection.end);
                this.updateModel(false, true).then(() => {
                    //Save the model so we have a new undo/redo point.
                    this.saveModel();
                    this.setSelection(selection.start + text.length);
                });
            };

            let text = await clipboardRead([ClipboardType.TEXT], event);
            if (text) {
                processPaste(text);
            }
        } catch (error) {
            logger.error(error, "[TextEditor] failed to paste");
        }
    }

    remove() {
        this.element.isEditingText = false;
        //TODO check if we really need to save model everytime texteditor is removed because this happens even if no edit was performed and refreshes canvas which
        this.saveModel();
        // this.element.canvas.refreshCanvas();

        if (this.cursorFlash) {
            clearInterval(this.cursorFlash);
            this.cursorFlash = null;
        }

        this.$textInput.remove();
        //     this.uiGroup.remove();

        this.element.renderSelectionFunction = null;
        this.element.canvas.layouter.refreshRender();
    }
}

export const CreateTextStylePopup = function(view, element, options = {}) {
    return view.addControl({
        id: "textStyling",
        type: controls.POPUP_BUTTON,
        icon: "format_shapes",
        label: options.label,
        showArrow: options.label != null,
        items: () => [{
            type: "control",
            view: () => controls.createIconDropdownMenu(view, {
                label: "Style",
                model: element.model,
                property: "textStyle",
                menuClass: "icon-menu",
                enabled: element.isOverImage,
                transitionModel: false,
                markStylesAsDirty: true,
                items: [{
                    value: "white_text", label: "White", image: getStaticUrl("/images/ui/textstyles/text_white.svg")
                }, {
                    value: "white_text_with_shadow",
                    label: "White with Shadow",
                    image: getStaticUrl("/images/ui/textstyles/text_shadow.svg")
                }, {
                    value: "dark_text", label: "Dark", image: getStaticUrl("/images/ui/textstyles/text_dark.svg")
                }, {
                    value: "white_box",
                    label: "White Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_white.svg")
                }, {
                    value: "transparent_light_box",
                    label: "Transparent Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_transparent.svg")
                }, {
                    value: "transparent_dark_box",
                    label: "Dark Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_dark.svg")
                }],
            })
        }, {
            type: "control",
            view: () => controls.createPositionPicker(view, {
                label: "Text Position",
                value: element.textPosition || element.model.textPosition,
                callback: value => {
                    element.parentElement?.markStylesAsDirty();
                    element.model.textAlign = null;
                    element.model.textPosition = value;
                    element.model.userPositionX = element.model.userPositionY = element.model.userWidth = null;

                    switch (value) {
                        case PositionType.LEFT:
                        case PositionType.BOTTOM_LEFT:
                        case PositionType.TOP_LEFT:
                            element.model.textAlign = HorizontalAlignType.LEFT;
                            break;
                        case PositionType.RIGHT:
                        case PositionType.BOTTOM_RIGHT:
                        case PositionType.TOP_RIGHT:
                            element.model.textAlign = HorizontalAlignType.RIGHT;
                            break;
                        default:
                            element.model.textAlign = HorizontalAlignType.CENTER;
                    }

                    element.canvas.updateCanvasModel(true)
                        .catch(() => {
                            ShowDialogAsync(BadFitDialog, {
                                title: "Sorry, we aren't able to fit all elements into the new layout",
                            });
                        });
                }
            })
            // }, {
            //     type: "divider"
            // }, {
            //     type: "item",
            //     icon: "close",
            //     label: "Remove Text",
            //     callback: () => {
            //
            //     }
        }]
    });
};

export { TextElementSelection, TextEditor };

export const editors = {
    TextElementDefaultOverlay,
    TextElementRollover,
    TextElementSelection,
};
