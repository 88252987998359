import styled from "styled-components";

export const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
        grid-template-columns: repeat(22, 1fr);
    }

    @media (min-width: 1180px) {
        grid-template-columns: repeat(30, 1fr);
    }
`;

export const HeaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
`;

export const BeautifulLogoContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 16px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

    @media (min-width: 768px) {
        padding: 0 48px;
    }
`;

export const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 32px);
    height: 3px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
    margin: 68px auto 0;
    max-width: 1024px;

    @media (min-width: 768px) {
        margin: 68px auto 0;
        width: calc(100% - 96px);
    }
`;

export const ScrollContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-height: 100vh;
`;

export const FormContainer = styled.div`
  margin: calc(71px + 42px) 0 130px;
  grid-column: 2/span 16;

  @media (min-width: 768px) {
      grid-column: 6 / span 12;
  }

  @media (min-width: 1180px) {
      grid-column: 9 / span 14;
      padding: 0 8vw;
  }
`;

export const Heading = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.45;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #222;

  @media (min-width: 768px) {
      font-size: 24px;
  }

  @media (min-width: 1180px) {
      font-size: 26px;
  }
  `;

export const BodySmall = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1.6;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #222;

  @media (min-width: 768px) {
      font-size: 18px;
      letter-spacing: -0.01em;
  }
  `;

export const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const IconBox = styled.div`
  margin: 0 15px 0 0;
  display: grid;
  width: 24px;
  height: 24px;
  place-items: center;

  i {
      color: #11a9e2;
  }
`;

export const WorkspaceSettingsContainer = styled.div`
    grid-column: 2/span 16;
    margin: calc(71px + 42px) 0 30px;

    @media (min-width: 768px) {
        grid-column: 6 / span 12;
    }

    @media (min-width: 1180px) {
        grid-column: 5 / span 10;
        margin-bottom: 130px;
    }
`;

export const PaymentDetailsContainer = styled.div`
    grid-column: 2/span 16;
    margin: 0 0 130px;

    @media (min-width: 768px) {
        grid-column: 6 / span 12;
    }

    @media (min-width: 1180px) {
        grid-column: 17 / span 10;
        margin: calc(71px + 42px) 0 130px;
    }
`;
