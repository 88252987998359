import React, { Component } from "react";
import styled from "styled-components";

import { FeatureType } from "js/core/models/features";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { ShowUpgradeDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { Gap20 } from "legacy-js/react/components/Gap";
import ProBadge from "legacy-js/react/components/ProBadge";
import { UIPane, UIPaneContents, UIPaneHeader, YellowButton } from "legacy-js/react/components/UiComponents";
import AnalyticsController from "legacy-js/react/views/Analytics/AnalyticsController";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";

import AnalyticsTabs from "../Components/AnalyticsTabs";
import { PresentationStats } from "../Components/Stats";
import ViewingAggregates from "../Components/ViewingAggregates";
import ViewsPerDay from "../Components/ViewsPerDay";

// Needed to ensure the chart sections resize with the window properly
const AbsoluteFrame = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const ContentsBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledUIPaneContents = styled(UIPaneContents)`
    height: calc(100% - 75px);
`;

class AnalyticsPane extends Component {
    tabsOnClickHandler = tabValue => {
        const analyticsProps = {
            presentation_id: this.props.presentation.get("id"),
            view_selected: tabValue.replace(" ", "")
        };
        trackActivity("PresentationAnalytics", "ToggleView", null, null, analyticsProps);
    };

    handleShowProDialogAnalytics = () => {
        const { presentation } = this.props;
        const workspaceId = presentation.getWorkspaceId();
        if (!app.user.features.isFeatureEnabled(FeatureType.ANALYTICS, workspaceId)) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "Analytics", ...presentation.getAnalytics() },
                workspaceId
            });
        }
    }

    render() {
        const workspaceId = this.props.presentation.getWorkspaceId();
        const featureDisabled = shouldShowProBadge(FeatureType.ANALYTICS, workspaceId);
        const analytics = { cta: "Analytics", ...this.props.presentation.getAnalytics() };

        return (
            <UIPane className="player_analytics overview-page">
                <AbsoluteFrame>
                    <UIPaneHeader>
                        Analytics
                        <ProBadge
                            show={featureDisabled}
                            workspaceId={this.props.presentation.getWorkspaceId()}
                            analytics={analytics}
                        />
                    </UIPaneHeader>
                    <StyledUIPaneContents>
                        <Gap20 />
                        {featureDisabled && (
                            <div className="analytics-upgrade-banner">
                                Get analytics and detailed data about who and when people are viewing your presentations.
                                <YellowButton
                                    onClick={() =>
                                        ShowUpgradeDialog({
                                            type: UpgradePlanDialogType.UPGRADE_PLAN,
                                            analytics,
                                            workspaceId
                                        })
                                    }
                                >
                                    Upgrade Plan
                                </YellowButton>
                            </div>
                        )}

                        <ContentsBlock style={featureDisabled ? { pointerEvents: "none" } : {}}>
                            <ViewsPerDay />
                            <PresentationStats />
                            <ViewingAggregates />
                            <AnalyticsTabs parentOnClickHandler={this.tabsOnClickHandler} />
                        </ContentsBlock>
                        {featureDisabled && (
                            <div className="analytics-upgrade-shield">
                                <div style={{ pointerEvents: "all" }} onClick={this.handleShowProDialogAnalytics}>Sample Data</div>
                            </div>
                        )}
                    </StyledUIPaneContents>
                </AbsoluteFrame>
            </UIPane>
        );
    }
}

export default AnalyticsController.withState(AnalyticsPane);
