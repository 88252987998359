import React, { Component } from "react";
import { DialogTitle } from "@material-ui/core";

import { BeautifulDialog, DialogContent } from "../../components/Dialogs/BaseDialog";
import { ColGrid } from "../../components/LayoutGrid";
import Spinner from "../../components/Spinner";
import { BlueButton } from "../../components/UiComponents";
import { Gap20 } from "../../components/Gap";

export class LinkToSlideDialog extends Component {
    handleClick = slideId => {
        const { callback, closeDialog } = this.props;
        callback(slideId);
        closeDialog();
    }

    render() {
        const { presentation, linkedSlide, closeDialog } = this.props;

        return (
            <BeautifulDialog maxWidth="lg" closeDialog={closeDialog}>
                <DialogTitle>
                    Select Slide To Link To
                </DialogTitle>
                <DialogContent className="link-to-slide-dialog">
                    <div className="scroller">
                        <ColGrid style={{ paddingBottom: 10 }}>
                            {
                                presentation.slides.map(slide => (
                                    <SlideThumbnail
                                        key={slide.id}
                                        slide={slide}
                                        onClick={() => this.handleClick(slide.id)}
                                        presentation={presentation}
                                    />
                                ))
                            }
                        </ColGrid>
                    </div>

                    <Gap20 />
                    {linkedSlide && <BlueButton onClick={() => this.handleClick(null)}>Remove Link</BlueButton>}
                </DialogContent>
            </BeautifulDialog>
        );
    }
}

class SlideThumbnail extends Component {
    state = {
        thumbnailUrl: null
    }

    componentDidMount() {
        const { presentation, slide } = this.props;

        slide.getThumbnailUrl(presentation.id).then(url => {
            this.setState({ thumbnailUrl: url });
        });
    }

    render() {
        const { onClick } = this.props;
        const { thumbnailUrl } = this.state;

        return (
            <div className="link-to-slide-thumbnail" onClick={onClick}>
                {thumbnailUrl && <img src={thumbnailUrl} />}
                {!thumbnailUrl && <Spinner />}
            </div>
        );
    }
}

