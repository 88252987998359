import * as geom from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";
import { HorizontalAlignType, VerticalAlignType } from "legacy-common/constants";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { BaseElement } from "../base/BaseElement";
import { Icon } from "../base/MediaElements/IconElement";
import { BestFitTextToHeight, FindBestFit } from "../layouts/FindBestFit";
import { layoutHelper } from "../layouts/LayoutHelper";

export class BigNumbers extends CollectionElement {
    static get schema() {
        return {
            horizontalAlign: HorizontalAlignType.CENTER,
        };
    }

    static get maximumItems() {
        return 8;
    }

    getChildItemType(itemModel) {
        return BigNumberItem;
    }

    get maxItemCount() {
        return BigNumbers.maximumItems;
    }

    get minItemWidth() {
        return 200;
    }

    get rows() {
        return new Set(this.itemElements.map(item => item.model.row)).size;
    }

    get defaultItemData() {
        return {
            ...BigNumberItem.schema,
            row: this.rows - 1,
            showLabel: _.last(this.itemCollection)?.showLabel ?? true,
            showChangeInValue: _.last(this.itemCollection)?.showChangeInValue ?? true,
            showDescription: _.last(this.itemCollection)?.showDescription ?? false
        };
    }

    getItemsInRows() {
        let itemsInRows = [];
        for (const item of this.itemElements) {
            const rowIdx = item.model.row ?? 0;
            if (!itemsInRows[rowIdx]) {
                itemsInRows[rowIdx] = [];
            }
            itemsInRows[rowIdx].push(item);
        }

        // Remove empty rows
        itemsInRows = itemsInRows.filter(items => items);

        // Reset row indexes in model
        itemsInRows.forEach((rowItems, rowIdx) => rowItems.forEach(item => item.model.row = rowIdx));

        return itemsInRows;
    }

    _calcProps(props, options) {
        const { size } = props;

        const itemsInRows = this.getItemsInRows();

        const vGap = this.styles.vGap;
        const hGap = (this.styles.hGap ?? 10);

        const rowsCount = itemsInRows.length;
        const maxRowHeight = (size.height - (rowsCount - 1) * vGap) / rowsCount;

        let y = 0;
        for (const rowItems of itemsInRows) {
            // For each row, find best fit scale
            const { rowItemProps, rowHeight, stylesScale } = FindBestFit({
                min: 0.4,
                max: 1,
                preCheckMax: true,
                layout: scale => {
                    let x = 0;
                    let rowHeight = 0;
                    for (const item of rowItems) {
                        item.scaleStyleValues(scale);

                        const itemProps = item.calcProps(new geom.Size(size.width, maxRowHeight));
                        itemProps.bounds = new geom.Rect(x, y, itemProps.size);

                        x += itemProps.size.width + hGap;
                        rowHeight = Math.max(rowHeight, itemProps.size.height);
                    }

                    const isFit = (x - hGap) <= size.width && rowHeight <= maxRowHeight;
                    const rowItemProps = isFit ? rowItems.map(item => item.getTreeProps()) : [];

                    return {
                        rowItemProps,
                        stylesScale: scale,
                        rowHeight,
                        isFit
                    };
                }
            });

            // Restore the calculatedProps and styles scale of the items in the row at the fit scale
            for (const item of rowItems) {
                item.scaleStyleValues(stylesScale);

                // try and assign if the row data was found
                const row = rowItemProps.findById(item.id);
                if (row) {
                    item.assignPropsToTree(row);
                }
            }

            if (this.model.layout === "fill") {
                // Recalc with fill width and rowheight
                const totalWidthOfItems = layoutHelper.getTotalBoundsOfItems(rowItems).width;
                if (totalWidthOfItems < size.width) {
                    const gapWidth = hGap * (rowItems.length - 1);
                    const scale = (size.width - gapWidth) / (totalWidthOfItems - gapWidth);
                    let x = 0;
                    for (const item of rowItems) {
                        const itemProps = item.calcProps(new geom.Size(Math.floor(item.calculatedProps.size.width * scale), maxRowHeight), {
                            forceWidth: true
                        });
                        itemProps.bounds = new geom.Rect(x, y, itemProps.size);
                        x += itemProps.size.width + hGap;
                    }
                }
            }

            // Recalc with the correct row height
            let x = 0;
            for (const item of rowItems) {
                if (item.bounds.height != rowHeight) {
                    const itemProps = item.calcProps(new geom.Size(item.bounds.width, rowHeight), {
                        forceWidth: true,
                        forceHeight: true
                    });
                    itemProps.bounds = new geom.Rect(x, y, itemProps.size);
                }
                x += item.calculatedProps.size.width + hGap;
            }

            layoutHelper.alignItemsInContainer(rowItems, size, HorizontalAlignType.CENTER, null);
            y += rowHeight + vGap;
        }

        layoutHelper.alignItemsInContainer(this.itemElements, size, null, VerticalAlignType.MIDDLE);

        return { size };
    }
}

class BigNumberItem extends CollectionItemElement {
    static get schema() {
        return {
            showLabel: true,
            showChangeInValue: true,
            showDescription: false,
            label: {
                text: "My Statistic"
            },
            value: {
                text: "$50,000"
            },
            changeLabel: {
                text: "100%"
            },
            positive: true
        };
    }

    get selectionPadding() {
        return 0;
    }

    get showLabel() {
        return this.model.showLabel;
    }

    get showChangeInValue() {
        return this.model.showChangeInValue;
    }

    get showDescription() {
        return this.model.showDescription;
    }

    get minItemWidth() {
        return this.parentElement.minItemWidth;
    }

    _build() {
        if (this.showLabel) {
            this.label = this.addElement("label", () => BigNumberLabel, {
                autoWidth: true,
                autoHeight: true,
                singleLine: true,
            });
        }

        this.value = this.addElement("value", () => BigNumberValue, {
            scaleTextToFit: true,
            minTextScale: 0.1,
            autoWidth: true,
            autoHeight: true,
            singleLine: true,
        });

        if (this.showChangeInValue) {
            this.changeInValue = this.addElement("changeInValue", () => BigNumberChangeInValue);
        }

        if (this.showDescription) {
            this.description = this.addElement("text", () => BigNumberDescription, {
                autoHeight: true
            });
        }
    }

    _loadStyles(styles) {
        if (!this.model.color && (this.model.color == "auto" || this.canvas.getSlideColor() == "neutral")) {
            // if (this.model.color && this.model.color != "auto") {
            //     styles.decoration.fillColor = this.model.color;
            // } else {
            if (this.canvas.getBackgroundColor().name == "background_light") {
                styles.decoration.fillColor = "background_accent";
            } else {
                styles.decoration.fillColor = "white";
            }
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let y = 0;

        let labelProps;
        if (this.showLabel) {
            labelProps = this.label.calcProps(size);
            labelProps.bounds = new geom.Rect(0, 0, labelProps.size);
            y += labelProps.size.height;
        }

        let changeInValueProps;
        if (this.showChangeInValue) {
            changeInValueProps = this.changeInValue.calcProps(size);
        }

        const valueProps = this.value.calcProps(new geom.Size(size.width - (changeInValueProps?.size.width ?? 0), size.height));
        valueProps.bounds = new geom.Rect(0, y, valueProps.size);
        y += valueProps.size.height;

        if (this.showChangeInValue) {
            // Shift change in value to the right
            changeInValueProps.bounds = new geom.Rect(valueProps.bounds.right, valueProps.bounds.centerV - changeInValueProps.size.height / 2, changeInValueProps.size);
        }

        let totalWidth = valueProps.size.width + (changeInValueProps?.size.width ?? 0);

        if (this.showLabel) {
            totalWidth = Math.max(totalWidth, labelProps.size.width);
        }

        let descriptionProps;
        if (this.showDescription) {
            const maxDescriptionHeight = size.height - y;
            descriptionProps = this.description.calcProps(new geom.Size(totalWidth, maxDescriptionHeight));
            if (!descriptionProps.isTextFit) {
                const bestSize = BestFitTextToHeight(this.description, { scaleTextToFit: false }, maxDescriptionHeight, totalWidth, size.width);
                descriptionProps = this.description.calcProps(bestSize);
            }
            descriptionProps.bounds = new geom.Rect(0, y, descriptionProps.size);
            y += descriptionProps.size.height;

            totalWidth = Math.max(totalWidth, descriptionProps.size.width);
        }

        const calculatedWidth = options.forceWidth ? size.width : Math.max(totalWidth, this.minItemWidth);
        const calculatedHeight = options.forceHeight ? size.height : y;
        return { size: new geom.Size(calculatedWidth, calculatedHeight) };
    }
}

class BigNumberLabel extends TextElement {

}

class BigNumberValue extends TextElement {

}

class BigNumberDescription extends TextElement {

}

class BigNumberChangeInValue extends BaseElement {
    get iconId() {
        return this.model.icon ?? "arrow-up";
    }

    _build() {
        this.icon = this.addElement("icon", () => BigNumberChangeInValueIcon, {
            canRollover: true
        });
        this.label = this.addElement("changeLabel", () => BigNumberChangeInValueLabel, {
            autoWidth: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const height = 50;

        const iconProps = this.icon.calcProps(new geom.Size(height, height));
        iconProps.bounds = new geom.Rect(0, 0, iconProps.size);

        const labelProps = this.label.calcProps(new geom.Size(size.width - iconProps.size.width, height));
        labelProps.bounds = new geom.Rect(iconProps.size.width, 0, labelProps.size);

        return { size: new geom.Size(iconProps.bounds.width + labelProps.bounds.width, height) };
    }
}

class BigNumberChangeInValueLabel extends TextElement {

}

class BigNumberChangeInValueIcon extends Icon {
    get iconId() {
        if (this.model.positive) {
            return "arrow-up";
        } else {
            return "arrow-down";
        }
    }

    get _doubleClickToSelect() {
        return false;
    }

    _loadStyles(styles) {
        if (this.model.positive) {
            styles.fillColor = "positive";
        } else {
            styles.fillColor = "negative";
        }
    }
}

export const elements = {
    BigNumbers,
    BigNumberItem
};

