export const PreviewSlide1 = {
    "template_id": "title",
    "layout": { "headerPosition": "top", "showFooter": "theme", "showElementTitle": false, "showAttribution": false },
    "states": [{
        "header": { "title": "", "body": "" },
        "primary": {
            "title": {
                "text": "A Sample Title Slide",
                "styles": [{ "bold": false, "italic": false, "start": 0, "end": 9 }, {
                    "bold": true,
                    "italic": false,
                    "start": 9,
                    "end": 20
                }]
            },
            "body": "",
            "label": { "text": "Sample Label", "styles": [{ "bold": false, "italic": false, "start": 0, "end": 12 }] },
            "showFooter": false,
            "showLabel": false,
            "description": {
                "text": "Sample body copy. This is a description.",
                "styles": [{ "bold": false, "italic": false, "start": 0, "end": 40 }]
            },
            "image": {
                "position": "top",
                "content_value": "2c622617f1f74a3f5701eafcdab8fab2394d50fe92c80aea796b7181bf47579b-I",
                "content_type": "image",
                "aoiLeft": 0,
                "aoiRight": 5760,
                "aoiTop": 327,
                "aoiBottom": 3513,
                "scale": 0.2222222222222222,
                "version": 1,
                "traySize": 360
            }
        }
    }],
    "version": 9
};

export const PreviewSlide2 = {
    "template_id": "textgrid_vertical_icons",
    "layout": {
        "headerPosition": "top",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false,
        "trayLayout": "none",
        "slideColor": "colorful"
    },
    "states": [{
        "header": {
            "title": {
                "text": "Sample Header",
                "styles": [{ "bold": false, "italic": false, "start": 0, "end": 13 }]
            },
            "body": { "text": "This is the subheader" },
            "label": { "text": "Sample Label", "styles": [{ "bold": false, "italic": false, "start": 0, "end": 5 }] }
        }, "primary": {
            "forceSingleColumn": false,
            "items": [{
                "content_type": "icon",
                "title": {
                    "text": "Sample Title",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 6 }, {
                        "bold": true,
                        "italic": false,
                        "start": 6,
                        "end": 12
                    }]
                },
                "body": {
                    "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras velit turpis, viverra a lectus non, eleifend ultrices lorem. Integer ultricies nulla ut elementum sollicitudin.",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 87 }]
                },
                "content_value": "rocket",
                "id": "225161587586374770"
            }, {
                "content_type": "icon",
                "title": {
                    "text": "Another Sample Title",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 15 }, {
                        "bold": true,
                        "italic": false,
                        "start": 15,
                        "end": 20
                    }]
                },
                "body": {
                    "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras velit turpis, viverra a lectus non, eleifend ultrices lorem. Integer ultricies nulla ut elementum sollicitudin.",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 97 }]
                },
                "content_value": "space",
                "id": "225171587586374771"
            }, {
                "content_type": "icon",
                "title": {
                    "text": "One More Sample Title",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 16 }, {
                        "bold": true,
                        "italic": false,
                        "start": 16,
                        "end": 21
                    }]
                },
                "body": {
                    "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras velit turpis, viverra a lectus non, eleifend ultrices lorem. Integer ultricies nulla ut elementum sollicitudin.",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 81 }]
                },
                "content_value": "astronaut",
                "id": "225181587586374771"
            }],
            "orientation": "auto"
        }, "tray": {
            "items": [{
                "id": "232551587586706266",
                "showText": true,
                "cellColor": "background_accent",
                "blocks": [{
                    "type": "headline",
                    "id": "139651596755949420",
                    "content": { "text": "10x", "styles": [{ "bold": false, "italic": false, "start": 0, "end": 3 }] }
                }, {
                    "id": "140111596755960852",
                    "type": "body",
                    "content": {
                        "text": "Show off with an impressive stat or accomplishment here.",
                        "styles": [{ "bold": false, "italic": false, "start": 0, "end": 56 }]
                    }
                }, { "id": "184521596757110839", "type": "divider" }, {
                    "id": "156411596756329102",
                    "type": "headline",
                    "content": { "text": "1.5x", "styles": [{ "bold": false, "italic": false, "start": 0, "end": 4 }] }
                }, {
                    "id": "157251596756341963",
                    "type": "body",
                    "content": { "text": "Or two!", "styles": [{ "bold": false, "italic": false, "start": 0, "end": 51 }] }
                }, {
                    "id": "191511596757166542",
                    "type": "body",
                    "content": {
                        "text": "Go on—let them know what you've been up to.",
                        "styles": [{ "bold": false, "italic": false, "start": 0, "end": 43 }]
                    }
                }],
                "scale": 0.18025000000000002,
                "aoiBottom": 3999.9999999999995,
                "aoiTop": 0,
                "aoiRight": 3078.3722592799786,
                "aoiLeft": 977.1062428004643,
                "version": 1,
                "textPosition": "center",
                "textStyle": "white_box"
            }],
            "trayWidth": 344.81520300088266,
            "aspectRatio": "fill",
            "userFontScale": { "CanvasElement/TrayContainer/TrayPhotoCollage/PhotoCollageItem/ContentBlockContainer/ContentBlockCollection/ContentBlockItem/ContentBlockTextCaption": 0.8500000000000001 }
        }
    }],
    "version": 9,
    "timeline": { "preset": "autoplay", "speed": "normal", "buildAnimationType": "overlapping" }
};

export const PreviewSlide3 = {
    "template_id": "chart_column",
    "layout": {
        "headerPosition": "top",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false,
        "trayLayout": "right_tray",
        "slideColor": "theme",
    },
    "states": [{
        "header": {
            "title": {
                "text": "Sample Header",
                "styles": [{ "bold": false, "italic": false, "start": 0, "end": 13 }]
            }, "body": "", "userFontScale": { "CanvasElement/Header/DisplayTextHeadline": 1 }
        },
        "primary": {
            "items": [{
                "elementType": "chart", "chart": {
                    "showLegend": false,
                    "showXAxis": true,
                    "showYAxis": true,
                    "showAxisLabels": false,
                    "showXGridLines": true,
                    "showYGridLines": true,
                    "groupData": false,
                    "chartData": {
                        "series": [{
                            "id": "series1",
                            "name": "Item 1",
                            "type": "column",
                            "data": [{ "y": 8 }, { "y": 18 }, { "y": 15 }, { "y": 22 }, { "y": 22 }, { "pt": true, "y": 29 }],
                            "colorName": "chart1",
                            "stacking": "normal"
                        }, {
                            "id": "series1578512353076",
                            "name": "Item 2",
                            "type": "column",
                            "data": [{ "y": 5, "pt": true }, { "y": 18, "pt": true }, { "y": 23, "pt": true }, {
                                "y": 26,
                                "pt": true
                            }, { "y": 15, "pt": true }, { "y": 21, "pt": true }],
                            "colorName": "chart2",
                            "marker": "none",
                            "stacking": "normal"
                        }, {
                            "id": "series1578512355605",
                            "name": "Item 3",
                            "type": "column",
                            "data": [{ "y": 5, "pt": true }, { "y": 13, "pt": true }, { "y": 22, "pt": true }, {
                                "y": 20,
                                "pt": true
                            }, { "y": 12, "pt": true }, { "y": 8, "pt": true }],
                            "colorName": "chart3",
                            "marker": "none",
                            "stacking": "normal"
                        }, {
                            "id": "series1578512365862",
                            "name": "Item 4",
                            "type": "column",
                            "data": [{ "y": 0, "pt": true }, { "y": 11, "pt": true }, { "y": 9, "pt": true }, {
                                "y": 12,
                                "pt": true
                            }, { "y": 0, "pt": true }, { "y": 2, "pt": true }],
                            "colorName": "chart4",
                            "marker": "none",
                            "stacking": "normal",
                            "zoneAxis": "x",
                            "zones": [{
                                "style": "default",
                                "color": "rgb(65, 60, 88)",
                                "fillColor": "rgb(65, 60, 88)",
                                "dashStyle": "Solid"
                            }]
                        }, {
                            "id": "series1600385371944",
                            "name": "Item 5",
                            "type": "line",
                            "data": [{ "y": 10, "pt": true }, { "y": 31, "pt": true }, { "y": 19, "pt": true }, {
                                "y": 42,
                                "pt": true
                            }, { "y": 35, "pt": true }, { "y": 60, "pt": true }],
                            "colorName": "chart5",
                            "marker": "circle"
                        }],
                        "xAxis": {
                            "visible": true,
                            "showFirstLabel": true,
                            "showLastLabel": true,
                            "endOnTick": true,
                            "categories": ["Jan", "Feb", "Mar", "Apr", "May", "June"],
                            "showAxisLine": true,
                            "showGridLine": false,
                            "showMajorTicks": false,
                            "showMinorTicks": false,
                            "axisTitle": false,
                            "axisTitleText": "Title",
                            "labelInterval": 1,
                            "dateSimple": true,
                            "dateFormat": "monthFirst",
                            "dateInterval": "daily",
                            "type": "linear",
                            "autoType": "linear",
                            "categoryType": "auto",
                            "labels": { "enabled": true },
                            "zeroAxisPadding": true
                        },
                        "yAxis": {
                            "visible": true,
                            "showFirstLabel": true,
                            "showLastLabel": true,
                            "endOnTick": true,
                            "showAxisLine": false,
                            "showGridLines": true,
                            "showMajorTicks": false,
                            "showMinorTicks": false,
                            "labelAbbreviation": false,
                            "labelCurrency": "none",
                            "labelFormat": "ones",
                            "axisTitle": "none",
                            "axisTitleText": "Title",
                            "labels": { "enabled": true },
                            "format": "currency",
                            "formatOptions": {
                                "decimal": 0,
                                "separator": true,
                                "abbreviated": false,
                                "changeStyle": "none",
                                "changeColor": true,
                                "currency": "$",
                                "dateFormat": "MMM Do",
                                "accountingStyle": false
                            },
                            "prefix": "",
                            "suffix": "k",
                            "tickAmount": 6,
                            "max": ""
                        },
                        "yAxis2": {
                            "visible": false,
                            "opposite": true,
                            "showFirstLabel": true,
                            "showLastLabel": true,
                            "endOnTick": true,
                            "showAxisLine": true,
                            "showGridLines": true,
                            "showMajorTicks": false,
                            "showMinorTicks": false,
                            "labelAbbreviation": false,
                            "labelCurrency": "none",
                            "labelFormat": "ones",
                            "axisTitle": "none",
                            "axisTitleText": "Title",
                            "labels": { "enabled": true }
                        },
                        "legend": { "enabled": false }
                    },
                    "legendPosition": "bottom",
                    "legendReverse": false,
                    "annotations": {
                        "items": [{
                            "id": "98077",
                            "nodeType": "text",
                            "x": 0.6372561947084491,
                            "y": 0.08142606985960489,
                            "blocks": [{
                                "type": "title",
                                "id": "980991601580642120",
                                "content": {
                                    "text": "Title Copy",
                                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 10 }]
                                }
                            }]
                        }],
                        "connections": {
                            "items": [{
                                "id": "980791601580642096",
                                "source": "98077",
                                "target": "/primary/117771578511685794/infographic",
                                "endDecoration": "circle",
                                "endPointIsLocked": true,
                                "canChangeConnectorType": false,
                                "targetSnapOptions": { "axis": "xAxis" }
                            }]
                        }
                    }
                }, "id": "117771578511685794"
            }]
        },
        "tray": {
            "items": [{
                "blocks": [{
                    "type": "caption",
                    "id": "2310151600384649558",
                    "content": {
                        "text": "Sample caption",
                        "styles": [{ "bold": false, "italic": false, "start": 0, "end": 14 }]
                    }
                }, {
                    "id": "139101578513217575",
                    "type": "headline",
                    "content": { "text": "$60,000", "styles": [{ "bold": false, "italic": false, "start": 0, "end": 7 }] }
                }, {
                    "id": "3083361602613148019",
                    "type": "body",
                    "content": {
                        "text": "Sample italicized body copy. The assumptions of rationality lead to modern portfolio theory.",
                        "styles": [{ "bold": false, "italic": true, "start": 0, "end": 92 }]
                    }
                }], "id": "135611578513040667", "cellColor": "background_accent"
            }], "trayWidth": 336.6983523447402, "aspectRatio": "fill", "showGutter": false
        }
    }],
    "version": 9
};

export const PreviewSlide4 = {
    "layout": {
        "headerPosition": "top",
        "showAttribution": false,
        "showElementTitle": false,
        "showFooter": "theme",
        "showHeader": true,
        "trayLayout": "none",
        "slideColor": "theme",
    },
    "states": [{
        "header": {
            "body": "<p>How Leads Will Progress Through our Funnel</p>",
            "showBody": true,
            "title": { "text": "Sample Header", "styles": [{ "bold": false, "start": 0, "end": 13 }] }
        },
        "primary": {
            "color": "accent1",
            "inverted": false,
            "items": [{
                "body": {
                    "text": "Sample body copy. At the corporate level, marketing objectives are typically broad-based.",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 89 }]
                }, "hilited": false, "title": { "text": "10,000" }, "id": "4821541555179942"
            }, {
                "body": {
                    "text": "Sample body copy. Pertaining to the general vision of the firm in the short, medium or long-term.",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 97 }]
                }, "title": { "text": "1,000" }, "id": "4831541555179944", "hilited": false
            }, {
                "body": {
                    "text": "Sample body copy. As an example, if one pictures a group of companies (or a conglomerate).",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 90 }]
                }, "title": { "text": "300" }, "id": "4841541555179945"
            }],
            "pyramidCapWidth": 0,
            "showDescription": true,
            "showTitle": false,
            "step": true,
            "capSize": 50,
            "baseSize": 6,
            "capAlign": "center"
        }
    }],
    "template_id": "pyramid",
    "version": 9,
};

export const PreviewSlide5 = {
    "template_id": "arrow_bars",
    "template_version": 1,
    "layout": {
        "headerPosition": "top",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false,
        "trayLayout": "none",
        "slideColor": "colorful",
    },
    "states": [{
        "header": {
            "title": {
                "text": "Sample Header",
                "styles": [{ "bold": false, "italic": false, "start": 0, "end": 13 }]
            },
            "body": "",
            "description": {
                "text": "Sample body copy. This is a description.",
                "styles": [{ "bold": false, "italic": false, "start": 0, "end": 40 }]
            },
            "label": { "text": "Sample Label", "styles": [{ "bold": false, "italic": false, "start": 0, "end": 12 }] }
        },
        "primary": {
            "showIndex": true,
            "showFoldEffect": true,
            "items": [{
                "value": 58,
                "showArrowHead": true,
                "label": {
                    "text": "One Sample Title",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 16 }]
                },
                "id": "31561591229491073"
            }, {
                "value": 92,
                "showArrowHead": true,
                "label": {
                    "text": "Another Sample Title",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 20 }]
                },
                "id": "31571591229491075"
            }, {
                "value": 74,
                "showArrowHead": true,
                "label": {
                    "text": "One More Sample Title",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 21 }]
                },
                "id": "31581591229491076"
            }]
        },
        "tray": {
            "items": [{
                "content_value": "81eb309f8e0855e6f181892a1fd88364c400974ad359d713b4438e77b27be9c2-I",
                "content_type": "image",
                "id": "82391591658441484",
                "aoiLeft": 168.9089048106448,
                "aoiRight": 3288.85977482088,
                "aoiTop": 4.547473508864641e-13,
                "aoiBottom": 3456,
                "scale": 0.14134837962962965,
                "version": 1,
                "cellColor": "background_accent"
            }]
        }
    }],
    "version": 9,
};

export const PreviewSlide6 = {
    "template_id": "user_testimonial",
    "template_version": 1,
    "layout": {
        "headerPosition": "none",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false,
        "slideColor": "theme",
        "trayLayout": "left_tray"
    },
    "states": [{
        "header": { "title": "", "body": "" },
        "primary": {
            "showTail": true,
            "showDesignQuotes": true,
            "frameStyle": "quote-box",
            "items": [{
                "quote": {
                    "text": "Maybe stories are just data with a soul.",
                    "styles": [{ "bold": false, "italic": false, "start": 0, "end": 28 }, {
                        "bold": true,
                        "italic": false,
                        "start": 28,
                        "end": 40
                    }]
                },
                "showAttribution": true,
                "showImage": false,
                "showLogo": false,
                "id": "3338781602614473279",
                "textAlign": "center",
                "title": { "text": "Brené Brown", "styles": [{ "bold": false, "italic": false, "start": 0, "end": 11 }] },
                "content": {
                    "aoiLeft": 0,
                    "aoiRight": 3456,
                    "aoiTop": 0,
                    "aoiBottom": 4608,
                    "scale": 0.012152777777777778,
                    "version": 1
                }
            }],
            "userFontScale": { "CanvasElement/UserTestimonial/UserTestimonialItem/UserTestimonialQuoteElement": 1 }
        },
        "tray": {
            "items": [{
                "content_value": "ec74fe6eb4905f125318fa63f6cbef4a14f7d8be0eecf0ea65ef6b1e3a66553d-I",
                "content_type": "image",
                "id": "3355461602614642620",
                "cellColor": "background_accent",
                "aoiLeft": 0,
                "aoiRight": 4000,
                "aoiTop": 183.67346938775518,
                "aoiBottom": 5816.326530612245,
                "scale": 0.11025,
                "version": 1
            }], "trayWidth": 290.2994296577947
        }
    }],
    "version": 9,
};
