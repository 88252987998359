import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import ConvertPPTSlideToAuthoringDialog from "legacy-js/react/components/Dialogs/ConvertPPTSlideToAuthoringDialog";

import { ElementSelection } from "../BaseElementEditor";

const PPTSlideSelection = ElementSelection.extend({
    renderControls: function() { },

    onClick: function(...args) {
        ShowDialog(ConvertPPTSlideToAuthoringDialog, {
            editor: this.selectionLayer.editor,
            canvas: this.element.canvas
        });
    },
});

export const editors = {
    PPTSlideSelection
};
