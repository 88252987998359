import React from "react";
import styled from "styled-components";
import InfoToolTip from "legacy-js/react/components/InfoToolTip";
import { Gap10 } from "legacy-js/react/components/Gap";

export const Container = styled.label`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #666;
  font-weight: 500;
`;

export function SimpleCaption({ children, tooltip = null }) {
    return (
        <Container>
            {children}
            {tooltip &&
                <>
                    <Gap10 />
                    <InfoToolTip title={tooltip} />
                </>
            }
        </Container>
    );
}

