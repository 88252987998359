import * as geom from "js/core/utilities/geom";

import { BaseElement } from "../base/BaseElement";
import { SVGRectElement } from "../base/SVGElement";

export class DebugBox extends BaseElement {
    _build() {
        this.rect = this.addElement("rect", () => SVGRectElement);
    }

    _calcProps(props, options) {
        let { size } = props;

        let rectProps = this.rect.calcProps(size);
        rectProps.bounds = new geom.Rect(0, 0, rectProps.size);

        return { size };
    }
}

export const elements = {
    DebugBox
};

