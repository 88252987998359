import React, { Component } from "react";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import { ShowUpgradeDialog } from "./Dialogs/BaseDialog";

class ProBadge extends Component {
    constructor(props) {
        super(props);

        if (!props.workspaceId) {
            throw new Error("workspaceId is required");
        }

        if (!props.analytics) {
            throw new Error("analytics is required");
        }

        if (props.analytics && !props.analytics.cta) {
            throw new Error("analytics.cta is required");
        }

        this.state = {
            upgradeType: this.props.upgradeType || UpgradePlanDialogType.UPGRADE_PLAN
        };
    }

    handleClick = event => {
        if (this.state.upgradeType === UpgradePlanDialogType.TEAM_NOOP) return;
        event.stopPropagation();

        if (this.props.beforeShowDialogCallback) {
            this.props.beforeShowDialogCallback();
        }

        ShowUpgradeDialog({
            type: this.state.upgradeType,
            analytics: this.props.analytics,
            workspaceId: this.props.workspaceId
        });
    }

    render() {
        const { style, show } = this.props;
        const { upgradeType } = this.state;
        const isTeamBadge = upgradeType === UpgradePlanDialogType.UPGRADE_PLAN_TO_TEAM || upgradeType === UpgradePlanDialogType.TEAM_NOOP;
        if (show) {
            const plan = isTeamBadge ? "Team" : "Pro";
            return (
                <div
                    className="pro-badge" style={style || {}}
                    onClick={this.handleClick}
                >{plan.toUpperCase()}</div>
            );
        } else {
            return null;
        }
    }
}

export default ProBadge;
