import React, { Fragment } from "react";
import { BaseElement } from "../base/BaseElement";
import styled from "styled-components";
import { PositionType } from "legacy-common/constants";
import { Icon } from "@material-ui/core";
import { ds } from "js/core/models/dataService";
import { isRenderer } from "legacy-js/config";
import { $, _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";

const VideoFrame = styled.div`
  background: black;
  border-radius: 50%;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: opacity 500ms;
  position: relative;

  video {
    height: 100%;
  }


  .btn {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .5);
    color: white;

    .MuiIcon-root {
      font-size: 50px;
    }
    
    &.rewind-icon {
      width: 30px;
      height: 30px;
      left: 75%;
      .MuiIcon-root {
        font-size: 20px;
      }
    }
  }
  
  .no-video {
    position: absolute;
    display: flex;
    align-items:  center;
    justify-content: center;
    width: 100px;
    height: 100px;
    color: #aaa;
    .MuiIcon-root {
      font-size: 80px;
    }
  }

`;

export class VideoOverlay extends BaseElement {
    get _canSelect() {
        return true;
    }

    setupElement() {
        this.videoFrameRef = React.createRef();
        this.videoRef = React.createRef();
    }

    async _load() {
        if (this.model.videoAssetId && (!this.asset || this.asset.id != this.model.videoAssetId)) {
            await this.loadVideoAsset(this.model.videoAssetId);
        }
    }

    async loadVideoAsset(assetId) {
        this.asset = await ds.assets.getAssetById(assetId);
        if (this.asset) {
            this.videoUrl = await this.asset.getURL();
        }
    }

    get video() {
        return this.videoRef.current;
    }

    _prepareToShowElement() {
        if (isRenderer) {
            return;
        }

        if (this.canvas.isCurrentCanvas) {
            this.video.currentTime = 0;
            if (this.canvas.isPlayback) {
                this.video.play();
            }
            $(this.videoFrameRef.current).css("opacity", 1);
        }
    }

    _stopElement() {
        if (isRenderer) {
            return;
        }

        this.video.pause();
        $(this.videoFrameRef.current).css("opacity", 0);
    }

    get size() {
        return this.model.size ?? 200;
    }

    _calcProps(props, options) {
        return {
            size: new geom.Size(this.size, this.size)
        };
    }

    get isVideoPlaying() {
        let video = this.videoRef.current;
        return !video.paused && !video.ended;
    }

    handleMouseEnter = () => {
        this.updateTransportControls(true);
    }

    handleMouseLeave = () => {
        this.updateTransportControls(false);
    }

    handleClick = event => {
        event.stopPropagation();

        let video = this.videoRef.current;
        if (this.isVideoPlaying) {
            video.pause();
        } else {
            video.play();
        }
        _.defer(() => {
            this.updateTransportControls(true);
        });
    }

    updateTransportControls = mouseOver => {
        $(this.videoFrameRef.current).find(".btn").hide();

        if (mouseOver) {
            if (this.isVideoPlaying) {
                $(this.videoFrameRef.current).find(".pause-icon").css("display", "flex");
            } else {
                $(this.videoFrameRef.current).find(".play-icon").css("display", "flex");
            }
            $(this.videoFrameRef.current).find(".rewind-icon").css("display", "flex");
        }
    }

    handleRewind = event => {
        event.stopPropagation();

        let video = this.videoRef.current;
        $(this.videoFrameRef.current).find(".btn").hide();
        video.currentTime = 0;
        // video.pause();
        _.defer(() => {
            this.updateTransportControls(true);
        });
    }

    get isInteractive() {
        return true;
    }

    get interactiveAction() {
        return {
            type: "video",
        };
    }

    renderChildren(transition) {
        let { size } = this.calculatedProps;
        return [
            <VideoFrame ref={this.videoFrameRef}
                style={{ width: size.width, height: size.height }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <video ref={this.videoRef} src={this.videoUrl} autoPlay={false} preload="auto"/>
                {this.videoUrl != null &&
                    <Fragment>
                        <div className="btn play-icon" onClick={this.handleClick}><Icon>play_arrow</Icon></div>
                        <div className="btn pause-icon" onClick={this.handleClick}><Icon>pause</Icon></div>
                        <div className="btn rewind-icon" onClick={this.handleRewind}><Icon>replay</Icon></div>
                    </Fragment>
                }
                {this.videoUrl == null && <div className="no-video"><Icon>video_camera_front</Icon></div>}
            </VideoFrame>
        ];
    }

    getPosition() {
        const PADDING = 20;
        let size = this.size;
        let width = size / this.parentElement.bounds.width;
        let height = size / this.parentElement.bounds.height;
        let paddingH = PADDING / this.parentElement.bounds.width;
        let paddingV = PADDING / this.parentElement.bounds.height;

        let position;

        if (this.model.x == 1 - width - paddingH && this.model.y == 1 - height - paddingV) {
            position = PositionType.BOTTOM_RIGHT;
        } else if (this.model.x == paddingH && this.model.y == 1 - height - paddingV) {
            position = PositionType.BOTTOM_LEFT;
        } else if (this.model.x == paddingH && this.model.y == paddingV) {
            position = PositionType.TOP_LEFT;
        } else if (this.model.x == 1 - width - paddingH && this.model.y == paddingV) {
            position = PositionType.TOP_RIGHT;
        } else {
            position = "Custom";
        }
        return position;
    }

    setPosition(pos, transition) {
        const PADDING = 20;
        let size = this.size;
        let width = size / this.parentElement.bounds.width;
        let height = size / this.parentElement.bounds.height;
        let paddingH = PADDING / this.parentElement.bounds.width;
        let paddingV = PADDING / this.parentElement.bounds.height;

        switch (pos) {
            case PositionType.BOTTOM_RIGHT:
                this.model.x = 1 - width - paddingH;
                this.model.y = 1 - height - paddingV;
                break;
            case PositionType.BOTTOM_LEFT:
                this.model.x = paddingH;
                this.model.y = 1 - height - paddingV;
                break;
            case PositionType.TOP_LEFT:
                this.model.x = paddingH;
                this.model.y = paddingV;
                break;
            case PositionType.TOP_RIGHT:
                this.model.x = 1 - width - paddingH;
                this.model.y = paddingV;
                break;
        }
        this.canvas.updateCanvasModel(transition);
    }
}

