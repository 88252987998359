export async function withMaxConcurrency<T>(taskRunners: (() => Promise<T>)[], maxConcurrency: number = 10): Promise<T[]> {
    const results: T[] = [];

    //A promise chain that runs the tasks from the queue one after the other until the queue is empty
    const runTask = async () => {
        const taskRunner = taskRunners.shift();
        if (!taskRunner) {
            return;
        }

        const result = await taskRunner();
        results.push(result);

        return runTask();
    };

    await Promise.all([...new Array(Math.min(maxConcurrency, taskRunners.length))].map(runTask));

    return results;
}
