import { app } from "js/namespaces";
import { buildVersion } from "legacy-js/config";
import { getSymbolCmdOrCtrl, trackActivity } from "js/core/utilities/utilities";
import isConnected from "js/core/utilities/isConnected";
import { ShowOfflineDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import AppController from "legacy-js/core/AppController";
import { PanelType } from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import getLogger, { LogGroup } from "js/core/logger";
import { isInternalBAIUser } from "legacy-js/config";
import { auth } from "js/firebase/auth";

const logger = getLogger(LogGroup.HELP_MENU);

async function getAppcuesLaunchpad(user) {
    const accountId = 84430;
    const url = `https://api.appcues.com/v1/accounts/${accountId}/users/${user.uid}/taco?url=${window.location}`;
    const res = await fetch(url);
    const data = await res.json();
    logger.info("[Appcues Launchpad]", data);
    return data;
}

export function getHelpMenu(source, includeBuildInfo) {
    const user = auth().currentUser;

    const menu = {
        overwriteTriggerHandler: true,
        items: [
            { value: "get_help", label: "Get Help", icon: "support" },
            { value: "take_tour", label: "Take Tour", icon: "tour" },
            {
                label: "Tutorials", icon: "ondemand_video", type: "menu", menu: {
                    items: async close => {
                        let items = [];
                        if (app.mainView?.editorView?.slideGrid) {
                            items.push({
                                type: "info",
                                label: "<center>Tutorials are not currently designed <br>to work with the Organize View. <br><br>Please return to the Editor View <br>and try again.</center>",
                            });
                        } else {
                            const data = await getAppcuesLaunchpad(user);
                            items = data.contents
                                .sort((a, b) => a.name > b.name ? 1 : -1)
                                .map(content => {
                                    return {
                                        label: content.name,
                                        value: content.id,
                                    };
                                });
                            if (!items.length) {
                                items.push({
                                    type: "info",
                                    label: "<center>Nothing to see here.<br>Check back later!</center>",
                                });
                            }
                        }
                        return items;
                    },
                    callback: flowId => {
                        window.Appcues.show(flowId);
                        trackActivity("HelpCenter", "PlayTour", null, null, { source, flowId }, { audit: false });
                    },
                }
            },
            { value: "give_feedback", label: "Give Feedback", icon: "edit" },
            { value: "debug_info", label: "Debug Info", icon: "bug_report" },
            { type: "divider" },
            { value: "keyboard_shortcuts", label: "Keyboard Shortcuts", shortcut: `${getSymbolCmdOrCtrl()} ?` },
        ],
        callback: action => {
            switch (action) {
                case "get_help":
                    if (!isConnected.connected) {
                        ShowOfflineDialog();
                        return;
                    }
                    window.zendeskInit();
                    window.zE(() => {
                        window.zE.activate();
                    });
                    break;
                case "give_feedback":
                    if (!isConnected.connected) {
                        ShowOfflineDialog();
                        return;
                    }
                    UIController.showFeedbackDialog();
                    break;
                case "debug_info":
                    AppController.showDebugInfo();
                    break;
                case "keyboard_shortcuts":
                    AppController.setExternalActivePanel(PanelType.SHORTCUTS);
                    break;
                case "take_tour":
                    AppController.showTour();
                    break;
                case "internal_bug_report":
                    AppController.showInternalBugReport();
                    break;
            }
        }
    };

    if (includeBuildInfo) {
        menu.items.push({ type: "divider" });
        menu.items.push({ type: "info", label: `Build ${buildVersion}` });
    }

    if (isInternalBAIUser(user.email)) {
        menu.items.push({ type: "divider" });
        menu.items.push({ value: "internal_bug_report", label: "Internal Bug Report", icon: "bug_report" });
    }

    return menu;
}

