import React, { Component } from "react";

export default class DialogContent extends Component {
    render() {
        let props = { ...this.props };
        if (props.className) {
            props.className += " dialog-content";
        } else {
            props.className = "dialog-content";
        }
        return <div {...props}>{this.props.children}</div>;
    }
}
