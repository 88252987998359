import React, { Component } from "react";
import { Popover, Popper } from "@material-ui/core";
import { Gradient } from "legacy-js/editor/ThemeEditor/components/Gradient";
import styled from "styled-components";

const ColorChits = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 14px;
`;

export class SimpleColorPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedColor: props.selectedColor,
            anchorEl: null
        };
    }

    handleShowPopover = event => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClosePopover = () => {
        this.setState({ anchorEl: null });
    }

    handleSelectColor = color => {
        this.setState({
            selectedColor: color.id,
            anchorEl: null
        });

        this.props.onSelect(color);
    }

    render() {
        const { selectedColor, colors, size } = this.props;
        const { anchorEl } = this.state;

        let color = colors.findById(selectedColor);

        if (!color) {
            color = {
                id: "none",
                value: "white",
                type: "color"
            };
        }

        return (
            <>
                <ColorChit color={color}
                    size={size}
                    onMouseDown={this.handleShowPopover}
                />
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClosePopover}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                >
                    <ColorChits>
                        {colors.map(color => (
                            <ColorChit key={color.id} size={30} color={color}
                                selected={color.id == selectedColor}
                                onMouseDown={() => this.handleSelectColor(color)}>
                            </ColorChit>
                        ))}
                    </ColorChits>
                </Popover>
            </>
        );
    }
}

const ColorChitContainer = styled.div.attrs(({ color, size, selected }) => ({
    style: {
        width: size,
        height: size,
    }
}))`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px #ccc;
  overflow: hidden;
  display: flex;

  &:hover {
    transform: scale(1.2);
  }

  transition: transform 100ms ease-in-out;
`;

const ColorChitContents = styled.div`
  width: 100%;
  height: 100%;
`;

const AssetChit = styled.div`
  background: #fff;
  width: 100px;
  height: 56px;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  div {
    display: none;
  }

  &:hover {
    div {
      display: block;
    }
  }
`;

const AssetPreview = styled.img`
  padding: 10px;
  background: #fff;
  border: solid 1px #ccc;
  width: 160px;
  height: 100%;
`;

const SplitChit = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${props => `${props.size}px ${props.size}px 0 0`};
  border-color: ${props => props.color1} transparent transparent transparent;
  background: ${props => props.color2};
`;

export function ColorChit({ color, size, onMouseDown, selected }) {
    let ref = React.useRef(null);
    let [anchorEl, setAnchorEl] = React.useState(null);

    let contents;
    switch (color.type) {
        case "multi-color":
            contents = color.value.map((c, index) => <ColorChitContents key={`${c}${index}`} style={{ background: c }} />);
            break;
        case "gradient":
            contents = <Gradient gradient={color.value} />;
            break;
        case "asset":
            contents = <img src={color.value}/>;
            break;
        case "custom":
            contents = color.value;
            break;
        case "split":
            contents = <SplitChit size={size} color1={color.value[0]} color2={color.value[1]} />;
            break;
        case "color":
        default:
            contents = <ColorChitContents style={{ background: color.value }} />;
            break;
    }

    return (
        <ColorChitContainer ref={ref}
            size={size}
            selected={selected}
            onMouseEnter={() => setAnchorEl(ref.current)}
            onMouseLeave={() => setAnchorEl(null)}
            onMouseDown={onMouseDown}
        >
            {contents}
            {color.type == "asset" && (
                <Popper open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    style={{ zIndex: 10000 }}
                    placement="bottom"
                    popperOptions={{
                        modifiers: {
                            offset: {
                                offset: "0,10",
                            },
                        },
                    }}
                >
                    <AssetPreview src={color.value} />
                </Popper>
            )}
        </ColorChitContainer>
    );
}

