import { $ } from "legacy-js/vendor";
import { controls, Dialog } from "legacy-js/editor/ui";

import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";

const EmbedSelection = ElementSelection.extend({
    createDialogue: function() {
        const dialogue = new SourceUrlDialog({ element: this.element });
        dialogue.show();
    },

    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            icon: "ondemand_video",
            label: "Set Embed URL...",
            callback: this.createDialogue.bind(this)
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Full Bleed",
            property: "fullBleed"
        });

        if (this.element.showDefaultOverlay) {
            this.$el.append(controls.createButton(this, {
                icon: "ondemand_video",
                label: "Set Embed URL...",
                className: "large_icon light",
                callback: this.createDialogue.bind(this)
            }));
        }
    }
});

const SourceUrlDialog = Dialog.extend({
    lockSlide: true,
    className: "ImportMediaDialog",

    getTitle: function() {
        return "Set Embed URL";
    },

    setupDialog: function(options) {
        this.element = options.element;
    },

    renderContents: function($contents) {
        $contents.addEl($.input("text", "Add URL..."));
        this.renderButtons($contents, { acceptLabel: "Submit" });
    },

    onShown: function() {
        this.$el.find("input").select();
    },

    onAcceptDialog: function() {
        this.setEmbedUrl();
    },

    setEmbedUrl: function() {
        this.element.model.sourceUrl = this.$el.find("input").val();

        this.element.canvas.updateCanvasModel(false).then(() => {
            this.close();
        });
    },
});

export const editors = {
    EmbedSelection,
    SourceUrlDialog
};
