import React from "react";
import styled from "styled-components";
import { Chip, Icon } from "@material-ui/core";

const TagList = styled.div`
    //margin-bottom: -10px;
    width: 100%;
    .MuiChip-root {
        margin-right: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
    }
`;

export default function TagsList({ tags, onRemove, onClick }) {
    return (
        <TagList>
            {tags.map((tag, i) => (
                <Chip
                    clickable
                    key={i}
                    color="default"
                    label={tag}
                    onDelete={() => onRemove(tag)}
                    deleteIcon={<Icon fontSize="small">close</Icon>}
                />
            ))}
            <Chip
                icon={<Icon>add_circle</Icon>}
                color="primary"
                label="ADD TAG"
                onClick={onClick}
                style={{ color: "white", fontWeight: 600 }}
            />
        </TagList>
    );
}
