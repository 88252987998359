import { _ } from "legacy-js/vendor";
import React from "reactn";
import * as geom from "js/core/utilities/geom";
import { app } from "js/namespaces";
import moment from "moment/moment";
import { HorizontalAlignType, ShapeType } from "legacy-common/constants";
import { SVGGroup } from "legacy-js/core/utilities/svgHelpers";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { SVGElement, SVGPolylineElement } from "../base/SVGElement";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";

class GanttChart extends BaseElement {
    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    getCanvasMargins() {
        return {
            left: 55, right: 55, top: 50, bottom: 40
        };
    }

    get relativeTimescale() {
        return this.model.relativeTimescale || false;
    }

    _migrate_7() {
        this.model.tasks = this.model.tasks.map(task => {
            return {
                ...task,
                startDate: task.startDate + moment(task.startDate).utcOffset() * 60000,
                endDate: task.endDate + moment(task.endDate).utcOffset() * 60000
            };
        });

        if (this.model.milestones) {
            this.model.milestones = this.model.milestones.map(milestone => {
                return {
                    ...milestone,
                    date: milestone.date + moment(milestone.date).utcOffset() * 60000
                };
            });
        }
    }

    _migrate_9() {
        this.model.items = _.cloneDeep(this.model.tasks);
        delete this.model.tasks;
    }

    // We can't do this as a migration because we already have a v9 migration in place
    normalizeDates() {
        this.model.items = this.model.items.map(task => {
            return {
                ...task,
                startDate: task.startDate + moment().utcOffset() * 60000,
                endDate: task.endDate + moment().utcOffset() * 60000
            };
        });

        if (this.model.milestones) {
            this.model.milestones = this.model.milestones.map(milestone => {
                return {
                    ...milestone,
                    date: milestone.date + moment().utcOffset() * 60000
                };
            });
        }

        this.model.isNormalized = true;
    }

    get startDate() {
        if (app.isDraggingItem && this.cachedStartDate) {
            return this.cachedStartDate;
        }

        let earliestDate = this.model.items.length ? moment.utc(_.minBy(this.model.items, item => item.startDate).startDate) : moment.utc();

        if (this.model.milestones.length) {
            let earliestMilestone = moment.utc(_.minBy(this.model.milestones, milestone => milestone.date).date);
            earliestDate = moment.min(earliestDate, earliestMilestone).startOf("day");
        }

        let startDate;
        if (this.relativeTimescale) {
            startDate = earliestDate;
        } else {
            switch (this.axisScale) {
                case "days":
                    startDate = earliestDate;
                    break;
                case "weeks":
                    startDate = earliestDate.startOf("week");
                    break;
                case "months":
                    startDate = earliestDate.startOf("month");
                    break;
                case "quarters":
                    startDate = earliestDate.startOf("quarter");
                    break;
                case "years":
                    startDate = earliestDate.startOf("year");
                    break;
            }
        }
        this.cachedStartDate = startDate.clone();
        return startDate;
    }

    get endDate() {
        if (app.isDraggingItem && this.cachedEndDate) {
            return this.cachedEndDate;
        }

        let latestDate = this.model.items.length ? moment.utc(_.maxBy(this.model.items, item => item.endDate).endDate) : moment.utc();

        if (this.model.milestones.length) {
            let latestMilestone = moment.utc(_.maxBy(this.model.milestones, milestone => milestone.date).date);
            latestDate = moment.max(latestDate, latestMilestone);
        }

        latestDate = latestDate.endOf("day");

        if (moment.duration(latestDate.diff(this.startDate)).asDays() < 7) {
            latestDate = this.startDate.add(7, "days");
        }

        let endDate;
        if (this.relativeTimescale) {
            switch (this.axisScale) {
                case "days":
                    endDate = latestDate;
                    break;
                case "weeks":
                    endDate = this.startDate.clone().add(Math.floor(moment.duration(latestDate.diff(this.startDate)).asWeeks()) + 1, "weeks");
                    break;
                case "months":
                case "quarters":
                    endDate = this.startDate.clone().add(Math.floor(moment.duration(latestDate.diff(this.startDate)).asMonths()) + 1, "months");
                    break;
                case "years":
                    endDate = this.startDate.clone().add(Math.floor(moment.duration(latestDate.diff(this.startDate)).asYears()) + 1, "years");
                    break;
            }
        } else {
            switch (this.axisScale) {
                case "days":
                    endDate = latestDate;//.add(1, "day");
                    break;
                case "weeks":
                    endDate = latestDate.add(1, "week").startOf("week");
                    break;
                case "months":
                    endDate = latestDate.add(1, "month").startOf("month");
                    break;
                case "quarters":
                    endDate = latestDate.add(1, "quarter").startOf("quarter");
                    break;
                case "years":
                    endDate = latestDate.add(1, "year").startOf("year");
                    break;
            }
        }
        this.cachedEndDate = endDate.clone();
        return endDate;
    }

    get duration() {
        return moment.duration(this.endDate.diff(this.startDate));
    }

    get showGrid() {
        return this.model.showGrid != undefined ? this.model.showGrid : true;
    }

    get axisScale() {
        return this.model.axisScale || "days";
    }

    _build() {
        if (!this.model.items) {
            this.model.items = [];
        }
        if (!this.model.milestones) {
            this.model.milestones = [];
        }

        if (!this.model.isNormalized) {
            this.normalizeDates();
        }

        if (this.showGrid) {
            this.grid = this.addElement("grid", () => GanttChartGrid);
            this.grid.layer = -2;
        }

        if (this.model.milestones.length) {
            this.milestones = this.addElement("milestones", () => GanttChartMilestones);
            this.milestones.layer = -1;
        } else {
            this.milestones = null;
        }

        this.tasks = this.addElement("tasks", () => GanttChartTasks);
    }

    _calcProps(props, options) {
        let { size } = props;

        let tasksTopPadding = 0;

        let gridHeigth = size.height;
        let plotBoxBounds = new geom.Rect(0, 0, size);

        const calcGridProps = () => {
            let gridProps = this.grid.calcProps(new geom.Size(size.width, gridHeigth), {
                ...options,
                startDate: this.startDate,
                endDate: this.endDate,
                axisScale: this.axisScale,
                relativeTimescale: this.relativeTimescale
            });
            gridProps.bounds = new geom.Rect(0, size.height - gridProps.size.height, gridProps.size);
            plotBoxBounds = gridProps.plotBoxBounds.offset(gridProps.bounds.position);
        };

        if (this.grid) {
            calcGridProps();
        }

        if (this.milestones) {
            let milestonesProps = this.milestones.calcProps(plotBoxBounds.size, options);
            milestonesProps.bounds = plotBoxBounds;
            tasksTopPadding = milestonesProps.labelSpacing;

            if (this.grid && tasksTopPadding > 0) {
                gridHeigth -= tasksTopPadding;
                calcGridProps();
            }
        }

        let tasksProps = this.tasks.calcProps(plotBoxBounds.size, options);
        tasksProps.bounds = plotBoxBounds;

        return { size, isFit: tasksProps.isFit };
    }
}

class GanttChartGrid extends BaseElement {
    _build() {
        this.lines = this.addElement("lines", () => GanttChartGridLines);
        this.labels = this.addElement("labels", () => GanttChartGridLabels);
    }

    _calcProps(props, options) {
        const { size } = props;

        const plotBoxBounds = new geom.Rect(20, 0, size.width - 40, size.height - this.labels.styles.fontSize - 6);
        const labelsSpacing = 6;
        const labelsHeight = this.labels.styles.fontSize;
        const labelsBounds = new geom.Rect(plotBoxBounds.left, plotBoxBounds.top + plotBoxBounds.height + labelsSpacing, plotBoxBounds.width, labelsHeight);

        this.lines.calcProps(size, { ...options, plotBoxBounds });
        this.labels.calcProps(size, { ...options, labelsBounds });

        return { size, plotBoxBounds };
    }
}

class GanttChartGridLines extends SVGElement {
    renderSVG(props, styles) {
        const { startDate, endDate, axisScale, plotBoxBounds } = props.options;

        const children = [];

        const minSpacing = 150;

        const duration = moment.duration(endDate.diff(startDate));

        let totalTicks;
        switch (axisScale) {
            case "days":
                totalTicks = Math.round(duration.asDays());
                break;
            case "weeks":
                totalTicks = Math.round(duration.asWeeks());
                break;
            case "months":
                totalTicks = Math.round(duration.asMonths());
                break;
            case "quarters":
                totalTicks = Math.round(duration.asMonths() / 3);
                break;
            case "years":
                totalTicks = Math.round(duration.asYears());
                break;
        }

        const tickSpacing = (plotBoxBounds.width) / totalTicks;
        const skip = Math.ceil(minSpacing / tickSpacing);

        let x = plotBoxBounds.left;
        for (let tick = 0; tick <= totalTicks; tick += skip) {
            children.push(<line key={tick} x1={x} y1={0} x2={x} y2={plotBoxBounds.height} />);
            x += tickSpacing * skip;
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <g style={styles} id={this.id}>{children}</g>
            </SVGGroup>
        );
    }
}

class GanttChartGridLabels extends SVGElement {
    get shouldApplyTextStyle() {
        return true;
    }

    renderSVG(props, styles) {
        const { axisScale, relativeTimescale, startDate, endDate, labelsBounds } = props.options;

        const children = [];

        const minSpacing = 150;

        const duration = moment.duration(endDate.diff(startDate));

        let totalTicks;
        let getLabelText;
        switch (axisScale) {
            case "days":
                totalTicks = Math.round(duration.asDays());
                if (relativeTimescale) {
                    getLabelText = day => `Day ${day + 1}`;
                } else {
                    getLabelText = day => startDate.clone().startOf("day").add(day, "days").format("MMM D");
                }
                break;
            case "weeks":
                totalTicks = Math.round(duration.asWeeks());
                if (relativeTimescale) {
                    getLabelText = week => `Week ${week + 1}`;
                } else {
                    getLabelText = week => startDate.clone().startOf("week").add(week, "weeks").format("[Week] w");
                }
                break;
            case "months":
                totalTicks = Math.round(duration.asMonths());
                if (relativeTimescale) {
                    getLabelText = month => `Month ${month + 1}`;
                } else {
                    getLabelText = month => startDate.clone().startOf("month").add(month, "months").format("MMM YY");
                }
                break;
            case "quarters":
                totalTicks = Math.round(duration.asMonths() / 3);
                if (relativeTimescale) {
                    getLabelText = quarter => `Quarter ${quarter + 1}`;
                } else {
                    getLabelText = quarter => startDate.clone().startOf("quarter").add(quarter, "quarters").format("[Q]Q YYYY");
                }
                break;
            case "years":
                totalTicks = Math.round(duration.asYears());
                if (relativeTimescale) {
                    getLabelText = year => `Year ${year + 1}`;
                } else {
                    getLabelText = year => startDate.clone().startOf("year").add(year, "years").format("YYYY");
                }
                break;
        }

        const tickSpacing = (labelsBounds.width) / totalTicks;
        const skip = Math.ceil(minSpacing / tickSpacing);
        const date = startDate.clone();

        let x = labelsBounds.left;
        for (let tick = 0; tick <= totalTicks; tick += skip) {
            children.push(<text fontFamily={styles.fontFamily} key={tick} x={x} y={labelsBounds.top + labelsBounds.height} textAnchor="middle" >{getLabelText(tick)}</text>);
            date.add(skip, "days");
            x += tickSpacing * skip;
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <g id={this.id} style={styles}>{children}</g>
            </SVGGroup>
        );
    }
}

class GanttChartTasks extends CollectionElement {
    getChildItemType() {
        return GanttChartTask;
    }

    // get collectionPropertyName() {
    //     return "tasks";
    // }

    get defaultItemData() {
        let startDate = this.startDate;
        let endDate = this.endDate;

        let totalDays = moment.duration(endDate.diff(startDate)).asDays();

        return {
            startDate: this.startDate.toDate().getTime(),
            endDate: this.startDate.clone().add(totalDays / 2, "days").toDate().getTime()
        };
    }

    get maxItemCount() {
        return 18;
    }

    get startDate() {
        return this.parentElement.startDate;
    }

    get endDate() {
        return this.parentElement.endDate;
    }

    get showImage() {
        return this.model.showImage || false;
    }

    get showTaskDetail() {
        return this.model.showTaskDetail || "duration";
    }

    _calcProps(props, options) {
        let { size } = props;

        let startDate = this.startDate;
        let endDate = this.endDate;

        let totalDays = Math.round(moment.duration(endDate.diff(startDate)).asDays());
        let dayWidth = size.width / totalDays;

        const maxRowHeight = 55;

        let rowGap = this.styles.vGap;

        let rowHeight = Math.min(maxRowHeight, (size.height - (this.itemCount - 1) * rowGap) / this.itemCount);
        if (rowHeight <= 27) {
            if (rowHeight <= 14) {
                props.isFit = false;
            }
            for (let task of this.itemElements) {
                task.updateStyles(this.styles.tiny.GanttChartTask);
            }
            rowGap = this.styles.tiny.vGap;
            rowHeight = Math.min(maxRowHeight, (size.height - (this.itemCount - 1) * rowGap) / this.itemCount);
        } else if (rowHeight <= 35) {
            for (let task of this.itemElements) {
                task.updateStyles(this.styles.small.GanttChartTask);
            }
            rowGap = this.styles.small.vGap;
            rowHeight = Math.min(maxRowHeight, (size.height - (this.itemCount - 1) * rowGap) / this.itemCount);
        }

        let y = size.height / 2 - (this.itemElements.length * (rowHeight + rowGap) - rowGap) / 2;

        for (let item of this.itemElements) {
            if (item.taskEndDate.isBefore(item.taskStartDate)) {
                item.model.endDate = item.taskStartDate.toDate().getTime();
            }

            let startX = moment.duration(item.taskStartDate.diff(this.startDate)).asDays() * dayWidth;
            let endX = moment.duration(item.taskEndDate.diff(this.startDate)).asDays() * dayWidth;

            let itemProps = item.calcProps(new geom.Size(endX - startX, rowHeight), {
                rightSpace: size.width - endX,
                leftSpace: startX
            });
            itemProps.bounds = new geom.Rect(startX, y, itemProps.size);

            y += rowHeight + rowGap;
        }

        this.rowHeight = rowHeight + rowGap;

        return { size };
    }
}

class GanttChartTask extends CollectionItemElement {
    get selectionPadding() {
        return 0;
    }

    get taskStartDate() {
        return moment.utc(this.model.startDate).startOf("day");
    }

    get taskEndDate() {
        return moment.utc(this.model.endDate).endOf("day");
    }

    get duration() {
        return moment.duration(this.taskEndDate.diff(this.taskStartDate.startOf("day")));
    }

    get showImage() {
        return this.parentElement.showImage;
    }

    get showTaskDetail() {
        return this.parentElement.showTaskDetail;
    }

    _build() {
        if (this.showImage) {
            this.content = this.addElement("content", () => FramedMediaElement, {
                frameType: ShapeType.CIRCLE,
                allowUnframedImages: false
            });
        }

        this.text = this.addElement("title", () => GanntChartTaskText, {
            scaleTextToFit: true,
            constrainWidth: true,
            autoWidth: false,
            autoHeight: false,
            minTextScale: 0.75
        });

        if (this.showTaskDetail != "none") {
            let labelModel;

            if (this.showTaskDetail == "duration") {
                let days = Math.round(this.duration.asDays());
                labelModel = days + " day".pluralize(days > 1);
            } else if (this.showTaskDetail == "dates") {
                labelModel = this.taskStartDate.format("MMM D") + " to " + this.taskEndDate.format("MMM D");
            }
            if (labelModel) {
                this.label = this.addElement("label", () => TextElement, {
                    model: {
                        label: {
                            text: labelModel
                        }
                    },
                    scaleTextToFit: true,
                    autoWidth: true,
                    autoHeight: false,
                    canSelect: false,
                    canRollover: false,
                    canEdit: false,
                });
            }
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let paddingLeft = 0;// styles.paddingLeft;
        let paddingRight = 0;// styles.paddingRight;

        let left = 0;

        if (this.showImage) {
            let contentProps = this.content.calcProps(new geom.Size(size.height, size.height));
            contentProps.bounds = new geom.Rect(0, 0, contentProps.size);

            paddingLeft = contentProps.size.width;

            // const CONTENT_SCALE = 1;
            // let content = this.content.calcProps(new geom.Size(size.height * CONTENT_SCALE, size.height * CONTENT_SCALE));
            // content.bounds = new geom.Rect(size.height * CONTENT_SCALE / 3, size.height / 2 - content.size.height / 2, content.size);
            // paddingLeft = content.bounds.right - size.height * CONTENT_SCALE / 4;
        }

        let labelSize = new geom.Size(0, 0);
        let labelProps;
        if (this.showTaskDetail != "none") {
            labelProps = this.label.calcProps(new geom.Size(400, size.height));
            labelProps.bounds = new geom.Rect(size.width - labelProps.size.width, 0, labelProps.size);
            paddingRight = labelProps.size.width;
            labelSize = labelProps.size;
        }

        let availableTextInBarSize = new geom.Size(size.width - paddingLeft - paddingRight, size.height);
        let text = this.text.calcProps(availableTextInBarSize);

        if (!text.isTextFit) {
            props.insideFrame = false;

            this.text.styles.filter = "textStroke";
            if (this.label) {
                this.label.styles.filter = "textStroke";
            }

            const labelMarginLeft = 10;

            // try to fit to right of task
            text = this.text.calcProps(new geom.Size(options.rightSpace - labelSize.width, size.height), { autoWidth: true });
            if (text.isTextFit) {
                text.bounds = new geom.Rect(size.width + 20, 0, text.size.width, size.height);
                if (this.showTaskDetail != "none") {
                    labelProps.bounds = new geom.Rect(text.bounds.right + labelMarginLeft, size.height / 2 - labelSize.height / 2, labelSize);
                }
            } else {
                // try to fit to left of task
                text = this.text.calcProps(new geom.Size(options.leftSpace, size.height), {
                    textAlign: HorizontalAlignType.RIGHT,
                    autoWidth: true
                });
                text.bounds = new geom.Rect(-text.size.width - labelSize.width - labelMarginLeft - 20, size.height / 2 - text.size.height / 2, text.size);
                if (this.showTaskDetail != "none") {
                    labelProps.bounds = new geom.Rect(text.bounds.right + labelMarginLeft, size.height / 2 - labelSize.height / 2, labelSize);
                }
            }

            if (!text.isTextFit && availableTextInBarSize.width > options.rightSpace && availableTextInBarSize.width > options.leftSpace) {
                props.insideFrame = true;
                text = this.text.calcProps(availableTextInBarSize);
                text.bounds = new geom.Rect(paddingLeft, size.height / 2 - text.size.height / 2, availableTextInBarSize);
            }
        } else {
            props.insideFrame = true;
            text.bounds = new geom.Rect(paddingLeft, size.height / 2 - text.size.height / 2, text.size);
            text.bounds = new geom.Rect(paddingLeft, 0, text.size.width, size.height);
        }

        if (this.hasStoredPropChanged("insideFrame", props.insideFrame)) {
            this.markStylesAsDirty();
        }

        return { size };
    }

    getBackgroundColor(forElement) {
        if (!this.calculatedProps?.insideFrame || forElement === this.decoration) {
            return this.parentElement.getBackgroundColor();
        } else {
            return this.getDecorationFillColor();
        }
    }
}

class GanntChartTaskText extends TextElement {
    get requireParentSelection() {
        return false;
    }

    get passThroughSelection() {
        return true;
    }
}

class GanttChartMilestones extends CollectionElement {
    getChildItemType() {
        return GanttChartMilestone;
    }

    get collectionPropertyName() {
        return "milestones";
    }

    get minItemCount() {
        return 0;
    }

    get maxItemCount() {
        return 6;
    }

    get startDate() {
        return this.parentElement.startDate;
    }

    get endDate() {
        return this.parentElement.endDate;
    }

    _calcProps(props, options) {
        let { size } = props;
        let totalDays = moment.duration(this.endDate.diff(this.startDate)).asDays();
        let dayWidth = size.width / totalDays;

        let labelSpacing = 0;

        for (let milestone of this.itemElements) {
            let milestoneProps = milestone.calcProps(new geom.Size(size.width, size.height));
            labelSpacing = Math.max(labelSpacing, milestoneProps.size.height);

            let milestoneX = moment.duration(milestone.date.diff(this.startDate)).asDays() * dayWidth;

            if (milestone.flipLabel) {
                milestoneProps.bounds = new geom.Rect(milestoneX - milestoneProps.size.width, 0, milestoneProps.size);
            } else {
                milestoneProps.bounds = new geom.Rect(milestoneX, 0, milestoneProps.size);
            }
        }

        return { size, labelSpacing };
    }
}

class GanttChartMilestone extends CollectionItemElement {
    get canSelect() {
        return true;
    }

    get selectionPadding() {
        return 5;
    }

    get date() {
        return moment.utc(this.model.date);
    }

    get flipLabel() {
        return this.model.flipLabel || false;
    }

    _build() {
        this.label = this.addElement("label", () => TextElement, {
            autoWidth: true,
            autoHeight: true,
            uiOffset: 20,
            placeholder: "Type Milestone",
            allowAlignment: false
        });

        this.line = this.addElement("line", () => SVGPolylineElement);
    }

    _calcProps(props, options) {
        let { size } = props;

        // this.label.styles.textAlign = this.flipLabel ? HorizontalAlignType.RIGHT : HorizontalAlignType.LEFT;
        let label = this.label.calcProps(size);

        let line = this.line.createProps();
        if (this.flipLabel) {
            line.path = [[label.size.width, 0], [label.size.width, size.height]];
        } else {
            line.path = [[0, 0], [0, size.height]];
        }

        return { size: label.size };
    }
}

export const elements = {
    GanttChart
};
