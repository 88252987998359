const { TrayType } = require("../../../constants");
const obsoleteTemplates = [];

obsoleteTemplates.push({
    getInstanceProps: () => ({
        elementType: "TableFrameV1",

        allowColorBackgrounds: false,
        allowColorful: false,

        defaultData: {
            primary: {
                showTopLeftCell: true,
                showBorder: true,
                showColGridLines: true,
                showRowGridLines: true,
                showHeaderRow: true,
                showHeaderCol: true,
                autoFit: true,
                cols: [
                    {
                        index: 0,
                        style: "defaultCol",
                        break: false,
                    },
                    {
                        index: 1,
                        style: "defaultCol",
                        break: false,
                    },
                    {
                        index: 2,
                        style: "defaultCol",
                        break: false,
                    },
                    {
                        index: 3,
                        style: "defaultCol",
                        break: false,
                    },
                    {
                        index: 4,
                        style: "defaultCol",
                        break: false,
                    }
                ],
                rows: [
                    {
                        index: 0,
                        style: "defaultRow",
                        break: false,
                    },
                    {
                        index: 1,
                        style: "defaultRow",
                        break: false,
                    },
                    {
                        index: 2,
                        style: "defaultRow",
                        break: false,
                    },
                    {
                        index: 3,
                        style: "defaultRow",
                        break: false,
                    }
                ],
                cells: [
                    {
                        "row": 0,
                        "col": 0,
                    },
                    {
                        "row": 0,
                        "col": 1,
                    },
                    {
                        "row": 0,
                        "col": 2,
                    },
                    {
                        "row": 0,
                        "col": 3,
                    },
                    {
                        "row": 0,
                        "col": 4,
                    },
                    {
                        "row": 1,
                        "col": 0,
                    },
                    {
                        "row": 1,
                        "col": 1,
                    },
                    {
                        "row": 1,
                        "col": 2,
                    },
                    {
                        "row": 1,
                        "col": 3,
                    },
                    {
                        "row": 1,
                        "col": 4,
                    },
                    {
                        "row": 2,
                        "col": 0,
                    },
                    {
                        "row": 2,
                        "col": 1,
                    },
                    {
                        "row": 2,
                        "col": 2,
                    },
                    {
                        "row": 2,
                        "col": 3,
                    },
                    {
                        "row": 2,
                        "col": 4,
                    },
                    {
                        "row": 3,
                        "col": 0,
                    },
                    {
                        "row": 3,
                        "col": 1,
                    },
                    {
                        "row": 3,
                        "col": 2,
                    },
                    {
                        "row": 3,
                        "col": 3,
                    },
                    {
                        "row": 3,
                        "col": 4,
                    }
                ]
            }
        }

    }), getStaticProps: () => ({
        id: "table",
        hideTemplate: true,
        updateTemplateId: "table-v2",
        updateMigration: migrateTableToVersion2,
        updateSummaryText: "Mergeable cells, new cell styling options, support for more rows and columns, and better performance,",
        icon: "table",
        title: "Table",
        description: "Organize your data into columns and rows to present information, categorize ideas, or compare items.",
        category: "basic popular",
        tags: "traction objective goal OKR market share feature features product benefit benefits business differentiate project differentiator differentiators characteristic characteristics attribute attributes qualities proposition propositions percent percentage revenue metrics business profit sales income volume expenses costs summary summarize accomplish accomplishments accomplished make a list amount total sum churn metrics financial model compare vs by categories results business update status relationship",
    })
});

function migrateTableToVersion2(canvas) {
    let table = canvas.layouter.elements.primary.table;

    table.model.tableWidth = table.calculatedProps.bounds.width / table.calculatedProps.allowedSize.width;
    table.model.tableHeight = table.calculatedProps.bounds.height / table.calculatedProps.allowedSize.height;
    table.model.tableBackgroundColor = canvas.model.layout.backgroundColor;

    if (table.model.showHeaderCol) {
        if (table.model.cols[0].style == "defaultCol") {
            table.model.cols[0].style = "headerCol";
        }
        table.model.showHeaderCol = null;
    }

    if (table.model.showHeaderRow) {
        if (table.model.rows[0].style == "defaultRow") {
            table.model.rows[0].style = "headerRow";
        }
        table.model.showHeaderRow = null;
    }

    for (let cell of table.cells) {
        if (cell.contents.type == "TableCellText") {
            cell.model.fontSize = cell.contents.calculatedProps.fontSize;
        }
        if (cell.emphasized) {
            cell.model.cellStyle = "stroke";
            cell.model.cellColor = "slide";
            cell.model.bold = true;
        }
    }
}

obsoleteTemplates.push({
    getInstanceProps: () => ({
        elementType: "BulletPoints",

        variations: "list",

        defaultImageTrayType: TrayType.RIGHT_TRAY,
        allowColorful: true,
        defaultData: {
            "primary": {
                "forceSingleColumn": false,
                "items": [
                    {
                        "content_type": "icon",
                        "title": "",
                        "body": "",
                        "content_value": ""
                    }
                ],
                "listStyle": "bullets"
            }
        }

    }), getStaticProps: () => ({
        id: "textgrid_bullets",
        hideTemplate: true,
        updateTemplateId: "textlist",
        updateMigration: migrateTextListToVersion2,
        updateSummaryText: "Better performance, more control over up to 3 columns of items with optional headers, and new checked item style",
        icon: "textgrid_bullets",
        title: "Bullet Points",
        description: "Get the main point across with a concise list of headlines",
        category: "basic popular",
        tags: "bullet update status points text message messages values share our vision feature features benefit benefits differentiate differentiator differentiators characteristic characteristics attribute attributes qualities proposition propositions summary summarize accomplish accomplishments accomplished make a list services solution key takeaway highlight priority case study product features"
    })
});

obsoleteTemplates.push({
    getInstanceProps: () => ({
        elementType: "BulletPoints",

        defaultData: {
            "primary": {
                "forceSingleColumn": false,
                "items": [
                    {
                        "content_type": "icon",
                        "title": "",
                        "body": "",
                        "content_value": ""
                    }
                ],
                "listStyle": "numbered"
            }
        },

        variations: "list",

        defaultImageTrayType: TrayType.RIGHT_TRAY,
        allowColorful: true,

    }), getStaticProps: () => ({
        hideTemplate: true,
        id: "textgrid_numbered",
        icon: "textgrid_numbered",
        updateTemplateId: "textlist",
        updateMigration: migrateTextListToVersion2,
        title: "Numbered List",
        description: "Walk your audience through numbered steps, key takeaways, or action items.",
        category: "basic",
        tags: "pipeline procedure schedule objective goal OKR numbered values share our vision feature features benefit benefits differentiate differentiator differentiators characteristic characteristics attribute attributes qualities proposition propositions steps process summary summarize accomplish accomplishments accomplished list message services solution key takeaway highlight priority make a list bullet update status points call to action schedule"
    })
});

function migrateTextListToVersion2(canvas) {
    let textList = canvas.layouter.elements.primary;

    if (textList.model.forceSingleColumn) {
        textList.model.autoArrange = false;
        for (let item of textList.model.items) {
            item.col = 0;
        }
    }

    if (textList.model.userFontScale) {
        for (let key of Object.keys(textList.model.userFontScale)) {
            textList.model.userFontScale[key.replace("BulletPoints", "TextList")] = textList.model.userFontScale[key];
            delete textList.model.userFontScale[key];
        }
    }
}

module.exports = obsoleteTemplates;
