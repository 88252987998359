import React from "react";
import { GlobalStateController } from "bai-react-global-state";

import { _ } from "legacy-js/vendor";
import { trackActivity } from "js/core/utilities/utilities";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog, ShowUpgradeDialog,
    ShowWarningDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import TeamSlideDialog from "legacy-js/react/views/TeamResources/dialogs/TeamSlideDialog";
import LockedSharedSlideDialog from "legacy-js/react/views/TeamResources/dialogs/LockedSharedSlideDialog";
import SwitchTemplateDialog from "legacy-js/react/views/SwitchTemplate/SwitchTemplateDialog";
import { app } from "js/namespaces";
import { appVersion, isPPTAddin } from "legacy-js/config";
import { FeatureType } from "legacy-common/features";
import { ds } from "js/core/models/dataService";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import { ExportToPPT } from "legacy-js/exporter/exportToPPT";
import { exportToGoogleSlides } from "legacy-js/exporter/exportToGoogleSlides";
import { getCanvasBundle } from "legacy-js/canvas";
import PresentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { linkedSlides } from "js/core/utilities/conversions";
import { CreateSharedSlideDialog } from "legacy-js/react/views/UserOptions/dialogs/CreateSharedSlideDialog";
import { getPricingPageUrl } from "js/core/utilities/pricing";

export const CanvasExportType = {
    JPEG: "jpeg",
    PPTX: "pptx",
    GOOGLE: "google"
};

export class FailedToRenderCanvasError extends Error { }

export class CanvasController extends GlobalStateController {
    constructor(initialState, collaborationSlidesLockService) {
        super({
            ...initialState,
            wrapperRef: React.createRef()
        });

        this.isCurrentCanvas = false;
        this.renderCanvasPromise = null;
        this.collaborationSlidesLockService = collaborationSlidesLockService;
    }

    get canvas() {
        return this._state.canvas;
    }

    get maxCanvasScale() {
        return this._maxCanvasScale;
    }

    set maxCanvasScale(maxCanvasScale) {
        this._maxCanvasScale = maxCanvasScale;

        if (this.canvas) {
            this.canvas.maxCanvasScale = maxCanvasScale;
        }
    }

    get canvasScale() {
        return this._canvasScale;
    }

    set canvasScale(canvasScale) {
        this._canvasScale = canvasScale;

        if (this.canvas) {
            this.canvas.canvasScale = canvasScale;
        }
    }

    get slide() {
        return this._state.slide;
    }

    get slideId() {
        return this._state.slide.id;
    }

    get isTemplateObsolete() {
        return !!this.canvas?.slideTemplate?.constructor?.updateTemplateId;
    }

    async renderCanvas(forceSlideVersion) {
        const { slide, canvasWidth, canvasHeight, isEditable } = this._state;

        if (!this.renderCanvasPromise) {
            this.renderCanvasPromise = (async () => {
                let slideVersion;
                if (forceSlideVersion) {
                    slideVersion = forceSlideVersion;
                } else if (slide.loaded) {
                    // Model is loaded - get version from model
                    slideVersion = (slide.get("version") ?? appVersion);
                } else {
                    // Get version from metadata to avoid loading model
                    const slidesMetadata = await PresentationEditorController.slidesMetadataLoadPromise;
                    if (slidesMetadata[slide.id]) {
                        slideVersion = slidesMetadata[slide.id].version ?? appVersion;
                    } else {
                        // Metadata doesn't contain slide - will have to load model
                        await slide.load();
                        slideVersion = (slide.get("version") ?? appVersion);
                    }
                }

                const { SlideCanvas } = await getCanvasBundle(slideVersion);

                // create the slide canvas
                const canvas = new SlideCanvas({
                    dataModel: slide,
                    canvasWidth,
                    canvasHeight,
                    editable: true
                });
                canvas.canvasController = this;
                canvas.isEditable = !!isEditable;

                await this._updateState({ canvas, canvasWidth, canvasHeight });

                if (this.canvasScale) {
                    canvas.canvasScale = this.canvasScale;
                }
                if (this.maxCanvasScale) {
                    canvas.maxCanvasScale = this.maxCanvasScale;
                }

                await canvas.renderSlide()
                    .catch(err => {
                        throw new FailedToRenderCanvasError(err);
                    });

                return canvas;
            })();
        }

        return await this.renderCanvasPromise;
    }

    async setEditable(isEditable) {
        await this._updateState({ isEditable });

        if (!this.renderCanvasPromise) {
            return;
        }
        await this.renderCanvasPromise;

        this.canvas.isEditable = isEditable;

        await this.canvas.layouter?.generationPromiseChain;

        this.canvas.layouter?.refreshRender();
    }

    async reloadCanvas(forceSlideVersion) {
        if (this.renderCanvasPromise) {
            await this.renderCanvasPromise;
        }

        const wasCurrentCanvas = this.canvas.isCurrentCanvas;
        if (wasCurrentCanvas) {
            await this.removeAsCurrentCanvas();
        }

        const previousCanvas = this.canvas;

        this.renderCanvasPromise = null;

        await this.renderCanvas(forceSlideVersion);

        previousCanvas.remove();

        if (wasCurrentCanvas) {
            await this.setAsCurrentCanvas();
        }
    }

    async setAsCurrentCanvas(canEditSharedSlide = false) {
        // Ensure canvas is rendered
        await this.renderCanvas();

        const { canvas, slide } = this._state;

        if (!slide.isLocked() || canEditSharedSlide) {
            app.currentCanvas = canvas;

            canvas.setAsCurrentCanvas();

            await canvas.prepareToShowElements();
        }
    }

    setOpacity(opacity) {
        return this._updateState({ opacity });
    }

    removeAsCurrentCanvas() {
        const { canvas } = this._state;
        if (!canvas) {
            return;
        }

        if (app.currentCanvas === canvas) {
            app.currentCanvas = null;
        }

        canvas.removeAsCurrentCanvas();
    }

    /*
       Locks slide for collaborators for lockTimeSeconds
     */
    lockSlideForCollaborators(lockTimeSeconds = 5) {
        if (!this.collaborationSlidesLockService || this.freezeCollaboratorsLock) {
            return;
        }
        this.collaborationSlidesLockService.lock(this.slideId, lockTimeSeconds);
    }

    /*
       Explicitly unlocks slide for collaborators
     */
    unlockSlideForCollaborators() {
        if (!this.collaborationSlidesLockService || this.freezeCollaboratorsLock) {
            return;
        }
        this.collaborationSlidesLockService.unlock(this.slideId);
    }

    /*
      Is slide locked by user (locked for other collaborators)
     */
    isLockedForCollaborators() {
        if (!this.collaborationSlidesLockService) {
            return false;
        }
        const lockState = this.collaborationSlidesLockService.getLockState(this.slideId);
        return !!lockState?.isLockedByMe;
    }

    getTemplate() {
        const { canvas } = this._state;
        return canvas.getTemplate();
    }

    getTemplateName() {
        const { canvas } = this._state;

        const template = this.getTemplate();

        if (canvas.bundleVersion < appVersion) {
            return `${template.title} (v${canvas.bundleVersion})`;
        } else {
            return template.title;
        }
    }

    async editLibrarySlide() {
        const { presentation, slide } = this._state;

        if (isPPTAddin) {
            ShowWarningDialog({
                title: "Sorry, this Team Slide cannot be edited.",
                message: "Team Slides cannot be edited from PowerPoint."
            });
            return;
        }

        if (app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, presentation.getWorkspaceId()) && slide.isLibrarySlideInCurrentUserOrg()) {
            if (await ShowConfirmationDialog({
                title: "This is a Shared Slide and cannot be edited from within a presentation. Would you like to open the Properties menu?",
                message: "Edits to Shared Slides are universal and can be made from the Properties menu or Team Resources.",
                okButtonLabel: "View slide properties"
            })) {
                ShowDialog(TeamSlideDialog, {
                    libraryItemId: slide.get("libraryItemId")
                });
            }
        } else if (slide.isLibrarySlideInCurrentUserOrg()) {
            // if the user is a member and the team slide is in their org, let them know they need to contact an owner or librarian
            ShowDialog(LockedSharedSlideDialog, {
                organizationId: presentation.get("orgId")
            });
        } else {
            // otherwise let them know they cant
            ShowErrorDialog({
                title: "Sorry, this Team Slide is not editable by you.",
                message: "This Team Slide is not in your workspace and you don't have permissions to edit it."
            });
        }
    }

    switchTemplate = source => {
        const { canvas, slide } = this._state;

        if (slide.isLibrarySlide()) {
            this.editLibrarySlide();
        } else {
            trackActivity("Slide", "ShowSwitchTemplate", null, null, { source }, { audit: false });
            ShowDialog(SwitchTemplateDialog, { canvas });
        }
    }

    convertToClassic = async () => {
        const { presentation, canvas } = this._state;

        if (app.user.features.isFeatureEnabled(FeatureType.CONVERT_TO_CLASSIC, presentation.getWorkspaceId())) {
            await canvas.convertToClassic();
        } else {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "ConvertToAuthoring", ...presentation.getAnalytics() },
                workspaceId: presentation.getWorkspaceId()
            });
        }
    }

    exportCanvas = ({ type, slide }) => {
        const { canvas, presentation } = this._state;
        const workspaceId = presentation.getWorkspaceId();

        switch (type) {
            case CanvasExportType.JPEG:
                this.canvas.dataModel.exportSlideToJpeg();
                break;
            case CanvasExportType.PPTX:
                if (app.user.features.isFeatureEnabled(FeatureType.DESKTOP_APP, workspaceId)) {
                    const exporter = new ExportToPPT();
                    exporter.export({ slideCanvases: [canvas], includeSkippedSlides: true, slide });
                    trackActivity("Presentation", "SlideExport", null, null, { type: "pptx" }, { audit: true });
                } else {
                    ShowUpgradeDialog({
                        type: UpgradePlanDialogType.UPGRADE_PLAN,
                        analytics: { cta: "exportToPPT", ...presentation.getAnalytics() },
                        workspaceId,
                    });
                }
                break;
            case CanvasExportType.GOOGLE:
                if (app.user.features.isFeatureEnabled(FeatureType.DESKTOP_APP, workspaceId)) {
                    exportToGoogleSlides({ slideCanvases: [canvas], includeSkippedSlides: true, slide });
                } else {
                    ShowUpgradeDialog({
                        type: UpgradePlanDialogType.UPGRADE_PLAN,
                        analytics: { cta: "ExportToGoogleSlides", ...presentation.getAnalytics() },
                        workspaceId,
                    });
                }
                break;
        }
    }

    convertToSharedSlide = async () => {
        const { slide, presentation } = this._state;

        const canEditLibraryItems = app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, presentation.getWorkspaceId());

        if (!canEditLibraryItems) {
            const userIsBasicInWorkspace = app.user.features.isFeatureEnabled(FeatureType.UPGRADE, presentation.getWorkspaceId());
            const currentPlan = userIsBasicInWorkspace ? "basic" : app.user.analyticsPersonalPlan;
            window.open(getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), currentPlan), "_blank");
            return;
        }

        if (slide.isLibrarySlide()) {
            ShowWarningDialog({
                title: "Failed to add slide to library",
                message: "This slide is already part of another library",
            });
            return;
        }

        // check for linked data which may change the dialogs that
        // are shown for the user
        const containsLinkedData = linkedSlides.detect(ds.selection.slide);
        const onBeforeSaveSlide = containsLinkedData
            ? slide => {
                linkedSlides.remove(slide);
                slide.commit();
            }
            : null;

        // handles displaying the Share Dialog - possibly may be called
        // immediately or after showing a warning about linked slides
        const showShareSlideDialog = () => {
            ShowDialog(CreateSharedSlideDialog, {
                slide,
                presentation: slide.presentation,
                onBeforeSaveSlide
            });
        };

        // since there's linked data, show a dialog warning that
        // the linked slides will be unlinked
        if (containsLinkedData) {
            return ShowConfirmationDialog({
                title: "This slide is linked to other parts of your deck.",
                message: "Sharing it with your team will remove those links. Would you like to continue?",
                acceptCallback: showShareSlideDialog,
                cancelCallback: () => { }
            });
        }

        // there's nothing to warn about so immediately
        // display the share dialog
        showShareSlideDialog();
    }

    viewSharedSlideProperties = () => {
        const { slide } = this._state;

        ShowDialog(TeamSlideDialog, {
            libraryItemId: slide.get("libraryItemId")
        });
    }

    dispose() {
        this.canvas?.remove();
    }
}
