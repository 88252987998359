import React, { Component } from "react";
import styled from "styled-components";
import { Icon } from "@material-ui/core";

const Container = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: 10px;
  
  &.vertical {
    grid-auto-flow: row;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 15px;
  }
  
  & > label {
    width: ${props => props.vertical ? "100%" : (props.labelWidth + "px")};
    margin: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  & > div {
    min-width: ${props => `${props.childrenContainerMinWidth ?? 150}px`};
  }
`;

export class LabeledContainer extends Component {
    render() {
        const { id, labelWidth = 100, vertical, icon, childrenContainerMinWidth } = this.props;

        return (
            <Container
                id={id}
                className={vertical ? "vertical" : null}
                vertical={vertical}
                labelWidth={labelWidth}
                childrenContainerMinWidth={childrenContainerMinWidth}
            >
                {icon && <Icon>{icon}</Icon>}
                <label>{this.props.label}</label>
                <div>
                    {this.props.children}
                </div>
            </Container>
        );
    }
}

export const ToggleButtonContainer = styled.div`
  display: flex;
  column-gap: 3px;
  .MuiToggleButton-root {
    border-radius: 0px;
    padding: 8px;
    
  }
`;
