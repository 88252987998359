import { continuousBinarySearch } from "./utilities";
import { Point } from "./geom";

function normAngle(start, theta) {
    while (theta < start) theta += 2 * Math.PI;
    while (theta > start + 2 * Math.PI) theta -= 2 * Math.PI;
    return theta;
}

function findDistances(start, end, getPoint, samples) {
    let points = [...Array(samples).keys()].map(ii => getPoint(start + (ii / samples) * (end - start)));
    let distances = [0], distance = 0;
    for (let ii = 1; ii < points.length; ii++) {
        distance += points[ii].distance(points[ii - 1]);
        distances.push(distance);
    }
    return {
        distances: distances,
        total: distance + points[points.length - 1].distance(points[0])
    };
}

function findClosestIndex(startIndex, targetDistance, distances) {
    for (let i = startIndex; i < distances.length; i++) {
        if (distances[i] >= targetDistance) {
            if (i === 0) {
                return 0;
            }
            if (distances[i] - targetDistance < targetDistance - distances[i - 1]) {
                return i;
            } else {
                return i - 1;
            }
        }
    }
    return distances.length - 1;
}

function findFractionalDistances(start, end, getPoint, fractions, samples) {
    let distances = findDistances(start, end, getPoint, samples);
    let angles = [];
    let closestIndex = 0;
    let b = [];
    for (let i = 0; i < fractions.length; i++) {
        closestIndex = findClosestIndex(closestIndex, distances.total * fractions[i], distances.distances);
        b.push(closestIndex);
        angles.push(start + (closestIndex / samples) * (end - start));
    }
    return angles;
}

class Arc {
    constructor(shape, start, end) {
        this.shape = shape;
        this.start = start;
        this.end = end;
    }

    removeShapeByHit(hit) {
        let newStart = this.start, newEnd = this.end;
        if (hit(this.shape.point(this.start))) {
            newStart = continuousBinarySearch(this.start, this.end, 0.002, angle => !hit(this.shape.point(angle)));
        }
        if (hit(this.shape.point(this.end))) {
            newEnd = continuousBinarySearch(this.start, this.end, 0.002, angle => hit(this.shape.point(angle)));
        }
        return this.shape.arc(newStart, newEnd);
    }

    calcDistances() {
        if (!this.distances) {
            const samples = Math.floor(500 * (this.end - this.start) / (2 * Math.PI));
            const distances = findDistances(this.start, this.end, angle => this.shape.point(angle), samples);
            this.distances = distances.distances;
        }
    }

    distance() {
        this.calcDistances();
        return this.distances[this.distances.length - 1];
    }

    angleAtDistance(distance) {
        this.calcDistances();
        for (let ii = 0; ii < this.distances.length; ii++) {
            if (this.distances[ii] >= distance) {
                return this.start + (this.end - this.start) * (ii / this.distances.length);
            }
        }
    }
}

class EllipseArc extends Arc {
    toPath() {
        let startPoint = this.shape.point(this.start);
        let endPoint = this.shape.point(this.end);
        let bigArc = Math.abs(this.end - this.start) > Math.PI ? 1 : 0;
        let cwArc = this.shape.ccw ? "0" : "1";
        return `M ${startPoint.x} ${startPoint.y} A ${this.shape.rx} ${this.shape.ry} 0 ${bigArc} ${cwArc} ${endPoint.x} ${endPoint.y}`;
    }
}

class PolygonArc extends Arc {
    toPath() {
        let angles = this.shape.corners().filter(
            corner => this.start < normAngle(this.start, corner) && normAngle(this.start, corner) < normAngle(this.start, this.end));
        angles.push(this.end);
        angles = angles.map(angle => normAngle(this.start, angle));
        angles = angles.sort((l, r) => l - r);

        let startPoint = this.shape.point(this.start);
        return `M ${startPoint.x} ${startPoint.y} ` + angles.map(
            angle => {
                let nextPoint = this.shape.point(angle);
                return `L ${nextPoint.x} ${nextPoint.y}`;
            }).join(" ");
    }
}

class Ellipse {
    constructor(x, y, rx, ry, ccw) {
        this.x = x;
        this.y = y;
        this.rx = rx;
        this.ry = ry;
        this.ccw = ccw;
    }

    point(theta) {
        return new Point(this.x + this.rx * Math.cos((this.ccw ? -1 : 1) * theta), this.y + this.ry * Math.sin((this.ccw ? -1 : 1) * theta));
    }

    equalPoints(start, n, arcAngle = 2 * Math.PI) {
        return findFractionalDistances(start, start + arcAngle, angle => this.point(angle), [...Array(n).keys()].map(ii => ii / n), 600);
    }

    arc(left, right) {
        return new EllipseArc(this, left, right);
    }

    contains(pt) {
        return (parseInt(Math.pow((pt.x - this.x), 2)) / parseInt(Math.pow(this.rx, 2))) + (parseInt(Math.pow((pt.y - this.y), 2)) / parseInt(Math.pow(this.ry, 2))) <= 1;
    }

    static EllipseFromRect(rect) {
        return new Ellipse(rect.center.x, rect.center.y, rect.width / 2, rect.height / 2, false);
    }
}

export function ellipsecheckpoint(h, k, x, y, a, b) {
    // checking the equation of
    // ellipse with the given point
    var p = (parseInt(Math.pow((x - h), 2)) / parseInt(Math.pow(a, 2))) +
        (parseInt(Math.pow((y - k), 2)) / parseInt(Math.pow(b, 2)));

    return p;
}
window.checkPoint = ellipsecheckpoint;
window.Ellipse = Ellipse;

class Polygon {
    constructor(x, y, firstCorner, r, n, ccw) {
        this.x = x;
        this.y = y;
        this.r = r;
        this.n = n;
        this.firstCorner = firstCorner;
        this.ccw = ccw;
    }

    corners() {
        return [...Array(this.n).keys()].map(
            ii => this.firstCorner + ii * 2 * Math.PI / this.n);
    }

    point(theta) {
        let prevCorner = this.firstCorner + (2 * Math.PI / this.n) * Math.floor((theta - this.firstCorner) * this.n / (2 * Math.PI));
        let nextCorner = prevCorner + 2 * Math.PI / this.n;

        let prevPoint = new Point(this.x + this.r * Math.cos((this.ccw ? -1 : 1) * prevCorner), this.y + this.r * Math.sin((this.ccw ? -1 : 1) * prevCorner));
        let nextPoint = new Point(this.x + this.r * Math.cos((this.ccw ? -1 : 1) * nextCorner), this.y + this.r * Math.sin((this.ccw ? -1 : 1) * nextCorner));

        return prevPoint.offset(nextPoint.minus(prevPoint).scale((theta - prevCorner) / (nextCorner - prevCorner)));
    }

    equalPoints(start, n) {
        return findFractionalDistances(start, start + 2 * Math.PI, angle => this.point(angle), [...Array(n).keys()].map(ii => ii / n), 500);
    }

    arc(left, right) {
        return new PolygonArc(this, left, right);
    }
}

export { Ellipse, Polygon };
