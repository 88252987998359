import { PLAN_NICKNAMES } from "./constants";

interface PlanDefinition {
    amount: number;
    nickname: string;
    currency: string;
    isDefault?: boolean;
    createSecret?: boolean;
    isDeprecated?: boolean;
    migrateTo?: string;
}

interface OneTimePlanDefinition extends PlanDefinition {
    type: "one_time";
}

interface RecurringPlanDefinition extends PlanDefinition {
    planId: string;
    interval: "month" | "year";
    type: "recurring";
}

interface CouponDefinition {
    id: string;
    percent_off: number;
    duration: "once" | "repeating";
    currency: string;
    name: string;
    applies_to: {
        products: string[];
    };
}

interface ProductDefinition {
    productId: string;
    productName: string;
    unitLabel: string;
    plans: (RecurringPlanDefinition | OneTimePlanDefinition)[];
    coupons: CouponDefinition[];
}

const products: { [internalProductId: string]: ProductDefinition } = {
    pro: {
        productId: "prod_FRozwsfXiAqlX7",
        productName: "Pro",
        unitLabel: "seat",
        plans: [
            {
                planId: "plan_FRp0E8ka77SQdm",
                amount: 1500,
                nickname: PLAN_NICKNAMES.PRO_MONTH,
                type: "recurring",
                interval: "month",
                currency: "usd"
            },
            {
                planId: "price_1Q1CFoLH1BfAwD4c6NIx45pY",
                amount: 14400,
                nickname: PLAN_NICKNAMES.PRO_ANNUAL,
                type: "recurring",
                interval: "year",
                currency: "usd",
                isDefault: true
            },
            {
                planId: "price_1Q2BjVLH1BfAwD4cvKZncQa9",
                amount: 4500,
                nickname: PLAN_NICKNAMES.PRO_MONTH_45,
                type: "recurring",
                interval: "month",
                currency: "usd",
                isDefault: true
            },
            {
                planId: "price_1KGq3AC6TOcbsMhjMy8D2VEJ",
                amount: 9000,
                nickname: PLAN_NICKNAMES.PROM_MONTH_90,
                type: "recurring",
                interval: "month",
                currency: "usd"
            },
            // Plans that we have to deprecate because they have incorrect tax behavior on production
            {
                planId: "plan_FRp0uyxxHheQa8",
                amount: 14400,
                nickname: PLAN_NICKNAMES.PRO_ANNUAL,
                type: "recurring",
                interval: "year",
                currency: "usd",
                isDeprecated: true,
                migrateTo: "price_1Q1CFoLH1BfAwD4c6NIx45pY"
            },
            {
                planId: "price_1KGq2WC6TOcbsMhjPPZ88t7x",
                amount: 4500,
                nickname: PLAN_NICKNAMES.PRO_MONTH_45,
                type: "recurring",
                interval: "month",
                currency: "usd",
                isDeprecated: true,
                migrateTo: "price_1Q2BjVLH1BfAwD4cvKZncQa9"
            },
        ],
        coupons: [{
            id: "1a92574ab65c4ac28d19f0bca0f63c48",
            percent_off: 15,
            duration: "once",
            currency: "usd",
            name: "15% off Pro once",
            applies_to: {
                products: ["prod_FRozwsfXiAqlX7"]
            }
        }]
    },
    team: {
        productId: "prod_HEQlgWSQkz3qGb",
        productName: "Team",
        unitLabel: "seat",
        plans: [
            {
                planId: "price_1KGq4RC6TOcbsMhjchFSsrwQ",
                amount: 5000,
                nickname: PLAN_NICKNAMES.TEAM_MONTH_50,
                type: "recurring",
                interval: "month",
                currency: "usd",
                isDefault: true
            },
            {
                planId: "price_1Q2BnJLH1BfAwD4ctqUcpXiD",
                amount: 48000,
                nickname: PLAN_NICKNAMES.TEAM_ANNUAL_480,
                type: "recurring",
                interval: "year",
                currency: "usd",
                isDefault: true
            },
            {
                planId: "plan_HEQmYB4HfYaXxc",
                amount: 1500,
                nickname: PLAN_NICKNAMES.TEAM_MONTH_MIGRATED,
                type: "recurring",
                interval: "month",
                currency: "usd"
            },
            {
                planId: "plan_HEQnBa6SxskXOc",
                amount: 14400,
                nickname: PLAN_NICKNAMES.TEAM_ANNUAL_MIGRATED,
                type: "recurring",
                interval: "year",
                currency: "usd"
            },
            {
                planId: "plan_HEQmYB4HfYaXxb",
                amount: 4600,
                nickname: PLAN_NICKNAMES.TEAM_MONTH,
                type: "recurring",
                interval: "month",
                currency: "usd"
            },
            {
                planId: "plan_HEQnBa6SxskXOb",
                amount: 45600,
                nickname: PLAN_NICKNAMES.TEAM_ANNUAL,
                type: "recurring",
                interval: "year",
                currency: "usd"
            },
            // Plans that we have to deprecate because they have incorrect tax behavior on production
            {
                planId: "price_1KGq4wC6TOcbsMhjSammt9bG",
                amount: 48000,
                nickname: PLAN_NICKNAMES.TEAM_ANNUAL_480,
                type: "recurring",
                interval: "year",
                currency: "usd",
                isDeprecated: true,
                migrateTo: "price_1Q2BnJLH1BfAwD4ctqUcpXiD"
            },
        ],
        coupons: []
    },
    enterprise: {
        productId: "prod_N9QNyf6Ao1LDZl",
        productName: "Enterprise",
        unitLabel: "seat",
        plans: [
            {
                planId: "price_1Nn0LULH1BfAwD4cepFhKqI9",
                amount: 5000,
                nickname: PLAN_NICKNAMES.PRICE_ENTERPRISE_50_MONTH,
                type: "recurring",
                interval: "month",
                currency: "usd",
                isDefault: true
            },
            {
                planId: "price_1Nn0JoLH1BfAwD4c9BM4Cgnn",
                amount: 48000,
                nickname: PLAN_NICKNAMES.PRICE_ENTERPRISE_480_ANNUAL,
                type: "recurring",
                interval: "year",
                currency: "usd",
                isDefault: true
            }
        ],
        coupons: []
    },
    tax: {
        productId: "prod_Q7eorw1s9Q3j0w",
        productName: "Tax",
        unitLabel: "tax",
        plans: [
            {
                amount: 0,
                nickname: "tax",
                currency: "usd",
                type: "one_time",
                createSecret: true,
                isDefault: true
            }
        ],
        coupons: []
    }
};

export default products;
