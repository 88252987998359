import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Icon,
    IconButton,
    InputAdornment,
    Radio,
    TextField,
    Typography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { Component, Fragment } from "react";
import styled from "styled-components";

import { AppView } from "legacy-common/constants";
import { incUserProps } from "legacy-js/analytics";
import AppController from "legacy-js/core/AppController";
import getLogger from "js/core/logger";
import { FeatureType } from "js/core/models/features";
import { PresentationPrivacyType } from "js/core/models/presentation";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { normalizePermissionForDisplay } from "js/core/utilities/permissions";
import { trackActivity } from "js/core/utilities/utilities";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import { app } from "js/namespaces";
import {
    DialogContent,
    ShowConfirmationDialog,
    ShowDialog,
    ShowDialogAsync,
    ShowErrorDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import { FlexSpacer, Gap, Gap10, Gap20, Gap30, Gap5 } from "legacy-js/react/components/Gap";
import { ConfirmInviteDialog } from "legacy-js/react/components/InviteInput/ConfirmInviteDialog";
import { createInviteSuggestionContext } from "legacy-js/react/components/InviteInput/InviteContext";
import { InviteTagsInput } from "legacy-js/react/components/InviteInput/InviteTagsInput";
import { Box, FlexBox } from "legacy-js/react/components/LayoutGrid";
import LinkExpiration from "legacy-js/react/components/LinkExpiration";
import Loadable from "legacy-js/react/components/Loadable";
import ProBadge from "legacy-js/react/components/ProBadge";
import TextArea from "legacy-js/react/components/TextArea";
import { BlueButton } from "legacy-js/react/components/UiComponents";
import { WarningWatermark } from "legacy-js/react/components/WarningWatermark";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";
import linkDS, { validateLinkPassword } from "../dataservices/LinkDataService";
import permissionsDS from "../dataservices/PermissionsDataService";
import PermissionsList from "../panes/PermissionsList";
import PresentationSettingsContainer from "../PresentationSettingsContainer";
import CreateInviteDialog from "./CreateInviteDialog";

const logger = getLogger();

const ShareDialogContainer = styled(Dialog)`
    .MuiPaper-root {
        display: grid;
        row-gap: 10px;
        grid-auto-flow: row;
        background-color: transparent;
    }
    
    .MuiDialog-paperScrollPaper {
        overflow-y: auto;
    }
    
    .MuiOutlinedInput-root.Mui-disabled {
        height: 44px;
        pointer-events: none;
    }
    
    .MuiDialogTitle-root{
        padding: 24px 34px 20px 34px;
        .MuiIcon-root{
            font-size: 34px;
            color: #11a9ef;
            vertical-align: -9px;
            margin-right: 10px;
        }
    }
    
    .dialog-content {
        padding-bottom: 14px;
    }
`;

const DialogPanel = styled.div`
    background: white;
    border-radius: 4px;
    padding: 0px;
    max-width: min(100vw - 20px, 860px);
`;

const Expander = styled.div`
    transition: 300ms;
    overflow: hidden;
    height: auto;
`;

export class ShareDialog extends Component {
    constructor(props) {
        super(props);

        const privacySetting = props.presentation?.getPrivacySetting();
        this.state = {
            selectedPanel: props.selectedPanel || (
                privacySetting === PresentationPrivacyType.SECURED ? "secure" : "link"
            )
        };
    }

    handleSetSelected = selectedPanel => {
        this.setState({ selectedPanel });
    }

    render() {
        const { selectedPanel } = this.state;
        const { emails, location, presentation, closeDialog } = this.props;

        return (
            <ShareDialogContainer
                open
                className="share-dialog-container"
                maxWidth="md"
                onClose={(event, reason) => {
                    if (reason === "escapeKeyDown") {
                        event.stopPropagation();
                    }
                    closeDialog();
                }}
            >
                {
                    app.isConstrained &&
                    <FlexBox right>
                        <IconButton className="exit-button" onClick={closeDialog}>
                            <Icon>close</Icon>
                        </IconButton>
                    </FlexBox>
                }
                <CollaboratePanel
                    emails={emails}
                    location={location || "simple-share"}
                    presentation={presentation}
                    expanded={selectedPanel == "collaborate"}
                    onSelected={() => this.handleSetSelected("collaborate")}
                    closeDialog={closeDialog}
                />
                <SharePublicLinkPanel
                    presentation={presentation}
                    expanded={selectedPanel == "link"}
                    onSelected={() => this.handleSetSelected("link")}
                    closeDialog={closeDialog}
                />
                <ShareSecurelyPanel
                    presentation={presentation}
                    expanded={selectedPanel == "secure"}
                    onSelected={() => this.handleSetSelected("secure")}
                    closeDialog={closeDialog}
                />
            </ShareDialogContainer>
        );
    }
}

class SharePublicLinkPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            link: null,
            linkName: "Public Link",
            linkExpiresAt: null,
            linkShouldExpire: false,
            linkHasExpired: false,
        };
        this.linkRef = React.createRef();
    }

    async componentDidMount() {
        const { presentation } = this.props;
        try {
            if (!presentation.links) {
                presentation.loadPresentationLinks();
            }

            let link = this.getExistingPublicLink();
            if (link) {
                this.setState({ link, linkExpiresAt: link.get("expiresAt") });
            } else {
                link = await linkDS.createPublicLink(presentation);
                link.update({ isDefault: true });
                this.setState({ link, shouldCleanup: true });
            }
        } catch (err) {
            logger.error(err, "[SharePublicLinkPanel] linkDS.createPublicLink() failed", { presentationId: presentation?.id });
        }
    }

    componentWillUnmount() {
        const { link, linkCopied, shouldCleanup } = this.state;

        if (link && !linkCopied && shouldCleanup) {
            link.destroy();
        }
    }

    getExistingPublicLink() {
        const publicLinks = this.props.presentation.links.find(link => link.get("type") == "public");
        if (!publicLinks || !publicLinks.length) {
            return null;
        }

        const defaultPublicLink = publicLinks.find(link => link.get("isDefault"));
        return defaultPublicLink || publicLinks[0];
    }

    handleAccept = async () => {
        const { presentation } = this.props;
        const { link } = this.state;

        if (!link.get("enabled")) {
            link.update({ enabled: true });
        }

        // set the presentation to PUBLIC if necessary
        if (presentation.getPrivacySetting() !== PresentationPrivacyType.PUBLIC) {
            if (!(await ShowConfirmationDialog({
                title: "Make this presentation public?",
                message: <>
                    <p>In order to share your presentation, its privacy setting must be set to <b>Public.</b></p>
                    <br />
                    <p>Public presentations can only be viewed by people who have been given a link or invited to the presentation.</p>
                </>
            }))) {
                return;
            }

            presentation.setPrivacySetting(PresentationPrivacyType.PUBLIC);

            const analyticsProps = {
                presentation_id: presentation.get("id"),
                old_value: presentation.getPrivacySetting(),
                new_value: PresentationPrivacyType.PUBLIC
            };
            trackActivity("Sharing", "ChangePrivacySetting", null, null, analyticsProps, true);
        }

        await linkDS.saveLink(presentation, link);
        this.setState({ linkCopied: true });

        const shareSuccessful = url => {
            this.props.closeDialog(url);
            const props = {
                presentation_id: presentation.id,
                link_ids: [link.get("id")],
                is_new: true,
                type: "public_link",
                workspace_id: presentation.getWorkspaceId()
            };
            trackActivity("Sharing", "CopyLink", null, null, props, { audit: true });
        };

        // Try to share. If it's cancelled or unsupported, we do not continue tracking
        try {
            const url = link.getLinkURL();
            if (app.isMobileOrTablet && window.navigator.share) {
                await window.navigator.share({ url });
                shareSuccessful(url);
            } else {
                await clipboardWrite({ [ClipboardType.TEXT]: url });
                shareSuccessful(url);
            }
        } catch (err) {
            logger.error(err, "[SharePublicLinkPanel] handleAccept() share cancelled", { presentationId: this.props.presentation?.id });
        }
    }

    handleNameChange = event => {
        this.state.link.update({ name: event.target.value });
        this.forceUpdate();
    }

    toggleAllowCommenting = event => {
        const allowCommenting = !this.state.link.get("allowCommenting");
        this.state.link.update({ allowCommenting });
        this.forceUpdate();
    }

    handleUpdateLinkExpiration = ({ linkExpiresAt, linkShouldExpire, linkHasExpired }) => {
        this.setState({ linkExpiresAt, linkShouldExpire, linkHasExpired });

        if (!linkHasExpired) {
            this.state.link.update({
                expiresAt: linkShouldExpire ? linkExpiresAt : null
            });
        }
    }

    handleShowMoreOptions = () => {
        this.props.closeDialog();
        ShowDialog(PresentationSettingsContainer, {
            startPane: "share", presentation: this.props.presentation,
        });
    }

    render() {
        const { presentation, expanded } = this.props;
        const {
            link,
            linkExpiresAt,
            linkHasExpired,
        } = this.state;

        const disabled = !link || linkHasExpired || (link?.shouldLinkExpire() && link?.isLinkExpired());
        const allowCommenting = link?.attributes.allowCommenting;

        return (
            <DialogPanel onClick={this.props.onSelected}>
                <DialogTitle><Icon>link</Icon>Share Public Link</DialogTitle>
                <Expander style={{ maxHeight: expanded ? "500px" : "0px" }}>
                    <DialogContent>
                        <h3>
                            Anyone with this link will have access to this presentation. Pro users can track engagement with viewer analytics.
                        </h3>
                        {!app.isConstrained && <Gap20 />}
                        <TextField
                            label="Link Name"
                            value={link ? link.get("name") : "Public Link"}
                            placeholder="Enter Link Name"
                            fullWidth
                            onChange={this.handleNameChange}
                            inputRef={this.textFieldRef}
                        />

                        <Gap30 />
                        <ToggleAllowCommenting
                            allowCommenting={allowCommenting}
                            onToggle={this.toggleAllowCommenting}
                        />
                        <LinkExpiration
                            expiresAt={linkExpiresAt}
                            presentation={presentation}
                            onUpdate={this.handleUpdateLinkExpiration}
                        />
                    </DialogContent>
                    <WarningWatermark
                        cta="GetLinkWarningWatermark"
                        presentation={this.props.presentation}
                    />
                    <DialogActions>
                        {!app.isConstrained && AppController.view !== AppView.LIBRARY && (
                            <Button onClick={this.handleShowMoreOptions}>More Sharing Options...</Button>
                        )}
                        <FlexSpacer />
                        <BlueButton onClick={this.handleAccept} disabled={disabled}>
                            Copy Link
                        </BlueButton>
                    </DialogActions>
                </Expander>
            </DialogPanel>
        );
    }
}

const SecureLinkType = {
    PASSWORD: "password",
    VIEWER_AUTH: "viewer_auth",
};

class ShareSecurelyPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            linkOption: SecureLinkType.PASSWORD,
            linkExpiresAt: null,
            linkShouldExpire: false,
            linkHasExpired: false,
            showPassword: true,
            password: "",
            passwordError: null,
            loading: false,
        };
        this.textFieldRef = React.createRef();
        this.passwordFieldRef = React.createRef();
    }

    async componentDidMount() {
        const { presentation } = this.props;
        try {
            if (!presentation.links) {
                presentation.loadPresentationLinks();
            }

            let link = this.getExistingProtectedLink();
            if (link) {
                this.setState({ link, linkExpiresAt: link.get("expiresAt") });
            } else {
                link = await linkDS.createProtectedLink(presentation);
                this.setState({ link, shouldCleanup: true });
            }
        } catch (err) {
            logger.error(err, "[ShareSecureLinkPanel] linkDS.createProtectedLink() failed", { presentationId: presentation?.id });
        }
    }

    componentWillUnmount() {
        const { link, linkCopied, shouldCleanup } = this.state;

        if (link && !linkCopied && shouldCleanup) {
            link.destroy();
        }
    }

    getExistingProtectedLink = () => {
        const protectedLinks = this.props.presentation.links.find(link => link.get("type") == "protected");
        if (!protectedLinks || !protectedLinks.length) {
            return null;
        }

        const defaultProtectedLink = protectedLinks.find(link => link.get("isDefault"));
        return defaultProtectedLink || protectedLinks[0];
    }

    handleAccept = async () => {
        const { presentation } = this.props;
        const { link, linkOption, password, passwordError } = this.state;

        // set the presentation to SECURED if necessary
        if (presentation.getPrivacySetting() !== PresentationPrivacyType.SECURED) {
            if (!(await ShowConfirmationDialog({
                title: "Your presentation is currently public",
                message: <Fragment>
                    <p>In order to send a secured invite, your presentation's security setting will be set to <b>Secured.</b></p>
                    <p>Any public links, embeds or social shares for this presentation will be deactivated. Only viewers who have been sent a password protected link or secure email will have access.</p>
                    <br />
                    <b>Are you sure you wish to continue?</b>
                </Fragment>
            }))) {
                return;
            }

            presentation.setPrivacySetting(PresentationPrivacyType.SECURED);

            const analyticsProps = {
                presentation_id: presentation.get("id"),
                old_value: presentation.getPrivacySetting(),
                new_value: PresentationPrivacyType.SECURED
            };
            trackActivity("Sharing", "ChangePrivacySetting", null, null, analyticsProps, true);
        }

        // Open the invite dialog to create per-user INVITE links if viewer auth is selected
        if (linkOption === SecureLinkType.VIEWER_AUTH) {
            const { presentation } = this.props;
            const { allowCommenting, linkExpiresAt, linkShouldExpire, linkHasExpired } = this.state;

            await ShowDialogAsync(CreateInviteDialog, {
                presentation, allowCommenting, linkExpiresAt, linkShouldExpire, linkHasExpired, hideLinkSettings: true,
                callback: () => {
                    this.props.closeDialog();
                    this.forceUpdate();
                }
            });
            return;
        }

        // Save the password if the password option is selected
        if (linkOption === SecureLinkType.PASSWORD) {
            await linkDS.updateLink(link, { password });
        }

        await linkDS.saveLink(presentation, link);
        this.setState({ linkCopied: true });

        const shareSuccessful = url => {
            this.props.closeDialog(url);
            const props = {
                presentation_id: presentation.id,
                link_ids: [link.get("id")],
                is_new: true,
                type: "protected_link",
                workspace_id: presentation.getWorkspaceId()
            };
            trackActivity("Sharing", "CopyLink", null, null, props, { audit: true });
        };

        // Try to share. If it's cancelled or unsupported, we do not continue tracking
        try {
            const url = link.getLinkURL();
            if (app.isMobileOrTablet && window.navigator.share) {
                await window.navigator.share({ url });
                shareSuccessful(url);
            } else {
                await clipboardWrite({ [ClipboardType.TEXT]: url });
                shareSuccessful(url);
            }
        } catch (err) {
            logger.error(err, "[ShareSecureLinkDialog] handleAccept() share cancelled", { presentationId: this.props.presentation?.id });
        }
    }

    handleNameChange = event => {
        this.state.link.update({ name: event.target.value });
        this.forceUpdate();
    }

    handlePasswordChange = async event => {
        const password = event.target.value;
        const passwordError = validateLinkPassword(password);

        this.setState({ password, passwordError });
    }

    handleLinkOptionChange = option => {
        this.setState({ linkOption: option, password: "", passwordError: null });
    }

    toggleAllowCommenting = event => {
        const allowCommenting = !this.state.link.get("allowCommenting");
        this.state.link.update({ allowCommenting });
        this.forceUpdate();
    }

    handleUpdateLinkExpiration = ({ linkExpiresAt, linkShouldExpire, linkHasExpired }) => {
        this.setState({ linkExpiresAt, linkShouldExpire, linkHasExpired });

        if (!linkHasExpired) {
            this.state.link.update({
                expiresAt: linkShouldExpire ? linkExpiresAt : null
            });
        }
    }

    handleShowMoreOptions = () => {
        this.props.closeDialog();
        const { presentation } = this.props;
        ShowDialog(PresentationSettingsContainer, { startPane: "share", presentation });
    }

    render() {
        const {
            expanded,
            presentation,
        } = this.props;
        const {
            link,
            linkOption,
            linkExpiresAt,
            linkShouldExpire,
            linkHasExpired,
            showPassword,
            password,
            passwordError,
            loading
        } = this.state;

        const isSecureShareDisabled = !app.user.features.isFeatureEnabled(FeatureType.SECURED_SHARING, UIController.getWorkspaceId());
        const isSendDisabled = isSecureShareDisabled || (linkShouldExpire && linkHasExpired) || (linkOption === SecureLinkType.PASSWORD && (!password || passwordError));

        const allowCommenting = link && link.get("allowCommenting");

        return (
            <DialogPanel onClick={this.props.onSelected}>
                <DialogTitle>
                    <FlexBox left middle>
                        <Icon>lock</Icon>
                        Share Securely
                        <ProBadge
                            show={isSecureShareDisabled}
                            analytics={{ cta: "SecuredSharing", ...this.props.presentation.getAnalytics() }}
                            workspaceId={UIController.getWorkspaceId()}
                        />
                    </FlexBox>
                </DialogTitle>
                <Expander style={{ maxHeight: expanded ? "600px" : "0px" }}>
                    {
                        !app.isConstrained &&
                        <Fragment>
                            <DialogContent>
                                <Gap10 />
                                <FlexBox left top style={{ paddingBottom: "20px", marginLeft: "-10px" }}>
                                    <label style={{ display: "flex", alignItems: "flex-start", cursor: "pointer", width: "100%" }}>
                                        <Radio
                                            checked={linkOption === SecureLinkType.PASSWORD}
                                            onChange={() => this.handleLinkOptionChange(SecureLinkType.PASSWORD)}
                                        />
                                        <Box style={{
                                            display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", paddingLeft: "5px"
                                        }}>
                                            <Gap size={8} />
                                            <span style={{ fontWeight: "600", fontSize: "18px", color: "#222" }}>
                                                Password Protection
                                            </span>
                                            <Gap5 />
                                            <span> Add a layer of security with a password. Anyone with this link and password will have access to this presentation. Password is case sensitive. Name your link and track engagement with Analytics.</span>
                                            {!isSecureShareDisabled && linkOption === SecureLinkType.PASSWORD && (
                                                <>
                                                    <Gap10 />
                                                    <TextField
                                                        label="Link Name"
                                                        value={link ? link.get("name") : ""}
                                                        placeholder="Enter Link Name"
                                                        onChange={this.handleNameChange}
                                                        disabled={loading}
                                                        inputRef={this.textFieldRef}
                                                    />
                                                    <Gap10 />
                                                    <TextField
                                                        label="Enter Password"
                                                        placeholder="Enter Password"
                                                        type={showPassword ? "text" : "password"}
                                                        onChange={this.handlePasswordChange}
                                                        inputRef={this.passwordFieldRef}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => this.setState({ showPassword: !showPassword })}
                                                                    >
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    {passwordError && (
                                                        <Typography variant="caption" color="error">
                                                            {passwordError}
                                                        </Typography>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </label>
                                </FlexBox>
                                <FlexBox left top style={{ marginLeft: "-10px" }}>
                                    <label style={{ display: "flex", alignItems: "flex-start", cursor: "pointer", width: "100%" }}>
                                        <Radio
                                            checked={linkOption === SecureLinkType.VIEWER_AUTH}
                                            onChange={() => this.handleLinkOptionChange(SecureLinkType.VIEWER_AUTH)}
                                        />
                                        <Box style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            width: "100%",
                                            paddingLeft: "5px"
                                        }}>
                                            <Gap size={8} />
                                            <span style={{ fontWeight: "600", fontSize: "18px", color: "#222" }}>
                                                Viewer Authentication
                                            </span>
                                            <Gap5 />
                                            <span>A more secure option. Requires viewer to sign up and validate email. Track individual viewer activity with Analytics.</span>
                                        </Box>
                                    </label>
                                </FlexBox>
                                <Gap30 />
                                <ToggleAllowCommenting
                                    allowCommenting={allowCommenting}
                                    onToggle={this.toggleAllowCommenting}
                                />
                                <LinkExpiration
                                    expiresAt={linkExpiresAt}
                                    presentation={presentation}
                                    onUpdate={this.handleUpdateLinkExpiration}
                                />
                            </DialogContent>
                            <DialogActions>
                                {!app.isConstrained && AppController.view !== AppView.LIBRARY && (
                                    <Button onClick={this.handleShowMoreOptions}>More Sharing Options...</Button>
                                )}
                                <FlexSpacer />
                                <BlueButton
                                    onClick={this.handleAccept}
                                    disabled={isSendDisabled}
                                >
                                    {linkOption === SecureLinkType.VIEWER_AUTH ? "Send Email" : "Copy Link"}
                                </BlueButton>
                            </DialogActions>
                        </Fragment>
                    }
                </Expander>
            </DialogPanel >
        );
    }
}

class CollaboratePanel extends Component {
    constructor(props) {
        super(props);

        // Retrieve workspace prohibited flags
        const prohibitExternalWorkspaceCollaboration = app.user.features.isFeatureEnabled(
            FeatureType.PROHIBIT_EXTERNAL_WORKSPACE_COLLABORATION,
            AppController.orgId
        );

        const inviteContext = createInviteSuggestionContext({
            includeFolders: true,
            presentationId: this.props.presentation.id,
            prohibitExternalWorkspaceCollaboration,
            saveContext: inviteContext => this.setState({ inviteContext }),
            afterInit: () => {
                const emails = props.emails || [];
                this.state.inviteContext.selectItems(emails);
            },

        });

        this.state = {
            inviteContext,
            customMessage: "",
            permission: "write",
            isLoading: false,
            showCollaboratorList: false,
        };
    }

    componentWillUnmount() {
        this.state.inviteContext.destroy();
    }

    handleToggleCollaboratorList = showCollaboratorList => {
        this.setState({
            showCollaboratorList: showCollaboratorList,
        });
    }

    handleViewAllCollaborators = () => {
        this.props.closeDialog();
        const { presentation } = this.props;
        ShowDialog(PresentationSettingsContainer, { startPane: "collaborate", presentation });
    }

    handleChangeCustomMessage = customMessage => {
        this.setState({
            customMessage
        });
    }

    handleDropdownChange = value => {
        this.setState({ permission: value });
    }

    handleAccept = async () => {
        if (!this.state.isLoading) {
            const {
                inviteContext,
                customMessage,
            } = this.state;
            const { location, closeDialog } = this.props;
            this.setState({ isLoading: true });
            try {
                const users = inviteContext.getSelectedUniqueUsers();
                const selection = inviteContext.getSelectionWithRedundanciesRemoved();
                const presentationId = this.props.presentation.id;

                await ShowDialogAsync(ConfirmInviteDialog, {
                    hasPermissionEditing: true,
                    users,
                    callback: async permission => {
                        let emails = [];
                        let folders = [];
                        let asyncInvites = [];

                        let displayPerm = normalizePermissionForDisplay(permission);

                        selection.forEach(item => {
                            switch (item.type) {
                                case "folder":
                                    // Skip folders that wouldn't be changing permissions
                                    if (item.permissionType !== displayPerm) {
                                        folders.push({
                                            id: item.id,
                                            name: item.name,
                                        });
                                        asyncInvites.push(permissionsDS.inviteTeam(item.id, permission, presentationId, false));
                                    }
                                    break;
                                case "user":
                                default:
                                    emails.push(item.email);
                                    break;
                            }
                        });

                        // If we have individual user emails, give them permissions separate from the teams
                        if (emails.length) {
                            const payload = { emails, permission, customMessage };
                            asyncInvites.push(permissionsDS.createPermission(payload, presentationId, false));
                        }

                        await Promise.all(asyncInvites);

                        incUserProps({
                            invites: emails.length
                        });
                        const props = {
                            presentation_id: presentationId,
                            old_value: null,
                            new_value: permission,
                            team_folders: folders,
                            recipient_emails: emails,
                            sender_email: app.user.getEmail(),
                            location
                        };

                        trackActivity("Collab", "Invite", null, null, props, { audit: true });
                        closeDialog(selection);
                    },
                });
            } catch (err) {
                logger.error(err, "[CollaboratePanel] handleAccept() failed");
                ShowErrorDialog({
                    error: "Failed to collaborate",
                    message: err.message
                });
            } finally {
                this.setState({ isLoading: false });
            }
        }
    };

    render() {
        const { expanded, presentation } = this.props;
        const {
            inviteContext,
            customMessage,
            isLoading,
            showCollaboratorList
        } = this.state;

        return (
            <Fragment>
                {
                    !showCollaboratorList &&
                    <DialogPanel onClick={this.props.onSelected}>
                        <DialogTitle><Icon>person_add_alt_1</Icon>Invite collaborators</DialogTitle>
                        <Loadable isLoading={isLoading}>
                            <Expander style={{ maxHeight: expanded ? "500px" : "0px" }}>
                                <DialogContent>
                                    <InviteTagsInput
                                        inviteContext={inviteContext}
                                        sourceName="CollaboratePanel"
                                    />

                                    <Gap10 />
                                    <TextArea
                                        isVisible
                                        text={customMessage}
                                        onChange={customMessage => this.handleChangeCustomMessage(customMessage)}
                                        placeholder="Add a note..."
                                    />
                                </DialogContent>
                                {
                                    !app.isConstrained &&
                                    <DialogActions>
                                        {AppController.view == AppView.PRESENTATION_EDITOR && <Button onClick={this.handleViewAllCollaborators}>View all collaborators</Button>}
                                        <FlexSpacer />
                                        <BlueButton disabled={!inviteContext.selection.length} onClick={() => this.handleAccept()} color="primary">
                                            Send Invite
                                        </BlueButton>
                                    </DialogActions>
                                }
                                {
                                    app.isConstrained &&
                                    <DialogActions
                                        style={{
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <BlueButton size="large" disabled={!inviteContext.selection.length} onClick={() => this.handleAccept()} color="primary">
                                            Send Invite
                                        </BlueButton>
                                        <Gap10 />
                                        {app.mainView.editorView && <Button
                                            className="text-blue"
                                            onClick={() => this.handleToggleCollaboratorList(true)}
                                        >View all collaborators</Button>}
                                    </DialogActions>
                                }
                            </Expander>
                        </Loadable>
                    </DialogPanel>
                }
                {
                    showCollaboratorList &&
                    <DialogPanel onClick={this.props.onSelected}>
                        <DialogTitle><Icon>person_add_alt_1</Icon>Who has access?</DialogTitle>
                        <Loadable isLoading={isLoading}>
                            <Expander style={{ maxHeight: expanded ? "500px" : "0px" }}>
                                <DialogContent>
                                    <PermissionsList
                                        presentation={presentation}
                                        orgId={presentation.get("orgId")}
                                        refreshOnMount={true}
                                    />
                                </DialogContent>
                                <DialogActions
                                    style={{
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <BlueButton
                                        size="large"
                                        onClick={() => this.handleToggleCollaboratorList(false)}
                                        color="primary"
                                    >Invite new collaborators</BlueButton>
                                </DialogActions>
                            </Expander>
                        </Loadable>
                    </DialogPanel>
                }
            </Fragment>
        );
    }
}

