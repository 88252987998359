import React from "reactn";
import styled from "styled-components";
import {
    Select,
    MenuItem
} from "@material-ui/core";

const Frame = styled.div`
  padding: 0px 10px;
  display: flex;
  align-items: center;

  >div:first-child {
    padding-left: 10px;
  }
  
  &:before {
    content: "";
    width: 1px;
    height: 50%;
    background: #DBDBDB;
  }
`;

const StyledSelect = styled(Select)`
  &&& {
    text-transform: none !important;
    background: inherit !important; //override focus gray
  }
`;

export function FilterDropDown({ filterOptions, onChange, selectedFilter }) {
    return (
        <Frame>
            <StyledSelect
                onChange={onChange}
                value={selectedFilter}
                disableUnderline
            >
                {
                    filterOptions.map(filter => {
                        return (
                            <MenuItem
                                disableGutters
                                key={filter.id}
                                value={filter.id}
                            >
                                {filter.name}
                            </MenuItem>
                        );
                    })
                }
            </StyledSelect>
        </Frame>
    );
}
