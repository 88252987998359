import React from "react";

import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
        position: "absolute",
        left: "50vw",
        top: "50vh",
        marginLeft: -20,
        marginTop: -20
    },
    progress: {
        color: "#23aae0",
    }
};

export const LoadingPage = withStyles(styles)(props => {
    return (
        <div className={props.classes.root}>
            <CircularProgress size={35} thickness={5} className={props.classes.progress} />
        </div>
    );
});
