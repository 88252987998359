import { ds } from "js/core/models/dataService";
import { controls } from "legacy-js/editor/ui";
import {
    ShowDialog,
    ShowWarningDialog,
    ShowConfirmationDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import LinkDataDialog from "legacy-js/react/views/LinkData/LinkDataDialog";

export function getDataSourceControl(element, elementEditor) {
    const _handleSelectedData = processedData => {
        processedData.initialImport = true;
        element._useUpdatedDataSource(processedData);
        ds.selection.element = element;
        if (elementEditor?.refreshRender) elementEditor.refreshRender();
    };

    const _handleUnlinkData = () => {
        element.removeDataSource();
        element.model.dataSourceLink = null;
        element.canvas.updateCanvasModel(false);
        if (elementEditor?.refreshRender) elementEditor.refreshRender();
    };

    if (element.hasDataSourceLink()) {
        return controls.createPopupButton(elementEditor, {
            label: "Linked Data",
            icon: "import_export",
            className: "datasource linked",
            items: [{
                value: "refresh_data", label: "Refresh Data", icon: "refresh"
            }, {
                value: "unlink_data", label: "Unlink Data", icon: "link_off"
            }, {
                value: "view_data", label: "View Data...", icon: "visibility"
            }, {
                value: "import_new_data", label: "Import New...", icon: "import_export"
            }],
            callback: async option => {
                if (option === "refresh_data") {
                    element.getDataSourceManager(
                        element.model.dataSourceLink.dataSourceId
                    ).refreshData().then(latestData => {
                        if (latestData?.oauthDisconnected) _handleUnlinkData();
                        if (elementEditor?.refreshRender) elementEditor.refreshRender();
                    }).catch(err => {
                        ShowWarningDialog({
                            title: "Sorry!",
                            message: "We were unable to refresh data for this data source. Please try again.",
                        });
                    });
                } else if (option === "unlink_data") {
                    ShowConfirmationDialog({
                        title: "Are you sure you want to unlink this data?",
                        message: "Once unlinked your data will no longer update when your source file is updated.",
                        okButtonLabel: "Unlink",
                        acceptCallback: () => _handleUnlinkData()
                    });
                } else if (option === "view_data") {
                    ShowDialog(LinkDataDialog, {
                        element,
                        viewCurrLinkedData: true,
                        handleSelectedData: d => _handleSelectedData(d),
                        handleDisconnect: () => _handleUnlinkData()
                    });
                } else if (option === "import_new_data") {
                    ShowDialog(LinkDataDialog, {
                        element,
                        handleSelectedData: d => _handleSelectedData(d),
                        handleDisconnect: () => _handleUnlinkData()
                    });
                }
            }
        });
    } else {
        return controls.createButton(elementEditor, {
            label: "Import Data",
            icon: "import_export",
            className: "datasource",
            callback: () => {
                ds.selection.element = null;
                ShowDialog(LinkDataDialog, {
                    element,
                    handleSelectedData: d => _handleSelectedData(d),
                    handleDisconnect: () => _handleUnlinkData()
                });
            }
        });
    }
}
