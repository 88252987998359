import React, { Component } from "reactn";
import styled from "styled-components";
import { TextField, Button, DialogActions } from "@material-ui/core";
import moment from "moment";

import { _ } from "legacy-js/vendor";
import {
    UIPane,
    UIPaneContents,
    Section,
    UIPaneHeader,
    BlueButton,
} from "legacy-js/react/components/UiComponents";
import Avatar from "legacy-js/react/components/Avatar";
import { BlueSwitch } from "legacy-js/react/components/Switch";
import { app } from "js/namespaces";
import { ShowDialog, BeautifulDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import AddTagsDialog from "legacy-js/react/views/UserOptions/dialogs/AddTagsDialog";
import { FlexBox, ScrollBox } from "legacy-js/react/components/LayoutGrid";
import { FlexSpacer, Gap10, Gap20, Gap30 } from "legacy-js/react/components/Gap";
import TagsList from "legacy-js/react/components/TagsList";
import Tooltip from "legacy-js/react/views/PPTAddin/Components/Tooltip";
import getUserProfile from "js/core/services/userProfiles";
import { ASSET_RESOLUTION } from "../../../../../common/constants";
import { trackActivity } from "js/core/utilities/utilities";

const AssetPreviewContainer = styled.div`
    width: 640px;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E0E0E0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .replace-button {
        opacity: 0;
    }

    &:hover {
        .replace-button {
            opacity: 1;
        }
    }
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    label {
        font-size: 13px;
        width: 80px;
    }

    .avatar {
        margin-right: 10px;
    }

    .name {
        font-size: 14px;
        font-weight: 600;
        color: #333;
    }

    .date {
        margin-left: 20px;
        font-size: 14px;
        color: #666;
    }
`;

export default class TeamAssetSettingsDialog extends Component {
    constructor(props) {
        super(props);

        // const previewProps = _.cloneDeep(props.previewProps);
        // previewProps.tags = props.previewProps.tags ?? [];
        //
        // this.state = {
        //     delta: {
        //         tags: [...previewProps.tags],
        //     },
        //     previewProps,
        // };

        this.state = {
            assetName: "",
            alt: "",
            hidden: false,
            url: "",
            tags: [],
            createdBy: "",
            modifiedBy: ""
        };
    }

    componentDidMount() {
        this.loadStateFromAsset(this.props.asset);
    }

    async loadStateFromAsset(asset) {
        this.setState({
            assetName: asset.get("assetName"),
            alt: asset.getAltText(),
            hidden: asset.get("hidden"),
            url: await asset.getURL(ASSET_RESOLUTION.SMALL),
            tags: asset.get("tags") ?? [],
            createdBy: await getUserProfile(asset.get("createdById")),
            modifiedBy: await getUserProfile(asset.get("modifiedById")),
        });
    }

    handleRemoveTag = tag => {
        let { tags } = this.state;

        if (tags) {
            tags = [...tags];
            const index = tags.indexOf(tag);
            if (index > -1) {
                tags.splice(index, 1);
                this.setState({ tags });
            }
        }
    }

    handleAddTag = () => {
        const { existingTags } = this.props;
        const { tags } = this.state;

        ShowDialog(AddTagsDialog, {
            activeTags: [...tags],
            existingTags,
            acceptCallback: newTags => {
                this.setState({
                    tags: newTags,
                });
            },
        });
    }

    handleSaveChanges = () => {
        const { asset } = this.props;
        const { assetName, hidden, tags, alt } = this.state;
        asset.update({ assetName, alt, hidden, tags, modifiedById: app.user.id, modifiedAt: Date.now() });

        trackActivity("TeamAsset", "Updated", null, null, {
            id: asset.id,
            orgId: asset.get("orgId"),
            type: asset.get("type"),
        });

        this.props.callback();
        this.props.closeDialog();
    }

    render() {
        const { asset, closeDialog } = this.props;
        const { assetName, alt, hidden, createdBy, modifiedBy, url, tags } = this.state;

        return (
            <BeautifulDialog maxWidth="lg" closeDialog={closeDialog} closeButton>
                <UIPane style={{ overflow: "hidden" }}>
                    <UIPaneHeader>Team Asset Settings</UIPaneHeader>
                    <ScrollBox>
                        <UIPaneContents style={{ minHeight: 200 }}>
                            <Section>
                                <Gap10 />
                                <FlexBox top left>
                                    <AssetPreviewContainer
                                        title={alt}
                                        style={{ backgroundImage: `url(${url})` }}
                                    >
                                    </AssetPreviewContainer>
                                    <Gap30 />
                                    <FlexBox column top left width={500}>
                                        <TextField
                                            autoFocus
                                            id="library-name"
                                            label="Name"
                                            fullWidth
                                            value={assetName}
                                            onChange={event => this.setState({ assetName: event.target.value })}
                                            onBlur={event => this.setState({ assetName: event.target.value })}
                                        />
                                        <Gap30 />
                                        <UserInfo>
                                            <label>Created by</label>
                                            <Avatar url={createdBy.photoURL} />
                                            <div className="name">
                                                <span className="fullName">
                                                    {
                                                        createdBy.displayName ||
                                                        createdBy.email ||
                                                        <Tooltip title="This data predates our tracking of asset changes." arrow>
                                                            <b>Unknown</b>
                                                        </Tooltip>
                                                    }
                                                </span>
                                            </div>
                                            <div className="date">{moment.utc(asset.get("createdAt")).local().format("LL")}</div>
                                        </UserInfo>
                                        <Gap20 />
                                        {modifiedBy &&
                                            <UserInfo>
                                                <label>Modified by</label>
                                                <Avatar url={modifiedBy.photoURL} />
                                                <div className="name">
                                                    <span className="fullName">
                                                        {
                                                            modifiedBy.displayName ||
                                                            modifiedBy.email ||
                                                            <Tooltip title="This data predates our tracking of asset changes." arrow>
                                                                <b>Unknown</b>
                                                            </Tooltip>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="date">{moment.utc(asset.get("modifiedAt")).local().format("LL")}</div>
                                            </UserInfo>
                                        }
                                        <Gap20 />
                                        <BlueSwitch
                                            id="hideSwitch"
                                            checked={!hidden}
                                            onChange={() => this.setState({ hidden: !hidden })}
                                            label="Published"
                                            tooltipTitle="Published assets are visible to all users in your team. Unpublished assets are only visible to libarians."
                                        />
                                        <Gap10 />
                                        <TextField
                                            id="alt-text"
                                            label="Alt text"
                                            fullWidth
                                            value={alt}
                                            onChange={event => this.setState({ alt: event.target.value })}
                                            onBlur={event => this.setState({ alt: event.target.value })}
                                        />
                                    </FlexBox>
                                </FlexBox>
                            </Section>
                            <Section>
                                <FlexBox left middle fillWidth>
                                    <div className="section-title">Tags</div>
                                    <Gap20 />
                                    <TagsList
                                        tags={tags}
                                        onRemove={this.handleRemoveTag}
                                        onClick={this.handleAddTag}
                                    />
                                </FlexBox>
                            </Section>
                        </UIPaneContents>
                    </ScrollBox>
                </UIPane>
                <DialogActions>
                    <FlexSpacer/>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <BlueButton onClick={this.handleSaveChanges}>Save Changes</BlueButton>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
