import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { Appearance, Stripe, StripeElements } from "@stripe/stripe-js";
import React, { forwardRef } from "react";

type BaseComponent = React.ComponentType<any>;
type InjectedProps = { stripe: Stripe, elements: StripeElements };
type WrapperProps<T extends BaseComponent> = Omit<React.ComponentProps<T>, keyof InjectedProps> & Partial<InjectedProps>;

export interface WithElementsOptions {
    mode?: "setup" | "payment" | "subscription";
    paymentMethodTypes?: string[];
    appearance?: Appearance;
}

const appearance: Appearance = {
    variables: {
        fontFamily: "Source Sans Pro, sans-serif",
        borderRadius: "0px",
        colorPrimary: "#11a9e2",
        colorDanger: "#ff0000"
    },
    rules: {
        ".Input": {
            borderColor: "#c4c4c4",
            transition: "none"
        },
        ".Input:focus": {
            boxShadow: "0 0 0px 1px #11a9e2",
            borderColor: "#11a9e2"
        },
        ".Input:focus:hover": {
            boxShadow: "0 0 0px 1px #11a9e2",
            borderColor: "#11a9e2"
        },
        ".Input--invalid": {
            boxShadow: "0 0 0px 1px #ff0000",
            borderColor: "#ff0000"
        },
        ".Input:hover": {
            borderColor: "#333"
        },
    }
};

export default function WithElements<T extends BaseComponent>(Component: T, options?: WithElementsOptions) {
    return forwardRef<T, WrapperProps<T>>(function ComponentWithElements(props, ref) {
        if (!props.stripe) {
            throw new Error("stripe prop is required");
        }

        return (
            <Elements
                stripe={props.stripe}
                options={{
                    mode: options?.mode ?? "setup",
                    paymentMethodCreation: "manual",
                    paymentMethodTypes: options?.paymentMethodTypes ?? ["card"],
                    fonts: [{ cssSrc: "https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap" }],
                    appearance: options?.appearance ?? appearance
                }}
            >
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        // @ts-ignore
                        <Component {...props} stripe={stripe} elements={elements} ref={ref} />
                    )}
                </ElementsConsumer>
            </Elements>
        );
    });
}
