import React, { Component } from "react";
import { DialogTitle, DialogActions, Button, TextField, InputAdornment, IconButton, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { _ } from "legacy-js/vendor";
import LinkDS from "legacy-js/react/views/PresentationSettings/dataservices/LinkDataService";
import { RowGrid } from "legacy-js/react/components/LayoutGrid";
import {
    BeautifulDialog,
    DialogContent,
    ShowSnackBar
} from "legacy-js/react/components/Dialogs/BaseDialog";
import { Gap20 } from "legacy-js/react/components/Gap";
import { trackActivity } from "js/core/utilities/utilities";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";
import { LinkType, validateLinkPassword } from "../dataservices/LinkDataService";
import LinkExpiration from "legacy-js/react/components/LinkExpiration";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import getLogger from "js/core/logger";

const logger = getLogger();

export default class CreateLinkDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            passwordError: null,
            showPassword: true,
            accepted: false,
            fetching: true,
            linkType: props.linkType ?? LinkType.PUBLIC,
        };

        this.textFieldRef = React.createRef();
        this.passwordFieldRef = React.createRef();
    }

    async componentDidMount() {
        const linkType = this.props.linkType ?? LinkType.PUBLIC;

        if (linkType === LinkType.PUBLIC) {
            this.link = await LinkDS.createPublicLink(this.props.presentation);
        } else {
            this.link = await LinkDS.createProtectedLink(this.props.presentation);
        }

        this.setState({
            fetching: false,
            linkExpiresAt: this.link.get("expiresAt"),
            linkHasExpired: this.link.shouldLinkExpire() && this.link.hasLinkExpired()
        });
    }

    componentWillUnmount() {
        if (!this.state.accepted && this.link) {
            this.link.destroy();
        }
    }

    handleAccept = async () => {
        try {
            if (this.props.linkType === LinkType.PROTECTED) {
                const { password, passwordError } = this.state;
                if (!password || passwordError) return;

                await LinkDS.updateLink(this.link, { password });
            }

            await LinkDS.saveLink(this.props.presentation, this.link);

            ShowSnackBar({
                message: `Link for ${this.link.get("name")} was copied to the clipboard`
            });
            clipboardWrite({
                [ClipboardType.TEXT]: this.link.getLinkURL(),
            });

            this.setState({ accepted: true });
            this.props.closeDialog();

            const analyticsProps = {
                presentation_id: this.props.presentation.id,
                link_ids: [this.link.get("id")],
                is_new: true,
                type: `${this.link.get("type")}-link`,
                workspace_id: this.props.presentation.getWorkspaceId()
            };
            trackActivity("Sharing", "CopyLink", null, null, analyticsProps, { audit: true });

            this.props.callback && this.props.callback();
        } catch (err) {
            logger.error(err, "[CreateLinkDialog] LinkDS.saveLink() failed", { presentationId: this.props.presentation?.id });
        }
    }

    handleNameChange = event => {
        this.link.update({ name: event.target.value });
        this.forceUpdate();
    }

    handlePasswordChange = async event => {
        const password = event.target.value;
        const passwordError = validateLinkPassword(password);

        this.setState({ password, passwordError });
    }

    handleUpdateLinkExpiration = ({
        linkShouldExpire, linkHasExpired, linkExpiresAt
    }) => {
        this.setState({
            linkShouldExpire,
            linkHasExpired,
            linkExpiresAt
        });

        if (!linkHasExpired) {
            this.link.update({
                expiresAt: linkShouldExpire ? linkExpiresAt : null
            });
        }
    }

    toggleAllowCommenting = event => {
        if (this.link) {
            const allowCommenting = !this.link.get("allowCommenting");
            this.link.update({ allowCommenting });
            this.forceUpdate();
        }
    }

    render() {
        const {
            fetching,
            linkType,
            linkExpiresAt,
            linkHasExpired,
            showPassword,
            password,
            passwordError
        } = this.state;

        const disabled = fetching || linkHasExpired || (linkType === LinkType.PROTECTED && (!password || passwordError));
        const allowCommenting = this.link?.get("allowCommenting");

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>{
                    linkType === "public" ? "Create a Public Link" : "Create a Password Protected Link"
                }</DialogTitle>
                <DialogContent>
                    <RowGrid gap={0}>
                        <h3>{
                            linkType === "public"
                                ? "Anyone with this link will have access to this presentation. Pro users can track engagement with comments and viewer analytics."
                                : "Add a layer of security with a password. Anyone with this link and password will have access to this presentation. Password is case sensitive. Name your link and track engagement with Analytics."
                        }</h3>
                        <Gap20 />
                        <TextField
                            label="Link Name"
                            value={fetching ? "" : this.link.get("name")}
                            onChange={this.handleNameChange}
                            disabled={fetching}
                            inputRef={this.textFieldRef}
                        />
                        <Gap20 />
                        {linkType !== "public" && (
                            <>
                                <TextField
                                    label="Enter Password"
                                    type={showPassword ? "text" : "password"}
                                    onChange={this.handlePasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.setState({ showPassword: !showPassword })}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {passwordError && (
                                    <Typography variant="caption" color="error">
                                        {passwordError}
                                    </Typography>
                                )}
                                <Gap20 />
                            </>
                        )}

                        <ToggleAllowCommenting
                            allowCommenting={allowCommenting}
                            onToggle={this.toggleAllowCommenting}
                        />

                        <LinkExpiration
                            expiresAt={linkExpiresAt}
                            presentation={this.props.presentation}
                            onUpdate={this.handleUpdateLinkExpiration}
                            stacked
                        />
                    </RowGrid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.handleAccept} disabled={disabled} color="primary">
                        Copy Link to Clipboard
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
