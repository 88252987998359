import React, { Component } from "react";
import { app } from "js/namespaces";
import Spinner from "legacy-js/react/components/Spinner";
import ErrorMessage from "legacy-js/react/components/ErrorMessage";
import AnalyticsController from "legacy-js/react/views/Analytics/AnalyticsController";
import { FetchStatus } from "legacy-js/react/constants";
import { UIPane } from "legacy-js/react/components/UiComponents";
import { FeatureType } from "js/core/models/features";
import LinkAnalyticsPane from "./Panes/LinkAnalyticsPane";
import AnalyticsPane from "./Panes/AnalyticsPane";
import "css/analytics.scss";

class Analytics extends Component {
    componentDidMount() {
        setTimeout(async () => {
            if (app.user.features.isFeatureEnabled(FeatureType.ANALYTICS, this.props.presentation.getWorkspaceId())) {
                await AnalyticsController.fetchPresentationAnalytics(this.props.presentation);
                await AnalyticsController.clearSelectedLink();
            } else {
                await AnalyticsController.setDummyData();
            }
        }, 0);
    }

    componentWillUnmount() {
        AnalyticsController.reset();
    }

    render() {
        const { showSelectedLink, status } = this.props.analytics;

        switch (status.type) {
            case FetchStatus.UNSET:
            case FetchStatus.LOADING:
                return (
                    <UIPane>
                        <Spinner />
                    </UIPane>
                );
            case FetchStatus.ERROR:
                return (
                    <UIPane>
                        <ErrorMessage title="An error occurred while loading analytics data" message={status.message} />
                    </UIPane>
                );
            case FetchStatus.SUCCESS:
            default:
                return (<>
                    <AnalyticsPane />
                    {showSelectedLink ? <LinkAnalyticsPane /> : undefined}
                </>);
        }
    }
}

export default AnalyticsController.withState(Analytics);
