import { tinycolor, SVG, _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { AssetType, ASSET_FILETYPE, ASSET_BREAKPOINTS } from "legacy-common/constants";
import { isOfflinePlayer, isRenderer } from "legacy-js/config";

import { BaseElement } from "../BaseElement";

export class MediaElement extends BaseElement {
    get assetId() {
        return this.model[this.bindTo];
    }

    get bindTo() {
        return this.options.bindTo || "content_value";
    }

    get _canSelect() {
        return false;
    }

    get _doubleClickToSelect() {
        return true;
    }

    get passThroughSelection() {
        return false;
    }

    get selectionPadding() {
        return 0;
    }

    get constrainAspectRatio() {
        return this.model.constrainAspectRatio;
    }

    get modelDefined() {
        return !["aoiLeft", "aoiRight", "aoiTop", "aoiBottom"].some(
            prop => this.model[prop] == null || isNaN(this.model[prop]) || !isFinite(this.model[prop]));
    }

    get backgroundColor() {
        if (this.model.backgroundColor && this.model.backgroundColor != "none") {
            return this.model.backgroundColor;
        } else if (this.asset && this.asset.get("hasSolidBackground")) {
            return tinycolor(this.asset.get("imageBackgroundColor")).toHexString();
        }
    }

    get hasAlpha() {
        if (this.asset) {
            return this.asset.get("hasAlpha");
        } else {
            return false;
        }
    }

    get hasSolidBackground() {
        if (this.asset) {
            return this.asset.get("hasSolidBackground");
        } else {
            return false;
        }
    }

    get allowBackdrop() {
        return this.options.allowBackdrop ?? true;
    }

    get isScaledBelowFill() {
        return this.getScale(this.calculatedProps.bounds.size) < this.calcFilledMedia(this.calculatedProps.bounds.size).scale;
    }

    updateAsset(asset) {
        this.model[this.bindTo] = asset.id;
        this.model.content_type = asset.type;

        this.resetMediaTransform();
    }

    resetMediaTransform() {
        // reset the model so the default transform is applied when this new asset is loaded
        this.model.aoiLeft = this.model.aoiRight = this.model.aoiTop = this.model.aoiBottom = this.model.scale = null;
    }

    get initialSizeToLoadMediaAt() {
        return isRenderer ? "original" : "xsmall";
    }

    recalcProps() {
        // changes to calculatedProps means that externally set props will not survive a refreshElement/recalcProps so we need to save/restore clipPath which might have been set from ContentElement
        let clipPath = this.calculatedProps.clipPath;
        let newProps = super.recalcProps();
        newProps.clipPath = clipPath;
        return newProps;
    }

    refreshElement() {
        // If there's an authoring layer on the canvas, then will be calling refreshElement()
        // on the authoring layer to correctly refresh it w/o remounting
        const authoringLayer = this.isOnAuthoringCanvas ? this.canvas.layouter.canvasElement.elements.primary.overlay?.authoringRef?.current : null;
        if (authoringLayer) {
            authoringLayer.refreshElement();
        } else {
            this.canvas.refreshElement(this);
        }
    }

    get canRefreshElement() {
        return true;
    }

    get hflip() {
        if (this.modelDefined) {
            return this.model.flipHorizontal ? -1 : 1;
        }

        return 1;
    }

    _calcProps(props, options) {
        let { size } = props;
        // if (!this.asset) return { size };

        let transform;
        if (this.modelDefined) {
            // image has been initialized, use model properties
            transform = {
                scale: this.adjustScale(this.getScale(size), new geom.Rect(0, 0, size)),
                hflip: this.hflip,
                offset: this.getOffset(size),
                center: this.getMediaCenter()
            };
        } else {
            // no model is set for the image transform so calc the default transform and save
            if (options.optimalFitSize) {
                transform = this.calcFitMedia(new geom.Rect(0, 0, options.optimalFitSize));
            } else if (options.fitAsset) {
                transform = this.calcFitMedia(new geom.Rect(0, 0, options.fitSize || size));
            } else {
                transform = this.getDefaultMediaTransform(size);
                transform.scale = Math.min(1.5, transform.scale);
            }
            this.updateModel(transform, size);
        }

        // if (options.fitAsset) {
        //     transform = this.calcFitMedia(new geom.Rect(0, 0, options.fitSize || size));
        // }

        props.transformProps = {
            translateX: size.width / 2 - transform.hflip * (transform.center.x + transform.offset.x) * transform.scale,
            translateY: size.height / 2 - (transform.center.y + transform.offset.y) * transform.scale,
            scaleX: transform.scale * transform.hflip,
            scaleY: transform.scale
        };

        props.filter = this.model.filter;
        props.filterBrightness = this.model.filterBrightness;
        props.filterBlur = this.model.filterBlur;
        props.filterContrast = this.model.filterContrast;

        return { size };
    }

    loadOptimalSize() {
        this.optimalMediaSize = this.calculateOptimalAssetSizeType(this.calculatedProps.paddedBounds);
        this.asset.getURL(this.optimalMediaSize).then(url => {
            if (!this.canvas.layouter) return;
            this.assetUrl = url;
            if (this.canvas.layouter.isGenerating) {
                this.canvas.layouter.runPostRender(() => this.refreshElement());
            } else {
                this.refreshElement();
            }
        });
    }

    getDefaultMediaTransform(size) {
        if (this.asset && this.asset.get("type") == AssetType.LOGO) {
            return this.calcFitMedia(new geom.Rect(0, 0, size));
        } else {
            return this.calcFilledMedia(new geom.Rect(0, 0, size));
        }
    }

    _prepareToShowElement() {
        if (this.asset && this.asset.get("fileType") === ASSET_FILETYPE.GIF && this.image) {
            this.image.attr("href", this.gifImage.src, SVG.xlink);
        }
    }

    _stopElement() {
        if (this.asset && this.asset.get("fileType") === ASSET_FILETYPE.GIF && this.image) {
            this.image.attr("href", "", SVG.xlink);
        }
    }

    // get the optimal size type that should be loaded at the current canvas scale and element bounds
    calculateOptimalAssetSizeType(bounds = null) {
        if (this.asset.get("fileType") === ASSET_FILETYPE.GIF || this.asset.get("fileType") === ASSET_FILETYPE.SVG) {
            return "original";
        }

        let area = this.asset.get("h") * this.asset.get("w");
        // In the app, always use a deterministic size, maxing out at the 'xlarge' size
        if (isOfflinePlayer) {
            let size = area > (1600 * 1600) ? "xlarge" : "original";
            return this.asset.has(size) ? size : "original";
        }

        let scale = (bounds || this.innerBounds)
            ? this.getScale(bounds || this.innerBounds)
            : (this.model.get("scale") || 1);
        let scaledArea = area * Math.pow(scale * this.canvas.getMaxScale(), 2);
        // * window.devicePixelRatio; mitch: 12/30 - i'm disabling highDPI support to see if it's a better performance/quality tradeoff
        if (scaledArea >= area) {
            return "xlarge";
        }
        for (var breakpoint of ASSET_BREAKPOINTS) {
            if (scaledArea > breakpoint.area) {
                return breakpoint.size;
            }
        }
    }

    // The native size of the asset before scaling
    get mediaSize() {
        if (this.asset) {
            return new geom.Size(this.asset.get("w") + (this.styles.imagePadding || 0) * 2, this.asset.get("h") + (this.styles.imagePadding || 0) * 2);
        } else {
            // Not sure why this path would exist as the data doesn't make sense but it is a path that occurs when migrating the culturegrid template.
            return new geom.Size(0, 0);
        }
    }

    // The center point of the original asset
    getMediaCenter() {
        return new geom.Point(this.mediaSize.width / 2, this.mediaSize.height / 2);
    }

    getScale(size) {
        const {
            aoiLeft,
            aoiRight,
            aoiTop,
            aoiBottom,
        } = this.model;

        let didFit = (
            aoiRight <= this.mediaSize.width &&
            aoiLeft >= 0 &&
            aoiBottom <= this.mediaSize.height &&
            aoiTop >= 0
        );
        let heightScale = size.height / (aoiBottom - aoiTop);
        let widthScale = size.width / (aoiRight - aoiLeft);
        let finalScale = Math.max(heightScale, widthScale);
        if (didFit) {
            // ensure that changing layout will not cause a fitting image to stop fitting
            finalScale = Math.max(finalScale, geom.fillRect(this.mediaSize, size));
        }
        let result = Math.max(this.minScale(size), Math.min(finalScale, this.maxScale(size)));
        if (isNaN(result)) return 1;
        return result;
    }

    adjustScale(scale, size) {
        let fillPct = scale / geom.fillRect(this.mediaSize, size);
        // if (fillPct > 0.90) return Math.max(geom.fillRect(this.mediaSize, bounds), scale);
        return scale;
    }

    shouldFitMedia() {
        return false;
    }

    minScale(size) {
        // if (this.options.allowScaleBelowFill) {
        return geom.fitImageToRect(this.mediaSize, size) * 0.5;
        // } else {
        //     return geom.fillRect(this.mediaSize, size);
        // }
    }

    maxScale(size) {
        return Math.max(1.5, this.calcFilledMedia(size).scale);
    }

    // How far is the image offset from the center of the element
    getOffset(size) {
        let center = this.getMediaCenter();
        let x = (this.model.aoiLeft + this.model.aoiRight) / 2 - center.x;
        if (isNaN(x)) {
            x = 0;
        }
        let y = (this.model.aoiTop + this.model.aoiBottom) / 2 - center.y;
        if (isNaN(y)) {
            y = 0;
        }
        return this.clampOffset(new geom.Point(x, y), size);
    }

    // max offset - offset such that the edge of the image lines up with the edge of the element.
    maxOffset(size, scale) {
        if (scale === undefined) scale = this.getScale(size);
        scale = this.adjustScale(scale, size);

        let center = this.getMediaCenter();

        if (this.shouldFitMedia()) {
            return new geom.Point(0, 0);
        } else {
            return new geom.Point(
                Math.max(0, center.x - size.width / (2 * scale)),
                Math.max(0, center.y - size.height / (2 * scale)));
        }
    }

    minOffset(size, scale) {
        return this.maxOffset(size, scale).scale(-1);
    }

    // Take an offset point and clamp it between min and max
    clampOffset(offset, size, scale) {
        return offset.max(this.minOffset(size, scale)).min(this.maxOffset(size, scale));
    }

    calcFitMedia(size, adjustment = 1) {
        let scale = geom.fitImageToRect(this.mediaSize, size) * adjustment;
        return {
            scale: scale,
            hflip: this.hflip,
            offset: new geom.Point(0, 0),
            center: this.getMediaCenter()
        };
    }

    calcFilledMedia(size) {
        let scale = geom.fillRect(this.mediaSize, size);
        return {
            scale: scale,
            hflip: this.hflip,
            offset: new geom.Point(0, 0),
            center: this.getMediaCenter()
        };
    }

    updateModel(props, size) {
        let scale = "scale" in props ? props.scale : this.getScale(size);
        let offset = props.offset ? this.clampOffset(props.offset, size, scale) : this.getOffset(size);
        let center = this.getMediaCenter();
        let flipHorizontal = "flipHorizontal" in props ? props.flipHorizontal : this.model.flipHorizontal;
        scale = Math.max(this.minScale(size), Math.min(this.maxScale(size), scale));

        let width = size.width / scale;
        let height = size.height / scale;
        let aoiLeft = center.x + offset.x - width / 2;
        let aoiRight = center.x + offset.x + width / 2;
        let aoiTop = center.y + offset.y - height / 2;
        let aoiBottom = center.y + offset.y + height / 2;
        Object.assign(this.model, { aoiLeft, aoiRight, aoiTop, aoiBottom, scale, flipHorizontal, version: 1 });
    }

    get colorFilters() {
        return ["theme", "accent1", "accent2", "accent3", "accent4", "accent5", "background_accent"];
    }
}
