import React, { Component } from "reactn";

export class ImageThumbnail extends Component {
    state = {
        isFailedToLoad: false,
        isLoaded: false
    };

    handleOnLoaded = () => {
        const { onLoaded } = this.props;

        this.setState({ isLoaded: true });

        if (onLoaded) {
            onLoaded();
        }
    };

    handleLoadError = () => {
        this.setState({ isFailedToLoad: true, isLoaded: true });
    };

    render() {
        const {
            isFailedToLoad,
            isLoaded
        } = this.state;
        const {
            imageRef,
            src,
            width,
            autoSize = true,
            imgProps,
            onClick
        } = this.props;

        const styles = {
            opacity: isLoaded ? 1 : 0
        };

        if (autoSize && width) {
            styles.width = width;
            styles.height = (width * imgProps.height) / imgProps.width;
        }

        if (isFailedToLoad) {
            return (
                <div className="image-error-warning" style={styles}>
                    Unable to load image
                </div>
            );
        } else {
            return (
                <img
                    className="image-thumbnail"
                    src={src}
                    style={styles}
                    onLoad={this.handleOnLoaded}
                    onError={this.handleLoadError}
                    onClick={onClick}
                    ref={imageRef}
                />
            );
        }
    }
}
