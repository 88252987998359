import React, { Component, Fragment } from "react";
import Api from "js/core/api";
import { Button, DialogActions, DialogTitle } from "@material-ui/core";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import Loadable from "legacy-js/react/components/Loadable";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.PERMISSIONS);

export default class TransferOwnershipDialog extends Component {
    state = {
        isLoading: false,
    };

    handleAccept = async () => {
        const { resourceId, newOwner, closeDialog, callback } = this.props;
        this.setState({
            isLoading: true
        });
        try {
            await Api.transferPresentationOwnership
                .post({
                    presentationId: resourceId,
                    toUid: newOwner.id
                });
        } catch (err) {
            if (err) {
                logger.error(err, "TransferOwnershipDialog Api.transferPresentationOwnership.post() failed", {
                    resourceId, toUid: newOwner?.id
                });
                ShowErrorDialog({
                    error: "Failed to transfer ownership",
                    message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
                });
            }
        }
        closeDialog();
        callback();
    };

    render() {
        const { isLoading } = this.state;
        const { newOwner, closeDialog } = this.props;

        return (
            <BeautifulDialog closeDialog={closeDialog}>
                <DialogTitle>Transfer Ownership</DialogTitle>
                <DialogContent>
                    <Loadable isLoading={isLoading}>
                        Do you want to give ownership of this presentation to {newOwner.email}? You will be made collaborator with editor
                        permissions.
                    </Loadable>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button disabled={isLoading} onClick={this.handleAccept} color="primary">
                        Make Owner
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
