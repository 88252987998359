import Backbone from "backbone";
import { JSXElementConstructor, ReactElement, useEffect } from "react";

import { LibraryView } from "legacy-common/constants";
import { IUser } from "common/interfaces";
import PresentationLibraryController, { PresentationFilterType, PresentationLibraryControllerState } from "legacy-js/controllers/PresentationLibraryController";

export interface PresentationLibraryControllerInitializerProps {
    workspaceId: string;
    user: Backbone.Model<IUser> & { getLibrarySettings: () => { viewType: string; sortField: string; sortReverse: boolean; }; };
    children: ReactElement<any, string | JSXElementConstructor<any>>;
    view?: string;
    pane?: PresentationFilterType;
    folderId?: string;
    subFolderId?: string;
}

export function PresentationLibraryControllerInitializer({
    user,
    workspaceId,
    view = LibraryView.LIBRARY,
    pane,
    folderId,
    subFolderId,
    children
}: PresentationLibraryControllerInitializerProps) {
    useEffect(() => {
        if (PresentationLibraryController.isInitialized) {
            return;
        }

        const librarySettings = user.getLibrarySettings();
        const viewType = (librarySettings?.viewType ?? "grid") as "grid" | "list";
        const field = librarySettings?.sortField ?? "modifiedAt";
        const reverse = librarySettings?.sortReverse ?? true;

        const initialStateValues: Partial<PresentationLibraryControllerState> & { workspaceId: string } = { workspaceId, viewType, sort: { field, reverse } };

        if (view === LibraryView.LIBRARY && pane) {
            initialStateValues.filter = { type: pane, folderId, subFolderId };
        }

        PresentationLibraryController.initialize(initialStateValues);
    }, []);

    useEffect(() => {
        if (!PresentationLibraryController.isInitialized) {
            return;
        }

        PresentationLibraryController.setWorkspaceId(workspaceId);
    }, [workspaceId]);

    useEffect(() => {
        if (!PresentationLibraryController.isInitialized) {
            return;
        }

        if (view !== LibraryView.LIBRARY) {
            if (PresentationLibraryController.filter.type === PresentationFilterType.TRASH) {
                PresentationLibraryController.setFilter({ type: PresentationFilterType.RECENT, folderId: null, subFolderId: null });
            }
            return;
        }

        if (pane) {
            PresentationLibraryController.setFilter({ type: pane, folderId, subFolderId });
        }
    }, [view, pane, folderId, subFolderId]);

    return children;
}
