import React, { Component } from "reactn";
import * as geom from "js/core/utilities/geom";

export class AuthoringBlock extends Component {
    constructor() {
        super();

        this.ref = React.createRef();
    }

    get blockMargin() {
        return {
            top: 10,
            bottom: 10,
            left: 0,
            right: 0
        };
    }

    get bounds() {
        if (this.ref.current) {
            return geom.Rect.FromBoundingClientRect(this.ref.current.getBoundingClientRect()).inflate(this.blockMargin);
        }

        return null;
    }

    get model() {
        return this.props.model;
    }

    get index() {
        return this.props.index;
    }

    get scale() {
        return this.props.scale;
    }

    get type() {
        return this.model.type;
    }
}
