import React, { Component } from "react";
import styled from "styled-components";
import { Popover, Icon, Tooltip } from "@material-ui/core";

import { cancelPropagation } from "js/core/utilities/utilities";
import presentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { Gap5 } from "legacy-js/react/components/Gap";

const Container = styled.button.attrs(({ disabled }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1
    }
}))`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0px;
  align-items: center;
  cursor: pointer;
  padding: ${({ showArrow }) => showArrow ? "0px 16px" : "0px 10px"};
  height: 100%;
  background: none;
  border: none;
  color: currentColor;
  display: flex;
  padding: 0px 10px;

  label {
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 13px;
  }

  &:hover {
    background: ${({ showHoverEffect }) => showHoverEffect ? "rgba(255,255,255,0.1)" : "none"};
  }

  .drop-down-arrow {
    margin-right: -10px;
    font-size: 18px !important;
  }

  &.dialogStyle {
    border: solid 1px #ccc;
    padding: 5px 12px;
    display: inline-flex;

    label {
      font-size: 13px;
      text-transform: none;
    }

    .drop-down-arrow {
      color: #999;
    }
  }
`;

const Popup = styled(Popover).attrs(p => ({
    $disableOverflow: !!p.$disableOverflow,
}))`
  .MuiMenuItem-root {
    padding: 15px 20px 15px 15px;
    font-family: "Source Sans Pro";
    font-size: 13px;
    font-weight: 600;

    .MuiIcon-root {
      margin-right: 6px;
      color: rgb(119, 119, 119);
      font-size: 20px;
      vertical-align: -6px;
    }
  }

  .MuiFormControlLabel-label {
    font-family: "Source Sans Pro";
    font-size: 13px;
    font-weight: 600;
    text-transform: none;
  }

  .MuiPopover-paper {
    overflow-y: ${p => p.$disableOverflow ? "hidden" : "auto"};
  }
`;

export const PopupMenuPaddedContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 15px;

  label {
    color: #333;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03em;
  }

  hr {
    background: #ddd;
    width: 100%;
    height: 1px;
    border: none;
  }
`;

export class PopupMenu extends Component {
    constructor(props) {
        super(props);

        this.popupRef = React.createRef();

        this.state = {
            show: false,
            anchorEl: null,
            anchorPosition: { left: 100, top: 100 }
        };
    }

    showPopup = event => {
        event.preventDefault();
        event.stopPropagation();

        presentationEditorController.setPopupState(true);
        if (this.props.useFixedPosition) {
            this.setState({
                show: true,
                anchorPosition: {
                    left: event.currentTarget.getBoundingClientRect().left,
                    top: event.currentTarget.getBoundingClientRect().top
                }
            });
        } else {
            this.setState({
                show: true,
                anchorEl: event.currentTarget,
            });
        }

        if (this.props.onShow) {
            this.props.onShow();
        }
    };

    componentWillUnmount() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    closePopup = () => {
        this.setState({ anchorEl: null, show: false });

        presentationEditorController.setPopupState(false);
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    handleClickMenuItem = event => {
        this.props.onChange && this.props.onChange(event.currentTarget.getAttribute("value"));
        this.closePopup();
    }

    repositionPopup = () => {
        this.popupRef && this.popupRef.current.updatePosition();
    }

    render() {
        const {
            id,
            icon,
            label,
            setGap = true,
            svgIcon,
            showArrow = false,
            position,
            tooltip = "",
            style,
            dialogStyle,
            iconStyle,
            children,
            childrenAreMenuItems = false,
            showHoverEffect = true,
            disableOverflow = false,
            disabled = false,
            arrowAdjust = 0,
            useFixedPosition,
            className,
        } = this.props;

        let popupPosition = {};
        if (position == "above") {
            popupPosition = {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                },
                transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            };
        } else {
            popupPosition = {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            };
        }

        let isFunction = typeof children === "function";

        return (
            <div className={className}>
                <Tooltip title={tooltip} arrow={!!tooltip}>
                    <Container
                        id={id}
                        onMouseDown={this.showPopup}
                        className={dialogStyle ? "dialogStyle" : null}
                        style={style} showArrow={showArrow}
                        showHoverEffect={showHoverEffect}
                        disabled={disabled}
                    >
                        {icon && <Icon className="popupmenu-icon" style={iconStyle}>{icon}</Icon>}
                        {setGap && icon && label && <Gap5 />}
                        {label && <label>{label}</label>}
                        {svgIcon}
                        {showArrow && <Icon className="drop-down-arrow"
                            style={{ marginLeft: arrowAdjust }}>arrow_drop_down</Icon>}
                    </Container>
                </Tooltip>

                <Popup
                    $disableOverflow={disableOverflow}
                    action={this.popupRef}
                    open={this.state.show}
                    anchorEl={this.state.anchorEl}
                    disableEnforceFocus
                    {...popupPosition}
                    onClose={this.closePopup}
                    anchorReference={useFixedPosition ? "anchorPosition" : "anchorEl"}
                    anchorPosition={this.state.anchorPosition}
                    // allow the popup to capture mouse events and avoid
                    // accidential closing
                    onMouseDown={cancelPropagation}
                    onMouseUp={cancelPropagation}
                    onClick={cancelPropagation}
                >
                    {!isFunction && React.Children.map(children, child => {
                        if (child) {
                            if (childrenAreMenuItems) {
                                if (child.type.name == "NestedMenuItem") {
                                    return React.cloneElement(child, {
                                        onChildClick: this.closePopup,
                                        repositionPopup: this.repositionPopup,
                                    });
                                } else {
                                    if (child.props.onClick) {
                                        return React.cloneElement(child, {
                                            onClick: () => {
                                                this.closePopup();
                                                child.props.onClick();
                                            },
                                            repositionPopup: this.repositionPopup,
                                        });
                                    } else {
                                        return React.cloneElement(child, {
                                            onClick: this.handleClickMenuItem,
                                            repositionPopup: this.repositionPopup,
                                        });
                                    }
                                }
                            } else {
                                return React.cloneElement(child, {
                                    closePopup: this.closePopup,
                                    repositionPopup: this.repositionPopup,
                                });
                            }
                        }
                    }
                    )}
                    {isFunction && children(this.closePopup)}
                </Popup>
            </div>
        );
    }
}

