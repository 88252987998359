import { ds } from "js/core/models/dataService";
import { $, _ } from "legacy-js/vendor";
import { app } from "js/namespaces.js";
import { controls } from "legacy-js/editor/ui";
import { HorizontalAlignType, NodeType } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { Convert } from "js/core/utilities/geom";
import renderReactRoot from "legacy-js/react/renderReactRoot";

import { CollectionItemElementSelection } from "../CollectionElementEditor";
import { CreateMediaMenu } from "./pictureEditor";
import { ImageFrameMenu } from "../EditorComponents/ImageFrameMenu";

export function renderContentItemControls(view, config) {
    config = _.defaults(config, {
        showSlider: true,
        allowFrames: true
    });

    let items = [];

    if (view.element.parentElement.getAllowedNodeTypes && view.element.parentElement.getAllowedNodeTypes(view.element).length > 1) {
        for (let nodeType of view.element.parentElement.getAllowedNodeTypes(view.element)) {
            switch (nodeType) {
                case NodeType.TEXT:
                    items.push({
                        label: "Text",
                        value: NodeType.TEXT,
                        image: getStaticUrl("/images/ui/node_types/node_text.svg")
                    });
                    break;
                case NodeType.BOX:
                    items.push({
                        label: "Box",
                        value: NodeType.BOX,
                        image: getStaticUrl("/images/ui/node_types/node_box.svg")
                    });
                    break;
                case NodeType.CIRCLE:
                    items.push({
                        label: "Circle",
                        value: NodeType.CIRCLE,
                        image: getStaticUrl("/images/ui/node_types/node_circle.svg")
                    });
                    break;
                case NodeType.DIAMOND:
                    items.push({
                        label: "Diamond",
                        value: NodeType.DIAMOND,
                        image: getStaticUrl("/images/ui/node_types/node_diamond.svg")
                    });
                    break;
                case NodeType.CAPSULE:
                    items.push({
                        label: "Capsule",
                        value: NodeType.CAPSULE,
                        image: getStaticUrl("/images/ui/node_types/node_capsule.svg")
                    });
                    break;
                case NodeType.BULLET_TEXT:
                    items.push({
                        label: "Bullet",
                        value: NodeType.BULLET_TEXT,
                        image: getStaticUrl("/images/ui/node_types/node_bullet_text.svg")
                    });
                    break;
                case NodeType.CONTENT_AND_TEXT:
                    items.push({
                        label: "Media with Text",
                        value: NodeType.CONTENT_AND_TEXT,
                        image: getStaticUrl("/images/ui/node_types/node_content_and_text.svg")
                    });
                    break;
                case NodeType.NUMBERED_TEXT:
                    items.push({
                        label: "Numbered",
                        value: NodeType.NUMBERED_TEXT,
                        image: getStaticUrl("/images/ui/node_types/node_numbered_text.svg")
                    });
                    break;
                case NodeType.CONTENT:
                    items.push({
                        label: "Just Media",
                        value: NodeType.CONTENT,
                        image: getStaticUrl("/images/ui/node_types/node_content.svg")
                    });
                    break;
            }
        }

        view.addControl({
            id: "nodeType",
            type: controls.POPUP_BUTTON,
            icon: "apps",
            showArrow: false,
            menuClass: "icon-menu fourcol",
            items: items,
            callback: value => {
                if (!(view.element.nodeType.equalsAnyOf(NodeType.TEXT, NodeType.NUMBERED_TEXT, NodeType.CONTENT_AND_TEXT, NodeType.BULLET_TEXT) && value.equalsAnyOf(NodeType.Text, NodeType.NUMBERED_TEXT, NodeType.CONTENT_AND_TEXT, NodeType.BULLET_TEXT))) {
                    view.element.model.userSize = null;
                }
                view.element.model.nodeType = value;
                view.element.markStylesAsDirty();

                if (!view.element.model.color) {
                    view.element.model.color = "theme";
                }

                view.element.canvas.refreshCanvasAutoRevert({ transition: false })
                    .then(() => view.element.canvas.saveCanvasModel())
                    .then(() => {
                        // switching the nodeType will create a new nodeElement which this editor is not pointing to
                        // so we can refresh by setting selection.element to the new node which is at the same index as the
                        // old element
                        ds.selection.element = view.element.parentElement.itemElements[view.element.itemIndex];
                    });
            }
        });
    }

    let contentElement = view.element.content || view.element.marker;
    if (view.element.nodeType == NodeType.CONTENT || view.element.nodeType == NodeType.CONTENT_AND_TEXT) {
        CreateMediaMenu(view, contentElement.content);

        if (config.allowFrames) {
            view.addControl({
                type: controls.POPUP_BUTTON,
                icon: "filter_frames",
                showArrow: false,
                customMenuClass: "frame-popup",
                menuContents: closeMenu => {
                    let $menu = $.div();
                    renderReactRoot(ImageFrameMenu, {
                        onSelect: frame => {
                            contentElement.model.frameType = frame;
                            contentElement.markStylesAsDirty();
                            contentElement.canvas.updateCanvasModel(false);
                            closeMenu();
                        }
                    }, $menu[0]);
                    return $menu;
                },
                transitionModel: false
            });
        }

        // if (view.element.canChangeColors) {
        view.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            showBackgroundColors: true,
            omitCurrentBackgroundColor: true,
            showAuto: true,
            showDecorationStyles: () => (contentElement.allowDecorationStyles && config.allowFrames),
            property: "color",
            model: contentElement.model
        });
        // }
    } else if (view.element.canChangeColors) {
        view.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            showBackgroundColors: true,
            omitCurrentBackgroundColor: true,
            showAuto: true,
            showDecorationStyles: () => (view.element.allowDecorationStyles),
            property: "color",
            model: view.element.model
        });
    }

    if (view.element.canChangeTextDirection) {
        view.addControl({
            id: "textDirection",
            type: controls.POPUP_BUTTON,
            icon: getStaticUrl("/images/ui/icons/text-position-white.svg"),
            showArrow: false,
            menuContents: closeMenu => {
                let $menu = $.grid();
                $menu.append(controls.createPositionPicker(view, {
                    label: "Text Position",
                    excludeCorners: true,
                    showAuto: true,
                    showLabels: true,
                    value: view.element.textDirection,
                    callback: value => {
                        view.element.model.textDirection = value;
                        view.element.canvas.updateCanvasModel(false);
                    }
                }));

                return $menu;
            }
        });
    }

    if (view.element.showResizeSlider && config.showSlider) {
        view.addControl({
            type: controls.SLIDER,
            min: view.element.minMarkerSize,
            max: view.element.maxMarkerSize,
            step: 0.1,
            property: "size",
        });
    }
}

export function renderNodeConnectorWidget(view, callback) {
    view.$connectorWidget = view.$el.addEl($.div("create-connector-widget control"));
    view.$connectorWidget.append($.icon("my_location"));
    view.$connectorWidget.on("mousedown", event => callback(event));
    view.$connectorWidget.on("mouseenter", event => {
        view.selectionLayer.hideWidgets(view.$connectorWidget);
    });
    view.$connectorWidget.on("mouseleave", event => {
        if (!app.isDraggingItem) {
            view.selectionLayer.showWidgets(view.$connectorWidget);
        }
    });
}

export const ContentItemSelection = CollectionItemElementSelection.extend({
    showDragDropTarget: false,

    getOffset: function() {
        return 10;
    },

    canDrag() {
        return this.model.canDrag === false ? false : true;
    },

    renderControls() {
        renderContentItemControls(this);

        if (this.element.showResizeHandle) {
            this.renderResizeHandle();
        }
    },

    refreshElement() {
        if (this.element.canRefreshElement) {
            this.element.refreshElement(false);
            return Promise.resolve();
        } else {
            return this.element.canvas.refreshCanvas();
        }
    },

    _layout: function() {
        let selectionBounds = this.getElementSelectionBounds();

        if (this.$connectorWidget) {
            this.$connectorWidget.left(selectionBounds.zeroOffset().right - 9).top(selectionBounds.zeroOffset().bottom - 9);
        }

        if ((this.element.showResizeHandle || this.element.canDragResize) && this.$resizeHandle) {
            this.$resizeHandle.show();
            switch (this.element.horizontalScaleOrigin) {
                case HorizontalAlignType.RIGHT:
                    this.$resizeHandle.left(-5);
                    break;
                case HorizontalAlignType.LEFT:
                case HorizontalAlignType.CENTER:
                default:
                    this.$resizeHandle.left(selectionBounds.width - 5);
                    break;
            }
        } else if (this.$resizeHandle) {
            this.$resizeHandle.hide();
        }
    },

    renderResizeHandle() {
        this.$resizeHandle = this.$el.addEl($.div("resize-handle control horizontal"));
        this.$resizeHandle.on("mousedown", event => {
            app.isDraggingItem = true;

            ds.selection.element = this.element;
            ds.selection.rolloverElement = this.element;

            this.$el.find(".resize-handle").addClass("selected");
            this.selectionLayer.hideWidgets(this.$el.find(".resize-handle, .selection-box"));

            this.element.isDragResizing = true;

            let elementBounds;
            if (this.element.text) {
                elementBounds = this.element.text.canvasBounds.multiply(this.canvasScale);
            } else {
                elementBounds = this.element.canvasBounds.multiply(this.canvasScale);
            }

            let scaleOrigin = this.element.horizontalScaleOrigin;

            $("body").on("mousemove.drag", async event => {
                event.stopPropagation();

                const dragPt = Convert.ScreenToSelectionLayerCoordinates(event.pageX, event.pageY);

                let width;
                switch (scaleOrigin) {
                    case HorizontalAlignType.LEFT:
                        width = Math.abs(dragPt.x - elementBounds.left);
                        break;
                    case HorizontalAlignType.RIGHT:
                        width = Math.abs(dragPt.x - elementBounds.right);
                        break;
                    case HorizontalAlignType.CENTER:
                    default:
                        width = Math.abs(dragPt.x - elementBounds.centerH) * 2;
                        break;
                }

                width /= this.element.canvas.canvasScale;

                this.element.setUserWidth(width);

                // this.element.model.textWidth = Math.clamp(width, this.element.minTextWidth || 100, this.element.maxTextWidth || 1280);

                await this.element.canvas.refreshCanvas();
                this.layout();
            });

            $("body").on("mouseup.drag", event => {
                event.stopPropagation();
                $("body").off(".drag");

                app.isDraggingItem = false;
                this.element.isDragResizing = false;

                this.$el.find(".resize-handle").removeClass("selected");
                this.selectionLayer.showWidgets();

                this.element.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = this.element;
                });
            });
        });
    }

});

export const editors = {
    ContentItemSelection,
};
