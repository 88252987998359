import { _ } from "legacy-js/vendor";
import React, { Component } from "react";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Spinner from "./Spinner";
import { FetchStatus } from "legacy-js/react/constants";
import ErrorMessage from "legacy-js/react/components/ErrorMessage";

export default class ReactChart extends Component {
    createConfig(props) {
        let {
            title,
            height,
            yAxisTitle,
            yAxis2Title,
            series,
            categories,
            useThumbnailFormatter,
            tooltip = null,
        } = props;

        const scrollbar =
            useThumbnailFormatter &&
            categories.length > Math.round(window.innerWidth / 90);
        const maxAxis = scrollbar ? Math.round(window.innerWidth / 90) : null;

        let config = {
            chart: {
                backgroundColor: "transparent",
                height
                // scrollablePlotArea: scrollbar ? {
                //     minWidth: 90 * categories.length,
                //     scrollPositionX: 1
                // } : null
            },
            title: {
                text: title
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    label: {
                        enabled: false
                    }
                },
                line: {
                    color: "#50bbe6",
                    lineWidth: 4,
                    clip: false,
                    softThreshold: false
                },
                column: {
                    color: "#50bbe6",
                    borderWidth: 0
                    //     pointWidth: 80
                }
            },
            yAxis: [
                {
                    title: {
                        text: yAxisTitle
                    },
                    minRange: 0.1,
                    // max: Math.max.apply(Math, series[0].data),
                    gridLineColor: "#444",
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: "#666"
                }
            ],
            xAxis: {
                categories,
                tickLength: 0,
                gridLineColor: "#444",
                gridLineWidth: 0,
                lineWidth: 1,
                lineColor: "#666"
            },
            series,
            credits: {
                enabled: false
            }
        };

        // Only set this field if we actually have a value.
        //   Otherwise, Highcharts will throw an error.
        if (tooltip) {
            config.tooltip = tooltip;
        }

        if (yAxis2Title) {
            config.yAxis.push({
                title: {
                    text: yAxis2Title
                },
                // max: Math.max.apply(Math, series[1].data),
                opposite: true,
                gridLineWidth: 0,
                lineWidth: 1,
                lineColor: "#666"
            });
        }

        props.chartConfig && _.merge(config, props.chartConfig);

        return config;
    }

    render() {
        let { status, height } = this.props;
        let config = this.createConfig(this.props);

        let style = {
            height: height + "px"
        };

        return (
            <div className="chart" style={style}>
                {status.type === FetchStatus.LOADING && <Spinner />}
                {status.type === FetchStatus.SUCCESS && (
                    <HighchartsReact highcharts={Highcharts} options={config} />
                )}
                {status.type === FetchStatus.ERROR && (
                    <ErrorMessage
                        title="An error occured while loading data"
                        message={status.message}
                    />
                )}
            </div>
        );
    }
}
