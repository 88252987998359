import React from "react";
import styled from "styled-components";

import {
    Close as CloseIcon,
    ViewModule as ViewModuleIcon
} from "@material-ui/icons";

import { stopPropagation } from "../helpers/stopPropagation";

import Thumbnail from "./Thumbnail";

const Container = styled.div.attrs(({ visible }) => ({
    style: {
        bottom: visible ? "0" : "-100%"
    }
}))`
    width: 100vw;
    height: 100vh;
    background: black;
    position: absolute;
    left: 0;
    z-index: 999999;

    transition: bottom ease-in-out 0.5s;
`;

const ThumbnailsScrolledContainer = styled.div`
    margin: 48px 20px 20px 20px;
    max-height: calc(100% - 68px);
    overflow-y: auto;

    ::-webkit-scrollbar { 
        display: none;
    }
`;

const ThumbnailsContainer = styled.div`
    padding: 2px;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    justify-items: center;
    margin-bottom: 40px;
`;

const IconContainer = styled.div`
    position: absolute;
    top: 12px;
    right: 50%;
    transform: translateX(50%);
    color: #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const IconText = styled.span`
    margin-left: 5px;
`;

const CloseButton = styled(CloseIcon)`
    position: absolute;
    right: 15px;
    top: 12px;
    color: #ccc;
    cursor: pointer;

    &:hover {
        color: #11a9e2;
    }
`;

function getStyledIconButton(Icon, style = {}) {
    return styled(Icon).attrs(() => ({ style }))`
      color: #ccc;
      cursor: pointer;

      &:hover {
        color: #11a9e2;
      }
    `;
}

const ThumbnailsButton = getStyledIconButton(ViewModuleIcon);

class ThumbnailsPane extends React.Component {
    render() {
        const {
            isOpen,
            presentation,
            slides,
            slidesMetadata,
            currentSlideIndex,
            currentSlidePlaybackStageIndex,
            goToSlide,
            onClose
        } = this.props;

        const skippedSlideIds = Object.entries(slidesMetadata).map(([slideId, { isSkipped }]) => isSkipped ? slideId : null).filter(slideId => !!slideId);

        const Thumbnails = [];
        presentation.getSips(skippedSlideIds)
            .forEach((slideId, slideIndex) => {
                const slide = slides[slideId];
                const playbackStagesCount = slide.getPlaybackStageCount();
                [...Array(playbackStagesCount)].forEach((_, playbackStageIndex) => {
                    Thumbnails.push(
                        <Thumbnail
                            key={`${slideId}-${playbackStageIndex}`}
                            shouldLoadThumbnail={isOpen}
                            hilited={slideIndex === currentSlideIndex && playbackStageIndex === currentSlidePlaybackStageIndex}
                            presentation={presentation}
                            slide={slide}
                            slideMetadata={slidesMetadata[slideId]}
                            clickable={true}
                            playbackStageIndex={playbackStageIndex}
                            slideIndex={slideIndex}
                            onClick={event => stopPropagation(event, () => {
                                goToSlide(slideIndex, true, playbackStageIndex);
                                onClose();
                            })}
                        />
                    );
                });
            });

        return (
            <Container visible={isOpen} >
                <CloseButton onClick={event => stopPropagation(event, onClose)} />
                <IconContainer>
                    <ThumbnailsButton onClick={event => stopPropagation(event, onClose)} />
                    <IconText>{Object.keys(slides).length} {Object.keys(slides).length > 1 ? "SLIDES" : "SLIDE"}</IconText>
                </IconContainer>
                <ThumbnailsScrolledContainer>
                    <ThumbnailsContainer>
                        {Thumbnails}
                    </ThumbnailsContainer>
                </ThumbnailsScrolledContainer>
            </Container >
        );
    }
}

export default ThumbnailsPane;
