export function isFullsceenAllowed() {
    return document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.documentElement.webkitRequestFullScreen;
}

export function isFullscreen() {
    if (window.isOfflinePlayer) {
        return !!window.isFullscreen;
    }

    return (
        document.fullscreenElement != undefined ||
        document.webkitFullscreenElement != undefined ||
        document.mozFullscreenElement != undefined ||
        document.msFullscreenElement != undefined
    );
}

export function enterFullscreen() {
    if (window.isOfflinePlayer) {
        document.dispatchEvent(new CustomEvent("enter-fullscreen"));
        return;
    }

    if (document.body.requestFullscreen) {
        document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen();
    } else if (document.body.mozRequestFullScreen) {
        document.body.mozRequestFullScreen();
    } else if (document.body.msRequestFullscreen) {
        document.body.msRequestFullscreen();
    }
}

export function exitFullscreen() {
    if (window.isOfflinePlayer) {
        document.dispatchEvent(new CustomEvent("exit-fullscreen"));
        return;
    }

    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}

export function toggleFullscreen() {
    if (isFullscreen()) {
        exitFullscreen();
    } else {
        enterFullscreen();
    }
}
