import React from "reactn";

import { _ } from "legacy-js/vendor";
import { getTransformProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { AssetType, HorizontalAlignType, VerticalAlignType } from "legacy-common/constants";
import { Shape } from "js/core/utilities/shapes";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { AutoSizeContentElement } from "../base/ContentElement";
import { TextElement } from "../base/TextElement";
import { TextGroup } from "../base/TextGroup";
import { SVGPathElement, SVGElement } from "../base/SVGElement";
import { CalcTextBoxGrid } from "../layouts/TextGridLayout";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";

const OPEN_QUOTE = "M13.536 49.68C9.024 49.68 5.616 48.048 3.312 44.784C1.104 41.424 0 36.768 0 30.816C0 24 1.68 18 5.04 12.816C8.4 7.632 13.536 3.36 20.448 0L25.056 9.216C20.736 11.424 17.472 13.968 15.264 16.848C13.152 19.632 12.096 23.184 12.096 27.504C12.384 27.408 12.864 27.36 13.536 27.36C16.608 27.36 19.2 28.32 21.312 30.24C23.52 32.064 24.624 34.608 24.624 37.872C24.624 41.52 23.568 44.4 21.456 46.512C19.344 48.624 16.704 49.68 13.536 49.68ZM47.52 49.68C43.008 49.68 39.6 48.048 37.296 44.784C35.088 41.424 33.984 36.768 33.984 30.816C33.984 24 35.664 18 39.024 12.816C42.384 7.632 47.52 3.36 54.432 0L59.04 9.216C54.72 11.424 51.456 13.968 49.248 16.848C47.136 19.632 46.08 23.184 46.08 27.504C46.368 27.408 46.848 27.36 47.52 27.36C50.592 27.36 53.184 28.32 55.296 30.24C57.504 32.064 58.608 34.608 58.608 37.872C58.608 41.52 57.552 44.4 55.44 46.512C53.328 48.624 50.688 49.68 47.52 49.68Z";
const CLOSE_QUOTE = "M4.608 49.68L0 40.464C4.32 38.256 7.53601 35.76 9.64801 32.976C11.856 30.096 12.96 26.496 12.96 22.176C12.672 22.272 12.24 22.32 11.664 22.32C8.592 22.32 5.952 21.408 3.744 19.584C1.632 17.664 0.576004 15.072 0.576004 11.808C0.576004 8.16 1.58401 5.28 3.60001 3.168C5.71201 1.056 8.4 0 11.664 0C16.08 0 19.44 1.632 21.744 4.896C24.048 8.16 25.2 12.816 25.2 18.864C25.2 25.68 23.472 31.68 20.016 36.864C16.656 42.048 11.52 46.32 4.608 49.68ZM38.592 49.68L33.984 40.464C38.304 38.256 41.52 35.76 43.632 32.976C45.84 30.096 46.944 26.496 46.944 22.176C46.656 22.272 46.224 22.32 45.648 22.32C42.576 22.32 39.936 21.408 37.728 19.584C35.616 17.664 34.56 15.072 34.56 11.808C34.56 8.16 35.568 5.28 37.584 3.168C39.696 1.056 42.384 0 45.648 0C50.064 0 53.424 1.632 55.728 4.896C58.032 8.16 59.184 12.816 59.184 18.864C59.184 25.68 57.456 31.68 54 36.864C50.64 42.048 45.504 46.32 38.592 49.68Z";

class UserTestimonial extends CollectionElement {
    getChildItemType() {
        return UserTestimonialItem;
    }

    get defaultItemData() {
        let lastItem = _(this.itemElements).compact().last();
        return {
            showAttribution: lastItem.model.showAttribution,
            showImage: lastItem.model.showImage,
            showLogo: lastItem.model.showLogo
        };
    }

    get flipOrientation() {
        return Boolean(this.model.flipOrientation);
    }

    get maxItemCount() {
        return 6;
    }

    get frameStyle() {
        return this.model.frameStyle || "divider";
    }

    get showFrame() {
        return this.frameStyle == "box" || this.frameStyle == "quote-box";
    }

    _build() {
        super._build();

        if (this.frameStyle === "divider") {
            this.divider = this.addElement("divider", () => UserTestimonialDividers);
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let grid = CalcTextBoxGrid(this.itemElements.length, size, this.styles, { flipOrientation: this.flipOrientation });

        let alignAttributionBlocks = (this.itemElements.length == 2 || this.itemElements.length == 3) && this.flipOrientation == false;

        // calc each item
        let calcItems = (quoteTextScale, maxAttributionHeight) => {
            let minQuoteTextScale = quoteTextScale;
            for (let item of this.itemElements) {
                let gridBounds = grid[this.itemElements.indexOf(item)];

                let itemProps = item.calcProps(gridBounds.size, {
                    // overColor,
                    alignAttributionBlocks,
                    maxAttributionHeight,
                    direction: (gridBounds.width > gridBounds.height) ? "horizontal" : "vertical",
                    quoteTextScale,
                    attributionOptions: {
                        direction: (gridBounds.width > gridBounds.height) ? "horizontal" : "vertical",
                        smallAttribution: this.itemCount >= 4 || (this.itemCount == 3 && this.flipOrientation)
                    }
                });
                itemProps.bounds = gridBounds;
                minQuoteTextScale = Math.min(itemProps.calculatedQuoteTextScale, minQuoteTextScale);
            }
            return minQuoteTextScale;
        };

        // calcing items to figure out max attribution height
        calcItems(1, 0);
        let maxAttributionHeight = 0;
        this.itemElements.forEach(element => {
            if (element.showAttribution) {
                maxAttributionHeight = Math.max(maxAttributionHeight, element.attribution.calculatedProps.size.height);
            }
        });

        // do first pass starting with quoteTextScale = 1, as each item calcs, it can reduce the quoteTextScale if he
        // needs to and then if the quoteTextScale has changed and MatchTextSizes = true, we will recalc with the updated
        // quoteTextScale
        const minQuoteTextScale = calcItems(1, maxAttributionHeight);
        if (this.model.matchQuoteSizes && minQuoteTextScale !== 1) {
            calcItems(minQuoteTextScale, maxAttributionHeight);
        }

        if (this.frameStyle === "divider") {
            this.divider.calcProps(size, { flipOrientation: this.flipOrientation, itemsCount: this.itemElements.length });
        }

        return { size };
    }
}

class UserTestimonialDividers extends SVGElement {
    renderSVG(props, svgStyles, transition) {
        const { bounds, options: { flipOrientation, itemsCount } } = props;

        const dividers = [];
        switch (itemsCount) {
            case 2:
                if (flipOrientation) {
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(0, bounds.height / 2), new geom.Point(bounds.width, bounds.height / 2)));
                } else {
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width / 2, 0), new geom.Point(bounds.width / 2, bounds.height)));
                }
                break;
            case 3:
                if (flipOrientation) {
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(0, bounds.height / 3), new geom.Point(bounds.width, bounds.height / 3)));
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(0, bounds.height - bounds.height / 3), new geom.Point(bounds.width, bounds.height - bounds.height / 3)));
                } else {
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width / 3, 0), new geom.Point(bounds.width / 3, bounds.height)));
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width - bounds.width / 3, 0), new geom.Point(bounds.width - bounds.width / 3, bounds.height)));
                }
                break;
            case 4:
                dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width / 2, 0), new geom.Point(bounds.width / 2, bounds.height)));
                dividers.push(this.drawDivider(dividers.length, new geom.Point(0, bounds.height / 2), new geom.Point(bounds.width, bounds.height / 2)));
                break;
            case 5:
                if (flipOrientation) {
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width / 2, 0), new geom.Point(bounds.width / 2, bounds.height / 2)));
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width / 3, bounds.height / 2), new geom.Point(bounds.width / 3, bounds.height)));
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width - bounds.width / 3, bounds.height / 2), new geom.Point(bounds.width - bounds.width / 3, bounds.height)));
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(0, bounds.height / 2), new geom.Point(bounds.width, bounds.height / 2)));
                } else {
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width / 2, bounds.height), new geom.Point(bounds.width / 2, bounds.height / 2)));
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width / 3, bounds.height / 2), new geom.Point(bounds.width / 3, 0)));
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width - bounds.width / 3, bounds.height / 2), new geom.Point(bounds.width - bounds.width / 3, 0)));
                    dividers.push(this.drawDivider(dividers.length, new geom.Point(0, bounds.height / 2), new geom.Point(bounds.width, bounds.height / 2)));
                }
                break;
            case 6:
                dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width / 3, 0), new geom.Point(bounds.width / 3, bounds.height)));
                dividers.push(this.drawDivider(dividers.length, new geom.Point(bounds.width - bounds.width / 3, 0), new geom.Point(bounds.width - bounds.width / 3, bounds.height)));
                dividers.push(this.drawDivider(dividers.length, new geom.Point(0, bounds.height / 2), new geom.Point(bounds.width, bounds.height / 2)));
                break;
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <g style={svgStyles}>
                    {dividers}
                </g>
            </SVGGroup>
        );
    }

    drawDivider(key, startPoint, endPoint) {
        return <line key={key} x1={startPoint.x} y1={startPoint.y} x2={endPoint.x} y2={endPoint.y} />;
    }
}

class UserTestimonialAttributionDivider extends SVGElement {
    renderSVG(props, svgStyles) {
        const { direction } = props.options;
        const { calculatedProps } = this;
        const { logo } = this.options;
        const isHorizontal = direction == "horizontal";

        // line points
        const x1 = isHorizontal ? logo.bounds.left + logo.styles.marginLeft / 2 : 0;
        const y1 = isHorizontal ? logo.bounds.top : logo.bounds.top + logo.styles.marginTop / 2;
        const x2 = isHorizontal ? logo.bounds.left + logo.styles.marginLeft / 2 : calculatedProps.bounds.width;
        const y2 = isHorizontal ? logo.bounds.bottom : logo.bounds.top + logo.styles.marginTop / 2;

        return (
            <SVGGroup ref={this.ref} >
                <g style={svgStyles}>
                    <line x1={x1} y1={y1} x2={x2} y2={y2} />
                </g>
            </SVGGroup>
        );
    }
}

class UserTestimonialItem extends CollectionItemElement {
    static get schema() {
        return {
            showAttribution: true,
            showDesignQuotes: true
        };
    }

    get selectionPadding() {
        return 0;
    }

    get showDesignQuotes() {
        return this.parentElement.model.showDesignQuotes;
    }

    get showAttribution() {
        return Boolean(this.model.showAttribution);
    }

    get showFrame() {
        return this.parentElement.showFrame;
    }

    get flipOrientation() {
        return this.parentElement.flipOrientation;
    }

    _build() {
        if (this.showFrame) {
            this.frame = this.addElement("frame", () => SVGPathElement);
        }

        this.quote = this.addElement("quote", () => UserTestimonialQuoteElement, {
            placeholder: "Type Quote",
            autoWidth: false,
            autoHeight: true,
            scaleTextToFit: true,
            constrainWidth: true,
            textLayout: "even",
            forceRefreshOnKeyPress: true // needed so quotes recalc on each key press
        });

        if (this.model.showAttribution) {
            this.attribution = this.addElement("attribution", () => UserTestimonialAttribution);
        }

        if (this.showDesignQuotes) {
            // Ppt export doesn't support scale() transform for svg nodes, so we have to export this as an image
            this.leadingQuote = this.addElement("leadingQuote", () => UserTestimonialItemDesignQuote, { side: "leading", exportAsImage: true });
            this.trailingQuote = this.addElement("trailingQuote", () => UserTestimonialItemDesignQuote, { side: "trailing", exportAsImage: true });
        }
    }

    // get alwaysRefreshStyles() {
    //     return true;
    // }

    _loadStyles(styles) {
        if (this.itemCount == 1) {
            styles.applyStyles(styles.bigQuote);
        }

        if (this.flipOrientation && (this.itemCount == 2 || this.itemCount == 3)) {
            styles.applyStyles(styles.wideLayout);
        }

        if (this.itemCount == 2 && this.flipOrientation == false) {
            styles.applyStyles({
                quote: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    paddingBottom: 0
                }
            });
        }

        if (this.showAttribution) {
            styles.applyStyles(styles.withAttribution);
        }
    }

    _calcProps(props, options = {}) {
        let { size } = props;

        let availableHeight = size.height;

        let tailHeight = this.parentElement.frameStyle == "quote-box" ? this.styles.frame.tailHeight : 0;
        availableHeight -= tailHeight;

        if (this.showFrame) {
            this.frame.createProps({
                path: Shape.drawTestimonialBubble(size.inflate(this.styles.padding), Object.assign({ showTail: this.parentElement.frameStyle == "quote-box" }, this.styles.frame)),
                layer: -1,
            });
        }

        if (this.showAttribution) {
            // attributionBlocks were already calcSize'd by parent so we just need to set bounds
            let attributionProps = this.attribution.calcProps(size, options.attributionOptions);
            let x, y;
            if (options.direction == "horizontal") {
                // right justify
                x = size.width - this.attribution.calculatedProps.size.width;
            } else {
                // center
                x = size.width / 2 - this.attribution.calculatedProps.size.width / 2;
            }

            if (options.alignAttributionBlocks) {
                y = size.height - options.maxAttributionHeight - tailHeight;
                availableHeight -= options.maxAttributionHeight;
            } else {
                y = size.height - this.attribution.calculatedProps.size.height - tailHeight;
                availableHeight -= this.attribution.calculatedProps.size.height;
            }

            attributionProps.bounds = new geom.Rect(x, y, attributionProps.size);
        }

        let textLayout;
        if (options.direction == "horizontal") {
            textLayout = "default";
        } else {
            textLayout = "even";
        }

        // Make sure we base off default styles to allow correct props recalc
        this.quote.styles.marginLeft = this.quote.defaultStyles.marginLeft;
        this.quote.styles.marginRight = this.quote.defaultStyles.marginRight;

        let quoteProps = this.quote.calcProps(new geom.Size(size.width, availableHeight), {
            textLayout,
            constrainWidth: true,
            forceTextScale: options.quoteTextScale
        });

        if (this.showDesignQuotes) {
            let adjustedMargin = 70 * 100 / this.quote.styles.fontSize * quoteProps.textScale;
            this.quote.styles.marginLeft = this.quote.styles.marginRight = adjustedMargin;

            quoteProps = this.quote.calcProps(new geom.Size(size.width, availableHeight), {
                textLayout,
                constrainWidth: true,
                forceTextScale: options.quoteTextScale
            });

            const leadingQuoteProps = this.leadingQuote.createProps();
            leadingQuoteProps.quoteProps = quoteProps;
            leadingQuoteProps.layer = 9999;

            const trailingQuoteProps = this.trailingQuote.createProps();
            trailingQuoteProps.quoteProps = quoteProps;
            trailingQuoteProps.layer = 9999;
        }

        if (options.direction == "horizontal") {
            quoteProps.bounds = new geom.Rect(0, availableHeight / 2 - quoteProps.size.height / 2, size.width, quoteProps.size.height);
        } else {
            quoteProps.bounds = new geom.Rect(0, availableHeight / 2 - quoteProps.size.height / 2, size.width, quoteProps.size.height);
        }

        return { size, calculatedQuoteTextScale: this.quote.textScale };
    }

    getBackgroundColor(forElement) {
        if (this.showFrame && this.styles.frame.fillColor != "none") {
            return this.getShapeFillColor(this.frame);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }

    _migrate_9() {
        if (!this.model.showAttribution) {
            this.model.content = null;
            this.model.logo = null;
            this.model.title = null;
            this.model.body = null;
        }
        if (!this.model.showImage) {
            this.model.content = null;
        }
        if (!this.model.showLogo) {
            this.model.logo = null;
        }
    }
}

class UserTestimonialItemDesignQuote extends SVGElement {
    get canvasBounds() {
        return null; // forces ppt export to use svg client rect bounds
    }

    containsPoint() {
        return false;
    }

    createLeadingQuote(quote, svgStyles, transition, scale, firstWord) {
        const quoteProps = quote.calculatedProps;

        let openQuotePosition = new geom.Point(firstWord.x - 65 + (1 - scale) * 60, -15)
            .offset(quote.styles.marginLeft, quote.styles.marginTop)
            .offset(quoteProps.textBounds.left, quoteProps.textBounds.top)
            .offset(quoteProps.paddedBounds.left, quoteProps.paddedBounds.top);

        let openQuoteBounds = new geom.Rect(openQuotePosition, 100, 100);
        this.calculatedProps.bounds = openQuoteBounds;

        return (
            <g {...getTransformProps(openQuoteBounds, transition)}>
                <path d={OPEN_QUOTE} style={{ transform: `scale(${scale})` }} />
            </g>
        );
    }

    createTrailingQuote(quote, svgStyles, transition, scale, lastWord) {
        const quoteProps = quote.calculatedProps;

        let closeQuoteY;
        if (quoteProps.textLayout.lines.length == 1) {
            closeQuoteY = quoteProps.textBounds.top - 15;
        } else {
            closeQuoteY = lastWord.y - 10;
        }

        let closeQuotePosition = new geom.Point(lastWord.x + lastWord.width + 5, closeQuoteY)
            .offset(quote.styles.marginLeft, quote.styles.marginTop)
            .offset(quoteProps.textBounds.left, quoteProps.textBounds.top)
            .offset(quoteProps.paddedBounds.left, quoteProps.paddedBounds.top);

        let closeQuoteBounds = new geom.Rect(closeQuotePosition, 100, 100);
        this.calculatedProps.bounds = closeQuoteBounds;

        return (
            <g {...getTransformProps(closeQuoteBounds, transition)}>
                <path d={CLOSE_QUOTE} style={{ transform: `scale(${scale})` }} />
            </g>
        );
    }

    renderSVG(props, svgStyles, transition) {
        const { quoteProps } = props;

        const BASE_QUOTE_FONT_SIZE = 75;

        let quoteColor = this.canvas.getTheme().palette.getForeColor("slide", this.parentElement.getSlideColor(), this.parentElement.getBackgroundColor(this.parentElement));

        let firstWord = quoteProps.textLayout.lines[0].words[0];
        let lastWord = _.last(_.last(quoteProps.textLayout.lines).words);

        if (!firstWord) return;

        let scale = Math.min(1.1, firstWord.fontSize / BASE_QUOTE_FONT_SIZE);

        const quote = this.options.side === "leading"
            ? this.createLeadingQuote(this.parentElement.quote, svgStyles, transition, scale, firstWord)
            : this.createTrailingQuote(this.parentElement.quote, svgStyles, transition, scale, lastWord);

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <svg style={{ fill: quoteColor }}>
                    {quote}
                </svg>
            </SVGGroup>
        );
    }
}

class UserTestimonialAttribution extends BaseElement {
    static get schema() {
        return {
            showImage: true,
            showLogo: false
        };
    }

    get _canSelect() {
        return false;
    }

    get selectionPadding() {
        return 20;
    }

    get passThroughSelection() {
        return false;
    }

    _build() {
        if (!this.model.content) {
            this.model.content = {};
        }
        if (!this.model.logo) {
            this.model.logo = {};
        }

        this.textGroup = this.addElement("textGroup", () => TextGroup, {
            autoWidth: true,
            autoHeight: true,
            showAddButton: false,
            title: {
                placeholder: "Type name",
                showStyleBar: false
            },
            body: {
                placeholder: "Type title",
                showStyleBar: false
            }
        });
        if (this.model.showImage) {
            this.content = this.addElement("content", () => UserTestimonialPhotoElement, {
                model: this.model.content,
                defaultAssetType: AssetType.IMAGE,
                canSelect: true,
                allowUnframedImages: false
            });
        }
        if (this.model.showLogo) {
            this.logo = this.addElement("logo", () => UserTestimonialLogoElement, {
                model: this.model.logo,
                defaultAssetType: AssetType.LOGO,
                defaultButtonLabel: "Logo",
                defaultShowBackdrop: true,
                fitHeight: true
            });

            this.divider = this.addElement("divider", () => UserTestimonialAttributionDivider, {
                logo: this.logo
            });
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        if (options.direction == "horizontal") {
            this.updateStyles(this.styles.horizontal, true);
        } else {
            this.updateStyles(this.styles.vertical, true);
        }

        this.scaleStyleValues(options.smallAttribution ? 0.75 : 1);

        let items = [];
        if (this.model.showImage) {
            items.push(this.content);
        }
        items.push(this.textGroup);
        if (this.model.showLogo) {
            items.push(this.logo);
            this.logo.styles.height = 50 * (this.logo.model.logoScale || 1);
        }

        let layouter = this.getLayouter(props, items, size);

        if (options.direction == "horizontal") {
            layouter.distributeHorizontally({ verticalAlign: VerticalAlignType.MIDDLE });
        } else {
            layouter.distributeVertically({ horizontalAlign: HorizontalAlignType.CENTER });
        }

        if (this.divider) {
            this.divider.calcProps(layouter.size, { direction: options.direction });
        }

        props.isFit = true;

        return { size: layouter.size, direction: options.direction };
    }
}

class UserTestimonialQuoteElement extends TextElement {
    get minTextScale() {
        return 0.15;
    }

    get minFontSize() {
        return 10;
    }

    get allowAlignment() {
        return true;
    }

    get selectionPadding() {
        return 10;
    }
}

class UserTestimonialPhotoElement extends FramedMediaElement {
    get _canSelect() {
        return true;
    }

    get defaultOverlayType() {
        return "ContentElementDefaultOverlay";
    }

    get passThroughSelection() {
        return false;
    }
}

class UserTestimonialLogoElement extends AutoSizeContentElement {
    // get _canSelect() {
    //     return true;
    // }
    //
    // get passThroughSelection() {
    //     return false;
    // }
    //
    // _loadStyles(styles) {
    //     styles.height = 50 * (this.model.logoScale || 1);
    // }
}

export const elements = {
    UserTestimonial,
};
