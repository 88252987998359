import { ds } from "js/core/models/dataService";
import { controls } from "legacy-js/editor/ui";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddSlideContainer } from "legacy-js/react/views/AddSlide";

import { ElementSelection } from "../BaseElementEditor";

const SlidePlaceholderSelection = ElementSelection.extend({
    getOffset: function() {
        return 20;
    },

    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: "CHOOSE TEMPLATE",
            icon: "autorenew",
            callback: () => {
                ShowDialog(AddSlideContainer, {
                    replaceSlide: ds.selection.slide
                });
            }
        });
    }
});

export const editors = {
    SlidePlaceholderSelection,
};
