import React from "react";
import styled from "styled-components";
import { themeColors } from "legacy-js/react/sharedStyles";

const Badge = styled.div`
  padding: 4px 12px;
  color: white;
  font-weight: 600;
  background: ${themeColors.ui_blue};
  border-radius: 4px;
  user-select: none;
`;

const BadgeSmall = styled.div`
  background: ${themeColors.ui_blue};
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0px;
  font-weight: 600;
  padding: 3px 5px;
  border-radius: 2px;
  text-shadow: 0px 0px 7px rgb(0 0 0 / 20%);
  height: 19px;
  line-height: 1.3em;
  box-sizing: border-box;
`;

export function BetaBadge({ small, ...props }) {
    if (small) {
        return <BadgeSmall {...props}>BETA</BadgeSmall>;
    }

    return <Badge {...props}>BETA</Badge>;
}
