import { $, _ } from "legacy-js/vendor";
import React, { Component } from "react";
import { FeatureType } from "js/core/models/features";
import { app } from "js/namespaces";
import { PresentationPrivacyType } from "js/core/models/presentation";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import ProBadge from "legacy-js/react/components/ProBadge";
import "css/player-settings.scss";
import { Section, UIPane, UIPaneContents, UIPaneHeader } from "legacy-js/react/components/UiComponents";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import { ShowUpgradeDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import styled from "styled-components";
import PlayerSettings from "../components/PlayerSettings";

export default class PlayerSettingsPane extends Component {
    constructor(props) {
        super(props);
        let presentation = props.presentation;
        let privacySetting = presentation.getPrivacySetting();

        this.state = {
            featureEnabled: app.user.features.isFeatureEnabled(FeatureType.PLAYER_SETTINGS, presentation.getWorkspaceId()),
            privacySetting,
            removeBeautifulBranding: app.user.features.isFeatureEnabled(FeatureType.PLAYER_SETTINGS, presentation.getWorkspaceId()),
            showTitle: presentation.get("showTitle"),
            showAuthor: presentation.get("showAuthor"),
            requireEmail: presentation.get("requireEmail") && privacySetting !== PresentationPrivacyType.PRIVATE,
            showSocialSharing: presentation.get("showSocialSharing") && privacySetting == PresentationPrivacyType.PUBLIC,
            showGetDeckUI: presentation.get("showGetDeckUI") && privacySetting === PresentationPrivacyType.PUBLIC,
            autoPlay: presentation.get("autoPlay"),
            autoPlayDuration: presentation.get("autoPlayDuration") || 5,
            autoLoop: presentation.get("autoLoop"),
            showBranding: app.user.features.isFeatureEnabled(FeatureType.PLAYER_SETTINGS, presentation.getWorkspaceId()) == false,
            allowSocialSharing: presentation.get("showSocialSharing") && privacySetting == PresentationPrivacyType.PUBLIC,
            allowPdfDownload: presentation.get("allowPdfDownload") && privacySetting !== PresentationPrivacyType.PRIVATE,
            presentationName: presentation.get("name"),
        };
    }

    componentDidMount = async () => {
        const { presentation } = this.props;
        let slideThumbnail = await presentation.getThumbnailUrl(null, "large");

        this.setState({
            slideThumbnail,
        });
    }

    showUpgradeDialog = event => {
        if (!this.state.featureEnabled) {
            event.preventDefault();
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "Player Settings", ...this.props.presentation.getAnalytics() },
                workspaceId: this.props.presentation.getWorkspaceId()
            });
        }
    };

    handleChange = deltaProps => {
        if (this.state.featureEnabled) {
            const { presentation } = this.props;
            presentation.update(deltaProps);
            this.setState(deltaProps);
        }
    }

    render() {
        const { presentation } = this.props;
        const {
            privacySetting,
            removeBeautifulBranding,
            showTitle,
            showAuthor,
            requireEmail,
            showSocialSharing,
            showGetDeckUI,
            autoPlay,
            autoPlayDuration,
            autoLoop,
            slideThumbnail,
            showBranding,
            allowSocialSharing,
            allowPdfDownload,
            presentationName,
        } = this.state;

        return (
            <UIPane className="player_settings">
                <UIPaneHeader>
                    Player Settings
                    <ProBadge
                        show={shouldShowProBadge(FeatureType.PLAYER_SETTINGS, presentation.getWorkspaceId())}
                        analytics={{ cta: "Player Settings", ...presentation.getAnalytics() }}
                        workspaceId={presentation.getWorkspaceId()}
                    />
                </UIPaneHeader>
                <UIPaneContents>
                    <Section>
                        <PlayerSettings
                            privacySetting={privacySetting}
                            removeBeautifulBranding={removeBeautifulBranding}
                            showTitle={showTitle}
                            showAuthor={showAuthor}
                            creator={{
                                displayName: app.user.getDisplayName(),
                                photoURL: app.user.getAvatarUrl(),
                            }}
                            requireEmail={requireEmail}
                            showSocialSharing={showSocialSharing}
                            showGetDeckUI={showGetDeckUI}
                            includeAutoPlay={true}
                            autoPlay={autoPlay}
                            autoPlayDuration={autoPlayDuration}
                            autoLoop={autoLoop}
                            slideThumbnail={slideThumbnail}
                            showBranding={showBranding}
                            allowSocialSharing={allowSocialSharing}
                            allowPdfDownload={allowPdfDownload}
                            presentationName={presentationName}
                            promptForUpgrade={this.showUpgradeDialog}
                            handleChange={this.handleChange}
                        />
                    </Section>
                </UIPaneContents>
            </UIPane>
        );
    }
}

const AudioPreview = styled.div`
  background: #eee;
  color: black;
  padding: 6px 8px;
  display: inline-flex;
  align-items: center;
`;
