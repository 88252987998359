import React, { Component } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import { Checkbox } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

import { _ } from "legacy-js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "legacy-js/config";
import * as xlsxUtils from "js/core/utilities/xlsx";
import * as REGEX from "js/core/utilities/regex";
import { ElementTypesSupported } from "js/core/services/dataSourceManager";

const logger = getLogger(LogGroup.DATA_SOURCE);

const Container = styled.div`
    width: 100%;
    max-height: 550px;
    display: flex;
    flex-direction: column;
`;

const TableContainer = styled.div`
    overflow-y: auto;
    table {
        width: 100%;
        border-collapse: collapse;
        td, th {
            border: 1px solid #A9A9A9;
            padding: 10px;
            text-align: center;
            text-overflow: ellipsis;
        }
        td.legend-cell {
            background: #DBE9F0;
        }
        td.category-cell {
            background: #FFF1D9;
        }
        td.errored-cell {
            border: 1px double #FF4500;
            color: #FF4500;
        }
        td.hidden-cell {
            color: white;
        }
        td.predata-row-cell {
            height: 24px;
            border: 0px;
            padding: 0px;
            color: #666666;
        }
        td.predata-col-cell {
            width: 24px;
            border: 0px;
            padding: 0px;
            color: #666666;
        }
        td.transpose-button {
            cursor: pointer;
        }
    }
`;

const CheckboxContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 20px;
    .left-container {
        width: 70%;
        display: flex;
        justify-content: flex-start;
        gap: 60px;
    }
    .right-container {
        width: 30%;
        display: flex;
        justify-content: flex-end;
    }
    .error-message {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        color: #FF4500;
    }
`;

const CheckboxLabelButton = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    gap: 15px;
    cursor: pointer;
    .label-text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 15px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
    .highlight-legend {
        background: #DBE9F0;
    }
    .highlight-category {
        background: #FFF1D9;
    }
`;

interface Props {
    elementType: ElementTypesSupported,
    workbookData: XLSX.WorkBook,
    viewCurrLinkedData: boolean,
    selectedSheetIndex: number,
    selectedCellRange: string,
    isDataTransposed: boolean,
    useFirstRowAsCategory: boolean,
    useFirstColAsLegend: boolean,
    handleValidatedData: Function,
}

interface State {
    data: string[][],
    error: string,
    isDataTransposed: boolean,
    useFirstRowAsCategory: boolean,
    useFirstColAsLegend: boolean,
}

export default class PreviewDataPane extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            error: "",
            isDataTransposed: props.isDataTransposed || false,
            useFirstRowAsCategory: props.useFirstRowAsCategory || false,
            useFirstColAsLegend: props.useFirstColAsLegend || false,
        };
    }

    componentDidMount() {
        this.processSelectedData();
    }

    processSelectedData = () => {
        try {
            const { elementType, workbookData, viewCurrLinkedData, selectedSheetIndex, selectedCellRange } = this.props;

            let { isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend } = this.state;
            let { csvData: data } = xlsxUtils.getSelectedWorkbookData(workbookData, selectedSheetIndex, selectedCellRange);

            if (isDataTransposed) data = _.zip(...data);
            data = xlsxUtils.trimCsvData(data);

            if (!data.length || !data[0].length) {
                throw new Error("Parsed CSV data is empty");
            }

            if (
                !viewCurrLinkedData &&
                [ElementTypesSupported.Chart, ElementTypesSupported.PieChart, ElementTypesSupported.WaterfallChart].includes(elementType)
            ) {
                useFirstRowAsCategory = data.length > 1;
                useFirstColAsLegend = elementType === ElementTypesSupported.Chart && data[0].length > 1;
            }

            const error = this.validateSelectedData(data, useFirstRowAsCategory, useFirstColAsLegend);

            this.setState({ data, error, useFirstRowAsCategory, useFirstColAsLegend });
            this.props.handleValidatedData(error ? null : data, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend);
        } catch (err) {
            logger.error(err, "[PreviewDataPane] processSelectedData() failed");
            this.setState({ error: err.message });
        }
    }

    validateSelectedData = (data: string[][], useFirstRowAsCategory: boolean, useFirstColAsLegend: boolean) => {
        switch (this.props.elementType) {
            case ElementTypesSupported.Chart:
                return xlsxUtils.isCsvDataValidForCharts(data, useFirstRowAsCategory, useFirstColAsLegend);
            case ElementTypesSupported.PieChart:
                return xlsxUtils.isCsvDataValidForPieCharts(data, useFirstRowAsCategory);
            case ElementTypesSupported.WaterfallChart:
                return xlsxUtils.isCsvDataValidForWaterfallCharts(data, useFirstRowAsCategory);
            case ElementTypesSupported.Table:
            default:
                return;
        }
    }

    handleTranspose = () => {
        const data = _.unzip(this.state.data);
        const { isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend } = this.state;
        const error = this.validateSelectedData(data, useFirstRowAsCategory, useFirstColAsLegend);

        this.setState({ data, error, isDataTransposed: !isDataTransposed, useFirstColAsLegend, useFirstRowAsCategory });
        this.props.handleValidatedData(error ? null : data, !isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend);
    }

    handleCheckboxClick = (useFirstColAsLegend: boolean, useFirstRowAsCategory: boolean) => {
        const { data, isDataTransposed } = this.state;
        const error = this.validateSelectedData(data, useFirstRowAsCategory, useFirstColAsLegend);

        this.setState({ error, useFirstColAsLegend, useFirstRowAsCategory });
        this.props.handleValidatedData(error ? null : data, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend);
    }

    renderTableBody() {
        const { elementType } = this.props;
        const { data, useFirstColAsLegend, useFirstRowAsCategory } = this.state;

        const tableContent = [];
        const rowCount = data?.length;
        const colCount = data?.[0]?.length;

        for (let y = -1; y < rowCount; y++) {
            const trKey = `tr-${y}`;
            const trCells = [];

            for (let x = -1; x < colCount; x++) {
                const tdKey = `td-${y}-${x}`;
                let className = "";

                if (x >= 0 && y >= 0) {
                    const cell = data[y][x].trim();

                    if ([ElementTypesSupported.Chart, ElementTypesSupported.PieChart, ElementTypesSupported.WaterfallChart].includes(elementType)) {
                        if (elementType === ElementTypesSupported.PieChart && y > (useFirstRowAsCategory ? 1 : 0)) {
                            // when pie charts, consider all rows after second one invalid
                            className = "errored-cell";
                        } else if (elementType === ElementTypesSupported.WaterfallChart && y > (useFirstRowAsCategory ? 1 : 0)) {
                            // when waterfall charts, consider all rows after second one invalid
                            className = "errored-cell";
                        } else {
                            if (useFirstRowAsCategory && y === 0) {
                                // when useFirstRowAsCategory and cell belongs to first row (y=0)
                                className = "category-cell";
                            } else if (useFirstColAsLegend && x === 0) {
                                // when useFirstColAsLegend and cell belongs to first column (x=0)
                                className = "legend-cell";
                            } else if (!REGEX.numberRegex.test(cell)) {
                                // when waterfall charts, allow 'sum' strings in data values
                                if (!(elementType === ElementTypesSupported.WaterfallChart && cell.toUpperCase() === "SUM")) {
                                    className = "errored-cell";
                                }
                            }

                            // when both useFirstRowAsCategory and useFirstColAsLegend then hide cel (0,0)
                            if (x === 0 && y === 0 && useFirstRowAsCategory && useFirstColAsLegend) {
                                className = "hidden-cell";
                            }
                        }
                    }

                    trCells.push(<td key={tdKey} className={className}>{cell}</td>);
                } else {
                    if (y === -1 && x === -1) {
                        className = "predata-row-cell predata-col-cell transpose-button";
                        trCells.push(<td key={tdKey} onClick={this.handleTranspose} className={className}>
                            <img src={getStaticUrl("/images/data-linking/table-transpose.svg")} />
                        </td>);
                    } else if (y === -1) {
                        className = "predata-row-cell";
                        trCells.push(<td key={tdKey} className={className}>{xlsxUtils.columnIndexToLetter(x)}</td>);
                    } else {
                        className = "predata-col-cell";
                        trCells.push(<td key={tdKey} className={className}>{y}</td>);
                    }
                }
            }
            tableContent.push(<tr key={trKey}>{trCells}</tr>);
        }

        return tableContent;
    }

    render() {
        const { elementType } = this.props;
        const { error, useFirstColAsLegend, useFirstRowAsCategory } = this.state;

        return (
            <Container>
                <TableContainer>
                    <table>
                        <tbody>{this.renderTableBody()}</tbody>
                    </table>
                </TableContainer>
                <CheckboxContainer>
                    <div className="left-container">
                        {[ElementTypesSupported.Chart, ElementTypesSupported.PieChart].includes(elementType) && (<>
                            {elementType === ElementTypesSupported.Chart && (
                                <CheckboxLabelButton onClick={() => this.handleCheckboxClick(!useFirstColAsLegend, useFirstRowAsCategory)}>
                                    <Checkbox color="primary" checked={useFirstColAsLegend} />
                                    <span className="label-text highlight-legend">USE FIRST COLUMN AS LEGEND</span>
                                </CheckboxLabelButton>
                            )}
                            <CheckboxLabelButton onClick={() => this.handleCheckboxClick(useFirstColAsLegend, !useFirstRowAsCategory)}>
                                <Checkbox color="primary" checked={useFirstRowAsCategory} />
                                <span className="label-text highlight-category">USE FIRST ROW AS CATEGORY LABELS</span>
                            </CheckboxLabelButton>
                        </>)}
                    </div>
                    <div className="right-container">
                        {error && <span className="error-message"><Warning />{error}</span>}
                    </div>
                </CheckboxContainer>
            </Container >
        );
    }
}
