import * as geom from "js/core/utilities/geom";
import { ContentBlockType } from "legacy-common/constants";
import { _ } from "legacy-js/vendor";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { ContentBlockContainer } from "./ContentBlock";

class StackDiagram extends CollectionElement {
    static get schema() {
        return {
            cols: 12,
            rows: 12
        };
    }

    getChildItemType() {
        return StackDiagramItem;
    }

    getDefaultItemData(col, row, width, height) {
        return {
            x: col,
            y: row,
            width: width,
            height: height,
            showText: true,
        };
    }

    _calcProps(props, options) {
        let { size } = props;
        this.cols = this.model.cols;
        this.rows = this.model.rows;

        this.cols = Math.min(12, this.cols);
        this.rows = Math.min(12, this.rows);

        this.hGap = this.styles.hGap || 0;
        this.vGap = this.styles.vGap || 0;

        this.colWidth = (size.width - (this.cols - 1) * this.hGap) / this.cols;
        this.rowHeight = (size.height - (this.rows - 1) * this.vGap) / this.rows;

        let outOfBounds = [];

        for (let item of this.itemElements) {
            let itemBounds = item.getGridBounds();

            if (itemBounds.left > this.cols - 1 || itemBounds.top > this.rows - 1) {
                outOfBounds.push(item);
                continue;
            }

            if (itemBounds.left + itemBounds.width > this.cols) {
                itemBounds.width = this.cols - itemBounds.left;
                item.model.width = itemBounds.width;
            }
            if (itemBounds.top + itemBounds.height > this.rows) {
                itemBounds.height = this.rows - itemBounds.top;
                item.model.height = itemBounds.height;
            }

            let x = itemBounds.left * (this.colWidth + this.hGap);
            let y = itemBounds.top * (this.rowHeight + this.vGap);
            let width = itemBounds.width * (this.colWidth + this.hGap) - this.hGap;
            let height = itemBounds.height * (this.rowHeight + this.vGap) - this.vGap;

            let itemProps = item.calcProps(new geom.Size(width, height));
            itemProps.bounds = new geom.Rect(x, y, width, height);
        }

        if (outOfBounds.length) {
            _.each(outOfBounds, item => {
                this.model.items.remove(item.model);
                delete this.elements[item.id];
            });
        }

        return { size };
    }

    fillEmptySlots() {
        let emptyCells = [];

        for (let row = 0; row < this.rows; row++) {
            for (let col = 0; col < this.cols; col++) {
                let found = _.find(this.itemElements, function(item) {
                    return col >= item.model.x && col < item.model.x + item.model.width && row >= item.model.y && row < item.model.y + item.model.height;
                });
                if (!found) {
                    emptyCells.push(new geom.Rect(col, row, 1, 1));
                }
            }
        }

        if (emptyCells.length > 0) {
            let box = this.combineCells(emptyCells);
            this.addItem(this.getDefaultItemData(box.left, box.top, box.width, box.height));
        }
    }

    splitItem(item, bounds) {
        let boxes = [];

        let itemBounds = item.getGridBounds();

        // break the item area into boxes without the overlapped area
        for (let row = itemBounds.top; row < itemBounds.bottom; row++) {
            for (let col = itemBounds.left; col < itemBounds.right; col++) {
                let box = new geom.Rect(col, row, 1, 1);
                if (!bounds.overlaps(box)) {
                    boxes.push(box);
                }
            }
        }

        return this.combineCells(boxes);
    }

    combineCells(availableCells) {
        let canGrowRight = function(box) {
            let canGrow = true;
            for (let y = box.top; y < box.bottom; y++) {
                if (!_.find(availableCells, function(target) {
                    return target.top === y && target.left === box.right;
                })) {
                    canGrow = false;
                }
            }
            return canGrow;
        };
        let canGrowDown = function(box) {
            let canGrow = true;
            for (let x = box.left; x < box.right; x++) {
                if (!_.find(availableCells, function(target) {
                    return target.left === x && target.top === box.bottom;
                })) {
                    canGrow = false;
                }
            }
            return canGrow;
        };

        let areas = [];
        //combine the adjacent boxes to form bigger boxes
        for (let box of availableCells) {
            // first check right - down
            let area = box.clone();
            while (canGrowRight(area)) {
                area.width += 1;
            }
            while (canGrowDown(area)) {
                area.height += 1;
            }
            areas.push(area);

            // now check down - right
            area = box.clone();
            while (canGrowDown(area)) {
                area.height += 1;
            }
            while (canGrowRight(area)) {
                area.width += 1;
            }
            areas.push(area);
        }

        //find the biggest combined area
        return _.maxBy(areas, function(area) {
            return area.width * area.height;
        });
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get animationElementName() {
        return "Stack diagram";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

class StackDiagramItem extends CollectionItemElement {
    static get schema() {
        return {
            showText: true,
            showIcon: false,
            x: 0,
            y: 0,
            width: 12,
            height: 1
        };
    }

    get selectionPadding() {
        return 0;
    }

    get isHidden() {
        return (this.model.color == "transparent");
    }

    get showText() {
        if (this.isHidden) {
            return false;
        }
        return this.model.showText != undefined ? this.model.showText : true;
    }

    get showIcon() {
        if (this.isHidden) {
            return false;
        }
        return this.model.showIcon != undefined ? this.model.showIcon : false;
    }

    get showChildren() {
        if (this.isHidden) {
            return false;
        }
        return this.model.items != null && this.model.items.length > 0;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    _build() {
        this.content = this.addElement("content", () => ContentBlockContainer, {
            defaultBlockType: ContentBlockType.TITLE,
            centerPaddingOffset: 0,
            hideDisabledControls: true
        });
    }

    _loadStyles(styles) {
        if (this.showText && this.showChildren) {
            styles.paddingTop = 30;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let contentProps = this.content.calcProps(size);
        contentProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }

    getGridBounds() {
        return new geom.Rect(this.model.x, this.model.y, Math.max(this.model.width, 1), Math.max(this.model.height, 1));
    }

    _migrate_9() {
        this.model.color = this.model.backgroundColor;
        this.model.backgroundColor = null;
    }
}

class StackDiagramItemContainer extends CollectionElement {
    getChildItemType() {
        return StackDiagramItemContainerItem;
    }

    get minItemCount() {
        return 0;
    }

    get defaultItemData() {
        return {
            text: { text: "" }
        };
    }

    _calcProps(props, options) {
        let { size } = props;
        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcRowLayout();

        props.isFit = layouter.isFit && layouter.isTextFit;

        return { size: layouter.size };
    }
}

class StackDiagramItemContainerItem extends CollectionItemElement {
    get selectionPadding() {
        return this.styles.padding;
    }

    _loadStyles(styles) {
        let canvasBackgroundColor = this.canvas.getBackgroundColor();
        if (canvasBackgroundColor.isColor) {
            styles.applyStyles({ decoration: { fillColor: canvasBackgroundColor.setAlpha(0.4).toRgbString() } });
        }
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            autoHeight: false,
            canSelect: false,
            canEdit: false,
            canRollover: false
        });
    }

    _calcProps(props, options) {
        let { size } = props;
        let textSize = this.text.calcSize(size);
        this.text.bounds = new geom.Rect(0, size.height / 2 - textSize.height / 2, textSize);

        this.isTextFit = this.text.isTextFit;
        return { size };
    }
}

export { StackDiagram, StackDiagramItem };

export const elements = {
    StackDiagram,
};
