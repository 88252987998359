import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { controls } from "legacy-js/editor/ui";
import { PaletteColorType } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { $ } from "legacy-js/vendor";
import { Convert } from "js/core/utilities/geom";

import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { TextGroupSelection } from "./TextGroupEditor";

const VennDiagramSelection = CollectionElementSelection.extend({

    getAddItemLabel: function() {
        return "Add Bubble";
    },

    renderControls: function() {
        this.createAddItemButton();

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Presets",
            menuClass: "icon-menu",
            items: [{
                value: "2up", label: "2 circles", image: getStaticUrl("/images/ui/venn/2venn.svg")
            }, {
                value: "3up", label: "3 circles", image: getStaticUrl("/images/ui/venn/3venn.svg")
            }, {
                value: "4up", label: "4 circles", image: getStaticUrl("/images/ui/venn/4venn.svg")
            }],
            callback: type => {
                this.setPredefinedLayout(type);
            }
        });
    },

    createAddItemButton: function(config = {}) {
        this.addControl({
            type: controls.BUTTON,
            label: this.getAddItemLabel(),
            icon: "add_circle",
            enabled: this.element.maxItemCount ? (this.element.itemCount < this.element.maxItemCount) : true,
            callback: () => {
                this.addItem({ model: this.generateRandomPoint() }, true).then(item => {
                    if (item && !config.skipTextSelection) {
                        if (config.callback) {
                            config.callback(item);
                        } else {
                            this.selectNewItemText(item.id);
                        }
                    }
                });
            }
        });
    },

    generateRandomPoint() {
        let x = Math.round(Math.random() * 100) - 50;
        let y = Math.round(Math.random() * 100) - 50;
        let radius = Math.round(Math.random() * 100) + 50;
        let items = this.element.itemElements;
        let item = items.find(item => Math.abs(item.x - x) < 10 && Math.abs(item.y - y) < 10);
        if (item) {
            return this.generateRandomPoint();
        } else {
            return { x, y, radius };
        }
    },

    polygonContains: function(mouseX, mouseY, path) {
        // ray-casting algorithm based on
        // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
        let xi,
            xj,
            yi,
            yj,
            i,
            j,
            intersect,
            x = mouseX,
            y = mouseY,
            inside = false;
        for (i = 0, j = path.length - 1; i < path.length; j = i++) {
            xi = path[i][0], yi = path[i][1], xj = path[j][0], yj = path[j][1], intersect = yi > y != yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi;

            if (intersect) inside = !inside;
        }
        return inside;
    },

    onMouseMove: function(event) {
        let mouseX = event.clientX - this.element.getScreenBounds().left;
        let mouseY = event.clientY - this.element.getScreenBounds().top;

        for (let sub of this.subComponentBounds) {
            if (this.polygonContains(mouseX, mouseY, sub.path)) {
                // show the widget and call the rollover callback
                sub.widget.opacity(1);
                sub.rollover(event, mouseX + this.element.offsetX, mouseY + this.element.offsetY);
            } else {
                // hide the widget
                sub.widget.opacity(0);
            }
        }
    },

    onClick: function(event) {
        let mouseX = event.clientX - this.element.getScreenBounds().left;
        let mouseY = event.clientY - this.element.getScreenBounds().top;
        mouseX -= this.element.offsetX;
        mouseY -= this.element.offsetY;

        for (let sub of this.subComponentBounds) {
            if (this.polygonContains(mouseX, mouseY, sub.path)) {
                sub.click(event, mouseX, mouseY);
            }
        }
    },

    setPredefinedLayout: function(type) {
        let items = this.element.itemCollection;

        let createOrTrimItems = count => {
            if (items.length > count) {
                while (items.length > count) {
                    this.element.deleteItem(items.pop().id);
                }
            } else {
                while (items.length < count) {
                    this.element.addItem();
                }
            }
        };

        switch (type) {
            case "2up":
                createOrTrimItems(2);
                items[0] = {
                    title: items[0].title,
                    body: items[0].body,
                    x: -150,
                    y: 0,
                    radius: 200,
                    color: PaletteColorType.ACCENT1
                };
                items[1] = {
                    title: items[1].title,
                    body: items[1].body,
                    x: 150,
                    y: 0,
                    radius: 200,
                    color: PaletteColorType.ACCENT2
                };
                break;
            case "3up":
                createOrTrimItems(3);
                items[0] = {
                    title: items[0].title,
                    body: items[0].body,
                    x: 0,
                    y: -100,
                    radius: 150,
                    color: PaletteColorType.ACCENT1
                };
                items[1] = {
                    title: items[1].title,
                    body: items[1].body,
                    x: -100,
                    y: 100,
                    radius: 150,
                    color: PaletteColorType.ACCENT2
                };
                items[2] = {
                    title: items[2].title,
                    body: items[2].body,
                    x: 100,
                    y: 100,
                    radius: 150,
                    color: PaletteColorType.ACCENT3
                };
                break;
            case "4up":
                createOrTrimItems(4);
                items[0] = {
                    title: items[0].title,
                    body: items[0].body,
                    x: -100,
                    y: -100,
                    radius: 140,
                    color: PaletteColorType.ACCENT1
                };
                items[1] = {
                    title: items[1].title,
                    body: items[1].body,
                    x: 100,
                    y: -100,
                    radius: 140,
                    color: PaletteColorType.ACCENT2
                };
                items[2] = {
                    title: items[2].title,
                    body: items[2].body,
                    x: -100,
                    y: 100,
                    radius: 140,
                    color: PaletteColorType.ACCENT3
                };
                items[3] = {
                    title: items[3].title,
                    body: items[3].body,
                    x: 100,
                    y: 100,
                    radius: 140,
                    color: PaletteColorType.ACCENT4
                };
                break;
        }
        this.element.canvas.refreshCanvas().then(() => {
            // this.element.beautify();
            this.element.canvas.updateCanvasModel();
        });
    }

});

const VennDiagramItemSelection = CollectionItemElementSelection.extend({
    captureMouseEvents: true,
    showDragDropTarget: false,

    canDrag: function() {
        return true;
    },

    renderControls: function() {
        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });

        this.addControl({
            type: controls.SLIDER,
            min: 75,
            max: 400,
            label: "Size",
            property: "radius",
            onEnd: () => {
                app.mainView.editorView.selectionLayer.hideWidgets();
                this.element.parentElement.beautify();
                this.element.parentElement.canvas.updateCanvasModel(true).then(() => {
                    app.mainView.editorView.selectionLayer.showWidgets();
                });
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "settings",
            showArrow: false,
            items: [{
                value: "bringToFront", label: "Bring to Front", icon: "flip_to_front"
            }, {
                value: "sendToBack", label: "Send to Back", icon: "flip_to_back"
            }],
            callback: action => {
                switch (action) {
                    case "bringToFront":
                        this.element.parentElement.itemCollection.remove(this.element.model);
                        this.element.parentElement.itemCollection.push(this.element.model);
                        break;
                    case "sendToBack":
                        this.element.parentElement.itemCollection.remove(this.element.model);
                        this.element.parentElement.itemCollection.insert(this.element.model, 0);
                        break;
                }
                this.element.canvas.updateCanvasModel(false);
            }
        });
    },

    onDrag: function(event, position, dragProps) {
        this.element.dragPosition = position.elementPosition;
        if (!this.canvas.layouter.isGenerating) {
            this.canvas.refreshCanvas(false);
        }
    },

    onStopDrag: async function(event, position, dragProps) {
        event.stopPropagation();

        let dragItem = dragProps.dragItem;
        let containerElement = dragProps.containerElement;

        dragItem.isDragging = false;

        let offset = containerElement.calculatedProps.offset;
        let scale = containerElement.calculatedProps.scale;

        dragItem.model.x = (position.elementPosition.x - offset.x) / scale + dragItem.model.radius;
        dragItem.model.y = (position.elementPosition.y - offset.y) / scale + dragItem.model.radius;

        // await dragProps.dragItem.canvas.refreshCanvas();
        containerElement.beautify();
        await dragItem.canvas.updateCanvasModel(true);
        app.mainView.editorView.selectionLayer.showWidgets();
    }

});

const VennDiagramLabelSelection = TextGroupSelection.extend({

    renderControls() {
        TextGroupSelection.prototype.renderControls.apply(this, arguments);

        let $dragWidget = this.createDragWidget(this.element, {});
        $dragWidget.on("mousedown", event => this.handleDragStart(event));

        let containerElement = this.element.parentElement;
        if (containerElement.model.userPositionedLabel || this.element.model.textWidth) {
            this.addControl({
                type: controls.BUTTON,
                label: "Auto position label",
                icon: "auto_fix_high",
                callback: value => {
                    ds.selection.element = null;
                    this.element.model.textWidth = null;
                    containerElement.model.userPositionedLabel = false;
                    this.element.getRootElement().beautify();
                    containerElement.canvas.updateCanvasModel(true).then(() => {
                        this.render();
                    });
                }
            });
        }
    },

    handleDragStart(event) {
        app.isDraggingItem = true;

        let containerElement = this.element.parentElement;

        ds.selection.element = null;

        // this.selectionLayer.hideWidgets(this.$el.find(".resize-handle,.selection-box"));

        let mouseMoveHandledAt;
        $("body").on("mousemove.drag", event => {
            event.stopPropagation();

            // Making dragging smoother
            window.requestAnimationFrame(timestamp => {
                if (mouseMoveHandledAt === timestamp) {
                    return;
                }
                mouseMoveHandledAt = timestamp;

                let dragPt = Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY);

                containerElement.model.labelCenterX = dragPt.x - containerElement.calculatedProps.bounds.width / 2 + this.element.calculatedProps.bounds.width / 2 + this.element.selectionPadding;
                containerElement.model.labelCenterY = dragPt.y - containerElement.calculatedProps.bounds.height / 2 + this.element.calculatedProps.bounds.height / 2 + this.element.selectionPadding;
                containerElement.model.userPositionedLabel = true;

                containerElement.refreshElement();
            });
        });

        $("body").on("mouseup.drag", event => {
            event.stopPropagation();
            $("body").off(".drag");
            app.isDraggingItem = false;
            // this.selectionLayer.showWidgets();

            this.element.canvas.updateCanvasModel(false).then(() => {
                ds.selection.element = this.element;
            });
        });
    }

});

export const editors = {
    VennDiagramSelection,
    VennDiagramItemSelection,
    VennDiagramLabelSelection
};
