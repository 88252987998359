import { _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { AssetType, ContentBlockType } from "legacy-common/constants";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { ContentElement } from "../base/ContentElement";
import { TextGroup } from "../base/TextGroup";
import { SVGPolylineElement, SVGCircleElement } from "../base/SVGElement";

class Timeline extends CollectionElement {
    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    getChildItemType() {
        return TimelineItem;
    }

    get canRollover() {
        return true;
    }

    getCanvasMargins() {
        return {
            left: this.model.showStartMarker ? 50 : 0,
            right: this.model.showEndMarker ? 50 : 0,
            top: 50,
            bottom: 50
        };
    }

    _build() {
        if (this.model.showStartMarker) {
            this.startMarker = this.addElement("start_marker", () => TimelineMarker, { model: this.model.startMarker });
        }
        if (this.model.showEndMarker) {
            this.endMarker = this.addElement("end_marker", () => TimelineMarker, { model: this.model.endMarker });
        }

        this.timeline = this.addElement("timeline", () => SVGPolylineElement);

        super._build();
    }

    sortItems() {
        return _.sortBy(this.itemElements, item => {
            return item.model.x;
        });
    }

    _calcProps(props) {
        const { size } = props;

        if (this.itemElements.length > this.styles.maxItemCount) {
            props.isFit = false;
        }

        // timeline bounds start at full bounds and then are reduced if showing start/end markers
        this.timelineBounds = new geom.Rect(0, 0, size);

        if (this.model.showStartMarker) {
            let startMarker = this.startMarker.calcProps(new geom.Size(this.styles.markerSize, this.styles.markerSize));
            startMarker.bounds = new geom.Rect(0, size.height / 2 - this.styles.markerSize / 2, this.styles.markerSize, this.styles.markerSize);
            this.timelineBounds = this.timelineBounds.deflate({ left: this.styles.markerSize });
        }
        if (this.model.showEndMarker) {
            let endMarker = this.endMarker.calcProps(new geom.Size(this.styles.markerSize, this.styles.markerSize));
            endMarker.bounds = new geom.Rect(size.width - this.styles.markerSize, size.height / 2 - this.styles.markerSize / 2, this.styles.markerSize, this.styles.markerSize);
            this.timelineBounds = this.timelineBounds.deflate({ right: this.styles.markerSize });
        }

        // add props for the timeline line
        this.timeline.createProps({
            path: [[this.timelineBounds.left, size.height / 2], [this.timelineBounds.right, size.height / 2 + 1]],
            layer: -1
        });

        // layout timeline milestones

        let currentMilestonePosition = "above";
        let lastAboveMilestone, lastBelowMilestone;

        for (let milestone of this.sortItems()) {
            let milestoneOptions = {
                position: currentMilestonePosition
            };

            // calculate x value from 0-1 scale
            let x = (milestone.model.x || 0.5) * this.timelineBounds.width + this.timelineBounds.left;
            //offset so the contentBlock is centered on the x position
            // if (milestone.model.showContent) {
            //     x -= milestone.options.contentSize / 2;
            // }

            // alternate between above and below and set the positionOffset if the widths of 2 items overlap
            if (currentMilestonePosition == "above") {
                milestoneOptions.position = "above";
                if (lastAboveMilestone && lastAboveMilestone.bounds.right > x) {
                    switch (lastAboveMilestone.verticalOffset) {
                        case "top":
                            milestoneOptions.verticalOffset = "bottom";
                            break;
                        case "middle":
                            lastAboveMilestone.verticalOffset = "top";
                            milestoneOptions.verticalOffset = "bottom";
                            break;
                        case "bottom":
                            milestoneOptions.verticalOffset = "top";
                            break;
                    }
                } else {
                    milestoneOptions.verticalOffset = "middle";
                }
            } else {
                milestoneOptions.position = "below";
                if (lastBelowMilestone && lastBelowMilestone.bounds.right > x) {
                    switch (lastBelowMilestone.verticalOffset) {
                        case "top":
                            milestoneOptions.verticalOffset = "bottom";
                            break;
                        case "middle":
                            lastBelowMilestone.verticalOffset = "bottom";
                            milestoneOptions.verticalOffset = "top";
                            break;
                        case "bottom":
                            milestoneOptions.verticalOffset = "top";
                            break;
                    }
                } else {
                    milestoneOptions.verticalOffset = "middle";
                }
            }

            let milestoneProps = milestone.calcProps(new geom.Size(this.styles.itemWidth, size.height), milestoneOptions);
            milestoneProps.bounds = new geom.Rect(x, 0, milestoneProps.size.width, size.height);

            if (currentMilestonePosition == "above") {
                lastAboveMilestone = {
                    bounds: milestoneProps.bounds,
                    verticalOffset: milestoneOptions.verticalOffset
                };
            } else {
                lastBelowMilestone = {
                    bounds: milestoneProps.bounds,
                    verticalOffset: milestoneOptions.verticalOffset
                };
            }

            currentMilestonePosition = currentMilestonePosition == "above" ? "below" : "above";
        }

        return { size };
    }

    distributeMilestones(clearDraggedFlags = false) {
        const sorted = _.sortBy(this.itemCollection, "x");
        if (clearDraggedFlags) sorted.forEach(x => x.hasBeenDragged = false);
        const remaining = [...sorted];
        let startX = 0;
        let endX = 1;
        while (remaining.length) {
            // Get all the items before the left-most dragged item
            let indexEnd = remaining.findIndex(x => x.hasBeenDragged);
            if (indexEnd > -1) endX = remaining[indexEnd].x;
            else indexEnd = remaining.length;
            const undragged = remaining.splice(0, indexEnd);

            // Space out the undragged items between start and end
            const range = endX - startX;
            for (let index = 0; index < undragged.length; ++index) {
                const item = undragged[index];
                const interp = (index + 1) / (undragged.length + 1);
                item.x = startX + interp * range;
            }

            // Remove the dragged item from remaining
            if (remaining.length) {
                remaining.splice(0, 1);
                startX = endX;
                endX = 1;
            }
        }
    }

    get animationElementName() {
        return "Timeline";
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    getAnimations() {
        const animations = super.getAnimations();
        const sortedItemElements = this.sortItems();
        animations.sort((a, b) => sortedItemElements.indexOf(a.element) - sortedItemElements.indexOf(b.element));
        return animations;
    }
}

class TimelineMarker extends TextElement {
    get allowStyling() {
        return true;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [];
    }
}

class TimelineItem extends CollectionItemElement {
    get name() {
        return "Milestone";
    }

    get canRollover() {
        return false;
    }

    get selectionPadding() {
        return 10;
    }

    get selectionBounds() {
        return this.infoBlock.selectionBounds;
    }

    _build() {
        this.infoBlock = this.addElement("infoBlock", () => TimelineItemInfoBlock, {
            showContent: this.parentElement.model.showContent
        });

        this.connector = this.addElement("connector", () => SVGPolylineElement);
        this.marker = this.addElement("marker", () => SVGCircleElement);
    }

    _calcProps(props, options) {
        const { size } = props;

        let timelineOffsetY = 75;

        let infoBlock = this.infoBlock.calcProps(size, { timelineOffsetY });

        let y; // This will be used for migration
        let offsetY;
        if (options.position === "above") {
            y = 0.25;
            offsetY = size.height / 2 - timelineOffsetY - infoBlock.size.height;
        } else {
            y = this.parentElement.model.showContent ? 0.7 : 0.65;
            offsetY = size.height / 2 + timelineOffsetY;
        }
        let verticalSpacing = 50;
        switch (options.verticalOffset) {
            case "top":
                y -= (this.parentElement.model.showContent && options.position !== "above") ? 0.15 : 0.1;
                offsetY -= verticalSpacing;
                break;
            case "middle":
                offsetY += 0;
                break;
            case "bottom":
                y += (this.parentElement.model.showContent && options.position !== "above") ? 0.12 : 0.1;
                offsetY += verticalSpacing;
                break;
        }

        this.model.y = y;

        let offsetX = 0;
        if (this.parentElement.model.showContent) {
            offsetX -= this.styles.infoBlock.content.width / 2;
        }
        infoBlock.bounds = new geom.Rect(offsetX, offsetY, infoBlock.size);

        let timelinePoint = new geom.Point(0, size.height / 2);

        let connectorEndY;
        if (this.parentElement.model.showContent) {
            if (options.position === "above") {
                connectorEndY = offsetY + this.styles.infoBlock.content.height + this.infoBlock.content.bounds.top;
            } else {
                connectorEndY = offsetY + this.infoBlock.content.bounds.top;
            }
        } else {
            if (options.position === "above") {
                connectorEndY = offsetY;
            } else {
                connectorEndY = offsetY + infoBlock.size.height;
            }
        }

        this.connector.createProps({
            path: [[timelinePoint.x, timelinePoint.y], [timelinePoint.x, connectorEndY]]
        });

        this.marker.createProps({
            bounds: new geom.Rect(timelinePoint.x - this.styles.marker.radius, timelinePoint.y - this.styles.marker.radius, this.styles.marker.radius * 2, this.styles.marker.radius * 2)
        });

        return { size };
    }

    // updateSVGBounds is overridden in timeline so we can animate the vertical position of the text while dragging milestones in the editor
    shouldTransition() {
        let transition = false;
        // set the item's bounds as normal. transition will be false if a result of dragging milestone
        // this.svg.setBounds(this.offsetBounds, transition);

        // check if the text is at a different vertical position from the previous render and force transition to true for all the children of the item
        // this will animate the vertical position of the text and the connector line while the item itself is positioned without animation for smooth dragging
        if (!this.isDragging || this.lastY != this.infoBlock.calculatedProps.bounds.y) {
            transition = true;
        }
        this.lastY = this.infoBlock.calculatedProps.bounds.y;
        return transition;
    }

    get animationElementName() {
        return `Milestone #${this.itemIndex + 1}`;
    }
}

class TimelineItemInfoBlock extends BaseElement {
    get selectionPadding() {
        return 10; //
    }

    _build() {
        if (this.options.showContent) {
            this.content = this.addElement("content", () => ContentElement, {
                model: this.model,
                defaultAssetType: AssetType.ICON
            });
        }

        this.text = this.addElement("text", () => TextGroup, {
            allowedBlockTypes: [ContentBlockType.TITLE, ContentBlockType.BODY],
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let maxSize = new geom.Size(size.width, size.height / 2 - options.timelineOffsetY);
        if (this.options.showContent) {
            let layouter = this.getLayouter(props, [this.content, this.text], maxSize);
            layouter.calcHorizontalBlockLayout({
                verticalAlign: layouter.VerticalBlockAlignType.MIDDLE_TITLE,
                allowVerticalTextOverflow: true
            });
            return { size: layouter.size };
        } else {
            let text = this.text.calcProps(maxSize);
            text.bounds = new geom.Rect(0, 0, text.size);
            return { size: text.size };
        }
    }
}

export const elements = {
    Timeline
};
