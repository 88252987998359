import React, { Component } from "reactn";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Tab, Tabs } from "@material-ui/core";
import { $ } from "legacy-js/vendor";
import { getStaticUrl } from "legacy-js/config";
import styled from "styled-components";
import { themeColors } from "legacy-js/react/sharedStyles";

import { DeviceFrames } from "../../elements/base/Frame";

const TabBar = styled.div`
  width: 100%;
`;

const ImageFrameTabContents = styled.div`
  width: 100%;
  height: 160px;
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  color: #333;
  padding: 0px 20px;
  
  div {
    padding: 10px 20px;
  }
  
  div:hover {
    background: ${themeColors.rollover};
  }
  
  img {
    height: 80px;
    margin-bottom: 20px;
  } 
  
  label {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export class ImageFrameMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: (props.showDecorativeFrames ?? true) ? 0 : 1
        };
    }

    handleChangeTab = (event, tab) => {
        this.setState({ value: tab });
    }

    handleSelect = (id, aspectRatio) => {
        this.props.onSelect(id, aspectRatio);
    }

    render() {
        const { showDecorativeFrames = true } = this.props;
        const { value } = this.state;

        return (
            <FlexBox vertical left style={{ width: 1000 }}>
                <TabBar>
                    <Tabs
                        value={value}
                        onChange={this.handleChangeTab}
                    >
                        <Tab id="decorative" label="Decorative" />
                        <Tab id="computers" label="Computers" />
                        <Tab id="displays" label="Displays" />
                        <Tab id="phones" label="Phones" />
                        <Tab id="cisco" label="Cisco Devices" />
                        <Tab id="tablets" label="Other Devices" />
                    </Tabs>
                </TabBar>
                <div style={{ width: "100%", overflowX: "scroll" }}>
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={0} category="Decorative" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={1} category="Computers" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={2} category="Displays" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={3} category="Phones" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={4} category="Cisco" />
                    <ImageFrameTab onSelect={this.handleSelect} value={value} index={5} category="Other" />
                </div>
            </FlexBox>
        );
    }
}

class ImageFrameTab extends Component {
    handleSelect = device => {
        let img = $(`#${device.id}`)[0];
        let aspectRatio = img.width / img.height;
        this.props.onSelect(device.id, aspectRatio);
    }

    render() {
        const { value, index, category } = this.props;

        if (value == index) {
            return (
                <ImageFrameTabContents key={category}>
                    {DeviceFrames.filter(d => d.category == category && !d.isHidden).map(device => (
                        <FlexBox key={device.id} vertical onClick={() => this.handleSelect(device)}>
                            <img id={device.id} src={getStaticUrl("/images/frames/thumbnails/" + device.thumbnail)} />
                            <label>{device.name}</label>
                        </FlexBox>
                    ))}
                </ImageFrameTabContents>
            );
        } else {
            return null;
        }
    }
}
