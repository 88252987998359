import React, { Component } from "reactn";
import isConnected from "js/core/utilities/isConnected";
import {
    getImageProperties,
    trimWhiteEdges,
} from "js/core/utilities/imageUtilities";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { ASSET_FILETYPE, AssetType } from "legacy-common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import Spinner from "../../../components/Spinner";
import { ImageThumbnail } from "legacy-js/react/components/ImageThumbnail";
import { loadImage } from "js/core/utilities/promiseHelper";
import { ShowOfflineDialog, ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

const logger = getLogger(LogGroup.ASSETS);

class LogoPreview extends Component {
    state = {
        isLoading: false
    };

    render() {
        const {
            addAssetCallback,
            handleConfirm,
            src,
            name,
            width
        } = this.props;
        const {
            isLoading
        } = this.state;

        const importLogo = async () => {
            if (!isConnected.connected) {
                ShowOfflineDialog();
                return;
            }

            this.setState({ isLoading: true });

            const tempImage = await loadImage(src, null, "anonymous");
            const updatedDataURL = trimWhiteEdges(tempImage);
            const image = await loadImage(updatedDataURL);
            const imageProperties = getImageProperties(image, {
                type: "image/png"
            });

            imageProperties.hasSolidBackground = true;
            imageProperties.imageBackgroundColor = "#FFFFFF";

            return ds.assets
                .getOrCreateImage({
                    url: updatedDataURL,
                    name,
                    fileType: ASSET_FILETYPE.PNG,
                    assetType: AssetType.LOGO,
                    metadata: {
                        source: "logo"
                    },
                    imagePropsOverride: imageProperties
                })
                .then(asset => {
                    addAssetCallback(asset);
                    this.setState({ isLoading: false });
                    handleConfirm();
                })
                .catch(err => {
                    ShowWarningDialog({
                        title: "Sorry!",
                        message: "We seem to be having trouble accessing this image. Please try again.",
                    });
                    this.setState({ isLoading: false });
                    logger.error(err, "[LogoPreview] importLogo() failed");
                });
        };

        return (
            <div
                className="logo-wrapper"
                onClick={importLogo}
                style={{ width: width, height: width }}
            >
                {isLoading && <Spinner />}
                <ImageThumbnail src={src} />
            </div>
        );
    }
}

export default LogoPreview;
