import React, { Component } from "react";
import { DialogTitle, DialogActions, Button, TextField, InputAdornment, IconButton, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { RowGrid } from "legacy-js/react/components/LayoutGrid";
import {
    BeautifulDialog,
    DialogContent,
    ShowSnackBar
} from "legacy-js/react/components/Dialogs/BaseDialog";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";
import LinkDS, { LinkType, validateLinkPassword } from "../dataservices/LinkDataService";
import { FlexSpacer, Gap20 } from "legacy-js/react/components/Gap";
import LinkExpiration from "legacy-js/react/components/LinkExpiration";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { TextButton } from "legacy-js/react/components/UiComponents";

export default class EditLinkDialog extends Component {
    state = {
        showPassword: true,
        showResetPassword: true,
        linkShouldExpire: false,
        linkHasExpired: false,
        linkExpiresAt: null,
    };

    componentDidMount() {
        this.setState({
            linkExpiresAt: this.props.link?.get("expiresAt")
        });
    }

    copyToClipboard() {
        const url = this.props.link.getLinkURL();
        clipboardWrite({ [ClipboardType.TEXT]: url });
        ShowSnackBar({
            message: `Link for ${this.props.link.get("name")} was copied to the clipboard`
        });
    }

    handleAccept = shouldCopy => {
        if (shouldCopy) {
            this.copyToClipboard();
        }

        this.props.closeDialog();
        this.props.callback();
    };

    handleNameChange = name => {
        this.props.link.update({ name });
        this.update();
    }

    handlePasswordChange = async event => {
        const link = this.props.link;

        const password = event.target.value;
        const passwordError = validateLinkPassword(password);

        await LinkDS.updateLink(link, { password, enabled: !passwordError });
        this.setState({ passwordError });
    }

    toggleAllowCommenting = event => {
        const { link } = this.props;
        if (link) {
            const allowCommenting = !link.get("allowCommenting");
            link.update({ allowCommenting });
            this.update();
        }
    }

    handleUpdateLinkExpiration = ({ linkShouldExpire, linkHasExpired, linkExpiresAt }) => {
        this.setState({
            linkShouldExpire,
            linkHasExpired,
            linkExpiresAt
        });

        if (!linkHasExpired) {
            this.props.link.update({
                expiresAt: linkShouldExpire ? linkExpiresAt : null
            });
        }
    }

    update = () => {
        this.forceUpdate();
        this.props.callback();
    }

    render() {
        const { link, presentation } = this.props;
        const { linkHasExpired, linkExpiresAt, showResetPassword, showPassword, passwordError } = this.state;

        const disabled = linkHasExpired || link.isLinkExpired() || !link.get("enabled");
        const allowCommenting = link.get("allowCommenting");
        const linkType = link.get("type");

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>{
                    linkType === LinkType.PROTECTED ? "Edit Password Protected Link" : "Edit Shareable Link"
                }</DialogTitle>
                <DialogContent>
                    <RowGrid gap={0}>
                        <TextField
                            label="Link Name"
                            value={link.get("name")}
                            onChange={event => this.handleNameChange(event.target.value)}
                        />
                        <Gap20 />

                        {linkType === LinkType.PROTECTED && (
                            showResetPassword ? (
                                <>
                                    <TextButton
                                        onClick={() => this.setState({ showResetPassword: false })}
                                        style={{ padding: 0, color: "orangered", width: "fit-content", backgroundColor: "transparent" }}
                                    >
                                        Reset Password
                                    </TextButton>
                                    <Gap20 />
                                </>
                            ) : (
                                <>
                                    <TextField
                                        label="Enter Password"
                                        type={showPassword ? "text" : "password"}
                                        onChange={this.handlePasswordChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => this.setState({ showPassword: !showPassword })}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {passwordError && (
                                        <Typography variant="caption" color="error">
                                            {passwordError}
                                        </Typography>
                                    )}
                                    <Gap20 />
                                </>
                            )
                        )}

                        <ToggleAllowCommenting
                            allowCommenting={allowCommenting}
                            onToggle={this.toggleAllowCommenting}
                        />
                        <LinkExpiration
                            expiresAt={linkExpiresAt}
                            presentation={presentation}
                            link={link}
                            onUpdate={this.handleUpdateLinkExpiration}
                            stacked
                        />
                    </RowGrid>
                </DialogContent>
                <DialogActions>
                    <FlexSpacer />
                    <Button
                        onClick={() => this.handleAccept(true)}
                        disabled={disabled}
                        color="secondary"
                    >
                        Copy Link
                    </Button>
                    <Button
                        onClick={() => this.handleAccept(false)}
                        disabled={disabled}
                        color="primary"
                    >
                        Done
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
