import * as geom from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { SVGPolygonElement } from "../base/SVGElement";

class ArrowBars extends CollectionElement {
    getChildItemType() {
        return ArrowBarItem;
    }

    get defaultItemData() {
        return {
            label: { text: "" },
            value: 75,
            showArrowHead: true
        };
    }

    get maxItemCount() {
        return 5;
    }

    getCanvasMargins() {
        return {
            left: this.model.showFoldEffect ? 0 : 50,
            top: 50,
            right: 50,
            bottom: 50
        };
    }

    _migrate_5() {
        if (this.model.showArrowHeads && this.model.items) {
            for (let item of this.model.items) {
                item.showArrowHead = true;
            }
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        const layouter = this.getLayouter(props, this.itemElements, size);
        if (this.isAnimating) {
            layouter.calcVerticalLayout({ itemOptions: { textScale: this.minTextScale } });
        } else {
            this.minTextScale = 1;
            layouter.calcVerticalLayout();

            if (this.minTextScale < 1) {
                // Recalc using minTextScale
                layouter.calcVerticalLayout({ itemOptions: { textScale: this.minTextScale } });
            }
        }

        layouter.alignVertically();

        if (this.model.showFoldEffect) {
            for (const item of this.itemElements) {
                const arrowOffset = item.styles.arrow.arrowHeadOffset;
                const topPoint = [item.calculatedProps.foldWidth, item.bounds.top + arrowOffset / 2];
                const bottomPoint = [item.calculatedProps.foldWidth, item.bounds.top + item.bounds.height - arrowOffset / 2];
                const vanishingPoint = [item.styles.arrow.vanishingPointOffsetX, item.styles.arrow.vanishingPointOffsetY + size.height / 2];

                let slope = (vanishingPoint[1] - topPoint[1]) / (vanishingPoint[0] - topPoint[0]);
                const perspectiveTopPoint = [0, vanishingPoint[1] - slope * vanishingPoint[0]];

                slope = (vanishingPoint[1] - bottomPoint[1]) / (vanishingPoint[0] - bottomPoint[0]);
                const perspectiveBottomPoint = [0, vanishingPoint[1] - slope * vanishingPoint[0]];

                const foldPath = [perspectiveTopPoint, topPoint, bottomPoint, perspectiveBottomPoint].map(points => [points[0] - item.calculatedProps.elementPadding, points[1] - item.bounds.top]);

                item.fold.createProps({
                    path: foldPath
                });
            }
        }

        return { size };
    }
}

class ArrowBarItem extends CollectionItemElement {
    static get schema() {
        return {
            showArrowHead: true,
            value: 50
        };
    }

    get showIndex() {
        return this.parentElement.model.showIndex;
    }

    get selectionPadding() {
        return 0;
    }

    get rolloverPadding() {
        return 0;
    }

    get MIN_FOLD_WIDTH() {
        return 120;
    }

    get showFold() {
        return this.parentElement.model.showFoldEffect;
    }

    _migrate_8() {
        this.model.value = Math.min(this.model.value * 100 / 91, 100);
    }

    _build() {
        this.arrow = this.addElement("arrow", () => SVGPolygonElement);

        if (this.showFold) {
            this.fold = this.addElement("fold", () => SVGPolygonElement);
        }

        if (this.showIndex) {
            this.index = this.addElement("index", () => TextElement, {
                model: {
                    index: this.itemIndex + 1
                },
                canRollover: false,
                canEdit: false,
                canSelect: false,
                isTabbable: false,
                scaleTextToFit: true
            });
        }

        this.label = this.addElement("label", () => TextElement, {
            autoHeight: false
        });
    }

    _calcProps(props, options) {
        const { size } = props;
        const { textScale } = options;

        const currentValue = Math.max(this.isAnimating ? (this.animationState.growProgress ?? 1) * this.model.value : this.model.value, 15);
        // Setting minimum value to 11.5%
        const percent = ((currentValue ?? 100) / 100) * 0.885 + 0.115;

        let arrowBounds = new geom.Rect(0, 0, (size.width + this.styles.paddingLeft + this.styles.paddingRight) * percent, size.height).inflate({
            top: this.styles.paddingTop,
            bottom: this.styles.paddingBottom
        }).zeroOffset();
        let availableTextBounds = new geom.Rect(0, 0, size.width * percent, size.height);
        if (this.model.showArrowHead) {
            availableTextBounds.width -= 50;
        }

        if (this.showFold) {
            // children.createProps(this.fold);

            props.elementPadding = this.parentElement.styles.marginLeft;
            props.foldWidth = Math.max(this.MIN_FOLD_WIDTH, props.elementPadding);
            //offset the arrow bounds
            const offset = props.foldWidth - props.elementPadding - arrowBounds.left;

            arrowBounds = arrowBounds.deflate({ left: offset });
            availableTextBounds = availableTextBounds.deflate({ left: offset });
        }

        const arrowProps = this.arrow.createProps({
            layer: -1
        });

        if (this.model.showArrowHead) {
            arrowProps.path = Shape.drawArrow(arrowBounds, arrowBounds.height - this.styles.arrow.arrowHeadOffset, this.styles.arrow.arrowHeadLength, "right");
        } else {
            arrowBounds.height -= this.styles.arrow.arrowHeadOffset;
            arrowBounds.top += this.styles.arrow.arrowHeadOffset * 0.5;
            arrowProps.path = Shape.drawBounds(arrowBounds);
        }

        if (this.showIndex) {
            const index = this.index.calcProps(new geom.Size(40, availableTextBounds.height));
            index.bounds = new geom.Rect(availableTextBounds.left, availableTextBounds.centerV - index.size.height / 2, index.size);
            availableTextBounds = availableTextBounds.deflate({ left: index.size.width + this.index.styles.marginRight });
        }

        const labelBounds = this.isAnimating ? (this.animationLabelBounds ?? availableTextBounds) : availableTextBounds;
        const labelProps = this.label.calcProps(labelBounds.size, {
            forceTextScale: textScale ?? null,
            scaleTextToFit: !textScale
        });
        labelProps.bounds = new geom.Rect(labelBounds.left, 0, labelProps.size);

        if (!textScale) {
            this.parentElement.minTextScale = Math.min(this.label.textScale, this.parentElement.minTextScale);
        }

        if (this.isAnimating) {
            const isLabelFit = labelProps.originalTextSize.height <= availableTextBounds.height && labelProps.originalTextSize.width <= availableTextBounds.width;
            if (isLabelFit && !this.isLabelFit) {
                this.labelFitAtProgress = this.animationState.growProgress;
                this.isLabelFit = true;
            } else if (isLabelFit && this.animationState.growProgress >= this.labelFitAtProgress) {
                this.label.animationState.fadeInProgress = (this.animationState.growProgress - this.labelFitAtProgress) / (1 - this.labelFitAtProgress);
                if (this.showIndex) {
                    this.index.animationState.fadeInProgress = this.label.animationState.fadeInProgress;
                }
            } else if (!isLabelFit) {
                this.isLabelFit = false;
            }
        }

        return { size };
    }

    getBackgroundColor(forElement) {
        if (forElement === this.label || forElement === this.index) {
            return this.getShapeFillColor(this.arrow);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }

    get animationElementName() {
        return `Arrow bar #${this.itemIndex + 1}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            easing: "easeOutQuad",
            prepare: () => {
                this.parentElement.animationTextScale = this.parentElement.minTextScale;
                this.animationLabelBounds = this.label.calculatedProps.bounds;
                this.isLabelFit = false;
                this.animationState.growProgress = 0;
                this.animationState.fadeInProgress = 0;

                this.label.animationState.fadeInProgress = 0;
                if (this.showIndex) {
                    this.index.animationState.fadeInProgress = 0;
                }
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.growProgress = progress;
                this.animationState.fadeInProgress = Math.min(progress * 2, 1);
                return this.parentElement;
            }
        }];
    }
}

export { ArrowBars };

export const elements = {
    ArrowBars,
};
