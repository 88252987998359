import { _ } from "legacy-js/vendor";
import React, { Component, Fragment } from "reactn";
import { ASSET_RESOLUTION, AssetType } from "legacy-common/constants";
import { SearchInput } from "../../../components";
import {
    UIContainerContext,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "legacy-js/react/components/UiComponents";
import { ds } from "js/core/models/dataService";
import ImageThumbnailGrid from "legacy-js/react/views/AddAssets/Components/ImageThumbnailGrid";
import Loadable from "legacy-js/react/components/Loadable";
import AssetPreview from "legacy-js/react/views/AddAssets/Components/AssetPreview";
import { loadImage } from "js/core/utilities/promiseHelper";
import { getAverageImageColor } from "js/core/utilities/imageUtilities";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import { UIActionType, isUIActionRestricted } from "js/core/uiActionsRestrictors";
import { Notice } from "legacy-js/react/components/Notice";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ASSETS);

export class RecentlyUsedAssetsPane extends Component {
    static contextType = UIContainerContext;

    state = {
        loadedAssets: [],
        isLoading: true,
        muted: true,
    };

    componentDidMount() {
        this.lookupPreviewRefs = {};
        this.loadRecentlyUsedAssets();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.assetTypes, prevProps.assetTypes)) {
            this.loadRecentlyUsedAssets();
        }
    }

    async getAssetsToLoad(loadedAssets = null) {
        const {
            assetTypes
        } = this.props;

        loadedAssets = loadedAssets || this.state.loadedAssets;

        const orgId = UIController.getOrganizationId();
        const assetResponses = await Promise.all(assetTypes.map(type => ds.assets.getAssetsByType(type, orgId)));
        let assetsToLoad = assetResponses
            .flat()
            .sort((a, b) => {
                const userAssetA = ds.userAssets.get(a.id);
                const userAssetB = ds.userAssets.get(b.id);
                const timeA = userAssetA?.get("lastUsed") || userAssetA?.get("modifiedAt");
                const timeB = userAssetB?.get("lastUsed") || userAssetB?.get("modifiedAt");

                return timeB - timeA;
            })
            // Respecting ui restrictions
            .filter(asset => !isUIActionRestricted(UIActionType.ADD_ASSET, { assetType: asset.type }))
            // Only load up to 100 results and ignore the already loaded assets
            .slice(loadedAssets.length, 100);

        // Load urls now
        const asyncLoadProps = assetsToLoad.map(asset => {
            let loadAsset;
            if (asset.type === AssetType.VIDEO || asset.type === AssetType.STOCK_VIDEO) {
                loadAsset = async () => {
                    const result = { asset };
                    try {
                        const previewProps = { ...asset.attributes };
                        if (asset.type === AssetType.VIDEO) {
                            previewProps.url = await asset.getURL();
                            const previewAssetId = asset.get("previewAssetId");
                            if (previewAssetId) {
                                const previewAsset = await ds.assets.getAssetById(previewAssetId, AssetType.IMAGE);
                                previewProps.previewUrl = await previewAsset.getURL();
                            }
                        }

                        // Ensure we have width and height values
                        previewProps.width = previewProps.width || previewProps.w;
                        previewProps.height = previewProps.height || previewProps.h;

                        result.previewProps = previewProps;
                    } catch (err) {
                        logger.error(err, "[RecentlyUsedAssetsPane] failed to load video asset", { id: asset.id });
                        result.error = err;
                    }

                    return result;
                };
            } else {
                loadAsset = async () => {
                    const result = { asset };
                    try {
                        let url = await asset.getURL(ASSET_RESOLUTION.SMALL);

                        // Will calculate average image color to set black background for white svg and png logos
                        let renderDarkBackground = false;
                        if (asset.type === AssetType.LOGO && (asset.get("fileType") === "svg" || asset.get("fileType") === "png")) {
                            let image;
                            // Note: anonymous cross origin will allow us to use this image in a canvas to calculate its color
                            try {
                                image = await loadImage(url, null, "Anonymous");
                            } catch (err) {
                                // Will retry w/o cache because cached requests don't get served correctly for
                                // anonymous cross origin requests
                                url = await asset.getURL(ASSET_RESOLUTION.SMALL, true);
                                image = await loadImage(url, null, "Anonymous");
                            }
                            const { r, g, b, hasAlpha } = getAverageImageColor(image);
                            if (hasAlpha && r > 240 && g > 240 && b > 240) {
                                renderDarkBackground = true;
                            }
                        }

                        const previewProps = {
                            ...asset.attributes,
                            url,
                            renderDarkBackground,
                        };

                        // Ensure we have width and height values
                        previewProps.width = previewProps.width || previewProps.w;
                        previewProps.height = previewProps.height || previewProps.h;

                        result.previewProps = previewProps;
                    } catch (err) {
                        logger.error(err, "[RecentlyUsedAssetsPane] failed to load image asset", { id: asset.id });
                        result.error = err;
                    }

                    return result;
                };
            }
            return loadAsset();
        });
        assetsToLoad = await Promise.all(asyncLoadProps);

        loadedAssets = [
            ...loadedAssets,
            ...assetsToLoad,
        ];

        // Remove the assets with errors and load any remaining assets to fill the gaps
        const assetsWithErrors = assetsToLoad.filter(({ error }) => !!error);
        if (assetsWithErrors.length) {
            const assets = assetsWithErrors.map(({ asset }) => asset);
            this.removeAssets(assets, loadedAssets);
            loadedAssets = await this.getAssetsToLoad(loadedAssets);
        }

        return loadedAssets;
    }

    async loadRecentlyUsedAssets() {
        this.setState({
            isLoading: true
        });

        const loadedAssets = await this.getAssetsToLoad();

        this.setState({
            loadedAssets,
            isLoading: false
        });
    }

    removeAssets = (assetsToRemove, loadedAssets) => {
        assetsToRemove.forEach(asset => {
            const userAsset = ds.userAssets.get(asset.id);
            ds.userAssets.remove(userAsset);

            // Remove the ref associated with this asset
            const key = asset.id;
            this.lookupPreviewRefs[key] && delete this.lookupPreviewRefs[key];

            // Remove the asset from the loaded assets
            const index = loadedAssets.findIndex(x => x.asset.id === asset.id);
            if (index > -1) {
                loadedAssets.splice(index, 1);
            }
        });
    }

    removeAssetCallback = async asset => {
        let {
            loadedAssets
        } = this.state;

        this.removeAssets([asset], loadedAssets);

        // Load any assets we haven't loaded already
        loadedAssets = await this.getAssetsToLoad();

        this.setState({
            loadedAssets,
        });
    };

    hidePlaybacks = itemExcluded => {
        this.lookupPreviewRefs = this.lookupPreviewRefs || {};
        Object.entries(this.lookupPreviewRefs)
            .filter(([key, value]) => !!value?.current && key !== itemExcluded.id)
            .forEach(([key, value]) => value.current.hidePlayback());
    }

    render() {
        const {
            isLoading,
            loadedAssets,
            muted,
        } = this.state;
        const {
            addAssetCallback,
            handleConfirm,
            handleSearch,
            assetTypes,
            searchPrompt,
            showSearch,
            backgroundVideoOnly = false,
        } = this.props;
        const inputFocused = !this.context;

        const showLogos = assetTypes.includes(AssetType.LOGO);
        const onlyLogos = assetTypes.every(x => x === AssetType.LOGO);

        return (
            <UIPane>
                <UIPaneHeader>
                    {showSearch ? <SearchInput
                        placeholder={searchPrompt}
                        handleSubmit={searchTerm => {
                            handleSearch(searchTerm);
                        }}
                        focus={inputFocused}
                        handleClearSearch={() =>
                            this.setState({
                                searchResults: null
                            })
                        }
                    /> : "Recently Used Media"}
                </UIPaneHeader>
                <UIPaneContents style={{ paddingTop: 20 }}>
                    <Loadable isLoading={isLoading}>
                        {
                            !!loadedAssets.length &&
                            <ImageThumbnailGrid
                                columns={showLogos ? 4 : 3}
                            >
                                {
                                    loadedAssets.filter(entry => !entry.asset.get("hidden")).map(({
                                        asset,
                                        previewProps,
                                    }) => {
                                        // Get the ref, or create it if it doesn't exist
                                        const key = asset.id;
                                        let ref = this.lookupPreviewRefs[key];
                                        if (!ref) {
                                            ref = React.createRef();
                                            this.lookupPreviewRefs[key] = ref;
                                        }
                                        return (
                                            <AssetPreview
                                                ref={ref}
                                                key={key}
                                                className={`media-asset-${asset.type}`}
                                                asset={asset}
                                                previewProps={previewProps}
                                                addAssetCallback={addAssetCallback}
                                                handleConfirm={handleConfirm}
                                                showContextMenu
                                                muted={muted}
                                                toggleMuted={() => this.setState({ muted: !this.state.muted })}
                                                hidePlaybacks={this.hidePlaybacks}
                                                backgroundVideoOnly={backgroundVideoOnly}
                                                menuItems={asset.canRemoveFromUserAssets()
                                                    ? [{
                                                        label: "Remove from Recently Used",
                                                        onClick: this.removeAssetCallback
                                                    }]
                                                    : []
                                                }
                                            />
                                        );
                                    })
                                }
                            </ImageThumbnailGrid>
                        }
                        {
                            !loadedAssets.length &&
                            <>
                                {
                                    onlyLogos &&
                                    <Notice
                                        title="Search for a logo"
                                    />
                                }
                                {
                                    !onlyLogos &&
                                    <Notice
                                        title="You haven't used any media yet."
                                        message="Search our free media library for images, videos and logos."
                                    />
                                }
                            </>
                        }
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default RecentlyUsedAssetsPane;
