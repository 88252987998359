import { controls } from "legacy-js/editor/ui";
import moment from "moment/moment";
import { _, $ } from "legacy-js/vendor";

import { ElementSelection, ElementOptionsMenu } from "../BaseElementEditor";

const CountdownOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        // changing directions
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Count",
            items: [
                { value: "down", label: "Down" },
                { value: "up", label: "Up" },
            ],
            value: this.element.model.direction || "down",
            callback: value => {
                this.element.model.direction = value;
                this.element.resetCountdownDisplay();
            }
        });

        // toggle on and off seconds
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Seconds",
            labelAtEnd: true,
            model: this.model,
            property: "showSeconds",
            callback: show => {
                if (show) {
                    this.element.resetCountdownDisplay();
                }
            }
        });
    }
});

const CountdownSelection = ElementSelection.extend({
    createDateTimePickerMenu() {
        let $menu = $.div("countdown-menu");

        // date formats to work with
        const modelDateFormat = "MM/DD/YYYY";
        const modelTimeFormat = "hh:mm A";
        const inputFormat = "YYYY-MM-DDTHH:mm";
        const modelFormat = `${modelDateFormat} ${modelTimeFormat}`;

        // convert to datetie local input
        const date = moment(`${this.element.model.date} ${this.element.model.time}`, modelFormat);
        const local = date.format(inputFormat);

        // create the input
        const $datetime = $.input();
        $datetime.attr({
            type: "datetime-local",
            value: local
        });

        const getCurrent = () => {
            const current = moment($datetime.val(), inputFormat);
            const date = current.format(modelDateFormat);
            const time = current.format(modelTimeFormat);
            return { date, time, isValid: current.isValid() };
        };

        const applyChanges = () => {
            const { date, time, isValid } = getCurrent();

            // don't save if invalid
            if (!isValid) {
                return;
            }

            // update the model
            this.element.model.date = date;
            this.element.model.time = time;
            this.element.canvas.saveCanvasModel();
        };

        // handle changes
        $datetime.on("input", applyChanges);

        // add to the menu
        $menu.append($datetime);

        // return the menu
        return $menu;
    },

    renderControls: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Set Date & Time",
            icon: "calendar_month",
            menuContents: (...args) => this.createDateTimePickerMenu.apply(this, args)
        });
    }
});

export const editors = {
    CountdownSelection,
    CountdownOptionsMenu
};
