import * as geom from "js/core/utilities/geom";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { ContentElement } from "../base/ContentElement";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";
import { StreetMap } from "./infographics/StreetMap";

class ContactInfo extends BaseElement {
    getCanvasMargins() {
        if (this.canvas.layouter.showFooter) {
            return { left: 0, top: 0, right: 0, bottom: 10 };
        } else {
            return { left: 0, top: 0, right: 0, bottom: 0 };
        }
    }

    get layout() {
        return this.model.layout || "left_map";
    }

    _build() {
        switch (this.layout) {
            case "left_map":
            case "right_map":
                this.map = this.addElement("map", () => StreetMap);
                break;
            case "left_picture":
            case "top_picture":
            case "right_picture":
                this.picture = this.addElement("picture", () => ContentElement);
                break;
        }
        this.list = this.addElement("list", () => ContactInfoItemList);
    }

    //region migration
    _migrate_5() {
        if (this.canvas.model.oldTrayLayout) {
            if (this.canvas.model.oldTrayLayout.layout == "none" || !this.canvas.model.oldTrayLayout.tray) {
                this.model.layout = "none";
            } else {
                if (this.canvas.model.oldTrayLayout.tray && this.canvas.model.oldTrayLayout.tray.trayElement == "Street Map") {
                    switch (this.canvas.model.oldTrayLayout.layout) {
                        case "right_tray_half":
                            this.model.layout = "right_map";
                            break;
                        case "left_tray_half":
                        default:
                            this.model.layout = "left_map";
                            break;
                    }
                    this.model.assetId = this.canvas.model.oldTrayLayout.tray.assetId;
                    this.model.mapStyle = this.canvas.model.oldTrayLayout.tray.mapStyle;
                    this.model.markerColor = this.canvas.model.oldTrayLayout.tray.markerColor;
                } else if (this.canvas.model.oldTrayLayout.tray && this.canvas.model.oldTrayLayout.tray.trayElement == "Image") {
                    switch (this.canvas.model.oldTrayLayout.layout) {
                        case "right_tray_half":
                            this.model.layout = "right_picture";
                            break;
                        case "top_tray_half":
                            this.model.layout = "top_picture";
                            break;
                        case "left_tray_half":
                        default:
                            this.model.layout = "left_picture";
                            break;
                    }
                    this.model.content_value = this.canvas.model.oldTrayLayout.tray.content_value;
                    this.model.content_type = this.canvas.model.oldTrayLayout.tray.content_type;
                    this.model.aoiLeft = this.canvas.model.oldTrayLayout.tray.aoiLeft;
                    this.model.aoiRight = this.canvas.model.oldTrayLayout.tray.aoiRight;
                    this.model.aoiTop = this.canvas.model.oldTrayLayout.tray.aoiTop;
                    this.model.aoiBottom = this.canvas.model.oldTrayLayout.tray.aoiBottom;
                    this.model.scale = this.canvas.model.oldTrayLayout.tray.scale;
                }
            }

            delete this.canvas.model.oldTrayLayout;
        }
    }
    // endregion

    _calcProps(props, options) {
        let { size } = props;
        let listBounds;

        let mapProps, pictureProps;

        switch (this.layout) {
            case "none":
                listBounds = new geom.Rect(0, 0, size);
                break;
            case "left_map":
                mapProps = this.map.calcProps(new geom.Size(size.width / 2, size.height));
                mapProps.bounds = new geom.Rect(0, 0, size.width / 2, size.height);
                listBounds = new geom.Rect(size.width / 2, 0, size.width / 2, size.height);
                break;
            case "right_map":
                mapProps = this.map.calcProps(new geom.Size(size.width / 2, size.height));
                mapProps.bounds = new geom.Rect(size.width / 2, 0, size.width / 2, size.height);
                listBounds = new geom.Rect(0, 0, size.width / 2, size.height);
                break;
            case "left_picture":
                pictureProps = this.picture.calcProps(new geom.Size(size.width / 2, size.height));
                pictureProps.bounds = new geom.Rect(0, 0, size.width / 2, size.height);
                listBounds = new geom.Rect(size.width / 2, 0, size.width / 2, size.height);
                break;
            case "top_picture":
                pictureProps = this.picture.calcProps(new geom.Size(size.width, size.height / 2));
                pictureProps.bounds = new geom.Rect(0, 0, size.width, size.height / 2);
                listBounds = new geom.Rect(0, size.height / 2, size.width, size.height / 2);
                break;
            case "right_picture":
                pictureProps = this.picture.calcProps(new geom.Size(size.width / 2, size.height));
                pictureProps.bounds = new geom.Rect(size.width / 2, 0, size.width / 2, size.height);
                listBounds = new geom.Rect(0, 0, size.width / 2, size.height);
                break;
        }

        let listProps = this.list.calcProps(listBounds.size);
        listProps.bounds = listBounds;

        return { size };
    }
}

class ContactInfoItemList extends CollectionElement {
    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    get canSelect() {
        return false;
    }

    getChildItemType(model) {
        switch (model.type) {
            case "contact_header":
                return ContactItemHeader;
            case "address":
                return ContactItemAddress;
            case "text":
                return ContactItemText;
            default:
                return ContactItemIcon;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcColumnLayout();
        layouter.alignVertically();

        return { size };
    }
}

class ContactItem extends CollectionItemElement {
    get minHeight() {
        return 50;
    }

    get selectionPadding() {
        return 10;
    }
}

class ContactItemHeader extends ContactItem {
    get minHeight() {
        return this.text.minHeight;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            autoHeight: true,
            scaleTextToFit: true
        });
    }
}

class ContactItemAddress extends ContactItem {
    _build() {
        this.text = this.addElement("map_address", () => TextElement, {
            singleLine: true,
            autoHeight: true,
            selection: "ContactItemAddressTextSelection",
            model: this.parentElement.model // share the map_address from the ContactInfo model with the map
        });
    }
}

class ContactItemText extends ContactItem {
    _build() {
        this.text = this.addElement("text", () => TextElement, {
            autoHeight: true
        });
    }
}

class ContactItemIcon extends ContactItem {
    get minHeight() {
        return this.styles.icon.height;
    }
    _build() {
        let icon;
        let placeholderPrompt;
        switch (this.model.type) {
            case "phone":
                icon = "phone-call";
                placeholderPrompt = "Type phone";
                break;
            case "email":
                icon = "symbol-at";
                placeholderPrompt = "Type email";
                break;
            case "fax":
                icon = "printer";
                placeholderPrompt = "Type fax";
                break;
            case "website":
                icon = "internet";
                placeholderPrompt = "Type website";
                break;
            case "social":
                icon = this.model.social_type;
                placeholderPrompt = "Type " + icon;
                break;
        }

        if (this.model.content_value == undefined || this.model.content_type == undefined) {
            this.model.content_type = "icon";
            this.model.content_value = icon;
        }

        this.icon = this.addElement("icon", () => FramedMediaElement, {
            canDoubleClickToEdit: false,
            canSelect: false
        });

        this.text = this.addElement("text", () => TextElement, {
            placeholder: placeholderPrompt,
            singleLine: true,
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, [this.icon, this.text], size);
        layouter.distributeHorizontally({ verticalAlign: layouter.VerticalAlignType.MIDDLE });

        return { size: layouter.size };
    }
}

export const elements = {
    ContactInfo
};
