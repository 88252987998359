import React from "reactn";
import { Icon, IconButton, MenuItem, Slider } from "@material-ui/core";
import { IconMenu } from "../../../../components/UiComponents";

export function SortMenu({
    selectedOption,
    sortOptions,
    onSetSort,
    disabled,
}) {
    return (
        <IconMenu disabled={disabled} icon="sort" iconButtonColor="primary">
            {sortOptions.map(option => (
                <MenuItem
                    key={option.value}
                    onClick={() => onSetSort({ field: option.value, reverse: option.reverse })}
                    selected={option.value === selectedOption}
                >
                    {option.icon && <Icon>{option.icon}</Icon>}
                    {option.label}
                </MenuItem>
            ))}
        </IconMenu>
    );
}

