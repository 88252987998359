import { ds } from "js/core/models/dataService";
import { $, _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import { controls } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { ShowDialogAsync } from "legacy-js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "legacy-js/react/components/Dialogs/BadFitDialog";

import { ElementSelection } from "../BaseElementEditor";
import { CollectionItemElementSelection } from "../CollectionElementEditor";

const StackDiagramSelection = ElementSelection.extend({});

const StackDiagramItemSelection = CollectionItemElementSelection.extend({
    getTitle: function() {
        return false;
    },

    getOffset: function() {
        return 20;
    },

    getWidgetPosition: function() {
        return "inner";
    },

    showOptionsMenu: function() {
        return !this.element.isHidden;
    },

    canDrag: function() {
        return false;
    },

    canDelete: function() {
        return !this.element.isHidden;
    },

    cleanUp: function() {
        if (this.element.text && ds.selection.element != this.element.text) {
            this.element.text.options.canSelect = false;
            this.element.text.options.canEdit = false;
            this.element.text.options.canRollover = false;
        }
    },

    renderControls: function() {
        if (this.element.text) {
            this.element.text.options.canSelect = true;
            this.element.text.options.canEdit = true;
            this.element.text.options.canRollover = true;
        }

        if (!this.element.isHidden) {
            if (!app.currentCanvas.getBackgroundColor().isColor) {
                this.addControl({
                    type: controls.COLOR_PALETTE_PICKER,
                    property: "color",
                    includeAuto: true,
                    showBackgroundColors: true,
                    // includeBackgroundAccent: true
                });
            }
        }

        let $resizeContainer = this.$el.addEl($.div("resize_container"));
        $resizeContainer.append($.div("resize_box"));

        $resizeContainer.append($.div("resize_controls top"));
        $resizeContainer.append($.div("resize_controls bottom drop_shadow"));
        $resizeContainer.append($.div("resize_controls right"));
        $resizeContainer.append($.div("resize_controls left"));

        if (this.element.isHidden) {
            let $unhide = $resizeContainer.append(controls.createButton(this, {
                label: "Unhide",
                callback: () => {
                    this.element.model.color = null;
                    this.element.canvas.updateCanvasModel().then(() => {
                        ds.selection.element = null;
                        ds.selection.element = this.element;
                    });
                }
            }));
        }

        this.enableDragHandles(this.element, this.$el);
    },

    onDeleteItem() {
        Object.assign(this.element.model, {
            color: "transparent",
            text: null,
            items: null
        });
        this.element.canvas.updateCanvasModel().then(() => {
            ds.selection.element = null;
            ds.selection.element = this.element;
        });
    },

    enableDragHandles(item, $overlay) {
        $overlay.find(".resize_controls.right, .resize_controls.left").draggable({
            axis: "x",
            start: (event, ui) => {
                app.isDraggingItem = true;
                this.onStartResize(item, event, ui);
            },
            drag: (event, ui) => {
                this.onDragResize(item, event, ui);
            },
            stop: (event, ui) => {
                // _.defer(() => {
                // this is deferred so that the selection layer doesn't handle the click event that fires after the drag stop
                app.isDraggingItem = false;
                this.onEndResize(item, event, ui);
                // });
            }
        });
        $overlay.find(".resize_controls.top, .resize_controls.bottom").draggable({
            axis: "y",
            start: (event, ui) => {
                app.isDraggingItem = true;
                this.onStartResize(item, ui);
            },
            drag: (event, ui) => {
                this.onDragResize(item, event, ui);
            },
            stop: (event, ui) => {
                _.defer(() => {
                    // this is deferred so that the selection layer doesn't handle the click event that fires after the drag stop
                    app.isDraggingItem = false;
                    this.onEndResize(item, event, ui);
                });
            }
        });
    },

    onStartResize(item, event, ui) {
        // fade out widgets (use display=null so that they aren't set to display: none by velocity which will mess up positioning)
        $(".widget_bar").velocity("transition.fadeOut", { display: null });

        item.resizeStart = _.clone(item.model);
        this.isResizing = true;
    },

    onDragResize(item, event, ui) {
        $(".drag_cell_target_hilite, .drag_cell_target_prevent").remove();

        this.hGap = this.element.parentElement.hGap;
        this.vGap = this.element.parentElement.vGap;
        this.cols = this.element.parentElement.cols;
        this.rows = this.element.parentElement.rows;
        this.colWidth = this.element.parentElement.colWidth;
        this.rowHeight = this.element.parentElement.rowHeight;

        let direction;
        if ($(event.target).hasClass("right")) {
            direction = "right";
        } else if ($(event.target).hasClass("left")) {
            direction = "left";
        } else if ($(event.target).hasClass("bottom")) {
            direction = "bottom";
        } else {
            direction = "top";
        }

        let currentBounds = item.getGridBounds();
        let dragX = ui.position.left / app.currentCanvas.getScale();
        let dragY = ui.position.top / app.currentCanvas.getScale();

        let col = Math.clamp(Math.ceil((dragX + this.hGap - this.colWidth / 2) / (this.colWidth + this.hGap)), -item.resizeStart.x, this.cols);
        let row = Math.clamp(Math.ceil((dragY + this.vGap - this.rowHeight / 2) / (this.rowHeight + this.vGap)), -item.resizeStart.y, this.rows);

        let { x, y, width, height } = item.model;

        switch (direction) {
            case "left":
                if (item.resizeStart.width - col >= 1) {
                    x = item.resizeStart.x + col;
                    width = item.resizeStart.width - col;
                }
                break;
            case "right":
                width += col - width;
                if (width === 0) {
                    width = 1;
                }
                break;
            case "top":
                if (item.resizeStart.height - row >= 1) {
                    y = item.resizeStart.y + row;
                    height = item.resizeStart.height - row;
                }
                break;
            case "bottom":
                height += row - height;
                if (height === 0) {
                    height = 1;
                }
                break;
        }

        // calculate offsets because resize box is relative to item
        let scale = app.currentCanvas.getScale();
        let boxRect = new geom.Rect(((x - item.resizeStart.x) * (this.colWidth + this.hGap)) * scale, ((y - item.resizeStart.y) * (this.rowHeight + this.vGap)) * scale, Math.round(width * (this.colWidth + this.hGap) - this.hGap) * scale, Math.round(height * (this.rowHeight + this.vGap) - this.vGap) * scale);

        // if (this.element.parentElement.model.showGutter) {
        //     boxRect = boxRect.offset(-this.hGap, -this.vGap);
        // }

        // animate the resize box if it's changed
        let $box = this.$el.find(".resize_box");
        $box.setBounds(boxRect);
        $box.css("background", "rgba(35, 170, 224, 0.3)");

        this.dragBounds = new geom.Rect(x, y, width, height);

        if (!this.dragBounds.equals(currentBounds)) {
            if (this.dragBounds.width === 0) {
                this.dragBounds.width = 1;
            }
            if (this.dragBounds.height === 0) {
                this.dragBounds.height = 1;
            }

            if (!this.fitsNewSize(item, this.dragBounds)) {
                let pixelBounds = this.gridBoundsToPixelBounds(item.getGridBounds());
                item.svg.rect().addClass("drag_cell_target_prevent").attr({
                    width: pixelBounds.width,
                    height: pixelBounds.height
                });
            }

            let gridBounds;
            let edgeItems = _.filter(this.element.parentElement.itemElements, compareItem => {
                if (item !== compareItem) {
                    gridBounds = compareItem.getGridBounds();
                    switch (direction) {
                        case "right":
                            return currentBounds.right === gridBounds.left && gridBounds.top >= this.dragBounds.top && gridBounds.bottom <= this.dragBounds.bottom;
                        case "left":
                            return currentBounds.left === gridBounds.right && gridBounds.top >= this.dragBounds.top && gridBounds.bottom <= this.dragBounds.bottom;
                        case "top":
                            return currentBounds.top === gridBounds.bottom && gridBounds.left >= this.dragBounds.left && gridBounds.right <= this.dragBounds.right;
                        case "bottom":
                            return currentBounds.bottom === gridBounds.top && gridBounds.left >= this.dragBounds.left && gridBounds.right <= this.dragBounds.right;
                    }
                }
            });

            edgeItems.forEach(edgeItem => {
                gridBounds = edgeItem.getGridBounds();

                switch (direction) {
                    case "right":
                        gridBounds = gridBounds.inflate({ left: currentBounds.right - this.dragBounds.right }).toXYObject();
                        break;
                    case "left":
                        gridBounds = gridBounds.inflate({ right: this.dragBounds.left - currentBounds.left }).toXYObject();
                        break;
                    case "top":
                        gridBounds = gridBounds.inflate({ bottom: this.dragBounds.top - currentBounds.top }).toXYObject();
                        break;
                    case "bottom":
                        gridBounds = gridBounds.inflate({ top: currentBounds.bottom - this.dragBounds.bottom }).toXYObject();
                        break;
                }
                if (gridBounds.width === 0 || gridBounds.height === 0) {
                    // do nothing
                } else if (!this.fitsNewSize(edgeItem, gridBounds)) {
                    let pixelBounds = this.gridBoundsToPixelBounds(edgeItem.getGridBounds());
                    edgeItem.svg.rect().addClass("drag_cell_target_prevent").attr({
                        width: pixelBounds.width,
                        height: pixelBounds.height
                    });
                }
            });
        }
    },

    onEndResize(item, event, ui) {
        let direction;
        if ($(event.target).hasClass("right")) {
            direction = "right";
        } else if ($(event.target).hasClass("left")) {
            direction = "left";
        } else if ($(event.target).hasClass("bottom")) {
            direction = "bottom";
        } else {
            direction = "top";
        }

        let currentBounds = item.getGridBounds();

        if (!this.dragBounds.equals(currentBounds)) {
            if (this.dragBounds.width === 0) {
                this.dragBounds.width = 1;
            }
            if (this.dragBounds.height === 0) {
                this.dragBounds.height = 1;
            }

            let gridBounds;
            let edgeItems = _.filter(this.element.parentElement.itemElements, compareItem => {
                if (item !== compareItem) {
                    gridBounds = compareItem.getGridBounds();
                    switch (direction) {
                        case "right":
                            return currentBounds.right === gridBounds.left && gridBounds.top >= this.dragBounds.top && gridBounds.bottom <= this.dragBounds.bottom;
                        case "left":
                            return currentBounds.left === gridBounds.right && gridBounds.top >= this.dragBounds.top && gridBounds.bottom <= this.dragBounds.bottom;
                        case "top":
                            return currentBounds.top === gridBounds.bottom && gridBounds.left >= this.dragBounds.left && gridBounds.right <= this.dragBounds.right;
                        case "bottom":
                            return currentBounds.bottom === gridBounds.top && gridBounds.left >= this.dragBounds.left && gridBounds.right <= this.dragBounds.right;
                    }
                }
            });

            // find all items that intersect the newly resized item
            let intersectingItems = _.filter(this.element.parentElement.itemElements, checkItem => {
                return this.dragBounds.intersects(checkItem.getGridBounds(), true) && checkItem != item;
            });

            Object.assign(item.model, {
                x: this.dragBounds.left,
                y: this.dragBounds.top,
                width: this.dragBounds.width,
                height: this.dragBounds.height
            });
            edgeItems.forEach(edgeItem => {
                gridBounds = edgeItem.getGridBounds();

                switch (direction) {
                    case "right":
                        Object.assign(edgeItem.model, gridBounds.inflate({ left: currentBounds.right - this.dragBounds.right }).toXYObject());
                        break;
                    case "left":
                        Object.assign(edgeItem.model, gridBounds.inflate({ right: this.dragBounds.left - currentBounds.left }).toXYObject());
                        break;
                    case "top":
                        Object.assign(edgeItem.model, gridBounds.inflate({ bottom: this.dragBounds.top - currentBounds.top }).toXYObject());
                        break;
                    case "bottom":
                        Object.assign(edgeItem.model, gridBounds.inflate({ top: currentBounds.bottom - this.dragBounds.bottom }).toXYObject());
                        break;
                }
                if (edgeItem.model.width <= 0 || edgeItem.model.height <= 0) {
                    this.element.parentElement.deleteItem(edgeItem.id);
                }
            });
            intersectingItems.forEach(intersectingItem => {
                gridBounds = intersectingItem.getGridBounds();
                // if the resize bounds completely overlap the item, delete it
                if (this.dragBounds.overlaps(gridBounds)) {
                    this.element.parentElement.deleteItem(intersectingItem.id);
                } else {
                    //find the biggest area available in the overlapped item and set it
                    let bounds = this.element.parentElement.splitItem(intersectingItem, this.dragBounds);

                    Object.assign(intersectingItem.model, {
                        x: bounds.left,
                        y: bounds.top,
                        width: bounds.width,
                        height: bounds.height
                    });
                }
            });
        }
        $(".drag_cell_target_hilite, .drag_cell_target_prevent").remove();
        item.resizeStart = null;
        this.isResizing = false;
        this.element.parentElement.fillEmptySlots();
        this.element.canvas.updateCanvasModel().then(() => {
            ds.selection.element = null;
            ds.selection.element = this.element;
        }).catch(err => {
            ShowDialogAsync(BadFitDialog, {
                title: "Sorry, one or more of the stack diagram boxes won't fit with this layout",
            });
        });
    },

    fitsNewSize(item, gridBounds) {
        return true;
        //commented below code out due to lint error
        // let bounds = this.gridBoundsToPixelBounds(gridBounds);
        // item.calcSize(bounds);
        // return item.isFit;
    },

    gridBoundsToPixelBounds(gridBounds) {
        return new geom.Size(gridBounds.width * (this.colWidth + this.hGap) - this.hGap, gridBounds.height * (this.rowHeight + this.vGap) - this.vGap);
    }

});

export const editors = {
    StackDiagramSelection,
    StackDiagramItemSelection,
};
