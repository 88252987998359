import React, { Component, Fragment } from "reactn";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import { DialogContent, BeautifulDialog } from "./BaseDialog";
import { rand } from "js/core/utilities/utilities";

export default class RefreshTimerDialog extends Component {
    state = {
        time: rand(5, 45)
    };

    componentDidMount = () => {
        setInterval(() => {
            this.setState(prevState => {
                return { time: prevState.time - 1 };
            }, () => {
                if (this.state.time <= 0) {
                    this.handleClose();
                }
            });
        }, 1000);
    }

    handleClose() {
        window.location.reload();
        this.props.closeDialog();
    }

    showMessage() {
        let { time } = this.state;
        return (<Fragment>This browser tab is running an old version of the application and must
            be refreshed. Your work will be saved.
            Refreshing in <span id="auto-refresh-timer">{time}</span> seconds.</Fragment>);
    }

    render() {
        return (
            <BeautifulDialog closeDialog={this.handleClose}>
                <DialogTitle>Refresh Required</DialogTitle>
                <DialogContent>{this.showMessage()}</DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>
                        Refresh Now
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
