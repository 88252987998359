import { HorizontalAlignType, ListStyleType, VerticalAlignType } from "legacy-common/constants";
import * as geom from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";
import { ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { getSizingValue } from "js/core/utilities/utilities";

import { TextGroup } from "../../base/TextGroup";
import { SVGCircleElement } from "../../base/SVGElement";
import { TextElement } from "../../base/TextElement";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { Icon } from "../../base/MediaElements/IconElement";
import { FindBestFit } from "../../layouts/FindBestFit";
import { layoutHelper } from "../../layouts/LayoutHelper";

export class TextList extends CollectionElement {
    static get schema() {
        return {
            autoArrange: 2,
            verticalAlign: VerticalAlignType.MIDDLE,
            showColumnHeaders: false,
            columns: 1,
            startNum: 1
        };
    }

    get forceSingleColumn() {
        return this.options.forceSingleColumn || this.model.forceSingleColumn;
    }

    get listStyle() {
        return this.options.listStyle || this.model.listStyle;
    }

    getChildItemType() {
        return TextListItem;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" }
        };
    }

    get showDefaultOverlay() {
        return false;
    }

    get autoArrange() {
        if (this.showColumnHeaders) {
            return false;
        } else {
            return this.model.autoArrange;
        }
    }

    get maxColumns() {
        return this.styles.maxCols ?? 3;
    }

    get columns() {
        let maxCol = (_.max(this.itemCollection.map(model => model.col)) ?? 0) + 1;
        return Math.min(maxCol, this.maxColumns);
    }

    get showColumnHeaders() {
        return this.model.showColumnHeaders;
    }

    getItemsInColumns() {
        let itemsInColumns = [];

        for (let col = 0; col < this.columns; col++) {
            itemsInColumns.push([]);
        }

        for (let item of this.itemElements) {
            if (item.model.col >= itemsInColumns.length) {
                itemsInColumns[itemsInColumns.length - 1].push(item);
            } else {
                itemsInColumns[item.model.col ?? 0].push(item);
            }
        }

        return itemsInColumns;
    }

    getModelsInColumns() {
        let modelsInColumns = [];
        for (let col = 0; col < this.columns; col++) {
            modelsInColumns.push([]);
        }

        for (let item of this.itemCollection) {
            if (item.col >= modelsInColumns.length) {
                modelsInColumns[modelsInColumns.length - 1].push(item);
            } else {
                modelsInColumns[item.col ?? 0].push(item);
            }
        }

        return modelsInColumns;
    }

    // addItem(props, index) {
    //     let beforeItem = this.itemElements[index - 1];
    //     if (beforeItem) {
    //         props.col = beforeItem.model.col;
    //     }
    //
    //     return super.addItem(props, index);
    // }

    _build() {
        this.buildItems();

        if (this.showColumnHeaders) {
            this.columnHeaders = [];
            for (let col = 0; col < this.columns; col++) {
                this.columnHeaders.push(this.addElement("columnHeader" + col, () => TextListColumnHeader, { columnIndex: col }));
            }
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        if (size.width < 800) {
            this.updateStyles(this.styles.reducedWidth);
        } else {
            this.updateStyles(this.styles.fullWidth);
        }
        size = size.deflate({ left: this.styles.columnOuterPadding, right: this.styles.columnOuterPadding });

        if (this.autoArrange) {
            this.makeBeautiful(props, this.autoArrange);
        }

        let hGap = this.styles.hGap ?? 10;

        // calculate the column width
        let columnWidth = (size.width - hGap * (this.columns - 1)) / this.columns;
        columnWidth = Math.min(columnWidth, getSizingValue(this.styles.maxColWidth, size.width));

        let columns = this.columns;
        let itemsInColumns = this.getItemsInColumns();

        // calculate the offsetX for all the columns
        let offsetX = layoutHelper.getHorizontalOffsetForItems(columnWidth, hGap, columns, size.width, HorizontalAlignType.CENTER);
        offsetX += this.styles.columnOuterPadding;

        // calculate the properties for each column for use in drag/drop calculations
        props.columns = [];
        for (let col = 0; col < columns; col++) {
            props.columns.push({
                col: col,
                items: itemsInColumns[col].map(item => item.id),
                bounds: new geom.Rect(offsetX + col * (columnWidth + hGap), 0, columnWidth, size.height)
            });
        }

        let columnHeaderHeight = 0;
        if (this.showColumnHeaders) {
            for (let col = 0; col < columns; col++) {
                let colHeaderProps = this.columnHeaders[col].calcProps(new geom.Size(columnWidth, size.height));
                colHeaderProps.bounds = new geom.Rect(offsetX + col * (columnWidth + hGap), 0, colHeaderProps.size);
                columnHeaderHeight = Math.max(columnHeaderHeight, colHeaderProps.size.height);
            }
            for (let col = 0; col < columns; col++) {
                let colHeaderProps = this.columnHeaders[col].calculatedProps;
                colHeaderProps.bounds.top = columnHeaderHeight - colHeaderProps.bounds.height;
            }
        }

        let { itemsProps, columnHeights, isFit } = FindBestFit({
            min: 0.1,
            max: 1,
            preCheckMax: true, // early out if scale = 1 fits
            doFinalLayout: false, // do a final relayout at the fit size so all the tree props are correct
            layout: scale => {
                let itemsProps = [];
                let columnHeights = [];

                let vGap = (this.styles.vGap ?? 10) * scale;
                for (let col = 0; col < columns; col++) {
                    let y = 0;
                    y += columnHeaderHeight;

                    for (let item of itemsInColumns[col]) {
                        let itemProps = item.calcProps(new geom.Size(columnWidth, size.height), {
                            scale,
                            itemInColumnIndex: itemsInColumns[col].indexOf(item)
                        });
                        itemProps.bounds = new geom.Rect(offsetX + col * (columnWidth + this.styles.hGap), y, itemProps.size);
                        y += itemProps.bounds.height + vGap;

                        itemsProps.push(item.getTreeProps());
                    }
                    columnHeights.push(y - vGap);
                }

                return {
                    isFit: _.max(columnHeights) < size.height,
                    columnHeights,
                    itemsProps
                };
            }
        });

        // restore the calculatedProps of the tree at the fit scale
        for (let item of this.itemElements) {
            item.assignPropsToTree(itemsProps.findById(item.id));
        }

        let containerHeight;
        if (options.autoHeight) {
            containerHeight = layoutHelper.getTotalBoundsOfItems(this.itemElements).size.height;
        } else {
            containerHeight = size.height;
        }

        // vertical align
        let verticalAlignOffsetY = layoutHelper.getVerticalAlignOffset(_.max(columnHeights), containerHeight, this.model.verticalAlign);
        if (this.model.verticalAlign == VerticalAlignType.MIDDLE) {
            // verticalAlignOffsetY -= verticalAlignOffsetY * .25;
        }

        for (let col = 0; col < this.columns; col++) {
            if (this.showColumnHeaders) {
                this.columnHeaders[col].calculatedProps.bounds.top += verticalAlignOffsetY;
            }
            for (let item of itemsInColumns[col]) {
                item.calculatedProps.bounds.top += verticalAlignOffsetY;
            }
        }

        return { size: new geom.Size(size.width, containerHeight), isFit };
    }

    makeBeautiful(props = this.calculatedProps, autoArrange) {
        if (this.showColumnHeaders) return;

        let minCols, maxCols;
        switch (autoArrange) {
            case 1:
                minCols = 1;
                maxCols = 1;
                break;
            case 3:
                minCols = 3;
                maxCols = 3;
                break;
            case 2:
            default:
                minCols = 2;
                maxCols = 2;
                break;
        }

        let layouter = FindBestFit({
            min: 0.2,
            max: 1,
            preCheckMax: true,
            layout: scale => {
                let layouter = this.getLayouter(props, this.itemElements, props.allowedSize);

                layouter.calcColumnLayout({
                    forceSingleColumn: false,
                    distributeRows: "even",
                    vGap: 50,
                    hGridAlign: "center",
                    vGridAlign: "middle",
                    maxColWidth: "75%",
                    minColWidth: 300,
                    minCols,
                    maxCols,
                    maxRows: 5,
                    itemOptions: {
                        scale
                    }
                });
                return layouter;
            }
        });

        if (layouter.isTextFit) {
            for (let col = 0; col < layouter.itemsInColumns.length; col++) {
                for (let layoutItem of layouter.itemsInColumns[col]) {
                    this.itemElements.find(item => item.id == layoutItem.id).model.col = col;
                }
            }
        } else {
            ShowWarningDialog({ title: "Sorry, can't make this beautiful" });
        }
    }

    getAnimations() {
        if (!this.showColumnHeaders) {
            return super.getAnimations();
        }

        const animations = this._getAnimations();
        animations.forEach(animation => animation.element = this);

        if (this.animateChildren) {
            const itemsInColumns = this.getItemsInColumns();
            [...new Array(this.columns).keys()].forEach(columnIndex => {
                animations.push(...this.columnHeaders[columnIndex].getAnimations());

                itemsInColumns[columnIndex]
                    .sort((elementA, elementB) => elementA.itemIndex - elementB.itemIndex)
                    .forEach(element => {
                        animations.push(...element.getAnimations());
                    });
            });
        }

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        return animations;
    }
}

export class TextListItem extends CollectionItemElement {
    get minHeight() {
        return this.text.minHeight;
    }

    get passThroughSelection() {
        return false;
    }

    get requireParentSelection() {
        return false;
    }

    get listStyle() {
        return this.parentElement.model.listStyle || ListStyleType.BULLET;
    }

    get listIndex() {
        let textList = this.getRootElement();
        if (textList.showColumnHeaders) {
            for (let col of textList.getModelsInColumns()) {
                if (col.contains(this.model)) {
                    return col.indexOf(this.model);
                }
            }
        } else {
            return this.itemIndex;
        }
    }

    get animationElementName() {
        return `Item #${this.listIndex + 1}`;
    }

    _build() {
        switch (this.listStyle) {
            case ListStyleType.BULLET:
                this.content = this.addElement("bullet", () => SVGCircleElement);
                break;
            case ListStyleType.NUMBERED:
                this.content = this.addElement("index", () => TextElement, {
                    model: {
                        index: this.listIndex + (this.parentElement.model.startNum || 1)
                    },
                    canEdit: false,
                    isTabbable: false
                });
                break;
            case ListStyleType.CHECKBOX:
                this.content = this.addElement("checkbox", () => TextListCheckBox, { fitAsset: true });
                break;
            case ListStyleType.ICON:
                this.content = this.addElement("icon", () => TextListIcon, { fitAsset: true });
                break;
        }

        this.text = this.addElement("text", () => TextGroup, {
            autoHeight: true,
            showAddButton: false,
            title: {
                singleLine: true
            },
            body: {
                allowParagraphStyles: true,
                allowEmptyLines: false,
                placeholder: "Type text or hit enter to add another bullet"
            }
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.listStyle === ListStyleType.TEXT || this.listStyle == "none") {
            // just text
            let textProps = this.text.calcProps(size, { forceTextScale: options.scale ?? 1 });
            props.isFit = true;
            return { size: textProps.size };
        } else {
            // text and a content element
            if (this.listStyle === ListStyleType.CHECKBOX && this.model.checkState === "unchecked") {
                props.opacity = 0.5;
            }

            this.content.scaleStyleValues(options.scale ?? 1);

            let contentProps = this.content.calcProps(size);

            if (options.bulletAlign === "right") {
                this.text.styles.textAlign = HorizontalAlignType.RIGHT;
                this.text.styles.marginRight = this.content.styles.gap + contentProps.size.width;
                this.text.styles.marginLeft = 0;
                contentProps.bounds = new geom.Rect(size.width - contentProps.size.width, 0, contentProps.size);
            } else {
                this.text.styles.marginLeft = this.content.styles.gap + contentProps.size.width;
                this.text.styles.marginRight = 0;
                contentProps.bounds = new geom.Rect(0, 0, contentProps.size);
            }

            let textProps = this.text.calcProps(size, {
                forceTextScale: options.scale ?? 1,
            });
            textProps.bounds = new geom.Rect(0, 0, textProps.size);

            contentProps.bounds.top = this.text.title.calculatedProps.textLayout.lines[0].fontHeight / 2 - contentProps.size.height / 2;

            return {
                size: textProps.bounds.size
            };
        }
    }
}

export class TextListCheckBox extends Icon {
    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return true;
    }

    get iconId() {
        switch (this.model.checkState) {
            case "unchecked":
                return "check-yes";
            case "crossed-out":
                return "x";
            case "checked":
            default:
                return "check-yes";
        }
    }

    _loadStyles(styles) {
        switch (this.model.checkState) {
            case "unchecked":
                styles.fillColor = "primary";
                break;
            case "crossed-out":
                styles.fillColor = "negative";
                break;
            case "checked":
            default:
                styles.fillColor = "positive";
                break;
        }
    }
}

export class TextListIcon extends Icon {
    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return true;
    }

    get iconId() {
        return this.model.icon || "star";
    }
}

class TextListColumnHeader extends TextElement {
    get autoHeight() {
        return true;
    }

    get placeholderPrompt() {
        return { text: "Type column header", style: [] };
    }

    get animationElementName() {
        return `Column Header #${this.options.columnIndex + 1}`;
    }
}

export const elements = {
    TextList
};
