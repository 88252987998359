import * as geom from "js/core/utilities/geom";
import { VerticalAlignType, HorizontalAlignType, HeaderPositionType, ConnectorType } from "legacy-common/constants";
import { calcCircleLayout } from "./CircleLayout";
import { calcArcConnectorLayout, calcStraightConnectorLayout } from "./ConnectorLayout";
import { getSizingValue } from "js/core/utilities/utilities";
import { _ } from "legacy-js/vendor";
import { ElementStyles } from "js/core/styleSheet";

import { layoutHelper } from "./LayoutHelper";
import { calcRowLayout } from "./RowLayout";
import { calcHorizontalBlockLayout, calcVerticalBlockLayout } from "./BlockLayouts";
import { calcGridLayout } from "./GridLayout";
import { calcBoxGridLayout } from "./BoxGridLayout";
import { calcColumnLayout } from "./ColumnLayout"; // prototype
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ELEMENTS);

export class LayoutObj {
    [Symbol.iterator]() {
        let index = -1;
        let data = Object.values(this);

        return {
            next: () => ({ value: data[++index], done: !(index in data) })
        };
    }

    setProps(element, p1, p2) {
        let props;
        if (p1 instanceof geom.Size) {
            props = element.calcProps(p1, p2);
        } else if (p1 instanceof geom.Rect) {
            logger.warn("[LayoutObj] rect passed as Size to setProps");
            props = element.calcProps(p1.size, p2);
        } else {
            props = p1;
        }

        if (!props) {
            props = {};
        }

        if (!props.styles) {
            props.styles = new ElementStyles(element.styles);
        }

        props.id = element.id;

        let elementProps;
        if (this[element.id]) {
            elementProps = this[element.id];
        } else {
            elementProps = {};
        }

        // set default bounds to full rect
        if (!props.bounds && props.size && !elementProps.bounds) {
            props.bounds = new geom.Rect(0, 0, props.size);
        }

        props = Object.assign(elementProps, props);
        this[element.id] = props;

        // set the default layer
        if (props.layer == undefined) {
            props.layer = Object.values(this).length - 1;
        }

        // store a reference to the calculatedProps on the element so we can access when walking element tree after
        // calc
        element.calculatedProps = props;

        return props;
    }

    //     get maxWidthOfItems() {
    //         return _.maxBy(Object.values(this), layout => layout.size.width).size.width;
    //     }
    //
    //     get maxHeightOfItems() {
    //         return _.maxBy(Object.values(this), layout => layout.size.height).size.height;
    //     }
    //
    //     // offsetBounds(x, y) {
    //     //     for (let props of this) {
    //     //         props.bounds = props.bounds.offset(x, y);
    //     //     }
    //     // }
    //
    //     get totalBounds() {
    //         let bounds;
    //         for (let props of this) {
    //             if (bounds == undefined) {
    //                 bounds = props.bounds;
    //             } else {
    //                 bounds = bounds.union(props.bounds);
    //             }
    //         }
    //         return bounds;
    //     }
}

export class ElementLayouter {
    get HorizontalAlignType() {
        return {
            LEFT: "left",
            CENTER: "center",
            RIGHT: "right"
        };
    }

    get VerticalAlignType() {
        return {
            TOP: "top",
            MIDDLE: "middle",
            BOTTOM: "bottom"
        };
    }

    get VerticalBlockAlignType() {
        return {
            TOP: "top",
            MIDDLE: "middle",
            BOTTOM: "bottom",
            MIDDLE_OR_TOP: "middle-or-top",
            MIDDLE_TITLE: "center-title"
        };
    }

    constructor(element, props, items, containerSize) {
        this.element = element;

        this.props = props;
        this.items = _.compact(items); // compact to remove any null items (convenient for optional elements)
        this.containerSize = containerSize;

        this.isFit = true;
    }

    // get props() {
    //     return this.element.calculatedProps;
    // }

    get styles() {
        return this.element.styles;
    }

    get isFit() {
        return this.props.isFit;
    }

    set isFit(value) {
        this.props.isFit = value;
    }

    // get children() {
    //     return this.props.children;
    // }

    calcHorizontalLayout(options = {}) {
        let styles = _.defaults({}, this.styles, {
            maxColumnWidth: "100%",
            minColumnWidth: "10%",
            hGap: 0
        });

        let maxColumnWidth = getSizingValue(styles.maxColumnWidth, this.containerSize.width) || 200;
        let minColumnWidth = getSizingValue(styles.minColumnWidth, this.containerSize.width) || 100;

        let columnWidth = Math.clamp((this.containerSize.width - styles.hGap * (this.items.length - 1)) / this.items.length, minColumnWidth, maxColumnWidth);

        let itemProps = [];
        let x = 0;
        let totalSize = new geom.Size(-styles.hGap, this.containerSize.height);

        if (options.verticallyAlignText) {
            let maxTextHeight = 0;
            for (let item of this.items) {
                // this is tricky because we are trying to calculate props on the child of an item that itself has not had it's props calc'd
                // to do this we need to pass the correct styles down to the item.text and then apply padding and margin styles
                let textProps = item.text.calcProps(new geom.Size(columnWidth, this.containerSize.height), { styles: styles[item.type][item.text.id] });
                maxTextHeight = Math.max(maxTextHeight, textProps.size.inflate(item.styles.padding || 0).inflate(item.styles.margin || 0).height);
            }
            maxTextHeight = Math.ceil(maxTextHeight);
            options.itemOptions = Object.assign({ textHeight: maxTextHeight }, options.itemOptions);
        }

        for (let item of this.items) {
            let itemProps = item.calcProps(new geom.Size(columnWidth, this.containerSize.height), options.itemOptions);
            itemProps.bounds = new geom.Rect(x, 0, new geom.Size(columnWidth, itemProps.size.height));

            x += columnWidth + styles.hGap;
            totalSize.width += columnWidth + styles.hGap;
        }

        // let tallestItemHeight = _.maxBy(this.items, item => item.calculatedSize.height).calculatedSize.height;
        let tallestItemHeight = layoutHelper.getMaxHeightOfItems(this.items);
        totalSize.height = tallestItemHeight;

        switch (options.verticalItemAlign || VerticalAlignType.TOP) {
            case VerticalAlignType.TOP:
                break;
            case VerticalAlignType.MIDDLE:
                for (let itemProps of this.items.map(item => item.calculatedProps)) {
                    itemProps.bounds.top = tallestItemHeight / 2 - itemProps.size.height / 2;
                }
                break;
            case VerticalAlignType.BOTTOM:
                for (let itemProps of this.items.map(item => item.calculatedProps)) {
                    itemProps.bounds.top = tallestItemHeight - itemProps.size.height;
                }
                break;
        }

        this.isFit = totalSize.width <= this.containerSize.width && totalSize.height <= this.containerSize.height;
        this.isFit = this.isFit && this.items.length <= this.element.maxItemCount;

        this.size = totalSize;

        return this;
    }

    calcVerticalLayout(options = {}) {
        let styles = _.defaults({}, this.styles, {
            vGap: 0,
            maxRowHeight: 100,
            minRowHeight: 50,
        });

        let maxRowHeight = getSizingValue(styles.maxRowHeight, this.containerSize.height) || 200;
        let minRowHeight = getSizingValue(styles.minRowHeight, this.containerSize.height) || 100;

        let rowHeight = Math.clamp((this.containerSize.height - styles.vGap * (this.items.length - 1)) / this.items.length, minRowHeight, maxRowHeight);

        let y = 0;
        let totalSize = new geom.Size(this.containerSize.width, -styles.vGap);
        for (let item of this.items) {
            let itemProps = item.calcProps(new geom.Size(this.containerSize.width, rowHeight), options.itemOptions);
            itemProps.bounds = new geom.Rect(0, y, new geom.Size(this.containerSize.width, rowHeight));

            y += rowHeight + styles.vGap;
            totalSize.height += rowHeight + styles.vGap;
        }

        this.isFit = totalSize.width <= this.containerSize.width && totalSize.height <= this.containerSize.height;
        this.isFit = this.isFit && this.items.length <= this.element.maxItemCount;

        this.size = totalSize;

        return this;
    }

    alignHorizontally(align) {
        if (this.props.isFit === false) {
            return this;
        }
        // if (!this.size) {
        //     this.size = Object.values(this.children)[0].size;
        // }

        let offsetX;
        switch (align || this.styles.hGridAlign || this.styles.horizontalAlign || "center") {
            case this.HorizontalAlignType.LEFT:
                offsetX = 0;
                break;
            case this.HorizontalAlignType.CENTER:
                offsetX = this.containerSize.width / 2 - this.size.width / 2;
                break;
            case this.HorizontalAlignType.RIGHT:
                offsetX = this.containerSize.width - this.size.width;
                break;
        }

        for (let itemProps of this.items.map(item => item.calculatedProps)) {
            itemProps.bounds = itemProps.bounds.offset(offsetX, 0);
        }
        return this;
    }

    alignVertically(align, adjustOptical = true) {
        if (this.props.isFit === false) {
            return this;
        }
        if (!this.size) {
            this.size = this.items[0].calculatedProps.size;
        }

        let offsetY;
        switch (align || "middle") {
            case this.VerticalBlockAlignType.TOP:
                offsetY = 0;
                break;
            case this.VerticalBlockAlignType.MIDDLE:
                offsetY = this.containerSize.height / 2 - this.size.height / 2;

                // adjust for optical white space
                if (adjustOptical && this.element.canvas.model.layout.headerPosition === HeaderPositionType.TOP) {
                    offsetY -= Math.max(this.containerSize.height * 0.75 - this.size.height, 0) * 0.1;
                }
                break;
            case this.VerticalBlockAlignType.BOTTOM:
                offsetY = this.containerSize.height - this.size.height;
                break;
        }

        for (let itemProps of this.items.map(item => item.calculatedProps)) {
            itemProps.bounds = itemProps.bounds.offset(0, offsetY);
        }
        return this;
    }

    distributeHorizontally(options) {
        if (!this.items.length) {
            this.size = this.containerSize;
            return this;
        }

        options = _.defaults(options, {
            verticalAlign: VerticalAlignType.TOP,
            gap: 0,
            reverse: false,
            useCalculatedSizes: false,
            itemOptions: {}
        });

        this.isFit = true;

        let totalWidth = -options.gap;
        let availableSize = this.containerSize.clone();

        if (options.reserveMinWidths) {
            let widths = this.items.reduce((acc, item) => acc + item.minWidth + options.gap, -options.gap);
            availableSize = availableSize.deflate({ right: widths });
        }

        if (availableSize.width < 0) {
            this.isFit = false;
        }

        for (let item of this.items) {
            if (options.reserveMinWidths) {
                availableSize = availableSize.inflate({ right: item.minWidth });
            }

            let itemProps;
            if (options.useCalculatedSizes && item.calculatedProps.size) {
                itemProps = item.calculatedProps;
            } else {
                itemProps = item.calcProps(availableSize, options.itemOptions);
            }
            availableSize = availableSize.deflate({ right: itemProps.size.width });
            totalWidth += itemProps.size.width + options.gap;
        }
        if (totalWidth > this.containerSize.width + 1) {
            this.isFit = false;
        }

        let maxHeight = layoutHelper.getMaxHeightOfItems(this.items);
        let x = 0;

        let items = options.reverse ? _.reverse(this.items) : this.items;
        for (let item of items) {
            let itemProps = item.calculatedProps;
            itemProps.bounds = new geom.Rect(x, layoutHelper.getVerticalAlignOffset(itemProps.size.height, maxHeight, options.verticalAlign), itemProps.size);
            x += itemProps.size.width + options.gap;
        }

        this.size = new geom.Size(x - options.gap, maxHeight);
        return this;
    }

    distributeVertically(options) {
        if (!this.items.length) {
            this.size = this.containerSize;
            return this;
        }

        options = _.defaults(options, {
            horizontalAlign: HorizontalAlignType.CENTER,
            gap: 0,
            reverse: false,
            useCalculatedSizes: false,
            itemOptions: {}
        });

        this.isFit = true;

        let totalHeight = -options.gap;
        let availableSize = this.containerSize.clone();

        if (options.reserveMinHeights) {
            let heights = this.items.reduce((acc, item) => acc + item.minHeight * (options.itemOptions.forceTextScale || 1) + options.gap, -options.gap);
            availableSize = availableSize.deflate({ bottom: heights });
        }
        for (let item of this.items) {
            if (options.reserveMinHeights) {
                availableSize = availableSize.inflate({ bottom: item.minHeight * (options.itemOptions.forceTextScale || 1) });
            }
            // let itemLayout = (options.useCalculatedSizes && item.calculatedSize) ? item.calculatedLayout : item.calcLayout(this.layout, availableSize, options.itemOptions);
            let itemProps;
            if (options.useCalculatedSizes && item.calculatedProps.size) {
                itemProps = item.calculatedProps;
            } else {
                itemProps = item.calcProps(availableSize, options.itemOptions);
            }
            availableSize = availableSize.deflate({ top: itemProps.size.height });
            totalHeight += itemProps.size.height + options.gap;
        }
        if (totalHeight > this.containerSize.height + 1) {
            this.isFit = false;
        }

        let maxWidth = layoutHelper.getMaxWidthOfItems(this.items);

        let y = 0;
        let items = options.reverse ? _.reverse(this.items) : this.items;
        for (let item of items) {
            item.calculatedProps.bounds = new geom.Rect(layoutHelper.getHorizontalAlignOffset(item.calculatedProps.size.width, maxWidth, options.horizontalAlign), y, item.calculatedProps.size);
            y += item.calculatedProps.size.height + options.gap;
        }

        this.size = new geom.Size(maxWidth, y - options.gap);
        return this;
    }

    calcCircleLayout(options) {
        this.size = this.containerSize;

        let { shape, angles, points, itemSizes, correctedCenter } = calcCircleLayout(this.props, Object.assign({
            size: this.size,
            styles: this.styles,
            items: this.items
        }, options));
        this.shape = shape;
        this.angles = angles;
        this.itemSizes = itemSizes;
        this.points = points;
        this.correctedCenter = correctedCenter;

        if (this.styles.maxItemCount) {
            this.isFit = this.items.length <= this.styles.maxItemCount;
        }

        this.items.map((item, index) => {
            let angle = this.angles[index];
            let itemSize = this.itemSizes[index];
            let point = this.shape.point(angle);
            item.calculatedProps.bounds = new geom.Rect(point.x, point.y, itemSize.width, itemSize.height);
            item.calculatedProps.angle = angle;
        });

        return this;
    }

    calcConnectorLayout(options) {
        let connectorLayoutFn;
        if (options.connectorType === ConnectorType.ARC) {
            connectorLayoutFn = calcArcConnectorLayout;
        } else if (options.connectorType === ConnectorType.STRAIGHT) {
            connectorLayoutFn = calcStraightConnectorLayout;
        }
        this.items.map((item, index) => {
            connectorLayoutFn({
                index,
                item,
                shape: this.shape,
                angles: this.angles,
                items: this.items,
                styles: this.styles,
                points: this.points,
                correctedCenter: this.correctedCenter,
                content_size: options.content_size,
                show_arrows: options.show_arrows,
                pointing_in: options.pointing_in,
                start_angle: options.start_angle,
                circle_radius: options.circle_radius
            });

            this.isFit && item.connectorFits;

            if (this.props.isFit) {
                let { linePath, arrowPath } = item.calcConnector();
                item.line.styles = this.styles.arrowArcs;
                item.line.path = linePath;

                if (options.show_arrows) {
                    item.arrow.styles = this.styles.arrowHeads;
                    item.arrow.path = arrowPath;
                }
            }
        });

        return this;
    }
}

ElementLayouter.prototype.calcRowLayout = calcRowLayout;
ElementLayouter.prototype.calcVerticalBlockLayout = calcVerticalBlockLayout;
ElementLayouter.prototype.calcHorizontalBlockLayout = calcHorizontalBlockLayout;
ElementLayouter.prototype.calcColumnLayout = calcColumnLayout;
ElementLayouter.prototype.calcBoxGridLayout = calcBoxGridLayout;
ElementLayouter.prototype.calcGridLayout = calcGridLayout;
