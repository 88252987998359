import * as geom from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";
import { BackgroundStyleType, PositionType } from "legacy-common/constants";
import { getValueOrDefault } from "js/core/utilities/extensions";

import { PhotoCollage } from "./PhotoCollage";
import { ContentBlockContainer, ContentBlockFrame } from "./ContentBlock";
import { Footer } from "./Footer";
import { BaseElement } from "../base/BaseElement";
import { TextElement } from "../base/TextElement";

const HeadlinePositionType = {
    TOP: "top",
    BOTTOM: "bottom",
    LEFT: "left",
    RIGHT: "right",
    CENTER: "center"
};

class Headline extends BaseElement {
    get _canRollover() {
        return this.showImages && this.headlinePosition != HeadlinePositionType.CENTER;
    }

    get minWidth() {
        return 400;
    }

    get minHeight() {
        return 200;
    }

    get dividerMin() {
        return this.headlinePosition === HeadlinePositionType.TOP || this.headlinePosition === HeadlinePositionType.BOTTOM ? 0.1 : 0.33;
    }

    get dividerMax() {
        return 0.75;
    }

    get dividerSize() {
        return Math.clamp(this.model.dividerSize || 0.5, this.dividerMin, this.dividerMax);
    }

    get showImages() {
        if (this.model.items) {
            return this.model.items.length > 0 && Object.keys(this.model.items[0]).length > 0;
        } else {
            return false;
        }
    }

    // get canStyleText() {
    //     return this.showImages && this.headlinePosition == HeadlinePositionType.CENTER;
    // }
    //
    get fullBleed() {
        if (this.model.aspectRatio == "fit") {
            return false;
        } else {
            return this.model.fullBleed;
        }
    }

    get reserveFooterSpace() {
        if (this.fullBleed && (!this.showImages || this.images.showGutter)) {
            return true;
        } else {
            return false;
        }
    }

    get headlinePosition() {
        if (this.showImages) {
            return this.model.headlinePosition || HeadlinePositionType.TOP;
        } else {
            return HeadlinePositionType.CENTER;
        }
    }

    get textPosition() {
        return getValueOrDefault(this.model.textPosition, PositionType.CENTER);
    }

    _build() {
        if (!this.model.blocks || this.model.blocks.length == 0) {
            this.model.blocks = [{ type: "headline" }];
        }

        if (this.showImages) {
            // it is important that the HeadlineImages element be built after the HeadlineContentBlock so that it is after HeadlineContentBlock in the headlines's elements collection
            // this will allow selection to work when clicking on text over a HeadlineImage because the selectionLayer findElementsAtPoint code iterates through the elements collection
            // the layer property is used to position the HeadlineImages before the HeadlineContentBlock in the DOM
            this.images = this.addElement("images", () => HeadlineImages);
            this.images.layer = -1;
        }

        this.headline = this.addElement("block", () => HeadlineContentBlock, {
            canDragPosition: this.showImages && this.headlinePosition == HeadlinePositionType.CENTER,
            canDragResize: this.showImages && this.headlinePosition == HeadlinePositionType.CENTER
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let headlineBounds = new geom.Rect(0, 0, size);

        if (this.headlinePosition == HeadlinePositionType.CENTER) {
            let headlineProps = this.headline.calcProps(size);
            headlineProps.bounds = new geom.Rect(0, 0, headlineProps.size);
        } else {
            switch (this.headlinePosition) {
                case HeadlinePositionType.LEFT:
                    headlineBounds = new geom.Rect(0, 0, size.width * this.dividerSize - this.styles.horizontalGap / 2, size.height);
                    break;
                case HeadlinePositionType.RIGHT:
                    headlineBounds = new geom.Rect(size.width - size.width * this.dividerSize + this.styles.horizontalGap / 2, 0, size.width * this.dividerSize - this.styles.horizontalGap / 2, size.height);
                    break;
                case HeadlinePositionType.TOP:
                    this.headline.styles.paddingBottom = 20;
                    headlineBounds = new geom.Rect(0, 0, size.width, size.height * this.dividerSize - this.styles.verticalGap / 2);
                    break;
                case HeadlinePositionType.BOTTOM:
                    this.headline.styles.paddingTop = 20;
                    headlineBounds = new geom.Rect(0, size.height - size.height * this.dividerSize + this.styles.verticalGap / 2, size.width, size.height * this.dividerSize - this.styles.verticalGap / 2);
                    break;
            }

            this.headline.styles.paddingLeft = this.headline.styles.paddingRight = this.headline.styles.paddingTop = this.headline.styles.paddingBottom = 0;

            let headlineProps = this.headline.calcProps(headlineBounds.size);
            headlineProps.bounds = new geom.Rect(0, 0, headlineProps.size).centerInRect(headlineBounds);
        }

        let imageGridBounds = new geom.Rect(0, 0, size);
        if (this.showImages) {
            let canvasMargins = this.getCanvasMargins();
            let gutterSize = this.images.showGutter ? this.images.gutterSize : 0;
            switch (this.headlinePosition) {
                case HeadlinePositionType.CENTER:
                    if (this.fullBleed) {
                        if (this.canvas.model.layout.showElementAttribution || this.canvas.model.layout.elementTextBlockPosition == "inline") {
                            imageGridBounds = imageGridBounds.inflate({
                                top: canvasMargins.top,
                                left: canvasMargins.left,
                                right: canvasMargins.right,
                                bottom: 0
                            }).deflate(gutterSize);
                        } else {
                            imageGridBounds = imageGridBounds.inflate(canvasMargins).deflate(gutterSize);
                        }
                    }
                    break;
                case HeadlinePositionType.LEFT:
                    imageGridBounds = imageGridBounds.deflate({ left: headlineBounds.width + this.styles.horizontalGap / 2 });
                    if (this.fullBleed) {
                        imageGridBounds = imageGridBounds.inflate({
                            top: canvasMargins.top - gutterSize,
                            right: canvasMargins.right - gutterSize,
                            bottom: canvasMargins.bottom - gutterSize
                        });
                    }
                    break;
                case HeadlinePositionType.RIGHT:
                    imageGridBounds = imageGridBounds.deflate({ right: headlineBounds.width + this.styles.horizontalGap / 2 });
                    if (this.fullBleed) {
                        imageGridBounds = imageGridBounds.inflate({
                            top: canvasMargins.top - gutterSize,
                            left: canvasMargins.left - gutterSize,
                            bottom: canvasMargins.bottom - gutterSize
                        });
                    }
                    break;
                case HeadlinePositionType.TOP:
                    imageGridBounds = imageGridBounds.deflate({ top: headlineBounds.height + this.styles.verticalGap / 2 });
                    if (this.fullBleed) {
                        imageGridBounds = imageGridBounds.inflate({
                            left: canvasMargins.left - gutterSize,
                            right: canvasMargins.right - gutterSize,
                            bottom: canvasMargins.bottom - gutterSize
                        });
                    }
                    break;
                case HeadlinePositionType.BOTTOM:
                    imageGridBounds = imageGridBounds.deflate({ bottom: headlineBounds.height + this.styles.verticalGap / 2 });
                    if (this.fullBleed) {
                        imageGridBounds = imageGridBounds.inflate({
                            top: canvasMargins.top - gutterSize,
                            right: canvasMargins.right - gutterSize,
                            left: canvasMargins.left - gutterSize
                        });
                    }
                    break;
            }

            let imagesProps = this.images.calcProps(imageGridBounds.size);
            imagesProps.bounds = imageGridBounds;
            imagesProps.layer = -1;
        }

        return { size };
    }

    getBackgroundColor(forElement) {
        if (forElement && forElement instanceof Footer && this.showImages && this.fullBleed && !this.images.showGutter && (this.headlinePosition == HeadlinePositionType.CENTER || this.headlinePosition == HeadlinePositionType.TOP || this.headlinePosition == HeadlinePositionType.RIGHT)) {
            return BackgroundStyleType.IMAGE;
        }
        if (forElement && (forElement instanceof ContentBlockFrame || forElement instanceof TextElement) && this.showImages && this.headlinePosition == HeadlinePositionType.CENTER) {
            return BackgroundStyleType.IMAGE;
        } else {
            return super.getBackgroundColor(forElement);
        }
    }

    getAnimations() {
        return Object.values(this.elements)
            // Sorting in order to put image animations in the end
            .sort((a, b) => a.type.localeCompare(b.type))
            .reduce((animations, element) => ([...animations, ...element.getAnimations()]), []);
    }
}

class HeadlineContentBlock extends ContentBlockContainer {
    get minItemCount() {
        return 0;
    }

    get canSelect() {
        return false;
    }

    get isOverImage() {
        return (this.parentElement.headlinePosition == HeadlinePositionType.CENTER && this.parentElement.showImages);
    }

    get showAddBlockUI() {
        return true;
    }

    // get canStyleText() {
    //     if (this.parentElement.headlinePosition == "center" && this.parentElement.showImages) {
    //         return super.canStyleText;
    //     } else {
    //         return false;
    //     }
    // }
}

class HeadlineImages extends PhotoCollage {
    get canSelect() {
        return true;
    }

    get canRollover() {
        return false;
    }

    get minItemCount() {
        return 0;
    }

    get isTray() {
        return true;
    }

    get allowText() {
        return false;
    }
}

export { Headline, HeadlinePositionType };

export const elements = {
    Headline
};
