import { MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import styled from "styled-components";

import { LibraryView } from "legacy-common/constants";
import { FeatureType } from "legacy-common/features";
import AppController from "legacy-js/core/AppController";
import { app } from "js/namespaces";
import { Divider } from "legacy-js/react/components/UiComponents";

const MyPresentationsTitle = styled.div`
    padding: 10px;
    font-size: 16px;
    color: #000;
    border: none;
    cursor: pointer;
    background: transparent;
    font-weight: 600;
    font-family: "Source Sans Pro";
    text-transform: uppercase;
`;

export class PresentationLibraryViewSelect extends Component {
    render() {
        const { libraryView, onSwitchType, isMyPresentationsOnly } = this.props;

        const canEditLibraryItems = !app.integrator && app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, AppController.orgId);

        return (
            <>
                {isMyPresentationsOnly ? (
                    <MyPresentationsTitle>My Presentations</MyPresentationsTitle>
                ) : (
                    <Select id="libraryType" value={libraryView} onChange={event => onSwitchType(event.target.value)}
                        disableUnderline>
                        <MenuItem value={LibraryView.LIBRARY}>My Presentations</MenuItem>
                        <MenuItem value={LibraryView.MY_RESOURCES}>My Resources</MenuItem>
                        {canEditLibraryItems && <MenuItem value={LibraryView.TEAM_RESOURCES}>Team Resources</MenuItem>}
                    </Select>
                )}
                <Divider margin={10} />
            </>
        );
    }
}
