import React from "react";
import styled from "styled-components";
import {
    Icon,
    Grow,
    Popper,
    MenuItem,
    MenuList,
    Paper,
    ClickAwayListener
} from "@material-ui/core";

const ContextBar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 10px;
    margin-top: 5px;

    .editor & {
        color: #11a9e2;
    }
    .viewer & {
        color: #fff;
    }
`;

const SlideTag = styled.div`
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 10px;

    .editor & {
        background: #F7FAFC;
    }
    .viewer & {
        background: #666666;
    }
`;

const CommentPaper = styled(Paper)`
    background: #eee;
`;

const MenuButton = styled(Icon)`
    font-size: 12px !important;
    padding: 3px !important;
    border-radius: 50%;
    cursor: pointer;
`;

const CommentMenu = ({ hasMenu, isPresentationOwner, text, slideIndex, menuItems }) => {
    const [popupAnchorEl, setPopupAnchorEl] = React.useState(null);

    const handleMenuClick = event => {
        setPopupAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setPopupAnchorEl(null);
    };

    return (
        <>
            {!!text && (
                <ContextBar>
                    <SlideTag>Slide {slideIndex + 1}</SlideTag>
                    <MenuButton
                        style={{ opacity: (hasMenu || isPresentationOwner) ? 1 : 0 }}
                        onClick={handleMenuClick}
                    >
                        more_vert
                    </MenuButton>
                </ContextBar>
            )}
            {(hasMenu || isPresentationOwner) && !!text && (
                <Popper
                    id="long-menu"
                    open={!!popupAnchorEl}
                    anchorEl={popupAnchorEl}
                    transition
                    disablePortal
                    modifiers={{ offset: { offset: "-100,0" } }}
                    style={{ zIndex: 99999 }}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: "center bottom" }}
                        >
                            <CommentPaper>
                                <ClickAwayListener onClickAway={handleMenuClose}>
                                    <MenuList>
                                        {
                                            menuItems.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={() => {
                                                        item.onClick();
                                                        handleMenuClose();
                                                    }}
                                                    data-test-id={item.testId}
                                                >
                                                    {item.text}
                                                </MenuItem>
                                            ))
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </CommentPaper>
                        </Grow>
                    )}
                </Popper>
            )}
        </>
    );
};

export default CommentMenu;
