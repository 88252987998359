import React, { Component, setGlobal, getGlobal } from "reactn";
import { SearchAssets } from "js/core/utilities/searchAssets";
import { SearchInput } from "legacy-js/react/components";
import { app } from "js/namespaces.js";
import { _ } from "legacy-js/vendor";
import { loadImage } from "js/core/utilities/promiseHelper";
import {
    UIContainerContext,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "legacy-js/react/components/UiComponents";
import DropDown from "legacy-js/react/components/DropDown";
import Loadable from "../../../components/Loadable";
import LoadingOverlay from "../../../components/LoadingOverlay";
import ImageThumbnailGrid from "legacy-js/react/views/AddAssets/Components/ImageThumbnailGrid";
import StockPhotoPreview from "legacy-js/react/views/AddAssets/Components/StockPhotoPreview";
import { Notice } from "legacy-js/react/components/Notice";
import getLogger, { LogGroup } from "js/core/logger";
import styled from "styled-components";

const logger = getLogger(LogGroup.ASSETS);

const CopyrightWarning = styled.div`
    z-index: 2;
    position: absolute;
    right: 20px;
    bottom: 0;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: right;
    color: #676767;
    padding: 10px;
    background: white;
`;

const StyledPaneHeader = styled(UIPaneHeader)`
    .MuiButton-label {
        font-size: 16px;
    }
`;

export class WebImagesPane extends Component {
    static contextType = UIContainerContext;

    constructor(props) {
        super(props);
        const searchFilters = {};
        this.state = {
            isSearching: false,
            searchFilters,
            searchResults: null,
            searchAssetsUtil: new SearchAssets(),
            stockCategory: null
        };
        this.searchInputRef = React.createRef();

        if (app.tourFuncs) {
            app.tourFuncs.doTourImageSearch = async () => {
                this.setState({
                    stockCategory: "dandelion"
                });
                await this.doSearch("dandelion", searchFilters);
            };
        }
    }

    componentDidMount = async () => {
        const {
            prefilledSearchQuery,
            onPrefilledSearchQueryComplete
        } = this.props;

        const global = getGlobal();
        const { lastPhotoSearchTerm } = global;

        const prefill = prefilledSearchQuery;
        this.setState({ autoSearch: prefill });
        if (prefill) {
            await this.doSearch(prefill, this.state.searchFilters);
        } else {
            await this.doSearch(lastPhotoSearchTerm, this.state.searchFilters);
        }
        onPrefilledSearchQueryComplete();
    };

    componentDidUpdate = async prevProps => {
        if (
            this.props.prefilledSearchQuery &&
            prevProps.prefilledSearchQuery !== this.props.prefilledSearchQuery
        ) {
            this.setState({ autoSearch: this.props.prefilledSearchQuery });
            await this.doSearch(this.props.prefilledSearchQuery, this.state.searchFilters);
        }
    };

    doSearch = async (searchTerm, searchFilters) => {
        setGlobal({ lastPhotoSearchTerm: searchTerm });
        if (!searchTerm) {
            this.setState({ searchResults: null });
            return;
        }
        searchTerm = searchTerm.toLowerCase();
        this.setState({
            isLoading: true,
            isSearching: true,
            searchFilters,
            searchResults: []
        });
        const response = await this.state.searchAssetsUtil.searchWebService(
            searchTerm,
            searchFilters,
        );
        if (response.complete && response.results.length) {
            await Promise.all(
                response.results.map(result =>
                    loadImage(result.thumbnailUrl, 4000)
                        .catch(err => logger.error(err, "loadImage() failed", { url: result.thumbnailUrl }))
                )
            );
            this.setState({
                searchResults: response.results,
                isLoading: false,
                isSearching: false
            });
        } else {
            this.setState({
                searchResults: [],
                isLoading: false,
                isSearching: false
            });
        }
    };

    getNextPage = async () => {
        this.setState({ isLoading: true });
        const response = await this.state.searchAssetsUtil.getNextPage();
        if (response.complete && response.results.length) {
            await Promise.all(
                response.results.map(result =>
                    loadImage(result.thumbnailUrl, 4000)
                        .catch(err => logger.error(err, "loadImage() failed", { url: result.thumbnailUrl }))
                )
            );
            this.setState(prevState => {
                if (
                    prevState.searchResults &&
                    prevState.searchResults.length > 1
                ) {
                    const searchResults = [
                        ...prevState.searchResults,
                        ...response.results
                    ];
                    const uniqueResults = _.uniqBy(
                        searchResults,
                        e => e.previewUrl
                    );
                    return { searchResults: uniqueResults, isLoading: false };
                }
            });
        }
    };

    checkScroll = e => {
        const scrollDistance =
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
        if (scrollDistance <= e.currentTarget.clientHeight * 2) {
            if (!this.state.isLoading) {
                const debouncedLoadPage = _.debounce(
                    () => {
                        this.getNextPage();
                    },
                    3000,
                    { leading: true }
                );
                debouncedLoadPage();
            }
        }
    };

    renderSearchResults = () => {
        const {
            searchResults,
            isSearching,
        } = this.state;
        const {
            addAssetCallback,
            handleConfirm,
        } = this.props;

        let results = searchResults?.map((imgData, idx) => {
            return (
                <StockPhotoPreview
                    addAssetCallback={addAssetCallback}
                    key={`${imgData.previewUrl}`}
                    handleConfirm={handleConfirm}
                    imgProps={imgData}
                    {...(idx === 0 && { tourImage: true })}
                />
            );
        });

        return (
            <>
                {isSearching && <LoadingOverlay />}
                <Loadable isLoading={isSearching}>
                    {
                        !!results?.length &&
                        <ImageThumbnailGrid>
                            {results}
                        </ImageThumbnailGrid>
                    }
                    {
                        !results?.length &&
                        <Notice title="Search the web for images"
                            message={
                                <>
                                    <p>Beautiful.ai does not curate or manage the images returned by the web image search.</p>
                                    <br/>
                                    <p>WARNING: Poor quality, watermarked, licensed or inappropriate images may be returned by the search.</p>
                                    <br/>
                                    <p>NOTE: Some images may not be licensed for commerical use. Please be sure you ensure you have permission and/or rights to use each image in your presentation.</p>
                                </>
                            }
                        />
                    }
                </Loadable>
            </>
        );
    };

    handleClearSearch() {
        this.searchInputRef.current.clearSearch();
    }

    handleSearchFilter(filter) {
        const searchFilters = {};
        if (filter) {
            searchFilters[filter] = true;
        }
        const global = getGlobal();
        const { lastPhotoSearchTerm } = global;
        this.doSearch(lastPhotoSearchTerm, searchFilters);
        return true;
    }

    render() {
        const {
            searchFilters,
            searchResults,
            stockCategory,
            autoSearch,
        } = this.state;
        const inputFocused = !this.context;

        const global = getGlobal();
        const { lastPhotoSearchTerm } = global;

        return (
            <UIPane>
                <StyledPaneHeader>
                    <SearchInput
                        ref={this.searchInputRef}
                        allowEmptySubmit
                        handleSubmit={searchTerm =>
                            this.doSearch(searchTerm, searchFilters)
                        }
                        placeholder="Search for images..."
                        handleClearSearch={() => {
                            setGlobal({ lastPhotoSearchTerm: null });
                            this.setState({
                                searchResults: null,
                                stockCategory: null,
                                isSearching: false
                            });
                        }}
                        focus={inputFocused}
                        prefilledSearch={stockCategory || autoSearch || lastPhotoSearchTerm}
                    />
                    <DropDown
                        variant="text"
                        onSelect={filter => this.handleSearchFilter(filter)}
                        items={[
                            {
                                label: "All Images",
                                value: null,
                            },
                            {
                                label: "Creative Commons Only",
                                value: "creativeCommonsOnly",
                            },
                        ]}
                        dropdownId="webImagesFilter"
                        selectedIndex={0}
                        type="transparent"
                        aboveAll
                    />
                </StyledPaneHeader>
                <UIPaneContents
                    style={{ paddingTop: 20, position: "relative" }}
                    onScroll={e => {
                        searchResults && this.checkScroll(e);
                    }}
                >
                    {this.renderSearchResults()}
                </UIPaneContents>
                <CopyrightWarning>Some content may be subject to copyright.</CopyrightWarning>
            </UIPane>
        );
    }
}
