import React from "react";

import { FirebaseAuthConsumer } from "./FirebaseAuthContext";
import { FirebaseAuthInitalizer } from "./FirebaseAuthInitalizer";

export function AuthConsumer({ children }) {
    return (
        <FirebaseAuthInitalizer>
            <FirebaseAuthConsumer>
                {auth => children(auth)}
            </FirebaseAuthConsumer>
        </FirebaseAuthInitalizer>
    );
}
