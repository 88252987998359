import { ds } from "js/core/models/dataService";
import { $, _ } from "legacy-js/vendor";
import { controls } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { DirectionType, ConnectorType, NodeType, WidgetPositionType } from "legacy-common/constants";
import { app } from "js/namespaces";
import { AnchorType } from "js/core/utilities/geom";
import { Ellipse } from "js/core/utilities/ellipse";
import { Convert } from "js/core/utilities/geom";

import { CollectionElementSelection } from "../CollectionElementEditor";
import { ElementOptionsMenu } from "../BaseElementEditor";
import {
    NodeElementRollover,
    renderNodeConnectorWidget,
    renderNodeElementControls,
} from "./NodeEditor";
import { ContentItemSelection, renderContentItemControls } from "./ContentItemEditor";

import { ContentItem } from "../../elements/base/ContentItem";

const NodeDiagramSelection = CollectionElementSelection.extend({

    showSelectionBox: false,

    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: "Add Node",
            icon: "add_circle",
            callback: () => {
                app.mainView.editorView.showAddAnnotation(this.element);
            }
        });

        this.addControl({
            type: controls.BUTTON,
            label: "Center Items",
            callback: () => {
                ds.selection.element = null;
                this.element.centerLayout();
                app.currentCanvas.updateCanvasModel(false);
            }
        });

        // this.addControl({
        //     type: controls.POPUP_BUTTON,
        //     label: "Layout",
        //     items: [{
        //         label: "Linear", value: "linear"
        //     }, {
        //         label: "Step", value: "step"
        //     }, {
        //         label: "Loop", value: "loop"
        //     }, {
        //         label: "SeeSaw", value: "seesaw"
        //     }, {
        //         label: "Rows", value: "rows"
        //     }, {
        //         label: "Hub And Spoke", value: "hubandspoke"
        //     }],
        //     callback: value => {
        //         this.setLayout(value);
        //     }
        // });
    },

    setLayout(layout) {
        let nodeTree = this.element.buildNodeTree();
        let nodes = nodeTree.flat();

        let deltaX = 0.8 / (nodes.length - 1);
        let x = 0.1;

        this.element.connectors.model.items = [];

        for (let i = 0; i < nodes.length - 1; i++) {
            this.element.connectors.model.items.push({
                startAnchor: AnchorType.FREE,
                endAnchor: AnchorType.FREE,
                startDecoration: "none",
                endDecoration: "arrow",
                connectorType: ConnectorType.STRAIGHT,
                source: nodes[i].id,
                target: nodes[i + 1].id
            });
        }

        switch (layout) {
            case "linear": {
                for (let node of nodes) {
                    node.model.x = x;
                    x += deltaX;
                    node.model.y = 0.5;
                    node.model.textDirection = DirectionType.BOTTOM;
                }
                break;
            }
            case "rows": {
                let rowCount = Math.ceil(nodes.length / 2);
                deltaX = 0.8 / (rowCount - 1);
                for (let i = 0; i < rowCount; i++) {
                    nodes[i].model.x = 0.1 + i * deltaX;
                    nodes[i].model.y = 0.25;
                    nodes[i].model.textDirection = DirectionType.TOP;
                }

                this.element.connectors.model.items[rowCount - 1].connectorType = ConnectorType.STEP;
                this.element.connectors.model.items[rowCount - 1].startAnchor = AnchorType.BOTTOM;
                this.element.connectors.model.items[rowCount - 1].endAnchor = AnchorType.TOP;
                this.element.connectors.model.items[rowCount - 1].lineStyle = "dashed";

                for (let i = rowCount; i < nodes.length; i++) {
                    nodes[i].model.x = 0.1 + (i - rowCount) * deltaX;
                    nodes[i].model.y = 0.75;
                    nodes[i].model.textDirection = DirectionType.BOTTOM;
                }
                break;
            }
            case "seesaw": {
                let up = true;
                for (let node of nodes) {
                    node.model.x = x;
                    x += deltaX;
                    node.model.y = up ? 0.25 : 0.75;
                    node.model.textDirection = up ? DirectionType.TOP : DirectionType.BOTTOM;
                    up = !up;
                }
                break;
            }
            case "step": {
                deltaX = 0.8 / (Math.ceil(nodes.length / 2) - 1);
                let count = 0;
                let col = 0;
                let up = true;
                for (let node of nodes) {
                    node.model.x = 0.1 + col * deltaX;
                    node.model.y = up ? 0.25 : 0.75;
                    node.model.textDirection = up ? DirectionType.TOP : DirectionType.BOTTOM;
                    up = !up;
                    count++;
                    if (count > 1) {
                        count = 0;
                        col++;
                        up = !up;
                    }
                }
                break;
            }
            case "loop": {
                let rowCount = Math.ceil(nodes.length / 2);
                deltaX = 0.8 / (rowCount - 1);
                for (let i = 0; i < rowCount; i++) {
                    nodes[i].model.x = 0.1 + i * deltaX;
                    nodes[i].model.y = 0.25;
                    nodes[i].model.textDirection = DirectionType.TOP;
                }
                for (let i = rowCount; i < nodes.length; i++) {
                    nodes[i].model.x = 0.9 - (i - rowCount) * deltaX;
                    nodes[i].model.y = 0.75;
                    nodes[i].model.textDirection = DirectionType.BOTTOM;
                }
                break;
            }
            case "hubandspoke": {
                let hub = nodes[0];
                hub.model.x = 0.5;
                hub.model.y = 0.5;
                hub.model.nodeType = "circle";

                let r = this.element.bounds.square().width / 2 - 50;

                let ellipse = new Ellipse(this.element.bounds.width / 2, this.element.bounds.height / 2, r, r, false);
                let angles = ellipse.equalPoints(0, nodes.length - 1, Math.PI * 2);

                for (let i = 0; i < nodes.length - 1; i++) {
                    let pt = ellipse.point(angles[i]);
                    nodes[i + 1].model.x = pt.x / this.element.bounds.width;
                    nodes[i + 1].model.y = pt.y / this.element.bounds.height;
                }

                this.element.connectors.model.items = [];

                for (let i = 1; i < nodes.length; i++) {
                    this.element.connectors.model.items.push({
                        startAnchor: AnchorType.FREE,
                        endAnchor: AnchorType.FREE,
                        startDecoration: "none",
                        endDecoration: "arrow",
                        connectorType: ConnectorType.STRAIGHT,
                        source: nodes[i].id,
                        target: nodes[0].id
                    });
                }

                break;
            }
        }

        this.element.canvas.updateCanvasModel(true);
    }

});

const NodeDiagramOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Snap to Grid",
            property: "snapToGrid"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Grid",
            property: "showGrid"
        });
    }
});

const NodeDiagramItemRollover = NodeElementRollover.extend({
    renderControls() {
        NodeElementRollover.prototype.renderControls.apply(this, arguments);
        renderNodeConnectorWidget(this, event => createNodeDiagramConnector(event, this));
    },
});

const NodeDiagramItemSelection = ContentItemSelection.extend({
    captureMouseEvents: false,

    canDrag() {
        return false;
    },

    renderControls: function() {
        if (this.element instanceof ContentItem) {
            renderContentItemControls(this, { allowFrames: true });
            if (this.element.showResizeHandle) {
                this.renderResizeHandle();
            }
        } else {
            renderNodeElementControls(this);
            this.renderResizeHandle();
        }

        renderNodeConnectorWidget(this, event => createNodeDiagramConnector(event, this));

        let $dragWidget = this.createDragWidget(this.element, {
            position: WidgetPositionType.DRAG_HANDLE
        });
        $dragWidget.makeDraggable({
            element: this.element,
            axis: this.getDragAxis(),
            dragDistance: 5,
            start: (event, axis) => {
                app.isDraggingItem = true;
                this.selectionLayer.hideWidgets($(".drag_button"));
            },
            drag: (event, position) => {
                if (!this.canvas.layouter.isGenerating) {
                    const registrationPoint = this.element.registrationPoint;

                    const containerElement = this.element.parentElement;

                    const dragPosition = position.elementPosition.offset(registrationPoint);
                    this.element.dragWidgetPosition = position.elementPosition;
                    this.element.model.x = Math.clamp(dragPosition.x / containerElement.canvasBounds.width, 0, 1);
                    this.element.model.y = Math.clamp(dragPosition.y / containerElement.canvasBounds.height, 0, 1);

                    // If the element has snap options - remove them after it's been moved
                    if (this.element.model.snapOptions) {
                        delete this.element.model.snapOptions;
                    }

                    this.element.getRootElement().refreshElement();
                }
            },
            stop: (event, position) => {
                app.isDraggingItem = false;
                this.selectionLayer.showWidgets();
                this.element.canvas.updateCanvasModel(false);
            },
        });
    },
});

function createNodeDiagramConnector(event, view, anchor) {
    event.stopPropagation();

    app.isDraggingItem = true;

    let element = view.element;
    let containerElement = element.parentElement;

    element.canvas.lockSlideForCollaborators(60); // 1 minute

    let cornerAngle1 = element.bounds.getPoint("center").angleToPoint(element.bounds.getPoint("bottom-right"));
    let cornerAngle2 = element.bounds.getPoint("center").angleToPoint(element.bounds.getPoint("bottom-left"));
    let cornerAngle3 = element.bounds.getPoint("center").angleToPoint(element.bounds.getPoint("top-left"));
    let cornerAngle4 = element.bounds.getPoint("center").angleToPoint(element.bounds.getPoint("top-right"));

    // create a new connector model that will point to the mouse
    let connectorModel = {
        source: element.id,
        startAnchor: anchor || AnchorType.FREE,
        targetPoint: Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY),
        endDecoration: "arrow",
        connectorType: ConnectorType.STEP
    };
    containerElement.connectors.model.items.push(connectorModel);

    let targetElement;

    let startDragPt = new geom.Point(event.pageX, event.pageY);

    $("body").on("mousemove.create-connector", event => {
        let mousePt = Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY);
        mousePt = mousePt.constrain(containerElement.bounds.zeroOffset());

        view.selectionLayer.hideWidgets($(".anchor-widget"));

        // use angles to determiine which anchor point we are closest to during dragging
        let angle = element.bounds.getPoint("center").angleToPoint(mousePt);
        if (angle < cornerAngle1) {
            connectorModel.startAnchor = AnchorType.RIGHT;
        } else if (angle < cornerAngle2) {
            connectorModel.startAnchor = AnchorType.BOTTOM;
        } else if (angle < cornerAngle3) {
            connectorModel.startAnchor = AnchorType.LEFT;
        } else if (angle < cornerAngle4) {
            connectorModel.startAnchor = AnchorType.TOP;
        } else {
            connectorModel.startAnchor = AnchorType.RIGHT;
        }

        // check if the mouse is over another node
        let mouseOverElement = _.find(containerElement.itemElements, element => element.bounds.inflate(20).contains(mousePt));
        if (mouseOverElement && mouseOverElement != targetElement && mouseOverElement != element) {
            // if we are over an element, set it as the new target element and draw it's anchor points
            targetElement = mouseOverElement;
            view.selectionLayer.renderAnchorPoints(targetElement);
        } else if (mouseOverElement == null) {
            // if we aren't over an element, clear the target element and remove any anchor points
            targetElement = null;
            view.selectionLayer.renderAnchorPoints(null);
        }

        view.selectionLayer.$el.find(".anchor-widget.selected").removeClass("selected"); // clear any previouslyselected anchor

        if ($(event.target).hasClass("anchor-widget")) {
            // we are dragging over another elements anchor point
            connectorModel.endAnchor = $(event.target).data("anchor");
            $(event.target).addClass("selected");
        } else if (targetElement) {
            // if we aren't over an anchor point but we ARE over an element, use FREE anchor
            connectorModel.endAnchor = AnchorType.FREE;
        } else {
            // otherwise we aren't over an element or an anchor point so set endAnchor to inverse of startAnchor
            switch (connectorModel.startAnchor) {
                case AnchorType.LEFT:
                    connectorModel.endAnchor = AnchorType.RIGHT;
                    break;
                case AnchorType.RIGHT:
                    connectorModel.endAnchor = AnchorType.LEFT;
                    break;
                case AnchorType.TOP:
                    connectorModel.endAnchor = AnchorType.BOTTOM;
                    break;
                case AnchorType.BOTTOM:
                    connectorModel.endAnchor = AnchorType.TOP;
                    break;
            }
        }

        // update the connector model to preview the connection
        if (targetElement) {
            // we are over a target node
            connectorModel.target = targetElement.id;
            connectorModel.connectorType = ConnectorType.STEP;
        } else {
            // we are free dragging
            connectorModel.target = null;
            connectorModel.targetPoint = mousePt;
            connectorModel.connectorType = ConnectorType.STEP;
        }

        containerElement.canvas.refreshCanvas(false);
    });

    $("body").on("mouseup.create-connector", event => {
        if (new geom.Point(event.pageX, event.pageY).distance(startDragPt) > 20) {
            let mousePt = Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY);
            mousePt = mousePt.constrain(containerElement.bounds.zeroOffset());

            let newNode;
            if (!targetElement) {
                // if we ended the drag without connecting to another a node,  create a new node and connect to it
                newNode = containerElement.addItem();
                newNode.nodeType = element.model.nodeType;
                newNode.x = mousePt.x / containerElement.bounds.width;
                newNode.y = mousePt.y / containerElement.bounds.height;
                connectorModel.target = newNode.id;
            }
            connectorModel.targetPoint = null;

            // delete connector if it's pointing at itself
            if (connectorModel.target == element.id && connectorModel.startAnchor == connectorModel.endAnchor) {
                containerElement.connectors.model.items.remove(connectorModel);
            }

            element.canvas.refreshCanvas({ suppressCanvasRefreshEvent: true }).then(() => {
                view.selectionLayer.renderAnchorPoints(null);
                view.selectionLayer.showWidgets();

                if (newNode) {
                    let nodeElement = containerElement.getItemElementById(newNode.id);

                    let delta = nodeElement.bounds.getPoint(connectorModel.endAnchor).delta(nodeElement.bounds.getPoint("center"));
                    nodeElement.model.x += delta.x / containerElement.bounds.width;
                    nodeElement.model.y += delta.y / containerElement.bounds.height;

                    element.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = nodeElement;
                    });
                }

                app.isDraggingItem = false;
                element.canvas.unlockSlideForCollaborators();
            });
        } else {
            // we didnt drag far enough to create a new node so cancel the operation and delete the connectorModel
            containerElement.connectors.model.items.remove(connectorModel);
            element.canvas.refreshCanvas().then(() => {
                view.selectionLayer.showWidgets();
                app.isDraggingItem = false;
                element.canvas.unlockSlideForCollaborators();
            });
        }

        $("body").off(".create-connector");
    });
}

export const editors = {
    NodeDiagramSelection,
    NodeDiagramOptionsMenu,
    NodeDiagramItemSelection,
    NodeDiagramItemRollover,
};

