import React from "react";
import classNames from "classnames";
import styled from "styled-components";

const StyledLabel = styled.div`
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 20px 0 0 -50%;
  width: 100%;
  text-align: center;
`;

export default function Spinner(props) {
    const {
        classes = {},
        label,
        style,
        labelStyle,
        small = false
    } = props;
    return (
        <div
            className={classNames(classes.spinnerAddOn, "spinner")}
            style={style}
        >
            {small &&
            <svg className="circular">
                <circle className="path" cx="10" cy="10" r="7.5" />
            </svg>
            }
            {!small &&
                <svg className="circular">
                    <circle className="path" cx="20" cy="20" r="15" />
                </svg>
            }
            {
                label &&
                <StyledLabel
                    style={labelStyle}
                >{label}</StyledLabel>
            }
        </div>
    );
}
