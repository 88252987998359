import velocityAnimate from "velocity-animate";
import velocityUI from "velocity-animate/velocity.ui";

import { VelocityComponent } from "velocity-react";
import React from "reactn";

export const FadeIn = ({ children }) => (
    <VelocityComponent runOnMount animation={{ opacity: 1 }} duration={1000}>
        <div style={{ opacity: 0 }}>{children}</div>
    </VelocityComponent>
);

export const ShakingAnimaiton = ({ children, shake = false, handleComplete, duration = 1000 }) => {
    return shake ? (
        <VelocityComponent
            complete={handleComplete}
            runOnMount
            animation="callout.tada"
            duration={duration}>
            {children}
        </VelocityComponent>
    ) : <>{children}</>;
};
