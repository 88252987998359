import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";

import { ds } from "js/core/models/dataService";
import getUserProfile from "js/core/services/userProfiles";
import { TEAM_USER_ROLES } from "legacy-common/constants";
import { UserProfile } from "legacy-js/react/components/Avatar";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import Loadable from "legacy-js/react/components/Loadable";
import { isOwnerOrLibrarian } from "legacy-common/utils/roles";

const FlexDiv = styled.div`
    display: flex;
`;

const SpacedPTag = styled.p`
    letter-spacing: .1px;
`;

const ProfileContainer = styled.div`
    display: flex;
    padding: 10px;
`;

export default class LockedSharedSlideDialog extends Component {
    state = {
        isLoading: true,
        firstHalfOfMembers: [],
        secondHalfOfMembers: []
    }

    async componentDidMount() {
        const { organizationId } = this.props;
        const defaultTeam = ds.teams.defaultTeamForOrg(organizationId);
        let ownersAndLibrariansIds = [];

        for (let [memberId, memberData] of Object.entries(defaultTeam.get("members"))) {
            if (isOwnerOrLibrarian(memberData.role)) {
                ownersAndLibrariansIds.push(memberId);
            }
        }

        const ownersAndLibrariansProfiles = await Promise.all(ownersAndLibrariansIds.map(memberId => getUserProfile(memberId)));
        const halfwayIndex = Math.ceil(ownersAndLibrariansProfiles.length / 2);

        const firstHalfOfMembers = ownersAndLibrariansProfiles.filter((member, index) => index < halfwayIndex);
        const secondHalfOfMembers = ownersAndLibrariansProfiles.filter((member, index) => index >= halfwayIndex);

        this.setState({ firstHalfOfMembers, secondHalfOfMembers, isLoading: false });
    }

    render() {
        const { isLoading, firstHalfOfMembers, secondHalfOfMembers } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog} >
                <DialogTitle>Sorry, you don't have permission to edit this Shared Slide.</DialogTitle>
                <DialogContent>
                    <Loadable isLoading={isLoading}>
                        <SpacedPTag>This is a Shared Slide and its content is only editable by Owners and Librarians in your team. These are the members who have editing permissions.</SpacedPTag>
                        <FlexDiv>
                            <div>
                                {
                                    firstHalfOfMembers.map(user => {
                                        return (
                                            <ProfileContainer key={user.uid}>
                                                <UserProfile
                                                    key={user.uid}
                                                    profile={user}
                                                />
                                            </ProfileContainer>
                                        );
                                    })
                                }
                            </div>
                            <div>
                                {
                                    secondHalfOfMembers.map(user => {
                                        return (
                                            <ProfileContainer key={user.uid}>
                                                <UserProfile
                                                    key={user.uid}
                                                    profile={user}
                                                />
                                            </ProfileContainer>
                                        );
                                    })
                                }
                            </div>
                        </FlexDiv>
                    </Loadable>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.closeDialog} >
                        Got It
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
