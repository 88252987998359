import {
    AssetType,
    BackgroundStyleType,
    ContentBlockType,
    HorizontalAlignType, ListStyleType,
    PositionType,
    TextBreakType
} from "legacy-common/constants";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { SVGCircleElement, SVGPolylineElement } from "../base/SVGElement";
import { _, tinycolor } from "legacy-js/vendor";
import { app } from "js/namespaces.js";
import * as geom from "js/core/utilities/geom";
import { getValueOrDefault } from "js/core/utilities/extensions";

import { BaseElement } from "../base/BaseElement";
import { TextElement } from "../base/TextElement";
import { NodeElement } from "../base/NodeElement";
import { TextGroup } from "../base/TextGroup";
import { TextListCheckBox, TextListIcon } from "./Lists/TextList";
import { FindBestFit } from "../layouts/FindBestFit";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";

export class ContentBlockContainer extends BaseElement {
    get _canSelect() {
        return false;
    }

    get showAddBlockUI() {
        return false;
    }

    get canSelectContentBlockFrame() {
        return true;
    }

    get requireParentSelection() {
        return false;
    }

    get isOverImage() {
        return super.getBackgroundColor(this) == BackgroundStyleType.IMAGE;
    }

    get availableBlockTypes() {
        if (this.options.allowedBlockTypes) {
            return this.options.allowedBlockTypes;
        }

        return [ContentBlockType.HEADLINE, ContentBlockType.HEADING, ContentBlockType.TITLE, ContentBlockType.BODY, ContentBlockType.CAPTION, ContentBlockType.BIGTEXT, ContentBlockType.DIVIDER, ContentBlockType.ICON];
    }

    get minItemCount() {
        return 0;
    }

    get defaultBlockType() {
        return getValueOrDefault(this.options.defaultBlockType, ContentBlockType.HEADLINE);
    }

    get canDelete() {
        return true;
    }

    get canDragPosition() {
        if (this.isOverImage) {
            return getValueOrDefault(this.options.canDragPosition, false);
        } else {
            return false;
        }
    }

    get canDragResize() {
        if (this.isOverImage) {
            return getValueOrDefault(this.options.canDragResize, false);
        } else {
            return false;
        }
    }

    get hasUserTransform() {
        return this.canDragPosition && (this.userPositionX != null && this.userPositionY != null);
    }

    get userWidth() {
        return this.model.userWidth;
    }

    get userPositionX() {
        return this.model.userPositionX;
    }

    get userPositionY() {
        return this.model.userPositionY;
    }

    get textPosition() {
        if (this.hasUserTransform) {
            return PositionType.USER;
        } else {
            return getValueOrDefault(this.model.textPosition, PositionType.CENTER);
        }
    }

    get hideDisabledControls() {
        return !!this.options.hideDisabledControls;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return this.options.canRefreshElement ?? true;
    }

    getDefaultTextAlign() {
        // this is for legacy contentblocks that don't have a textAlign model set
        switch (this.textPosition) {
            case PositionType.LEFT:
            case PositionType.BOTTOM_LEFT:
            case PositionType.TOP_LEFT:
                return HorizontalAlignType.LEFT;
            case PositionType.RIGHT:
            case PositionType.BOTTOM_RIGHT:
            case PositionType.TOP_RIGHT:
                return HorizontalAlignType.RIGHT;
            default:
                return HorizontalAlignType.CENTER;
        }
    }

    _build() {
        this.contentBlockFrame = this.addElement("contentBlockFrame", () => ContentBlockFrame);
    }

    _calcProps(props, options) {
        let { size } = props;

        let innerPadding = this.contentBlockFrame.hasBackdrop ? this.styles.innerPaddingWithBackdrop : this.styles.innerPaddingWithNoBackdrop;
        if (this.hasUserTransform) {
            // we don't need padding in this case
            innerPadding = 0;
        }

        let fillWidth = false;
        switch (this.textPosition) {
            case PositionType.BOTTOM:
            case PositionType.TOP:
                if (this.contentBlockFrame.hasBackdrop) {
                    innerPadding = 0;
                }
                break;
            case PositionType.FILL:
                fillWidth = true;
                break;
        }

        let innerBounds = new geom.Rect(0, 0, size).deflate(innerPadding);
        let availableContentSize = innerBounds.size;

        if (this.userWidth) {
            availableContentSize.width = Math.min(availableContentSize.width, this.userWidth);
        } else {
            switch (this.textPosition) {
                case PositionType.CENTER:
                    if (size.width > 300) {
                        // availableContentSize.width = availableContentSize.width - (getValueOrDefault(options.centerPaddingOffset, 20)); // this is to match pre Aug 2020 refactor
                        // availableContentSize.height = availableContentSize.height - (getValueOrDefault(options.centerPaddingOffset, 20)); // this is to match pre Aug 2020 refactor
                    }
                    break;
                case PositionType.TOP:
                case PositionType.BOTTOM:
                    // void
                    break;
                case PositionType.TOP_LEFT:
                case PositionType.LEFT:
                case PositionType.BOTTOM_LEFT:
                case PositionType.TOP_RIGHT:
                case PositionType.RIGHT:
                case PositionType.BOTTOM_RIGHT:
                    // availableContentSize.width = (availableContentSize.width / 2 - innerPadding * 2) - 20;  // this is to match pre Aug 2020 refactor
                    availableContentSize.width = availableContentSize.width / 2;
                    break;
                case PositionType.FILL:
                    break;
            }
        }

        let contentBlockFrameProps = this.contentBlockFrame.calcProps(availableContentSize, {
            textAlign: this.model.textAlign || this.getDefaultTextAlign(),
            forceTextScale: options.forceTextScale,
            textPosition: this.textPosition,
            autoWidth: !fillWidth && !this.userWidth
        });

        // special case to allow full width when text is left/right and doesn't fit
        if (!fillWidth && !this.userWidth && this.contentBlockFrame.contentBlocks.doesContentFit == false) {
            contentBlockFrameProps = this.contentBlockFrame.calcProps(size.deflate(innerPadding), {
                textAlign: this.model.textAlign || this.getDefaultTextAlign(),
                forceTextScale: contentBlockFrameProps.children.contentBlocks.scale,
                textPosition: this.textPosition,
                autoWidth: false
            });
        }

        let contentSize = contentBlockFrameProps.size;
        if (fillWidth) {
            // when fillWidth is set, force the contentSize to be the full width
            contentSize.width = innerBounds.width;
        }

        if (this.hasUserTransform) {
            // user positioned, limiting to the available bounds
            const userPositionX = Math.clamp(this.userPositionX * size.width, innerBounds.left, innerBounds.width - contentSize.width) / size.width;
            const userPositionY = Math.clamp(this.userPositionY * size.height, innerBounds.top, innerBounds.height - contentSize.height) / size.height;
            if (this.userPositionX !== userPositionX || this.userPositionY !== userPositionY) {
                this.model.userPositionX = userPositionX;
                this.model.userPositionY = userPositionY;
            }
            contentBlockFrameProps.bounds = new geom.Rect(userPositionX * size.width, userPositionY * size.height, contentSize);
        } else {
            // auto positioned using textPosition
            contentBlockFrameProps.bounds = new geom.Rect(0, 0, contentSize).positionInContainer(innerBounds, this.textPosition);
        }

        return { size, scale: contentBlockFrameProps.scale };
    }

    _migrate_9() {
        let migratedBlocks = [];
        if (this.model.blocks) {
            for (let block of this.model.blocks) {
                if (block.type == "bullet point") {
                    block.type = ContentBlockType.BULLET_POINT;
                }
                if (block.type == "list") {
                    this.model.listStyle = block.listStyle;
                    for (let item of block.items) {
                        migratedBlocks.push({
                            type: ContentBlockType.BULLET_POINT,
                            title: item.title,
                            body: item.body,
                            text_format: item.text_format
                        });
                    }
                } else {
                    migratedBlocks.push(block);
                }
            }
            this.model.blocks = migratedBlocks;
        }

        let userFontScale = this.getRootElement().model.userFontScale;
        if (userFontScale) {
            for (let key of Object.keys(userFontScale)) {
                if (key.contains("ContentBlockList")) {
                    userFontScale[key.replace("ContentBlockList/TextListItem", "ContentBlockBulletPoint")] = userFontScale[key];
                    delete userFontScale[key];
                }
            }
        }
    }
}

export class ContentBlockFrame extends BaseElement {
    static get schema() {
        return {
            verticalGap: 30,
            backdropPadding: 30
        };
    }

    get _canSelect() {
        return true;
    }

    get requireParentSelection() {
        return false;
    }

    get selectionPadding() {
        return this.hasBackdrop ? 0 : 30;
    }

    get canStyleText() {
        return this.parentElement.isOverImage;
    }

    get hasBackdrop() {
        return this.canStyleText && this.textStyle.contains("box");
    }

    get textStyle() {
        if (this.canStyleText) {
            return this.model.textStyle || "none";
        } else {
            return "none";
        }
    }

    get hideDisabledControls() {
        return this.parentElement.hideDisabledControls;
    }

    _build() {
        this.contentBlocks = this.addElement("contentBlocks", () => ContentBlockCollection);
    }

    _calcProps(props, options) {
        let { size, children } = props;

        if (this.hasBackdrop && this.canvas.layouter.isGenerating) {
            let decorationProps = {
                type: "frame",
                shape: "rect"
            };

            switch (this.textStyle) {
                case "white_box":
                    decorationProps.fillColor = "white";
                    break;
                case "transparent_light_box":
                    decorationProps.fillColor = "white";
                    decorationProps.fillOpacity = 0.5;
                    break;
                case "transparent_dark_box":
                    decorationProps.fillColor = "black";
                    decorationProps.fillOpacity = 0.3;
                    break;
            }

            this.createDecoration(decorationProps);
        }

        let padding = 0;
        if (this.hasBackdrop && options.autoWidth) {
            // when autoWidth, set the style padding to account for backdrop padding
            padding = this.model.backdropPadding ?? 30;
        }
        this.contentBlocks.styles.paddingLeft = this.contentBlocks.styles.paddingRight = this.contentBlocks.styles.paddingTop = this.contentBlocks.styles.paddingBottom = padding;

        let contentBlockProps = this.contentBlocks.calcProps(size, {
            ...options,
            textStyle: this.textStyle,
            autoWidth: options.autoWidth,
            hasBackdrop: this.hasBackdrop
        });

        let calculatedSize;

        if (options.autoWidth) {
            // use the calculated content size as our width
            contentBlockProps.bounds = new geom.Rect(0, 0, contentBlockProps.size);
            calculatedSize = contentBlockProps.size;
        } else {
            // use the props size as our width
            let contentX;
            switch (options.textAlign) {
                case HorizontalAlignType.LEFT:
                    contentX = contentBlockProps.innerPadding;
                    break;
                case HorizontalAlignType.CENTER:
                    contentX = size.width / 2 - contentBlockProps.size.width / 2;
                    break;
                case HorizontalAlignType.RIGHT:
                    contentX = size.width - contentBlockProps.size.width - contentBlockProps.innerPadding;
                    break;
            }
            contentBlockProps.bounds = new geom.Rect(contentX, contentBlockProps.innerPadding, contentBlockProps.size);
            calculatedSize = new geom.Size(size.width, contentBlockProps.size.height + contentBlockProps.innerPadding * 2);
        }

        return {
            size: calculatedSize,
            scale: contentBlockProps.scale
        };
    }

    getBackgroundColor(forElement) {
        if (this.hasBackdrop) {
            return this.canvas.getTheme().palette.getColor(this.decoration.styles.fillColor);
        }
        let backgroundColor = super.getBackgroundColor(forElement);
        if (!this.textStyle || backgroundColor != BackgroundStyleType.IMAGE) {
            return backgroundColor;
        }

        switch (this.textStyle) {
            case "dark_text":
                return this.canvas.getTheme().palette.getColor("white");
            case "white_text_with_shadow":
            case "transparent_dark_box":
            case "white_text":
            default:
                return this.canvas.getTheme().palette.getColor("black");
        }
    }
}

export class ContentBlockCollection extends CollectionElement {
    get container() {
        return this.parentElement.parentElement;
    }

    get _canSelect() {
        return false;
    }

    get minItemCount() {
        return 1;
    }

    get collectionPropertyName() {
        return "blocks";
    }

    getChildItemType(model) {
        return ContentBlockItem;
    }

    async convertToAuthoring(convertElementToGroup, convertChildrenToGroup) {
        let items = [];

        // convert all items - if there's a separator, assume that
        // a new group should be started
        for (const item of this.itemElements) {
            if (item.elements.content instanceof ContentBlockDivider && items.length) {
                await convertChildrenToGroup(items);
                await convertElementToGroup(item.elements.content);

                // clear the array
                items.splice(0, items.length);
            } else {
                items.push(item.content);
            }
        }

        // if there's anything left over
        if (items.length) {
            await convertChildrenToGroup(items);
        }
    }

    get defaultItemData() {
        return { type: this.options.defaultBlockType || ContentBlockType.TITLE };
    }

    get availableBlockTypes() {
        if (this.options.allowedBlockTypes) {
            return this.options.allowedBlockTypes;
        }

        return [ContentBlockType.HEADLINE, ContentBlockType.HEADING, ContentBlockType.TITLE, ContentBlockType.BODY, ContentBlockType.CAPTION, ContentBlockType.BIGTEXT, ContentBlockType.BULLET_POINT, ContentBlockType.DIVIDER, ContentBlockType.ICON];
    }

    get canAlign() {
        return this.options.canAlign || false;
    }

    get allowAlignment() {
        return true;
    }

    get firstTextLineHeight() {
        let firstItem = this.itemElements[0];
        switch (firstItem.contentType) {
            case ContentBlockType.DIVIDER:
                return 10;
            default:
                return firstItem.content.firstTextLineHeight;
        }
    }

    get isTextFit() {
        return this.itemElements.every(element => element.content == null || element.content.isTextFit);
    }

    addItem(props, index) {
        if (!props.type) {
            if (this.itemElements[index - 1].contentType == ContentBlockType.BULLET_POINT) {
                props.type = ContentBlockType.BULLET_POINT;
            }
        }

        return super.addItem(props, index);
    }

    _calcProps(props, options) {
        let { size } = props;

        let textAlign = this.model.textAlign || options.textAlign || HorizontalAlignType.CENTER;

        let doesContentFit;

        let vGap = this.model.verticalGap ?? this.styles.verticalGap ?? 30;

        let { itemsProps, fitValue: scale, innerPadding } = FindBestFit({
            min: 0.4,
            max: 1,
            preCheckMax: true,
            forceScale: options.forceTextScale,
            layout: scale => {
                let innerSize = size;
                let innerPadding = 0;
                if (options.hasBackdrop) {
                    innerPadding = this.model.backdropPadding * scale;
                    innerSize = size.deflate(innerPadding);
                }
                let doesContentFit = true;

                let layouter = this.getLayouter(props, this.itemElements, innerSize);
                layouter.distributeVertically({
                    horizontalAlign: textAlign,
                    reserveMinHeights: true,
                    gap: vGap * scale,
                    itemOptions: {
                        textAlign: textAlign,
                        autoWidth: options.autoWidth,
                        textColor: this.model.textColor,
                        forceTextScale: scale
                    }
                });

                for (let item of this.itemElements) {
                    if ((item.content instanceof ContentBlockTextElement || item.content instanceof ContentBlockBulletPoint) && item.content?.isTextFit == false) {
                        doesContentFit = false;
                        break;
                    }
                }
                if (layouter.size.height > size.height) {
                    doesContentFit = false;
                }

                return {
                    isFit: layouter.isFit && doesContentFit,
                    itemsProps: this.itemElements.map(item => item.getTreeProps()),
                    innerPadding
                };
            }
        });

        props.isFit = true;
        // restore the calculatedProps of the tree at the fit scale
        for (let item of this.itemElements) {
            item.assignPropsToTree(itemsProps.findById(item.id));
        }

        let contentBounds = null;
        for (let item of this.itemElements) {
            if (item.contentType == ContentBlockType.BULLET_POINT) {
                // always left align bullet point blocks
                item.calculatedProps.bounds.left = 0;
            }
            contentBounds = contentBounds ? contentBounds.union(item.bounds) : item.bounds;

            if (options.textStyle == "white_text_with_shadow") {
                item.content.styles.filter = "textShadow";
            }
        }

        return { size: contentBounds.size, scale, doesContentFit, innerPadding };
    }
}

export class ContentBlockItem extends CollectionItemElement {
    get minHeight() {
        return this.content.minHeight;
    }

    get selectionPadding() {
        return 10;
    }

    get rolloverPadding() {
        return 10;
    }

    get _canSelect() {
        return true;
    }

    get showRollover() {
        return false;
    }

    get _doubleClickToSelect() {
        return false;
    }

    get passThroughSelection() {
        return true;
    }

    get requireParentSelection() {
        return false;
    }

    get contentType() {
        return this.model.type || "text";
    }

    get textAlign() {
        return this.parentElement.textAlign;
    }

    _build() {
        // don't love doing this here but this handles conversion of the model when switching to/from a bulletpoint which uses TextGroup model
        if (this.lastBuildModel && this.lastBuildModel.type !== this.contentType) {
            if (this.contentType == ContentBlockType.BULLET_POINT) {
                this.model.title = this.model.content;
            } else if (this.lastBuildModel.type == ContentBlockType.BULLET_POINT) {
                this.model.content = this.model.title;
            }
        }

        switch (this.contentType) {
            case ContentBlockType.BIGTEXT:
                this.content = this.addElement("content", () => ContentBlockTextBigText, {
                    breakType: "even"
                });
                break;
            case ContentBlockType.HEADLINE:
                this.content = this.addElement("content", () => ContentBlockTextHeadline, {
                    breakType: "even"
                });
                break;
            case ContentBlockType.HEADING:
                this.content = this.addElement("content", () => ContentBlockTextHeading);
                break;
            case ContentBlockType.TITLE:
            case "subheading": // legacy
            case "label": // legacy
            case "legacytitle":// legacy
                this.content = this.addElement("content", () => ContentBlockTextTitle);
                break;
            case ContentBlockType.CAPTION:
                this.content = this.addElement("content", () => ContentBlockTextCaption);
                break;
            case ContentBlockType.DIVIDER:
                this.content = this.addElement("content", () => ContentBlockDivider);
                break;
            // case ContentBlockType.LIST:
            //     this.content = this.addElement("content", () => ContentBlockList, { autoHeight: true });
            //     break;
            case ContentBlockType.ICON:
                this.content = this.addElement("content", () => ContentBlockIcon, {
                    defaultAssetType: AssetType.ICON,
                    autoHeight: true,
                    allowUnframedImages: true
                });
                break;
            case ContentBlockType.BULLET_POINT:
                this.content = this.addElement("content", () => ContentBlockBulletPoint);
                break;
            case "description": //legacy
            case ContentBlockType.BODY:
            default:
                this.content = this.addElement("content", () => ContentBlockTextBody);
                break;
        }

        // this.content.options.allowAlignment = this.parentElement.options.allowAlignment;
        this.content.options.allowAlignment = this.parentElement.allowAlignment;

        this.options.rollover = "ContentBlockItemContentRollover";
    }

    _calcProps(props, options) {
        let { size } = props;

        // size.height = this.model.blockHeight ?? size.height;

        let textAlign = options.textAlign || this.textAlign;

        this.content.styles.textAlign = textAlign;

        let contentSize = size;

        if (this.contentType == ContentBlockType.ICON) {
            contentSize.width = this.model.contentSize;
            options.autoWidth = false; // this option is overloaded and get's passed through to the FrameMediaElement incorrectly
        }

        let contentProps = this.content.calcProps(contentSize, options);
        contentProps.bounds = new geom.Rect(0, 0, contentProps.size);

        props.isTextFit = contentProps.isTextFit;

        return { size: contentProps.size };
    }

    _migrate_9() {
        switch (this.model.type) {
            case "title":
            case "label":
            case "subheading":
                // styles were using header font for title style and that's fixed but we don't want to change existing styles so this will use the hidden legacytitle style
                this.model.type = "legacytitle";
                break;
        }
    }
}

export class ContentBlockTextElement extends TextElement {
    get selectionPadding() {
        return 10;
    }

    get uiOffset() {
        return 30;
    }

    get _doubleClickToSelect() {
        return false;
    }

    get passThroughSelection() {
        return true;
    }

    get requireParentSelection() {
        return false;
    }

    get allowedTextStyles() {
        return _.pull([...this.findClosestOfType(ContentBlockCollection).availableBlockTypes], ContentBlockType.DIVIDER, ContentBlockType.ICON);
    }

    get textStylePropertyName() {
        return "type";
    }

    get allowStyling() {
        return true;
    }

    get allowUserScale() {
        return true;
    }

    get singleLine() {
        return true;
    }

    get allowAlignment() {
        return this.options.allowAlignment != undefined ? this.options.allowAlignment : true;
    }

    get autoHeight() {
        return true;
    }

    get constrainWidth() {
        return true;
    }

    get scaleTextToFit() {
        return false;
    }

    get allowBlockType() {
        return true;
    }

    getSlideColor(options = {}) {
        if (this.findClosestOfType(NodeElement)) {
            return this.findClosestOfType(NodeElement).getSlideColor(options);
        } else {
            return super.getSlideColor(options);
        }
    }
}

class ContentBlockTextBigText extends ContentBlockTextElement {
    get allowedBreakTypes() {
        return [TextBreakType.EVEN, TextBreakType.DEFAULT];
    }

    get placeholderPrompt() {
        return "Type text";
    }
}

class ContentBlockTextHeadline extends ContentBlockTextElement {
    get allowedBreakTypes() {
        return [TextBreakType.EVEN, TextBreakType.DEFAULT];
    }

    get placeholderPrompt() {
        return "Type headline";
    }
}

class ContentBlockTextTitle extends ContentBlockTextElement {
    get placeholderPrompt() {
        return "Type title";
    }
}

class ContentBlockTextBody extends ContentBlockTextElement {
    get placeholderPrompt() {
        return "Type text";
    }

    get pasteCharLimit() {
        return 5000;
    }
}

class ContentBlockTextCaption extends ContentBlockTextElement {
    get placeholderPrompt() {
        return "Type caption";
    }
}

class ContentBlockTextHeading extends ContentBlockTextElement {
    get placeholderPrompt() {
        return "Type heading";
    }
}

class ContentBlockIcon extends FramedMediaElement {
    static get schema() {
        return {
            contentSize: 100
        };
    }

    get minHeight() {
        return this.styles.height;
    }

    get _canSelect() {
        return false;
    }

    get defaultOverlayType() {
        return "ContentElementDefaultOverlay";
    }
}

class ContentBlockBulletPoint extends BaseElement {
    get minHeight() {
        return this.styles.marginTop + this.styles.marginBottom + this.styles.paddingTop + this.styles.paddingBottom + this.styles.fontSize;
    }

    get listStyle() {
        return this.findClosestOfType(ContentBlockCollection).model.listStyle || ListStyleType.BULLET;
    }

    get listIndex() {
        let collection = this.findClosestOfType(ContentBlockCollection);
        let index = 0;
        for (let item of collection.itemElements) {
            if (item) {
                if (item.id == this.parentElement.id) break;
                if (item.contentType == ContentBlockType.BULLET_POINT) {
                    index++;
                } else {
                    index = 0;
                }
            }
        }
        return index;
    }

    get isTextFit() {
        return this.calculatedProps.isTextFit;
    }

    _build() {
        switch (this.listStyle) {
            case ListStyleType.BULLET:
                this.content = this.addElement("bullet", () => SVGCircleElement);
                break;
            case ListStyleType.NUMBERED:
                this.content = this.addElement("index", () => TextElement, {
                    model: {
                        index: this.listIndex + 1
                    },
                    canEdit: false,
                    isTabbable: false
                });
                break;
            case ListStyleType.CHECKBOX:
                this.content = this.addElement("checkbox", () => TextListCheckBox, { fitAsset: true });
                break;
            case ListStyleType.ICON:
                this.content = this.addElement("icon", () => TextListIcon, { fitAsset: true });
                break;
        }

        this.text = this.addElement("text", () => TextGroup, {
            autoWidth: true,
            autoHeight: true,
            showAddButton: false,
            title: {
                singleLine: true,
            },
            body: {
                allowParagraphStyles: true,
                allowEmptyLines: false,
                placeholder: "Type text or hit enter to add another bullet"
            }
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        this.styles.marginTop = this.styles.marginBottom = this.defaultStyles.marginTop * (options.forceTextScale ?? 1);

        size.width = Math.min(this.styles.maxWidth || size.width, size.width);

        if (this.listStyle === ListStyleType.TEXT) {
            // just text
            let textProps = this.text.calcProps(size, { forceTextScale: options.forceTextScale ?? 1 });
            return { size: textProps.size, isTextFit: textProps.isTextFit };
        } else {
            // text and a content element
            if (this.listStyle === ListStyleType.CHECKBOX && this.model.checkState === "unchecked") {
                props.opacity = 0.5;
            }

            if (options.bulletAlign === "right") {
                this.text.styles.textAlign = HorizontalAlignType.RIGHT;
                this.text.styles.marginRight = this.content.styles.gap;
                this.text.styles.marginLeft = 0;
            } else {
                this.text.styles.marginLeft = this.content.styles.gap;
                this.text.styles.marginRight = 0;
            }

            this.content.scaleStyleValues(options.forceTextScale ?? 1);

            let contentProps = this.content.calcProps(size);
            contentProps.bounds = new geom.Rect(0, 0, contentProps.size);

            let textProps = this.text.calcProps(new geom.Size(size.width - contentProps.size.width, size.height), {
                forceTextScale: options.forceTextScale ?? 1,
            });
            textProps.bounds = new geom.Rect(contentProps.bounds.width, 0, textProps.size);

            contentProps.bounds.top = this.text.title.calculatedProps.textLayout.lines[0].fontHeight / 2 - contentProps.size.height / 2;

            return {
                size: new geom.Size(contentProps.bounds.width + textProps.bounds.width, textProps.bounds.height),
                isTextFit: textProps.isTextFit
            };
        }
    }
}

class ContentBlockDivider extends BaseElement {
    static get schema() {
        return {
            style: "line",
            dividerWidth: "auto",
            blockHeight: 11
        };
    }

    get minHeight() {
        return this.styles.marginTop + this.styles.marginBottom + this.styles.paddingTop + this.styles.paddingBottom;
    }

    get showRollover() {
        return true;
    }

    get dividerStyle() {
        return this.model.style || "line";
    }

    get dividerWidth() {
        return this.model.dividerWidth || "full";
    }

    _build() {
        if (this.dividerStyle != "spacer") {
            this.divider = this.addElement("divider", () => SVGPolylineElement);
        }
    }

    _calcProps(props, options) {
        const { size, children } = props;

        switch (this.dividerWidth) {
            case "full":
                break;
            case "half":
                size.width = size.width / 2;
                break;
            case "short":
                size.width = Math.max(size.width / 4, 100);
                break;
            case "auto":
                if (options.autoWidth) {
                    size.width = 100;
                }
                break;
        }

        let height = this.model.blockHeight ?? 11;
        // let height = size.height;

        // if (options.textColor && this.canvas.layouter.isGenerating) {
        //     this.divider.styles.strokeColor = options.textColor;
        // }

        if (this.divider) {
            this.divider.calculatedProps = {
                path: [[0, Math.floor(height / 2)], [size.width, Math.floor(height / 2)]]
            };

            switch (this.dividerStyle) {
                case "line":
                    this.divider.styles.strokeWidth = 1;
                    this.divider.styles.strokeDash = null;
                    this.divider.styles.strokeOpacity = 0.3;
                    break;
                case "dashed":
                    this.divider.styles.strokeWidth = 2;
                    this.divider.styles.strokeDash = "8 8";
                    this.divider.styles.strokeOpacity = 0.4;
                    break;
                case "dotted":
                    this.divider.styles.strokeWidth = 3;
                    this.divider.styles.strokeDash = "3 9";
                    this.divider.styles.strokeOpacity = 0.4;
                    break;
            }
        }

        return { size: new geom.Size(size.width, height) };
    }
}

