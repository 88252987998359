import React, { Component } from "reactn";
import { $, _ } from "../../vendor";

export function RowGrid(props) {
    props = {
        gap: 20,
        margin: "",
        ...props,
    };
    let style = {
        rowGap: props.gap,
        margin: props.margin,
        ...props.style
    };

    return (
        <div
            className="row-grid"
            disabled={props.disabled}
            style={style}
        >
            {props.children}
        </div>
    );
}

export function ColGrid(props) {
    props = {
        gap: 20,
        ...props,
    };
    let style = {
        columnGap: props.gap,
        ...props.style
    };

    return (
        <div
            className="col-grid"
            disabled={props.disabled}
            style={style}
        >
            {props.children}
        </div>
    );
}

export class Box extends Component {
    ref = React.createRef();

    get defaultStyles() {
        return {};
    }

    getStyles() {
        const styles = { ...this.props.style };

        if (this.props.fillWidth || this.props.fill) {
            styles.width = "100%";
        }

        if (this.props.fillHeight || this.props.fill) {
            styles.height = "100%";
        }

        if (this.props.width) {
            styles.width = this.props.width;
        }

        if (this.props.height) {
            styles.height = this.props.height;
        }

        if (this.props.center) {
            styles.alignItems = "center";
            styles.justifyContent = "center";
        }

        return styles;
    }

    render() {
        const {
            id,
            style,
            children,
        } = this.props;

        const styles = this.getStyles();

        const componentProps = _.omit(this.props, [
            "fill",
            "fillWidth",
            "fillHeight",
            "width",
            "height",
            "column",
            "vertical",
            "center",
            "wrap",
            "horizontalAlign",
            "verticalAlign",
            "left",
            "right",
            "top",
            "bottom",
            "center",
            "middle",
            "rows",
            "columns",
            "template",
            "between",
        ]);

        return (
            <div id={id}
                key={id}
                {...componentProps}
                style={{
                    ...styles,
                    ...style,
                }}
                ref={this.ref}
            >
                {children}
            </div>
        );
    }
}

export class AbsoluteBox extends Box {
    getStyles() {
        let props = this.props;
        let styles = super.getStyles();

        styles.position = "absolute";
        styles.left = props.left;
        styles.top = props.top;
        styles.width = props.width;
        styles.height = props.height;

        return styles;
    }
}

export class FlexBox extends Box {
    getStyles() {
        let props = this.props;
        let styles = super.getStyles();

        styles.display = styles.display || "flex";

        if (props.gap) {
            styles.gap = props.gap;
        }

        if (props.column || props.vertical) {
            styles.flexDirection = "column";
        }

        if (props.center) {
            styles.alignItems = "center";
            styles.justifyContent = "center";
        }

        if (props.wrap) {
            styles.flexWrap = "wrap";
        }

        switch (props.horizontalAlign || "center") {
            case "start":
                styles.justifyContent = "start";
                break;
            case "left":
                styles.justifyContent = "flex-start";
                break;
            case "center":
                styles.justifyContent = "center";
                break;
            case "right":
                styles.justifyContent = "flex-end";
                break;
            case "between":
                styles.justifyContent = "space-between";
                break;
            case "around":
                styles.justifyContent = "space-around";
                break;
            case "evenly":
                styles.justifyContent = "space-evenly";
                break;
        }

        switch (props.verticalAlign || "middle") {
            case "start":
                styles.alignItems = "start";
                break;
            case "top":
                styles.alignItems = "flex-start";
                break;
            case "middle":
            case "center":
                styles.alignItems = "center";
                break;
            case "bottom":
                styles.alignItems = "flex-end";
                break;
        }

        if (styles.flexDirection == "column") {
            if (props.left) {
                styles.alignItems = "flex-start";
            }
            if (props.right) {
                styles.alignItems = "flex-end";
            }
            if (props.top) {
                styles.justifyContent = "flex-start";
            }
            if (props.bottom) {
                styles.justifyContent = "flex-end";
            }
            if (props.middle) {
                styles.justifyContent = "center";
            }
        } else {
            if (props.left) {
                styles.justifyContent = "flex-start";
            }
            if (props.right) {
                styles.justifyContent = "flex-end";
            }
            if (props.top) {
                styles.alignItems = "flex-start";
            }
            if (props.bottom) {
                styles.alignItems = "flex-end";
            }
            if (props.middle) {
                styles.alignItems = "center";
            }
        }
        if (props.between) {
            styles.justifyContent = "space-between";
        }

        return styles;
    }
}

export class GridBox extends Box {
    getStyles() {
        let {
            columns = false,
            gap = 20,
            template,
        } = this.props;
        let styles = super.getStyles();

        styles.display = "grid";
        if (columns) {
            styles.gridAutoFlow = "column";
            styles.gridAutoColumns = "max-content";
            if (template) {
                styles.gridTemplateRows = template;
            }
        } else {
            styles.gridAutoFlow = "row";
            styles.gridAutoRows = "max-content";
            if (template) {
                styles.gridTemplateColumns = template;
            }
        }
        styles.gridGap = gap;

        return styles;
    }
}

export function FillDiv(props) {
    let style = {
        width: "100%",
        height: "100%",
        ...props.style
    };

    return (
        <div
            id={props.id}
            disabled={props.disabled}
            style={style}
        >
            {props.children}
        </div>
    );
}

export class ScrollBox extends Box {
    scrollTo(selector, duration = 400) {
        let $el = $(this.ref.current);
        let $target = $(this.ref.current).find(selector);

        if ($target.length) {
            let scroll = $target.offset().top - $el.offset().top;// - $el.scrollTop();
            $el.animate({ scrollTop: $el.scrollTop() + scroll }, duration);
        }
    }

    getStyles() {
        let styles = super.getStyles();

        styles.overflowY = "scroll";
        styles.overflowX = "hidden";

        return styles;
    }
}
